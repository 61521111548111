import { URLS } from 'src/constants';
import { fe, TReqConfig } from '../utils';
import { TProfileEmptyResponse, TYakassaSubscription } from 'src/api/profile/types';
import { FullProfile, ProfileRestrictions } from 'src/models/ts/accounts';
import { LoginMethods } from 'src/models/ts/login';
import { wlDetector } from 'src/utils';

export const change = (config: TReqConfig = {}) =>
  fe.post(wlDetector().isSmotreshka ? URLS.changeProfileV2 : URLS.changeProfile, config);

export const create = (config: TReqConfig = {}): Promise<TProfileEmptyResponse> =>
  fe.post(URLS.createProfile, config);

export const update = (id: string, config: TReqConfig = {}): Promise<TProfileEmptyResponse> =>
  fe.put(URLS.updateProfile(id), config);

export const remove = (id: string): Promise<TProfileEmptyResponse> =>
  fe.delete(URLS.deleteProfile(id));

export const getProfile = (id: string): Promise<FullProfile> => fe.get(URLS.getProfile(id));

export const managing = (config: TReqConfig = {}): Promise<TProfileEmptyResponse> =>
  fe.post(URLS.managingLogin, config);

export const managingLogout = (config: TReqConfig = {}): Promise<TProfileEmptyResponse> =>
  fe.post(URLS.managingLogout, config);

export const managingWithVerifiedContacts = (
  config: TReqConfig = {}
): Promise<TProfileEmptyResponse> => fe.post(URLS.managingLoginWithVerifiedContacts, config);

export const getProfileRestrictions = (config: TReqConfig = {}): Promise<ProfileRestrictions> =>
  fe.get(URLS.profileRestrictions, config);

export const changePassword = (config: TReqConfig = {}): Promise<TProfileEmptyResponse> =>
  fe.post(URLS.changePassword, config);

export const activateSubscription = (
  id: string,
  config: TReqConfig = {}
): Promise<TYakassaSubscription> => fe.post(URLS.yakassa.activateSubscription(id), config);

export const cancelSubscription = (
  id: string,
  config: TReqConfig = {}
): Promise<TYakassaSubscription> => fe.post(URLS.yakassa.cancelSubscription(id), config);

export const managingMethods = (config: TReqConfig = {}): Promise<LoginMethods> =>
  fe.get(URLS.managingLoginMethods, config);

export const enableAutoRenewSubscription = (id: string, config: TReqConfig = {}) =>
  fe.post(URLS.quickSubscribe.enableAutoRenew(id), config);

export const disableAutoRenewSubscription = (id: string, config: TReqConfig = {}) =>
  fe.post(URLS.quickSubscribe.disableAutoRenew(id), config);
