<template>
  <header
    v-if="!showAgeConfirmationOverlay"
    :class="[componentName, { dark: !isSanta }]"
    :data-ctype="$options.name"
    :data-cname="componentName"
  >
    <FeSettings v-if="isSanta || $store.secretSanta" @logOut="logOut" />

    <div class="wrapper container-1440" :class="{ 'hide-menu': menuItems.length > 5 }">
      <div
        v-click-outside="clickOutsideMenu"
        class="menu-dropdown-container"
        :class="{ opened: burgerMenuVisible }"
      >
        <ButtonAction
          theme-forced="dark"
          data-cy="burger"
          size="medium"
          :class="{ pressed: burgerMenuVisible }"
          class="burger"
          :icon="IconBurger"
          @click="openMenu()"
        />

        <div v-if="burgerMenuVisible" class="menu-wrapper">
          <div class="menu-dropdown">
            <LinkInternalOrExternal
              v-for="item in menuItems"
              :key="item.link"
              class="menu-item"
              :class="[{ active: isMenuItemActive(item.link) }]"
              :link="item.link"
            >
              <span v-html="item.title" />
            </LinkInternalOrExternal>
          </div>
        </div>
      </div>

      <template v-if="actionName !== 'MOBILE_MENU'">
        <div v-show="!logoHasLink" class="logo">
          <img :src="logoUrl" alt="logo" />
        </div>
        <router-link v-show="logoHasLink" to="/" class="logo">
          <img :src="logoUrl" alt="logo" />
        </router-link>
      </template>

      <div class="menu body2">
        <LinkInternalOrExternal
          v-for="item in menuItems"
          :key="item.link"
          class="menu-item-wrap flex-center-vertical"
          :class="[
            { active: isMenuItemActive(item.link) },
            { 'pointer-events-none': isMenuItemActive(item.link) && $store.player.visible },
          ]"
          :link="item.link"
        >
          <div class="menu-item" v-html="item.title" />
        </LinkInternalOrExternal>
      </div>

      <div class="right">
        <template v-if="$route.name !== 'search'">
          <div class="search body1">
            <InputText
              v-if="!isAnonymous"
              v-model="$store.search.query"
              class="search-input"
              :placeholder="getTranslation('what_to_watch')"
              input-category="default"
              theme-forced="dark"
              size="small"
              icon="search"
              :submit-on-enter="true"
              @clickEnter="submitSearch"
              @submitSearch="submitSearch"
            />
            <ButtonAction
              class="search-button"
              :class="{ anonymous: isAnonymous }"
              :icon="IconSearch"
              :to="searchUrl"
              theme-forced="dark"
              size="small"
              data-cy="search"
            />
          </div>
        </template>

        <div class="desktop-only">
          <!-- uncomment this button when we implement favourties -->
          <!-- <ButtonAction-->
          <!--   v-if="!isAnonymous"-->
          <!--   class="mr-8"-->
          <!--   theme-forced="dark"-->
          <!--   data-cy="favorites"-->
          <!--   :icon="IconBookmarkOn"-->
          <!-- />-->

          <LanguageDropdown />

          <div v-show="isAnonymous" class="flex-center-vertical enter-buttons body2">
            <button
              type="button"
              class="action-button ml-16"
              data-cy="header-button-auth"
              @click="signIn"
              v-html="getTranslation('enter')"
            />

            <ButtonDefault
              v-if="$store.authAndReg.showRegistrationButton && !isWl"
              view="secondary"
              class="gift ml-16"
              data-cy="header-register"
              :with-icon="true"
              theme-forced="dark"
              @click="
                signUp();
                gaEvent({
                  category: 'header_personal_area',
                  action: 'Клик  “30 дней бесплатно”',
                });
              "
            >
              <IconSVG :svg="IconGift" />
              <span v-html="getTranslation('reg_button_text')" />
            </ButtonDefault>

            <button
              v-if="$store.authAndReg.showRegistrationButton && isWl"
              type="button"
              class="action-button ml-16"
              data-cy="header-register"
              @click="
                signUp();
                gaEvent({
                  category: 'header_personal_area',
                  action: 'Клик  “Регистрация”',
                });
              "
              v-html="getTranslation('registration')"
            />
          </div>

          <ProfileDropdown
            v-show="!isAnonymous"
            ref="profileSelector"
            @setProfileForChange="setProfileForChange"
            @setDropdownActionName="setDropdownActionName"
          />
        </div>
      </div>
    </div>

    <MobileMenu
      v-if="actionName === 'MOBILE_MENU'"
      :logo-url="logoUrl"
      :menu-items="menuItems"
      @closeMobileMenu="closeMobileMenu"
      @signIn="signIn"
      @signUp="signUp"
      @logOut="logOut"
      @setProfileForChange="setProfileForChange"
      @setDropdownActionName="setDropdownActionName"
      @isMenuItemActive="isMenuItemActive"
    />

    <portal to="modal">
      <ModalSequoia
        v-if="isModalOpen && actionName === PROFILE_ACTIONS.CHANGE"
        type="fixed"
        size="small"
        @close="actionName = ''"
      >
        <ProfileChange
          class="with-side-and-bottom-padding"
          :action-name="PROFILE_ACTIONS.CHANGE"
          :profile="profileForChange"
        />
      </ModalSequoia>
    </portal>
  </header>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Mixins, Ref, Watch } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconSearch from 'src/svg/search.svg';
import IconBookmarkOn from 'src/svg/bookmark-on.svg';
import IconBurger from 'src/svg/burger.svg';
import IconGift from 'src/svg/gift.svg';
import CmsComponent from 'src/mixins/CmsComponent';
import InputText from 'src/components/ui/input/InputText.vue';
import ProfileChange from 'src/components/personal/forms/ProfileChange.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import { wlDetector } from 'src/utils';
import { PROFILE_ACTIONS, AUTH_AND_REG_URLS } from 'src/constants';
import { actions } from 'src/store/actions';
import { clickOutside } from 'src/utils/directives';
import FeSettings from 'src/components/FeSettings.vue';
import NotificationSubscriptionPaymentError from 'src/components/ui/notifications/NotificationSubscriptionPaymentError.vue';
import { selectors } from 'src/store/selectors';
import { TTranslations } from 'src/store/translations/types';
import LanguageDropdown from 'src/components/header/LanguageDropdown.vue';
import ProfileDropdown from 'src/components/header/ProfileDropdown.vue';
import logger from 'src/utils/logger';
import { TProfile } from 'src/api/auth/types';
import MobileMenu from 'src/components/header/MobileMenu.vue';
import { makePath, isUrlExternal } from 'src/utils/url';
import { TYakassaSubscription } from 'src/api/quick-subscribe/types';
import { TImageCMS } from 'src/store/common/types';
import LinkInternalOrExternal from 'src/components/ui/LinkInternalOrExternal.vue';
import { localStore } from 'src/utils/storage';

const log = logger('header');

@Component({
  components: {
    MobileMenu,
    LanguageDropdown,
    ProfileDropdown,
    ModalSequoia,
    IconSVG,
    InputText,
    ButtonDefault,
    ButtonAction,
    ProfileChange,
    FeSettings,
    NotificationSubscriptionPaymentError,
    LinkInternalOrExternal,
  },
  directives: { clickOutside },
})
export default class HeaderSequoia extends Mixins(SequoiaComponent, CmsComponent) {
  IconGift = IconGift;
  IconSearch = IconSearch;
  IconBookmarkOn = IconBookmarkOn;
  IconBurger = IconBurger;

  isUrlExternal = isUrlExternal;
  PROFILE_ACTIONS = PROFILE_ACTIONS;

  showFavorites = true;
  profileForChange?: TProfile;
  burgerMenuVisible = false;

  isSanta = wlDetector().isSanta;
  actionName = '';
  archivedSubs: TYakassaSubscription[] = [];

  content?: Partial<{
    logoLight: TTranslations<TImageCMS>;
    logoDark: TTranslations<TImageCMS>;
    menu: Array<{ title: string; items: Array<{ link: string; translation: string }> }>;
    favoritesUrl: string;
    personalAreaUrl: string;
  }> | null = null;

  @Ref('profileSelector')
  refProfileDropdown?: any;

  @Watch('$route')
  onRouteChange() {
    this.actionName = '';
    actions.common.unlockBodyScroll(this.$store);
  }

  @Watch('$store.common.isProfileLoaded')
  onProfileChange(val: boolean) {
    if (val) {
      // reset actionName when profile was changed
      this.actionName = '';
    }
  }

  @Watch('$store.vod.states.sourcesLoaded')
  onSourcesLoaded(val: boolean) {
    if (val) {
      this.checkVodMenu();
    }
  }

  get isWl() {
    return !wlDetector().isSmotreshka;
  }

  get isUztel() {
    return wlDetector().isUztel;
  }
  get searchQuery() {
    return selectors.search.querySelector(this.$store);
  }
  get searchUrl() {
    return `/search${this.searchQuery ? '?q=' + this.searchQuery : ''}`;
  }
  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get showAgeConfirmationOverlay() {
    return this.$store.common.showAgeConfirmationOverlay;
  }

  get logoHasLink() {
    const homePage = this.$store.cmsPages?.find(
      (p) => p.component.template.includes('<HomePage') && p.path === '/'
    );
    return !(this.$route.path === '/' || (this.$route.path.includes('/channel/') && !homePage));
  }

  get logoUrl() {
    return this.getByLanguage(this.content?.logoDark)?.url || '';
  }

  get menuItems() {
    if (!this.content?.menu) {
      return [];
    }

    return this.content.menu
      .filter((item: any) => item.show)
      .map((item: any) => ({
        link: item.link,
        title: this.getTranslation(item.titleId),
      }));
  }

  submitSearch() {
    this.$router.push(this.searchUrl);
  }

  checkVodMenu() {
    if (!this.content?.menu) {
      return [];
    }

    this.content.menu = this.content.menu.map((menu: any) => {
      const menuItem = { ...menu, show: true };
      if (menu.link.startsWith('/vod') || menu.link.startsWith('/archive')) {
        const url = menu.link.replace('/vod', '').replace('/', '');
        if (url === '') {
          menuItem.show = Object.keys(this.$store.vod.sources).length > 0;
        } else {
          menuItem.show = !!this.$store.vod.sources[url];
        }
      }

      return menuItem;
    });
  }

  serverPrefetch() {
    this.checkVodMenu();
  }

  async mounted() {
    this.checkVodMenu();
    actions.common.unlockBodyScroll(this.$store);
  }

  async checkAuthForUztel() {
    try {
      await actions.authAndReg.uztelAuth(this.$store, this.$ssrContext?.cookiesToSet);
      localStore.set('showLoginSuccessNotification', true);
      actions.authAndReg.showLoginSuccessNotification(this.$store);
      try {
        await actions.payment.loadPaymentSubscriptionsV2(this.$store);
      } catch (err) {
        log.error('onLoadPaymentSubscriptions', err);
      }
    } catch (err) {
      log.error(err);
      this.closeMobileMenu();
      await this.$router.push(AUTH_AND_REG_URLS.defaultLogin);
      throw err;
    } finally {
      localStore.set('ipWasChecked', true);
    }
  }

  setDropdownActionName(name: string) {
    this.actionName = name;
  }

  setProfileForChange(profile: TProfile) {
    this.profileForChange = profile;
  }

  openMenu() {
    if (window.innerWidth < 960) {
      this.actionName = 'MOBILE_MENU';
      actions.common.lockBodyScroll(this.$store);
      this.burgerMenuVisible = false;
    } else {
      this.burgerMenuVisible = !this.burgerMenuVisible;
    }
  }

  clickOutsideMenu() {
    this.burgerMenuVisible = false;
  }

  closeMobileMenu() {
    actions.common.unlockBodyScroll(this.$store);
    // clear actionName only if no modals were triggered
    this.actionName = this.actionName === 'MOBILE_MENU' ? '' : this.actionName;
    if (this.refProfileDropdown) {
      this.refProfileDropdown.profilesListOpened = false;
    }
  }

  isMenuItemActive(link: string) {
    const exact = link === '/';
    return exact ? this.$route.path === link : this.$route.path.includes(link);
  }

  signIn() {
    if (this.isUztel && !localStore.get('ipWasChecked')) {
      this.checkAuthForUztel();
    } else {
      this.closeMobileMenu();
      this.$router.push(AUTH_AND_REG_URLS.defaultLogin);
    }

    this.gaEvent({
      category: 'header_personal_area',
      action: 'Клик "Вход"',
    });
  }

  signUp() {
    this.closeMobileMenu();
    const url = this.$store.authAndReg.registrationButtonUrl
      ? this.$store.authAndReg.registrationButtonUrl
      : AUTH_AND_REG_URLS.defaultReg;
    if (url.startsWith('http')) {
      window.location.href = makePath(url);
    } else {
      this.$router.push(url);
    }
  }

  async logOut() {
    await actions.authAndReg.logout(this.$store);
    localStorage?.removeItem('tab');
    document.location.href = makePath('/');

    this.gaEvent({
      category: 'header_personal_area',
      action: 'Клик "Выход"',
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/components/header/dropdowns';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-header);
  width: 100%;
  min-width: 320px;
  height: var(--header-height);
  color: var(--c-dark-font-primary);
  background-color: var(--c-dark-300);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  // to avoid bugs on scroll in iOS
  transform: translateZ(0);
  -webkit-font-smoothing: antialiased;

  a {
    color: var(--c-dark-font-primary);
  }

  .button.action.search-button {
    display: none;
    margin-right: 8px;

    &.anonymous {
      display: flex;
      margin-right: 0;
      margin-left: auto;
    }

    @include mobile-and-tablet {
      display: flex;
    }

    &::v-deep .icon {
      color: var(--alpha-dark-9);
    }
  }

  .logo,
  .mobile-menu-wrapper::v-deep .logo {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    height: 64px;
  }

  .mobile-menu-wrapper::v-deep .logo {
    flex: 1;
  }

  .wrapper {
    display: flex;
    align-items: center;
    padding: 0 64px;

    @media (max-width: #{$desktop-s-max}) {
      padding: 0 64px 0 48px;
    }

    .logo {
      position: relative;
      z-index: var(--z-2);
      margin-right: 8px;

      @include mobile-and-tablet {
        margin-right: 0;
      }

      img {
        max-width: 164px;
        height: 100%;
        max-height: 28px;
      }
    }

    .menu-dropdown-container {
      position: relative;
      display: none;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;

      @include desktop-s {
        margin-right: 8px;
      }

      @include mobile-and-tablet {
        position: absolute;
        left: 24px;
      }

      @include mobile {
        left: 2px;
      }

      .burger {
        display: none;

        @include desktop-s {
          display: flex;
          width: 56px;
          height: 64px;
          border-radius: 0;
        }

        .icon {
          color: var(--alpha-dark-9);
        }

        &.pressed,
        &:active {
          background-color: var(--alpha-dark-4);
        }

        &:not(.pressed) {
          @include devices-with-hover {
            &:hover {
              background-color: var(--alpha-dark-3);
            }
          }
        }

        @include mobile-and-tablet {
          display: flex;
          margin-right: 0;

          .icon {
            color: var(--alpha-dark-9);
          }
        }
      }

      .menu-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: var(--z-dropdown);
        display: none;
        width: 230px;
        background-color: var(--c-dark-300);
        border-radius: 0 0 4px 4px;
      }

      @include desktop-s {
        &.opened .menu-wrapper {
          display: block;
        }
      }

      .menu-dropdown {
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        background-color: var(--alpha-dark-4);
        @include body2;

        .menu-item {
          padding: 10px 10px 10px 16px;
          color: var(--c-dark-font-primary);
          border-left: 4px solid transparent;
          transition: 0.15s var(--ease-out);

          @include devices-with-hover {
            &:hover {
              background-color: var(--alpha-dark-3);
            }
          }

          &.active {
            border-color: var(--c-dark-brand);
          }
        }
      }
    }

    .menu {
      display: flex;
      height: 64px;
      @include body2;

      @include mobile-and-desktop-s {
        display: none;
      }

      .menu-item-wrap {
        flex-shrink: 0;
        margin-left: 24px;
        cursor: pointer;

        &:hover .menu-item,
        &.active .menu-item {
          color: var(--c-dark-font-primary);
        }

        .menu-item {
          padding: 2px 0;
          color: var(--c-dark-font-secondary);
          border-bottom: 2px solid transparent;
          transition: 0.15s var(--ease-out);
        }

        &.active .menu-item {
          border-color: var(--c-dark-brand);
        }
      }
    }

    .right {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;

      > .desktop-only {
        display: flex;
        flex-grow: 0;
        align-items: center;
        justify-content: flex-end;
        height: 64px;

        .button.primary {
          margin-bottom: 0;
        }
      }

      @include mobile-and-tablet {
        position: absolute;
        right: 24px;
        justify-content: center;
        width: 64px;
        height: 64px;
      }

      @include mobile {
        right: 0;
      }
    }

    .menu-dropdown-container,
    .search-button {
      cursor: pointer;

      &.show {
        display: flex;
      }
    }

    .search {
      position: relative;

      .search-input {
        width: 260px;
        height: 40px;
        margin-right: 16px;
        color: var(--c-dark-font-primary);

        @include mobile-and-tablet {
          display: none;
        }
      }

      .label {
        position: absolute;
        top: 8px;
        right: 12px;
      }
    }

    .enter-buttons {
      height: 100%;

      .action-button {
        display: flex;
        align-items: center;
        height: 100%;
        color: var(--c-dark-font-secondary);
        transition: 0.15s var(--ease-out);

        &:hover,
        &:active {
          color: var(--c-dark-font-primary);
        }
      }
    }

    @include tablet {
      padding: 0 32px;
    }

    @include mobile {
      padding: 0 8px;
    }

    @include mobile-and-desktop-s {
      .menu-dropdown-container {
        display: flex;
      }
    }

    @include mobile-and-tablet {
      justify-content: center;

      .search-button {
        display: flex;
      }

      .right {
        flex: none;

        > .desktop-only {
          display: none;
        }
      }

      .button.action.search-button {
        display: flex;
        margin-right: 0;

        .icon {
          color: var(--alpha-dark-9);
        }
      }
    }

    @include mobile-s {
      padding: 0 8px;
    }
  }
}

.cms-layout-santa header {
  height: 120px;
}
</style>
