<template>
  <div>
    <FeSettings v-if="isSanta || $store.secretSanta" />
    <ErrorLayout :http-status="500" :image-desktop="imageDesktop" :image-mobile="imageMobile">
      <h2>К&nbsp;сожалению, произошла какая&#x2011;то&nbsp;ошибка</h2>
      <p v-if="isSmotreshka">
        Попробуйте обновить страницу или
        <a
          target="_blank"
          class="link nav-underlined"
          href="https://ask.smotreshka.tv//?utm_source=500"
          >свяжитесь&nbsp;с&nbsp;нами</a
        >, если это не помогло.
      </p>
      <p v-else>Попробуйте обновить страницу или повторить попытку через несколько минут.</p>
    </ErrorLayout>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ErrorLayout from 'src/layouts/ErrorLayout.vue';
import FeSettings from 'src/components/FeSettings.vue';
import { wlDetector } from 'src/utils';
import { actions } from 'src/store/actions';

@Component({ components: { ErrorLayout, FeSettings } })
export default class Error500Page extends Vue {
  get isSanta() {
    return wlDetector().isSanta;
  }

  get isSmotreshka() {
    return wlDetector().isSmotreshka;
  }

  get imageDesktop() {
    const src = '/public/images/backgrounds/error-pages/500.png';
    return `${src}?v${this.$store.imagesMTimes[src]}`;
  }

  get imageMobile() {
    const src = '/public/images/backgrounds/error-pages/500-mobile.png';
    return `${src}?v${this.$store.imagesMTimes[src]}`;
  }

  mounted() {
    actions.common.showFooter(this.$store);
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }
}
</script>
