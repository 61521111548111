<template>
  <div
    class="tile-channel-now col-desktop-3 col-desktop-s-4 col-tablet-4 col-mobile-4"
    :class="theme"
    @mouseleave="refBookmark ? refBookmark.$el.blur() : null"
  >
    <div class="top">
      <BadgeSource v-if="!!channelLogo" class="logo" :src="channelLogo" :width="60" :height="40" />

      <div class="channel-data">
        <div class="channel-number caption2" v-text="channelNumber" />
        <div class="channel-title h6" v-text="channelTitle" />
      </div>

      <ButtonDefault
        view="tertiary"
        size="small"
        type="button"
        :with-icon="true"
        class="hidden-mobile-and-tablet"
        @click="showChannelDetails"
      >
        <IconSVG :svg="IconInfo" />
        <span v-html="getTranslation('about_channel')" />
      </ButtonDefault>

      <ButtonBookmark
        v-if="!isAnonymous"
        ref="bookmark"
        size="medium"
        :theme-forced="theme"
        :active="isChannelInFavorites"
        class="hidden-mobile-and-tablet"
        @click.native="toggleChannelFavorite"
        @mouseleave.native="$event.target.blur()"
      />

      <DropdownSequoia
        v-slot="{ toggle, opened }"
        class="channel-action-menu hidden-desktop"
        :list="actionMenuChannel"
        position="right"
        width="content"
        :action-menu="true"
        :action-menu-mobile-custom-view="true"
        @select="selectAction"
      >
        <ButtonAction
          size="medium"
          :class="{ active: opened }"
          :icon="IconMoreHorizontal"
          @click="toggle"
        />
      </DropdownSequoia>
    </div>

    <ProgramPreview
      :src="liveProgramSrc"
      :title="liveProgramTitle"
      :time="liveProgramStartTime"
      :timeline-position="liveProgramStartTimelinePosition"
      :show-progress="true"
      :width="310"
      :height="175"
      @click="selectChannel"
    />

    <TileChannelNowProgramInfo
      :theme-forced="theme"
      :timeline-position="liveProgramStartTimelinePosition"
      :live-program-title="liveProgramTitle"
      :live-program-start-time="liveProgramStartTime"
      :next-program-start-time="nextProgramStartTime"
      :next-program-title="nextProgramTitle"
      :next-program-starts-in="nextProgramStartsIn"
      @showEpg="showEpg"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import * as api from 'src/api';
import Component, { mixins } from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref } from 'vue-property-decorator';
import ButtonBookmark from 'src/components/ui/buttons/ButtonBookmark.vue';
import ProgramPreview from 'src/components/tv-channels/ProgramPreview.vue';
import TileChannelNowProgramInfo from 'src/components/tv-channels/TileChannelNowProgramInfo.vue';
import { TChannelEnhanced } from 'src/api/channels/types';
import { getChannelLogo, getChannelNumber, getChannelTitle } from 'src/utils/channel';
import IconSVG from 'src/components/IconSVG.vue';
import IconInfo from 'src/svg/info.svg';
import IconMoreHorizontal from 'src/svg/more-horizontal.svg';
import IconBookmarkOn from 'src/svg/bookmark-on.svg';
import IconBookmarkOff from 'src/svg/bookmark-off.svg';
import IconEpg from 'src/svg/epg.svg';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import BadgeSource from 'src/components/ui/BadgeSource.vue';
import { actions } from 'src/store/actions';
import { TV_SPECIAL_GENRES } from 'src/constants';
import { selectors } from 'src/store/selectors';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import { scrollEverythingToTop } from 'src/utils/scroll-everything-to-top';
import AddResizeListener from 'src/mixins/AddResizeListener';

const CHANNEL_ACTION = {
  addFavorite: 'addFavorite',
  aboutChannel: 'aboutChannel',
  tvProgram: 'tvProgram',
};

@Component({
  components: {
    ButtonAction,
    TileChannelNowProgramInfo,
    ButtonDefault,
    IconSVG,
    ButtonBookmark,
    ProgramPreview,
    DropdownSequoia,
    BadgeSource,
  },
})
export default class TileChannelNow extends mixins(SequoiaComponent, AddResizeListener) {
  IconInfo = IconInfo;
  IconMoreHorizontal = IconMoreHorizontal;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  liveProgramSrc!: string;

  @Prop()
  liveProgramTitle?: string;

  // e.g. '22:47'
  @Prop()
  liveProgramStartTime?: string;

  @Prop()
  nextProgramTitle?: string;

  // e.g. '22:47'
  @Prop()
  nextProgramStartTime?: string;

  // e.g. '1 ч 39 мин'
  @Prop()
  nextProgramStartsIn?: string;

  @Prop()
  liveProgramStartTimelinePosition?: number;

  @Prop({ required: true })
  channel!: TChannelEnhanced;

  @Ref('bookmark')
  refBookmark?: Vue;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get channelLogo() {
    return getChannelLogo(this.channel);
  }

  get channelNumber() {
    return getChannelNumber(this.channel);
  }

  get channelTitle() {
    return getChannelTitle(this.channel);
  }

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  get isChannelInFavorites() {
    return !!this.favoriteChannels[this.channel?.id];
  }

  get currentGenre() {
    return selectors.tvChannels.currentGenreSelector(this.$store);
  }

  get shouldAddToRecentlyWatched() {
    return this.currentGenre !== TV_SPECIAL_GENRES.recentlyWatched;
  }

  get currentChannelCollection() {
    return selectors.channelCollections.currentCollectionSelector(this.$store);
  }

  get actionMenuChannel(): TDropdownItem[] {
    const actions = [
      {
        id: CHANNEL_ACTION.aboutChannel,
        key: '2',
        value: this.getTranslation('about_channel'),
        icon: IconInfo,
        selected: false,
        action: () => this.showChannelDetails(),
      },
      {
        id: CHANNEL_ACTION.tvProgram,
        key: '3',
        value: this.getTranslation('tv_program'),
        icon: IconEpg,
        selected: false,
        action: () => this.showEpg(),
      },
    ];

    if (!this.isAnonymous) {
      actions.unshift({
        id: CHANNEL_ACTION.addFavorite,
        key: '1',
        value: this.getTranslation(this.isChannelInFavorites ? 'favorites_off' : 'favorites_on'),
        icon: this.isChannelInFavorites ? IconBookmarkOn : IconBookmarkOff,
        selected: false,
        action: () => this.toggleChannelFavorite(),
      });
    }

    return actions;
  }

  selectAction(action: TDropdownItem) {
    const item = this.actionMenuChannel.find((item) => item.id === action.id);
    if (item && item.action) {
      item.action();
    }
  }

  async toggleChannelFavorite() {
    if (this.channel) {
      await actions.tvChannels.toggleChannelFavorite(this.$store, this.channel.id);
    }

    const { favouriteChannels } = await api.channels.getRecentAndFavChannels();
    Vue.set(this.$store.tvChannels, 'favorites', favouriteChannels || []);
  }

  showChannelDetails() {
    this.$emit('showChannelDetails', this.channel.id);
    this.gaEvent({ category: 'player_controls', action: 'Клик по кнопке "О канале"' });
  }

  showEpg() {
    this.$emit('showChannelDetails', this.channel.id, true);
  }

  async goToCatalogPage() {
    await this.$router.push(`/channels/now/${this.channel.id}/watch`);
  }

  async selectChannel() {
    if (this.currentChannelCollection) {
      actions.channelCollections.setCurrentCollectionSlug(this.$store, '');
      await this.goToCatalogPage();
    } else {
      await actions.tvChannels.selectChannel(
        this.$store,
        this.channel.id,
        this.$events,
        this.shouldAddToRecentlyWatched
      );

      scrollEverythingToTop(this.windowWidth < 600);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';
@import 'src/styles/placeholders-and-mixins/bookmark-indicator';

.tile-channel-now {
  padding: 12px;
  margin: 0 0 24px;
  border-radius: 8px;

  @media (min-width: #{$desktop-m-min}) {
    width: calc(100% * 3 / 12);
  }

  @include desktop-s {
    width: calc(100% * 4 / 12);
    margin-bottom: 20px;
  }

  @include tablet {
    width: calc(100% * 4 / 8 - 8px);
  }

  @include mobile {
    width: 100%;
  }

  .channel-action-menu {
    margin-left: auto;

    &:not(.is-mobile) {
      z-index: var(--z-6);
    }

    ::v-deep .menu-dropdown-container {
      margin-top: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &.dark {
    .buttons-dropdown {
      background-color: var(--c-dark-150);
    }
  }

  // -----------------------------------------------------------

  .top {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 12px;

    @include mobile-and-tablet {
      height: 48px;
    }

    .logo {
      flex-shrink: 0;
      width: 60px;
      height: 40px;
      aspect-ratio: 6 / 4;
    }

    .channel-data {
      padding-right: 8px;
      padding-left: 8px;
      overflow: hidden;
    }

    .channel-title {
      margin-bottom: 0;
      @extend %truncate-after-1-line;

      @include mobile-and-tablet {
        @include body1;

        font-weight: bold;
      }
    }

    > .button {
      &.default {
        display: none;
        padding-right: 0;
        padding-left: 0;
      }

      &.bookmark {
        margin-left: auto;

        &:not(.active) {
          @include devices-with-hover {
            display: none;
          }
        }

        @media (max-width: #{$tablet-max}) {
          display: none;
        }
      }

      &.action {
        display: none;
        margin-left: auto;

        @media (max-width: #{$tablet-max}) {
          display: flex;
        }
      }
    }
  }

  &:hover {
    @include bookmark-indicator;

    .top {
      @media (min-width: #{$desktop-s-min}) {
        justify-content: space-between;

        .button {
          &.default {
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            margin: 0 8px;
          }

          &.bookmark {
            display: flex;
            margin-left: 0;
          }
        }

        .channel-data {
          display: none;
        }
      }
    }
  }

  .program-preview {
    margin-bottom: 12px;

    @include mobile-and-tablet {
      margin-bottom: 8px;
    }

    @include devices-with-hover {
      &:hover {
        transform: scale(1.04);
      }

      &:active {
        transform: scale(1.02);
      }
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    @include devices-with-hover {
      @media (min-width: $desktop-s-min) {
        &:hover {
          background-color: var(--alpha-light-3);
        }
      }
    }

    @include mobile-and-tablet {
      background-color: var(--alpha-light-2);
    }
  }

  &.dark {
    @include devices-with-hover {
      @media (min-width: $desktop-s-min) {
        &:hover {
          background-color: var(--alpha-dark-3);
        }
      }
    }

    @include mobile-and-tablet {
      background-color: var(--alpha-dark-2);
    }
  }
}
</style>
