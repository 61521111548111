import { mixins } from 'vue-class-component';
import Global from 'src/mixins/Global';
import Metrics from 'src/mixins/Metrics';
import Seo from 'src/mixins/Seo';

export class SequoiaComponent extends mixins(Global, Metrics) {
  // typescript-friendly extendable class
}

export class SequoiaPage extends mixins(Global, Metrics, Seo) {
  // same as SequoiaComponent + Seo
}
