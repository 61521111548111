<template>
  <a
    ref="tilePoster"
    class="tile-poster"
    data-cy="tile-poster"
    :class="[
      theme,
      orientation,
      { large },
      { bookmarkless: isInFavorites === undefined },
      { 'pointer-events-none': disableHover },
      { 'no-overlay': !hasOverlay },
    ]"
    :href="link"
    @click.prevent="openTitle"
    @mouseleave="$refs.bookmark ? $refs.bookmark.$el.blur() : null"
  >
    <div class="inner">
      <div
        ref="tilePosterImage"
        class="image-container"
        :class="{ 'overlay-default-before overlay-accent-after': showOverlays }"
      >
        <LazyImage
          v-if="image"
          :theme-forced="theme"
          :with-icon="true"
          :src="image"
          :provider="imageProvider"
          :width="width"
          :height="height"
          class="image"
        />
        <BadgeSource
          v-if="!!logo && showIcon"
          :src="logo"
          :width="48"
          :height="32"
          :theme-forced="theme"
        />
        <ButtonBookmark
          v-if="isInFavorites !== undefined"
          ref="bookmark"
          size="small"
          :theme-forced="theme"
          :active="isInFavorites"
          @click.stop.prevent.native="$emit('toggleFavorite')"
        />
        <div class="info-wrap">
          <div class="info">
            <div class="ratings">
              <div v-if="ratingKinopoiskFormatted" class="rating">
                <ImageWithHash
                  src="/public/images/icons/kinopoisk.svg"
                  alt="Kinopoisk"
                  :title="getTranslation('kinopoisk')"
                />
                <span v-text="ratingKinopoiskFormatted" />
              </div>
              <div v-if="ratingImdbFormatted" class="rating">
                <ImageWithHash src="/public/images/icons/imdb.svg" title="IMDb" alt="IMDb" />
                <span v-text="ratingImdbFormatted" />
              </div>
            </div>
            <div v-if="description" class="description hidden-size-small" v-text="description" />
            <div
              v-if="isPortrait && durationFormatted"
              class="duration"
              v-text="durationFormatted"
            />
          </div>
        </div>
      </div>

      <TileTitle v-if="title" :title="title" />
      <TileDescription
        v-if="originalTitle"
        class="hidden-size-small"
        :description="originalTitle"
      />
      <TileDescription
        v-if="metaDescription"
        class="hidden-size-large"
        :description="metaDescription"
      />
    </div>
  </a>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import TileTitle from 'src/components/ui/tiles/parts/TileTitle.vue';
import TileDescription from 'src/components/ui/tiles/parts/TileDescription.vue';
import ButtonBookmark from 'src/components/ui/buttons/ButtonBookmark.vue';
import IconSVG from 'src/components/IconSVG.vue';
import { convertToMinutes } from 'src/utils/time/convert-time';
import LazyImage from 'src/components/LazyImage.vue';
import { BRANDING_METHODS_OPTIONS_ID } from 'src/constants';
import { actions } from 'src/store/actions';
import { TBrandingMethodOption } from 'src/store/branding-methods/types';
import { VODCountry } from 'src/models/ts/vod/v2/vod';
import BadgeSource from 'src/components/ui/BadgeSource.vue';
import { selectors } from 'src/store/selectors';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: {
    ImageWithHash,
    BadgeSource,
    LazyImage,
    TileTitle,
    TileDescription,
    IconSVG,
    ButtonBookmark,
  },
})
export default class TilePoster extends SequoiaComponent {
  @Prop({ default: false })
  disableHover!: boolean;

  @Prop({ default: 200 }) //  default max poster width ~200px
  width!: number;

  @Prop({ required: true })
  widthProportion!: number;

  @Prop({ required: true })
  heightProportion!: number;

  @Prop()
  image?: string;

  @Prop()
  imageProvider?: string;

  @Prop()
  logo?: string;

  @Prop({ default: undefined })
  isInFavorites?: boolean;

  @Prop()
  ratingImdb?: number;

  @Prop()
  ratingKinopoisk?: number;

  @Prop()
  title!: string;

  @Prop()
  originalTitle?: string;

  @Prop()
  link?: string;

  @Prop()
  years?: Array<number>;

  @Prop()
  countries?: VODCountry[];

  @Prop()
  genres?: Array<{ id: string; title: string }>;

  @Prop()
  duration?: number;

  @Prop()
  large?: boolean;

  @Prop()
  titleBrandingMethods?: Array<{ name: string; priority: number }>;

  @Prop()
  source?: string;

  @Watch('aspectRatio')
  actualizeAspectRatio() {
    if (this.refTilePoster) {
      const padding = `${this.aspectRatio * 100}%`;
      this.refTilePoster.style.setProperty('--tile-poster-aspect-ratio-padding', padding);
    }
  }

  @Ref('tilePoster')
  readonly refTilePoster?: HTMLDivElement;

  @Ref('tilePosterImage')
  readonly refTilePosterImage?: HTMLDivElement;

  brandingOptions: TBrandingMethodOption[] = [];

  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get isPortrait() {
    return this.orientation === 'portrait';
  }

  get isModalTitleOpen() {
    return selectors.vod.isModalTitleOpenSelector(this.$store);
  }

  get hasOverlay() {
    return (
      this.ratingImdbFormatted ||
      this.ratingKinopoiskFormatted ||
      this.duration ||
      (this.description && this.isPortrait)
    );
  }

  get showOverlays() {
    return (
      this.isInFavorites !== undefined ||
      this.ratingImdbFormatted ||
      this.ratingKinopoiskFormatted ||
      this.duration ||
      (this.description && this.isPortrait)
    );
  }

  get durationFormatted() {
    return this.duration && this.duration > 0
      ? `${Math.round(convertToMinutes(this.duration, 'second'))} мин`
      : '';
  }

  get description() {
    const description = [];
    if (this.years && this.years.length > 0) {
      description.push(this.joinInfoYears(this.years));
    }

    if (this.countries && this.countries.length > 0) {
      description.push(this.countries[0].title);
    }

    if (this.genres && this.genres.length > 0) {
      description.push(this.genres[0].title);
    }
    return description.length ? description.join(', ') : '';
  }

  get metaDescription() {
    if (this.title) {
      if (this.title === this.originalTitle) {
        return this.description;
      } else if (this.originalTitle) {
        return `${this.originalTitle} ${this.description ? '• ' + this.description : ''}`;
      } else {
        return this.description;
      }
    }
  }

  get ratingImdbFormatted() {
    return this.ratingImdb ? this.ratingImdb.toFixed(1) : '';
  }

  get ratingKinopoiskFormatted() {
    return this.ratingKinopoisk ? this.ratingKinopoisk.toFixed(1) : '';
  }

  get aspectRatio() {
    return this.heightProportion / this.widthProportion;
  }

  get orientation() {
    return this.aspectRatio < 1 ? 'landscape' : 'portrait';
  }

  get showIcon() {
    if (this.source === 'archive') {
      return true;
    }

    return this.brandingOptions?.find(
      (option) => option.id === BRANDING_METHODS_OPTIONS_ID.showIcon
    )?.used;
  }

  get height() {
    return Math.round(this.width * this.aspectRatio);
  }

  created() {
    this.brandingOptions =
      actions.brandingMethods.getCurrentBrandingMethods(
        this.$store,
        this.titleBrandingMethods || []
      ) || [];
  }

  mounted() {
    this.actualizeAspectRatio();
  }

  joinInfoYears(arr: number[]) {
    const len = arr.length;
    return len > 1 ? arr[0] : arr.toString();
  }

  openTitle() {
    this.$emit('click');
    if (this.link && !this.isModalTitleOpen) {
      this.$router.push(this.link);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/overlays';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';
@import 'src/styles/placeholders-and-mixins/bookmark-indicator';

// stylelint-disable property-no-vendor-prefix

$transition: all var(--ease-in-out) 0.25s;

// small and large sizes
// https://zeroheight.com/4bd47b4a7/p/11dde8-tileposter/t/15f369
$size-small: '(max-width: #{$desktop-s-max})'; // 1279px
$size-large: '(min-width: #{$desktop-m-min})'; // 1280px

.tile-poster {
  --tile-poster-aspect-ratio-padding: '';

  position: relative;
  display: block;
  flex-shrink: 0;
  cursor: pointer;
  transform: perspective(1000px);
  -webkit-appearance: initial;
  backface-visibility: hidden;

  &.landscape {
    &:not(.large) {
      .duration,
      .hidden-size-small {
        display: none;
      }
    }

    &.large .hidden-size-large {
      @media #{$size-large} {
        display: none;
      }
    }

    &.bookmarkless {
      .overlay-default-before {
        &::before {
          @include mobile-and-tablet {
            display: none;
          }
        }
      }
    }

    @include mobile-and-tablet {
      .overlay-default-before {
        @include overlay-color-before(var(--c-overlay-lvl-60));

        &::before {
          background-image: none;
        }
      }
    }

    .image-container {
      .info-wrap .info {
        padding: 12px;

        .ratings {
          @include heading6;

          margin-bottom: 4px;

          @media #{$size-small} {
            font-size: 1.125rem;
          }

          @include mobile-and-tablet {
            display: none;
          }

          .rating {
            flex-direction: row;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              margin-right: 4px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &.portrait {
    .tile-description.hidden-size-large {
      @media #{$size-large} {
        display: none;
      }
    }
  }

  .inner {
    display: block;
    width: 100%;
  }

  @include devices-with-hover {
    &:hover {
      .image-container {
        transform: translateY(-8px);

        &::before,
        &::after {
          opacity: 1;
        }
      }

      @include bookmark-indicator;

      .info-wrap {
        opacity: 1;
      }
    }

    .overlay-default-before {
      @include overlay-gradient-before(
        linear-gradient(-180deg, rgba(0, 0, 0, 0.3) 0%, rgb(0, 0, 0) 100%)
      );
    }

    @media #{$size-small} {
      &.no-overlay:hover .image-container {
        &::before,
        &::after {
          opacity: 0;
        }
      }
    }
  }

  .hidden-size-small {
    @media #{$size-small} {
      display: none;
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    padding-top: var(--tile-poster-aspect-ratio-padding);
    cursor: pointer;
    border-radius: 4px;
    transition: 0.25s all var(--ease-in-out);

    &::before,
    &::after {
      border-radius: 4px;
      opacity: 0;
      transition: 0.25s opacity var(--ease-in-out);
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .badge-source {
    position: absolute;
    top: 8px;
    left: -8px;
    z-index: var(--z-3);
    width: 48px;
    height: 32px;

    @include mobile-and-tablet {
      left: -4px;
    }
  }

  .bookmark {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: var(--z-3);
    display: block;
    pointer-events: none;
    opacity: 0;
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    border: 0;
  }

  .info-wrap {
    position: absolute;
    top: 0;
    z-index: var(--z-1);
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    opacity: 0;
    transition: $transition;

    .info {
      position: absolute;
      bottom: 0;
      padding: 16px;
      color: var(--c-dark-font-primary);

      @media #{$size-small} {
        padding: 12px;
      }

      @media #{$size-large} {
        padding: 16px;
      }

      > div:last-child {
        margin-bottom: 0;
      }

      .ratings {
        display: flex;
        margin-bottom: 8px;
        @extend %heading4;

        @media #{$size-small} {
          @include heading6;
        }

        .rating {
          display: flex;
          flex-direction: column;

          @media #{$size-large} {
            flex-direction: column;
            align-items: initial;
          }

          + .rating {
            margin-left: 16px;
          }

          img {
            width: 24px;
            height: 24px;
            margin-bottom: 6px;

            @media #{$size-large} {
              margin-right: 0;
              margin-bottom: 6px;
            }
          }
        }
      }

      .description {
        margin-bottom: 4px;
        @extend %body2;
      }

      .duration {
        @extend %caption2;
      }
    }
  }

  .tile-title {
    @media #{$size-small} {
      margin-top: 8px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    @include devices-with-hover {
      &:hover {
        .image-container {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }

    .image-container {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &.dark {
    @include devices-with-hover {
      &:hover {
        .image-container {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }

    .image-container {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
