import {
  convertToDays,
  convertToHours,
  convertToMilliseconds,
  convertToSeconds,
} from 'src/utils/time/convert-time';

const months = {
  ru: [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ],
};

const minutesInHour = 60;
const fullDigitsCount = 2;

export class DateTime {
  static getHM(date = new Date()) {
    const hours = `${date.getHours()}`;
    const minutes = `${date.getMinutes()}`;

    return hours.padStart(fullDigitsCount, '0') + ':' + minutes.padStart(fullDigitsCount, '0');
  }

  static getUTCHMFromEpoch(epoch = convertToSeconds(Date.now(), 'millisecond')) {
    return DateTime.getHM(new Date(convertToMilliseconds(epoch, 'second')));
  }

  static getDMonth(date = new Date()) {
    const day = String(date.getDate());
    const month = `${date.getMonth() + 1}`;

    return `${day.padStart(fullDigitsCount, '0')}.${month.padStart(fullDigitsCount, '0')}`;
  }

  static getDFullMonth(date = new Date()) {
    const day = date.getDate();

    return `${day} ${months['ru'][date.getMonth()]}`;
  }

  static toEpochDay(date = new Date()) {
    return Math.ceil(
      convertToDays(
        new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime(),
        'millisecond'
      )
    );
    // return Math.ceil(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) / 86400000)
  }

  static toEpochDayFromEpoch(epoch = convertToSeconds(Date.now(), 'millisecond')) {
    return Math.floor(convertToDays(epoch, 'second'));
  }

  static fromEpochDay(epochDay = DateTime.toEpochDay(new Date())) {
    const d = new Date(convertToMilliseconds(epochDay, 'day'));
    d.setHours(0);
    return d;
  }

  static prettyTimeHMS(offset: number, showHoursEvenIfLessThan?: boolean) {
    const time = Math.floor(Math.max(offset, 0));

    const hours = Math.floor(convertToHours(time, 'second'));
    const minutes = Math.floor(time / minutesInHour) % minutesInHour;
    const seconds = time % minutesInHour;

    return (
      (showHoursEvenIfLessThan || hours ? `${String(hours).padStart(fullDigitsCount, '0')}:` : '') +
      `${String(minutes).padStart(fullDigitsCount, '0')}:${String(seconds).padStart(
        fullDigitsCount,
        '0'
      )}`
    );
  }

  // playingCurrentTime in seconds
  // duration in seconds
  static prettifyPlayerTime(playingCurrentTime: number, duration: number) {
    return (
      DateTime.prettyTimeHMS(playingCurrentTime, duration > convertToSeconds(1, 'hour')) +
      ' / ' +
      DateTime.prettyTimeHMS(duration)
    );
  }
}
