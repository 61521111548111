<template>
  <div
    class="text-only"
    :data-ctype="$options.name"
    :data-cname="componentName"
    v-html="textValue"
  />
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';

@Component
export default class TextOnly extends mixins(Global, CmsComponent) {
  content?: {
    contentBlock?: { [language: string]: string };
  } | null = null;

  get textValue() {
    if (!this.content) return '';
    return this.getByLanguage(this.content.contentBlock);
  }
}
</script>

<style lang="scss" scoped>
.text-only {
  img {
    display: inline-block;
  }

  &::v-deep {
    ul {
      margin-bottom: 16px;
    }

    h2,
    h3,
    h4,
    h5,
    h6,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      margin-top: 16px;
    }

    p {
      margin-bottom: 16px;

      + h2,
      + h3,
      + h4,
      + h5,
      + h6,
      + .h2,
      + .h3,
      + .h4,
      + .h5,
      + .h6 {
        margin-top: 32px;
      }
    }
  }
}
</style>
