import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, Method } from 'axios';
import querystring from 'querystring';
import { URLS, COOKIE_NAMES, ENABLE_CYPRESS_MOCK, HTTP_CODES } from 'src/constants';
import { cookieStore } from 'src/utils/storage';
import { getAppVersionParamsFlattened } from 'src/utils/app-version';
import { getEnvVars } from 'src/config';
import { getLogger } from 'lightlog';
import { store } from 'src/store';

const log = getLogger('api-utils');

interface TCommonConfig extends AxiosRequestConfig {
  isFe?: boolean;
  isBe?: boolean;
  withSession?: boolean;
  withAppVersion?: boolean;
  formUrlEncoded?: boolean;
  verbose?: boolean;
}

export interface TReqConfig extends AxiosRequestConfig {
  // data?: {
  //   sourceId: string;
  //   titleId: string;
  //   mediaItemId: string;
  //   msecFromStart: number;
  //   totalDurationMsec: number;
  // };
  jsonData?: true;
  bowProxyDisabled?: boolean;
  withSession?: boolean;
  disabledEtag?: boolean;
}

const createRequest = (instance: AxiosInstance, commonConfig: TCommonConfig = {}) => ({
  get: requestFactory(instance, 'get', commonConfig),
  head: requestFactory(instance, 'head', commonConfig),
  delete: requestFactory(instance, 'delete', commonConfig),
  post: requestFactory(instance, 'post', commonConfig),
  put: requestFactory(instance, 'put', commonConfig),
  patch: requestFactory(instance, 'patch', commonConfig),
});

const requestFactory =
  (instance: AxiosInstance, method: Method, commonConfig: TCommonConfig) =>
  async (url: string, reqConfig: TReqConfig = {}) => {
    if (commonConfig.formUrlEncoded && !reqConfig.jsonData) {
      reqConfig.data = querystring.stringify(reqConfig.data);
    }

    reqConfig.headers = reqConfig.headers || {};
    reqConfig.headers['Accept-Language'] = store.languageCode || global.languageCode || '';

    if (reqConfig.jsonData) {
      reqConfig.headers['content-type'] = 'application/json';
    }

    // disabled because of https://lifestream.atlassian.net/browse/SEQ-2538
    // toDo investigate how it works with nginx cache
    // if (
    //   process.env.VUE_ENV === 'server' &&
    //   global.eTags[url] &&
    //   commonConfig.isBe &&
    //   !reqConfig?.disabledEtag
    // ) {
    //   reqConfig.headers['If-None-Match'] = global.eTags[url];
    // }

    const config = { url, method, params: {}, data: {}, ...commonConfig, ...reqConfig };

    if (commonConfig.isFe) {
      config.baseURL = getEnvVars().feBaseUrl;
    } else if (commonConfig.isBe) {
      config.baseURL = getEnvVars().beBaseUrl;
    }

    if (!config.bowProxyDisabled && config.params.token) {
      config.url = URLS.bow.proxy + url;
    } else if (config.withSession) {
      const feCookie =
        process.env.VUE_ENV === 'server'
          ? global.feCookie
          : (cookieStore.get(COOKIE_NAMES.feCookie) as null | Record<string, any>);
      config.params.session = feCookie?.session || undefined;
    }

    if (config.withAppVersion) {
      config.params = { ...config.params, ...getAppVersionParamsFlattened() };
    }

    if (ENABLE_CYPRESS_MOCK) {
      const cypressTestId = store.cypress.testId || global.cypressTestId;
      if (cypressTestId) {
        config.params = { ...config.params, 'cypress-test-id': cypressTestId };
      }
    }

    try {
      const result = await instance(config);

      // disabled because of https://lifestream.atlassian.net/browse/SEQ-2538
      // toDo investigate how it works with nginx cache
      // if (process.env.VUE_ENV === 'server' && result.headers.etag) {
      //   global.eTags[url] = result.headers.etag;
      // }

      if (config.verbose) {
        log.info(url, result.request.responseURL, result.status, result.data);
      }
      return result.data;
    } catch (err) {
      if (err.response?.status !== HTTP_CODES.NOT_MODIFIED) {
        const urlObj = new URL((config.baseURL || instance.defaults.baseURL) + url);
        errorHandler(err, config, urlObj.origin + urlObj.pathname);
      }
    }
  };

interface TError extends Error {
  info?: {
    url: string;
    data: any;
    headers: any;
    timeout: boolean;
    status?: number;
    code?: string;
  };
}

export const errorHandler = (err: AxiosError, config: TCommonConfig, url: string) => {
  if (config.verbose) {
    log.error(err?.request?.responseURL || `URL is ${url}`, err.message, err.stack);
  }

  const timeout = err?.code === 'ECONNABORTED';

  let errorMessage = `${err?.code} on request to ${url}`;
  if (timeout) {
    errorMessage = `Request timeout to ${url}`;
    if (err.config?.timeout) {
      errorMessage += ` after ${err.config.timeout}ms`;
    }
  } else if (err?.response?.status) {
    errorMessage = `Network error on request to ${url}, status ${err.response.status}`;
  }

  const error: TError = new Error(errorMessage);

  Object.assign(error, err?.response, err?.response?.data);

  error.info = {
    url,
    data: err?.response?.data,
    status: err?.response?.status,
    headers: err?.response?.headers,
    timeout,
    code: err?.code,
    ...error.info,
  };

  throw error;
};

const createNetworkAdapter = (config: AxiosRequestConfig) => axios.create(config);

const defaultTimeout = 10000; // 10 seconds
const feServerTimeout = 3500; // 3.5 seconds
const feClientTimeout = 20000; // 20 seconds

// local requests
const origin =
  typeof window === 'undefined' ? `http://127.0.0.1:${process.env.PORT}` : window.location.origin;

const siteInstance = createNetworkAdapter({
  baseURL: origin,
  timeout: defaultTimeout,
  withCredentials: true,
});

const site = createRequest(siteInstance);

// fe
const feInstance = createNetworkAdapter({
  timeout: typeof window === 'undefined' ? feServerTimeout : feClientTimeout,
  withCredentials: true,
});

const fe = createRequest(feInstance, {
  isFe: true,
  formUrlEncoded: true,
  withSession: true,
  withAppVersion: true,
});

// be
const beInstance = createNetworkAdapter({
  timeout: defaultTimeout,
  withCredentials: true,
});

const be = createRequest(beInstance, {
  isBe: true,
});

// ivi
const iviLightInstance = createNetworkAdapter({
  baseURL: ENABLE_CYPRESS_MOCK ? URLS.vod.ivi.lightServerMock : URLS.vod.ivi.lightServer,
  timeout: defaultTimeout,
  withCredentials: false,
});

const iviLight = createRequest(iviLightInstance);

const stringifyQuery = (params: { [key: string]: string }) => querystring.stringify(params);

const feOldInstance = createNetworkAdapter({
  baseURL: URLS.oldSmotreshkaPremiumPage,
  timeout: defaultTimeout,
  withCredentials: false,
});

const oldFe = createRequest(feOldInstance, {
  formUrlEncoded: true,
});

export { site, fe, be, iviLight, oldFe, stringifyQuery };
