import Vue from 'vue';
import VueEvents from 'vue-events';
import debounce from 'lodash/debounce';
import {
  EVENTS,
  HTTP_CODES,
  NOTIFICATION_SIMPLE_HIDE_TIMEOUT,
  PLAYER_ERROR_CODES,
  STORE_KEYS,
  THRESHOLDS,
  TV_SPECIAL_GENRES,
} from 'src/constants';
import { storage, wlDetector } from 'src/utils';
import { getByLanguage } from 'src/utils/language';
import { cookieStore, localStore } from 'src/utils/storage';
import { getChannelById } from 'src/utils/epg';
import * as api from 'src/api/index';
import { TStore } from 'src/store/types';
import {
  clearError,
  expandPlayer,
  goLive,
  lockScroll,
  resetPoster,
  setAutoplay,
  setError,
  setIsLive,
  setPickedEpochDay,
  setPushHistoryStateForChannel,
  showPlayer,
  updatePlayingTime,
} from 'src/store/player/actions';
import { loadEpgForCurrentChannel } from 'src/store/tv-epg/actions';
import {
  allChannelsSelector,
  channelByIdSelector,
  channelsRecentAndFavLoadedSelector,
  channelsRecentAndFavLoadingSelector,
  channelsLoadedSelector,
  channelsLoadingSelector,
  channelVitrinaSelector,
  filteredChannelsSelector,
  isChannelsCatalogNowOpenSelector,
  isChannelTabAboutOpenSelector,
  recentlyWatchedSelector,
  favoritesSelector,
} from 'src/store/tv-channels/selectors';
import {
  currentChannelIdSelector,
  currentChannelSelector,
  currentLanguageSelector,
  defaultRenditionIndexSelector,
  languagesSelector,
} from 'src/store/tv-current-channel/selectors';
import logger from 'src/utils/logger';
import { getRecentAndFavChannels, getRecommendedChannels } from 'src/api/channels';
import { setMetaTitle } from 'src/store/seo/actions';
import { languageCodeSelector, tvAssetTokenValueSelector } from 'src/store/common/selectors';
import { loadAssetTokens } from 'src/api/tokens';
import {
  hideModal,
  saveAssetTokens,
  showModal,
  showNotificationAuthAndReg,
} from 'src/store/common/actions';
import { addChannelsToChannelCollections } from 'src/store/channel-collections/actions';
import { scrollEverythingToTop } from 'src/utils/scroll-everything-to-top';
import { isAnonymousSelector } from 'src/store/account/selectors';
import { handleForTv } from 'src/store/quick-subscribe/actions';
import { DateTime } from 'src/utils/time/date-time';
import { translationSelector } from 'src/store/translations/selectors';
import { getChannelTitle } from 'src/utils/channel';
import {
  currentProgramSelector,
  isDvrDisabledSelector,
  isDvrRestrictedSelector,
} from 'src/store/tv-epg/selectors';
import { isVideoScaledSelector } from 'src/store/player/selectors';
import { TPlayerErrorCodes } from 'src/store/player/types';
import { loadChannelsNotForPurchase } from 'src/utils/channels';
import { deleteFromObject } from 'src/utils/object';
import { gaEvent } from 'src/utils/metrics';
import { gaParamsSelector } from 'src/store/metrics/selectors';
import { makePath } from 'src/utils/url';

const log = logger('tv-channels');

const specialGenresList = [
  { id: '', title: TV_SPECIAL_GENRES.all },
  { id: '', title: TV_SPECIAL_GENRES.available },
  { id: '', title: TV_SPECIAL_GENRES.favourite },
  { id: '', title: TV_SPECIAL_GENRES.recentlyWatched },
  { id: '', title: TV_SPECIAL_GENRES.recommended },
];

export const getChannelsNotForPurchase = async (
  store: TStore,
  feCookie = cookieStore.get('feCookie')
) => {
  const channelsNotForPurchase = await loadChannelsNotForPurchase(
    process.env.VUE_ENV === 'server' ? global.feCookie : feCookie
  );
  Vue.set(store.tvChannels, 'channelsNotForPurchase', channelsNotForPurchase);
};

export const loadTvData = async (
  store: TStore,
  session = '',
  feCookie = cookieStore.get('feCookie')
) => {
  if (store.flags.tvDataState.loaded) {
    log.info('TV data has already been loaded');
    return;
  }

  if (store.flags.tvDataState.loading) {
    log.warning('TV data is already loading right now...');
    return;
  }

  store.flags.tvDataState.loading = true;
  setChannelsLoading(store, true);

  if (!tvAssetTokenValueSelector(store) && wlDetector().isSmotreshka) {
    await saveAssetTokens(store, await loadAssetTokens());
  }

  await getChannelsNotForPurchase(store, feCookie);

  const { genresResponse, channelsResponse, recommendedChannelsResponse } =
    await api.channels.getTvData(
      !!store.siteConfig?.tv?.shouldUseTvApiV2,
      session,
      tvAssetTokenValueSelector(store)
    );

  Vue.set(store.tvChannels, 'genres', [...specialGenresList, ...genresResponse]);
  Vue.set(store.tvChannels, 'list', channelsResponse);
  Vue.set(store.tvChannels, 'recommended', recommendedChannelsResponse);

  store.flags.tvDataState.loading = false;
  store.flags.tvDataState.loaded = true;
  setChannelsLoading(store, false);

  log.info('TV data has been loaded successfully');

  await addChannelsToChannelCollections(store);
};

export const setChannelsLoaded = (store: TStore, val: boolean) => (store.tvChannels.loaded = val);
export const setChannelsLoading = (store: TStore, val: boolean) => (store.tvChannels.loading = val);
export const setChannelsRecentAndFavLoaded = (store: TStore, val: boolean) =>
  (store.tvChannels.recentAndFavLoaded = val);
export const setChannelsRecentAndFavLoading = (store: TStore, val: boolean) =>
  (store.tvChannels.recentAndFavLoading = val);

export const loadChannelsAndGenres = async (store: TStore, session = '') => {
  if (channelsLoadedSelector(store) || channelsLoadingSelector(store)) {
    return;
  }

  setChannelsLoading(store, true);

  if (!tvAssetTokenValueSelector(store) && wlDetector().isSmotreshka) {
    await saveAssetTokens(store, await loadAssetTokens());
  }

  const { channels, genres } = await api.channels
    .getChannels(!!store.siteConfig?.tv?.shouldUseTvApiV2, {
      params: { session },
      tvAssetToken: tvAssetTokenValueSelector(store),
    })
    .then((channels) => {
      setChannelsLoaded(store, true);
      return channels;
    })
    .catch((e) => {
      log.error(e);
      return { channels: {}, genres: [] };
    })
    .finally(() => {
      setChannelsLoading(store, false);
    });

  Vue.set(store.tvChannels, 'list', channels);
  log.info('TV channels have been successfully loaded');

  if (genres.length) {
    Vue.set(store.tvChannels, 'genres', [...genres, ...specialGenresList]);
    log.info('TV genres have been successfully loaded');
  }
};

export const resetChannels = (store: TStore) => {
  Vue.set(store.tvChannels, 'list', {});
};

export const loadRecentAndFavChannels = async (store: TStore, session = '') => {
  if (channelsRecentAndFavLoadedSelector(store) || channelsRecentAndFavLoadingSelector(store)) {
    return;
  }

  setChannelsRecentAndFavLoading(store, true);
  const sessionSuffix = session ? `?session=${session}` : '';
  const recentAndFavResponse = await getRecentAndFavChannels(sessionSuffix);
  Vue.set(store.tvChannels, 'favorites', recentAndFavResponse?.favouriteChannels || {});
  Vue.set(store.tvChannels, 'recentlyWatched', recentAndFavResponse?.recentlyWatchedChannels || {});
  setChannelsRecentAndFavLoading(store, false);
  setChannelsRecentAndFavLoaded(store, true);
  log.info('Recent and favorite channels were successfully loaded');
};

export const resetRecentAndFavChannels = (store: TStore) => {
  setChannelsRecentAndFavLoaded(store, false);
  setChannelsRecentAndFavLoading(store, false);
  Vue.set(store.tvChannels, 'favorites', {});
  Vue.set(store.tvChannels, 'recentlyWatched', {});
};

export const loadRecommendedChannels = async (store: TStore) => {
  const recommendedChannelsResponse = await getRecommendedChannels();
  Vue.set(store.tvChannels, 'recommended', recommendedChannelsResponse || {});
};

export const resetRecommendedChannels = (store: TStore) => {
  Vue.set(store.tvChannels, 'recommended', {});
};

const updateRecentlyWatched = debounce(
  (store: TStore, channelId: string) => {
    const recentlyWatchedChannels = recentlyWatchedSelector(store);

    if (!!recentlyWatchedChannels[channelId]) {
      // if channel was found among recently watched, delete it
      deleteFromObject(channelId, recentlyWatchedChannels);
      Vue.set(store.tvChannels, 'recentlyWatched', recentlyWatchedChannels);
    }

    // and then add it back to the first position
    Vue.set(store.tvChannels, 'recentlyWatched', {
      [channelId]: getChannelById(allChannelsSelector(store), channelId),
      ...recentlyWatchedChannels,
    });
  },
  THRESHOLDS.tv.channelSwitchDelay,
  { leading: false, trailing: true }
);

export const loadPlaybackInfoDetails = async (
  store: TStore,
  channel = currentChannelSelector(store),
  enable50fps = true
) => {
  if (!(Object.keys(store.tvChannels.list)?.length && channel)) {
    log.error(`Unable to load playbackInfo details: invalid channel #${channel?.id}`, channel);
    return;
  }

  log.info('loading playbackInfo details for channel', channel?.id);

  const playbackInfoDetails = await api.channels
    .getPlaybackInfo(channel?.id, enable50fps)
    .catch((err) => {
      let errorCode = PLAYER_ERROR_CODES.COMMON;
      log.error(err);
      if (
        err.status === HTTP_CODES.FORBIDDEN ||
        err.status === HTTP_CODES.NOT_ALLOWED_FOR_LOCATION
      ) {
        errorCode = PLAYER_ERROR_CODES.PROVIDER_ERROR;
      }
      setError(
        store,
        errorCode as TPlayerErrorCodes,
        err.data?.msg ||
          getByLanguage(store.translations['error_empty_response'], languageCodeSelector(store))
      );
      log.warning('playbackInfo details have not been loaded', playbackInfoDetails);
      throw err;
    });

  if (playbackInfoDetails) {
    clearError(store);
    log.info('playbackInfo details have been loaded', playbackInfoDetails);
  }

  if (!Array.isArray(playbackInfoDetails?.languages) || !playbackInfoDetails?.languages?.length) {
    log.error('Invalid playbackInfo: no languages were found');
  }

  if (store.siteConfig?.tv?.shouldUseTvApiV2) {
    if (store.tvChannels?.list?.[channel?.id]) {
      const info = {
        playbackInfo: {
          languageIndex: -1,
          renditionIndex: -1,
          details: playbackInfoDetails,
        },
      };

      Vue.set(store.tvChannels.list[channel.id], 'info', info);
    }
  }

  const playbackInfoFromStore = store.tvChannels?.list?.[channel?.id]?.info?.playbackInfo;

  if (playbackInfoFromStore) {
    Vue.set(playbackInfoFromStore, 'details', playbackInfoDetails);
    Vue.set(playbackInfoFromStore, 'languageIndex', -1);
    Vue.set(playbackInfoFromStore, 'renditionIndex', -1);
  }

  if (
    store.tvCurrentChannel?.info?.playbackInfo &&
    channel?.id === currentChannelIdSelector(store)
  ) {
    Vue.set(store.tvCurrentChannel.info.playbackInfo, 'details', playbackInfoDetails);
    Vue.set(store.tvCurrentChannel.info.playbackInfo, 'languageIndex', -1);
    Vue.set(store.tvCurrentChannel.info.playbackInfo, 'renditionIndex', -1);
  }

  if (!isDvrDisabledSelector(store, channel) && isDvrRestrictedSelector(store, channel)) {
    // when DVR is restricted (but not disabled) - we have to fetch dvrAvailability to get details of the restriction and show the error message
    log.warning('DVR is restricted for channel', channel?.id, 'Loading dvrAvailability...');
    const dvrAvailability = await api.channels.getDvrAvailability(channel?.id || '');
    log.info('dvrAvailability has been loaded', dvrAvailability);

    if (playbackInfoFromStore) {
      Vue.set(playbackInfoFromStore, 'dvrAvailability', dvrAvailability);
    }

    if (
      store.tvCurrentChannel?.info?.playbackInfo &&
      channel?.id === currentChannelIdSelector(store)
    ) {
      Vue.set(store.tvCurrentChannel.info.playbackInfo, 'dvrAvailability', dvrAvailability);
    }
  }

  // restore language, rendition & video scaled settings
  restoreLanguageSettings(store, channel);
  restoreChannelSettings(store, channel);

  log.info('loadPlaybackInfoDetails – DVR status', {
    isDvrDisabled: isDvrDisabledSelector(store),
    isDvrRestricted: isDvrRestrictedSelector(store),
  });
};

export const restoreLanguageSettings = (store: TStore, channel = currentChannelSelector(store)) => {
  const languages = languagesSelector(store);
  const savedLanguageId = storage.get(STORE_KEYS.player.language);

  if (!!channel?.info?.playbackInfo) {
    let languageIndex = languages.findIndex(
      (language: { id: any }) => language.id === savedLanguageId
    );

    if (languageIndex < 0) {
      languageIndex = languages.findIndex((language: { default: any }) => language.default);
    }

    const playbackInfo = store.tvChannels?.list?.[channel?.id]?.info?.playbackInfo;
    if (playbackInfo) {
      Vue.set(playbackInfo, 'languageIndex', -1);
    }

    if (
      store.tvCurrentChannel?.info?.playbackInfo &&
      channel?.id === currentChannelIdSelector(store)
    ) {
      Vue.set(store.tvCurrentChannel.info.playbackInfo, 'languageIndex', languageIndex);
    }

    log.info('restoreLanguageSettings', savedLanguageId, 'languageIndex', languageIndex);
  }
};

export const restoreChannelSettings = (store: TStore, channel = currentChannelSelector(store)) => {
  if (!channel?.id) {
    log.warning('channel ID was not passed');
    return;
  }

  const allChannelsSettings: Record<string, any> =
    storage.get(STORE_KEYS.player.channelsSettings) || {};
  const settings = allChannelsSettings[channel.id] || {};
  const languages = languagesSelector(store);

  if (!languages.length) {
    return log.error('Languages have not been loaded. Has loadPlaybackInfoDetails been called?');
  }

  const currentLanguage = currentLanguageSelector(store);
  let renditionIndex = defaultRenditionIndexSelector(store);

  const renditions = currentLanguage.renditions;

  if (renditions && settings.renditionIndex && renditions[settings.renditionIndex]) {
    renditionIndex = settings.renditionIndex;
  }

  const playbackInfo = store.tvChannels?.list?.[channel?.id]?.info?.playbackInfo;
  if (playbackInfo) {
    Vue.set(playbackInfo, 'renditionIndex', -1);
  }

  if (!!store.tvCurrentChannel?.info?.playbackInfo) {
    store.player.video.scaled = !!settings.hideBlackStripes;
    Vue.set(store.tvCurrentChannel.info.playbackInfo, 'renditionIndex', renditionIndex);
  }

  log.info('restoreChannelSettings', {
    renditionIndex,
    expand: isVideoScaledSelector(store),
  });
};

export const selectChannel = async (
  store: TStore,
  channelId: string,
  $events: VueEvents,
  shouldAddToRecentlyWatched = true,
  forced = false,
  timestamp = 0 // needed for playing programs from archive
) => {
  if (!channelId) {
    return log.error('channelId was not provided');
  }

  store.QS.wasChannelSelected = true;
  const channels = allChannelsSelector(store);

  if (!Object.keys(channels)?.length) {
    log.error('There are no channels! Load channels first!');
    return;
  }
  const channel = getChannelById(channels, channelId);

  if (!channelVitrinaSelector(store, channel) && isAnonymousSelector(store)) {
    showNotificationAuthAndReg(store);
    return;
  }

  setAutoplay(store, true);

  const currentChannelId = currentChannelIdSelector(store);
  const channelChanged = currentChannelId !== channelId;
  const currentProgram = currentProgramSelector(store);
  const currentProgramForChannel = currentProgramSelector(store, channelId);
  const programChanged = currentProgram?.id !== currentProgramForChannel?.id;

  if (channelChanged) {
    Vue.set(store, 'tvCurrentChannel', getChannelById(channels, channelId) || null);
  }

  if (!store.tvCurrentChannel) {
    log.warning(`channel was not found by id ${channelId}, redirecting to channels now page`);
    history.replaceState({}, '', makePath('/channels/now'));
    setChannelsCatalogMetaTitle(store);
    return;
  }

  const newChannelId = store.tvCurrentChannel?.id;
  log.info('selectChannel', { id: newChannelId, wasChanged: channelChanged });

  if (shouldAddToRecentlyWatched && newChannelId) {
    updateRecentlyWatched(store, newChannelId);
  }

  if (programChanged) {
    resetPoster(store);
  }

  // TODO set here SEO Meta title for playing CHANNEL in TV

  await loadEpgForCurrentChannel(store);

  log.info(`${STORE_KEYS.channelId}=${newChannelId} was saved to LocalStorage`);
  storage.set(STORE_KEYS.channelId, newChannelId);

  history.pushState(
    {},
    '',
    makePath(
      `/channels/${isChannelsCatalogNowOpenSelector(store) ? 'now' : 'list'}/${newChannelId}/watch`
    )
  );

  setMetaTitle(store, buildChannelMetaTitle(store, newChannelId));
  setPushHistoryStateForChannel(store, true);
  showPlayer(store);
  expandPlayer(store);
  lockScroll(store);
  setIsLive(store, timestamp === 0);

  if (timestamp === 0) {
    goLive(store, $events);
  } else {
    updatePlayingTime(store, timestamp);
    setPickedEpochDay(store, DateTime.toEpochDay(new Date(timestamp || Date.now())));
  }

  $events.emit(EVENTS.channelSelect, {
    channelId: newChannelId,
    channelChanged,
    timestamp,
    forced,
  });

  await handleForTv(store, getChannelById(channels, channelId));
};

export const selectPrevChannel = async (
  store: TStore,
  $events: VueEvents,
  shouldAddToRecentlyWatched = true
) => {
  const currentChannelId = currentChannelIdSelector(store);
  const channels = Object.values(filteredChannelsSelector(store));
  const currentChannelIndex = channels.findIndex((channel) => channel.id === currentChannelId);
  const newChannel = channels[currentChannelIndex - 1];
  if (newChannel) {
    log.info('selectPrevChannel', newChannel.id);
    await selectChannel(store, newChannel.id, $events, shouldAddToRecentlyWatched);
  }
};

export const selectNextChannel = async (
  store: TStore,
  $events: VueEvents,
  shouldAddToRecentlyWatched = true
) => {
  const currentChannelId = currentChannelIdSelector(store);
  const channels = Object.values(filteredChannelsSelector(store));
  const currentChannelIndex = channels.findIndex((channel) => channel.id === currentChannelId);
  const newChannel = channels[currentChannelIndex + 1];
  if (newChannel) {
    log.info('selectNextChannel', newChannel.id);
    await selectChannel(store, newChannel.id, $events, shouldAddToRecentlyWatched);
  }
};

export const selectGenre = (store: TStore, genre: string) => {
  store.tvChannels.currentGenre = genre;
  log.info('selectGenre', genre);
};

export const resetGenre = (store: TStore) => {
  selectGenre(store, TV_SPECIAL_GENRES.recommended);
  localStore.set('genre', TV_SPECIAL_GENRES.recommended);
  log.info('resetGenre to DEFAULT = __RECOMMENDED__');
};

export const toggleChannelFavorite = async (store: TStore, id: string) => {
  const isFav = !!favoritesSelector(store)[id];

  if (isFav) {
    await api.channels.removeFromFavorites(id);
  } else {
    await api.channels.addToFavorites(id);
  }
  gaEvent(
    {
      ...gaParamsSelector(store),
      action: isFav ? 'Удалить из избранного' : 'Добавить в избранное',
      channel_name: id,
    },
    store
  );

  const { favouriteChannels } = await api.channels.getRecentAndFavChannels();

  Vue.set(store.tvChannels, 'favorites', favouriteChannels || []);

  await showFavoriteNotification(store, id, !isFav);
};

export const showFavoriteNotification = async (
  store: TStore,
  channelId: string,
  shouldAddToFav = true
) => {
  let translationId: 'add_to_fav' | 'remove_from_fav' = 'add_to_fav';
  let icon: 'bookmark-on' | 'bookmark-off' = 'bookmark-on';

  if (!shouldAddToFav) {
    translationId = 'remove_from_fav';
    icon = 'bookmark-off';
  }

  const text = `<span>${translationSelector(store, translationId).replace(
    /%channelName%/g,
    getChannelTitle(channelByIdSelector(store, channelId || ''))
  )}</span>`;

  store.notifications.favorite.push({ icon, text });

  setTimeout(() => {
    store.notifications.favorite.shift();
  }, NOTIFICATION_SIMPLE_HIDE_TIMEOUT);
};

// -----------------------------------------------------------------------------------------
// Channels catalog page
// -----------------------------------------------------------------------------------------
export const setIsChannelsCatalogListOpen = (store: TStore, val: boolean) => {
  store.tvChannels.isChannelsCatalogListOpen = val;
};

export const setIsChannelsCatalogNowOpen = (store: TStore, val: boolean) => {
  store.tvChannels.isChannelsCatalogNowOpen = val;
};

// -----------------------------------------------------------------------------------------
// Channel's details: modal, page, etc.
// -----------------------------------------------------------------------------------------

export const setIsPageChannelOpen = (store: TStore, val: boolean) => {
  store.tvChannels.isPageChannelOpen = val;
};

export const setIsChannelTabEpgOpen = (store: TStore, val: boolean) => {
  store.tvChannels.isChannelTabEpgOpen = val;
};

export const setIsChannelTabAboutOpen = (store: TStore, val: boolean) => {
  store.tvChannels.isChannelTabAboutOpen = val;
};

export const setModalChannelId = (store: TStore, openChannelId: string) => {
  store.tvChannels.openChannelId = openChannelId;
};

export const showChannelDetails = async (
  store: TStore,
  channelId: string,
  isModal = true,
  isEpg = false
) => {
  if (!channelId) {
    log.error(`channelId was not passed to showChannelDetails`);
    return;
  }

  setModalChannelId(store, channelId);
  showModal(store);
  store.tvChannels.openChannelIdsHistory.push(channelId);
  store.tvChannels.isModalChannelOpen = true;

  if (isModal) {
    const url = `/channel/${channelId}/${isEpg ? 'program' : 'about'}`;
    history.pushState({}, '', makePath(url));
    setMetaTitle(store, buildChannelMetaTitle(store, channelId));
  }

  if (process.env.VUE_ENV === 'client') {
    scrollEverythingToTop();
  }
};

export const hideChannelDetails = (store: TStore) => {
  setModalChannelId(store, '');
  store.tvChannels.openChannelIdsHistory = [];
  store.tvChannels.isModalChannelOpen = false;
  hideModal(store);
  setChannelsCatalogMetaTitle(store);
};

export const handleModalStepBack = (store: TStore, channelId: string) => {
  popModalChannelIdsHistory(store);
  setModalChannelId(store, channelId);
  setChannelMetaTitle(store, channelId);
};

export const popModalChannelIdsHistory = (store: TStore) => {
  store.tvChannels.openChannelIdsHistory.pop();
};

// ---------------------------------------------------------------------------
// SEO
// ---------------------------------------------------------------------------

// SEO for channel /about & /program
export const setChannelMetaTitle = (store: TStore, channelId: string) => {
  setMetaTitle(store, buildChannelMetaTitle(store, channelId));
};

export const buildChannelMetaTitle = (store: TStore, channelId: string) => {
  const channelMetaTitle = `channel_${
    isChannelTabAboutOpenSelector(store) ? 'about' : 'epg'
  }_meta_title`;
  return (
    getByLanguage(store.translations[channelMetaTitle], store.languageCode) || channelMetaTitle
  ).replace(/%channelName%/g, getChannelTitle(channelByIdSelector(store, channelId)) || '');
};

// SEO for TV catalog /now & /list
export const setChannelsCatalogMetaTitle = (store: TStore) => {
  setMetaTitle(store, buildChannelsCatalogMetaTitle(store));
};

export const buildChannelsCatalogMetaTitle = (store: TStore) => {
  const tvMetaTitle = `tv_${isChannelsCatalogNowOpenSelector(store) ? 'now' : 'list'}_meta_title`;
  return getByLanguage(store.translations[tvMetaTitle], store.languageCode) || tvMetaTitle;
};
