<template>
  <div class="verify-pin-step">
    <div class="h3" v-html="getTranslation('welcome_screen_step_enterpin_title')" />
    <div
      class="body1"
      :class="isPhoneVerified ? 'mb-4' : 'mb-32'"
      v-html="descriptionTranslation"
    />
    <div v-if="isPhoneVerified" class="mb-32">
      <a
        class="link action-colored"
        @click="toggle"
        v-html="sendSmsCodeOrEnterPasswordTranslation"
      />
    </div>

    <InputText
      id="verification-step-code"
      v-model="value"
      :class="bySms ? 'mb-8' : 'mb-32'"
      :icon="bySms ? '' : 'visibility'"
      input-category="floating"
      autocomplete="off"
      required
      :label="getTranslation(bySms ? 'code_from_sms' : 'password')"
      :status="error.input ? 'error' : undefined"
      :message="error.input"
    />

    <div v-if="bySms" class="mb-32">
      <p
        v-if="sendSmsSeconds > 0"
        class="color-light-secondary"
        v-html="sendCodeAgainTranslation"
      />
      <a
        v-else
        class="link action-colored"
        @click="sendSms"
        v-html="getTranslation('adult_modal_send_sms_code_again')"
      />
    </div>
    <p class="body2 color-light-error mt-4" v-text="error.common" />

    <ButtonDefault
      view="primary"
      type="button"
      :disabled="!value"
      :is-loading="isLoading"
      class="mr-16 mt-8"
      @click="next"
    >
      <span v-html="getTranslation('next')" />
    </ButtonDefault>
    <ButtonDefault view="secondary" type="button" @click="cancel">
      <span v-html="getTranslation('cancel')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import * as api from 'src/api';
import sortBy from 'lodash/sortBy';
import { MANAGING_LOGIN_METHODS, RESEND_SMS_CODE_TIMEOUT } from 'src/constants';
import logger from 'src/utils/logger';
import { cleanupPhone } from 'src/utils/phone';

const log = logger('verification-step');

@Component({
  components: { ButtonDefault, InputText },
})
export default class VerificationStep extends SequoiaComponent {
  value = '';
  bySms = false;
  error = {
    common: '',
    input: '',
  };
  isLoading = false;

  sendSmsSeconds = 0;
  sendSmsInterval: NodeJS.Timeout | null = null;

  get isPhoneVerified() {
    return this.$store.account?.is_phone_verified;
  }

  get descriptionTranslation() {
    return !this.bySms
      ? this.getTranslation('password_confirm_request')
      : this.getTranslation('sms_code_confirm_request');
  }

  get sendSmsCodeOrEnterPasswordTranslation() {
    return !this.bySms
      ? this.getTranslation('welcome_screen_sms_code_verify')
      : this.getTranslation('welcome_screen_password_verify');
  }

  get sendCodeAgainTranslation() {
    return `${this.getTranslation('send_code_again_in')} ${this.sendSmsSeconds}`;
  }

  async sendSms() {
    this.error.input = '';
    this.sendSmsSeconds = RESEND_SMS_CODE_TIMEOUT;

    try {
      await api.auth.contactsVerification.startWithAccountSession({
        data: {
          type: 'phone',
          contacts: cleanupPhone(
            this.$store.account?.user?.phone,
            this.$store.authAndReg.usePlusInPhoneMethods.registration
          ),
          reason: 'default',
        },
      });
    } catch (err) {
      this.error.common = err.message;
    }
  }

  async next() {
    this.isLoading = true;

    try {
      if (this.bySms) {
        await this.verifySmsCode();
      } else {
        await this.managingByPassword();
      }

      this.$emit('changeStep', 'EnterPin');
    } catch (err) {
      this.error.common = err.message;
    } finally {
      this.isLoading = false;
    }
  }

  async managingByPassword() {
    await api.profile.managing({
      data: {
        email: this.$store.account?.user.login,
        password: this.value,
      },
    });
  }

  async verifySmsCode() {
    const { sessionToken } = await api.auth.contactsVerification.verify({
      data: {
        type: 'phone',
        contacts: cleanupPhone(
          this.$store.account?.user?.phone,
          this.$store.authAndReg.usePlusInPhoneMethods.registration
        ),
        code: this.value,
      },
    });
    await api.profile.managingWithVerifiedContacts({
      params: {
        'contacts-session-token': sessionToken,
      },
      data: {
        login: this.$store.account?.user.login,
      },
    });
  }

  toggle() {
    this.value = '';
    this.bySms = !this.bySms;

    if (this.bySms && this.sendSmsSeconds === 0) {
      this.sendSms();

      this.sendSmsInterval = setInterval(() => {
        if (this.sendSmsSeconds <= 0) {
          if (this.sendSmsInterval) {
            clearInterval(this.sendSmsInterval);
          }
          return;
        }
        this.sendSmsSeconds--;
      }, 1000);
    }
  }

  cancel() {
    this.$emit('changeStep', 'SetPin');
  }

  async mounted() {
    try {
      const { methods } = await api.profile.managingMethods();
      if (methods) {
        const methodsSorted = sortBy(methods, 'priority');
        this.bySms = methodsSorted[0]?.name === MANAGING_LOGIN_METHODS.widthVerifiedContacts;
      }
    } catch (err) {
      log.error(err);
    }
  }
}
</script>
