<template>
  <div class="archive-catalog catalog with-side-padding">
    <PageTitle
      :crumbs="crumbs"
      :text="getTranslation(isCinemaPage ? 'movie' : 'archive')"
      :show-text="(!isArchiveAvailable || showArchiveIsUnavailableError) && isMounted"
      :with-side-padding="false"
    />

    <div
      v-if="
        (!isArchiveAvailable || showArchiveIsUnavailableError) &&
        isMounted &&
        $store.vod.states.sourcesLoaded
      "
      class="error-block"
    >
      <h4 v-if="!isArchiveAvailable" v-html="getTranslation('archive_unavailable_error_profile')" />
      <h4
        v-if="!isArchiveAvailable || showArchiveIsUnavailableError"
        v-html="getTranslation('archive_unavailable_error_default')"
      />
    </div>

    <template v-else>
      <VodFilters
        :source-id="sourceId"
        :default-active-category-key="0"
        :categories="sortedCategories"
        :current-category="currentCategory"
        :current-genre="currentGenre"
        @loadTitles="loadTitles"
        @setSelectedGenre="setSelectedGenre"
      />

      <template v-if="!isLoaded && !showArchiveIsUnavailableError && !titles.length">
        <h4 class="loading"><span v-html="getTranslation('loading')" />...</h4>
      </template>

      <div
        v-if="isLoaded && !titles.length && isArchiveAvailable && !showArchiveIsUnavailableError"
        class="titles-not-found mt-32 mb-32 body1 color-light-font-secondary"
        v-html="getTranslation('search_not_found')"
      />

      <div v-if="titles.length" class="row" data-cy="titles">
        <TilePoster
          v-for="title in titles"
          :key="title.id"
          class="col-desktop-2 col-desktop-s-3 col-tablet-2 col-mobile-2"
          :countries="title.countries"
          :duration="title.duration"
          :genres="title.genres"
          :height-proportion="posterHeightProportion"
          :href="preparePosterLink(title, sourceId)"
          :image="title.posters[0].path"
          :logo="getChannelLogo(getChannelById(title.availableOnTvChannels[0].channelId))"
          :original-title="title.originalTitle"
          :rating-imdb="title.ratingImdb"
          :rating-kinopoisk="title.ratingKp"
          :source="sourceId"
          :title="title.title"
          :width-proportion="posterWidthProportion"
          :years="title.years"
          @click="showTitleDetails(title.id)"
        />
      </div>

      <ButtonShowMore
        :is-visible="isButtonShowMoreVisible && !loadTitlesPending && titles.length"
        @click="loadMoreTitles"
      />

      <a v-if="showSeoLinkNextPage" :href="seoLinkNextPage"></a>

      <LoaderSpinner v-if="loadTitlesPending" />
    </template>

    <ModalSequoia
      v-if="isModalOpen && isModalTitleOpen"
      type="responsive"
      theme-forced="dark"
      :show-title="false"
      :title="modalTitle ? modalTitle.preview.title : ''"
      :with-button-back="modalTitleIdsHistory.length > 1"
      @close="hideTitleDetails"
      @back="handleModalStepBack"
    >
      <ArchiveTitleDetails />
    </ModalSequoia>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Global from 'src/mixins/Global';
import Vod from 'src/mixins/Vod';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { NOTIFICATION_HIDE_TIMEOUT, VOD_TITLES_DEFAULT_LIMIT } from 'src/constants';
import { makePath, getArchiveTitleWatchUrl } from 'src/utils/url';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import TilePoster from 'src/components/ui/tiles/TilePoster.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import ArchiveTitleDetails from 'src/components/archive/ArchiveTitleDetails.vue';
import VodFilters from 'src/components/vod/VodFilters.vue';
import isEmpty from 'lodash/isEmpty';
import { getChannelLogo } from 'src/utils/channel';
import { VODGenre } from 'src/models/ts/vod/v2/vod';
import logger from 'src/utils/logger';
import { TChannelEnhanced } from 'src/api/channels/types';
import { getSeoBot } from 'src/utils/platform-detector';
import PageTitle from 'src/components/ui/PageTitle.vue';
import ButtonShowMore from 'src/components/ui/buttons/ButtonShowMore.vue';

const log = logger('archive-catalog');

@Component({
  components: {
    ButtonShowMore,
    ArchiveTitleDetails,
    ModalSequoia,
    LoaderSpinner,
    TilePoster,
    ButtonDefault,
    ButtonDropdown,
    IconSVG,
    VodFilters,
    PageTitle,
  },
})
export default class ArchiveCatalog extends mixins(Global, Vod) {
  crumbs = [{ name: this.getTranslation('menu_homescreen'), link: '/' }];

  currentGenre: VODGenre | null = null;
  loadMoreOffsetTop?: number;
  isButtonShowMoreVisible = true;
  loadTitlesPending = false;
  showArchiveIsUnavailableError = false;
  isMounted = false;

  @Prop({ required: true })
  refScrollWrap!: Element;

  @Watch('isLoaded')
  onIsLoadedChange(val: boolean) {
    if (val) {
      actions.common.showFooter(this.$store);
    }
  }

  @Watch('$store.vod.modalTitleId')
  onModalTitleIdChange(modalTitleId: string) {
    if (!modalTitleId) {
      history.pushState(
        {},
        '',
        makePath(
          !this.playingTitleId
            ? '/archive'
            : getArchiveTitleWatchUrl(
                this.playingTitleId,
                this.playingEpisodeId,
                this.playingMediaItemId
              )
        )
      );
    }
  }

  @Watch('$store.vod.states.sourcesLoaded')
  async onVodSourcesLoadedChange(val: boolean) {
    if (this.sortedCategories[0]?.id) {
      actions.vod.setCurrentCategoryId(this.$store, this.sourceId, this.sortedCategories[0]?.id);
    }
    if (val) {
      await this.loadVodData(true);
    }
  }

  get isCinemaPage() {
    return this.$route.path === '/cinema';
  }

  get isArchiveAvailable() {
    return !isEmpty(this.$store.vod.sources?.archive);
  }

  get isLoaded() {
    return (
      !this.isArchiveAvailable ||
      (this.vodSourcesLoaded &&
        this.vodSourceCategoriesLoaded &&
        !this.loadTitlesPending &&
        this.isMounted)
    );
  }

  get vodSourceCategoriesLoaded() {
    return selectors.vod.sourceCategoriesLoadedSelector(this.$store, this.sourceId);
  }

  get vodSourceCategoriesLoading() {
    return selectors.vod.sourceCategoriesLoadingSelector(this.$store, this.sourceId);
  }

  get posterWidthProportion() {
    return selectors.archive.archivePosterWidthProportionSelector(this.$store);
  }

  get posterHeightProportion() {
    return selectors.archive.archivePosterHeightProportionSelector(this.$store);
  }

  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get isModalTitleOpen() {
    return selectors.vod.isModalTitleOpenSelector(this.$store);
  }

  get modalTitleIdsHistory() {
    return selectors.vod.modalTitleIdsHistorySelector(this.$store);
  }

  get modalTitle() {
    return selectors.vod.modalTitleSelector(this.$store, this.sourceId);
  }

  get showSeoLinkNextPage() {
    return getSeoBot() && VOD_TITLES_DEFAULT_LIMIT >= this.titles.length;
  }

  get seoLinkNextPage() {
    return `/archive?offset=${(Number(this.$route.query.offset) || 0) + VOD_TITLES_DEFAULT_LIMIT}`;
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  async serverPrefetch() {
    actions.vod.resetSorting(this.$store);
    await actions.vod.loadSourceCategories(this.$store, this.sourceId);
    await actions.tvChannels.loadChannelsAndGenres(this.$store);

    if (getSeoBot()) {
      await this.loadVodData(true);
    }
  }

  async mounted() {
    actions.vod.resetSorting(this.$store);

    this.isMounted = true;

    if (!this.$store.vod.sources.archive) {
      this.showArchiveIsUnavailableError = true;
      return;
    }

    await this.loadVodData(true);

    if (this.$store.vod.errors?.sourceWasNotFound) {
      setTimeout(() => {
        if (this.$store.vod.errors) {
          this.$store.vod.errors.sourceWasNotFound = null;
        }
      }, NOTIFICATION_HIDE_TIMEOUT);
    }

    await actions.tvChannels.loadChannelsAndGenres(this.$store);
  }

  async loadVodData(resetGenre = false) {
    if (this.vodSourceCategoriesLoading) {
      return;
    }

    log.info(
      `Do you need to load VOD source categories?`,
      !this.categories || !this.vodSourceCategoriesLoaded
    );

    await actions.vod.loadSources(this.$store);

    if (!this.categories || !this.vodSourceCategoriesLoaded) {
      if (this.$store.vod?.sources?.archive?.categoriesLoaded) {
        this.$store.vod.sources.archive.categoriesLoaded = false;
      }
      await actions.vod.loadSourceCategories(this.$store, this.sourceId);
    }

    if (this.sortedCategories.length <= 0) {
      this.showArchiveIsUnavailableError = true;
      return;
    }

    this.showArchiveIsUnavailableError = false;

    this.flushTitles();
    this.$forceUpdate();

    if (resetGenre && this.currentCategory?.genres?.[0]) {
      this.currentGenre = this.currentCategory?.genres?.[0];
    }

    // during the initial load use categoryId from store
    // or 2nd category in order from filtered categories by priority
    actions.vod.setCurrentCategoryId(
      this.$store,
      this.sourceId,
      this.getCategoryIdFromTitle(this.title) ||
        this.currentCategoryId ||
        this.sortedCategories?.[0]?.id ||
        ''
    );

    await this.loadTitles();
  }

  async loadTitles(loadMore = false, catId = this.currentCategoryId, offset = 0) {
    if (this.loadTitlesPending) {
      return;
    }

    if (catId && !this.currentCategoryId) {
      log.error('loadTitles: titles were not loaded – categoryId was not initialized');
      return;
    }

    this.isButtonShowMoreVisible = true;
    this.loadTitlesPending = true;

    actions.vod.setCurrentCategoryId(this.$store, this.sourceId, catId);

    if (!loadMore) {
      this.flushTitles();
    }

    const sourceId = this.sourceId;
    const categoryId = this.currentCategoryId;
    const genreIds =
      this.currentGenre?.id && this.currentGenre.id !== '-1' ? this.currentGenre.id : undefined;

    const titles = await actions.vod
      .loadTitles(this.$store, {
        sourceId,
        categoryId,
        genreIds,
        limit: VOD_TITLES_DEFAULT_LIMIT,
        offset: Number(this.$route.query.offset) || 0,
      })
      .finally(() => {
        this.loadTitlesPending = false;
      });

    if (titles && (!titles.length || titles.length < VOD_TITLES_DEFAULT_LIMIT)) {
      this.isButtonShowMoreVisible = false;
    }
  }

  loadMoreTitles() {
    this.gaEvent({ category: 'archive', action: 'Клик по кнопке "Показать еще"' });
    this.loadMoreOffsetTop = this.refScrollWrap.scrollTop;
    this.loadTitles(true).finally(() => {
      if (this.loadMoreOffsetTop) {
        return (this.refScrollWrap.scrollTop = this.loadMoreOffsetTop);
      }
    });
  }

  async showTitleDetails(titleId: string) {
    await actions.vod.showTitleDetails(this.$store, this.sourceId, titleId, true);
  }

  hideTitleDetails() {
    actions.vod.hideTitleDetails(
      this.$store,
      this.sourceId,
      this.$store.vod.videoData.titleId,
      this.getTranslation('archive_catalog_meta_title')
    );
  }

  flushTitles() {
    if (this.currentCategoryId) {
      actions.vod.flushTitles(this.$store, this.sourceId, this.currentCategoryId);
    }
  }

  setSelectedGenre(genre: VODGenre) {
    this.currentGenre = genre;
  }

  handleModalStepBack() {
    this.$store.vod.isTitleLoading = true;
    const arrayOfTitleIds: Array<string> = this.modalTitleIdsHistory;
    const currentTitleId: string = arrayOfTitleIds[arrayOfTitleIds.length - 2];
    actions.vod.handleModalStepBack(this.$store, this.sourceId, currentTitleId);
    history.pushState({}, '', makePath(`/archive/${currentTitleId}`));
    document.querySelector('.modal')?.scrollTo(0, 0);
    this.$nextTick(() => {
      this.$store.vod.isTitleLoading = false;
    });
  }
}
</script>
