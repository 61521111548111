<template>
  <ButtonDefault
    size="medium"
    :class="componentName"
    :href="href"
    :data-ctype="$options.name"
    :data-cname="componentName"
  >
    <span v-html="translation" />
  </ButtonDefault>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';

@Component({
  components: { ButtonDefault },
})
export default class ButtonComponent extends mixins(Global, CmsComponent) {
  // TODO rename to ButtonSequioa or ButtonCMS

  content?: {
    translation?: { [language: string]: string };
    link?: string;
  } | null = null;

  get translation() {
    return this.getByLanguage(this.content?.translation);
  }

  get href() {
    return this.content?.link || '';
  }
}
</script>
