import { TStore } from 'src/store/types';

export const titleSelector = (store: TStore) => store.seo.title;

export const metaSelector = (store: TStore) => store.seo.meta;

export const breadcrumbsSelector = (store: TStore) => store.seo.breadcrumbs;

export const breadcrumbTelevisionChannelSelector = (store: TStore) =>
  store.seo.breadcrumbTelevisionChannel;

export const breadcrumbProductSelector = (store: TStore) => store.seo.breadcrumbProduct;
