import Vue from 'vue';
import { STORE_KEYS } from 'src/constants';
import { DateTime } from 'src/utils/time/date-time';
import { storage } from 'src/utils/storage';
import { TStore } from 'src/store/types';
import { TProgramEnhanced } from 'src/api/channels/types';
import {
  currentChannelIdSelector,
  currentLanguageIndexSelector,
  currentLanguageSelector,
  currentRenditionIndexSelector,
  dvrRestrictionMessageSelector,
  isCurrentChannelWithVitrinaSelector,
  languagesSelector,
} from 'src/store/tv-current-channel/selectors';
import { toggleChannelFavorite } from 'src/store/tv-channels/actions';
import {
  clearError,
  expandPlayer,
  lockScroll,
  setAlert,
  setIsLive,
  setPickedEpochDay,
  showPlayer,
  updatePlayingTime,
} from 'src/store/player/actions';
import logger from 'src/utils/logger';
import { isVideoScaledSelector } from 'src/store/player/selectors';
import { hasDvrSelector, isDvrRestrictedSelector } from 'src/store/tv-epg/selectors';

const log = logger('tv-current-channel');

export const reset = (store: TStore) => {
  log.info('reset tvCurrentChannel');
  Vue.set(store, 'tvCurrentChannel', null);
};

export const setLanguageIndex = (store: TStore, languageIndex: number) => {
  if (!store.tvCurrentChannel?.info?.playbackInfo) {
    log.error('setLanguageIndex', 'invalid playbackInfo');
    return;
  }
  Vue.set(store.tvCurrentChannel.info.playbackInfo, 'languageIndex', languageIndex);
  setRenditionIndex(store, 0);
  log.info('setLanguageIndex', languageIndex);
  saveLanguageSettings(store);
};

export const setRenditionIndex = (store: TStore, renditionIndex: number) => {
  if (!store.tvCurrentChannel?.info?.playbackInfo) {
    log.error('setLanguageIndex', 'invalid playbackInfo');
    return;
  }
  Vue.set(store.tvCurrentChannel.info.playbackInfo, 'renditionIndex', renditionIndex);
  log.info('setRenditionIndex', renditionIndex);
  saveChannelSettings(store);
};

export const markCurrentLanguageAsUnavailable = (store: TStore) => {
  const languages = languagesSelector(store);
  const languageIndex = currentLanguageIndexSelector(store);
  log.info('Mark as unavailable language with index = ', languageIndex);
  if (languages[languageIndex]) {
    Vue.set(languages[languageIndex], 'unavailable', true);
  }
};

export const unmarkCurrentLanguageAsUnavailable = (store: TStore) => {
  const languages = languagesSelector(store);
  const languageIndex = currentLanguageIndexSelector(store);
  log.info('Remove unavailable language mark with index = ', languageIndex);
  if (languages[languageIndex]) {
    Vue.set(languages[languageIndex], 'unavailable', undefined);
  }
};

export const toggleCurrentChannelFavorite = async (store: TStore) => {
  const currentChannelId = currentChannelIdSelector(store);
  if (currentChannelId) {
    await toggleChannelFavorite(store, currentChannelId);
  }
};

const saveLanguageSettings = (store: TStore) => {
  const currentLanguage = currentLanguageSelector(store);
  storage.set(STORE_KEYS.player.language, currentLanguage.id);
  log.info('saveLanguageSettings', currentLanguage.id);
};

const saveChannelSettings = (store: TStore) => {
  const currentChannelId = currentChannelIdSelector(store);
  if (!currentChannelId) {
    return;
  }
  const allChannelsSettings: Record<string, any> =
    storage.get(STORE_KEYS.player.channelsSettings) || {};
  const settings = allChannelsSettings[currentChannelId] || {};

  settings.hideBlackStripes = isVideoScaledSelector(store);
  settings.renditionIndex = currentRenditionIndexSelector(store);

  allChannelsSettings[currentChannelId] = settings;
  storage.set(STORE_KEYS.player.channelsSettings, allChannelsSettings);

  log.info('saveChannelSettings', settings);
};

export const selectProgram = (store: TStore, program: TProgramEnhanced) => {
  if (isDvrRestrictedSelector(store)) {
    setAlert(store, dvrRestrictionMessageSelector(store));
    log.info('cannot start playing a program on a channel with DVR-restriction');
    return;
  }

  if (!hasDvrSelector(store)) {
    log.warning('cannot start playing a program on a channel without DVR');
    return;
  }

  if (isCurrentChannelWithVitrinaSelector(store)) {
    return log.warning('cannot start playing a program for an external channel');
  }

  log.info('selectProgram', program);
  clearError(store);
  unmarkCurrentLanguageAsUnavailable(store); // in case if some old program stream returns unavailable channel flag should be removed to re-check stream, see https://lifestream.atlassian.net/browse/SEQ-1319
  setIsLive(store, false);
  updatePlayingTime(store, program.startMs);
  showPlayer(store);
  expandPlayer(store);
  lockScroll(store);
  setPickedEpochDay(store, DateTime.toEpochDay(new Date(program.startMs || Date.now())));
};
