<template>
  <div class="youtube-video" :class="theme" :data-ctype="$options.name" :data-cname="componentName">
    <div
      class="cover"
      :class="{ 'overlay-default-before overlay-accent-after': !startYoutube }"
      :style="styleObject"
      @click="startYoutubeToggle"
    >
      <button v-if="!startYoutube" type="button" class="play">
        <IconSVG :svg="IconPlay" />
      </button>

      <iframe
        v-if="startYoutube"
        scrolling="no"
        allowfullscreen
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        style="border: 0"
        :src="`https://www.youtube.com/embed/${content.id}?autoplay=1`"
      />
    </div>

    <div v-if="text" class="text" v-html="text" />
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

@Component({
  components: { IconSVG },
})
export default class YoutubeVideo extends mixins(Global, CmsComponent) {
  startYoutube = false;
  IconPlay = IconPlay;
  content?: {
    id?: string;
    image?: TTranslations<TImageCMS>;
    imageId?: string;
    translation?: TTranslations;
  } | null = null;

  get imageUrl() {
    return this.getByLanguage(this.content?.image)?.url || '';
  }

  get styleObject() {
    return {
      backgroundImage: !this.startYoutube
        ? this.content?.imageId
          ? `url(${this.imageUrl})`
          : `url(http://img.youtube.com/vi/${this.content?.id}/hqdefault.jpg)`
        : 'none',
    };
  }

  get text() {
    return this.getByLanguage(this.content?.translation);
  }

  startYoutubeToggle() {
    this.startYoutube = true;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/overlays';
@import 'src/styles/placeholders-and-mixins/media-queries';

.youtube-video {
  width: 100%;
}

.cover {
  position: relative;
  width: 100%;
  padding-top: var(--proportion-16-9);
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .play {
    display: none;
  }

  &.overlay-default-before {
    @include overlay-gradient-before(
      radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5) 50%)
    );

    @include devices-with-hover {
      &:hover {
        .play {
          transform: scale(1.1);
        }
      }
    }

    &:active .play {
      transition: none;
      transform: scale(0.9);
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: var(--z-3);
      display: block;
      width: 48px;
      height: 48px;
      margin-top: -24px;
      margin-right: -24px;
      margin-left: -24px;
      background-color: var(--c-overlay-brand-fixed-2);
      border: 0;
      border-radius: 50%;
      transition: transform 0.15s var(--ease-in);

      .icon {
        color: var(--c-light-100);
      }
    }
  }
}

.text {
  padding: 4px 16px 4px 12px;
  margin-top: 8px;
  border-left-style: solid;
  border-left-width: 4px;
}

// --------------------------------------------
// Theme Colors
// --------------------------------------------
.light {
  .text {
    color: var(--c-light-font-secondary);
    border-left-color: var(--c-light-brand);
  }
}

.dark {
  .text {
    color: var(--c-dark-font-secondary);
    border-left-color: var(--c-dark-brand);
  }
}
</style>
