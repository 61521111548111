<template>
  <div class="payment-retry-offer dark">
    <div class="flex-space-between">
      <div class="flex-center-vertical">
        <h4 class="title" v-html="offerName" />
        <BadgeSequoia :text="getTranslation('current_offer')" />
      </div>
      <ButtonDefault
        v-if="hasChangeSubscriptionButton"
        class="hidden-mobile-and-tablet"
        view="tertiary"
        theme-forced="dark"
        data-cy="change-subscription"
        @click="changeOffer"
      >
        <span v-html="getTranslation('change_subscription')" />
      </ButtonDefault>
    </div>
    <div class="price h5" v-text="offerPrice" />
    <div class="composition flex">
      <div class="flex flex-center-vertical mr-24">
        <IconSVG :svg="IconTV" class="mr-8" />
        <span v-text="channelsCount" />
      </div>
      <div class="flex flex-center-vertical">
        <IconSVG :svg="IconCatalog" class="mr-8" />
        <span v-text="archiveText" />
      </div>
    </div>

    <ButtonDefault
      v-if="hasChangeSubscriptionButton"
      class="hidden-desktop"
      view="tertiary"
      theme-forced="dark"
      data-cy="change-subscription-mobile"
      @click="changeOffer"
    >
      <span v-html="getTranslation('change_subscription')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import { actions } from 'src/store/actions';
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCatalog from 'src/svg/catalog.svg';
import IconTV from 'src/svg/tv.svg';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';

@Component({
  components: { ButtonDefault, BadgeSequoia, IconSVG },
})
export default class PaymentRetryOffer extends SequoiaComponent {
  IconTV = IconTV;
  IconCatalog = IconCatalog;

  @Prop({ required: true })
  offerName!: string;

  @Prop()
  subscriptionOptionRenderingMethodConfig?: any; // TODO add proper type

  get offerPrice() {
    return (
      this.subscriptionOptionRenderingMethodConfig?.priceAndPeriod?.translation ||
      '{priceAndPeriod}'
    );
  }

  get channelsCount() {
    return (
      this.subscriptionOptionRenderingMethodConfig?.channelsCount?.translation || '{channelsCount}'
    );
  }

  get archiveText() {
    return (
      this.subscriptionOptionRenderingMethodConfig?.programCatalog?.translation ||
      '{programCatalog}'
    );
  }

  get hasChangeSubscriptionButton() {
    return selectors.QS.subscriptionOptionsWithoutExistingSelector(this.$store).length;
  }

  changeOffer() {
    actions.QS.goToStepByName(this.$store, 'AboutSubscription');
    this.gaEvent({
      category: 'acquiring',
      action: 'Ретрай - сменить тариф',
      qs_qr: this.$route.query.token ? 'qr' : 'qs',
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.payment-retry-offer {
  padding: 24px 32px;
  background-color: var(--c-dark-400);
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

  .title {
    max-width: 280px;
    margin: 0 16px 12px 0;
  }

  .price {
    margin-top: 12px;
  }

  .composition {
    margin-top: 16px;

    .icon {
      flex-shrink: 0;
    }

    + .button {
      margin-top: 24px;

      @include mobile {
        width: 100%;
      }
    }
  }
}
</style>
