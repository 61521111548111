import { URLS } from 'src/constants';
import { fe, TReqConfig } from 'src/api/utils';
import logger from 'src/utils/logger';
import { VODPauses } from 'src/models/ts/pauses/v1/pauses';
import { TPausesEmptyResponse } from './types';
import { ChannelPauses } from 'src/models/ts/playback';

const log = logger('pauses-api');

export const getVodPauses = async (config: TReqConfig): Promise<VODPauses> =>
  fe.get(URLS.pauses.vod.titles, config);

export const getVodTitlePause = async (sourceId: string, titleId: string): Promise<VODPauses> =>
  fe.get(URLS.pauses.vod.title(sourceId, titleId));

export const saveVodPause = async (
  sourceId: string | undefined,
  titleId: string | undefined,
  mediaItemId: string | undefined,
  msecFromStart: number,
  totalDurationMsec: number,
  episodeId = '',
  seasonId = '',
  expiresAt = 0,
  config: TReqConfig = {}
): Promise<VODPauses | undefined> => {
  if (!sourceId || !mediaItemId || !msecFromStart || !totalDurationMsec) {
    log.warning(
      `Could not save pause;
      sourceId: ${sourceId};
      titleId: ${titleId};
      mediaItemId: ${mediaItemId};
      msecFromStart: ${msecFromStart};
      totalDurationMsec: ${totalDurationMsec};`
    );
    return;
  }
  return fe.post(URLS.pauses.vod.titles, {
    data: {
      sourceId,
      titleId,
      mediaItemId,
      msecFromStart,
      totalDurationMsec,
      episodeId,
      seasonId,
      expiresAt,
    },
    ...config,
  });
};

export const getTvPauses = async (id: string, config?: TReqConfig): Promise<ChannelPauses> =>
  fe.get(`${URLS.pauses.tv}/${id}`, config);

export const saveTvPause = async (config: TReqConfig): Promise<TPausesEmptyResponse> =>
  fe.put(URLS.pauses.tv, config);

export const deleteTvPause = async (
  id: string,
  config?: TReqConfig
): Promise<TPausesEmptyResponse> => fe.delete(`${URLS.pauses.tv}/${id}`, config);
