const PRELOAD = 1;
const PRELOAD_TOP = 0;

export default class LazyImage {
  public el: HTMLElement;
  public src = '';
  public parent?: HTMLElement | Window;

  constructor({ el, src }: { el: HTMLElement; src: string }) {
    this.src = src;
    this.el = el;
  }

  setParent(parent: HTMLElement | Window | undefined) {
    this.parent = parent;
  }

  checkInView() {
    const rect = this.el.getBoundingClientRect();
    return (
      rect.top < window.innerHeight * PRELOAD &&
      rect.bottom > PRELOAD_TOP &&
      rect.left < window.innerWidth * PRELOAD &&
      rect.right > 0
    );
  }

  update(src: string) {
    this.src = src;
  }
}
