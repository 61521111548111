<template>
  <div class="tv-catalog">
    <PageTitle :crumbs="crumbs" :text="getTranslation('menu_smotret_tv')" />

    <SectionHeader
      class="mb-24 with-side-padding"
      :icon="IconChannelsList"
      :text="sectionHeaderText"
    />

    <ChannelsPageSettings
      :sortings="sortings"
      :disable-sorting="disableSorting"
      :is-loading="
        (!isChannelsCatalogNowOpen && !isChannelsCatalogListOpen) ||
        !isTvDataStateLoaded ||
        isTvDataStateLoading ||
        !channelsRecentAndFavLoaded ||
        channelsRecentAndFavLoading ||
        isProfileLoading
      "
      @sortChannels="selectSorting"
    />

    <template v-if="isLoading">
      <h4 class="loading"><span v-html="getTranslation('loading')" />...</h4>
    </template>

    <div
      v-if="!getChannelsToShow().length && !isLoading && $store.tvChannels.searchQuery"
      class="with-side-padding"
    >
      <p v-html="getTranslation('channels_were_not_found')" />
    </div>

    <template v-if="getChannelsToShow().length && !isLoading">
      <div v-if="isChannelsCatalogNowOpen" class="channels-now">
        <div class="row with-side-padding">
          <TileChannelNow
            v-for="channel in getChannelsToShow()"
            :key="channel.id"
            :channel="channel"
            :live-program-src="getLiveProgramSrc(channel.id)"
            :live-program-title="getLiveProgramTitle(channel.id)"
            :live-program-start-time="getLiveProgramStartTimeHM(channel.id)"
            :live-program-start-timeline-position="getLiveProgramTimelinePosition(channel.id)"
            :next-program-title="getNextProgramTitle(channel.id)"
            :next-program-starts-in="getNextProgramStartsIn(channel.id)"
            :next-program-start-time="getNextProgramStartTimeHM(channel.id)"
            @showChannelDetails="showChannelDetails"
          />

          <ButtonShowMore
            :is-visible="isButtonShowMoreVisible && !isLoading"
            @click="loadMoreTiles"
          />
        </div>
      </div>

      <div v-if="isChannelsCatalogListOpen" class="channels-list">
        <ChannelsStrip class="pb-24" :channels="getChannelsToShow(false)" :show-tiles-only="true" />
      </div>
    </template>

    <LoaderSpinner v-if="isLoading" />

    <EmptyFavorites
      v-if="!favoriteChannels.length"
      :show-recommended-slider="shouldShowRecommendedSlider"
    />

    <ModalSequoia
      v-if="isModalOpen && isModalChannelOpen"
      v-slot="{ scrollBlock }"
      type="responsive"
      theme-forced="dark"
      :title="getChannelTitle(openChannel)"
      :show-title="false"
      :with-button-back="openChannelIdsHistory.length > 1"
      @close="hideChannelDetails"
      @back="handleModalChannelStepBack"
    >
      <ChannelDetails
        :channel="openChannel"
        :redirect-to-player="false"
        :scroll-block="scrollBlock"
      />
    </ModalSequoia>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import Channel from 'src/mixins/Channel';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { CHANNELS_TO_SHOW_LIMIT, TV_SPECIAL_GENRES, TV_SORTING } from 'src/constants';
import IconSVG from 'src/components/IconSVG.vue';
import IconArrowDown from 'src/svg/arrow-down.svg';
import IconArrowUp from 'src/svg/arrow-up.svg';
import IconChannelsList from 'src/svg/channels-list.svg';
import { getChannelNumber, getChannelTitle } from 'src/utils/channel';
import { TChannelEnhanced } from 'src/api/channels/types';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import TileChannelNow from 'src/components/tv-channels/TileChannelNow.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import ChannelDetails from 'src/components/tv-channels/ChannelDetails.vue';
import ChannelsStrip from 'src/components/home/ChannelsStrip.vue';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import ChannelsPageSettings from 'src/components/tv-channels/catalog/ChannelsPageSettings.vue';
import EmptyFavorites from 'src/components/tv-channels/catalog/EmptyFavorites.vue';
import logger from 'src/utils/logger';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import { getSeoBot } from 'src/utils/platform-detector';
import PageTitle from 'src/components/ui/PageTitle.vue';
import isEqual from 'lodash/isEqual';
import { makePath } from 'src/utils/url';
import ButtonShowMore from 'src/components/ui/buttons/ButtonShowMore.vue';

const log = logger('tv-catalog');

@Component({
  components: {
    ButtonShowMore,
    PageTitle,
    EmptyFavorites,
    ChannelsPageSettings,
    ChannelDetails,
    TileChannelNow,
    CmsLayout,
    IconSVG,
    ButtonDefault,
    ChannelsStrip,
    ModalSequoia,
    LoaderSpinner,
    SectionHeader,
  },
})
export default class ChannelsCatalog extends Channel {
  IconChannelsList = IconChannelsList;

  getChannelTitle = getChannelTitle;

  crumbs = [{ name: this.getTranslation('menu_homescreen'), link: '/' }];

  channelsToShowOffset = 0;
  sortedChannels: TChannelEnhanced[] = [];
  disableSorting = false;
  shouldShowRecommendedSlider = false;

  selectedSorting: TDropdownItem | null = null;
  sortings = [
    {
      icon: IconArrowUp,
      iconText: '&#8593;',
      id: '0',
      key: TV_SORTING.ascendingNumber,
      selected: true,
      value: this.getTranslation('sort_by_channel_number'),
    },
    {
      id: '1',
      icon: IconArrowDown,
      iconText: '&#8595;',
      key: TV_SORTING.descendingNumber,
      selected: false,
      value: this.getTranslation('sort_by_channel_number'),
    },
    {
      id: '2',
      icon: IconArrowUp,
      iconText: '&#8593;',
      key: TV_SORTING.ascendingName,
      selected: false,
      value: this.getTranslation('sort_by_channel_name'),
    },
    {
      id: '3',
      icon: IconArrowDown,
      iconText: '&#8595;',
      key: TV_SORTING.descendingName,
      selected: false,
      value: this.getTranslation('sort_by_channel_name'),
    },
  ];

  @Ref('loadMoreTilesButton')
  readonly refloadMoreTilesButton?: Vue;

  @Watch('isTvDataStateLoaded')
  async onTvDataStateLoadedChange(isLoaded: boolean) {
    if (isLoaded) {
      this.$store.tvEpg.loaded = false;
      this.sortChannels();
    }
  }

  @Watch('$store.tvChannels.searchQuery')
  onSearchQueryChange() {
    this.$store.tvEpg.loaded = false;
    this.sortChannels();
  }

  @Watch('$store.tvChannels.currentGenre')
  onGenreChange() {
    this.$store.tvEpg.loaded = false;
    this.sortChannels();
  }

  @Watch('$store.tvChannels.favorites')
  async onFavoritesChange(before: Array<TChannelEnhanced>, after: Array<TChannelEnhanced>) {
    if (!this.isCurrentGenreFavorites || isEqual(before, after)) {
      return;
    }

    this.$store.tvEpg.loaded = false;
    this.sortChannels();

    if (!this.favoriteChannels.length) {
      this.shouldShowRecommendedSlider = true;
    }

    log.info('Favorite channels were updated');
  }

  @Watch('isCurrentGenreFavorites')
  onIsCurrentGenreFavorites(val: boolean) {
    if (val && !this.favoriteChannels.length) {
      this.shouldShowRecommendedSlider = true;
    }
  }

  @Watch('$store.tvChannels.openChannelId')
  onModalChannelIdChange(openChannelId: string) {
    if (!openChannelId) {
      history.pushState(
        {},
        '',
        makePath(this.currentChannelId ? this.watchUrl : `/channels/${this.pageRoute}`)
      );
    }
  }

  // TODO do we still need this in TV 2.0?
  // @Watch('$store.player.ready')
  // async onPlayerReadyChange(ready: boolean) {
  //   // when using brand player - we should wait until player is mounted,
  //   // so onChannelChange event would be triggered there
  //   if (ready) {
  //     log.info('onPlayerReadyChange was triggered');
  //     // this code will be executed after player is mounted and all player events are bound
  //     const channelId = actions.tvCurrentChannel.pickChannelId(this.$store, this.currentChannelId);
  //     await actions.tvChannels.selectChannel(this.$store, channelId, this.$events, true);
  //     if (this.currentChannel) {
  //       await actions.QS.handleForTv(this.$store, this.currentChannel);
  //     }
  //   }
  // }

  get isProfileLoading() {
    return selectors.common.isProfileLoadingSelector(this.$store);
  }

  get isProfileLoaded() {
    return selectors.common.isProfileLoadedSelector(this.$store);
  }

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  get currentChannel() {
    return selectors.tvCurrentChannel.currentChannelSelector(this.$store);
  }

  get currentChannelId() {
    return this.$route.params.channelId || this.currentChannel?.id || '';
  }

  get isTvDataStateLoaded() {
    return this.$store.flags.tvDataState.loaded;
  }

  get isTvDataStateLoading() {
    return this.$store.flags.tvDataState.loading;
  }

  get isLoading() {
    return (
      (!this.isChannelsCatalogNowOpen && !this.isChannelsCatalogListOpen) ||
      !this.isTvDataStateLoaded ||
      this.isEpgLoading ||
      !this.isEpgLoaded ||
      this.isProfileLoading
    );
  }

  get sectionHeaderText() {
    return `${this.getTranslation(
      this.isChannelsCatalogNowOpen ? 'now_on_air' : 'list_of_channels'
    )}: ${selectors.tvChannels.currentGenreNameSelector(this.$store)}`;
  }

  get isChannelsCatalogListOpen() {
    return selectors.tvChannels.isChannelsCatalogListOpenSelector(this.$store);
  }

  get isChannelsCatalogNowOpen() {
    return selectors.tvChannels.isChannelsCatalogNowOpenSelector(this.$store);
  }

  get pageRoute() {
    return this.isChannelsCatalogNowOpen ? 'now' : 'list';
  }

  get isButtonShowMoreVisible() {
    return (
      !!this.channels &&
      Object.keys(this.channels).length > this.channelsToShowOffset + CHANNELS_TO_SHOW_LIMIT
    );
  }

  get watchUrl() {
    return `/channels/${this.pageRoute}/${this.currentChannelId}/watch`;
  }

  async mounted() {
    actions.player.resetPickedEpochDay(this.$store);
    this.$store.tvChannels.searchQuery = '';
    this.sortChannels();
    actions.common.showFooter(this.$store);
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  getChannelsToShow(withLimit = true) {
    if (withLimit && !getSeoBot()) {
      return (
        this.sortedChannels?.filter(
          (channel, i) => i < CHANNELS_TO_SHOW_LIMIT + this.channelsToShowOffset
        ) || []
      );
    } else {
      return this.sortedChannels;
    }
  }

  get channelsRecentAndFavLoaded() {
    return selectors.tvChannels.channelsRecentAndFavLoadedSelector(this.$store);
  }

  get channelsRecentAndFavLoading() {
    return selectors.tvChannels.channelsRecentAndFavLoadingSelector(this.$store);
  }

  async loadEpgForChannels() {
    if (this.isEpgLoaded) {
      log.info('EPG for channels has already been loaded');
      return;
    }

    if (this.isEpgLoading) {
      log.info('EPG for channels has already started loading');
      return;
    }

    this.$store.tvEpg.loading = true;

    await this.getChannelsToShow().forEach((channel) => {
      actions.tvEpg.loadEpgForChannel(this.$store, channel.id, false);
    });

    this.$store.tvEpg.loading = false;
    this.$store.tvEpg.loaded = true;
    await actions.appInfo.updateServerTime(this.$store);
  }

  async showChannelDetails(id: string, isEpg: boolean) {
    await actions.tvChannels.showChannelDetails(this.$store, id, true, isEpg);
  }

  hideChannelDetails() {
    actions.tvChannels.hideChannelDetails(this.$store);
  }

  handleModalChannelStepBack() {
    const arrayOfChannelIds = this.openChannelIdsHistory;
    const currentChannelId = arrayOfChannelIds[arrayOfChannelIds.length - 2];

    if (!currentChannelId) {
      actions.tvChannels.setModalChannelId(this.$store, '');
      this.$store.tvChannels.openChannelIdsHistory = [];
      this.$store.tvChannels.isModalChannelOpen = false;
      history.pushState(
        {},
        '',
        makePath(this.currentChannelId ? this.watchUrl : `/channels/${this.pageRoute}`)
      );
    } else {
      actions.tvChannels.handleModalStepBack(this.$store, currentChannelId);
      history.pushState({}, '', makePath(this.watchUrl));
      document.querySelector('.modal')?.scrollTo(0, 0);
    }
  }

  selectSorting(sorting: TDropdownItem) {
    if (!sorting) {
      return;
    }

    this.selectedSorting = sorting;
    this.$store.tvEpg.loaded = false;
    this.sortChannels();
  }

  sortChannels() {
    if (!this.isAnonymous && this.isCurrentGenreFavorites) {
      if (!this.channelsRecentAndFavLoaded) {
        log.warning(
          'Cannot start sorting channels. Recent and favofite channels were not loaded yet'
        );
        return;
      }

      if (this.channelsRecentAndFavLoading) {
        log.warning(
          'Cannot start sorting channels. Recent and favofite channels are already loading right now'
        );
        return;
      }
    }

    const channels = Object.values(this.channels);

    switch (this.currentGenre) {
      case TV_SPECIAL_GENRES.favourite:
      case TV_SPECIAL_GENRES.recommended:
      case TV_SPECIAL_GENRES.recentlyWatched:
        this.sortedChannels = channels;
        this.disableSorting = true;
        break;

      default:
        this.disableSorting = false;

        switch (this.selectedSorting?.key || this.sortings[0].key) {
          case TV_SORTING.ascendingNumber:
            this.sortedChannels = channels.sort(
              (a, b) => parseInt(getChannelNumber(a)) - parseInt(getChannelNumber(b))
            );
            break;
          case TV_SORTING.descendingNumber:
            this.sortedChannels = channels.sort(
              (a, b) => parseInt(getChannelNumber(b)) - parseInt(getChannelNumber(a))
            );
            break;
          case TV_SORTING.ascendingName:
            this.sortedChannels = channels.sort((a, b) =>
              getChannelTitle(a).localeCompare(getChannelTitle(b))
            );
            break;
          case TV_SORTING.descendingName:
            this.sortedChannels = channels.sort((a, b) =>
              getChannelTitle(b).localeCompare(getChannelTitle(a))
            );
            break;
        }
    }

    if (!getSeoBot()) {
      this.loadEpgForChannels();
    }
  }

  async loadMoreTiles() {
    this.gaEvent({ category: 'channels_now', action: 'Клик по кнопке "Показать еще"' });
    this.channelsToShowOffset += CHANNELS_TO_SHOW_LIMIT;
    this.$store.tvEpg.loaded = false;
    await this.loadEpgForChannels();
    (this.refloadMoreTilesButton?.$el as HTMLInputElement)?.blur();
  }

  get isEpgLoaded() {
    return selectors.tvEpg.isLoadedSelector(this.$store);
  }

  get isEpgLoading() {
    return selectors.tvEpg.isLoadingSelector(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.tv-catalog {
  .channels-strip {
    &::v-deep {
      .tile-poster-channel {
        margin-bottom: 24px;
      }
    }
  }

  .channels-list,
  .channels-now {
    position: relative;
    z-index: var(--z-4);
    overflow-x: hidden;

    .row {
      @include mobile-and-tablet {
        justify-content: space-between;
        margin: 0 0 16px;
      }
    }
  }

  .loading {
    text-align: center;
  }
}
</style>
