<template>
  <CmsLayout class="channels-page" data-cy="channels-page" :class="theme">
    <template v-slot="{ refScrollWrap }">
      <WrapperForCatalogAndPlayer :key="key" player-type="Tv" :ref-scroll-wrap="refScrollWrap" />
    </template>
  </CmsLayout>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Channel from 'src/mixins/Channel';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import WrapperForCatalogAndPlayer from 'src/components/player/WrapperForCatalogAndPlayer.vue';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import logger from 'src/utils/logger';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { SequoiaPage } from 'src/mixins';
import { getChannelTitle } from 'src/utils/channel';

const log = logger('channels-page');

@Component({
  components: {
    LoaderSpinner,
    CmsLayout,
    WrapperForCatalogAndPlayer,
  },
})
export default class ChannelsCatalogPage extends mixins(Channel, SequoiaPage) {
  key = 0;

  @Watch('$route')
  onRouteChange() {
    this.key++;
  }

  get currentChannel() {
    return selectors.tvCurrentChannel.currentChannelSelector(this.$store);
  }

  get currentChannelId() {
    return this.$route.params.channelId || this.currentChannel?.id || '';
  }

  get isChannelsCatalogNowRoute() {
    const route = this.$route.name === 'channels-now';
    if (process.env.VUE_ENV === 'client') {
      return route || (window && window.location.href.includes('now'));
    } else {
      return route;
    }
  }

  get isChannelsCatalogListRoute() {
    const route = this.$route.name === 'channels-list';
    if (process.env.VUE_ENV === 'client') {
      return route || (window && window.location.href.includes('list'));
    } else {
      return route;
    }
  }

  // TODO - do we still need this in TV 2.0?
  // get isCurrentChannelWithVitrina() {
  //   return selectors.tvCurrentChannel.isCurrentChannelWithVitrinaSelector(this.$store);
  // }

  get channelById() {
    return selectors.tvChannels.channelByIdSelector(this.$store, this.currentChannelId || '');
  }

  get tvCatalogPageMetaTitlePart() {
    return `tv_${this.pageRoute}_meta_`;
  }

  get pageRoute() {
    return this.isChannelsCatalogNowOpen ? 'now' : 'list';
  }
  get isChannelsCatalogNowOpen() {
    return selectors.tvChannels.isChannelsCatalogNowOpenSelector(this.$store);
  }

  get tvCatalogPageMetaTitle() {
    return `${this.tvCatalogPageMetaTitlePart}title`;
  }

  get tvCatalogPageMetaDescription() {
    return `${this.tvCatalogPageMetaTitlePart}description`;
  }

  get tvCatalogPageMetaKeywords() {
    return `${this.tvCatalogPageMetaTitlePart}keywords`;
  }

  async serverPrefetch() {
    await actions.tvChannels.loadTvData(this.$store);
    actions.tvChannels.setIsChannelsCatalogNowOpen(this.$store, this.isChannelsCatalogNowRoute);
    actions.tvChannels.setIsChannelsCatalogListOpen(this.$store, this.isChannelsCatalogListRoute);
    this.initMeta();
  }

  async mounted() {
    await actions.tvChannels.loadTvData(this.$store);
    await actions.tvChannels.loadRecentAndFavChannels(this.$store);

    actions.tvChannels.setIsChannelsCatalogNowOpen(this.$store, this.isChannelsCatalogNowRoute);
    actions.tvChannels.setIsChannelsCatalogListOpen(this.$store, this.isChannelsCatalogListRoute);

    // TODO - do we still need this in TV 2.0?
    // const channelId = actions.tvCurrentChannel.pickChannelId(this.$store, this.currentChannel?.id);
    // if (this.isCurrentChannelWithVitrina) {
    //   // if we don't show brand tv-player - just choose a channel
    //   // otherwise we should wait while brand tv-player will be ready (see $store.player.ready watcher)
    //   await this.selectChannel(channelId);
    // }

    actions.common.showFooter(this.$store);

    window.onpopstate = () => {
      const channelIdFromParams = this.$route.params.channelId;
      if (channelIdFromParams) {
        // reset this flag in order to avoid unnecessary pushes to history state
        this.$store.player.pushHistoryStateForChannel = false;
      }
    };
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  async selectChannel(channelId: string, autoplay = false, forced = false) {
    log.info('selectChannel', channelId);

    if (!selectors.player.autoplaySelector(this.$store)) {
      actions.player.setAutoplay(this.$store, autoplay);
    }
    await actions.tvChannels.selectChannel(this.$store, channelId, this.$events, true, forced);
  }

  getMetaInfo() {
    const metaTitle = this.currentChannel?.id
      ? this.getTranslation(`channel_watch_meta_title`).replace(
          /%channelName%/g,
          getChannelTitle(this.channelById)
        )
      : this.getTranslation(this.tvCatalogPageMetaTitle);

    const metaDescription = this.currentChannelId
      ? this.getTranslation(`channel_watch_meta_description`).replace(
          /%channelName%/g,
          getChannelTitle(this.channelById)
        ) || `channel_watch_meta_description`
      : this.getTranslation(this.tvCatalogPageMetaDescription) || this.tvCatalogPageMetaDescription;

    const metaKeywords = this.currentChannelId
      ? this.getTranslation(`channel_watch_meta_keywords`).replace(
          /%channelName%/g,
          getChannelTitle(this.channelById)
        ) || `channel_watch_meta_keywords`
      : this.getTranslation(this.tvCatalogPageMetaKeywords) || this.tvCatalogPageMetaKeywords;

    actions.seo.addBreadcrumbs(this.$store, [
      { name: this.getTranslation('breadcrumb_smotreshka'), link: '/' },
      {
        name: this.getTranslation('breadcrumb_tv_channels'),
        link: `/channels/${this.pageRoute}}`,
      },
    ]);

    return {
      title: metaTitle,
      meta: [
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          name: 'og:title',
          content: metaTitle,
        },
        {
          name: 'og:description',
          content: metaDescription,
        },
      ],
    };
  }
}
</script>

<style lang="scss" scoped>
.loader-spinner {
  top: 50%;
  transform: translateY(-50%);
}
</style>
