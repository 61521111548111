import { DEFAULT_EPG_DISPERSION_DAYS, TV_SPECIAL_GENRES } from 'src/constants';
import { isAnonymousSelector, providerIdSelector } from 'src/store/account/selectors';
import { TStore } from 'src/store/types';
import { filterChannels, filterChannelsByChannelsArray } from 'src/utils/channels';
import { convertToSeconds } from 'src/utils/time/convert-time';
import { getChannelIsLocked, getChannelTitle } from 'src/utils/channel';
import { isOkObject } from 'src/utils/object';
import { isOkNumber } from 'src/utils/number';
import { TChannelEnhanced } from 'src/api/channels/types';
import { TGenre, TTvChannelsList } from 'src/store/tv-channels/types';
import { translationSelector } from 'src/store/translations/selectors';
import { getByLanguage } from 'src/utils/language';
import { languageCodeSelector } from 'src/store/common/selectors';

// -------------------------------------------------------------------------------------
// Channels
// -------------------------------------------------------------------------------------

export const channelsLoadedSelector = (store: TStore) => store.tvChannels.loaded;

export const channelsLoadingSelector = (store: TStore) => store.tvChannels.loading;

export const channelsRecentAndFavLoadedSelector = (store: TStore) =>
  store.tvChannels.recentAndFavLoaded;

export const channelsRecentAndFavLoadingSelector = (store: TStore) =>
  store.tvChannels.recentAndFavLoading;

/**
 * All channels
 * This selector also considers channels-not-for-purchase
 */
export const allChannelsSelector = (store: TStore) => {
  const channelsNotForPurchase = store.tvChannels?.channelsNotForPurchase || {};
  if (Object.keys(channelsNotForPurchase).length) {
    return Object.entries(store.tvChannels.list).reduce<TTvChannelsList>(
      (acc, [channelId, channel]) => {
        if (typeof channelsNotForPurchase[channelId] === 'undefined') {
          acc[channelId] = channel;
        } else if (!getChannelIsLocked(channel)) {
          acc[channelId] = channel;
        }
        return acc;
      },
      {}
    );
  }

  return store.tvChannels.list;
};

/**
 * Channels could be filtered by genre or by search query in the channels list
 */
export const filteredChannelsSelector = (
  store: TStore,
  selectedGenre: string | null = store.tvChannels.currentGenre,
  ignoreSearch = false
) => {
  let filteredChannels = { ...allChannelsSelector(store) };

  if (selectedGenre) {
    switch (selectedGenre) {
      case TV_SPECIAL_GENRES.favourite:
        filteredChannels = filterChannelsByChannelsArray(
          filteredChannels,
          favoritesArraySelector(store)
        );
        break;
      case TV_SPECIAL_GENRES.recommended:
        filteredChannels = filterChannelsByChannelsArray(
          filteredChannels,
          recommendedArraySelector(store)
        );
        break;
      case TV_SPECIAL_GENRES.recentlyWatched:
        filteredChannels = filterChannelsByChannelsArray(
          filteredChannels,
          recentlyWatchedArraySelector(store)
        );
        break;
      case TV_SPECIAL_GENRES.available:
        filteredChannels = filterChannels(filteredChannels, (acc, key, channel) => {
          if (boughtChannelSelector(store, channel)) {
            acc[key] = channel;
          }
          return acc;
        });
        break;
      default:
        filteredChannels = filterChannels(filteredChannels, (acc, key, channel) => {
          const hasRelevantGenre = channel?.relevantGenres?.find(
            (genre) =>
              store.tvChannels.genres.find((g) => g?.id === genre?.genreId)?.title === selectedGenre
          );
          const hasSelectedGenres =
            (channel.info?.metaInfo?.genres || []).findIndex((genre) => genre === selectedGenre) !==
            -1;
          if ((channel?.keyNumber && hasRelevantGenre) || hasSelectedGenres) {
            acc[key] = channel;
          }
          return acc;
        });
    }
  }

  // filter by search query
  if (!ignoreSearch && store.tvChannels.searchQuery) {
    const predicate = store.tvChannels.searchQuery.toLocaleLowerCase();
    filteredChannels = filterChannels(filteredChannels, (acc, key, channel) => {
      const title = getChannelTitle(channel).toLocaleLowerCase() || '';
      if (title.indexOf(predicate) !== -1) {
        acc[key] = channel;
      }
      return acc;
    });
  }

  return filteredChannels;
};

export const boughtChannelSelector = (store: TStore, channel: TChannelEnhanced): boolean => {
  const isAnonymous = isAnonymousSelector(store);
  const channelVitrina = channelVitrinaSelector(store, channel);
  const showVitrinaAlways = showVitrinaSelector(store, channel);
  let bought;

  if (isAnonymous) {
    bought = !!channelVitrina;
  } else {
    bought = !getChannelIsLocked(channel) || showVitrinaAlways;
  }
  return bought;
};

export const isChannelLockedSelector = (store: TStore, channel: TChannelEnhanced) =>
  !boughtChannelSelector(store, channel);

export const recentlyWatchedSelector = (store: TStore) => store.tvChannels.recentlyWatched || {};

export const recentlyWatchedArraySelector = (store: TStore) =>
  Object.values(store.tvChannels.recentlyWatched) || [];

export const favoritesSelector = (store: TStore) => store.tvChannels.favorites || {};
export const favoritesArraySelector = (store: TStore) =>
  Object.values(store.tvChannels.favorites) || [];

export const recommendedSelector = (store: TStore) => store.tvChannels.recommended || {};
export const recommendedArraySelector = (store: TStore) =>
  Object.values(store.tvChannels.recommended) || [];
export const channelByIdSelector = (store: TStore, channelId: string) =>
  store.tvChannels.list[channelId];

export const channelFirstGenreTitleSelector = (store: TStore, channel?: TChannelEnhanced) => {
  const title = channel?.keyNumber
    ? store.tvChannels.genres.find((g) => g.id === channel?.relevantGenres?.[0]?.genreId)?.title
    : channel?.info?.metaInfo?.genres?.[0];
  return title || '';
};

// -------------------------------------------------------------------------------------
// Vitrina
// -------------------------------------------------------------------------------------
export const channelVitrinaSelector = (store: TStore, channel?: TChannelEnhanced | null) =>
  store.siteConfig?.vitrinaTv?.find((one) => one.channelId === channel?.id);

// -------------------------------------------------------------------------------------
// Genres
// -------------------------------------------------------------------------------------
export const genresSelector = (store: TStore) => store.tvChannels.genres;

export const currentGenreSelector = (store: TStore) => store.tvChannels.currentGenre;

export const genreTitleSelector = (store: TStore, genreTitle: string) => {
  switch (genreTitle) {
    case TV_SPECIAL_GENRES.all: {
      return translationSelector(store, 'all_channels');
    }
    case TV_SPECIAL_GENRES.available: {
      return translationSelector(store, 'available');
    }
    case TV_SPECIAL_GENRES.recommended: {
      return translationSelector(store, 'recommended');
    }
    case TV_SPECIAL_GENRES.favourite: {
      return translationSelector(store, 'favourite');
    }
    case TV_SPECIAL_GENRES.recentlyWatched: {
      return translationSelector(store, 'recentlyWatched');
    }
    default: {
      return genreTitle;
    }
  }
};

export const currentGenreNameSelector = (store: TStore) =>
  genreTitleSelector(store, currentGenreSelector(store));

export const genreIconSelector = (store: TStore, genre: TGenre) => {
  const genreTitle = genreTitleSelector(store, genre.title);
  const languageCode = languageCodeSelector(store);
  const currentGenre = store.siteConfig?.genreIcons?.find(
    (g) =>
      getByLanguage(g.translation, languageCode) === genreTitle ||
      (g.id !== '' && g.id === genre.id)
  );
  const icon = getByLanguage(
    currentGenre?.image || store.siteConfig?.genreIconDefault,
    languageCode
  );
  return icon?.url || '';
};

export const currentProgramSelector = (store: TStore, channelId: string) => {
  const channel = store.tvChannels.list?.[channelId];
  return channel?.programs && channel?.programs.length ? channel?.programs[0] : null;
};

export const epgBoundsSelector = (store: TStore, channelId: string) => {
  const EPGBounds = store.tvChannels.list?.[channelId]?.info?.EPGBounds;
  const EPGBoundsIsOk =
    isOkObject(EPGBounds) && isOkNumber(EPGBounds?.min) && isOkNumber(EPGBounds?.max);

  if (EPGBoundsIsOk) {
    return {
      leftBound: EPGBounds?.min,
      rightBound: EPGBounds?.max,
    };
  }
  return false;
};

export const epgDefaultBoundsSelector = (store: TStore) => {
  const now = Math.round(convertToSeconds(Date.now(), 'millisecond'));
  const week = convertToSeconds(DEFAULT_EPG_DISPERSION_DAYS, 'day');
  return {
    leftBound: store.appInfo?.dvrAvailable ? now - week : now,
    rightBound: now + week,
  };
};

/**
 * if user is not anonymous, and vitrina is always shown for current provider
 * https://lifestream.atlassian.net/browse/SEQ-1580
 */
export const showVitrinaSelector = (store: TStore, channel: TChannelEnhanced) => {
  const isAnonymous = isAnonymousSelector(store);
  const vitrina = channelVitrinaSelector(store, channel);
  const providerId = providerIdSelector(store);

  return (
    !isAnonymous && !!vitrina && !!providerId && vitrina.showAlwaysForProviderID === providerId
  );
};

// -----------------------------------------------------------------------------------------
// Channel's details: modal, page, etc.
// -----------------------------------------------------------------------------------------

export const openChannelIdsHistorySelector = (store: TStore) =>
  store.tvChannels.openChannelIdsHistory;

export const openChannelIdSelector = (store: TStore) => store.tvChannels.openChannelId;

export const isModalChannelOpenSelector = (store: TStore) => store.tvChannels.isModalChannelOpen;

export const isPageChannelOpenSelector = (store: TStore) => store.tvChannels.isPageChannelOpen;

export const isChannelTabEpgOpenSelector = (store: TStore) => store.tvChannels.isChannelTabEpgOpen;

export const isChannelTabAboutOpenSelector = (store: TStore) =>
  store.tvChannels.isChannelTabAboutOpen;

// -----------------------------------------------------------------------------------------
// Channels page
// -----------------------------------------------------------------------------------------

export const isChannelsCatalogListOpenSelector = (store: TStore) =>
  store.tvChannels.isChannelsCatalogListOpen;

export const isChannelsCatalogNowOpenSelector = (store: TStore) =>
  store.tvChannels.isChannelsCatalogNowOpen;
