export const parseMd = (text: string) => {
  let description = '';
  let startList = false;
  const lines = text.split('\n');

  for (let index = 0; index <= lines.length - 1; index++) {
    if (lines[index] === '' || lines[index] === '\r') {
      continue;
    }

    let str = lines[index];

    // h1-h6
    str = str.replace(/[\#]{6}(.+)/g, '<h6>$1</h6>');
    str = str.replace(/[\#]{5}(.+)/g, '<h5>$1</h5>');
    str = str.replace(/[\#]{4}(.+)/g, '<h4>$1</h4>');
    str = str.replace(/[\#]{3}(.+)/g, '<h3>$1</h3>');
    str = str.replace(/[\#]{2}(.+)/g, '<h2>$1</h2>');
    str = str.replace(/[\#]{1}(.+)/g, '<h1>$1</h1>');

    // ul
    if (str.trim().startsWith('-') || str.trim().startsWith('+')) {
      const charListItem = str[0];
      str = str.replace(/^-(.+|-)/gm, '<li>$1</li>');
      if (!startList) {
        startList = true;
        str = '<ul>' + str;
      }

      if (lines[index + 1] && !lines[index + 1].trim().startsWith(charListItem)) {
        str = str + '</ul>';
      }
    } else if (startList) {
      startList = false;
    }

    // p
    str = str.replace(/^\s*(\n)?(.+)/gm, (m: any) => {
      return /\<(\/)?(h\d|ul|ol|li)/.test(m) ? m : '<p>' + m + '</p>';
    });

    // a
    str = str.replace(
      /[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g,
      '<a href="$2" title="$4" target="_blank">$1</a>'
    );

    //Inline formatting
    str = str.replace(/[\*\_]{2}([^\*\_]+)[\*\_]{2}/g, '<b>$1</b>');
    str = str.replace(/[\*\_]{1}([^\*\_]+)[\*\[^\"]_]{1}/g, '<i>$1</i>');
    str = str.replace(/[\~]{2}([^\~]+)[\~]{2}/g, '<del>$1</del>');

    description = description + str;
  }

  return description;
};
