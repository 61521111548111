<template>
  <div class="page-title" @click="$emit('click')">
    <BreadCrumbs
      :crumbs="crumbs"
      :class="{ 'with-side-padding': withSidePadding }"
      :with-current-level="false"
    />
    <h1 class="h2" :class="{ 'with-side-padding': withSidePadding }" v-html="text" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import BreadCrumbs from 'src/components/ui/BreadCrumbs.vue';
import { TCrumb } from 'src/store/common/types';

@Component({
  components: { BreadCrumbs },
})
export default class PageTitle extends SequoiaComponent {
  @Prop()
  text?: string;

  @Prop()
  crumbs?: TCrumb[];

  @Prop()
  showText?: boolean;

  @Prop({ default: true })
  withSidePadding?: boolean;
}
</script>

<style lang="scss" scoped>
.credit-card {
  display: flex;
  align-items: center;

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 24px;
    margin-right: 8px;
    background-color: var(--c-light-100);
    border-radius: 4px;
  }

  .image {
    width: 24px;
  }

  .plus {
    color: currentColor;
  }
}
</style>
