<template>
  <a
    class="tile-poster-channel"
    :class="theme"
    :data-cy="dataCy ? `tile-poster-channel-${dataCy}` : ''"
    :href="`/channel/${channelId}/about`"
    @mouseenter="loadEpgForChannel"
    @mouseleave="refBookmark ? refBookmark.$el.blur() : null"
    @click.prevent="goToChannel()"
  >
    <div class="inner">
      <template v-if="channelImage">
        <div
          class="image-wrap"
          :class="{
            'overlay-default-before overlay-accent-after': !isAnonymous || hasAnyProgramInfo,
          }"
        >
          <LazyImage
            :with-icon="true"
            :src="channelImage.src"
            :width="channelImage.width"
            :height="channelImage.height"
            class="image"
          />
          <ButtonBookmark
            v-if="showFavorites"
            ref="bookmark"
            size="small"
            :active="isInFavorites"
            @click.stop.prevent.native="$emit('toggleFavorite')"
          />

          <div
            v-if="hasAnyProgramInfo || (!hasAnyProgramInfo && isEpgLoading)"
            class="program dark"
          >
            <LoaderSpinner v-if="!hasAnyProgramInfo && isEpgLoading" class="loader" />
            <template v-if="hasAnyProgramInfo">
              <ProgressBar
                v-if="timelinePosition"
                theme-forced="dark"
                :percents="timelinePosition"
              />
              <div
                v-if="programDescription"
                class="description caption2"
                v-text="programDescription"
              />
              <div v-if="programTitle" class="name body2 bold" v-text="programTitle" />
              <div v-if="programStartHM && programEndHM" class="time caption2">
                <span class="start" v-text="programStartHM" />
                <span> - </span>
                <span class="end" v-text="programEndHM" />
              </div>
            </template>
          </div>
        </div>

        <div v-if="channelName || programStartTimeAndTitle" @click="goToChannel()">
          <TileTitle v-if="channelName" :title="channelName" />
          <!--          uncomment once we start to lazy-load EPG for channel -->
          <!--          <TileDescription-->
          <!--            v-if="programStartTimeAndTitle"-->
          <!--            :description="programStartTimeAndTitle"-->
          <!--          />-->
        </div>
      </template>
      <ImageBackground v-else :with-icon="true" :theme-forced="theme" />
    </div>
  </a>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import ButtonBookmark from 'src/components/ui/buttons/ButtonBookmark.vue';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import TileTitle from 'src/components/ui/tiles/parts/TileTitle.vue';
import TileDescription from 'src/components/ui/tiles/parts/TileDescription.vue';
import LazyImage from 'src/components/LazyImage.vue';
import Vue from 'vue';
import ImageBackground from 'src/components/ui/ImageBackground.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import logger from 'src/utils/logger';

const log = logger('tile-poster-channel');

@Component({
  components: {
    LoaderSpinner,
    LazyImage,
    ProgressBar,
    ButtonBookmark,
    TileTitle,
    TileDescription,
    ImageBackground,
  },
})
export default class TilePosterChannel extends SequoiaComponent {
  isEpgLoading = false;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  channelImage!: {
    src: string;
    width?: number;
    height?: number;
  };

  @Prop()
  channelId?: string;

  @Prop()
  channelName?: string;

  @Prop()
  programTitle?: string;

  @Prop()
  programDescription?: string;

  @Prop()
  programStartHM?: string;

  @Prop()
  programEndHM?: string;

  @Prop({ required: true })
  showFavorites!: boolean;

  @Prop({ required: false })
  isInFavorites?: boolean;

  @Prop({ default: false })
  openWatch!: boolean;

  @Prop()
  timelinePosition?: number;

  @Prop()
  dataCy?: string;

  @Ref('bookmark')
  refBookmark?: Vue;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get programStartTimeAndTitle() {
    if (this.programStartHM && this.programTitle) {
      return `${this.programStartHM} • ${this.programTitle}`;
    } else if (this.programTitle) {
      return this.programTitle;
    } else {
      return '';
    }
  }

  get hasAnyProgramInfo() {
    return (
      this.timelinePosition ||
      this.programDescription ||
      this.programTitle ||
      (this.programStartHM && this.programEndHM)
    );
  }

  async goToChannel() {
    this.$emit('click');

    if (!this.channelId) {
      return;
    }

    if (this.openWatch) {
      await this.$router.push(`/channels/now/${this.channelId}/watch`);
    } else {
      await actions.tvChannels.showChannelDetails(this.$store, this.channelId, true);
    }
  }

  async loadEpgForChannel() {
    if (this.channelId) {
      this.isEpgLoading = true;
      await actions.tvEpg.loadEpgForChannel(this.$store, this.channelId).catch((err) => {
        log.error(err);
      });
      this.isEpgLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
// stylelint-disable property-no-vendor-prefix

@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/overlays';
@import 'src/styles/placeholders-and-mixins/truncate-lines';
@import 'src/styles/placeholders-and-mixins/bookmark-indicator';

.tile-poster-channel {
  position: relative;
  display: block;
  transform: perspective(1000px);
  -webkit-appearance: initial;
  backface-visibility: hidden;

  @include devices-with-hover {
    &:hover {
      .image-wrap {
        transform: translateY(-8px);

        &::before,
        &::after {
          opacity: 1;
        }
      }

      @include bookmark-indicator;

      @media (min-width: #{$desktop-m-min}) {
        .program {
          opacity: 1;
        }
      }
    }

    .overlay-default-before {
      @include overlay-color-before(var(--c-overlay-lvl-60));
    }
  }

  .inner {
    width: 100%;
  }

  .image-background {
    aspect-ratio: 16 / 9;
    margin-bottom: 36px;
  }

  .image-wrap {
    position: relative;
    width: 100%;
    padding-top: var(--proportion-16-9);
    overflow: hidden;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.25s var(--ease-in-out);
    aspect-ratio: 16 / 9;

    &::before,
    &::after {
      border-radius: 4px;
      opacity: 0;
      transition: opacity 0.25s var(--ease-in-out);
    }
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }

  .bookmark {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: var(--z-4);
    opacity: 0;
  }

  .program {
    position: absolute;
    bottom: 12px;
    left: 12px;
    z-index: var(--z-2);
    display: flex;
    flex-direction: column-reverse;
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    opacity: 0;
    transition: opacity 0.25s var(--ease-in-out);

    .time {
      margin-top: 4px;
    }

    .description {
      color: var(--c-dark-font-secondary);
    }

    .name,
    .description {
      @extend %truncate-after-1-line;
    }

    .progress-bar {
      margin-top: 8px;
    }
  }

  @media #{$devices-with-hover} and (min-width: #{$desktop-m-min}) {
    .tile-description {
      display: none;
    }
  }

  .tile-title {
    @media (max-width: #{$desktop-s-max}) {
      margin-top: 8px;
    }
  }

  .loader {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 30px;
    margin-bottom: 0;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    @include devices-with-hover {
      &:hover {
        .image-wrap {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }

    .image-wrap {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &.dark {
    @include devices-with-hover {
      &:hover {
        .image-wrap {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }

    .image-wrap {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
