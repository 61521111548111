<template>
  <ButtonDefault
    data-cy="title-start"
    size="large"
    :theme-forced="themeForced"
    :full-width="fullWidth"
    :size="size"
    :disabled="isPlayingTitleEqualModalTitle && !hasPlayerError"
    @click="$emit('click')"
  >
    <IconSVG :svg="IconPlay" />
    <span v-html="text" />
  </ButtonDefault>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';

@Component({
  components: { ButtonDefault, IconSVG },
})
export default class ButtonStartTitle extends SequoiaComponent {
  IconPlay = IconPlay;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: 'large' })
  size!: 'small' | 'medium' | 'large';

  @Prop({ default: false })
  fullWidth?: boolean;

  @Prop({ required: true })
  isPlayingTitleEqualModalTitle!: boolean;

  @Prop()
  hasPlayerError?: boolean;

  @Prop()
  hasSavedPause?: boolean;

  get text() {
    return this.isPlayingTitleEqualModalTitle && !this.hasPlayerError
      ? this.getTranslation('playing')
      : this.hasSavedPause
      ? this.getTranslation('vod_continue')
      : this.getTranslation('vod_watch');
  }

  // TODO use it for future tests with pause
  // get dataCyPrefixed() {
  //   return `title-${
  //     this.isPlayingTitleEqualModalTitle && !this.hasPlayerError
  //       ? 'playing'
  //       : this.hasSavedPause
  //       ? 'continue'
  //       : 'watch'
  //   }`;
  // }
}
</script>
