import Vue from 'vue';
import { TStore } from 'src/store/types';
import * as api from 'src/api';
import { selectors } from 'src/store/selectors';
import {
  currentStepSelector,
  currentTemplateComponentSelector,
  getStepIdxByName,
} from 'src/store/quick-subscribe/selectors';
import { isAnonymousSelector } from 'src/store/account/selectors';
import { showModal as showModalDefault } from 'src/store/common/actions';
import { wlDetector } from 'src/utils';
import { COOKIE_NAMES, HTTP_CODES, PLAYER_ERROR_CODES, QS_SOURCE_TYPES } from 'src/constants';
import { TPlayerErrorCodes } from 'src/store/player/types';
import { gaEvent, GaEventParams } from 'src/utils/metrics';
import { setError } from 'src/store/player/actions';
import { loadPaymentMethod } from 'src/store/payment/actions';
import intersectionWith from 'lodash/intersectionWith';
import sortBy from 'lodash/sortBy';
import { quickSubscribeDataPreparation } from 'src/utils/quick-subscribe';
import { TReqConfig } from 'src/api/utils';
import logger from 'src/utils/logger';
import { cookieStore } from 'src/utils/storage';
import { TSubscriptionOption, TSubscriptions } from 'src/store/quick-subscribe/types';
import { getChannelIsLocked } from 'src/utils/channel';
import {
  SubscriptionOptionListV2,
  SubscriptionOptionV2,
  SubscriptionStatus,
} from 'src/models/ts/offer';
import { TVODTitleEnhanced } from 'src/api/vod/types';
import { TChannelEnhanced } from 'src/api/channels/types';
import { shouldShowVitrinaForCurrentChannelSelector } from 'src/store/tv-current-channel/selectors';
import { getByLanguage } from 'src/utils/language';
import { translationSelector } from 'src/store/translations/selectors';
import { convertToDays } from 'src/utils/time/convert-time';
import { listOfAllSourceIdsSelector } from 'src/store/vod/selectors';

const log = logger('QS');

// this action is duplicated from vod/actions on purpose
// in order to avoid circular dependencies
const removeAssetTokens = async (store: TStore) => {
  cookieStore.remove(COOKIE_NAMES.audienceToken);
  store.common.assetTokens.audienceToken = undefined;
  cookieStore.remove(COOKIE_NAMES.tvAssetToken);
  store.common.assetTokens.tvAssetToken = undefined;
};

export const showModal = (store: TStore) => {
  store.QS.isModalOpen = true;
};

export const hideModal = (store: TStore) => {
  store.QS.isModalOpen = false;
};

export const handleModalStepBack = (store: TStore) => {
  goToPrevStep(store);
  store.QS.toPrevStep = true;
};

const isOnForbidden = (err: { status: number; message: string }, store: TStore) => {
  if (err.status === HTTP_CODES.FORBIDDEN) {
    store.QS.showNotification = true;
    store.QS.notificationText = err.message;
    setTimeout(() => {
      store.QS.showNotification = false;
      store.QS.notificationText = '';
    }, 10000);
  }
};

export const handleForTv = async (
  store: TStore,
  channel?: TChannelEnhanced | null,
  useCurrentChannelId = true
) => {
  if (!channel) {
    return;
  }

  store.QS.currentSourceId = (useCurrentChannelId ? store.tvCurrentChannel?.id : channel.id) || '';

  if (
    isAnonymousSelector(store) ||
    shouldShowVitrinaForCurrentChannelSelector(store) ||
    wlDetector().isSanta ||
    store.secretSanta ||
    !getChannelIsLocked(channel)
  ) {
    return;
  }
  try {
    await getTvV3SubscriptionOptions(
      store,
      useCurrentChannelId
        ? selectors.tvCurrentChannel.currentChannelIdSelector(store) || ''
        : channel.id
    );
  } catch (err) {
    isOnForbidden(err, store);
    if ([undefined, HTTP_CODES.NOT_FOUND].includes(err.status)) {
      try {
        await getTvSubscriptionOptions(store, channel.id);
      } catch (err) {
        isOnForbidden(err, store);
        if (![undefined, HTTP_CODES.NOT_FOUND].includes(err.status)) {
          // https://lifestream.atlassian.net/browse/SEQ-1352
          setError(store, PLAYER_ERROR_CODES.COMMON as TPlayerErrorCodes, err.message);
        }
      }
    }
  }

  await loadPaymentMethodAndShowModal(store, QS_SOURCE_TYPES.CHANNEL, true, channel);
};

export const handleForVod = async (
  store: TStore,
  sourceId: string,
  title: TVODTitleEnhanced,
  titleId: string
) => {
  if (store.secretSanta || wlDetector().isSanta) {
    return;
  }

  store.QS.currentSourceId = sourceId;

  try {
    await getVodV3SubscriptionOptions(store, {
      params: { sourceId },
    });
  } catch (err) {
    isOnForbidden(err, store);
    if ([undefined, HTTP_CODES.NOT_FOUND].includes(err.status)) {
      try {
        await getVodSubscriptionOptions(store, {
          params: { sourceId },
        });
      } catch (err) {
        isOnForbidden(err, store);
      }
    }
  }
  await loadPaymentMethodAndShowModal(
    store,
    QS_SOURCE_TYPES.VOD,
    false,
    null,
    sourceId,
    title,
    titleId
  );
};

export const loadPaymentMethodAndShowModal = async (
  store: TStore,
  sourceType: string,
  isChannel: boolean,
  channel: TChannelEnhanced | null,
  sourceId?: string,
  title?: TVODTitleEnhanced,
  episodeId?: string
) => {
  if (currentTemplateComponentSelector(store)) {
    const prioritySubscription = selectors.QS.prioritySubscriptionSelector(store);

    try {
      await loadPaymentMethod(store);
    } catch (err) {
      log.error('onLoadPaymentMethod', err);
    }

    store.QS.sourceType = sourceType;

    if (isChannel) {
      store.QS.channel = channel;
    } else if (sourceId && title && episodeId) {
      store.QS.vod = {
        name: title?.preview?.title || '',
        sourceId: sourceId,
        episodeId,
      };
    }

    showModalDefault(store);
    showModal(store);

    const eventParams: GaEventParams = {
      category: 'acquiring',
      action: 'Показ диалога оплаты',
      trial_available: `${prioritySubscription?.trialAvailable}`,
      qs_qr: 'qs',
    };

    if (isChannel) {
      eventParams.channel_name = channel?.name;
    } else {
      eventParams.vod_name = sourceId;
    }

    gaEvent(eventParams, store);
  }
};

// -----------------------------------------------------------------------------------------------
// Steps
// -----------------------------------------------------------------------------------------------

export const setCurrentStepByIndex = (store: TStore, index: number) => {
  if (
    store.QS.currentSourceId &&
    store.QS?.subscriptionsBySource[store.QS.currentSourceId]?.steps[index]
  ) {
    store.QS.currentStepIndex = index;
  }
};

export const goToNextOrPreviousStep = (store: TStore, toNext = true) => {
  const step = currentStepSelector(store);
  if (step) {
    const direction = toNext ? 'next' : 'prev';
    const nextIndex = step[direction]
      ? getStepIdxByName(store, step[direction] || '')
      : store.QS.currentStepIndex + (toNext ? 1 : -1);

    setCurrentStepByIndex(store, nextIndex || 0);
  }
};

export const goToStepByName = (store: TStore, name: string) => {
  if (!name) {
    return;
  }
  return setCurrentStepByIndex(store, getStepIdxByName(store, name) || 0);
};

export const goToNextStep = (store: TStore) => goToNextOrPreviousStep(store, true);

export const goToPrevStep = (store: TStore) => goToNextOrPreviousStep(store, false);

// -----------------------------------------------------------------------------------------------
// Create Subscription
// -----------------------------------------------------------------------------------------------
const getParamsWithTransactionId = (store: TStore, params: any = {}) => {
  if (store.QS.qsTransactionId) {
    params['qs_transaction_id'] = store.QS.qsTransactionId;
  }
  return params;
};

export const createYakassaSubscription = async (store: TStore, config: TReqConfig) => {
  await removeAssetTokens(store);
  config.params = getParamsWithTransactionId(store, config.params);
  return await api.quickSubscribe.createYakassaSubscription(config);
};

export const createYakassaSubscriptionV2 = async (store: TStore, config: TReqConfig) => {
  await removeAssetTokens(store);
  config.params = getParamsWithTransactionId(store, config.params);
  return await api.quickSubscribe.createYakassaSubscriptionV2(config);
};

export const createSubscription = async (store: TStore, config: TReqConfig) => {
  await removeAssetTokens(store);
  config.params = getParamsWithTransactionId(store, config.params);
  return await api.quickSubscribe.createSubscription(config);
};

export const createSubscriptionWithVerifiedPhone = async (store: TStore, config: TReqConfig) => {
  await removeAssetTokens(store);
  config.params = getParamsWithTransactionId(store, config.params);
  return await api.quickSubscribe.createSubscriptionWithVerifiedPhone(config);
};

export const createSubscriptionUpsale = async (store: TStore, config: TReqConfig) => {
  await removeAssetTokens(store);
  config.params = getParamsWithTransactionId(store, config.params);
  return await api.quickSubscribe.createSubscriptionUpsale(config);
};

export const createSubscriptionUpsaleV2 = async (store: TStore, config: TReqConfig) => {
  await removeAssetTokens(store);
  config.params = getParamsWithTransactionId(store, config.params);
  return await api.quickSubscribe.createSubscriptionUpsaleV2(config);
};

// -----------------------------------------------------------------------------------------------
// Subscription options
// -----------------------------------------------------------------------------------------------

const addOfferChannelsToRenderingData = async (
  store: TStore,
  sub: {
    offerId: any;
    renderingData: { channels: TChannelEnhanced[] };
    trialAvailable: any;
    trial: any;
  },
  isTrial = false
) => {
  if (sub.offerId) {
    const { channels: offerChannels } = await api.offers.getOfferChannels(sub.offerId);

    const channels = intersectionWith(
      Object.values(store.tvChannels.list),
      offerChannels,
      (channel, offerChannel) => channel.id === offerChannel.channelId
    );

    sub.renderingData.channels = sortBy(channels, 'strNumber');

    if (sub.trialAvailable && sub.trial && !isTrial) {
      await addOfferChannelsToRenderingData(store, sub.trial, true);
    }
  }
};

const prepareQSData = async (store: TStore, data: SubscriptionOptionListV2) => {
  store.QS.paymentRetry =
    data.existingSubscriptions?.[0]?.subscriptionStatus ===
    SubscriptionStatus.IN_RETRY.toLowerCase();
  const dataPrepared = quickSubscribeDataPreparation(data);
  dataPrepared.qsTransactionId = data.qsTransactionId;
  await Promise.all([
    ...dataPrepared.subscriptionOptions.map((sub) => addOfferChannelsToRenderingData(store, sub)),
  ]);
  return dataPrepared;
};

export const getVodSubscriptionOptions = async (
  store: TStore,
  config: { params: { sourceId: string; token?: string } }
) => {
  const sourceId = config.params.sourceId;

  if (store.QS.subscriptionsBySource[sourceId]) {
    return;
  }
  const data = await prepareQSData(store, await api.quickSubscribe.vod(config));
  Vue.set(store.QS.subscriptionsBySource, sourceId, data);
};

export const getVodV3SubscriptionOptions = async (
  store: TStore,
  config: { params: { sourceId: string; token?: string } }
) => {
  const sourceId = config.params.sourceId;

  if (store.QS.subscriptionsBySource[sourceId]) {
    return;
  }
  const data = await prepareQSData(store, await api.quickSubscribe.vodV3(config));
  Vue.set(store.QS.subscriptionsBySource, sourceId, data);
};

export const getTvSubscriptionOptions = async (
  store: TStore,
  channelId: string,
  config?: TReqConfig
) => {
  if (store.QS.subscriptionsBySource[channelId]) {
    return;
  }
  const data = await prepareQSData(store, await api.quickSubscribe.tv(channelId, config));
  Vue.set(store.QS.subscriptionsBySource, channelId, data);
};

export const getTvV3SubscriptionOptions = async (
  store: TStore,
  channelId: string,
  config?: TReqConfig
) => {
  const data = await prepareQSData(store, await api.quickSubscribe.tvV3(channelId, config));
  Vue.set(store.QS.subscriptionsBySource, channelId, data);
};

export const getAllSubscriptionOptions = async (store: TStore, config?: TReqConfig) => {
  const data = await api.quickSubscribe.loadAllAvailableSubscriptionOptions(config);
  data.subscriptionOptions = data.subscriptionOptions?.filter(
    ({ type }: SubscriptionOptionV2) => type === 'base'
  );
  const qsData = await prepareQSData(store, data);
  store.QS.allSubscriptions = <TSubscriptions>qsData;
};

export const prepareRenderingMethodConfig = async (
  store: TStore,
  renderingMethodConfig: { [x: string]: any },
  sub?: TSubscriptionOption
) => {
  if (!sub) {
    log.info('Subscription option was not provided');
    return;
  }

  Object.keys(renderingMethodConfig).forEach((propertyName) => {
    const property = renderingMethodConfig[propertyName];
    const propertyType =
      property.translationId || property.translationId === '' ? 'translation' : 'contentBlock';

    property[propertyType] = getByLanguageWithReplace(store, sub, property[propertyType]) || '';

    property.variables.forEach((variable: string | number) => {
      let variableFromRenderingData = sub.renderingData[variable] || '';

      if (variable === 'vodAvailable') {
        const sources = listOfAllSourceIdsSelector(store);
        const movieTheatres = sub.vodSources
          ?.filter((s) => sources.indexOf(s) >= 0)
          ?.map((s) => translationSelector(store, s))
          .join(`, `);
        if (movieTheatres && variableFromRenderingData) {
          variableFromRenderingData = `${
            variableFromRenderingData.split(':')[0]
          }: ${movieTheatres}`;
        }
      }

      property[propertyType] = property[propertyType].replace(
        `@${variable}@`,
        variableFromRenderingData
      );
    });
  });
};

export const getByLanguageWithReplace = (
  store: TStore,
  sub: TSubscriptionOption,
  dict: { [lang: string]: string } | undefined
) => {
  let translation = getByLanguage(dict, store.languageCode) || '';

  const variables = [
    {
      name: '%trialPeriod%',
      replacement: Math.round(convertToDays(sub.trialDurationSeconds, 'second')),
    },
    {
      name: '%sourceName%',
      replacement: store.QS.sourceType !== 'vod' ? store.QS?.channel?.name : '',
    },
    { name: '%channelCount%', replacement: sub.renderingData.channels.length },
  ];

  variables.forEach((variable) => {
    if (variable.replacement !== undefined) {
      translation = translation.replace(variable.name, variable.replacement as string);
    }
  });
  return translation;
};

export const loadAllAvailableSubscriptionOptions = async (store: TStore, config?: TReqConfig) => {
  const { existingSubscriptions, subscriptionOptions } =
    await api.quickSubscribe.loadAllAvailableSubscriptionOptions(config);

  store.QS.existingSubscriptions = existingSubscriptions;
  store.QS.allAvailableSubscriptions = subscriptionOptions;
};

export const setShowNotification = (store: TStore, val: boolean) => {
  store.QS.showNotification = val;
};
