<template>
  <div class="qs-background-container">
    <div class="background" :class="{ reduced: reducedImage }" :style="backgroundStyle" />
    <div class="accent" />
  </div>
</template>

<script lang="ts">
import { QS_SOURCE_TYPES } from 'src/constants';
import { selectors } from 'src/store/selectors';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

export default class QuickSubscribeBackground extends SequoiaComponent {
  @Prop({ default: false })
  reducedImage!: boolean;

  get prioritySubscription() {
    return selectors.QS.prioritySubscriptionSelector(this.$store);
  }

  get vodImages() {
    const images: any = {};

    this.$store.siteConfig?.wlSpecials?.QSVodBackground?.forEach(
      (source: { id: string; image: TTranslations<TImageCMS> | undefined }) => {
        images[source.id] = this.getByLanguage(source.image)?.url;
      }
    );

    return images;
  }

  get backgroundStyle() {
    const offerId = this.prioritySubscription?.offerId;
    const url =
      this.$store.QS.sourceType === QS_SOURCE_TYPES.CHANNEL
        ? this.getByLanguage(this.$store.siteConfig?.wlSpecials?.QSTvBackground)?.url
        : offerId
        ? this.vodImages[offerId]
        : '';

    return {
      backgroundImage: `url("${url || '/public/images/backgrounds/quick-sub.png'}")`,
    };
  }
}
</script>

<style lang="scss" scoped>
.qs-background-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-0);
  width: 100%;
  height: 100%;

  .background {
    position: relative;
    height: 600px;
    background-size: cover;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--c-gradient-dark-150);
    }

    &.reduced {
      transform: translateY(-50%);
    }
  }

  .accent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--c-overlay-brand-custom-1);
  }
}
</style>
