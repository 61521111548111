<template>
  <div
    class="media-item"
    data-cy="media-item"
    :class="[{ playing: isPlaying }, theme]"
    @click="$emit('click', id)"
  >
    <IconSVG :svg="IconPlay" />
    <div class="media-info body1">
      <div class="title" v-text="title" />
      <div v-if="channelTitle" class="channel-title" v-text="channelTitle" />
      <ProgressBar v-if="progress > 0 && !isPlaying" :percents="progress" :alpha-level="5" />
    </div>
    <BadgeSource v-if="!!img" class="logo" :src="img" :width="48" :height="32" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import BadgeSource from 'src/components/ui/BadgeSource.vue';

@Component({
  components: {
    IconSVG,
    ProgressBar,
    BadgeSource,
  },
})
export default class MediaItem extends Vue {
  IconPlay = IconPlay;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  id!: string;

  @Prop({ required: true })
  title!: string;

  @Prop()
  channelTitle!: string;

  @Prop()
  img!: string;

  @Prop()
  isPlaying!: boolean;

  @Prop()
  progress!: number;

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.media-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 8px 12px 8px 8px;
  overflow: hidden;
  background-color: var(--alpha-light-3);
  border-radius: 8px;

  @include devices-with-hover {
    &:hover {
      cursor: pointer;
      background-color: var(--alpha-light-4);
    }
  }

  &:active {
    background-color: var(--alpha-light-5);
  }

  &.playing {
    pointer-events: none;
    cursor: auto;
    background-color: var(--c-overlay-brand-fixed-1);

    .title,
    .icon {
      color: var(--c-light-brand-darker);
    }

    .icon {
      cursor: auto;
    }
  }

  .media-info {
    flex: 1;
    overflow: hidden;
  }

  .channel-title {
    color: var(--c-light-font-secondary);
    @include body2;
  }

  .progress-bar {
    max-width: 156px;
  }

  .logo {
    flex-shrink: 0;
    width: 48px;
    height: 32px;
    overflow: hidden;
  }

  .title,
  .channel-title {
    padding-right: 16px;
    @extend %truncate-after-1-line;

    @include mobile {
      padding-right: 12px;
    }
  }

  .icon {
    flex-shrink: 0;
    margin-right: 12px;
  }

  &.dark {
    background-color: var(--alpha-dark-3);

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-4);
      }
    }

    &:active {
      background-color: var(--alpha-dark-5);
    }

    &::after {
      background-color: var(--alpha-dark-4);
    }

    &.playing {
      .title,
      .icon {
        color: var(--c-dark-brand);
      }
    }

    .channel {
      color: var(--c-dark-font-secondary);
    }

    .channel-title {
      color: var(--c-dark-font-secondary);
    }
  }
}
</style>
