<template>
  <NotificationWithDetails
    v-if="isAnonymous"
    :title="getTranslation('alert_login_to_account_title')"
    :message="getTranslation('alert_login_to_account_desc')"
    :icon="IconProfile"
    data-cy="notification-auth-reg"
    @hide="hideNotification"
  >
    <ButtonDefault
      v-if="$store.authAndReg && $store.authAndReg.showRegistrationButton"
      view="primary"
      theme-forced="dark"
      data-cy="reg"
      @click="
        saveLink($store.authAndReg.registrationButtonUrl || defaultReg);
        gaEvent({ category: 'blocked_content', action: 'Клик по кнопке «Зарегистрироваться»' });
      "
    >
      <span v-html="getTranslation('signup')" />
    </ButtonDefault>
    <ButtonDefault
      view="tertiary"
      theme-forced="dark"
      data-cy="login"
      @click="
        saveLink(defaultLogin);
        gaEvent({ category: 'blocked_content', action: 'Клик по кнопке «Войти»' });
      "
    >
      <span v-html="getTranslation('signin')" />
    </ButtonDefault>
  </NotificationWithDetails>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { NotificationBaseComponent } from 'src/components/ui/notifications/NotificationBaseComponent';
import { actions } from 'src/store/actions';
import IconProfile from 'src/svg/profile.svg';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import NotificationWithDetails from 'src/components/ui/notifications/NotificationWithDetails.vue';
import { AUTH_AND_REG_URLS } from 'src/constants';
import { localStore } from 'src/utils/storage';
import { wlDetector } from 'src/utils';
import logger from 'src/utils/logger';
import { makePath } from 'src/utils/url';

const log = logger('notification-auth-and-reg');

@Component({
  components: {
    ButtonDefault,
    NotificationWithDetails,
  },
})
export default class NotificationAuthAndReg extends NotificationBaseComponent {
  IconProfile = IconProfile;
  defaultReg = AUTH_AND_REG_URLS.defaultReg;
  defaultLogin = AUTH_AND_REG_URLS.defaultLogin;

  async mounted() {
    this.gaEvent({ category: 'blocked_content', action: 'Появление ошибки «Вы не авторизованы»' });
  }

  get isUztel() {
    return wlDetector().isUztel;
  }

  hideNotification() {
    this.loaded = false;
    setTimeout(() => {
      actions.common.hideNotificationAuthAndReg(this.$store);
      actions.common.unlockBodyScroll(this.$store);
    }, 250);
  }

  async checkAuthForUztel() {
    try {
      const path = document.location.pathname;
      await actions.authAndReg.uztelAuth(this.$store, this.$ssrContext?.cookiesToSet);
      localStore.set('showLoginSuccessNotification', true);
      actions.authAndReg.showLoginSuccessNotification(this.$store);
      location.replace(makePath(path));
    } catch (err) {
      log.error(err);
      await this.$router.push(this.defaultLogin);
      this.hideNotification();
      throw err;
    } finally {
      localStore.set('ipWasChecked', true);
    }
  }

  saveLink(loginUrl: string): void {
    if (this.isUztel && !localStore.get('ipWasChecked')) {
      this.checkAuthForUztel();
    } else {
      this.$router.push(loginUrl);
      this.hideNotification();
    }
  }
}
</script>

<style lang="scss">
@import 'notification-base';
</style>
