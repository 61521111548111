<template>
  <div class="badge-source" :class="theme" :data-cy="dataCyPrefixed">
    <IconSVG v-if="icon" :svg="icon" :size="20" />
    <LazyImage
      v-if="src"
      class="logo"
      :src="src"
      :width="width"
      :height="height"
      :with-icon="true"
      :theme-forced="theme"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import LazyImage from 'src/components/LazyImage.vue';

@Component({
  components: {
    IconSVG,
    LazyImage,
  },
})
export default class BadgeSource extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  icon?: Record<string, unknown>;

  @Prop()
  src?: string;

  @Prop()
  width?: number;

  @Prop()
  height?: number;

  @Prop()
  dataCy!: string;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get dataCyPrefixed() {
    return this.dataCy ? `badge-source-${this.dataCy}` : '';
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.badge-source {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }

  &.dark {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}
</style>
