export const scrollEverythingToTop = (isWindow = false) => {
  setTimeout(() => {
    const modal = document.querySelector('.modal');
    const scrollWrap = document.querySelector('.scroll-layout-wrap');

    if (isWindow) {
      window.scrollTo(0, 0);
    } else if (modal) {
      modal.scrollTo(0, 0);
    } else if (scrollWrap) {
      scrollWrap.scrollTo(0, 0);
    }
  }, 0);
};
