<template>
  <div class="payment-retry dark" data-cy="payment-retry">
    <StatusMessage
      v-if="showSuccess"
      theme-forced="dark"
      :status="true"
      :title="getTranslation('retry_success_title')"
      :text="getTranslation('retry_success_text')"
      :button-text="getTranslation('retry_success_action')"
      @clickButton="onSuccessAction"
    />
    <QSPayment
      v-else
      :title="title"
      :description="getTranslation('retry_description')"
      :loading="isLoading"
    >
      <PaymentRetryActions
        :subscription-option-id="subscriptionOptionId"
        @loading="onLoadingChange"
        @success="showSuccess = true"
      />
      <PaymentRetryOffer
        class="offer"
        :subscription-option-rendering-method-config="subscriptionOptionRenderingMethodConfig"
        :offer-name="titleTranslation"
      />
    </QSPayment>
  </div>
</template>

<script lang="ts">
import cloneDeep from 'lodash/cloneDeep';
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import NotificationRetry from 'src/components/ui/notifications/NotificationRetry.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import QSPayment from 'src/components/quick-subscribe/QSPayment.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import CreditCard from 'src/components/ui/CreditCard.vue';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import PaymentRetryActions from 'src/components/payment-retry/parts/PaymentRetryActions.vue';
import PaymentRetryOffer from 'src/components/payment-retry/parts/PaymentRetryOffer.vue';
import StatusMessage from 'src/components/StatusMessage.vue';
import logger from 'src/utils/logger';
import { FALLBACK_RENDERING_METHOD_NAME, legacyRenderingMethods } from 'src/constants';
import { TSubscriptionOption } from 'src/store/quick-subscribe/types';
import { makePath } from 'src/utils/url';

const log = logger('payment-retry');

@Component({
  components: {
    StatusMessage,
    PaymentRetryOffer,
    PaymentRetryActions,
    QSPayment,
    QuickSubscribeBackground,
    CreditCard,
    ButtonDefault,
    ModalSequoia,
    NotificationRetry,
  },
})
export default class PaymentRetry extends SequoiaComponent {
  isLoading = false;
  showSuccess = false;

  get titleTranslation() {
    return this.subscriptionOptionRenderingMethodConfig?.title?.translation || '{title}';
  }

  get title() {
    return this.getTranslation('retry_title').replace('%offerTitle%', `${this.titleTranslation}`);
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  get existingSubscriptionInRetry() {
    return selectors.QS.existingSubscriptionInRetrySelector(this.$store);
  }

  get subscriptionOptionId() {
    return this.existingSubscriptionInRetry?.subscriptionId;
  }

  get subscriptionOfferId() {
    return this.existingSubscriptionInRetry?.offerId;
  }

  get subscriptionOption() {
    const subscriptionOptions = selectors.QS.allSubscriptionOptionsSelector(this.$store);
    return subscriptionOptions.find(
      (one: TSubscriptionOption) => one.offerId === this.subscriptionOfferId
    );
  }

  get subscriptionOptionRenderingMethodName() {
    return this.subscriptionOption?.renderingMethod?.name || '';
  }

  get subscriptionOptionRenderingMethodConfig() {
    if (this.subscriptionOptionRenderingMethodName) {
      const isLegacyMethod = legacyRenderingMethods.includes(
        this.subscriptionOptionRenderingMethodName
      );

      const renderingMethodConfig = cloneDeep(
        this.$store.QSConfig[
          isLegacyMethod
            ? FALLBACK_RENDERING_METHOD_NAME
            : this.subscriptionOptionRenderingMethodName
        ]
      );

      actions.QS.prepareRenderingMethodConfig(
        this.$store,
        renderingMethodConfig,
        this.subscriptionOption
      );
      return renderingMethodConfig;
    }
    return null;
  }

  async mounted() {
    try {
      await actions.payment.loadPaymentSubscriptions(this.$store, {
        params: { token: this.$route.query.token },
      });
      await actions.payment.loadPaymentMethod(this.$store, false, {
        params: { token: this.$route.query.token },
      });
    } catch (err) {
      log.error(err);
    }
  }

  onLoadingChange(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  onSuccessAction() {
    window.location.href = makePath('/');
  }
}
</script>

<style lang="scss" scoped>
.payment-retry {
  .offer {
    margin-top: 48px;
  }

  .error {
    margin-top: 30px;
    margin-bottom: -20px;
  }
}
</style>
