<template>
  <ButtonDefault
    v-if="isVisible"
    class="button-show-more mt-24 mt-tablet-16 mt-mobile-16 mb-tablet-24 mb-mobile-24"
    view="secondary"
    data-cy="show-more"
    size="medium"
    :full-width="true"
    :with-icon="true"
    @click="$emit('click')"
  >
    <IconSVG :svg="IconMoreHorizontal" />
    <span v-html="getTranslation('show_more')" />
  </ButtonDefault>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconMoreHorizontal from 'src/svg/more-horizontal.svg';

@Component({
  components: { ButtonDefault, IconSVG },
})
export default class ButtonShowMore extends SequoiaComponent {
  IconMoreHorizontal = IconMoreHorizontal;

  @Prop({ required: true })
  isVisible!: boolean;
}
</script>
