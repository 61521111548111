import { fe, TReqConfig } from '../utils';
import { URLS, VOD_TITLES_DEFAULT_LIMIT } from 'src/constants';
import { compareStrings } from 'src/utils/string';
import uniqBy from 'lodash/uniqBy';
import { store } from 'src/store';
import {
  TCategoriesResponse,
  TSourceCategories,
  TTitlesResponse,
  TVODTitlePreviewEnhanced,
} from 'src/api/vod/types';
import { SortingsOptions } from 'src/models/ts/vod/v2/meta';
import {
  VODAmedia2Playback,
  VODEpisodes,
  VODHomeCollections,
  VODPlaybackSession,
  VODPlaybackUrl,
  VODTitle,
  VODTitlePreview,
  VODTitlePreviews,
} from 'src/models/ts/vod/v2/vod';
import { TPlaybackSessionContext } from 'src/store/vod/types';
import { TPlaybackMethod } from 'src/store/archive/types';
import { VODSources } from 'src/models/ts/vod/v2/sources';

const vod = URLS.vod;

export const getSources = async (config: TReqConfig = {}): Promise<VODSources> =>
  fe.get(vod.sources, config);

export const getCategories = async (
  sourceId: string,
  config: TReqConfig = {}
): TCategoriesResponse => fe.get(vod.categories(sourceId), config);

export const getSourceCategories = async (
  sourceId: string
): Promise<{ categories: TSourceCategories }> => {
  const [categoriesResult] = await Promise.all([getCategories(sourceId)]);

  const tmpAllGenresSet = new Set<string>();

  if (sourceId === 'archive') {
    categoriesResult?.categories?.push({
      id: '-1',
      description: '',
      title: 'Все категории',
      priority: -1,
      genres: [],
      slug: '',
    });
  }

  const categories: TSourceCategories = (categoriesResult?.categories || [])
    .map((categoryRaw) => {
      const category = {
        ...categoryRaw,
        genres: categoryRaw.genres || [],
        titles: [],
        fullTitles: {},
      };
      category.genres.unshift({
        id: '-1',
        title: 'Все жанры',
        priority: 1000001,
        slug: '',
      });

      const tmpGenresSet = new Set<string>();

      category.genres = category.genres
        .filter((genre) => {
          if (!genre?.id) {
            return false;
          }

          const genreJson = JSON.stringify(genre);
          if (sourceId === 'archive' && genre.id !== '-1') {
            tmpAllGenresSet.add(genreJson);
          }

          tmpAllGenresSet.add(genreJson);
          if (tmpGenresSet.has(genreJson)) {
            return false;
          }

          tmpGenresSet.add(genreJson);
          return true;
        })
        .sort((a, b) => {
          const priorityDiff = b.priority - a.priority;
          if (priorityDiff !== 0) {
            return priorityDiff;
          }
          return compareStrings(a.title, b.title);
        });

      return category;
    })
    .reduce((acc: TSourceCategories, cat) => {
      acc[cat.id] = cat;
      return acc;
    }, {});

  if (sourceId === 'archive') {
    const tempGenres = [...new Set([...tmpAllGenresSet].map((genre) => JSON.parse(genre)))];
    if (categories['-1']) {
      categories['-1'].genres = tempGenres;
    }
  }
  return { categories };
};

export const getCategoryTitles = async ({
  sourceId,
  categoryId,
  genreIds,
  limit = VOD_TITLES_DEFAULT_LIMIT,
  offset = 0,
  sort = 'asc',
}: {
  sourceId: string;
  categoryId: string;
  genreIds: string;
  limit?: number;
  offset?: number;
  sort?: string;
}) => {
  const params = {
    categoryId,
    genreIds,
    limit,
    offset,
    sort,
  };

  // categoryId === '-1' means that we need to get ALL titles without a category,
  // so we can just simply drop categoryId from getTitles request
  params.categoryId = params.categoryId === '-1' ? (params.categoryId = '') : params.categoryId;

  let titles: TVODTitlePreviewEnhanced[] = [];

  let prevLength = titles.length;

  // server can return less than a required limit of titles,
  // so we need to try to load titles as many times as needed
  while (titles.length < limit) {
    const result = await getTitles(sourceId, { params });
    titles = titles.concat(result.titles || []);
    titles = uniqBy(titles, (title) => title.id);
    params.offset += titles.length;

    if (titles.length === prevLength || titles.length < VOD_TITLES_DEFAULT_LIMIT) {
      break;
    }

    prevLength = titles.length;
  }

  return titles.slice(0, limit);
};

export const getTitles = async (sourceId: string, config: TReqConfig = {}): TTitlesResponse =>
  fe.get(vod.titles(sourceId), config);

export const getTitlesMeta = async (
  sourceId: string,
  config: TReqConfig = {}
): Promise<SortingsOptions> => fe.get(vod.titlesMeta(sourceId), config);

export const getSearchTitles = async (
  sourceId: string,
  config: TReqConfig = {}
): Promise<VODTitlePreviews> => fe.get(vod.searchTitles(sourceId), config);

export const getTitle = async (
  sourceId: string,
  titleId: string,
  config: TReqConfig = {}
): Promise<VODTitle> => fe.get(vod.title(sourceId, titleId), config);

export const getAllEpisodes = async (
  sourceId: string,
  titleId: string,
  config: TReqConfig = {}
): Promise<VODTitlePreview[]> => {
  const result = await fe.get(vod.allEpisodes(sourceId, titleId), config);
  return Array.isArray(result?.episodes) ? result.episodes : [];
};

export const getSeasonEpisodes = async (
  sourceId: string,
  titleId: string,
  seasonId: string,
  config: TReqConfig = {}
): Promise<VODEpisodes> => fe.get(vod.seasonEpisodes(sourceId, titleId, seasonId), config);

export const getVodPlayback = async (
  titleId: string,
  config: TReqConfig = {}
): Promise<VODPlaybackUrl> => fe.get(vod.playback(titleId), config);

export const postVodPlaybackUpdate = async (
  sessionId: string,
  closePbs: boolean,
  config: TReqConfig = {}
): Promise<TPlaybackSessionContext> => fe.post(vod.playbackUpdate(sessionId, closePbs), config);

export const getIviPlayback = async (
  titleId: string,
  config: TReqConfig = {}
): Promise<TPlaybackMethod> => fe.get(vod.ivi.playback(titleId), config);

export const getAmediaPlayback = async (
  titleId: string,
  config: TReqConfig = {}
): Promise<{
  response: { video: VODAmedia2Playback };
  playbackSession: VODPlaybackSession | undefined;
}> => fe.get(vod.amedia.playback(titleId), config);

export const getMegogoPlayback = async (titleId: string, config: TReqConfig = {}): Promise<any> =>
  fe.get(vod.megogo.playback(titleId), config);

// Collections

export const getCollections = async (audienceToken = ''): Promise<VODHomeCollections> =>
  fe.get(URLS.home.vodCollections(audienceToken));

export const getCollectionTitles = async (
  sourceId: string,
  collectionId: string,
  fromSource: boolean,
  limit = VOD_TITLES_DEFAULT_LIMIT,
  offset = 0
): Promise<VODTitlePreviews> => {
  const params = {
    limit,
    offset,
  };

  params.offset +=
    (fromSource
      ? store.vod.collectionsFromSource.find((c) => c.id === collectionId)?.titlesList.length
      : store.vod.collections.find((c) => c.vodCollectionId === collectionId)?.titlesList.length) ||
    0;

  return fe.get(vod.collectionTitles(sourceId, collectionId), { params });
};
