import { TStore } from 'src/store/types';
import * as api from 'src/api';
import {
  promocodesKtCodesSelector,
  promocodesKtUsedCodesSelector,
} from 'src/store/site-config/selectors';
import logger from 'src/utils/logger';

const log = logger('site-config');

export const savePromocodesKT = (
  store: TStore,
  data: { code: string; phone: string },
  errorMessageUsed: string,
  errorMessageWrong: string
) => {
  const { code } = data;
  let result: { ok: boolean; error?: string };

  if (promocodesKtCodesSelector(store).includes(code)) {
    if (promocodesKtUsedCodesSelector(store).includes(code)) {
      result = {
        ok: false,
        error: errorMessageUsed,
      };
    } else {
      result = { ok: true };
      api.cms.savePromocodeKT(data).catch((err) => log.error(err));
    }
  } else {
    result = {
      ok: false,
      error: errorMessageWrong,
    };
  }
  return result;
};
