<template>
  <div
    class="channel-details title-and-channel-details"
    :class="[{ 'epg-open': isChannelTabEpgOpen }, { loading: !isDataLoaded }]"
  >
    <template v-if="isDataLoaded">
      <div class="top overlay-default-before overlay-accent-after dark">
        <div
          class="modal-top-background"
          :class="{ 'non-fullscreen-poster': !liveProgramSrc }"
          :style="{ backgroundImage: `url('${liveProgramSrc}')` }"
        />

        <div class="inner with-side-and-bottom-padding">
          <div class="inner-top">
            <BadgeSource
              v-if="!!channelLogo"
              :key="channel.id"
              class="logo"
              theme-forced="dark"
              :src="channelLogo"
              :width="170"
              :height="96"
            />
            <div class="wrap">
              <BreadCrumbs
                v-if="isPageChannelOpen"
                :crumbs="crumbs"
                theme-forced="dark"
                :with-current-level="false"
              />
              <component
                :is="isChannelTabAboutOpen ? 'h1' : 'div'"
                class="h2 mb-0"
                v-html="channelTitle"
              />
            </div>
          </div>

          <ChannelDetailsProgramInfoAndButtons
            class="inner-bottom"
            theme-forced="dark"
            :channel="channel"
            :show-buttons="(isChannelTabEpgOpen && windowWidth < 600) || windowWidth >= 600"
            :animate-buttons="isChannelTabEpgOpen && windowWidth < 600"
            :redirect-to-player="redirectToPlayer"
            :is-dvr-disabled="isDvrDisabled"
          />
        </div>
      </div>

      <NavigationTabs
        class="pl-24 pr-24"
        :theme-forced="theme"
        :tabs="tabs"
        @selectTab="selectTab"
      />

      <div class="bottom" :class="theme">
        <div v-if="isChannelTabAboutOpen">
          <ChannelDetailsProgramInfoAndButtons
            class="with-side-padding visible-mobile mb-32"
            :theme-forced="theme"
            :channel="channel"
            :show-buttons="isChannelTabAboutOpen && windowWidth < 600"
            :animate-buttons="false"
            :redirect-to-player="redirectToPlayer"
            :is-dvr-disabled="isDvrDisabled"
          />

          <TextWithTruncation
            class="description with-side-padding mt-0"
            :source-text="getChannelDescription"
            :small-text="true"
            :theme-forced="theme"
          />

          <div v-if="pausedProgram" class="with-side-padding">
            <SectionHeader
              class="mb-8 mb-tablet-4 mb-mobile-4"
              :icon="IconTvHistory"
              :text="getTranslation('continue_watching')"
            />

            <ContinueWatching
              class="mb-32 mb-tablet-20 mb-mobile-16"
              :channel-id="channel.id"
              :src="pausedProgram.image || ''"
              :pause-time="pausedProgramTime"
              :program-title="pausedProgram.title"
              :timeline-position="pausedProgramTimelinePosition"
              :redirect-to-player="redirectToPlayer"
            />
          </div>

          <NextProgramWidget
            v-if="nextPrograms[0].startTimeHM"
            class="with-side-padding"
            :theme-forced="theme"
            :next-programs="nextPrograms"
            @click="selectTab(1, true)"
          />

          <ChannelSliderMoreChannels
            v-if="isChannelTabAboutOpen"
            :channel="channel"
            :theme-forced="theme"
          />
        </div>

        <div v-if="isChannelTabEpgOpen">
          <h1 v-if="isChannelTabEpgOpen" class="h3 with-side-padding" v-html="seoHeaderForEPG" />

          <EpgSlider
            v-if="isChannelTabEpgOpen"
            class="mb-32 mb-tablet-24 mb-mobile-24"
            :channel-id="channel.id"
            @setPickedEpochDay="onChangePickedEpochDay"
          />
          <div ref="epg-list" class="with-side-padding">
            <EpgElement
              v-for="program in programsOfTheDay"
              :key="program.id"
              :channel="channel"
              :program="program"
              :src="program.image"
              :title="program.title"
              :description="program.description"
              :time="program.startTimeHM"
              :timeline-position="getProgramTimelinePosition(program)"
              :is-live="isProgramLive(program)"
              :is-future="isProgramInFuture(program)"
              :is-current="!!currentProgram && currentProgram.id === program.id"
              :redirect-to-player="redirectToPlayer"
              :is-dvr-disabled="isDvrDisabled"
            />
            <div
              v-if="!programsOfTheDay.length"
              class="color-warning"
              v-html="getTranslation('epg_is_currently_unavailable_for_channel')"
            />
          </div>
        </div>
      </div>
    </template>

    <LoaderSpinner v-else />
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { TChannelEnhanced, TProgramEnhanced } from 'src/api/channels/types';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { getChannelDescription, getChannelLogo, getChannelTitle } from 'src/utils/channel';
import IconSVG from 'src/components/IconSVG.vue';
import IconEpg from 'src/svg/epg.svg';
import IconInfo from 'src/svg/info.svg';
import IconTvHistory from 'src/svg/tv-history.svg';
import BadgeSource from 'src/components/ui/BadgeSource.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import TextWithTruncation from 'src/components/ui/TextWithTruncation.vue';
import ContinueWatching from 'src/components/tv-channels/ContinueWatching.vue';
import NextProgramWidget from 'src/components/tv-channels/NextProgramWidget.vue';
import BreadCrumbs from 'src/components/ui/BreadCrumbs.vue';
import NavigationTabs from 'src/components/ui/NavigationTabs.vue';
import { convertToSeconds, secondsToHM } from 'src/utils/time/convert-time';
import logger from 'src/utils/logger';
import dayjs from 'dayjs';
import EpgElement from 'src/components/tv-channels/EpgElement.vue';
import * as epg from 'src/utils/epg';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import ChannelDetailsProgramInfoAndButtons from 'src/components/tv-channels/ChannelDetailsProgramInfoAndButtons.vue';
import ChannelSliderMoreChannels from 'src/components/tv-channels/ChannelSliderMoreChannels.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { scrollEverythingToTop } from 'src/utils/scroll-everything-to-top';
import EpgSlider from 'src/components/tv-channels/EpgSlider.vue';
import AddResizeListener from 'src/mixins/AddResizeListener';
import { serverTimeMsSelector } from 'src/store/app-info/selectors';
import { makePath } from 'src/utils/url';

const log = logger('channel-details');

const COUNT_PER_PAGE = 30;

@Component({
  components: {
    EpgSlider,
    ChannelSliderMoreChannels,
    ChannelDetailsProgramInfoAndButtons,
    EpgElement,
    NavigationTabs,
    SectionHeader,
    ProgressBar,
    BadgeSequoia,
    BadgeSource,
    ButtonDefault,
    IconSVG,
    TextWithTruncation,
    ContinueWatching,
    NextProgramWidget,
    BreadCrumbs,
    LoaderSpinner,
  },
})
export default class ChannelDetails extends mixins(SequoiaComponent, AddResizeListener) {
  IconTvHistory = IconTvHistory;
  serverTimeMs = serverTimeMsSelector(this.$store);

  scrollContainer: HTMLDivElement | Window | null = null;
  scrollElement: HTMLDivElement | Element | null | undefined;
  epgPage = 1;
  epgCountPages = 1;

  crumbs = [
    { name: this.getTranslation('menu_homescreen'), link: '/' },
    { name: this.getTranslation('menu_smotret_tv'), link: '/channels' },
  ];

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  channel!: TChannelEnhanced;

  @Prop({ default: true })
  redirectToPlayer!: boolean;

  @Prop()
  scrollBlock?: HTMLDivElement;

  @Prop()
  refScrollWrap?: Element;

  @Ref('epg-list')
  readonly refEpgList!: HTMLDivElement;

  @Watch('channel')
  async onChannelChange(val: TChannelEnhanced) {
    if (val) {
      await actions.tvEpg.loadEpgForChannel(this.$store, val.id);
      scrollEverythingToTop();
      actions.common.showFooter(this.$store);
    }
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get channelId() {
    return this.channel?.id || '';
  }

  get isDataLoaded() {
    return this.channel && (this.isChannelTabAboutOpen || this.isChannelTabEpgOpen);
  }

  get seoHeaderForEPG() {
    return `${this.getTranslation('tv_program_for_channel')} ${this.channelTitle}`;
  }

  get currentProgram() {
    return selectors.tvEpg.currentProgramSelector(this.$store);
  }

  get isPageChannelOpen() {
    return selectors.tvChannels.isPageChannelOpenSelector(this.$store);
  }

  get isChannelTabEpgOpen() {
    return selectors.tvChannels.isChannelTabEpgOpenSelector(this.$store);
  }

  get isChannelTabAboutOpen() {
    return selectors.tvChannels.isChannelTabAboutOpenSelector(this.$store);
  }

  get isChannelAboutRoute() {
    const route = this.$route.name === 'channel-about';
    if (process.env.VUE_ENV === 'client') {
      return route || (window && window.location.href.includes('about'));
    } else {
      return route;
    }
  }

  get isChannelProgramRoute() {
    const route = this.$route.name === 'channel-program';
    if (process.env.VUE_ENV === 'client') {
      return route || (window && window.location.href.includes('program'));
    } else {
      return route;
    }
  }

  get tabs() {
    return [
      {
        active: this.isChannelTabAboutOpen,
        icon: IconInfo,
        text: this.getTranslation('about_channel'),
      },
      { active: this.isChannelTabEpgOpen, icon: IconEpg, text: this.getTranslation('epg') },
    ];
  }

  get channelLogo() {
    return getChannelLogo(this.channel);
  }

  get channelTitle() {
    return getChannelTitle(this.channel);
  }

  get getChannelDescription() {
    return getChannelDescription(this.channel);
  }

  get pickedEpochDay() {
    return selectors.player.pickedEpochDaySelector(this.$store);
  }

  get calendar() {
    return epg.getCalendarByChannelId(this.$store, this.channelId);
  }

  get programs() {
    return selectors.tvEpg.programsSelector(this.$store, this.channelId);
  }

  get programsOfTheDay() {
    const programs =
      this.calendar?.find((day) => day.epochDay === this.pickedEpochDay)?.programs || [];
    return programs.slice(0, this.epgPage * COUNT_PER_PAGE);
  }

  get liveProgram() {
    return selectors.tvEpg.programForChannelSelector({
      store: this.$store,
      channelId: this.channelId,
      isLive: true,
    });
  }

  get liveProgramSrc() {
    return this.liveProgram?.image || ``;
  }

  get nextProgramStartsIn() {
    if (this.liveProgram?.endMs) {
      return secondsToHM(convertToSeconds(this.liveProgram?.endMs - Date.now(), 'millisecond'));
    } else {
      return undefined;
    }
  }

  get nextPrograms() {
    const programs = [];
    for (let i = 0; i < 3; i++) {
      const program = this.getNextProgramForChannel(i);
      programs.push(program);
    }

    return programs.map((one, i) => {
      return {
        image: one?.image || '',
        startTimeHM: one?.startTimeHM || '',
        startsIn: i === 0 ? this.nextProgramStartsIn || '' : '',
        title: one?.title || '',
      };
    });
  }

  get pause() {
    return selectors.pauses.lastTvPause(this.$store);
  }

  get pausedProgramTime() {
    return dayjs(this.pause.time).format('DD.MM hh:mm');
  }

  get pausedProgram() {
    return selectors.tvEpg.programForChannelSelector({
      store: this.$store,
      channelId: this.channelId,
      timeMs: this.pause.time,
    });
  }

  get pausedProgramTimelinePosition() {
    return selectors.tvEpg.programTimelinePositionForChannelSelector(
      this.$store,
      this.channelId,
      this.pausedProgram,
      this.pause.time
    );
  }

  get isDvrDisabled() {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store, this.channel);
  }

  async serverPrefetch() {
    await this.loadData();
  }

  async mounted() {
    if (this.isChannelAboutRoute || this.isChannelProgramRoute) {
      actions.tvChannels.setIsChannelTabAboutOpen(this.$store, this.isChannelAboutRoute);
      actions.tvChannels.setIsChannelTabEpgOpen(this.$store, this.isChannelProgramRoute);

      await this.loadData();
      // if /channel/:id is the final route than always redirect to /channel/:id/about
      if (!this.isChannelAboutRoute && !this.isChannelProgramRoute) {
        this.selectTab(0);
      }

      actions.tvEpg.actualizeLiveProgramIndexForChannel(this.$store, this.channelId);

      if (this.isChannelProgramRoute) {
        this.initScrollForEpgList();
      }
    }

    window.onpopstate = async () => {
      if (this.isPageChannelOpen && this.channelId) {
        await actions.tvChannels.showChannelDetails(this.$store, this.channelId);
      } else {
        await actions.tvChannels.hideChannelDetails(this.$store);
      }
    };
  }

  destroyed() {
    this.scrollContainer?.removeEventListener('scroll', this.handleScroll);
  }

  initScrollForEpgList() {
    this.scrollContainer = this.scrollBlock ? this.scrollBlock : window;
    this.scrollElement = this.scrollBlock ? this.scrollBlock : document?.scrollingElement;
    this.scrollContainer?.addEventListener('scroll', this.handleScroll);
    const allPrograms =
      this.calendar?.find((day) => day.epochDay === this.pickedEpochDay)?.programs.length || 1;
    this.epgCountPages = Math.ceil(allPrograms / COUNT_PER_PAGE);
  }

  handleScroll(e: Event) {
    if (!e.target || this.epgPage >= this.epgCountPages) {
      return;
    }

    const { bottom: bottomList } = this.refEpgList.getBoundingClientRect();
    if (bottomList <= window.innerHeight * 2) {
      this.epgPage++;
    }
  }

  onChangePickedEpochDay() {
    this.epgPage = 1;
  }

  async loadData() {
    await actions.tvChannels.loadTvData(this.$store);

    try {
      // needed for updating DVR availability status
      await actions.tvChannels.loadPlaybackInfoDetails(this.$store, this.channel);
    } catch (err) {
      log.error('Load playback info error:', err);
    }
    await actions.tvEpg.loadEpgForChannel(this.$store, this.channelId);
    try {
      await actions.pauses.loadTvPauses(this.$store, this.channelId);
    } catch (err) {
      log.error('Load TV pauses error:', err);
    }
  }

  getProgramTimelinePosition(program: TProgramEnhanced) {
    return selectors.tvEpg.programTimelinePositionForChannelSelector(
      this.$store,
      this.channel.id,
      program
    );
  }

  getNextProgramForChannel(index = 0) {
    return selectors.tvEpg.programForChannelSelector({
      store: this.$store,
      channelId: this.channel.id,
      programIndex:
        selectors.tvEpg.nextProgramIndexForChannelSelector(this.$store, this.channel?.id) + index,
    });
  }

  isProgramLive(program: TProgramEnhanced) {
    return this.serverTimeMs > program.startMs && this.serverTimeMs < program.endMs;
  }

  isProgramInFuture(program: TProgramEnhanced) {
    return epg.isProgramInFuture(program);
  }

  selectTab(index: number, shouldScrollToTabs = false) {
    actions.tvChannels.setIsChannelTabAboutOpen(this.$store, index === 0);
    actions.tvChannels.setIsChannelTabEpgOpen(this.$store, index === 1);
    const url = `/channel/${this.channel.id}/${index === 0 ? 'about' : 'program'}`;
    history.pushState({}, '', makePath(url));
    actions.tvChannels.setChannelMetaTitle(this.$store, this.channelId);

    if (index === 1) {
      this.initScrollForEpgList();
    } else {
      this.epgPage = 1;
      this.scrollContainer?.removeEventListener('scroll', this.handleScroll);
    }

    if (shouldScrollToTabs) {
      const $tabs = document.querySelector('.tabs');
      $tabs?.scrollIntoView();
    }
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/title-and-channel-details';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/side-and-bottom-padding';

.channel-details {
  &.loading {
    display: flex;
    align-items: center;
  }

  &.epg-open {
    @include mobile {
      h1 {
        @include heading4;
      }

      .top .inner {
        .inner-top {
          bottom: 96px;

          .logo {
            max-width: 72px;
            max-height: 40px;
            margin-right: 12px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  &:not(.epg-open) {
    .top .inner {
      .inner-top {
        bottom: 24px;
      }
    }

    .inner-bottom::v-deep .buttons {
      @include mobile {
        opacity: 0;
      }
    }
  }

  .top {
    .inner {
      max-width: 680px;
      @include side-and-bottom-padding;

      .inner-top {
        align-items: center;
        padding-bottom: 48px;
        margin-top: auto;
        transition: bottom var(--ease-out) 0.25s;

        @media (min-width: $tablet-min) and (max-width: $desktop-s-max) {
          display: flex;
          padding-bottom: 20px;

          .wrap {
            margin-bottom: 16px;
          }
        }

        @include mobile {
          position: absolute;
          left: 24px;
          padding-bottom: 0;
        }

        h1,
        .breadcrumbs {
          transition: all var(--ease-out) 0.25s;
        }

        .logo {
          flex-shrink: 0;
          max-width: 170px;
          max-height: 96px;
          margin-right: auto;
          margin-bottom: 16px;
          aspect-ratio: 16 / 9;
          transition: all var(--ease-out) 0.25s;

          @include desktop-s {
            max-width: 128px;
            max-height: 72px;
            margin-right: 24px;
          }

          @include tablet {
            margin-right: 24px;
          }

          @include mobile-and-tablet {
            max-width: 98px;
            max-height: 56px;
          }

          @include mobile {
            margin-bottom: 16px;
          }
        }
      }

      .inner-bottom {
        &::v-deep {
          .program-air-time,
          .progress-bar,
          h5 {
            @include mobile {
              display: none;
            }
          }

          .progress-bar {
            max-width: 256px;
          }

          .button {
            @media (max-width: #{$desktop-s-max}) {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  .bottom {
    padding-bottom: 48px;
    overflow-x: hidden;

    @media (max-width: #{$desktop-s-max}) {
      padding-bottom: 32px;
    }

    &::v-deep .buttons {
      flex-wrap: wrap;
    }

    .description {
      max-width: 100%;
      margin-bottom: 48px;

      @media (max-width: #{$desktop-s-max}) {
        display: none;
      }

      &::v-deep p {
        + p {
          margin-top: 16px;
        }

        + ul {
          margin-bottom: 16px;

          > li {
            margin-left: 32px;
            list-style: disc;
          }
        }
      }
    }
  }

  .epg-element {
    margin-bottom: 32px;

    @include tablet {
      margin-bottom: 24px;
    }

    @include mobile {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::v-deep {
      .text-with-truncation {
        margin-top: 0;
      }
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  .bottom {
    &.light {
      background-color: var(--c-light-100);
    }

    &.dark {
      background-color: var(--c-dark-150);
    }
  }
}
</style>
