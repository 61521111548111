<template>
  <div class="next-program-widget" :class="theme">
    <SectionHeader :icon="IconNextOn" :text="getTranslation('next_on_channel')" />
    <div class="row">
      <TileNextProgram
        v-for="(nextProgram, i) in nextPrograms"
        :key="i"
        :image="nextProgram.image"
        :title="nextProgram.title"
        :start-time-h-m="nextProgram.startTimeHM"
        :starts-in="nextProgram.startsIn"
        :show-starts-in="i === 0"
      />
    </div>
    <ButtonDefault view="tertiary" size="medium" :with-icon="true" @click="$emit('click')">
      <IconSVG :svg="IconEpg" />
      <span v-html="getTranslation('entire_tv_program')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ProgramPreview from 'src/components/tv-channels/ProgramPreview.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconEpg from 'src/svg/epg.svg';
import IconNextOn from 'src/svg/next-on.svg';
import TileNextProgram from 'src/components/tv-channels/TileNextProgram.vue';

interface TProgramForWidget {
  image: string;
  startTimeHM: string;
  startsIn: string;
  title: string;
}

@Component({
  components: {
    TileNextProgram,
    ButtonDefault,
    SectionHeader,
    BadgeSequoia,
    ProgramPreview,
    IconSVG,
  },
})
export default class NextProgramWidget extends SequoiaComponent {
  IconEpg = IconEpg;
  IconNextOn = IconNextOn;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  nextPrograms!: TProgramForWidget[];

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.next-program-widget {
  .section-header {
    margin-bottom: 24px;

    @include mobile-and-tablet {
      margin-bottom: 16px;
    }
  }

  .row {
    margin-bottom: 24px;

    @include mobile-and-tablet {
      margin-bottom: 16px;
    }
  }

  .tile-next-program {
    @include tablet {
      &:last-child {
        display: none;
      }
    }
  }

  .button {
    @include mobile-and-tablet {
      width: 100%;
    }
  }
}
</style>
