import { TStore } from 'src/store/types';

export const accountSelector = (store: TStore) => store.account;

export const isAnonymousSelector = (store: TStore) => accountSelector(store)?.anonymous;

export const providerSelector = (store: TStore) => accountSelector(store)?.provider?.provider;

export const providerIdSelector = (store: TStore) => providerSelector(store)?.id || '';

export const providerNameSelector = (store: TStore) => providerSelector(store)?.name || '';

export const userSelector = (store: TStore) => accountSelector(store)?.user;

export const userIdSelector = (store: TStore) => accountSelector(store)?.id || '';

export const userEmailSelector = (store: TStore) => userSelector(store)?.email || '';

export const userLoginSelector = (store: TStore) => userSelector(store)?.login || '';

export const profilesSelector = (store: TStore) => accountSelector(store)?.profiles || [];
