import Vue from 'vue';
import Component from 'vue-class-component';
import logger from 'src/utils/logger';
import { Prop } from 'vue-property-decorator';

const log = logger('cms-component');

@Component
export default class CmsComponent extends Vue {
  content?: any;

  @Prop()
  componentName!: string;

  created() {
    const name = this.componentName;
    if (!name) {
      return log.error(`DID NOT PASS componentName to component ${this.$options.name}`);
    }
    this.content = this.$store.components[name]?.content || {};
  }
}
