import { TAuthAndReg } from './types';
import { AUTH_STEPS } from 'src/constants';

const initialState: TAuthAndReg = {
  checkboxes: [],
  phoneMask: {
    use: false,
    phoneCode: '+7 (999) 999 - 9999',
  },
  textBelowLoginButtonId: '',
  textBelowRegButtonId: '',
  registration: {
    checkboxes: [],
    contentBlock: {},
    contentBlockId: '',
    show: false,
    usePhoneRegistration: false,
  },
  contentBlock: {},
  contentBlockId: '',
  eula: {},
  eulaId: '',
  image: {},
  imageId: '',
  logo: {},
  logoId: '',
  password: {
    min: 1,
    max: 30,
  },
  regAcquiring: {
    disclaimerBlockId: '',
    advertisementTextBlockId: '',
    controlSubscriptionsOnlyOnSmotreshkaBlockId: '',
    disclaimerInChannelsListBlockId: '',
    disclaimer: {},
    advertisementText: {},
    controlSubscriptionsOnlyOnSmotreshka: {},
    disclaimerInChannelsList: {},
  },
  regTrials: {
    activeTrialId: '',
  },
  reportProblem: {},
  reportProblemId: '',
  restorePassword: {
    show: true,
    contentBlock: {},
    contentBlockId: '',
  },
  showPhoneUnlinkPage: true,
  showRegCaptcha: true,
  showRegPage: true,
  showRestorePwdPage: true,

  showRegistrationButton: true,
  registrationButtonUrl: '',

  currentStep: AUTH_STEPS.start,
  isLoading: false,
  useRecaptcha: false,
  phone: '',
  passwordAuth: '',
  logins: [],
  currentLogin: '',
  captchaValue: '',
  checkCode: '',
  errorMessages: [],
  incompletePhone: null,
  incompletePhoneInitialFlag: true,
  errorText: '',
  wlAppId: '',
  isTypeReg: '',
  gaNameComponent: '',
  verificationComponentName: '',
  register: {
    regionId: '',
    providerId: '',
    providerIdFromUrl: '',
    providerName: '',
  },
  captchaRefreshValue: 0,
  isSmsAuth: false,
  isAuthWithPassword: false,
  phoneUnsupported: false,
  phoneUnsupportedMessage: '',
  phoneTechnicalSim: false,
  recommendProvider: '',
  phoneInfoToken: '',
  cookieCaptcha: '',
  promoCode: '',
  promoCodeValid: false,
  promoCodeResultText: '',
  usePromoCodes: false,
  promoCodeId: '',
  canonicalValue: '',
  showLoginSuccessNotification: false,
  usePlusInPhoneMethods: {
    auth: false,
    registration: false,
    restorePassword: false,
    phoneInfo: false,
  },
  recaptchaId: '',
  termsOfUse: [],
  authPhoneNotFound: '',
  registrationSettings: [],
};

export default initialState;
