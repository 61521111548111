<template>
  <span class="loader-spinner" :class="[theme, { 'full-width': fullWidth }]">
    <span class="container">
      <svg class="svg" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5" />
      </svg>
    </span>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class LoaderSpinner extends Vue {
  @Prop({ default: false })
  fullWidth!: boolean;

  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
.loader-spinner {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  margin: 16px auto;

  &.full-width {
    width: 100%;
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: var(--z-2);
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  .svg {
    width: 100%;
    height: 100%;
    animation: rotate 2s linear infinite;

    .path {
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    .svg {
      .path {
        stroke: var(--c-light-brand);
      }
    }
  }

  &.dark {
    .svg {
      .path {
        stroke: var(--c-dark-brand);
      }
    }
  }
}
</style>
