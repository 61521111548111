<template>
  <button
    type="button"
    class="chip-selector"
    :class="[
      { selected },
      { disabled },
      { 'with-leading': withLeading },
      { 'with-action': withAction },
      size ? sizeClass : '',
      theme,
    ]"
    :data-cy="dataCyPrefixed"
    @click="$emit('click')"
  >
    <IconSVG
      v-if="withLeading"
      :svg="iconLeading"
      :svg-path="iconLeadingPath"
      :size="24"
      class="leading"
    />
    <span v-html="text" />
    <IconSVG
      v-if="withAction"
      :svg="iconAction"
      :svg-path="iconActionPath"
      :size="20"
      class="action"
    />
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';

@Component({
  components: {
    IconSVG,
  },
})
export default class ChipSelector extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  dataCy!: string;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: false })
  selected?: boolean;

  @Prop({ default: '' })
  text!: string;

  @Prop()
  size?: 'xs' | 's' | 'm';

  // used for icons from the project
  @Prop()
  iconLeading?: Record<string, unknown>;

  @Prop()
  iconAction?: Record<string, unknown>;

  // used for icons from Admin
  @Prop()
  iconLeadingPath?: string;

  @Prop()
  iconActionPath?: string;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get dataCyPrefixed() {
    return this.dataCy ? `button-circle-${this.dataCy}` : '';
  }

  get sizeClass() {
    return `size-${this.size}`;
  }

  get withLeading() {
    return this.iconLeading || this.iconLeadingPath;
  }

  get withAction() {
    return this.iconAction || this.iconActionPath;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.chip-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  padding-left: 16px;
  border-style: solid;
  border-width: 2px;
  border-radius: 28px;
  @include body1;

  &.disabled,
  &:disabled {
    border-color: transparent !important;
  }

  &.size-m {
    height: 48px;
  }

  &.size-s {
    height: 40px;
  }

  &.size-xs {
    height: 36px;

    @include devices-with-touch {
      height: 40px;
    }
  }

  @include devices-with-hover {
    @media (min-width: #{$desktop-s-min}) {
      height: 36px;
    }

    @include mobile-and-tablet {
      height: 40px;
    }
  }

  &.with-leading {
    padding-left: 0;

    .icon {
      margin-right: 4px;
      margin-left: 12px;
    }
  }

  &.with-action {
    padding-right: 0;

    .icon {
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    color: var(--c-light-font-primary);
    background-color: var(--alpha-light-2);
    border-color: var(--alpha-light-2);

    &.disabled,
    &:disabled {
      color: var(--c-light-font-tertiary) !important;
      background-color: var(--alpha-light-3);

      .icon.leading {
        color: var(--alpha-light-6);
      }

      .icon.action {
        color: var(--alpha-light-3);
      }
    }

    .icon.leading {
      color: var(--alpha-light-9);
    }

    .icon.action {
      color: var(--alpha-light-6);
    }

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-light-3);

        .icon.action {
          color: var(--alpha-light-7);
        }
      }
    }

    &:active {
      background-color: var(--alpha-light-3);

      .icon.action {
        color: var(--alpha-light-7);
      }
    }

    &.selected {
      background-color: var(--c-overlay-brand-fixed-2);
      border-color: var(--c-light-brand);
    }
  }

  &.dark {
    color: var(--c-dark-font-primary);
    background-color: var(--alpha-dark-2);
    border-color: var(--alpha-dark-2);

    &.disabled,
    &:disabled {
      color: var(--c-dark-font-tertiary) !important;
      background-color: var(--alpha-dark-3);

      .icon.leading {
        color: var(--alpha-dark-6);
      }

      .icon.action {
        color: var(--alpha-dark-3);
      }
    }

    .icon.leading {
      color: var(--alpha-dark-9);
    }

    .icon.action {
      color: var(--alpha-dark-6);
    }

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-3);

        .icon.action {
          color: var(--alpha-dark-7);
        }
      }
    }

    &:active {
      background-color: var(--alpha-dark-3);

      .icon.action {
        color: var(--alpha-dark-7);
      }
    }

    &.selected {
      background-color: var(--c-overlay-brand-fixed-2);
      border-color: var(--c-dark-brand);
    }
  }
}
</style>
