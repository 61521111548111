var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"trial-container with-side-and-bottom-padding dark",class:{ 'qr-acquiring': _vm.token }},[_c('QuickSubscribeBackground'),_c('div',{staticClass:"qs-content"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.prioritySubscription.renderingData.summary || '${summary}')}}),(_vm.token && _vm.$store.account.user)?_c('div',{staticClass:"about-subscription-note"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTranslation('qr_payment_for_account'))}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$store.account.user.login)}})]):_vm._e(),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.prioritySubscription.renderingData.description || '${description}')}}),_c('ButtonDefault',{attrs:{"is-loading":_vm.isLoading},on:{"click":function($event){_vm.$emit('createSub');
        _vm.gaEvent({
          category: 'acquiring',
          action: `Клик по кнопке 'Подключить'`,
          qs_qr: _vm.$route.query.token ? 'qr' : 'qs',
          offer_id: _vm.prioritySubscription.offerId,
        });}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.prioritySubscription.renderingData.call_to_action || '${call_to_action}')}})]),(_vm.prioritySubscription.renderingData.disclaimer)?_c('div',{staticClass:"disclaimer mt-24",domProps:{"innerHTML":_vm._s(_vm.prioritySubscription.renderingData.disclaimer)}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }