<template>
  <div class="error-layout container-1440" :class="`error${httpStatus} ${$store.theme || ''}`">
    <div class="text" :class="{ 'no-image': !imageDesktop }">
      <div class="text-inner">
        <slot />
      </div>
    </div>
    <img v-if="imageDesktop" class="image-desktop" :src="imageDesktop" alt="" />
    <img v-if="imageMobile" class="image-mobile" :src="imageMobile" alt="" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class ErrorLayout extends Vue {
  @Prop()
  httpStatus?: number;

  @Prop()
  imageDesktop?: string;

  @Prop()
  imageMobile?: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.error-layout {
  position: relative;
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  background: no-repeat;
  background-size: contain;

  @media (min-width: $desktop-s-min) {
    min-height: 420px;
  }

  @include desktop-s {
    flex-basis: 80%;
  }

  @include tablet {
    margin-top: 48px;
  }

  @include mobile {
    margin-top: 32px;
  }

  &.error500 {
    justify-content: flex-start;
    min-height: 100vh;
  }

  .text {
    position: absolute;
    top: 64px;
    left: 64px;
    width: 100%;
    max-width: 464px;

    &.no-image {
      position: relative;
    }

    @include tablet {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      max-width: none;
    }

    @include mobile {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      max-width: none;
    }
  }

  .text-inner {
    padding: 24px 32px;
    letter-spacing: 0.2px;
    background-color: var(--alpha-dark-8);
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    @include tablet {
      margin: 32px 48px 16px;
    }

    @include mobile {
      padding: 16px 24px;
      margin: 32px 32px 16px;
    }

    &::v-deep {
      h2,
      h3,
      h4 {
        margin-bottom: 0;
      }
    }

    &::v-deep h2 {
      @extend %heading4;

      margin-bottom: 0;
    }

    &::v-deep p {
      @extend %body1;

      color: var(--c-light-font-secondary);

      &:first-of-type {
        margin-top: 24px;
      }
    }
  }

  .image-desktop {
    display: block;
    @include mobile {
      display: none;
    }
  }

  .image-mobile {
    display: none;
    @include mobile {
      display: block;
    }
  }

  &.dark {
    .text-inner {
      background-color: var(--alpha-light-3);
    }

    &::v-deep p {
      color: var(--c-dark-font-primary);
    }
  }
}
</style>
