<template>
  <div
    class="vod title-details title-and-channel-details"
    data-cy="vod-title-details"
    :class="[theme, { loading: isSourceAvailable && !modalTitle }]"
  >
    <div
      v-if="!vodSourcesLoading && !isSourceAvailable"
      class="error-block with-side-padding pt-64 pt-tablet-48 pt-mobile-32"
    >
      <BreadCrumbs
        v-if="isPageTitleOpen"
        class="pb-48 pb-tablet-32 pb-mobile-32"
        :crumbs="crumbs"
        theme-forced="light"
        :with-current-level="false"
      />
      <h4 v-html="getTranslation('vod_unavailable_error_profile')" />
      <h4 v-html="getTranslation('vod_unavailable_error_default')" />
    </div>

    <template v-else>
      <template v-if="modalTitle && modalTitle.preview">
        <div class="top overlay-default-before overlay-accent-after dark">
          <div
            class="modal-top-background"
            :class="{ 'non-fullscreen-poster': !backgroundPosterFullscreen }"
            :style="{ backgroundImage: `url('${backgroundPosterFullscreenPath}')` }"
          />

          <div class="inner with-side-and-bottom-padding">
            <BreadCrumbs
              v-if="isPageTitleOpen"
              class="pb-8 hidden-mobile"
              :crumbs="crumbs"
              theme-forced="dark"
              :with-current-level="false"
            />

            <TitleHgroup
              :title="modalTitle.preview.title"
              :original-title="modalTitle.preview.originalTitle"
              tag="h2"
            />

            <div class="details body1">
              <TitleRating
                v-if="ratingKinopoiskFormatted || ratingImdbFormatted"
                class="mr-32"
                :rating-kinopoisk-formatted="ratingKinopoiskFormatted"
                :rating-imdb-formatted="ratingImdbFormatted"
              />
              <TitleYears
                v-if="years"
                class="mr-32"
                theme-forced="dark"
                :show-icon="true"
                :years="yearsFormatted"
              />
              <TitleCountry
                v-if="modalTitle.preview.countries"
                class="mr-32"
                theme-forced="dark"
                :show-icon="true"
                :country="modalTitle.preview.countries[0].title"
              />
              <TitleDuration
                theme-forced="dark"
                :show-icon="true"
                :video-duration-h-m="videoDurationHM"
                :has-series="hasSeries"
                :seasons-quantity="seasonsLength"
              />
            </div>

            <TitleContinueStatus
              v-if="canPlayTitlePause"
              theme-forced="dark"
              :title="hasSeries ? lastPauseTitle : ''"
              :progress="lastPause.currentProgress"
              :time-left="lastPause.timeLeft"
              :duration="lastPause.duration"
              data-cy="vod"
            />

            <div class="play-buttons">
              <TitleButtonStart
                theme-forced="dark"
                :is-playing-title-equal-modal-title="isPlayingTitleEqualModalTitle"
                :has-player-error="hasPlayerError"
                :has-saved-pause="hasSavedPause"
                @click="clickOnTitleButtonStart"
              />
              <TitleButtonStartNextEpisode
                v-if="(isPlayingTitleEqualModalTitle || hasSavedPause) && hasSeries && nextEpisode"
                theme-forced="dark"
                @click="playNextEpisodeFromModal"
              />
            </div>
          </div>
        </div>

        <div class="bottom with-side-and-bottom-padding">
          <TilePoster
            v-if="!$store.vod.isTitleLoading"
            class="poster-image"
            theme-forced="dark"
            :width="300"
            :disable-hover="true"
            :width-proportion="posterWidthProportion"
            :height-proportion="posterHeightProportion"
            :image="backgroundPoster.path"
            :image-provider="backgroundPoster.type"
          />

          <div class="details body1">
            <BreadCrumbs
              v-if="isPageTitleOpen"
              class="pb-8 text-center"
              :crumbs="crumbs"
              :with-current-level="false"
            />

            <TitleHgroup
              class="visible-mobile mb-mobile-16"
              :title="modalTitle.preview.title"
              :original-title="modalTitle.preview.originalTitle"
            />

            <TitleRating
              class="visible-mobile"
              :rating-kinopoisk-formatted="ratingKinopoiskFormatted"
              :rating-imdb-formatted="ratingImdbFormatted"
            />

            <div class="flex-wrap">
              <TitleYears v-if="years" class="mr-20" :years="yearsFormatted" />
              <TitleCountry
                v-if="modalTitle.preview.countries"
                class="mr-20"
                :country="modalTitle.preview.countries[0].title"
              />
              <TitleDuration
                :video-duration-h-m="videoDurationHM"
                :current-progress="lastPause.currentProgress"
                :has-series="hasSeries"
                :seasons-quantity="seasonsLength"
              />
            </div>

            <TitleContinueStatus
              v-if="canPlayTitlePause"
              class="visible-mobile mb-20"
              :title="hasSeries ? lastPauseTitle : ''"
              :progress="lastPause.currentProgress"
              :time-left="lastPause.timeLeft"
              :duration="lastPause.duration"
              data-cy="vod"
            />

            <div class="play-buttons visible-mobile">
              <TitleButtonStart
                :full-width="true"
                :is-playing-title-equal-modal-title="isPlayingTitleEqualModalTitle"
                :has-player-error="hasPlayerError"
                :has-saved-pause="hasSavedPause"
                size="medium"
                @click="clickOnTitleButtonStart"
              />

              <TitleButtonStartNextEpisode
                v-if="(isPlayingTitleEqualModalTitle || hasSavedPause) && hasSeries && nextEpisode"
                :full-width="true"
                size="medium"
                @click="playNextEpisodeFromModal"
              />
            </div>

            <table>
              <tbody>
                <tr>
                  <td>
                    <TitleSource :show-translation="true" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TitleCategories
                      class="vod"
                      :show-translation="true"
                      :categories="modalTitle.preview.categories"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TitleGenres
                      class="vod"
                      :show-translation="true"
                      :genres="modalTitle.preview.genres"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TitleAgeRating
                      class="vod"
                      :age-rating="modalTitle.preview.ageRating"
                      :show-translation="true"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <TextWithTruncation
            v-if="isTitleWithDetailsLoaded && modalTitle.details.description"
            :source-text="modalTitle.details.description"
          />

          <div v-if="legalLine.used" class="title-legalline mt-16" v-text="legalLine.value" />
        </div>

        <div v-if="isTitleWithDetailsLoaded && hasSeries && episodesForNavLength" class="episodes">
          <SectionHeader
            class="with-side-padding mb-24 mb-mobile-16"
            :icon="IconBrowsingHistory"
            :text="getTranslation('episodes')"
          />
          <div class="episodes-wrap pb-64 pb-tablet-56 pb-mobile-56" data-cy="episodes-wrap">
            <SelectorSingle
              v-if="seasonsLength"
              class="with-side-padding mb-24 mb-tablet-16 mb-tablet-16"
              :limit="5"
              :items="itemsForSeason"
              :selected="currentSeasonNumForNav"
              :convert-to-dropdown-for-tables-and-mobile="true"
              @select="selectSeason"
            />

            <ContentSlider
              v-show="!shouldShowEpisodesAsGrid"
              :key="refreshKey"
              :theme-forced="theme"
              type="adaptive"
            >
              <TileThumbnail
                v-for="episode in episodesForNav"
                :key="episode.id || episode.preview.id"
                class="tile col-desktop-2 col-tablet-2 col-mobile-2"
                orientation="landscape"
                :is-active="isActive(episode)"
                :class="{
                  active: isActive(episode),
                }"
                :title="episode.title || episode.preview.title"
                :image="getSrcForThumb(episode)"
                :duration="
                  thumbDuration(
                    episode.duration || (episode.preview ? episode.preview.duration : 0)
                  )
                "
                :timeline-position="
                  episode.id === lastPause.episodeId ? lastPause.currentProgress : 0
                "
                @click.native="
                  (isSavedPause = false), playNewEpisode(episode.id || episode.preview.id)
                "
              />
            </ContentSlider>

            <div v-show="shouldShowEpisodesAsGrid" class="row with-side-padding">
              <TileThumbnail
                v-for="episode in episodesForNav"
                :key="episode.id || episode.preview.id"
                class="tile col-desktop-2 col-tablet-2 col-mobile-2 mb-24"
                orientation="landscape"
                :is-active="isActive(episode)"
                :class="{
                  active: isActive(episode),
                }"
                :title="episode.title || episode.preview.title"
                :image="getSrcForThumb(episode)"
                :duration="
                  thumbDuration(
                    episode.duration || (episode.preview ? episode.preview.duration : 0)
                  )
                "
                :timeline-position="
                  episode.id === lastPause.episodeId ? lastPause.currentProgress : 0
                "
                @click.native="
                  (isSavedPause = false), playNewEpisode(episode.id || episode.preview.id)
                "
              />
            </div>

            <div v-if="showShouldShowEpisodesAsGridButton" class="with-side-padding mb-minus-24">
              <ButtonShowAllEpisodes
                :should-show-episodes-as-grid="shouldShowEpisodesAsGrid"
                @click="toggleEpisodesAsGrid"
              />
            </div>
          </div>
        </div>

        <TitleSliderMoreTitles />
      </template>

      <LoaderSpinner v-else />
    </template>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import Global from 'src/mixins/Global';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { secondsToHM } from 'src/utils/time/convert-time';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';
import IconBrowsingHistory from 'src/svg/browsing-history.svg';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import TitleContinueStatus from 'src/components/title/TitleContinueStatus.vue';
import TitleButtonStart from 'src/components/title/TitleButtonStart.vue';
import TitleCategories from 'src/components/title/TitleCategories.vue';
import TitleGenres from 'src/components/title/TitleGenres.vue';
import TitleDuration from 'src/components/title/TitleDuration.vue';
import TitleRating from 'src/components/title/TitleRating.vue';
import TitleAgeRating from 'src/components/title/TitleAgeRating.vue';
import TitleCountry from 'src/components/title/TitleCountry.vue';
import TitleYears from 'src/components/title/TitleYears.vue';
import TitleSource from 'src/components/title/TitleSource.vue';
import TitleHgroup from 'src/components/title/TitleHgroup.vue';
import TitleSliderMoreTitles from 'src/components/title/TitleSliderMoreTitles.vue';
import { BRANDING_METHODS_OPTIONS_ID, NOTIFICATION_HIDE_TIMEOUT } from 'src/constants';
import SelectorSingle from 'src/components/ui/selector-single/SelectorSingle.vue';
import TileThumbnail from 'src/components/ui/tiles/TileThumbnail.vue';
import { convertToMinutes } from 'src/utils/time/convert-time';
import AddResizeListener from 'src/mixins/AddResizeListener';
import BreadCrumbs from 'src/components/ui/BreadCrumbs.vue';
import TextWithTruncation from 'src/components/ui/TextWithTruncation.vue';
import TitleButtonStartNextEpisode from 'src/components/title/TitleButtonStartNextEpisode.vue';
import { getResizedImageUrl } from 'src/utils/images';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { TitleDetails } from 'src/mixins/TitleDetails';
import { selectors } from 'src/store/selectors';
import { TBrandingMethodOption } from 'src/store/branding-methods/types';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import TilePoster from 'src/components/ui/tiles/TilePoster.vue';
import { getSeoBot } from 'src/utils/platform-detector';
import ButtonShowAllEpisodes from 'src/components/ui/buttons/ButtonShowAllEpisodes.vue';
// import logger from 'src/utils/logger';

// const log = logger('vod-title-details');

@Component({
  components: {
    ButtonShowAllEpisodes,
    TilePoster,
    LoaderSpinner,
    TitleButtonStartNextEpisode,
    TextWithTruncation,
    TitleSliderMoreTitles,
    TitleHgroup,
    TitleSource,
    TitleAgeRating,
    TitleRating,
    TitleCategories,
    TitleGenres,
    TitleDuration,
    TitleContinueStatus,
    ButtonDefault,
    ButtonDropdown,
    IconSVG,
    ContentSlider,
    TitleButtonStart,
    TitleYears,
    TitleCountry,
    SelectorSingle,
    TileThumbnail,
    BreadCrumbs,
    SectionHeader,
  },
})
export default class VodTitleDetails extends mixins(Global, TitleDetails, AddResizeListener) {
  IconCross = IconCross;
  IconBrowsingHistory = IconBrowsingHistory;

  brandingOptions: TBrandingMethodOption[] = [];
  shouldShowEpisodesAsGrid = false;
  isMobile: boolean | null = null;
  isTablet: boolean | null = null;
  refreshKey = 0;

  @Watch('$store.vod.states.sourcesLoaded')
  async onVodSourcesLoadedChange(val: boolean) {
    if (val) {
      await this.loadSourceCategoriesAndSetCurrentCategorId();
    }
  }

  @Watch('isTitleWithDetailsLoaded')
  onTitleDetailsLoaded(val: boolean) {
    if (val) {
      this.getBrandingOptions();
    }
  }

  @Watch('windowWidth')
  onWindowWidthChange() {
    this.isMobile = this.windowWidth < 600;
    this.isTablet = this.windowWidth < 960 && this.windowWidth > 599;
  }

  get crumbs() {
    return [
      { name: this.getTranslation('menu_homescreen'), link: '/' },
      { name: this.getTranslation('vod'), link: '/vod' },
      { name: this.getTranslation(this.sourceId), link: `/vod/${this.sourceId}` },
    ];
  }

  get isSourceAvailable() {
    return !!this.source;
  }

  get canPlayTitlePause() {
    return (
      this.isTitleWithDetailsLoaded && this.hasSavedPause && !this.isPlayingTitleEqualModalTitle
    );
  }

  get showShouldShowEpisodesAsGridButton() {
    return (
      (this.isMobile && this.episodesForNavLength > 2) ||
      (this.isTablet && this.episodesForNavLength > 4) ||
      (!this.isMobile && !this.isTablet && this.episodesForNavLength > 6)
    );
  }

  get additionalImages() {
    return this.modalTitle?.preview?.additionalImages;
  }

  get backgroundPoster296x444Path() {
    const poster = this.backgroundPoster;
    if (!poster) {
      return '';
    }
    return poster?.path ? getResizedImageUrl(poster.path, 296, 444, poster.type) : '';
  }

  get backgroundPosterFullscreen() {
    const image = this.additionalImages?.find(
      (img: { originalType: string }) => img.originalType === 'fullscreen'
    );
    return image ? getResizedImageUrl(image.path, 296, 444, image.type) : '';
  }

  get backgroundPosterFullscreenPath() {
    return this.backgroundPosterFullscreen || this.backgroundPoster296x444Path;
  }

  get videoDurationHM() {
    return secondsToHM(this.modalTitle.preview?.duration);
  }

  get legalLine() {
    return (
      this.brandingOptions?.find(
        (option) => option.id === BRANDING_METHODS_OPTIONS_ID.showLegal
      ) || { used: false, value: '' }
    );
  }

  get posterWidthProportion() {
    return selectors.vod.posterWidthProportionSelector(this.$store, this.sourceId);
  }

  get posterHeightProportion() {
    return selectors.vod.posterHeightProportionSelector(this.$store, this.sourceId);
  }

  async serverPrefetch() {
    if (getSeoBot()) {
      await this.loadSourceCategoriesAndSetCurrentCategorId();
    }
  }

  created() {
    this.getBrandingOptions();
  }

  async mounted() {
    this.onWindowWidthChange();

    if (!this.isSourceAvailable) {
      return;
    }

    await this.loadSourceCategoriesAndSetCurrentCategorId();

    if (this.$store.vod?.errors?.sourceWasNotFound) {
      setTimeout(() => {
        if (this.$store.vod.errors) {
          this.$store.vod.errors.sourceWasNotFound = null;
        }
      }, NOTIFICATION_HIDE_TIMEOUT);
    }

    await this.onTitleIdChange(this.modalTitleId);

    if (this.hasSeries) {
      if (this.seasonsLength) {
        actions.vod.setCurrentSeasonNumForNav(
          this.$store,
          this.isPlayingTitleEqualModalTitle ? this.currentSeasonNum : 0
        );
      }

      if (this.hasSavedPause) {
        await this.onHasSavedPauseChange(this.hasSavedPause);
      }
    }

    window.onpopstate = async () => {
      if (!this.titleIdFromParams) {
        actions.vod.hideTitleDetails(this.$store, this.sourceId, this.title?.preview?.id || '');
      } else {
        this.$store.vod.isTitleLoading = true;
        await actions.vod.showTitleDetails(
          this.$store,
          this.sourceId,
          this.titleIdFromParams,
          false
        );
        this.$store.vod.isTitleLoading = false;
      }
    };
  }

  getBrandingOptions() {
    this.brandingOptions =
      actions.brandingMethods.getCurrentBrandingMethods(
        this.$store,
        this.title?.details?.brandingMethods || []
      ) || [];
  }

  async loadSourceCategories() {
    if (!this.categories) {
      await actions.vod.loadSourceCategories(this.$store, this.sourceId);
    }
  }

  async loadSourceCategoriesAndSetCurrentCategorId() {
    // if TitlePage is open, then load VOD source data first
    // in order to get current categoryId for loading titles by genres for content sliders
    if (this.isPageTitleOpen) {
      await this.loadSourceCategories();
      actions.vod.setCurrentCategoryId(
        this.$store,
        this.sourceId,
        this.getCategoryIdFromTitle(this.modalTitle)
      );
    }
  }

  isActive(episode: TVODTitlePreviewEnhanced) {
    return (
      this.isPlayingTitleEqualModalTitle &&
      (this.playingEpisodeId === episode.id || this.playingEpisodeId === episode?.preview?.id)
    );
  }

  clickOnTitleButtonStart() {
    if (this.isAnonymous) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    if (this.playingTitleId) {
      this.savePause();
    }

    let episodeId = '';
    if (this.hasSavedPause) {
      this.isSavedPause = true;
      if (this.hasSeries) {
        episodeId = this.lastPause.episodeId;
      }
    } else if (this.hasSeries) {
      episodeId = this.getFirstEpisodeFromChosenSeason();
    }

    this.playTitle(episodeId).catch(() => {
      // do nothing, errors should already be logged and printed
    });

    this.gaEvent({
      category: 'vod',
      action: `Клик по кнопке "${
        this.hasSavedPause ? this.getTranslation('vod_continue') : this.getTranslation('vod_watch')
      }"`,
      vod_name: this.sourceId,
      title_id: this.titleIdFromParams || this.episodeIdFromParams,
    });
  }

  async playTitle(episodeId: string) {
    if (this.isAnonymous) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    const title = this.modalTitle;

    actions.vod.hideTitleDetails(this.$store, this.sourceId, this.modalTitleId);

    if (title) {
      actions.player.setIsPlaying(this.$store, false);

      if (title.preview?.hasSeries && episodeId) {
        actions.vod.setCurrentSeasonNum(this.$store, this.currentSeasonNumForNav);
      }

      if (!this.isPageTitleOpen) {
        await actions.vod.playVideo(this.$store, this.sourceId, title, episodeId);
      } else if (title?.preview?.id) {
        await this.saveDataToStoreAndGoToCatalogPage(title.preview.id, episodeId);
      }
    }
  }

  async playNewEpisode(episodeId: string) {
    if (this.isAnonymous) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    await this.playTitle(episodeId).catch(() => {
      // do nothing, error should already be logged and printed
    });

    this.gaEvent({
      category: 'vod',
      action: 'Выбор серии в тайтле',
      title_id: this.title?.preview?.id,
      vod_name: this.sourceId,
    });
  }

  getFirstEpisodeFromChosenSeason() {
    const episode =
      this.seasons?.[this.currentSeasonNumForNav]?.episodes?.[0] ||
      this.episodesForNav?.[0] ||
      null;
    return episode?.id || episode?.preview?.id || '';
  }

  selectSeason(num: number) {
    this.refreshKey++;
    actions.vod.setCurrentSeasonNumForNav(this.$store, num);

    this.gaEvent({
      category: 'vod',
      action: 'Выбор сезона',
      season_num: `${num + 1}`,
      title_id: this.title?.preview?.id,
    });
  }

  thumbDuration(duration: number): string {
    return duration ? `${Math.round(convertToMinutes(duration, 'second'))} мин` : '';
  }

  getSrcForThumb(episode: TVODTitlePreviewEnhanced) {
    let src: string;
    let type = '';

    if (episode.thumbnails?.[0]) {
      src = episode.thumbnails[0].path;
      type = episode.thumbnails[0].type;
    } else if (episode.preview?.thumbnails?.[0]) {
      src = episode.preview.thumbnails[0].path;
      type = episode.preview.thumbnails[0].type;
    } else {
      src = `/public/images/placeholders/placeholder-poster-horizontal-${this.$store.theme}.png`;
      src += `?v${this.$store.imagesMTimes[src]}`;
    }

    return this.prepareThumb(src, type);
  }

  prepareThumb(
    src: string,
    type: string
  ): {
    src: string;
    width: number;
    height: number;
    orientation: 'portrait' | 'landscape';
    type: string;
  } {
    return { src, width: 180, height: 120, type: type, orientation: 'landscape' };
  }

  toggleEpisodesAsGrid() {
    this.shouldShowEpisodesAsGrid = !this.shouldShowEpisodesAsGrid;

    this.gaEvent({
      category: 'vod',
      action: `Клик "${this.shouldShowEpisodesAsGrid ? 'Показать все серии' : 'Свернуть'}"`,
      vod_name: this.sourceId,
      title_id: this.title?.preview?.id,
    });
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/title-and-channel-details';
@import 'src/styles/common/title-details';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.title-details {
  .top {
    .title-hgroup {
      @include mobile {
        display: none;
      }
    }
  }

  .bottom {
    .poster-image {
      right: 64px;
      width: 296px;

      @include desktop-s {
        right: 48px;
        width: 224px;
      }

      @include tablet {
        right: 48px;
        width: 128px;
      }

      @include tablet-s {
        display: none;
      }

      @include mobile {
        right: 50%;
        width: 146px;
        margin-right: -73px;
      }
    }
  }

  table {
    tr {
      &:last-child {
        .title-details-info {
          &.vod {
            padding-bottom: 0;
            border-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
