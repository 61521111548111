import moize from 'moize';

const DAYS_IN_MONTH = 30;
const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
const MILLISECONDS_IN_SECOND = 1000;

const names = ['month', 'day', 'hour', 'minute', 'second', 'millisecond'];
const constituents = [
  DAYS_IN_MONTH,
  HOURS_IN_DAY,
  MINUTES_IN_HOUR,
  SECONDS_IN_MINUTE,
  MILLISECONDS_IN_SECOND,
  1, // To have the same length as names array and for proper Array.slice() behaviour
];

const convertTo =
  (newUnitOfTime = 'millisecond') =>
  (value = 0, unitOfTime = 'month' || 'months') => {
    if (unitOfTime.endsWith('s')) {
      unitOfTime = unitOfTime.slice(0, -1);
    }

    let start = names.indexOf(unitOfTime);
    let end = names.indexOf(newUnitOfTime);

    if ([start, end].some((value) => value === -1)) {
      throw new Error(
        `Invalid unit of time is provided. Valid units are: ${names.join(
          ', '
        )}. Both singular and plural.`
      );
    }

    const isBackwards = end < start;

    if (isBackwards) {
      const temp = start;
      start = end;
      end = temp;
    }

    return (
      value *
      constituents.slice(start, end).reduce((mult, val) => mult * val, 1) ** (isBackwards ? -1 : 1)
    );
  };

export const convertToMilliseconds = moize(convertTo('millisecond'));
export const convertToSeconds = moize(convertTo('second'));
export const convertToMinutes = moize(convertTo('minute'));
export const convertToHours = moize(convertTo('hour'));
export const convertToDays = moize(convertTo('day'));
export const convertToMonths = moize(convertTo('month'));

export const secondsToHM = (seconds?: number) => {
  if (!seconds) {
    return '';
  }

  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const hDisplay = h > 0 ? `${h} ч` : '';
  const mDisplay = m > 0 ? `${m} мин` : '';

  const sDisplay = seconds > 0 && seconds < 60 ? `${Math.floor(seconds)} сек` : '';
  return `${hDisplay} ${mDisplay} ${sDisplay}`;
};
