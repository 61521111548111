<template>
  <div
    class="title-details title-and-channel-details"
    data-cy="archive-title-details"
    :class="[theme, { loading: !isArchiveAvailable && !modalTitle }]"
  >
    <div
      v-if="!vodSourcesLoading && !isArchiveAvailable"
      class="error-block with-side-padding pt-64 pt-tablet-48 pt-mobile-32"
    >
      <BreadCrumbs
        v-if="isPageTitleOpen"
        class="pb-48 pb-tablet-32 pb-mobile-32"
        :crumbs="crumbs"
        theme-forced="light"
      />

      <h4 v-html="getTranslation('vod_unavailable_error_profile')" />
      <h4 v-html="getTranslation('vod_unavailable_error_default')" />
    </div>

    <template v-else>
      <template v-if="modalTitle && modalTitle.preview">
        <div class="top overlay-default-before overlay-accent-after dark">
          <div
            class="modal-top-background"
            :style="{ backgroundImage: `url('${backgroundPosterPath}')` }"
          />

          <div class="inner with-side-and-bottom-padding">
            <BreadCrumbs v-if="isPageTitleOpen" class="pb-8" :crumbs="crumbs" theme-forced="dark" />

            <TitleHgroup
              :title="modalTitle.preview.title"
              :original-title="modalTitle.preview.originalTitle"
            />

            <div class="details body1">
              <TitleRating
                v-if="ratingKinopoiskFormatted || ratingImdbFormatted"
                class="mr-32"
                :rating-kinopoisk-formatted="ratingKinopoiskFormatted"
                :rating-imdb-formatted="ratingImdbFormatted"
              />
              <TitleYears
                v-if="years"
                class="mr-32"
                theme-forced="dark"
                :show-icon="true"
                :years="yearsFormatted"
              />
              <TitleCountry
                v-if="modalTitle.preview.countries"
                class="mr-32"
                theme-forced="dark"
                :show-icon="true"
                :country="modalTitle.preview.countries[0].title"
              />
              <TitleDuration
                theme-forced="dark"
                :show-icon="true"
                :video-duration-h-m="videoDurationHM"
                :has-series="hasSeries"
                :seasons-quantity="seasonsLength"
              />
            </div>

            <TitleContinueStatus
              v-if="canPlayTitlePause"
              theme-forced="dark"
              :title="lastPauseTitle"
              :progress="lastPause.currentProgress"
              :time-left="lastPause.timeLeft"
              :duration="lastPause.duration"
              data-cy="archive"
            />

            <div class="play-buttons">
              <TitleButtonStart
                v-if="canPlayTitle"
                theme-forced="dark"
                :is-playing-title-equal-modal-title="isPlayingTitleEqualModalTitle"
                :has-player-error="hasPlayerError"
                :has-saved-pause="!!lastPause.currentProgress"
                @click="clickOnTitleButtonStart"
              />
              <TitleButtonStartNextEpisode
                v-if="(isPlayingTitleEqualModalTitle || hasSavedPause) && hasSeries && nextEpisode"
                theme-forced="dark"
                @click="playNextEpisodeFromModal"
              />
            </div>
          </div>
        </div>

        <div class="bottom with-side-and-bottom-padding">
          <TilePoster
            v-if="!$store.vod.isTitleLoading"
            class="poster-image"
            theme-forced="dark"
            :width="300"
            :disable-hover="true"
            :width-proportion="posterWidthProportion"
            :height-proportion="posterHeightProportion"
            :image="backgroundPoster.path"
            :image-provider="backgroundPoster.type"
          />

          <div class="details body1">
            <TitleRating
              class="visible-mobile"
              :rating-kinopoisk-formatted="ratingKinopoiskFormatted"
              :rating-imdb-formatted="ratingImdbFormatted"
            />

            <div class="flex-wrap">
              <TitleYears v-if="years" class="mr-20" :years="yearsFormatted" />
              <TitleCountry
                v-if="modalTitle.preview.countries"
                class="mr-20"
                :country="modalTitle.preview.countries[0].title"
              />
              <TitleDuration
                :video-duration-h-m="videoDurationHM"
                :current-progress="lastPause.currentProgress"
                :has-series="hasSeries"
                :seasons-quantity="seasonsLength"
              />
            </div>

            <TitleContinueStatus
              v-if="canPlayTitlePause"
              class="visible-mobile mb-20"
              :title="lastPauseTitle"
              :progress="lastPause.currentProgress"
              :time-left="lastPause.timeLeft"
              :duration="lastPause.duration"
              data-cy="archive"
            />

            <div class="play-buttons visible-mobile">
              <TitleButtonStart
                v-if="canPlayTitle"
                :full-width="true"
                :is-playing-title-equal-modal-title="isPlayingTitleEqualModalTitle"
                :has-player-error="hasPlayerError"
                :has-saved-pause="hasSavedPause"
                size="medium"
                @click="clickOnTitleButtonStart"
              />

              <TitleButtonStartNextEpisode
                v-if="(isPlayingTitleEqualModalTitle || hasSavedPause) && hasSeries && nextEpisode"
                size="medium"
                :full-width="true"
                @click="playNextEpisodeFromModal"
              />
            </div>

            <table>
              <tbody>
                <tr>
                  <td>
                    <TitleCategories
                      class="vod"
                      :show-translation="true"
                      :categories="modalTitle.preview.categories"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TitleGenres
                      class="vod"
                      :show-translation="true"
                      :genres="modalTitle.preview.genres"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TitleAgeRating
                      class="vod"
                      :age-rating="modalTitle.preview.ageRating"
                      :show-translation="true"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="titleOptions" class="mb-48">
              <TitleOption
                v-for="option in titleOptions"
                :key="option.titleEn"
                class="vod"
                :option="option"
                :show-translation="true"
              />
            </div>
          </div>

          <TextWithTruncation
            v-if="isTitleWithDetailsLoaded && modalTitle.details.description"
            :source-text="modalTitle.details.description"
          />
        </div>

        <div class="media-items with-side-and-bottom-padding">
          <template v-if="isTitleWithDetailsLoaded && modalTitle.details.mediaItems">
            <SectionHeader
              class="mb-24 mb-mobile-16"
              :icon="IconBrowsingHistory"
              :text="`${getTranslation('air')}: ${modalTitle.preview.title}`"
            />

            <div class="media-wrap" data-cy="media-wrap">
              <MediaItem
                v-for="mediaItem in modalTitle.details.mediaItems"
                :id="mediaItem.id"
                :key="mediaItem.id"
                :theme-forced="theme"
                :title="mediaItem.title"
                :channel-title="getChannelTitle(getChannelById(mediaItem.channelId))"
                :img="getChannelLogo(getChannelById(mediaItem.channelId))"
                :is-playing="playingMediaItemId === mediaItem.id"
                :progress="
                  lastPause.id && mediaItem.id === lastPause.mediaItemId
                    ? lastPause.currentProgress
                    : 0
                "
                @click="
                  playMediaItem(mediaItem.id);
                  gaEvent({
                    category: 'archive',
                    action: 'Клик по эфиру',
                    title_id: modalTitleId,
                    vod_name: 'archive',
                  });
                "
              />
            </div>
          </template>

          <div v-if="hasSeries && filteredEpisodes.length">
            <SectionHeader
              class="mb-24 mb-mobile-16"
              :icon="IconBrowsingHistory"
              :text="getTranslation('episodes')"
            />

            <SelectorSingle
              v-if="seasonsLength"
              class="mb-24 mb-tablet-16 mb-tablet-16"
              :items="itemsForSeason"
              :selected="currentSeasonNumForNav"
              :limit="5"
              :placeholder="getTranslation('genres')"
              @select="selectSeason"
            />

            <div class="media-wrap" data-cy="media-wrap">
              <MediaList
                v-for="(episode, i) in filteredEpisodes"
                :key="getEpisodeData(episode).id + i.toString()"
                :theme-forced="theme"
                :class="{ hidden: episode.hidden }"
                :episode-id="getEpisodeData(episode).id"
                :title="getEpisodeData(episode).title"
                :list="prepareMediaList(episode)"
                :is-playing="playingEpisodeId === getEpisodeData(episode).id"
                :progress="
                  lastPause.mediaItemId &&
                  prepareMediaList(episode).find((item) => item.id === lastPause.mediaItemId)
                    ? lastPause.currentProgress
                    : 0
                "
                @clickEpisode="playFirstMediaItemFromEpisode"
                @clickMediaItem="playMediaItemFromEpisode"
              />

              <template v-if="filteredEpisodes.length > 5 && !showAllEpisodes">
                <ButtonDefault
                  class="show-all-episodes"
                  view="tertiary"
                  :full-width="true"
                  :with-icon="true"
                  @click="showAllEpisodes = true"
                >
                  <IconSVG :svg="IconMoreHorizontal" />
                  <span v-html="showAllEpisodesButtonText" />
                </ButtonDefault>
              </template>
            </div>
          </div>
        </div>

        <TitleSliderMoreTitles />
      </template>

      <LoaderSpinner v-else />
    </template>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import plurar from 'plural-ru';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';
import IconMoreHorizontal from 'src/svg/more-horizontal.svg';
import IconBrowsingHistory from 'src/svg/browsing-history.svg';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import MediaItem from 'src/components/ui/media/MediaItem.vue';
import MediaList from 'src/components/ui/media/MediaList.vue';
import TitleContinueStatus from 'src/components/title/TitleContinueStatus.vue';
import TitleButtonStart from 'src/components/title/TitleButtonStart.vue';
import TitleButtonStartNextEpisode from 'src/components/title/TitleButtonStartNextEpisode.vue';
import TitleCategories from 'src/components/title/TitleCategories.vue';
import TitleGenres from 'src/components/title/TitleGenres.vue';
import TitleDuration from 'src/components/title/TitleDuration.vue';
import TitleRating from 'src/components/title/TitleRating.vue';
import TitleAgeRating from 'src/components/title/TitleAgeRating.vue';
import TitleHgroup from 'src/components/title/TitleHgroup.vue';
import TextWithTruncation from 'src/components/ui/TextWithTruncation.vue';
import TitleSliderMoreTitles from 'src/components/title/TitleSliderMoreTitles.vue';
import BreadCrumbs from 'src/components/ui/BreadCrumbs.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { Watch } from 'vue-property-decorator';
import isEmpty from 'lodash/isEmpty';
import Vue from 'vue';
import { VOD_PAUSE_RESET } from 'src/constants';
import TitleYears from 'src/components/title/TitleYears.vue';
import TitleCountry from 'src/components/title/TitleCountry.vue';
import TitleOption from 'src/components/title/TitleOption.vue';
import SelectorSingle from 'src/components/ui/selector-single/SelectorSingle.vue';
import Global from 'src/mixins/Global';
import { TitleDetails } from 'src/mixins/TitleDetails';
import { titlePlaybackMethodParamsSelector } from 'src/store/archive/selectors';
import { loadPlaybackInfoDetailsForArchive } from 'src/store/archive/actions';
import PlayerVodArchive from 'src/components/player/PlayerVodArchive';
import { getChannelLogo, getChannelTitle } from 'src/utils/channel';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { TChannelEnhanced } from 'src/api/channels/types';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import TilePoster from 'src/components/ui/tiles/TilePoster.vue';
import { getSeoBot } from 'src/utils/platform-detector';
// import logger from 'src/utils/logger';

// const log = logger('archive-title-details');

interface TEpisodeWithHidden extends TVODTitlePreviewEnhanced {
  hidden?: boolean;
}

@Component({
  components: {
    TilePoster,
    TitleOption,
    LoaderSpinner,
    TitleAgeRating,
    TitleRating,
    TextWithTruncation,
    TitleCategories,
    TitleGenres,
    TitleDuration,
    TitleContinueStatus,
    ButtonDefault,
    IconSVG,
    MediaItem,
    MediaList,
    TitleButtonStart,
    TitleButtonStartNextEpisode,
    TitleHgroup,
    TitleSliderMoreTitles,
    BreadCrumbs,
    SelectorSingle,
    TitleYears,
    TitleCountry,
    SectionHeader,
  },
})
export default class ArchiveTitleDetails extends mixins(Global, TitleDetails, PlayerVodArchive) {
  IconCross = IconCross;
  IconMoreHorizontal = IconMoreHorizontal;
  IconBrowsingHistory = IconBrowsingHistory;

  isLastPauseProcessed = false;
  episodesAfterFilter: TEpisodeWithHidden[] = [];
  showAllEpisodes = false;

  @Watch('isEpisodesAndPausesDataLoaded')
  async onIsEpisodesAndPausesDataLoadedChange() {
    const episodes = this.getEpisodes();
    if (!episodes?.length) {
      return;
    }
    // if the last pause is old, and the episode does not exist anymore,
    // then reset last pause data
    if (!episodes.find((e) => e.preview?.id === this.lastPause.episodeId)) {
      Vue.set(this.$store.pauseData.vod, 'lastPause', VOD_PAUSE_RESET);
    }
  }

  get crumbs() {
    return [
      { name: this.getTranslation('menu_homescreen'), link: '/' },
      { name: this.getTranslation('archive'), link: '/archive' },
      { name: this.modalTitle?.preview?.title || '', link: '' },
    ];
  }

  get titleOptions() {
    const options = this.title?.options || undefined;

    if (options) {
      return Object.keys(options).reduce((acc: Array<{ key: string; value: string }>, option) => {
        acc.push({ key: option, value: (options as Record<string, string>)[option] });
        return acc;
      }, []);
    }

    return [];
  }

  get isArchiveAvailable() {
    return !isEmpty(this.$store.vod.sources?.archive);
  }

  get posterWidthProportion() {
    return selectors.archive.archivePosterWidthProportionSelector(this.$store);
  }

  get posterHeightProportion() {
    return selectors.archive.archivePosterHeightProportionSelector(this.$store);
  }

  get filteredEpisodes() {
    this.episodesAfterFilter = [];
    this.episodesForNav?.forEach((episode, i: number) => {
      if (!episode) {
        return;
      }
      const temp = episode as TEpisodeWithHidden;
      temp.hidden = i > 4 && !this.showAllEpisodes;
      this.episodesAfterFilter.push(temp);
    });

    return this.episodesAfterFilter;
  }

  get isEpisodesAndPausesDataLoaded() {
    return this.hasSeries && !!this.lastPause.titleId && this.getEpisodes()?.length;
  }

  get videoDurationHM() {
    return selectors.archive.titleDurationSelectorHM(this.$store, false);
  }

  get mediaItemWithPause() {
    if (this.hasSeries) {
      const episode = this.modalTitle?.episodes?.find(
        (e) => e?.preview?.id === this.lastPause.episodeId
      );

      return episode?.details?.mediaItems?.find(
        (mediaItem) => mediaItem.id === this.lastPause.mediaItemId
      );
    } else {
      return this.modalTitle?.details?.mediaItems?.find(
        (mediaItem) => mediaItem.id === this.lastPause.mediaItemId
      );
    }
  }

  get showAllEpisodesButtonText() {
    return `${this.getTranslation('show_all')} (${this.filteredEpisodes.length} ${plurar(
      this.filteredEpisodes.length,
      this.getTranslation('episode'),
      this.getTranslation('episode_genitive'),
      this.getTranslation('episodes_genitive')
    )})`;
  }

  get canPlayTitle() {
    return (
      this.isLastPauseProcessed &&
      (!!this.modalTitle?.details?.mediaItems?.length ||
        (this.hasSeries && !!this.getEpisodes()?.length))
    );
  }

  get canPlayTitlePause() {
    return (
      this.isTitleWithDetailsLoaded &&
      this.lastPause.id &&
      this.canPlayTitle &&
      this.hasSavedPause &&
      !this.isPlayingTitleEqualModalTitle &&
      (!this.hasSeries || this.isEpisodesAndPausesDataLoaded)
    );
  }

  async serverPrefetch() {
    if (getSeoBot()) {
      actions.vod.setCurrentCategoryId(
        this.$store,
        this.sourceId,
        this.getCategoryIdFromTitle(this.modalTitle)
      );
    }
  }

  async mounted() {
    if (this.modalTitleId) {
      await this.onTitleIdChange(this.modalTitleId);
    }

    if (this.hasSeries) {
      if (this.seasonsLength) {
        actions.vod.setCurrentSeasonNumForNav(
          this.$store,
          this.isPlayingTitleEqualModalTitle ? this.currentSeasonNum : 0
        );
      }
    }
    // if TitlePage is open, then load VOD source data first
    // in order to get current categoryId for loading titles by genres for content sliders
    if (this.isPageTitleOpen) {
      await this.loadSourceCategories();
      actions.vod.setCurrentCategoryId(
        this.$store,
        this.sourceId,
        this.getCategoryIdFromTitle(this.modalTitle)
      );
    }

    const pmParams = titlePlaybackMethodParamsSelector(this.$store, false);
    if (pmParams) {
      await loadPlaybackInfoDetailsForArchive(this.$store, pmParams.channel_id);
    }

    if (this.hasSavedPause) {
      await this.onHasSavedPauseChange(this.hasSavedPause);
    }

    this.isLastPauseProcessed = true;

    window.onpopstate = async () => {
      if (!this.titleIdFromParams) {
        this.$store.vod.isTitleLoading = true;
        await actions.vod.hideTitleDetails(
          this.$store,
          this.sourceId,
          this.title?.preview?.id || ''
        );
        this.$store.vod.isTitleLoading = false;
      }
    };
  }

  getEpisodeData(episode: TVODTitlePreviewEnhanced) {
    if (episode.preview) {
      return episode.preview;
    } else {
      return episode;
    }
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  async loadSourceCategories() {
    if (!this.categories || !this.vodSourcesLoaded) {
      await actions.vod.loadSourceCategories(this.$store, this.sourceId);
    }
  }

  selectSeason(num: number) {
    actions.vod.setCurrentSeasonNumForNav(this.$store, num);
  }

  clickOnTitleButtonStart() {
    if (this.isAnonymous) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    this.gaEvent({
      category: 'archive',
      action: `Клик по кнопке "${
        this.hasSavedPause ? this.getTranslation('vod_continue') : this.getTranslation('vod_watch')
      }"`,
      vod_name: 'archive',
      title_id: this.modalTitleId,
    });

    if (this.playingTitleId) {
      this.savePause();
    }

    if (this.hasSavedPause && this.lastPause?.mediaItemId) {
      if (this.hasSeries) {
        this.playMediaItemFromEpisode(this.lastPause.episodeId, this.lastPause.mediaItemId);
      } else {
        this.playMediaItem(this.lastPause.mediaItemId);
      }
      return;
    }

    if (this.hasSeries) {
      this.playFirstMediaItemFromFirstEpisode();
    } else {
      this.playTitle();
    }
  }

  async playTitle() {
    const titleId = this.modalTitleId;
    actions.vod.hideTitleDetails(this.$store, this.sourceId, titleId);

    if (titleId) {
      if (!this.isPageTitleOpen) {
        await actions.archive.playVideo(this.$store, {
          titleId,
        });
      } else {
        await this.saveDataToStoreAndGoToCatalogPage(titleId, '', '');
      }
    }
  }

  async playMediaItem(mediaItemId: string) {
    if (this.isAnonymous) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    const titleId = this.modalTitleId;
    actions.vod.hideTitleDetails(this.$store, this.sourceId, this.modalTitleId);

    if (titleId && mediaItemId) {
      if (!this.isPageTitleOpen) {
        await actions.archive.playVideo(this.$store, {
          titleId,
          mediaItemId,
          fromStart: mediaItemId === this.lastPause.mediaItemId ? this.lastPause.fromStart : 0,
        });
      } else {
        await this.saveDataToStoreAndGoToCatalogPage(titleId, '', mediaItemId);
      }
    }
  }

  async playMediaItemFromEpisode(episodeId: string, mediaItemId: string) {
    if (this.isAnonymous) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    actions.vod.setCurrentSeasonNum(this.$store, this.currentSeasonNumForNav);

    const titleId = this.modalTitleId;
    actions.vod.hideTitleDetails(this.$store, this.sourceId, this.modalTitleId);

    if (titleId && episodeId && mediaItemId) {
      if (!this.isPageTitleOpen) {
        await actions.archive.playVideo(this.$store, {
          titleId,
          episodeId,
          mediaItemId,
          fromStart: mediaItemId === this.lastPause.mediaItemId ? this.lastPause.fromStart : 0,
        });
      } else {
        await this.saveDataToStoreAndGoToCatalogPage(titleId, episodeId, mediaItemId);
      }
    }
  }

  async playFirstMediaItemFromEpisode(episodeId: string) {
    if (this.isAnonymous) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    actions.vod.setCurrentSeasonNum(this.$store, this.currentSeasonNumForNav);

    const titleId = this.modalTitleId;
    const episode = this.getEpisodes()?.find((e) => e?.preview?.id === episodeId);
    const mediaItemId = episode?.details?.mediaItems ? episode?.details?.mediaItems[0]?.id : '';
    actions.vod.hideTitleDetails(this.$store, this.sourceId, this.modalTitleId);

    if (titleId && episodeId && mediaItemId) {
      if (!this.isPageTitleOpen) {
        await actions.archive.playVideo(this.$store, { titleId, episodeId, mediaItemId });
      } else {
        await this.saveDataToStoreAndGoToCatalogPage(titleId, episodeId, mediaItemId);
      }
    }
  }

  async playFirstMediaItemFromFirstEpisode() {
    actions.vod.setCurrentSeasonNum(this.$store, this.currentSeasonNumForNav);

    const titleId = this.modalTitleId;
    const episode =
      this.seasons?.[this.currentSeasonNumForNav - 1]?.episodes?.[0] ||
      this.episodesForNav?.[0] ||
      null;
    const episodeId = episode?.preview?.id;
    const mediaItemId = episode?.details?.mediaItems ? episode?.details?.mediaItems[0]?.id : '';
    actions.vod.hideTitleDetails(this.$store, this.sourceId, this.modalTitleId);

    if (titleId && episodeId && mediaItemId) {
      if (!this.isPageTitleOpen) {
        await actions.archive.playVideo(this.$store, {
          titleId,
          episodeId,
          mediaItemId,
        });
      } else {
        await this.saveDataToStoreAndGoToCatalogPage(titleId, episodeId, mediaItemId);
      }
    }
  }

  prepareMediaList(episode: TVODTitlePreviewEnhanced) {
    return episode.details?.mediaItems?.map((item) => {
      const channel = this.getChannelById(item.channelId);
      return {
        id: item.id,
        title: item.title,
        channelName: channel ? this.getChannelTitle(channel) : '',
        img: channel ? this.getChannelLogo(channel) : '',
        isPlaying: this.playingMediaItemId === item.id,
        progress: item.id === this.lastPause.mediaItemId ? this.lastPause.currentProgress : 0,
      };
    });
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/title-and-channel-details';
@import 'src/styles/common/title-details';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.title-details {
  .bottom {
    .poster-image {
      right: 64px;
      width: 296px;

      @include desktop-s {
        right: 48px;
        width: 224px;
      }

      @include mobile-and-tablet {
        display: none;
      }
    }

    .details.visible-mobile {
      display: none;
    }

    .description {
      margin-top: 0;
    }

    .title-details-info.vod {
      @include mobile {
        display: block;
      }
    }
  }

  .genres,
  .categories,
  .year,
  .country {
    margin-right: 20px;
    margin-bottom: 4px;
  }

  // MEDIA ITEMS
  .media-wrap {
    max-width: 920px;
    margin-right: -16px;
    margin-left: -16px;

    > .media-item {
      margin-bottom: 12px;

      @include mobile {
        margin-bottom: 8px;
      }
    }

    .media-list {
      margin-bottom: 12px;

      @include mobile {
        margin-bottom: 8px;
      }

      &.hidden {
        display: none;
      }
    }
  }
}
</style>
