<template>
  <div class="title-details-info" :class="theme">
    <span v-if="showTranslation" class="left" :class="`color-${theme}-font-secondary`">
      <span v-html="getTranslation(`title_${option.key}`)" />:
    </span>
    <span class="age" v-text="option.value" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component
export default class TitleOption extends SequoiaComponent {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: false })
  showTranslation?: boolean;

  @Prop()
  option?: { key: string; value: string };

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>
