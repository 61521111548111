import { getEnvVars } from 'src/config';
import { store } from 'src/store';

export const isOkUrl = (url: any) => {
  try {
    new URL(url);
  } catch (err) {
    return false;
  }
  return true;
};

export const getCurrentUrl = () => new URL(window.location.href);

export const prependWithBeBaseUrl = (url = '') => {
  if (url.startsWith('//') || url.startsWith('http:') || url.startsWith('https:')) {
    return url;
  }

  return getEnvVars().beBaseUrl + (url.startsWith('/') ? '' : '/') + url;
};

export const matchUrlProtocolWithLocationProtocol = (url: string, isHttps: boolean) =>
  isHttps ? useHttpsProtocol(url) : url;

export const useHttpsProtocol = (url: string) =>
  new URL(url).protocol === 'http:' ? url.replace('http://', 'https://') : url;

export const addParamToQueryString = (url: string, param: string, value: string) => {
  const urlBeginWithHttp = url.startsWith('http');
  const urlBeginWithSlash = url.startsWith('/');
  const urlParsed = new URL(
    urlBeginWithHttp ? url : `http://smotreshka.tv${urlBeginWithSlash ? '' : '/'}${url}`
  );
  urlParsed.searchParams.append(param, value);

  return urlBeginWithHttp ? urlParsed.toString() : urlParsed.pathname + urlParsed.search;
};

/**
 * Get rid of port in url to make it work with https protocol
 */
export const removePortFromServerUrl = (url: string) => {
  const _url = url.split('/');
  const domain = _url[2].split(':');
  _url[2] = domain[0];
  return _url.join('/');
};

export const isUrlExternal = (url?: string) => {
  if (!url) {
    return;
  }
  const regExp = new RegExp('^(?:[a-z]+:)?//', 'i');
  return regExp.test(url);
};

export const getArchiveTitleWatchUrl = (titleId: string, episodeId = '', mediaItemId = '') =>
  ['archive', titleId, episodeId, mediaItemId, 'watch']
    .filter((slug) => slug)
    .map((slug) => `/${slug}`)
    .join('');

export const getVodTitleWatchUrl = (sourceId: string, titleId: string, episodeId = '') =>
  ['vod', sourceId, titleId, episodeId, 'watch']
    .filter((slug) => slug)
    .map((slug) => `/${slug}`)
    .join('');

export const createLinkTag = (url: string) => {
  const tmp = document.createElement('a');
  tmp.href = url;
  return tmp;
};

export const makePath = (path: string) => {
  return store.languageCode === store.defaultLanguage
    ? path === ''
      ? `/`
      : path
    : `/${store.languageCode}${path}`;
};
