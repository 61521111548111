import Vue from 'vue';
import VueEvents from 'vue-events';
import * as api from 'src/api';
import { TStore } from 'src/store/types';
import { isAnonymousSelector } from 'src/store/account/selectors';
import { serverTimeMsSelector } from 'src/store/app-info/selectors';
import { currentChannelIdSelector } from 'src/store/tv-current-channel/selectors';
import {
  isFastForwardingSelector,
  isFullscreenSelector,
  isOverlayFrozenSelector,
  isPlayerMinimizedSelector,
  isRewindingSelector,
  isStopSelector,
  overlayVisibleSelector,
  playDurationMsSelector,
  playingTimeMsSelector,
  videoVolumeSelector,
} from 'src/store/player/selectors';
import {
  dvrLeftBoundSelector,
  isDvrDisabledSelector,
  isDvrRestrictedSelector,
} from 'src/store/tv-epg/selectors';
import * as tvEpgActions from 'src/store/tv-epg/actions';

import throttle from 'lodash/throttle';
import {
  EVENTS,
  HIDE_PLAYER_ALERT_DELAY,
  SCREEN_MIN_WIDTH_FOR_SHOWING_PLAYER,
  STORE_KEYS,
  THRESHOLDS,
  TV_PLAYER_HIDE_OVERLAY_TIMEOUT,
} from 'src/constants';
import { storage } from 'src/utils';
import { TPlayerErrorCodes, TPlayerErrorOptions } from 'src/store/player/types';
import { gaEvent, GaEventParams } from 'src/utils/metrics';
import { convertToMilliseconds, convertToSeconds } from 'src/utils/time/convert-time';
import logger from 'src/utils/logger';
import { HTMLDivWithFullscreenElement } from 'src/components/player/types';
import { getDeviceFlags } from 'src/utils/platform-detector';
import { boundMinMax } from 'src/utils/number';
import { DateTime } from 'src/utils/time/date-time';
import { setShowNotificationWithDetails } from 'src/store/common/actions';

const log = logger('player');

export const setPlayerType = (store: TStore, type: 'tv' | 'vod' | 'archive' | '' = '') => {
  store.player.type = type;
};

export const setPushHistoryStateForChannel = (store: TStore, val: boolean) => {
  store.player.pushHistoryStateForChannel = val;
};

export const setHasSubtitles = (store: TStore, val: boolean) =>
  (store.player.video.hasSubtitles = val);

export const goFullscreen = (store: TStore, player?: HTMLDivWithFullscreenElement) => {
  if (!player) {
    log.error('player html element has not been passed');
    return;
  }

  if (player.requestFullscreen) player.requestFullscreen().then();
  else if (player.msRequestFullscreen) player.msRequestFullscreen();
  else if (player.mozRequestFullScreen) player.mozRequestFullScreen();
  else if (player.webkitRequestFullScreen) player.webkitRequestFullScreen();

  toggleFullscreen(store);
};

export const exitFullscreen = (store: TStore) => {
  if (document.exitFullscreen) document.exitFullscreen().then();
  else if (document.msExitFullscreen) document.msExitFullscreen().then();
  else if (document.mozCancelFullScreen) document.mozCancelFullScreen().then();
  else if (document.webkitExitFullscreen) document.webkitExitFullscreen().then();

  toggleFullscreen(store);
};

export const exitFullscreenOnAndroid = (store: TStore, isFullscreen: boolean) => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    log.error('to be used on client-side only');
    return;
  }

  if (window.innerHeight < window.innerWidth && getDeviceFlags().isAndroid && isFullscreen) {
    exitFullscreen(store);
  }
};

// ------------------------------------------------------
// Volume
// ------------------------------------------------------

const clearHideVolumeSliderTimeout = (store: TStore) => {
  if (store.player.volumeSlider.hideTimeoutId) {
    clearTimeout(store.player.volumeSlider.hideTimeoutId);
  }
};

export const showVolumeSlider = (store: TStore) => {
  clearHideVolumeSliderTimeout(store);
  store.player.volumeSlider.visible = true;
};

export const hideVolumeSlider = (store: TStore) => {
  clearHideVolumeSliderTimeout(store);
  store.player.volumeSlider.hideTimeoutId = setTimeout(
    () => (store.player.volumeSlider.visible = false),
    1000
  );
};

export const changeVolume = (store: TStore, volume: number) => {
  log.info('changeVolume');
  const updatedVolume = volume > 1 ? 1 : volume < 0 ? 0 : volume;
  store.player.video.volume = updatedVolume;
  log.info('Initial volume', volume, 'updated', updatedVolume);
};

export const actualizeVolume = (store: TStore, video?: HTMLVideoElement) => {
  if (!video) {
    return;
  }

  const volume = videoVolumeSelector(store);
  video.volume = volume > 1 ? 1 : volume < 0 ? 0 : volume;
};

const resetMute = (store: TStore) => {
  if (!!storage.get(STORE_KEYS.player.mute)) {
    toggleMute(store);
  }
};

export const volumeUp = (store: TStore) => {
  log.info('volumeUp');
  showOverlay(store);
  resetMute(store);
  if (store.player.video.volume < 0.96) {
    store.player.video.volume = Math.min(1, store.player.video.volume + 0.05);
  }
  storage.set(STORE_KEYS.player.volume, store.player.video.volume);
};

export const volumeDown = (store: TStore) => {
  log.info('volumeDown');
  showOverlay(store);
  resetMute(store);
  if (store.player.video.volume > 0.05) {
    store.player.video.volume = Math.max(0, store.player.video.volume - 0.05);
  } else if (store.player.video.volume <= 0.05) {
    store.player.video.volume = 0;
    toggleMute(store);
  }
  storage.set(STORE_KEYS.player.volume, store.player.video.volume);
};

export const changeMute = (store: TStore, muted: boolean) => {
  store.player.video.muted = muted;
};

export const toggleMute = (store: TStore) => {
  log.info('toggleMute');
  store.player.video.muted = !store.player.video.muted;
  storage.set(STORE_KEYS.player.mute, store.player.video.muted);

  gaEvent(
    {
      category: 'player_controls',
      action: `${store.player.video.muted ? 'Выключить' : 'Включить'} звук`,
    },
    store
  );
};

export const mute = (store: TStore) => {
  log.info('mute');
  store.player.video.muted = true;
};

export const unmute = (store: TStore) => {
  log.info('unmute');
  store.player.video.muted = false;
};

// ------------------------------------------------------

export const goLive = (store: TStore, $events: VueEvents) => {
  store.player.isLive = true;
  store.player.video.playingTimeMs = serverTimeMsSelector(store);
  $events.emit(EVENTS.player.play);
  setPickedEpochDay(store, DateTime.toEpochDay(new Date()));
};

export const actualizeStartTime = (store: TStore) => {
  store.player.video.startTimeMs = serverTimeMsSelector(store) || 0;
};

export const updatePlayingTime = (store: TStore, timestamp: number) => {
  const now = serverTimeMsSelector(store);
  const dvrLeftBound = dvrLeftBoundSelector(store);
  let playingTimeMs = boundMinMax(dvrLeftBound, timestamp, now);

  if (isDvrDisabledSelector(store) || isDvrRestrictedSelector(store) || isStopSelector(store)) {
    playingTimeMs = now;
  }

  store.player.video.playingTimeMs = playingTimeMs;

  tvEpgActions.actualizeCurrentProgramIndex(store);

  if (now === playingTimeMs) {
    setIsLive(store, true);
  }
};

export const setWasPlayingBeforeOffline = (store: TStore, val: boolean) =>
  (store.player.video.wasPlayingBeforeOffline = val);

export const setIsPlaying = (store: TStore, val: boolean) => {
  log.info('setIsPlaying', val);
  store.player.video.isPlaying = val;
};

export const startLoading = (store: TStore) => {
  log.info('startLoading');
  store.player.loading = true;
};

export const stopLoading = (store: TStore) => {
  log.info('stopLoading');
  store.player.loading = false;
};

export const setReady = (store: TStore, ready: boolean) => {
  log.info('setReady', ready);
  store.player.ready = ready;
};

export const setLoaded = (store: TStore, loaded: boolean) => {
  log.info('setLoaded', loaded);
  store.player.loaded = loaded;
};

export const setError = (
  store: TStore,
  errorCode: TPlayerErrorCodes,
  errorMessage = '',
  options: TPlayerErrorOptions = {}
) => {
  log.error('setError', errorCode, errorMessage);
  stopLoading(store);

  const err = {
    code: errorCode,
    message: errorMessage,
    options,
  };

  Vue.set(store.player, 'error', err);

  if (
    isPlayerMinimizedSelector(store) ||
    (process.env.VUE_ENV === 'client' &&
      document.documentElement?.clientWidth < SCREEN_MIN_WIDTH_FOR_SHOWING_PLAYER)
  ) {
    setShowNotificationWithDetails(store, true);
  } else {
    lockScroll(store);
  }

  if (isFullscreenSelector(store)) {
    exitFullscreen(store);
  }
};

export const clearError = (store: TStore) => {
  log.info('clearError');
  Vue.set(store.player, 'error', null);
};

export const setAlert = (store: TStore, alert: string) => {
  log.info('setAlert', alert);
  store.player.alert = alert;
};

export const clearAlert = (store: TStore) => {
  store.player.alert = '';
};

export const resetPoster = (store: TStore) => {
  log.info('resetPoster');
  store.player.video.poster = '';
};

export const setPoster = (store: TStore, poster: string) => {
  log.info('setPoster', poster);
  store.player.video.poster = poster;
};

export const showHelp = (store: TStore) => {
  gaEvent(
    {
      category: 'player_controls',
      action: 'Открытие туториала',
      channel_name: store.tvCurrentChannel?.name,
    },
    store
  );
  store.player.isHelpVisible = true;
};

export const hideHelp = (store: TStore, controlType?: string) => {
  const params: GaEventParams = {
    category: 'player_controls',
    action: 'Закрытие туториала',
    channel_name: store.tvCurrentChannel?.name,
  };
  if (controlType) {
    params.control_type = controlType;
  }
  gaEvent(params, store);
  store.player.isHelpVisible = false;
};

export const toggleFullscreen = (store: TStore) => {
  store.player.video.isFullscreen = !store.player.video.isFullscreen;
};

export const setHasVideoEnded = (store: TStore, val: boolean) => {
  store.player.hasVideoEnded = val;
};

export const showEpgInfo = (store: TStore) => {
  store.player.isEpgVisible = true;
};

export const hideEpgInfo = (store: TStore) => {
  store.player.isEpgVisible = false;
  releaseOverlay(store);
};

export const toggleEpgInfo = (store: TStore) => {
  if (store.player.isEpgVisible) {
    hideEpgInfo(store);
  } else {
    showEpgInfo(store);
  }
};

export const setOverlayVisibility = (store: TStore, val: boolean) => {
  const action = val ? 'show' : 'hide';
  log.info(`${action} overlay`);
  store.player.overlay.visible = val;
};

export const freezeOverlay = (store: TStore) => {
  store.player.overlay.frozen = true;
};

export const releaseOverlay = (store: TStore) => {
  store.player.overlay.frozen = false;
};

export const showFullscreenMenu = (store: TStore) => {
  hideDvrWarningOverlay(store);
  store.player.isFullscreenMenuVisible = true;
};

export const hideFullscreenMenu = (store: TStore) => {
  store.player.isFullscreenMenuVisible = false;
};

export const showSettingsMenu = (store: TStore) => {
  store.player.isSettingsMenuVisible = true;
};

export const hideSettingsMenu = (store: TStore) => {
  store.player.isSettingsMenuVisible = false;
};

const doShowOverlay = (store: TStore) => {
  if (store.player.overlay.hideTimeoutId) {
    clearTimeout(store.player.overlay.hideTimeoutId);
    store.player.overlay.hideTimeoutId = null;
  }

  if (!overlayVisibleSelector(store)) {
    setOverlayVisibility(store, true);
  }

  if (store.player.video.isPlaying && store.player.loading) {
    setTimeout(() => showOverlay(store), convertToMilliseconds(1, 'second'));
    return;
  }

  if (!isRewindingSelector(store) && !isFastForwardingSelector(store)) {
    store.player.overlay.hideTimeoutId = setTimeout(
      () => hideOverlay(store),
      THRESHOLDS.tv.hideOverlayAfterMilliseconds
    );
  }
};

export const showOverlay = throttle(doShowOverlay, TV_PLAYER_HIDE_OVERLAY_TIMEOUT);

export const hideOverlay = (store: TStore) => {
  if (isOverlayFrozenSelector(store)) {
    return;
  }

  if (store.player.alert) {
    // if alert is visible - wait until alert will disappear and then hide overlay
    store.player.overlay.hideTimeoutId = setTimeout(() => {
      setOverlayVisibility(store, false);
    }, HIDE_PLAYER_ALERT_DELAY);
    return;
  }

  setOverlayVisibility(store, false);
  store.player.touchedTimes = 0;
};

/**
 * Sending statistics to FE via /send-stats/channel-playing
 */
export const sendStats = (store: TStore) => {
  if (isAnonymousSelector(store) || !playingTimeMsSelector(store)) {
    // do not send statistic for anonymous users
    return;
  }

  const playDuration = convertToSeconds(playDurationMsSelector(store), 'millisecond');
  const playbackTime = convertToSeconds(playingTimeMsSelector(store), 'millisecond');

  if (playDuration <= 0 || !currentChannelIdSelector(store)) {
    // do not send statistic if video was not played
    // or if currentChannelId is empty
    return;
  }

  api.statistics.sendStats({
    ChannelId: currentChannelIdSelector(store),
    PlaybackTime: Math.round(playbackTime),
    EventDuration: playDuration,
  });
};

// ------------------------------------------------------
// DVR warning
// ------------------------------------------------------

export const showDvrWarningOverlay = (store: TStore) => {
  store.player.dvrWarningOverlay.isVisible = true;
};

export const hideDvrWarningOverlay = (store: TStore) => {
  store.player.dvrWarningOverlay.isVisible = false;
};

export const setDvrWarningOverlayData = (store: TStore, channelId: string, message: string) => {
  store.player.dvrWarningOverlay.channelId = channelId;
  store.player.dvrWarningOverlay.message = message;
};

// --------------------------------------------------------

export const setPickedEpochDay = (store: TStore, day: number) => {
  store.player.pickedEpochDay = day;
};

export const resetPickedEpochDay = (store: TStore) => {
  store.player.pickedEpochDay = DateTime.toEpochDay(new Date());
};

export const setAutoplay = (store: TStore, val: boolean) => {
  store.player.autoplay = val;
};

export const setTimelineBalloonFollowingMouse = (store: TStore, value: boolean) => {
  store.player.timeline.isBalloonFollowingMouse = value;
};

export const setIsLive = (store: TStore, isLive: boolean) => {
  store.player.isLive = isLive;
};

// ------------------------------------------------------
// Common FOR ALL players
// ------------------------------------------------------

export const minimizePlayer = (store: TStore) => {
  store.player.minimized = true;
};

export const expandPlayer = (store: TStore) => {
  store.player.minimized = false;
};

export const showPlayer = (store: TStore) => {
  store.player.visible = true;
};

export const hidePlayer = (store: TStore) => {
  store.player.visible = false;
};

export const lockScroll = (store: TStore) => {
  store.player.isScrollLocked = true;
};

export const unlockScroll = (store: TStore) => {
  store.player.isScrollLocked = false;
};
