import Vue from 'vue';
import * as api from 'src/api';
import { TStore } from 'src/store/types';
import { TVerificationData } from './types';
import {
  currentOfferIdSelector,
  currentOfferSelector,
  offersSelector,
} from 'src/store/registration/selectors';
import { TTrialStatusAfterRegistration } from 'src/api/trials/types';
import { getTrialStatusAfterRegistration } from 'src/api/trials';
import { GET_TRIAL_STATUS_ERROR_CODE, REGISTRATION_TRIAL_STATUSES } from 'src/constants';
import { loadChannelsAndGenres } from 'src/store/tv-channels/actions';
import {
  createYakassaSubscriptionV2,
  getAllSubscriptionOptions,
} from 'src/store/quick-subscribe/actions';
import { TReqConfig } from 'src/api/utils';
import { cleanupPhone } from 'src/utils/phone';
import { makePath } from 'src/utils/url';
import { sessionStore } from 'src/utils/storage';

export const retry = async (store: TStore, config?: TReqConfig) => {
  store.contactsVerification = await api.auth.contactsVerification.retry(config);
};

export const verify = async (store: TStore, config?: TReqConfig) => {
  const result = await api.auth.contactsVerification.verify(config);
  Vue.set(store, 'verificationResult', result);
  sessionStore.set('verificationResult', result);
  return result;
};

export const verifyContacts = async (
  store: TStore,
  phone?: string,
  captcha?: string | null,
  reason?: string,
  params = {},
  useRecaptcha = false,
  cookies = ''
) => {
  const withCaptcha = typeof captcha === 'string';

  let method: keyof typeof api.auth.contactsVerification = 'startWithAccountSession';

  let data: TVerificationData = {
    type: 'phone',
    contacts: cleanupPhone(phone, store.authAndReg.usePlusInPhoneMethods.registration),
    reason,
  };
  if (useRecaptcha) {
    method = 'startRecaptcha';
    data = {
      type: 'phone',
      contacts: cleanupPhone(phone, store.authAndReg.usePlusInPhoneMethods.registration),
      'g-recaptcha-response': captcha,
      reason,
    };
  } else if (withCaptcha) {
    method = 'start';
    data = {
      type: 'phone',
      contacts: cleanupPhone(phone, store.authAndReg.usePlusInPhoneMethods.registration),
      captcha,
      reason,
      cookies,
    };
  }

  store.contactsVerification = await api.auth.contactsVerification[method]({ data, params });
};

export const registerWithToken = async (store: TStore, config?: TReqConfig) => {
  const result = await api.auth.registerWithToken(config);
  Vue.set(store, 'registrationResult', result);
  return result;
};

export const registerMobileWithToken = async (store: TStore, config?: TReqConfig) => {
  const result = await api.auth.registerMobileWithToken(config);
  Vue.set(store, 'registrationResult', result);
  return result;
};

// -----------------------------------------------------------------------------------------
// After registration was completed
// -----------------------------------------------------------------------------------------

export const loadSubscriptionOptions = async (store: TStore) => {
  if (!store.flags.tvDataState.loaded) {
    await loadChannelsAndGenres(store);
  }

  await getAllSubscriptionOptions(store);
  const offers = offersSelector(store);

  if (offers[0]) {
    setCurrentOfferId(store, offers[0].id);
  }
};

export const setCurrentOfferId = (store: TStore, currentOfferId: string) => {
  store.registration.currentOfferId = currentOfferId;
};

export const createSubscription = async (store: TStore, utmParams = '') => {
  const currentOfferId = currentOfferIdSelector(store);
  const currentOffer = currentOfferSelector(store);
  if (!currentOffer) {
    store.registration.createSubscriptionError = 'Оффер не доступен';
    return;
  }
  let confirmationUrl;
  const returnUrl = utmParams
    ? `${location.origin}${makePath('/acquiring-status')}?${utmParams}`
    : `${location.origin}${makePath('/acquiring-status')}`;

  const response = await createYakassaSubscriptionV2(store, {
    data: {
      offer_id: currentOfferId,
      return_url: returnUrl,
    },
    params: { create_subscription_token: currentOffer.createSubscriptionToken },
  }).catch(async (e: Error) => {
    const subscriptionsList = await api.quickSubscribe.subscriptions();
    const pendingSubscription = subscriptionsList.find(
      (subscription) => subscription.offer.id === currentOfferId
    );
    if (pendingSubscription) {
      confirmationUrl = pendingSubscription.last_payment.confirmation.confirmation_url;
    } else {
      store.registration.createSubscriptionError = e.toString();
    }
  });
  if (response) {
    confirmationUrl = response.last_payment.confirmation.confirmation_url;
  }
  if (confirmationUrl) {
    store.registration.confirmationUrl = confirmationUrl;
  }
};

export const disableTrials = (store: TStore) => {
  store.registration.disableTrials = true;
};

const setTrialStatusAfterRegistrationAsUnknown = (store: TStore) => {
  store.registration.autoTrialStatus =
    REGISTRATION_TRIAL_STATUSES.unknown as TTrialStatusAfterRegistration;
};

/**
 * Recursive action to get trial status after registration
 * @param store
 * @param attempt
 * @param parentResolve - this function will be passed to child calls from the parent
 * @param parentReject - same as parentResolve
 */
export const loadTrialStatusAfterRegistration = async (
  store: TStore,
  attempt = 0,
  parentResolve?: (value: boolean) => void,
  parentReject?: () => void
) => {
  const response = await getTrialStatusAfterRegistration().catch(() => {
    // in case if there is now response - consider status as unknown
  });

  if (!response) {
    // no response - dead end, just set status as unknown and return void
    setTrialStatusAfterRegistrationAsUnknown(store);
    return;
  }

  // updating status
  store.registration.autoTrialStatus = response.status;
  store.registration.autoTrialDuration = response.duration;

  if (response.status === REGISTRATION_TRIAL_STATUSES.processStarted) {
    // if status is "process_started" - send on every 5th second new request to get final status in 5 attempts
    return new Promise((childResolve, childReject) => {
      const resolve = parentResolve ? parentResolve : childResolve;
      const reject = parentReject ? parentReject : childReject;
      if (store.registration.autoTrialStatus !== REGISTRATION_TRIAL_STATUSES.processStarted) {
        // resolved
        resolve(true);
      } else if (attempt > 4) {
        // stop and show try again screen
        setTrialStatusAfterRegistrationAsUnknown(store);
        reject({ code: GET_TRIAL_STATUS_ERROR_CODE });
      } else {
        // next attempt after 5 seconds
        setTimeout(async () => {
          await loadTrialStatusAfterRegistration(store, attempt + 1, resolve, reject);
        }, 5000);
      }
    });
  }
};
