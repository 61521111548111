var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currentCategory && _vm.currentCategory.title)?_c('div',{staticClass:"title-slider-more-titles",class:_vm.theme},_vm._l((_vm.titlesByGenres),function(titlesByGenre){return _c('div',{key:titlesByGenre.genre.id,staticClass:"block"},[_c('SectionHeader',{staticClass:"with-side-padding mb-24 mb-mobile-16",attrs:{"text":_vm.getSectionHeaderText(titlesByGenre)}}),_c('ContentSlider',{key:_vm.refreshKey,attrs:{"theme-forced":_vm.theme,"type":"adaptive"},on:{"gaScroll":function($event){_vm.gaScroll(_vm.getSectionHeaderText(titlesByGenre))}}},_vm._l((titlesByGenre.titles),function(title){return _c('TilePoster',{key:title.id,staticClass:"col-desktop-2 col-desktop-s-3 col-tablet-2 col-mobile-2",attrs:{"countries":title.countries,"duration":title.duration,"genres":title.genres,"height-proportion":_vm.posterHeightProportion(_vm.sourceId),"image":title.posters[0].path,"link":_vm.preparePosterLink(title, _vm.sourceId),"logo":_vm.getSourceLogoUrl(),"original-title":title.originalTitle,"rating-imdb":title.ratingImdb,"rating-kinopoisk":title.ratingKp,"source":_vm.sourceId,"title":title.title,"title-branding-methods":title.brandingMethods,"width-proportion":_vm.posterWidthProportion(_vm.sourceId),"years":title.years},on:{"click":function($event){_vm.gaEvent({
            category: 'vod',
            action: 'Клик по тайтлу в стрипе',
            strip_name: _vm.getSectionHeaderText(titlesByGenre),
            vod_name: _vm.sourceId,
          });
          _vm.switchTitleModal(title.id);}}})}),1)],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }