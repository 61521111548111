const inBrowser = typeof window !== 'undefined';
const noop = () => {
  // do nothing
};

export const isPassiveSupported = () => {
  if (!inBrowser) return;
  let support = false;
  try {
    const opts = Object.defineProperty({}, 'passive', {
      get: function () {
        support = true;
      },
    });
    window.addEventListener('test', noop, opts);
    window.removeEventListener('test', noop);
  } catch (err) {}
  return support;
};

const getStyle = (el: HTMLElement, prop: any) =>
  typeof getComputedStyle !== 'undefined'
    ? getComputedStyle(el, null).getPropertyValue(prop)
    : el.style[prop];

const getOverflow = (el: HTMLElement) =>
  getStyle(el, 'overflow') + getStyle(el, 'overflow-y') + getStyle(el, 'overflow-x');

export const findScrollableParent = (el: HTMLElement) => {
  if (!inBrowser) return;
  if (!(el instanceof HTMLElement)) {
    return window;
  }
  let parent = el;
  while (parent) {
    if (parent === document.body || parent === document.documentElement) {
      break;
    }
    if (!parent.parentNode) {
      break;
    }
    if (/(scroll|auto)/.test(getOverflow(parent))) {
      return parent;
    }
    parent = parent.parentNode as HTMLElement;
  }

  return window;
};

export const loadImage = (src: string) =>
  new Promise<{ naturalHeight: number; naturalWidth: number; src: string }>((resolve, reject) => {
    const image = new Image();
    if (!src) {
      const err = new Error('empty src');
      return reject(err);
    }

    image.src = src;

    image.onload = () => {
      resolve({
        naturalHeight: image.naturalHeight,
        naturalWidth: image.naturalWidth,
        src: image.src,
      });
    };

    image.onerror = (e) => {
      reject(e);
    };
  });
