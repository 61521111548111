import { TStore } from 'src/store/types';

export const languageCodeSelector = (store: TStore) => store.languageCode;

export const localTimeSelector = (store: TStore) => store.common.localTime;

export const isOfflineSelector = (store: TStore) => store.common.isOffline;

export const isModalOpenSelector = (store: TStore) => store.common.isModalOpen;

export const howManyTimesBodyScrollWasLockedSelector = (store: TStore) =>
  store.common.howManyTimesBodyScrollWasLocked;

export const showFooterSelector = (store: TStore) => store.common.showFooter;

export const isProfileLoadingSelector = (store: TStore) => store.common.isProfileLoading; // TODO move to profiles

export const isProfileLoadedSelector = (store: TStore) => store.common.isProfileLoaded; // TODO move to profiles

export const channelCollectionsDataStateLoadedSelector = (store: TStore) =>
  store.flags.channelCollectionsDataState.loaded;

// -----------------------------------------------------------------------------------------------
// Notifications
// -----------------------------------------------------------------------------------------------

export const showNotificationWithDetailsSelector = (store: TStore) =>
  store.common.showNotificationWithDetails;

export const showNotificationAuthAndRegSelector = (store: TStore) =>
  store.common.showNotificationAuthAndReg;

export const showNotificationSubscriptionPaymentErrorSelector = (store: TStore) =>
  store.common.showNotificationSubscriptionPaymentError;

export const showNotificationRetrySelector = (store: TStore) => store.common.showNotificationRetry;

export const showNotificationReviewSelector = (store: TStore) =>
  store.common.showNotificationReview;

// -----------------------------------------------------------------------------------------------
// Tokens
// -----------------------------------------------------------------------------------------------

export const audienceTokenSelector = (store: TStore) => {
  return store.common.assetTokens.audienceToken;
};

export const audienceTokenValueSelector = (store: TStore) => {
  return audienceTokenSelector(store)?.token || '';
};

export const tvAssetTokenSelector = (store: TStore) => {
  return store.common.assetTokens.tvAssetToken;
};

export const tvAssetTokenValueSelector = (store: TStore) => {
  return tvAssetTokenSelector(store)?.token || '';
};

// --------------------------------------------
// Authorizaion & Registration
// --------------------------------------------

export const authAndRegPasswordMinSelector = (store: TStore) => {
  const min: number | undefined = store.authAndReg?.password?.min;
  return Number(min) || 4;
};

export const authAndRegPasswordMaxSelector = (store: TStore) => {
  const max: number | undefined = store.authAndReg?.password?.max;
  return Number(max) || 32;
};

export const activeTrialIdFromAdmin = (store: TStore) => {
  return store.authAndReg?.regTrials?.activeTrialId;
};
