<template>
  <DropdownSequoia
    v-if="isMounted && filteredSorting.length"
    v-slot="{ value, opened, disabled, toggle }"
    :list="filteredSorting"
    :transform-native-select="true"
    width="240px"
    class="vod-sorting"
    :class="{ mobile: isMobile }"
    @select="selectSorting"
  >
    <ButtonDropdown
      class="vod-sorting"
      width="content"
      data-cy="sorting"
      :floating="true"
      :placeholder="getTranslation('sorting')"
      :disabled="disabled"
      :icon="IconSort"
      :open="opened"
      :value="value"
      @click="toggle"
    />
  </DropdownSequoia>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import * as api from 'src/api';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import IconSort from 'src/svg/sort.svg';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import logger from 'src/utils/logger';
import { getDeviceFlags } from 'src/utils/platform-detector';
import { wlDetector } from 'src/utils';
import { actions } from 'src/store/actions';

const log = logger('vod-sorting');

interface TSorting {
  name: string;
  options: Array<{ title: string; direction: string }>;
  title: string;
}

@Component({
  components: {
    ButtonDropdown,
    DropdownSequoia,
  },
})
export default class VodSorting extends SequoiaComponent {
  IconSort = IconSort;
  listOfSortings?: {
    key: string;
    name: string;
    selected: boolean;
    value: string;
  }[];
  sortings?: TSorting[] = [];
  isMounted = false;

  @Prop({ required: true })
  sourceId!: string;

  get isMobile() {
    return getDeviceFlags().isMobile;
  }

  get isUztel() {
    return wlDetector().isUztel;
  }

  get filteredSorting(): TDropdownItem[] {
    return (
      (this.listOfSortings = this.sortings?.map((sorting: TSorting, i: number) => ({
        key: (i + 1).toString(),
        name: sorting.name,
        selected: i === 0,
        value: sorting.title,
      }))) || []
    );
  }

  async mounted() {
    this.sortings = (
      await api.vod.getTitlesMeta(this.sourceId).catch((err) => log.error(err))
    )?.sortings;
    this.isMounted = true;
  }

  selectSorting(item: TDropdownItem) {
    actions.vod.setSorting(this.$store, item.name || '');
    this.$emit('loadTitlesWithSorting', item.name);
    this.gaEvent({
      category: 'vod',
      action: 'Клик по дропдауну  "Сортировка"',
      sorting: item.value,
    });
  }
}
</script>
