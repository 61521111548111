<template>
  <div class="filter-columns" :data-cy="'menu-filters-' + dataCy" :class="[themeForced]">
    <div v-if="title" class="filter-col-title caption1" v-html="title" />
    <div class="filter-col-list">
      <ChipSelector
        v-for="(item, i) in itemsList"
        :key="item.id + i.toString()"
        class="filter-col-item"
        :text="item.value"
        :selected="item.selected"
        :theme-forced="themeForced"
        :data-cy="dataCy"
        @click.native="$emit('select', item)"
      />

      <button
        v-show="checkCutList"
        type="button"
        class="link action-colored mt-4"
        @click="showAllList"
        v-html="showAllButtonText || getTranslation('show_all')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ChipSelector from 'src/components/ui/ChipSelector.vue';
import { Prop } from 'vue-property-decorator';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';

@Component({
  components: { ChipSelector },
})
export default class VodMenuFiltersColumn extends SequoiaComponent {
  cutList = true;

  @Prop()
  title?: string;

  @Prop()
  showAllButtonText?: string;

  @Prop()
  countDefaultItems!: number;

  @Prop()
  list!: Array<TDropdownItem>;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  dataCy?: string;

  get checkCutList() {
    return this.cutList && this.list.length > this.countDefaultItems;
  }

  get itemsList() {
    const list = [...new Map(this.list.map((item) => [item['value'], item])).values()];
    return this.checkCutList ? list.slice(0, this.countDefaultItems - 1) : list;
  }

  showAllList() {
    this.cutList = false;
  }
}
</script>

<style lang="scss" scoped>
.filter-columns {
  .filter-col-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;

    .filter-col-item {
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .link {
      height: 24px;
    }
  }

  .filter-col-title {
    color: var(--c-light-font-tertiary);
  }

  &.dark {
    .filter-col-title {
      color: var(--c-dark-font-tertiary);
    }
  }
}
</style>
