import * as api from 'src/api';
import Vue from 'vue';
import { TStore } from 'src/store/types';
import {
  DEFAULT_VOD_CATEGORIES,
  HTTP_CODES,
  IVI_FORBIDDEN_CODES,
  PLAYBACK_METHODS,
  VOD_SERIES_DATA_RESET,
  VOD_TITLES_DEFAULT_LIMIT,
  VOD_VIDEO_DATA_RESET,
} from 'src/constants';
import {
  TTitlesGetParams,
  TTitlesSearchParams,
  TVodSource,
  TVodVideoData,
  VODHomeCollectionEnhanced,
} from 'src/store/vod/types';
import { wlDetector } from 'src/utils';
import sortBy from 'lodash/sortBy';
import {
  currentCollectionSelector,
  currentEpisodeIdSelector,
  currentEpisodeNumSelector,
  currentSeasonIdSelector,
  currentSeasonNumForNavSelector,
  currentSeasonNumSelector,
  isPageTitleOpenSelector,
  isTitleLoadingSelector,
  listOfAllSourceIdsSelector,
  listOfSourceIdsWithoutArchiveSelector,
  nextEpisodeNumSelector,
  nextSeasonNumSelector,
  playingEpisodeIdSelector,
  playingEpisodeWithoutSeasonIndexSelector,
  playingTitleIdSelector,
  playingTitleSelector,
  sourceCategoriesLoadedSelector,
  sourceCategoriesLoadingSelector,
  sourcesLoadedSelector,
  sourcesLoadingSelector,
  sourcesSelector,
  sourcesWithoutArchiveSelector,
  videoDataSelector,
} from './selectors';
import { isAnonymousSelector } from 'src/store/account/selectors';
import {
  hideModal,
  saveAssetTokens,
  setShowNotificationWithDetails,
  showModal,
} from 'src/store/common/actions';
import { gaEvent } from 'src/utils/metrics';
import { TPlaybackMethod } from 'src/store/archive/types';
import { handleForVod } from '../quick-subscribe/actions';
import { lastVodPause } from '../pauses/selectors';
import { makePath, getVodTitleWatchUrl, matchUrlProtocolWithLocationProtocol } from 'src/utils/url';
import { setMetaTitle } from '../seo/actions';
import { convertToMilliseconds } from 'src/utils/time/convert-time';
import { setError, showPlayer } from 'src/store/player/actions';
import TitleOptions from '../../../tmp/archive-title-options.json';
import { salesPhoneFormattedSelector, urlSelector } from 'src/store/provider-info/selectors';
import { loadProviderInfo } from 'src/store/provider-info/actions';
import { audienceTokenValueSelector, languageCodeSelector } from 'src/store/common/selectors';
import { isOkObject } from 'src/utils/object';
import { getByLanguage, transliterate } from 'src/utils/language';
import { loadAssetTokens } from 'src/api/tokens';
import {
  TVODTitleEnhanced,
  TVODTitleEnhancedOption,
  TVODTitlePreviewEnhanced,
} from 'src/api/vod/types';
import { VODTitlePreview } from 'src/models/ts/vod/v2/vod';
import { VODSource } from 'src/models/ts/vod/v2/sources';
import logger from 'src/utils/logger';
import { scrollEverythingToTop } from 'src/utils/scroll-everything-to-top';
import { errorCodeSelector } from 'src/store/player/selectors';
import { getAmediaDrmStreamUrl, getDrmSystem } from 'src/utils/drm';
import { loadVodPauses } from 'src/store/pauses/actions';

const log = logger('vod-actions');

const VODTitlePreviewEnhancedDefault = {
  additionalImages: [],
  ageRating: '',
  availableOnTvChannels: [],
  brandingMethods: [],
  categories: [],
  countries: [],
  duration: 0,
  genres: [],
  hasSeries: false,
  id: '',
  image: '',
  link: '',
  originalTitle: '',
  posters: [],
  ratingImdb: 0,
  ratingKp: 0,
  ratingSource: 0,
  thumbnails: [],
  title: '',
  years: [],
};

// -----------------------------------------------------------------------------------------------
// Data
// -----------------------------------------------------------------------------------------------
export const loadSources = async (store: TStore) => {
  if (sourcesLoadedSelector(store) || sourcesLoadingSelector(store)) {
    return;
  }

  log.info(
    `Do you need to load VOD sources?`,
    !sourcesLoadedSelector(store) && !sourcesLoadingSelector(store)
  );

  store.vod.states.sourcesLoading = true;
  let loadVodError = false;

  const sourcesFe: VODSource[] =
    (
      await api.vod.getSources().catch((err) => {
        log.error('api.vod.getSources ->', err);
        loadVodError = true;
        return { sources: [] };
      })
    )?.sources || [];

  Vue.set(store.vod, 'sourcesFe', sourcesFe);
  store.vod.states.sourcesLoading = false;
  log.info('VOD FE sources have been loaded & set to store');

  if (!store.vod.sourcesFe.length) {
    store.vod.states.sourcesLoaded = !loadVodError;
    return;
  }

  const sources: Record<string, TVodSource> = {};

  store.vod.sourcesFe
    .filter(
      (source: any) =>
        source.id === 'archive' ||
        store.vod.sourcesBe.find((s: any) => s.id === source.id)?.isUsed ||
        wlDetector().isSanta
    )
    .sort((a: { priority: number }, b: { priority: number }) => a?.priority - b?.priority)
    .forEach((source: any) => {
      source.fullTitles = {};
      let _source: TVodSource;
      const sourceIndex = store.vod.sourcesBe.findIndex((s: any) => s.id === source.id);

      if (source.id !== 'archive') {
        _source = {
          ...source,
          color: store.vod.sourcesBe[sourceIndex]?.color || '',
          customTitle: store.vod.sourcesBe[sourceIndex]?.customTitle || '',
          customTitleId: store.vod.sourcesBe[sourceIndex]?.customTitleId || '',
          imageTileVod: store.vod.sourcesBe[sourceIndex]?.imageTileVod || '',
          isUsed: store.vod.sourcesBe[sourceIndex]?.isUsed || wlDetector().isSanta,
          logo: store.vod.sourcesBe[sourceIndex]?.logo || '',
          logoId: store.vod.sourcesBe[sourceIndex]?.logoId || '',
          logoTilePoster: store.vod.sourcesBe[sourceIndex]?.logoTilePoster || '',
          logoTilePosterDark: store.vod.sourcesBe[sourceIndex]?.logoTilePosterDark || '',
          posterProportions: {
            height: store.vod.sourcesBe[sourceIndex]?.posterProportions.height || 3,
            width: store.vod.sourcesBe[sourceIndex]?.posterProportions.width || 2,
          },
        };
      } else {
        _source = { ...source };
      }
      sources[source.id] = _source;
    });

  Vue.set(store.vod, 'sources', sources);
  store.vod.states.sourcesLoaded = !loadVodError;
  log.info('VOD BE + FE sources have been set to store');
};

export const resetSources = (store: TStore) => {
  // BE sources should not be reset
  Vue.set(store.vod, 'sources', {});
  Vue.set(store.vod, 'sourcesFe', {});
  store.vod.states.sourcesLoaded = false;
};

export const loadSourceCategories = async (store: TStore, sourceId: string) => {
  if (
    sourceCategoriesLoadedSelector(store, sourceId) ||
    sourceCategoriesLoadingSelector(store, sourceId) ||
    !store.vod.sources[sourceId]
  ) {
    return;
  }

  store.vod.sources[sourceId].categoriesLoading = true;

  const { categories } = await api.vod.getSourceCategories(sourceId);

  if (
    !categories || // null and undefined check
    (Object.keys(categories).length === 0 && isOkObject(categories))
  ) {
    log.error(`Categories for sourceId = ${sourceId} are absent.`);
  }

  Vue.set(store.vod.sources[sourceId], 'categories', categories);

  store.vod.sources[sourceId].categoriesLoaded = true;
  store.vod.sources[sourceId].categoriesLoading = false;

  log.info(`Categories data has loaded for sourceId = ${sourceId}`);
};

// ----------------------------------------------------------------------------------------------------------------
// Vod + Archive shared actions
// ----------------------------------------------------------------------------------------------------------------
const generateVodMetaTitle = (store: TStore, sourceId: string, titleId: string) => {
  const titlePreview = store.vod?.sources?.[sourceId]?.fullTitles[titleId]?.preview;
  const type = titlePreview?.categories?.[0]
    ? getByLanguage(
        store.translations[
          `category_${transliterate(titlePreview?.categories?.[0]?.title || '')}_singular`
        ],
        store.languageCode
      ) || ''
    : '';
  const year = titlePreview?.years?.[0];
  const sourceTitle = sourceId === 'archive' ? 'archive_meta_title' : 'vod_meta_title';

  return (getByLanguage(store.translations[sourceTitle], store.languageCode) || '')
    .replace(/%titleName%/g, titlePreview?.title || '')
    .replace(/%year%/g, year ? `(${year})` : '')
    .replace(/%titleType%/g, type)
    .replace(/%vodName%/g, store.vod?.sources?.[sourceId]?.customTitle?.[store.languageCode] || '');
};

export const setSorting = (store: TStore, sorting: string) => {
  store.vod.sorting = sorting;
};

export const resetSorting = (store: TStore) => {
  store.vod.sorting = '';
};

export const showTitleDetails = async (
  store: TStore,
  sourceId: string,
  titleId: string,
  isModal = true
) => {
  if (!titleId) {
    log.error(`titleId was not passed to showTitleDetails`);
    return;
  }

  setModalTitleId(store, titleId);
  showModal(store);
  store.vod.modalTitleIdsHistory.push(titleId);
  store.vod.isModalTitleOpen = true;

  if (isModal) {
    const url = sourceId === 'archive' ? `/archive/${titleId}` : `/vod/${sourceId}/${titleId}`;
    history.pushState({}, '', makePath(url));
  }

  if (
    store.vod.sources[sourceId] &&
    !Object.keys(store.vod.sources[sourceId].fullTitles).includes(titleId)
  ) {
    await loadTitleVod(store, sourceId, titleId);
  }

  if (process.env.VUE_ENV === 'client') {
    scrollEverythingToTop();

    if (store.vod.sources[sourceId].fullTitles[titleId]?.preview) {
      setMetaTitle(store, generateVodMetaTitle(store, sourceId, titleId));
    }
  }
};

export const hideTitleDetails = (
  store: TStore,
  sourceId: string,
  titleId: string,
  metaTitlePrefix = ''
) => {
  setModalTitleId(store, '');
  store.vod.modalTitleIdsHistory = [];
  store.vod.isModalTitleOpen = false;
  hideModal(store);

  const metaTitleVod =
    sourceId === 'archive'
      ? metaTitlePrefix
      : metaTitlePrefix.replace(
          /%vodName%/g,
          sourcesWithoutArchiveSelector(store)[sourceId]?.customTitle?.[store.languageCode] || ''
        );

  const metaTitle = store.player.visible
    ? generateVodMetaTitle(store, sourceId, titleId)
    : metaTitleVod;

  if (metaTitle) {
    setMetaTitle(store, metaTitle);
  }
};

export const handleModalStepBack = (store: TStore, sourceId: string, titleId: string) => {
  popModalTitleIdsHistory(store);
  setModalTitleId(store, titleId);
  setMetaTitle(store, generateVodMetaTitle(store, sourceId, titleId));
};

export const setModalTitleId = (store: TStore, modalTitleId: string) => {
  store.vod.modalTitleId = modalTitleId;
};

export const setIsPageTitleOpen = (store: TStore, val: boolean) => {
  store.vod.isPageTitleOpen = val;
};

export const popModalTitleIdsHistory = (store: TStore) => {
  store.vod.modalTitleIdsHistory.pop();
};

export const setIsTitleLoading = (store: TStore, val: boolean) => {
  store.vod.isTitleLoading = val;
};

// ----------------------------------------------------------------------------------------
// PLAYER
// ----------------------------------------------------------------------------------------

export const playVideo = async (
  store: TStore,
  sourceId: string,
  title: TVODTitleEnhanced,
  episodeId = ''
) => {
  if (!title) {
    log.error('Title was not passed to playVideo');
    return;
  }

  log.info('playVideo has been initialized');

  showPlayer(store);
  if (process.env.VUE_ENV === 'client') {
    scrollEverythingToTop(true);
  }

  const titleId = title.preview?.id || '';
  let _episodeId = episodeId;
  const isSavedPause = store.vod.isSavedPause;

  let errorMessage = '';

  if (isSavedPause && title.preview?.hasSeries && !episodeId) {
    _episodeId = lastVodPause(store).episodeId || episodeId;
  }

  if (!isPageTitleOpenSelector(store)) {
    setMetaTitle(store, generateVodMetaTitle(store, sourceId, titleId));
    try {
      await loadTitleVideoData(store, sourceId, _episodeId || titleId);

      setPlayingTitleId(store, titleId);
      setPlayingEpisodeId(store, _episodeId);

      if (title?.preview?.hasSeries && _episodeId) {
        setCurrentEpisodeId(store, _episodeId);
        if (title.seasons?.length) {
          await setCurrentAndNextSeasonsAndEpisodes(store, sourceId, _episodeId, isSavedPause);
          setPlayingSeasonId(store, currentSeasonIdSelector(store));
        }
      }

      const url = makePath(getVodTitleWatchUrl(sourceId, titleId, _episodeId));
      history.replaceState({}, '', url); // be careful, after calling history.replace vue-route will not know about this
    } catch (err) {
      if (err.code === HTTP_CODES.FORBIDDEN || IVI_FORBIDDEN_CODES.includes(err?.code)) {
        await checkForProviderWithCountryCode(store, err);
        errorMessage = err.user_message || err.message;
        // Quick Subscribe
        await handleForVod(store, sourceId, title, _episodeId || titleId);
      } else if (err.code === HTTP_CODES.IVI_NOT_ALLOWED_FOR_LOCATION) {
        errorMessage = err.user_message;
      } else if (err.status === HTTP_CODES.NOT_ALLOWED_FOR_LOCATION) {
        errorMessage =
          err.message ||
          getByLanguage(store.translations['error_empty_response'], languageCodeSelector(store));
      } else if (err.code === HTTP_CODES.NOT_FOUND) {
        errorMessage = err.message;
      }
      log.error(err);
      setError(store, err.code, errorMessage);
      throw new Error(err);
    }
  }
};

// noinspection JSUnusedGlobalSymbols
export const savePause = async (store: TStore) => {
  const videoData = videoDataSelector(store);
  if (videoData.titleId) {
    await api.pauses.saveVodPause(
      videoData.sourceId,
      videoData.titleId,
      videoData.mediaItemId,
      convertToMilliseconds(Math.floor(videoData.currentTime), 'second'),
      convertToMilliseconds(Math.floor(videoData.duration), 'second'),
      videoData.episodeId,
      videoData.seasonId
    );

    await loadVodPauses(store, videoData.sourceId, videoData.titleId);
  } else {
    log.warning(`Could not save pause. Title's ID was not provided`);
  }
};

export const setIsSavedPause = (store: TStore, val: boolean) => {
  store.vod.isSavedPause = val;
};

export const playNextEpisode = async (
  store: TStore,
  sourceId: string,
  title?: TVODTitleEnhanced
) => {
  const _title = title || playingTitleSelector(store, sourceId);

  let episodes = _title?.seasons?.length
    ? _title?.seasons?.[currentSeasonNumSelector(store)]?.episodes
    : _title?.episodes;

  setIsSavedPause(store, false);

  // for titles without seasons
  // play next episode in line
  if (_title?.episodes?.length) {
    const episodeId =
      episodes?.[playingEpisodeWithoutSeasonIndexSelector(store, sourceId, _title) + 1]?.preview
        ?.id;

    if (_title && episodeId) {
      await playVideo(store, sourceId, _title, episodeId);
    }
    return;
  }

  // for titles with seasons
  // play next episode in current season or 1st episode in next season
  if (
    nextSeasonNumSelector(store) &&
    currentSeasonNumSelector(store) !== nextSeasonNumSelector(store)
  ) {
    setCurrentSeasonNum(store, nextSeasonNumSelector(store) || 0);
    episodes = _title?.seasons?.[currentSeasonNumSelector(store)]?.episodes || null;
    setCurrentSeasonNumForNav(store, nextSeasonNumSelector(store) || 0);
  }

  // if playing season number differes from season number in navigation,
  // then updade season number for navigation
  if (currentSeasonNumSelector(store) !== currentSeasonNumForNavSelector(store)) {
    setCurrentSeasonNumForNav(store, currentSeasonNumSelector(store));
  }

  const nextEpisodeId =
    nextEpisodeNumSelector(store) !== undefined
      ? episodes?.[nextEpisodeNumSelector(store) || 0]?.id
      : null;

  if (!nextEpisodeId) {
    return;
  }

  await playVideo(store, sourceId, _title, nextEpisodeId);
};

// -----------------------------------------------------------------------------------------------
// Titles
// -----------------------------------------------------------------------------------------------

export const resetCurrentCategoryId = (store: TStore) => {
  for (const id of listOfSourceIdsWithoutArchiveSelector(store)) {
    if (store.vod.sources[id]) {
      Vue.set(store.vod.sources[id], 'currentCategoryId', DEFAULT_VOD_CATEGORIES[id]);
    }
  }
};

export const setCurrentCategoryId = (store: TStore, sourceId: string, categoryId = '') => {
  const source = store.vod.sources[sourceId];
  if (source) {
    Vue.set(store.vod.sources[sourceId], 'currentCategoryId', categoryId);
  }
};

export const flushTitles = (store: TStore, sourceId: string, categoryId?: string) => {
  if (process.env.VUE_ENV !== 'server') {
    if (categoryId && store.vod?.sources[sourceId]?.categories?.[categoryId]) {
      Vue.set(store.vod.sources[sourceId].categories?.[categoryId], 'titles', []);
    } else {
      Vue.set(store.vod.sources[sourceId], 'titles', []);
    }
  }
};

export const loadTitles = async (store: TStore, data: TTitlesGetParams) => {
  const { sourceId } = data;
  let { categoryId } = data;

  if (!sourceId) {
    log.error('sourceId was not passed');
    return [];
  }
  if (!categoryId) {
    log.error('categoryId was not passed');
    return [];
  }

  const categories = store.vod.sources[sourceId].categories;

  if (!categories?.[categoryId]) {
    log.error(`categoryId = ${categoryId} does not exists in categories`, categories);
    categoryId = categories?.[Object.keys(categories || {})[0]]?.id;
    if (categoryId) {
      data.categoryId = categoryId;
      setCurrentCategoryId(store, sourceId, categoryId);
      log.info(`using the 1st available categoryId = ${categoryId} instead`);
    } else {
      log.error(`Cannot set current category ID because categoryId = undefined`);
    }
    // return [];
  }

  const storedTitles = categories?.[categoryId]?.titles;

  if (!data.offset && storedTitles?.length) {
    data.offset = storedTitles.length;
  }

  const loadedTitles = await api.vod.getCategoryTitles(data);

  if (process.env.VUE_ENV === 'server') {
    // do nothing
    Vue.set(store.vod.sources[sourceId].categories?.[categoryId] || {}, 'titles', loadedTitles);
  } else if (store.vod.sources[sourceId] && categories?.[categoryId]) {
    const titles = storedTitles?.concat(loadedTitles);
    Vue.set(store.vod.sources[sourceId].categories?.[categoryId] || {}, 'titles', titles);
    log.info(`Titles have been loaded & set to store for categoryId = ${categoryId}`);
    return loadedTitles;
  }
};

export const searchTitles = async (store: TStore, data: TTitlesSearchParams) => {
  const { limit, offset, searchQuery, sourceId } = data;

  if (!sourceId) throw new Error('searchTitles action: sourceId was not passed');
  if (!searchQuery) throw new Error('searchTitles action: VOD searchQuery was not passed');

  const loadedTitles = await api.vod.getSearchTitles(sourceId, {
    params: {
      query: searchQuery,
      limit: limit,
      offset: offset || 0,
    },
  });

  const titles: VODTitlePreview[] = loadedTitles.titles || [];
  Vue.set(store.vod.sources[sourceId], 'titles', titles);
};

export const loadTitleVod = async (store: TStore, sourceId: string, titleId: string) => {
  if (!titleId) {
    log.error('titleId was not passed to loadTitleVod');
    return;
  }

  if (store.vod?.sources?.[sourceId]?.fullTitles?.[titleId]) {
    log.info('Title has already been loaded');
    return;
  }

  if (isTitleLoadingSelector(store)) {
    return;
  }

  setIsTitleLoading(store, true);

  const titleResponse = await api.vod.getTitle(sourceId, titleId).catch((err) => {
    setError(store, err.code, err.message);
    setIsTitleLoading(store, false);
  });

  if (errorCodeSelector(store) === '404') {
    return;
  }

  if (!titleResponse) {
    return;
  }

  const title: TVODTitleEnhanced = {
    ...titleResponse,
    episodes: [],
    seasons: [],
  };

  // remove "+" signs from the ageRating because not all titles have it,
  // then add "+" sign back to make sure that ALL titles have it
  if (title?.preview?.ageRating) {
    title.preview.ageRating = title.preview.ageRating.replace(/\D/g, '') + '+';
  }

  const titleWithOptions: Record<string, TVODTitleEnhancedOption> = TitleOptions;
  title.options = titleWithOptions[titleId] || undefined;

  if (store.vod.sources[sourceId]) {
    Vue.set(store.vod.sources[sourceId].fullTitles, titleId, { ...title });
  }

  if (title?.preview?.hasSeries) {
    await loadTitleSeasonsAndEpisodes(store, sourceId, title, titleId);
  }
  log.info(`Title ${titleId} has finished loading & been set to store`);

  setIsTitleLoading(store, false);
};

export const loadTitleSeasonsAndEpisodes = async (
  store: TStore,
  sourceId: string,
  title: TVODTitleEnhanced,
  titleId: string
) => {
  if (!sourceId || !title) {
    throw new Error('sourceId was not passed or video data was not loaded');
  }

  const episodes = await api.vod.getAllEpisodes(sourceId, titleId);
  const episodesPromises = episodes.map((episode) =>
    api.vod.getTitle(sourceId, episode.id).catch((err) => {
      setError(store, err.code, err.message);
    })
  );

  const episodesResponse = await Promise.all(episodesPromises);

  title.episodes = episodesResponse.map((episode) => ({
    ...VODTitlePreviewEnhancedDefault,
    ...episode,
    episodeNumber: '',
  }));

  if (title.episodes && title.episodes.length > 0) {
    Vue.set(store.vod.sources[sourceId].fullTitles[titleId], 'episodes', title.episodes);

    log.info(`Episodes without season have finished loading & been set to store`);
  }

  const seasons = title.details?.seasons || [];

  if (seasons.length) {
    const seasonsPromises = seasons.map((season: { id: string }) =>
      api.vod.getSeasonEpisodes(sourceId, titleId, season.id)
    );
    const seasonsData = await Promise.all(seasonsPromises);

    title.seasons = sortBy(
      seasons
        .map((season, index) => ({ ...season, ...seasonsData[index] }))
        .filter((s) => s.episodes?.length),
      'number'
    );

    if (sourceId === 'archive' && title.seasons?.length) {
      for (const i in title.seasons) {
        const episodesPromises = title.seasons[i].episodes.map(
          (episode: TVODTitlePreviewEnhanced) => {
            if (episode.id) {
              return api.vod.getTitle(sourceId, episode.id).catch((err) => {
                setError(store, err.code, err.message);
              });
            }
          }
        );

        const episodesResponse = await Promise.all(episodesPromises);
        title.seasons[i].episodes = episodesResponse.map((episode) => {
          return {
            preview: undefined,
            details: undefined,
            ...VODTitlePreviewEnhancedDefault,
            ...episode,
            episodeNumber: '',
          };
        });
      }
    }

    if (title.episodes?.length) {
      title.seasons.push({
        episodes: title.episodes,
        id: '0',
        number: 0,
        title:
          getByLanguage(store.translations['without_season'], store.languageCode) ||
          '{without_season}',
      });
    }

    Vue.set(store.vod.sources[sourceId].fullTitles[titleId], 'seasons', title.seasons);

    if (title.seasons.length > 0) {
      setCurrentSeasonNumForNav(
        store,
        playingTitleIdSelector(store) === title.preview?.id ? currentSeasonNumSelector(store) : 0
      );
    }

    log.info(`Seasons with episodes have finished loading & been set to store`);
  }
};

export const loadTitleVideoData = async (store: TStore, sourceId: string, titleId: string) => {
  if (!sourceId) {
    throw new Error('Source ID was not passed');
  }

  const titleResponse = await api.vod.getTitle(sourceId, titleId).catch(() => {
    // do nothing
  });

  if (!titleResponse) {
    return;
  }

  const title = { episodes: [], seasons: [], mediaItems: undefined, ...titleResponse };

  const mediaItem = title.details?.mediaItems?.[0] || title?.mediaItems?.[0];
  Vue.set(store.vod, 'mediaItem', mediaItem);

  if (title.preview?.hasSeries) {
    log.info(`title ${titleId} has series`);
  }

  const playbackMethods = sortBy(mediaItem?.playbackMethods, 'priority');
  const existingMethods = Object.values(PLAYBACK_METHODS);
  const playbackMethod = playbackMethods.find((pm) => existingMethods.includes(pm.name));

  if (!playbackMethod) {
    log.error('title:', title, 'playbackMethods:', playbackMethods);
    throw new Error('Proper playback method was not found');
  }

  const videoData: TVodVideoData = Object.assign({}, store.vod.videoData, {
    mediaItemId: mediaItem?.id,
    playbackMethod: playbackMethod.name,
    sourceId,
    titleId,
  });
  log.info('videoData has been created ', videoData);

  const prepareParams = (playbackMethod: TPlaybackMethod) =>
    playbackMethod.params.reduce<Record<string, string>>((acc, elem) => {
      acc[elem.key] = elem.value;
      return acc;
    }, {});

  let pmParams = prepareParams(playbackMethod);
  log.info('Playback method:', playbackMethod.name);

  try {
    // IVI
    if (playbackMethod.name === PLAYBACK_METHODS.iviTwoSteps) {
      const iviPlaybackMethod = await api.vod.getIviPlayback(titleId, {
        params: pmParams,
      });
      pmParams = prepareParams(iviPlaybackMethod);
    }

    if (
      playbackMethod.name === PLAYBACK_METHODS.iviTwoSteps ||
      playbackMethod.name === PLAYBACK_METHODS.ivi
    ) {
      const iviContentInfo = await api.ivi.getContentInfo(pmParams);

      for (const file of iviContentInfo.files) {
        file.url = matchUrlProtocolWithLocationProtocol(file.url, store.common.isHttps);
      }

      videoData.iviContentInfo = iviContentInfo;
      videoData.url = iviContentInfo?.files[0].url;
      if (videoData.url) {
        log.info('Playback url for IVI has been set to videoData', videoData.url);
      } else {
        log.error('Playback url for IVI was not provided, iviContentInfo:', iviContentInfo);
      }
    } else if (playbackMethod.name === PLAYBACK_METHODS.megogoV1) {
      // Megogo with DRM
      const { data } = await api.vod
        .getMegogoPlayback(titleId, {
          params: pmParams,
        })
        .catch((err) => {
          log.error(err);
          throw err;
        });
      videoData.megogoPlayback = { ...data };

      const drmSystem = getDrmSystem();
      const url = data.src;
      if (url) {
        videoData.url = matchUrlProtocolWithLocationProtocol(url, store.common.isHttps);
        log.info(
          `Playback url & data from ${playbackMethod.name} has been set to videoData, drmSystem:`,
          drmSystem
        );
      } else {
        log.error(`Playback url from ${playbackMethod.name} was not provided:`, data);
      }
    } else if (playbackMethod.name === PLAYBACK_METHODS.amedia2V1) {
      // Amediateka with DRM
      const {
        response: { video },
        playbackSession,
      } = await api.vod
        .getAmediaPlayback(titleId, {
          params: pmParams,
        })
        .catch((err) => {
          log.error(err);
          throw err;
        });

      videoData.amedia2Playback = { ...video };

      const drmSystem = getDrmSystem();
      const url = getAmediaDrmStreamUrl(videoData.amedia2Playback, drmSystem);
      if (url) {
        videoData.url = matchUrlProtocolWithLocationProtocol(url, store.common.isHttps);
        log.info(
          `Playback url & data from ${playbackMethod.name} has been set to videoData, drmSystem:`,
          drmSystem
        );
      } else {
        log.error(`Playback url from ${playbackMethod.name} was not provided:`, video);
      }

      if (playbackSession) {
        videoData.playbackSession = playbackSession;
      }
    } else if (playbackMethod.name === PLAYBACK_METHODS.smotreshkaVodV2) {
      // VOD
      const { url, playbackSession } = await api.vod
        .getVodPlayback(titleId, {
          params: pmParams,
        })
        .catch((err) => {
          log.error(err);
          throw err;
        });

      if (!url) {
        log.error('Playback video URL is absent');
        return;
      }

      if (playbackSession) {
        videoData.playbackSession = playbackSession;
      }

      videoData.url = matchUrlProtocolWithLocationProtocol(url, store.common.isHttps);
      log.info('Playback url has been set to videoData', url);
    } else {
      log.error(title, playbackMethod);
      log.error(`UNKNOWN PLAYBACK METHOD: '${playbackMethod.name}'`);
      return;
    }
  } catch (err) {
    setPlayingUrl(store, '');
    throw err;
  }

  setShowNotificationWithDetails(store, false);
  Vue.set(store.vod, 'videoData', videoData);
};

export const checkForProviderWithCountryCode = async (store: TStore, err: any) => {
  await loadProviderInfo(store);
  err.phone = salesPhoneFormattedSelector(store);
  err.url = urlSelector(store);

  if (isAnonymousSelector(store)) {
    gaEvent(
      { category: 'blocked_content_click', action: 'Появление ошибки "Вы не авторизованы"' },
      store
    );
  } else if (err.phone || err.url) {
    gaEvent(
      { category: 'blocked_content_click', action: '"Нет подписки" с контактов провайдера' },
      store
    );
  } else {
    gaEvent(
      {
        category: 'blocked_content_click',
        action: '"Нет подписки" без контактами провайдера',
      },
      store
    );
  }
};

// -----------------------------------------------------------------------------------------------
// VOD-collections
// -----------------------------------------------------------------------------------------------
export const resetCollections = (store: TStore) => {
  store.vod.states.collectionsLoaded = false;
  Vue.set(store.vod, 'collections', []);
};

export const loadCollections = async (store: TStore) => {
  const vodSources = Object.keys(sourcesSelector(store));
  let collections: Array<VODHomeCollectionEnhanced> = [];

  if (!listOfAllSourceIdsSelector(store).length) {
    log.warning('There are 0 VOD sources. Skip loading VOD-collections.');
    return;
  }

  if (store.vod.collections?.length && store.vod.states.collectionsLoaded) {
    log.info('VOD-collections have already been loaded before');
    return;
  }

  if (!audienceTokenValueSelector(store)) {
    await saveAssetTokens(store, await loadAssetTokens());
  }

  try {
    collections = (await api.vod.getCollections(audienceTokenValueSelector(store)))?.vodCollections;

    if (!collections?.length) {
      return;
    }

    // filter out collections with VOD sources unavailable to a user
    collections = collections.filter(
      (collection) =>
        collection.titlesList?.length &&
        (collection.vodSource === 'archive' || vodSources.includes(collection.vodSource))
    );

    // remap collections & sort by priority in ascending order
    collections = sortBy(mapCollections(store, collections), 'priority');

    Vue.set(store.vod, 'collections', collections);

    store.vod.states.collectionsLoaded = true;
  } catch (err) {
    log.error('VOD-collections did not load:', err);
  }
};

export const mapCollections = (store: TStore, collections: Array<VODHomeCollectionEnhanced>) => {
  if (collections.length) {
    return collections.map((collection) => ({
      caption: collection.caption,
      logo:
        collection.vodSource !== 'archive'
          ? getSourceLogoUrl(store, collection.vodSource)
          : undefined,
      titlesList: collection.titlesList,
      vodSource: collection.vodSource,
      vodCollectionId: collection.vodCollectionId,
      id: collection.id,
      priority: collection.priority,
      brandingMethods: [],
      placementBlocks: [],
    }));
  } else {
    return [];
  }
};

export const loadTitlesForCollection = async (
  store: TStore,
  collection: VODHomeCollectionEnhanced,
  fromSource: boolean
) => {
  const vodCollectionId = fromSource ? collection?.id : collection?.vodCollectionId;
  const vodSource = collection?.vodSource;

  if (!vodCollectionId || !vodSource) {
    store.vod.states.shouldLoadMoreTitlesInCollection = false;
    return;
  }

  store.vod.states.collectionTitlesLoading = true;

  const titles = (
    await api.vod.getCollectionTitles(vodSource, vodCollectionId, fromSource).finally(() => {
      store.vod.states.collectionTitlesLoading = false;
    })
  ).titles;

  store.vod.states.shouldLoadMoreTitlesInCollection = !!(
    titles?.length && titles?.length === VOD_TITLES_DEFAULT_LIMIT
  );

  if (titles?.length) {
    const index = store.vod.collections.findIndex((col) => col.id === collection.id);
    if (store.vod.collections[index]) {
      addTitlesToCollection(store, index, titles);
    } else if (vodSource) {
      addTitlesToCollectionFromSource(store, vodCollectionId, vodSource, titles);
    }
  }
};

export const addTitlesToCollection = (
  store: TStore,
  index: number,
  titles: Array<VODTitlePreview>
) => {
  const titlesList = store.vod.collections[index].titlesList;
  titlesList.push(...titles);
  Vue.set(store.vod.collections[index], 'titlesList', titlesList);
};

export const addTitlesToCollectionFromSource = (
  store: TStore,
  id: string,
  sourceId: string,
  titles: Array<VODTitlePreview>,
  caption = ''
) => {
  if (!store.vod.collectionsFromSource.find((c) => c.id === id)) {
    // add new collection
    store.vod.collectionsFromSource.push({
      vodCollectionId: '',
      brandingMethods: [],
      priority: 0,
      caption: caption,
      id: id,
      titlesList: titles,
      vodSource: sourceId,
      placementBlocks: [],
    });
  } else {
    // add titles to existing collection
    const index = store.vod.collectionsFromSource.findIndex((col) => col.id === id);
    const titlesList = store.vod.collectionsFromSource[index].titlesList;
    titlesList.push(...titles);
    Vue.set(store.vod.collectionsFromSource[index], 'titlesList', titlesList);
  }
};

export const getSourceLogoUrl = (store: TStore, sourceId: string) => {
  const logo = getByLanguage(
    store.theme === 'light'
      ? store.vod.sources[sourceId]?.logoTilePoster
      : store.vod.sources[sourceId]?.logoTilePosterDark,
    store.languageCode
  );
  return logo?.url || '';
};

// -----------------------------------------------------------------------------------------------
// Modal & Page with a collection
// -----------------------------------------------------------------------------------------------

export const showCollection = async (
  store: TStore,
  id: string,
  fromSource: boolean,
  loadMore = true,
  shouldPushStateToHistory = true
) => {
  setCurrentCollectionId(store, id);
  const collection = currentCollectionSelector(store);

  if (collection && loadMore) {
    await loadTitlesForCollection(store, collection, fromSource);
  }

  if (shouldPushStateToHistory) {
    history.pushState({}, '', makePath(`/collection/${id}`));
  }
};

export const setCurrentCollectionId = (store: TStore, currentCollectionId: string) => {
  store.vod.currentCollectionId = currentCollectionId;
};

// ----------------------------------------------------------------------------------------
// Video Data
// ----------------------------------------------------------------------------------------
export const setCurrentAndNextSeasonsAndEpisodes = async (
  store: TStore,
  sourceId: string,
  episodeId: string,
  isSavedPause: boolean,
  title?: TVODTitleEnhanced
) => {
  const currentTitle = title || playingTitleSelector(store, sourceId);
  const currentEpisodeId = title
    ? currentEpisodeIdSelector(store)
    : playingEpisodeIdSelector(store);
  const seasons = currentTitle?.seasons || [];
  let seasonNum = seasons?.findIndex((s) =>
    s.episodes.find((e) => e?.id === currentEpisodeId || e?.preview?.id === currentEpisodeId)
  );
  const episodes = seasons[seasonNum]?.episodes || [];

  if (!episodes.length) {
    return;
  }

  if (isSavedPause) {
    seasonNum =
      seasons?.findIndex((s) => s.id === lastVodPause(store).seasonId) ||
      currentSeasonNumSelector(store) ||
      0;
    setCurrentSeasonId(store, lastVodPause(store).seasonId);
  } else {
    setCurrentSeasonId(store, currentTitle?.seasons?.[currentSeasonNumSelector(store)]?.id || '');
  }

  setCurrentSeasonNum(store, seasonNum);
  setCurrentSeasonNumForNav(store, seasonNum);

  if (currentEpisodeId !== episodeId) {
    setCurrentEpisodeId(store, episodeId);
  }

  setCurrentEpisodeNum(
    store,
    episodes.findIndex((e) => e.id === episodeId || e?.preview?.id === episodeId)
  );

  if (
    episodes.length === currentEpisodeNumSelector(store) + 1 &&
    seasons.length > currentSeasonNumSelector(store) + 1
  ) {
    // next season, 1st episode
    setNextSeasonNum(store, currentSeasonNumSelector(store) + 1);
    setNextEpisodeNum(store, 0);
  } else if (episodes.length === currentEpisodeNumSelector(store) + 1) {
    // series finale – no more seasons & episodes
    setNextSeasonNum(store, undefined);
    setNextEpisodeNum(store, undefined);
  } else {
    // same season, next episode
    setNextSeasonNum(store, currentSeasonNumSelector(store));
    setNextEpisodeNum(store, currentEpisodeNumSelector(store) + 1);
  }
};

export const resetVideoData = (store: TStore) => {
  Vue.set(store.vod, 'videoData', { ...VOD_VIDEO_DATA_RESET });
};

export const setPlayingChannelId = (store: TStore, channelId: string) => {
  store.vod.videoData.channelId = channelId;
};

export const setPlayingCurrentTime = (store: TStore, currentTime: number, fromStart?: number) => {
  if (fromStart) {
    currentTime += fromStart;
  }
  store.vod.videoData.currentTime = currentTime;
};

export const setPlayingDuration = (store: TStore, num: number) => {
  store.vod.videoData.duration = num;
};

export const setPlayingEpisodeId = (store: TStore, episodeId: string) => {
  store.vod.videoData.episodeId = episodeId;
};

export const setPlayingMediaItemId = (store: TStore, mediaItemId: string) => {
  store.vod.videoData.mediaItemId = mediaItemId;
};

export const setPlayingSeasonId = (store: TStore, seasonId: string) => {
  store.vod.videoData.seasonId = seasonId;
};

export const setPlayingTitleId = (store: TStore, playingTitleId: string) => {
  store.vod.videoData.titleId = playingTitleId;
};

export const setPlayingUrl = (store: TStore, url: string) => {
  Vue.set(store.vod.videoData, 'url', url);
};

// ----------------------------------------------------------------------------------------------
// Series Data
// ----------------------------------------------------------------------------------------------
export const resetSeriesData = (store: TStore) => {
  Vue.set(store.vod, 'seriesData', { ...VOD_SERIES_DATA_RESET });
};

export const setCurrentEpisodeNum = (store: TStore, num: number) => {
  store.vod.seriesData.episodeNum = num;
};

export const setNextEpisodeNum = (store: TStore, num?: number) => {
  store.vod.seriesData.nextEpisodeNum = num;
};

export const setNextSeasonNum = (store: TStore, num?: number) => {
  store.vod.seriesData.nextSeasonNum = num;
};

export const setCurrentSeasonNum = (store: TStore, num: number) => {
  store.vod.seriesData.seasonNum = num;
};

export const setCurrentSeasonNumForNav = (store: TStore, num: number) => {
  store.vod.seriesData.seasonNumForNav = num;
};

export const setCurrentEpisodeId = (store: TStore, episodeId: string) => {
  store.vod.seriesData.episodeId = episodeId;
};

export const setCurrentMediaItemId = (store: TStore, mediaItemId: string) => {
  store.vod.seriesData.mediaItemId = mediaItemId;
};

export const setCurrentSeasonId = (store: TStore, seasonId: string) => {
  store.vod.seriesData.seasonId = seasonId;
};
