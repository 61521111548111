export const imageServerUrl = (url: string, width: number, height: number) =>
  `${url}?width=${width}&height=${height}`;

/**
 * Takes maximum image width and height and calculates its size according to provided aspect ratio
 *
 * For example:
 * maxWidth = 800, maxHeight = 600, aspect ratio = 16:9
 * image is horizontal, so we pick maxWidth as width and calculate height according to aspect ratio
 * (9 * 800) / 16 = 450
 */
export const calculateSizeByAspectRatio = (
  maxWidth: number,
  maxHeight: number,
  ratioWidth: number,
  ratioHeight: number
) => {
  let width = maxWidth;
  let height = maxHeight;
  const isVertical = ratioWidth < ratioHeight;
  if (isVertical) {
    // for vertical posters - calculate width
    width = Math.round((ratioWidth * height) / ratioHeight);
  } else {
    // for horizontal and square posters - calculate height
    height = Math.round((ratioHeight * width) / ratioWidth);
  }

  return { width, height };
};

const calculateSizeWithMaxWidth = (width: number, height: number, maxWidth: number) => {
  const _width = Math.min(width, maxWidth);
  const _height = width > maxWidth ? (_width * height) / width : height;
  return { width: _width, height: _height };
};

/**
 * Get resized image url of specified image provider
 *
 * https://dev.tightvideo.com/projects/server-side/wiki/Vod-pics
 */
export const getResizedImageUrl = (
  url: string,
  width?: number,
  height?: number,
  provider = 'imageserver'
) => {
  if (!(width && height)) {
    return url;
  }

  let maxWidth;
  let sizeWidthMaxWidth;

  // https://dev.tightvideo.com/projects/server-side/wiki/Vod-pics
  switch (provider) {
    case 'plain':
      return url;
    case 'ivi':
    case 'ivi.ru':
      return `${url}/${width}x${height}/`;
    case 'megogo':
      return `${url}/pt/r${width}x${height}`;
    case 'start':
      maxWidth = 2000;
      sizeWidthMaxWidth = calculateSizeWithMaxWidth(width, height, maxWidth);
      const urlParts = url.split('/');
      urlParts[5] = `${sizeWidthMaxWidth.width}x${sizeWidthMaxWidth.height}`;
      return urlParts.join('/');
    default:
      // imageserver by smotreshka
      maxWidth = 3000;
      sizeWidthMaxWidth = calculateSizeWithMaxWidth(width, height, maxWidth);
      return `${url}?width=${sizeWidthMaxWidth.width}&height=${sizeWidthMaxWidth.height}&quality=93`;
  }
};
