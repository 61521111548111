<template>
  <div v-if="years" class="title-details-info-with-icon year" :class="theme">
    <IconSVG v-if="showIcon" :svg="IconEpg" />
    <span v-text="years" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconEpg from 'src/svg/epg.svg';
import { store } from 'src/store';

const props = withDefaults(
  defineProps<{
    themeForced?: 'light' | 'dark';
    years?: string;
    showIcon?: boolean;
  }>(),
  {
    showIcon: false,
  }
);

const theme = computed(() => props.themeForced || store.theme);
</script>

<style lang="scss">
@import 'src/styles/common/title-details-info-with-icon';
</style>
