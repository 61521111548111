/**
 * Ported from https://github.com/d4nyll/lethargy
 */

interface Options {
  stability: number;
  sensitivity: number;
  tolerance: number;
  delay: number;
}

export default class LethargyService {
  opts: Options = {
    // Stability is how many records to use to calculate the average
    stability: 8,
    // The wheelDelta threshold. If an event has a wheelDelta below this value, it won't register
    sensitivity: 50,
    // How much the old rolling average have to differ
    // from the new rolling average for it to be deemed significant
    tolerance: 1.1,
    // Threshold for the amount of time between mousewheel events for them to be deemed separate
    delay: 50,
  };
  lastUpDeltas: Array<number>;
  lastDownDeltas: Array<number>;
  deltasTimestamp: Array<number>;

  constructor(options: Partial<Options> = {}) {
    this.opts = { ...this.opts, ...options };

    // Used internally and should not be manipulated
    this.lastUpDeltas = new Array(this.opts.stability * 2).fill(null);
    this.lastDownDeltas = new Array(this.opts.stability * 2).fill(null);
    this.deltasTimestamp = new Array(this.opts.stability * 2).fill(null);
  }

  static extractWheelDelta(e: WheelEvent) {
    // Standardise wheelDelta values for different browsers
    let lastDelta = 0;
    if (e.deltaY != null) {
      lastDelta = e.deltaY * -40;
    } else if (e.detail != null || e.detail === 0) {
      lastDelta = e.detail * -40;
    }
    return lastDelta;
  }

  // Checks whether the mousewheel event is an intent
  handleEvent(event: WheelEvent) {
    const lastDelta = LethargyService.extractWheelDelta(event);

    // Add the new event timestamp to deltasTimestamp array, and remove the oldest entry
    this.deltasTimestamp.push(Date.now());
    this.deltasTimestamp.shift();

    // If lastDelta is positive, it means the user scrolled up
    if (lastDelta > 0) {
      this.lastUpDeltas.push(lastDelta);
      this.lastUpDeltas.shift();
      return this.isInertia(1);
    }
    // Otherwise, the user scrolled down
    this.lastDownDeltas.push(lastDelta);
    this.lastDownDeltas.shift();
    return this.isInertia(-1);
  }

  // Checks if the event is an inertial scroll, if not, returns 1 or -1 depending on the direction
  // TODO: Change the name of the method, as it currently implies a boolean return value
  isInertia(direction: number) {
    // Get the relevant last*Delta array
    const lastDeltas = direction === -1 ? this.lastDownDeltas : this.lastUpDeltas;

    // If the array is not filled up yet, we cannot compare averages
    // so assume the scroll event to be intentional
    if (lastDeltas[0] === null) {
      return direction;
    }

    // If the last mousewheel occurred within the specified delay of the penultimate one,
    // and their values are the same. We will assume that this is a trackpad
    // with a constant profile and will return false
    if (
      this.deltasTimestamp[this.opts.stability * 2 - 2] + this.opts.delay > Date.now() &&
      lastDeltas[0] === lastDeltas[this.opts.stability * 2 - 1]
    ) {
      return false;
    }

    // Check to see if the new rolling average (based on the last half of the lastDeltas array)
    // is significantly higher than the old rolling average. If so return direction, else false
    const lastDeltasOld = lastDeltas.slice(0, this.opts.stability);
    const lastDeltasNew = lastDeltas.slice(this.opts.stability, this.opts.stability * 2);

    const oldSum = lastDeltasOld.reduce((t, s) => t + s);
    const newSum = lastDeltasNew.reduce((t, s) => t + s);

    const oldAverage = oldSum / lastDeltasOld.length;
    const newAverage = newSum / lastDeltasNew.length;

    if (
      Math.abs(oldAverage) < Math.abs(newAverage * this.opts.tolerance) &&
      this.opts.sensitivity < Math.abs(newAverage)
    ) {
      return direction;
    }
    return false;
  }

  showLastUpDeltas() {
    return this.lastUpDeltas;
  }

  showLastDownDeltas() {
    return this.lastDownDeltas;
  }
}
