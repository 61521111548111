<template>
  <a :href="url" class="logo" :class="{ 'pointer-events-none': disabled }">
    <img :src="src" alt="logo" />
  </a>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import { makePath } from 'src/utils/url';

@Component
export default class LogoSequoia extends SequoiaComponent {
  url = '';

  @Prop({ default: false })
  disabled!: boolean;

  get src() {
    return this.getByLanguage(this.$store.authAndReg?.logo)?.url;
  }

  mounted() {
    this.url = (this.$route.query.provider as string) || makePath('/');
  }
}
</script>
