const KIBIBYTE = 1024;

// convert bytes to bits
export function bytesToBits(value: number) {
  return value * 8;
}

// convert bytes to kilobytes
export const bytesToKiloBytes = (size: number) => {
  return (size / KIBIBYTE).toFixed(2) + ' KB';
};

// convert kilobytes to bytes
export const kiloBytesToBytes = (size: number) => {
  return size * KIBIBYTE;
};

// convert megabytes to bytes
export const megaBytesToBytes = (size: number) => {
  return kiloBytesToBytes(size) * KIBIBYTE;
};

// convert milliseconds to seconds
export function msToS(value: number) {
  return value / 1000;
}

// convert Bit/s to MBit/s
export function bitsPerSToMbitPerS(value: number) {
  return value / 1000000;
}

export function formatToFixed2(value: number) {
  return value.toFixed(2);
}

export const isOkNumber = (n: any) => typeof n === 'number' && !isNaN(n);

export const checkNumberWithDefault = (n: any, defaultValue: any) =>
  isOkNumber(n) ? n : defaultValue;

export const boundMinMax = (min: number, value: number, max: number) => {
  return value < min ? min : value > max ? max : value;
};
