import Vue from 'vue';
import throttle from 'lodash/throttle';
import LazyImage from 'src/utils/lazy-images/lazy-image';
import { isPassiveSupported } from 'src/utils/lazy-images/utils';

const LISTENING_EVENTS = [
  'scroll',
  'wheel',
  'mousewheel',
  'resize',
  'animationend',
  'transitionend',
  'touchmove',
];

export const cached: any = new Vue({ data: { src: {} } });

interface ListeningTarget {
  el: Window | HTMLElement;
  id: number;
  childrenCount: number;
  listened: boolean;
}

class Lazy {
  public images: LazyImage[] = [];
  public findVisibleImagesToLoad: () => void;

  private listeningTargets: ListeningTarget[] = [];
  private listeningTargetsIndex = 0;

  constructor() {
    this.findVisibleImagesToLoad = throttle(this._findVisibleImagesToRender.bind(this), 200);
  }

  public findImageByEl(el: HTMLElement) {
    return this.images.find((image) => image.el === el);
  }

  public addToQueue(lazyImage: LazyImage) {
    this.images.push(lazyImage);
    this.addListenerTarget(window);
    this.addListenerTarget(parent);
    this.findVisibleImagesToLoad();
  }

  public removeFromQueue(el: HTMLElement) {
    const index = this.images.findIndex((image) => image.el === el);
    if (index > -1) {
      const lazyImage = this.images[index];
      if (lazyImage.parent) {
        this.removeListenerTarget(lazyImage.parent);
      }
      this.removeListenerTarget(window);
      this.images.splice(index, 1);
    }
  }

  /**
   * add listener target
   */
  private addListenerTarget(el?: Window | HTMLElement) {
    if (!el) return;
    let target = this.listeningTargets.find((target) => target.el === el);
    if (!target) {
      target = {
        el,
        id: ++this.listeningTargetsIndex,
        childrenCount: 1,
        listened: true,
      };
      this.bindEvents(target.el);
      this.listeningTargets.push(target);
    } else {
      target.childrenCount++;
    }
    return this.listeningTargetsIndex;
  }

  /**
   * remove listener target or reduce target childrenCount
   */
  private removeListenerTarget(el: Window | HTMLElement) {
    this.listeningTargets.forEach((target, index) => {
      if (target.el === el) {
        target.childrenCount--;
        if (!target.childrenCount) {
          this.unbindEvents(target.el);
          this.listeningTargets.splice(index, 1);
        }
      }
    });
  }

  /**
   * add event-listeners
   */
  private bindEvents(el: Window | HTMLElement) {
    LISTENING_EVENTS.forEach((event) => {
      const options = isPassiveSupported() ? { passive: true } : undefined;
      el.addEventListener(event, this.findVisibleImagesToLoad, options);
    });
  }

  /**
   * remove event-listeners
   */
  private unbindEvents(el: Window | HTMLElement) {
    LISTENING_EVENTS.forEach((event) => {
      el.removeEventListener(event, this.findVisibleImagesToLoad);
    });
  }

  /**
   * find nodes visible in viewport and render them
   */
  private _findVisibleImagesToRender() {
    const toBeRemoved: HTMLElement[] = [];
    this.images.forEach((image) => {
      if (!image.el || !image.el.parentNode) {
        toBeRemoved.push(image.el);
        return;
      }
      const isInView = image.checkInView();
      if (!isInView) {
        return;
      }
      Vue.set(cached.src, image.src, image.src);
      toBeRemoved.push(image.el);
    });
    toBeRemoved.forEach((el) => {
      const index = this.images.findIndex((image) => image.el === el);
      if (index > -1) {
        this.images.splice(index, 1);
      }
    });
  }
}

export default new Lazy();
