<template>
  <div class="captcha" :class="theme">
    <img v-if="srcImg" class="img" :src="srcImg" alt="captcha" />
    <button type="button" class="refresh">
      <IconSVG :svg="IconRefresh" @click="refreshHandler" />
    </button>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Watch } from 'vue-property-decorator';
import IconRefresh from 'src/svg/refresh.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { getAppVersionParamsFlattened } from 'src/utils/app-version';
import querystring from 'querystring';
import { getEnvVars } from 'src/config';
import axios from 'axios';

@Component({
  components: { IconSVG },
})
export default class CaptchaSequoia extends SequoiaComponent {
  IconRefresh = IconRefresh;
  date = Date.now();
  firstTime = true;
  srcImg: string | null = null;

  @Prop({ default: false })
  useVercontServer!: boolean;

  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  @Watch('$store.authAndReg.captchaRefreshValue')
  onCaptchaRefreshValueChange() {
    this.firstTime = false;
    this.refreshHandler();
  }

  get beBaseUrl() {
    return getEnvVars().beBaseUrl;
  }

  async refreshHandler() {
    this.date = Date.now();
    this.srcImg = await this.loadImg();
  }

  async loadImg() {
    const appVersionParams = querystring.stringify(getAppVersionParamsFlattened());
    const params = `t=${this.date}&${appVersionParams}`;
    const reloadPart = this.firstTime ? 'reload=$true' : '';
    const url = this.useVercontServer
      ? `${this.beBaseUrl}/contacts-verification/v1/captcha?${params}`
      : `${this.beBaseUrl}/captcha?${reloadPart}&${params}`;

    const response = await axios.request({
      url,
      method: 'get',
      responseType: 'blob',
      withCredentials: true,
    });

    if (response.headers['cookie-custom']) {
      this.$store.authAndReg.cookieCaptcha = JSON.parse(response.headers['cookie-custom']).reduce(
        (acc: string, item: { name: string; value: string }) => {
          return `${acc}${item.name}=${item.value};`;
        },
        ''
      );
    }

    return URL.createObjectURL(response.data);
  }

  mounted() {
    this.$nextTick(() => {
      this.refreshHandler();
    });
  }
}
</script>

<style lang="scss" scoped>
.captcha {
  position: relative;
  flex: 0 0 120px;
  height: 48px;
  background-color: var(--alpha-dark-8);
  border-radius: 4px;

  .img {
    height: 100%;
  }

  .refresh {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    color: var(--c-light-font-secondary);
  }

  &.light {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }

  &.dark {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  }
}
</style>
