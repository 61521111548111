<template>
  <div class="channels-strip">
    <!-- for desktop -->
    <div
      v-if="!showSliderOnly"
      class="tile-posters"
      :class="{ 'hidden-mobile-and-tablet': !showTilesOnly }"
      :data-cy="dataCy ? `channels-strip-${dataCy}` : ''"
    >
      <div class="row with-side-padding pt-8">
        <TilePosterChannel
          v-for="(channel, index) in channelsToShow"
          :key="channel.id + index.toString()"
          ref="tile-poster-channel-desktop"
          class="tile col-desktop-2 col-desktop-s-3 col-tablet-2 col-mobile-2"
          :channel-id="channel.id"
          :channel-name="channel.name"
          :channel-image="channel.image"
          :show-favorites="!isAnonymous"
          :is-in-favorites="channel.inFavorites"
          :program-title="channel.currentProgram.title"
          :program-start-h-m="channel.currentProgram.startTimeHM"
          :program-end-h-m="channel.currentProgram.endTimeHM"
          :data-cy="dataCy"
          :open-watch="openWatch"
          @toggleFavorite="toggleChannelFavorite(channel.id)"
          @click="$emit('gaClick', channel.id)"
        />
        <ButtonDefault
          v-if="isButtonVisible"
          ref="getMoreChannelsButton"
          view="secondary"
          class="show-more mt-32 mt-tablet-16 mt-mobile-16"
          data-cy="show-more-titles"
          size="medium"
          :full-width="true"
          :with-icon="true"
          @click="getMoreChannels()"
        >
          <IconSVG :svg="IconMoreHorizontal" />
          <span v-html="getTranslation('show_more')" />
        </ButtonDefault>
      </div>
    </div>

    <!-- for tablet & mobiles -->
    <template v-if="!showTilesOnly">
      <HomeContentSlider
        type="adaptive"
        class="tile-posters"
        :class="{ 'visible-mobile-and-tablet': !showSliderOnly }"
        :highlight-visible="highlightVisible"
        :theme-forced="theme"
        @gaScroll="$emit('gaScroll')"
      >
        <TilePosterChannel
          v-for="(channel, index) in channelsMapped"
          :key="channel.id + index.toString()"
          class="tile col-desktop-2 col-desktop-s-3 col-tablet-2 col-mobile-2"
          :channel-id="channel.id"
          :channel-name="channel.name"
          :channel-image="channel.image"
          :show-favorites="!isAnonymous"
          :is-in-favorites="channel.inFavorites"
          :program-title="channel.currentProgram.title"
          :program-start-h-m="channel.currentProgram.startTimeHM"
          :program-end-h-m="channel.currentProgram.endTimeHM"
          :data-cy="dataCy"
          :open-watch="openWatch"
          @toggleFavorite="toggleChannelFavorite(channel.id)"
          @click="$emit('gaClick', channel.id)"
        />
      </HomeContentSlider>
    </template>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import HomeContentSlider from 'src/components/home/HomeContentSlider.vue';
import TilePosterChannel from 'src/components/ui/tiles/TilePosterChannel.vue';
import { getChannelLogo, getChannelTitle } from 'src/utils/channel';
import { TChannelEnhanced } from 'src/api/channels/types';
import IconSVG from 'src/components/IconSVG.vue';
import IconMoreHorizontal from 'src/svg/more-horizontal.svg';
import Vue from 'vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';

@Component({
  components: {
    HomeContentSlider,
    TilePosterChannel,
    IconSVG,
    ButtonDefault,
  },
})
export default class ChannelsStrip extends SequoiaComponent {
  IconMoreHorizontal = IconMoreHorizontal;
  channelsToShowOffset = 48;
  CHANNELS_TO_SHOW_LIMIT = 48;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  channels!: TChannelEnhanced[];

  @Prop({ default: false })
  openWatch!: boolean;

  @Prop({ default: false })
  showTilesOnly!: boolean;

  @Prop({ default: false })
  showSliderOnly!: boolean;

  @Prop({ default: false })
  highlightVisible!: boolean;

  @Prop({ default: true })
  hideOpenChannel!: boolean;

  @Prop({ default: 6 })
  channelsQuantity!: number;

  @Prop()
  dataCy?: string;

  @Ref('getMoreChannelsButton')
  readonly refGetMoreChannelsButton?: Vue;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  get openChannelId() {
    return selectors.tvChannels.openChannelIdSelector(this.$store);
  }

  get channelsMapped() {
    if (this.channels.length) {
      return this.channels.flatMap(({ id }) => {
        const channel = this.getChannelById(id);
        return (channel &&
          channel.id !== this.openChannelId &&
          channel.id !== this.$route.params.channelId &&
          this.hideOpenChannel) ||
          !this.hideOpenChannel
          ? {
              id: channel?.id,
              name: this.getChannelTitle(channel),
              image: {
                src: channel ? this.getChannelLogo(channel) : '',
                width: 205, // the largest width for all dimensions (on mobile L)
                height: 115, // the largest height for all dimensions (on mobile L)
              },
              inFavorites: !!this.favoriteChannels[channel?.id],
              currentProgram:
                selectors.tvEpg.programForChannelSelector({
                  store: this.$store,
                  channelId: channel?.id,
                  isLive: false,
                  programIndex: selectors.tvEpg.liveProgramIndexForChannelSelector(
                    this.$store,
                    channel?.id || ''
                  ),
                }) || {},
            }
          : [];
      });
    } else {
      const skeletonChannels = [];
      for (let i = 0; i < this.channelsQuantity; i++) {
        skeletonChannels.push({
          id: i,
          name: '',
          image: '',
          inFavorites: false,
          currentProgram: { title: '', startTimeHM: '', endTimeHM: '' },
        });
      }

      return skeletonChannels;
    }
  }

  get channelsToShow() {
    return this.channelsMapped.slice(0, this.channelsToShowOffset);
  }

  get isButtonVisible() {
    return this.channelsToShow.length < this.channelsMapped.length;
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  getChannelTitle(channel?: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  async toggleChannelFavorite(id: string) {
    this.$emit('gaFavorite', id);
    await actions.tvChannels.toggleChannelFavorite(this.$store, id);
  }

  getMoreChannels() {
    this.channelsToShowOffset += this.CHANNELS_TO_SHOW_LIMIT;
    (this.refGetMoreChannelsButton?.$el as HTMLInputElement)?.blur();
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>
