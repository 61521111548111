var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"slider",staticClass:"content-slider",class:[`type-${_vm.type}`, _vm.theme, { 'highlight-visible': _vm.highlightVisible }]},[_c('div',{staticClass:"inner",class:{ 'first-step': !_vm.canGoPrev, 'last-step': !_vm.canGoNext }},[_c('div',{staticClass:"nav-button left",class:{ active: _vm.canGoPrev },on:{"click":function($event){return _vm.goToPrev()}}},[(_vm.buttonsType === 'circled')?_c('ButtonCircle',{staticClass:"nav-button-icon circled",attrs:{"direction":"left"}}):(_vm.buttonsType === 'arrows')?_c('IconSVG',{staticClass:"nav-button-icon arrow",attrs:{"svg":_vm.IconArrowLeft,"size":32}}):_vm._e()],1),_c('div',{staticClass:"nav-button right",class:{ active: _vm.canGoNext },on:{"click":function($event){return _vm.goToNext()}}},[(_vm.buttonsType === 'circled')?_c('ButtonCircle',{staticClass:"nav-button-icon circled",attrs:{"direction":"right"}}):(_vm.buttonsType === 'arrows')?_c('IconSVG',{staticClass:"nav-button-icon arrow",attrs:{"svg":_vm.IconArrowRight,"size":32}}):_vm._e()],1),_c('div',{ref:"slider-items",staticClass:"slider-items",class:{
        row: _vm.type !== 'legacy',
        'with-side-padding': _vm.type !== 'legacy',
        'no-transition': _vm.disableAnimation,
        'animation-shake': _vm.hasShakeAnimation,
      },style:({
        transform: `translate3d(${_vm.translateX}px, 0, 0)`,
      })},[_vm._t("default")],2)]),(_vm.thumbnails)?_c('div',{staticClass:"thumbnails"},_vm._l((_vm.thumbnails),function(thumbnail,i){return _c('button',{staticClass:"thumbnail",class:{ active: _vm.firstVisibleSlideIndex >= i && _vm.lastVisibleSlideIndex <= i },style:({ backgroundImage: `url('${thumbnail}')` }),attrs:{"type":"button"},on:{"click":function($event){return _vm.goTo(i)}}})}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }