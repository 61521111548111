import { selectors } from 'src/store/selectors';
import Vod from 'src/mixins/Vod';
import Component, { mixins } from 'vue-class-component';
import { actions } from 'src/store/actions';
import { getArchiveTitleWatchUrl, getVodTitleWatchUrl } from 'src/utils/url';
import { Watch } from 'vue-property-decorator';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import logger from 'src/utils/logger';

const log = logger('class-title-details');

@Component
export class TitleDetails extends mixins(Vod) {
  @Watch('modalTitleId')
  async onTitleIdChange(titleId: string) {
    log.info(`titleId has changed to '${titleId}'`);
    if (titleId) {
      const seasonNumForNav = this.isPlayingTitleEqualModalTitle ? this.currentSeasonNum : 0;
      actions.vod.setCurrentSeasonNumForNav(this.$store, seasonNumForNav);
      await actions.pauses.loadVodPauses(this.$store, this.sourceId, this.modalTitleId);
      await this.onHasSavedPauseChange(true, true);
    }
  }

  @Watch('hasSavedPause')
  async onHasSavedPauseChange(val: boolean, hasModalTitleIdChanged = false) {
    if (val) {
      if (
        (!this.isPlayingTitleEqualModalTitle || hasModalTitleIdChanged) &&
        this.hasSeries &&
        this.lastPause.episodeId
      ) {
        const mediaItemId =
          (this.isPlayingTitleEqualModalTitle
            ? this.playingMediaItemId
            : this.lastPause.mediaItemId) || '';
        const seasonId = this.isPlayingTitleEqualModalTitle
          ? this.playingSeasonId
          : this.lastPause.seasonId;
        const episodeId = this.isPlayingTitleEqualModalTitle
          ? this.playingEpisodeId
          : this.lastPause.episodeId;
        const isSavedPause = this.isPlayingTitleEqualModalTitle ? false : this.isSavedPause;

        if (mediaItemId) {
          actions.vod.setCurrentMediaItemId(this.$store, mediaItemId);
        }
        if (seasonId) {
          actions.vod.setCurrentSeasonId(this.$store, seasonId);
        }
        actions.vod.setCurrentEpisodeId(this.$store, episodeId);

        if (this.seasonsLength) {
          await actions.vod.setCurrentAndNextSeasonsAndEpisodes(
            this.$store,
            this.sourceId,
            episodeId,
            isSavedPause,
            this.modalTitle
          );
        }
      }
    } else if (!this.playingTitle) {
      actions.vod.resetVideoData(this.$store);
      actions.vod.resetSeriesData(this.$store);
    }
  }

  get hasPlayerError() {
    return selectors.player.hasErrorSelector(this.$store);
  }

  get isTitleWithDetailsLoaded() {
    return !!this.modalTitle?.details;
  }

  get isPageTitleOpen() {
    return selectors.vod.isPageTitleOpenSelector(this.$store);
  }

  get years() {
    return this.modalTitle?.preview?.years;
  }

  get yearsFormatted() {
    if (this.years) {
      return this.years.length > 1
        ? `${this.years[0]} – ${this.years[this.years.length - 1]}`
        : this.years[0].toString();
    } else {
      return '';
    }
  }

  get isPlayingTitleEqualModalTitle() {
    return this.playingTitleId === this.modalTitleId;
  }

  get hasSeries() {
    return this.modalTitle?.preview?.hasSeries;
  }

  get currentSeasonNumForNav() {
    return selectors.vod.currentSeasonNumForNavSelector(this.$store);
  }

  get hasSavedPause() {
    if (!this.lastPause.id) {
      return false;
    }

    // for single titles
    if (!this.hasSeries) {
      return true;
    }

    // for episodes with series
    if (this.seasonsLength) {
      // within seasons
      return !!this.lastPauseSeason?.episodes.find(
        (e) => e?.id === this.lastPause.episodeId || e?.preview?.id === this.lastPause.episodeId
      );
    } else if (this.getEpisodes()?.length) {
      // without seasons
      return !!this.lastPauseEpisodeWithoutSeasons;
    }
  }

  get isSavedPause() {
    return selectors.vod.isSavedPause(this.$store);
  }

  set isSavedPause(val) {
    actions.vod.setIsSavedPause(this.$store, val);
  }

  get lastPause() {
    return selectors.pauses.lastVodPause(this.$store);
  }

  get lastPauseSeason() {
    return this.seasons?.find((s) => s?.id === this.lastPause.seasonId);
  }

  get lastPauseEpisode() {
    return this.lastPauseSeason?.episodes.find(
      (e) => e?.id === this.lastPause.episodeId || e?.preview?.id === this.lastPause.episodeId
    );
  }

  get lastPauseEpisodeWithoutSeasons() {
    return this.getEpisodes()?.find(
      (e: TVODTitlePreviewEnhanced) => e?.preview?.id === this.lastPause.episodeId
    );
  }

  get lastPauseTitle() {
    const title = [];

    if (!this.hasSeries && this.mediaItemWithPause?.title) {
      title.push(this.mediaItemWithPause.title);
      return title.join('. ');
    }

    if (this.seasonsLength) {
      title.push(
        this.lastPauseSeason?.title ||
          `${this.getTranslation('season')} ${this.lastPauseSeason?.number || ''}`
      );
      title.push(`${this.lastPauseEpisode?.title || this.lastPauseEpisode?.preview?.title || ''}`);
    } else {
      title.push(this.lastPauseEpisodeWithoutSeasons?.preview?.title || '');
    }

    return title.join('. ');
  }

  get mediaItemWithPause() {
    let item;
    if (this.hasSeries) {
      const getLastPauseEpisode = (episodes: TVODTitlePreviewEnhanced[]) =>
        episodes.find((episode) => episode.preview?.id === this.lastPause.episodeId);

      const season = this.seasons?.find((season) => getLastPauseEpisode(season.episodes));
      item = getLastPauseEpisode(season?.episodes || this.modalTitle?.episodes || []);
    } else {
      item = this.modalTitle;
    }
    return item?.details?.mediaItems?.find(
      (mediaItem) => mediaItem.id === this.lastPause.mediaItemId
    );
  }

  get ratingImdbFormatted() {
    return this.modalTitle?.preview?.ratingImdb
      ? this.modalTitle?.preview?.ratingImdb.toFixed(1)
      : '';
  }

  get ratingKinopoiskFormatted() {
    return this.modalTitle?.preview?.ratingKp ? this.modalTitle?.preview?.ratingKp.toFixed(1) : '';
  }

  get backgroundPoster() {
    return this.modalTitle?.preview?.posters?.[0];
  }

  get backgroundPosterPath() {
    return this.modalTitle?.preview?.posters ? this.modalTitle?.preview?.posters[0]?.path : '';
  }

  get currentEpisodeId() {
    return selectors.vod.currentEpisodeIdSelector(this.$store);
  }

  async beforeDestroy() {
    if (this.playingTitle?.seasons?.length) {
      await actions.vod.setCurrentAndNextSeasonsAndEpisodes(
        this.$store,
        this.sourceId,
        this.playingEpisodeId,
        this.isSavedPause
      );
    }
  }

  setShowNotificationAuthAndReg() {
    actions.common.showNotificationAuthAndReg(this.$store);
  }

  async saveDataToStoreAndGoToCatalogPage(
    titleId: string,
    episodeId: string,
    mediaItemId?: string
  ) {
    actions.vod.setPlayingTitleId(this.$store, titleId);
    actions.vod.setPlayingEpisodeId(this.$store, episodeId);
    if (mediaItemId) {
      actions.vod.setPlayingMediaItemId(this.$store, mediaItemId);
    }
    actions.vod.hideTitleDetails(this.$store, this.sourceId, this.modalTitleId);
    if (this.isArchive) {
      await this.$router.push(getArchiveTitleWatchUrl(titleId, episodeId, mediaItemId));
    } else {
      await this.$router.push(getVodTitleWatchUrl(this.sourceId, titleId, episodeId));
    }
  }

  async savePause() {
    await actions[this.type].savePause(this.$store);
  }

  async playNextEpisodeFromModal() {
    if (!this.nextEpisodeId) {
      return;
    }

    if (this.isAnonymous) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    await this.savePause();

    if (!this.isPlayingTitleEqualModalTitle) {
      if (this.seasonsLength) {
        await actions.vod.setCurrentAndNextSeasonsAndEpisodes(
          this.$store,
          this.sourceId,
          this.lastPause.episodeId,
          this.isSavedPause,
          this.modalTitle
        );
      }
    }

    if (this.isPageTitleOpen) {
      await this.saveDataToStoreAndGoToCatalogPage(this.lastPause.titleId, this.nextEpisodeId);
      return;
    }

    // save title to a local variable
    // because title will be erased after modal closes
    const _title = this.modalTitle;

    if (this.isArchive) {
      let nextEpisode;

      if (this.seasonsLength && this.nextEpisodeNum) {
        nextEpisode = this.getEpisodes(this.nextSeasonNum)?.[this.nextEpisodeNum];

        // for titles without seasons
        // play next episode in line
      } else if (this.getEpisodes()?.length) {
        const EPISODE_INDEX =
          _title.episodes?.findIndex(
            (e: TVODTitlePreviewEnhanced) => e?.preview?.id === this.currentEpisodeId
          ) || 0;
        nextEpisode = this.getEpisodes()?.[EPISODE_INDEX + 1];
      }

      if (nextEpisode) {
        await actions.archive.playNextEpisode(this.$store, nextEpisode, _title);
      }

      this.gaEvent({
        category: 'archive',
        action: 'Клик по кнопке "Следующий эпизод"',
        vod_name: 'archive',
        title_id: _title?.preview?.id,
      });
    } else {
      await actions.vod.playNextEpisode(this.$store, this.sourceId, _title);

      this.gaEvent({
        category: 'vod',
        action: 'Клик по кнопке "Следующий эпизод"',
        vod_name: this.sourceId,
        title_id: _title?.preview?.id,
      });
    }

    actions.vod.hideTitleDetails(this.$store, this.sourceId, this.modalTitleId);
  }
}
