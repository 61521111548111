<template>
  <div
    class="about-subscription with-side-and-bottom-padding dark"
    data-cy="qs-about-subscription"
    :class="{ 'qr-acquiring': token }"
  >
    <QuickSubscribeBackground />

    <div class="qs-content">
      <template v-if="token && $store.account.user">
        <h2 v-html="title" />
        <div class="about-subscription-note">
          <span v-html="getTranslation('qr_payment_for_account')" />&#32;<span
            v-text="$store.account.user.login"
          />
        </div>
      </template>

      <div>
        <OfferCard
          :sub="prioritySubscription"
          :priority="!token"
          :is-wl="isWl"
          :is-loading="isLoading"
          @createSub="createSub"
          @handleSubDetails="handleSubDetails"
        />

        <template v-if="otherSubscriptions.length">
          <span
            v-if="!showOther"
            class="link action-colored show-other"
            @click="handleShowOther"
            v-html="getTranslation('show_other_subscriptions')"
          />

          <h6 v-else class="show-other" v-html="availableAlternativeSubscriptionsText" />

          <transition name="fade-ease-out-slow">
            <div v-if="showOther" class="about-subscription-other-subs">
              <OfferCard
                v-for="sub in otherSubscriptions"
                :key="sub.offerId"
                :sub="sub"
                :is-wl="isWl"
                :is-loading="isLoading"
                @createSub="createSub"
                @handleSubDetails="handleSubDetails"
              />
            </div>
          </transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { goToNextStep } from 'src/store/quick-subscribe/actions';
import { CURRENCIES } from 'src/constants';
import UnorderedList from 'src/components/ui/UnorderedList.vue';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import OfferCard from 'src/components/quick-subscribe/OfferCard.vue';
import { selectors } from 'src/store/selectors';
import { wlDetector } from 'src/utils';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { TSubscriptionOption } from 'src/store/quick-subscribe/types';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    OfferCard,
    UnorderedList,
    QuickSubscribeBackground,
  },
})
export default class AboutSubscription extends SequoiaComponent {
  @Prop()
  isLoading?: boolean;

  goToNextStep = goToNextStep;
  CURRENCIES = CURRENCIES;

  showOther = false;
  offerChannels = [];
  token = '';

  created() {
    this.$store.QS.toPrevStep = false;
    this.$store.QS.subDetails = null;
  }

  get paymentRetry() {
    return selectors.QS.paymentRetrySelector(this.$store);
  }

  get subscriptionOptionsWithoutExisting() {
    return selectors.QS.subscriptionOptionsWithoutExistingSelector(this.$store);
  }

  get prioritySubscription() {
    if (this.paymentRetry) {
      return this.subscriptionOptionsWithoutExisting[0];
    }
    return selectors.QS.prioritySubscriptionSelector(this.$store);
  }

  get otherSubscriptions() {
    if (this.paymentRetry) {
      return this.subscriptionOptionsWithoutExisting.slice(1);
    }
    return selectors.QS.otherSubscriptionsSelector(this.$store);
  }

  get availableAlternativeSubscriptionsText() {
    const title =
      this.$store.QS?.sourceType === 'vod'
        ? this.vodCustomTitle
        : this.$store.QS?.channel?.name || this.$store.QS?.channel?.title;
    return `${title} ${this.getTranslation('available_alternative_subs')}`;
  }

  get vodCustomTitle() {
    const store = this.$store;
    const vodName = store.QS?.vod?.name || '';
    const vodSource = store.vod.sources[vodName];
    return vodSource?.customTitle ? this.getByLanguage(vodSource.customTitle) || '' : '';
  }

  get title() {
    const store = this.$store;

    return this.getTranslation('qr_payment_title').replace(
      '%channelName%',
      store.QS?.sourceType === 'vod'
        ? this.vodCustomTitle
        : store.QS.channel?.name || store.QS.channel?.title || ''
    );
  }

  get isWl() {
    return !wlDetector().isSmotreshka;
  }

  createSub(sub: TSubscriptionOption) {
    this.$emit('createSub', sub);
  }

  handleSubDetails(sub: TSubscriptionOption | null) {
    this.$store.QS.subDetails = sub;
    this.goToNextStep(this.$store);
    this.gaEvent({
      category: 'acquiring',
      action: 'Клик по кнопке "Подробнее"',
      trial_available: `${this.prioritySubscription?.trialAvailable}`,
      qs_qr: this.token ? 'qr' : 'qs',
      offer_id: sub?.offerId,
    });
    document.querySelector('.modal-quick-subscribe')?.scrollTo(0, 0);
  }

  handleShowOther() {
    this.showOther = true;
    this.gaEvent({
      category: 'acquiring',
      action: 'Клик "Показать другие тарифы"',
      qs_qr: this.token ? 'qr' : 'qs',
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.about-subscription {
  h1 {
    margin-bottom: 32px;

    @include tablet {
      font-size: 32px;
      line-height: 1.25;
    }
  }

  .about-subscription-note {
    margin-bottom: 32px;

    span {
      font-weight: bold;
    }
  }

  .show-other {
    position: relative;
    display: inline-block;
    margin-top: 48px;
    margin-bottom: 24px;

    @include mobile-and-tablet {
      margin-top: 32px;
    }
  }
}
</style>
