import * as authAndReg from 'src/store/auth-and-reg/actions';
import * as adultOnboarding from 'src/store/adult-onboarding/actions';
import * as appInfo from 'src/store/app-info/actions';
import * as archive from 'src/store/archive/actions';
import * as brandingMethods from 'src/store/branding-methods/actions';
import * as channelCollections from 'src/store/channel-collections/actions';
import * as common from 'src/store/common/actions';
import * as home from 'src/store/home/actions';
import * as payment from 'src/store/payment/actions';
import * as pauses from 'src/store/pauses/actions';
import * as player from 'src/store/player/actions';
import * as profile from 'src/store/profile/actions';
import * as providerInfo from 'src/store/provider-info/actions';
import * as QS from 'src/store/quick-subscribe/actions';
import * as registration from 'src/store/registration/actions';
import * as search from 'src/store/search/actions';
import * as seo from 'src/store/seo/actions';
import * as siteConfig from 'src/store/site-config/actions';
import * as tvChannels from 'src/store/tv-channels/actions';
import * as tvCurrentChannel from 'src/store/tv-current-channel/actions';
import * as tvEpg from 'src/store/tv-epg/actions';
import * as vod from 'src/store/vod/actions';
import * as metrics from 'src/store/metrics/actions';

export const actions = {
  adultOnboarding,
  appInfo,
  archive,
  authAndReg,
  brandingMethods,
  channelCollections,
  common,
  home,
  payment,
  pauses,
  player,
  profile,
  providerInfo,
  QS,
  registration,
  search,
  seo,
  siteConfig,
  tvChannels,
  tvCurrentChannel,
  tvEpg,
  vod,
  metrics,
};
