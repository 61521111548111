<template>
  <div v-if="ageRating" ref="ageRating" class="age-rating title-details-info" :class="theme">
    <span v-if="showTranslation" class="left" :class="`color-${theme}-font-tertiary`">
      <span v-html="getTranslation('age')" />
    </span>
    <span class="age" v-text="ageRating" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component
export default class TitleAgeRating extends SequoiaComponent {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: false })
  showTranslation?: boolean;

  @Prop()
  ageRating?: string;

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
.age-rating {
  .age {
    padding: 2px 8px;
    border-radius: 4px;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .age {
      background-color: var(--alpha-light-2);
    }
  }

  &.dark {
    .age {
      background-color: var(--alpha-dark-2);
    }
  }
}
</style>
