<template>
  <component
    v-if="currentStepComponentName"
    :is="currentStepComponentName"
    :is-loading="isLoading"
    @createSub="createSub"
  />
</template>

<script lang="ts">
import * as api from 'src/api';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import AboutSubscription from './steps/AboutSubscription.vue';
import SubscriptionDetails from './steps/SubscriptionDetails.vue';
import PhoneSending from '../phone-verification-steps/PhoneSending.vue';
import PhoneVerification from '../phone-verification-steps/PhoneVerification.vue';
import SelectVerificationSubscription from './steps/SelectVerificationSubscription.vue';
import PasswordVerificationSubscription from './steps/PasswordVerificationSubscription.vue';
import PhoneVerificationSubscription from './steps/PhoneVerificationSubscription.vue';
import Error from '../phone-verification-steps/PhoneVerificationError.vue';
import TrialGift from './steps/TrialGift.vue';
import PaymentRetry from './steps/PaymentRetry.vue';
import { goToStepByName } from 'src/store/quick-subscribe/actions';
import {
  CONTACT_VERIFICATION_OUTDATED_ERROR_CODE,
  SUBSCRIPTION_METHOD_NAMES,
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_STEPS,
  YAKASSA_RESPONSE_CODES,
} from 'src/constants';
import { expireDate } from 'src/utils/time/date';
import logger from 'src/utils/logger';
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { TError } from 'src/store/quick-subscribe/types';
import { LoginMethod } from 'src/models/ts/login';
import { makePath } from 'src/utils/url';
import { TYakassaSubscription } from 'src/api/profile/types';

const log = logger('acquiring');
@Component({
  components: {
    AboutSubscription,
    SubscriptionDetails,
    PhoneSending,
    PhoneVerification,
    Error,
    TrialGift,
    PaymentRetry,
    SelectVerificationSubscription,
    PasswordVerificationSubscription,
    PhoneVerificationSubscription,
  },
})
export default class AcquiringQS extends SequoiaComponent {
  token = '';
  isLoading = false;

  get currentStepComponentName() {
    return selectors.QS.currentStepComponentNameSelector(this.$store);
  }

  get paymentMethodAvailable() {
    return selectors.payment.paymentMethodAvailableSelector(this.$store);
  }

  mounted() {
    this.token = this.$route.query.token as string;
  }

  handleError(err: TError) {
    log.error(err);
    this.$store.QS.serverError.code = err.code;

    if (this.token) {
      this.$store.QS.QRPayment.isError = true;
      this.$store.QS.serverError.message = err.last_payment?.cancellation_details?.reason;
    } else {
      actions.common.hideModal(this.$store);
      actions.QS.hideModal(this.$store);
      if (!err.code) {
        this.$store.QS.serverError.message = this.getTranslation('error_server_unavailable');
      } else if (err.message) {
        this.$store.QS.serverError.message = err.message;
      } else if (err.last_payment) {
        this.$store.QS.serverError.message = this.getTranslation(
          `qs_${err.last_payment?.cancellation_details?.reason}`
        );
      } else {
        this.$store.QS.serverError.message = this.getTranslation('something_went_wrong');
      }
      this.$router.replace(
        `/acquiring-status?subscribed=false&source_url=${encodeURIComponent(location.pathname)}`
      );
      actions.player.hidePlayer(this.$store);
      actions.vod.resetVideoData(this.$store);
    }
  }

  async cancelInRetrySubscriptions() {
    try {
      // selecting in retry subscriptions
      const inRetrySubscriptions = selectors.payment.subscriptionsInRetrySelector(this.$store);
      // combining cancellation requests in an array of promises since there could be several subscriptions in in-retry status
      const promises: Array<Promise<TYakassaSubscription>> = [];
      inRetrySubscriptions.forEach((subscription) => {
        promises.push(
          api.profile.cancelSubscription(subscription.id, {
            params: { token: this.token },
          })
        );
      });
      await Promise.all(promises);
    } catch (err) {
      this.handleError(err as TError);
    }
  }

  handleChangeOffer() {
    actions.QS.goToStepByName(this.$store, 'AboutSubscription');
  }

  async createSub(subscription = this.$store.QS.tempSub) {
    const sourceUrl = location.pathname;
    const trialAvailable = subscription?.trialAvailable;
    const usePM = this.$store.QS.usePM && this.paymentMethodAvailable;
    const returnUrl =
      this.$route.name === 'qr-payment'
        ? `${location.origin}${makePath('/qr-payment')}?token=${this.token}&expires_at=${
            this.$route.query.expires_at
          }&utm_source=qr_purchase`
        : `${location.origin}${makePath('/acquiring-status')}?source_url=${encodeURIComponent(
            sourceUrl
          )}&utm_source=blocked_content`;

    this.$store.QS.tempSub = subscription;

    this.isLoading = true;
    await actions.payment
      .loadPaymentSubscriptions(this.$store, {
        params: { token: this.token },
      })
      .catch(() => {
        // do nothing
      });

    await this.cancelInRetrySubscriptions(); // before creating new subscription we must check existing subscriptions in-retry and cancel them, see https://lifestream.atlassian.net/browse/SEQ-1364

    const offer = this.$store.payment.subscriptions?.find(
      (s) => s?.offer?.id === subscription?.offerId
    );
    let createSubscriptionData: Record<string, any> = {
      offer_id: subscription?.offerId,
      use_pm: usePM,
      return_url: returnUrl,
    };
    let createSubscription: any = actions.QS.createYakassaSubscription;

    if (subscription?.trialAvailable) {
      createSubscriptionData.create_subscription_token = subscription.createSubscriptionToken;
      createSubscription = actions.QS.createYakassaSubscriptionV2;
    }

    const subMethodName =
      subscription?.subscriptionMethod?.name || subscription?.subscriptionMethods[0].name;
    if (subMethodName === SUBSCRIPTION_METHOD_NAMES.UPSALE) {
      createSubscription = actions.QS.createSubscriptionUpsale;
      createSubscriptionData = { Id: subscription?.offerId };
    } else if (
      subMethodName === SUBSCRIPTION_METHOD_NAMES.UPSALE_V2 ||
      subMethodName === SUBSCRIPTION_METHOD_NAMES.UPSALE_V21
    ) {
      createSubscription = actions.QS.createSubscriptionUpsaleV2;
      createSubscriptionData = {
        Id: subscription?.offerId,
        CreateSubscriptionToken: subscription?.createSubscriptionToken,
      };
    } else if (subMethodName === SUBSCRIPTION_METHOD_NAMES.AUTHENTICATED) {
      createSubscription = actions.QS.createSubscription;
      createSubscriptionData = { Id: subscription?.offerId };
    }

    try {
      const result = await createSubscription(this.$store, {
        params: {
          token: this.token,
        },
        data: createSubscriptionData,
      });
      const paid = result?.last_payment?.paid;
      const confirmationUrl = result?.last_payment?.confirmation?.confirmation_url;
      const successUrl = `/acquiring-status?subscribed=true&source_url=${encodeURIComponent(
        sourceUrl
      )}`;

      if (
        subMethodName &&
        [
          SUBSCRIPTION_METHOD_NAMES.UPSALE,
          SUBSCRIPTION_METHOD_NAMES.UPSALE_V2,
          SUBSCRIPTION_METHOD_NAMES.UPSALE_V21,
        ].includes(subMethodName)
      ) {
        if (result.status === '200') {
          this.$emit('handleClose');
          actions.player.hidePlayer(this.$store);
          actions.vod.resetVideoData(this.$store);
          goToStepByName(this.$store, 'AboutSubscription');
          return this.$router.replace(successUrl);
        } else {
          throw result;
        }
      }

      if (paid) {
        if (this.token) {
          this.$store.QS.QRPayment.title =
            (trialAvailable ? subscription?.trial?.title : subscription?.title) || '';
          this.$store.QS.QRPayment.expiresAt = expireDate(offer?.expires_at);
          this.$store.QS.QRPayment.isPaid = true;
        } else {
          this.$emit('handleClose');
          actions.player.hidePlayer(this.$store);
          actions.vod.resetVideoData(this.$store);
          await this.$router.replace(successUrl);
        }
      } else if (confirmationUrl) {
        if (
          trialAvailable &&
          subscription?.trial?.renderingData?.channels &&
          subscription?.renderingData?.channels &&
          subscription?.trial?.renderingData?.channels?.length >
            subscription?.renderingData?.channels?.length
        ) {
          this.$store.QS.confirmationUrl = confirmationUrl;
          goToStepByName(this.$store, 'TrialGift');
        } else {
          location.href = confirmationUrl;
        }
      } else if (result.status === '200') {
        this.$emit('handleClose');
        actions.player.hidePlayer(this.$store);
        actions.vod.resetVideoData(this.$store);
        await this.$router.replace(successUrl);
      } else {
        throw result;
      }
    } catch (err) {
      const error = err as TError;

      if (error.code === YAKASSA_RESPONSE_CODES.SUBSCRIPTION_ALREADY_EXISTS) {
        const lastPayment = offer?.last_payment;
        const confirmationUrl = lastPayment?.confirmation?.confirmation_url;
        if (lastPayment?.status === SUBSCRIPTION_STATUSES.PENDING && confirmationUrl) {
          if (
            trialAvailable &&
            subscription?.trial?.renderingData?.channels &&
            subscription?.renderingData?.channels &&
            subscription?.trial?.renderingData?.channels?.length >
              subscription?.renderingData?.channels?.length
          ) {
            this.$store.QS.confirmationUrl = confirmationUrl;
            goToStepByName(this.$store, 'TrialGift');
          } else {
            location.href = confirmationUrl;
          }
        } else {
          this.handleError(error);
        }
      } else if (error.code === YAKASSA_RESPONSE_CODES.NO_VERIFIED_PHONE) {
        goToStepByName(this.$store, 'PhoneSending');
      } else if (error.code === CONTACT_VERIFICATION_OUTDATED_ERROR_CODE) {
        this.isLoading = true;
        const method = await this.detectNextStep();
        goToStepByName(this.$store, method);
        this.isLoading = false;
      } else {
        this.handleError(error);
      }
    } finally {
      this.isLoading = false;
    }
  }

  async detectNextStep() {
    const { methods } = await api.profile.managingMethods();
    const isPasswordMethod = methods.find((method: LoginMethod) => method.name === 'password');
    const isPhoneMethod =
      methods.find((method) => method.name === 'with-verified-contacts') &&
      this.$store.account?.user.phone;

    if (isPasswordMethod && isPhoneMethod) {
      return SUBSCRIPTION_STEPS.SELECT_VERIFICATION;
    } else if (!isPhoneMethod) {
      return SUBSCRIPTION_STEPS.PASSWORD_VERIFICATION;
    } else {
      return SUBSCRIPTION_STEPS.PHONE_VERIFICATION;
    }
  }
}
</script>
<style lang="scss">
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/link';

.verification-subscription {
  .verification-subscription-info {
    position: relative;
    z-index: var(--z-1);
  }

  .verification-subscription-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  .title {
    max-width: 480px;
    margin-bottom: 24px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-height: 395px;
    padding-top: 48px;
    background-color: var(--c-light-100);

    .input-wrapper {
      width: 100%;
      max-width: 256px;
    }

    .form {
      display: flex;
      width: 100%;
    }

    .repeat-code {
      color: var(--c-light-font-primary);
      @extend %light-link-nav-underlined;
    }

    .timer-text {
      font-size: 14px;
      color: var(--c-light-font-secondary);
    }
  }
}
</style>
