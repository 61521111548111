<template>
  <transition name="button-fade" mode="out-in">
    <ButtonAction
      v-if="isVisible"
      :icon="IconAngleUp"
      class="scroll-top"
      data-cy="scroll-top"
      :theme-forced="theme"
      @click="onClick"
    />
  </transition>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { scrollToTop } from 'src/utils/scroll-to-top';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleUp from 'src/svg/angle-up.svg';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';

@Component({
  components: { ButtonAction, IconSVG },
})
export default class ButtonActionScrollTop extends Vue {
  IconAngleUp = IconAngleUp;

  scrollToTop = scrollToTop;
  scrollContainer: HTMLDivElement | Window | null = null;
  scrollElement: HTMLDivElement | Element | null | undefined;
  isVisible = false;
  isInitialized = false;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  scrollBlock?: HTMLDivElement;

  @Watch('scrollBlock')
  onScrollBlockChange() {
    if (!this.isInitialized) {
      this.scrollContainer?.removeEventListener('scroll', this.onScroll);
      this.initializeScroll();
      this.isInitialized = true;
    }
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  mounted() {
    this.initializeScroll();
  }

  destroyed() {
    this.scrollContainer?.removeEventListener('scroll', this.onScroll);
  }

  initializeScroll() {
    this.scrollContainer = this.scrollBlock ? this.scrollBlock : window;
    this.scrollElement = this.scrollBlock ? this.scrollBlock : document?.scrollingElement;
    this.scrollContainer?.addEventListener('scroll', this.onScroll);
  }

  onScroll() {
    this.isVisible = !!(this.scrollElement && this.scrollElement.scrollTop >= 800);
  }

  onClick() {
    if (this.scrollElement) {
      this.scrollToTop(this.scrollElement, 500);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.button.scroll-top {
  display: none;

  @media (min-width: #{$desktop-s-min}) {
    @include devices-with-hover {
      position: fixed;
      bottom: 24px;
      left: 12px;
      z-index: var(--z-6);
      display: flex;

      // --------------------------------------------
      // Theme Colors
      // --------------------------------------------

      &.light {
        background-color: var(--alpha-light-3);
      }

      &.dark {
        background-color: var(--alpha-dark-3);
      }
    }
  }
}

// Animation
.button-fade-enter-active,
.button-fade-leave-active,
.button-fade-leave-to-active {
  transition: opacity 0.15s var(--ease-out);
}

.button-fade-enter,
.button-fade-leave,
.button-fade-leave-to {
  opacity: 0;
}
</style>
