<template>
  <!-- empty action is needed in order to show Search button
  instead of a default one on mobile devices -->
  <form
    action=""
    class="input-block input-search-wrap"
    :class="[theme, { 'with-submit-button': withSubmitButton }]"
    @submit.prevent="submitSearch"
  >
    <!-- this input is needed to properly submit form with 1 input
         https://stackoverflow.com/questions/17797025/why-does-a-form-with-one-text-input-submit-on-enter-while-one-with-two-text-input -->
    <input type="hidden" />
    <input
      id="search"
      ref="search"
      class="input"
      type="search"
      enterkeyhint="search"
      :placeholder="!helper ? placeholder : ' '"
      :value="value"
      data-cy="search"
      @input="updateSelf($event.target.value)"
      @keydown.prevent.enter="submitSearch"
    />

    <label class="label" for="search" :class="{ 'with-helper': helper }">
      <IconSVG :svg="IconSearch" class="icon-search" />
      <span v-if="helper" class="placeholder">
        <span class="hidden-mobile-and-tablet">
          <span v-text="placeholder" />, <span class="helper" v-text="helper" />
        </span>
        <span class="visible-mobile-and-tablet" v-html="getTranslation('search')" />
      </span>
    </label>

    <div class="controls">
      <button
        v-if="value"
        type="submit"
        class="input-icon clear"
        data-cy="button-clear"
        @click="clearInput"
      >
        <IconSVG :svg="IconClear" />
      </button>
      <ButtonCircleConnected
        v-if="withSubmitButton"
        class="submit"
        direction="right"
        icon-type="arrow"
        data-cy="submit"
        :disabled="!value"
        @click="submitSearch"
      />
    </div>
  </form>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref } from 'vue-property-decorator';
import ButtonCircleConnected from 'src/components/ui/buttons/ButtonCircleConnected.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconSearch from 'src/svg/search.svg';
import IconClear from 'src/svg/clear.svg';

@Component({
  components: {
    IconSVG,
    ButtonCircleConnected,
  },
})
export default class InputSearch extends SequoiaComponent {
  IconSearch = IconSearch;
  IconClear = IconClear;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  value!: string;

  @Prop({ default: ' ' })
  placeholder!: string;

  @Prop()
  helper?: string;

  @Prop({ default: false })
  withSubmitButton!: boolean;

  @Ref('search')
  readonly refSearch?: HTMLInputElement;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get inputValue() {
    return this.value;
  }

  set inputValue(value: string) {
    this.$emit('input', value);
  }

  updateSelf(value: string) {
    this.$emit('input', value);
  }

  clearInput() {
    this.inputValue = '';
    this.refSearch?.focus();
  }

  submitSearch() {
    this.$emit('submitSearch');
  }
}
</script>

<style lang="scss">
@import 'src/components/ui/input/index';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.input-search-wrap.input-block {
  position: relative;
  height: 56px;

  .label {
    position: absolute;
    left: 0;
    width: 68px;
    height: 100%;
    padding-left: 16px;
    cursor: text;
    opacity: 1;

    &.with-helper {
      width: 100%;
    }

    .icon-search,
    .placeholder {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .placeholder {
      @extend %headline1;

      left: 68px;
      white-space: nowrap;
      transition: opacity 0.15s var(--ease-in-out);

      @include mobile-and-tablet {
        left: 54px;
      }

      @include mobile {
        font-size: 1.125rem;
        line-height: 1;
      }
    }

    //.helper {
    //  border-bottom: 1px dashed;
    //}
  }

  .input {
    height: 100%;
    padding-right: 56px;
    padding-left: 52px;
    border-style: solid;
    border-width: 2px;
    @extend %headline1;

    &:-webkit-autofill::first-line {
      font-size: 1.375rem;
    }

    &:focus {
      + .label {
        width: auto;

        .placeholder {
          z-index: var(--z-rock-bottom);
          opacity: 0;
        }
      }
    }

    &:not(:placeholder-shown) + .label .placeholder {
      opacity: 0;
    }
  }

  .controls {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .clear {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 100%;
  }

  .submit {
    display: none;
  }

  @include devices-with-hover {
    height: 64px;

    &.with-submit-button {
      .input {
        padding-right: 128px;
      }
    }

    .label {
      padding-left: 24px;
    }

    .input {
      padding-right: 68px;
      padding-left: 68px;
      font-size: 1.5rem;

      &:-webkit-autofill::first-line {
        font-size: 1.5rem;
      }
    }

    .controls {
      padding-left: 20px;
    }

    .clear {
      width: 40px;
      margin-right: 12px;
    }

    .submit {
      display: inline-flex;
      margin-right: 16px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .icon-search {
      color: var(--c-light-font-primary);
    }

    .input {
      border-color: var(--alpha-light-6);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

      &:focus {
        border-color: var(--alpha-light-8);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
      }

      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
      }

      &,
      &:focus,
      &:hover,
      &:active {
        background-color: var(--c-light-100);
      }
    }
  }

  &.dark {
    .icon-search {
      color: var(--c-dark-font-primary);
    }

    .input {
      border-color: var(--alpha-dark-6);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

      &:focus {
        border-color: var(--alpha-dark-8);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
      }

      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
      }

      &,
      &:focus,
      &:hover,
      &:active {
        background-color: var(--c-dark-150);
      }
    }
  }
}
</style>
