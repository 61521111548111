export const removeItem = (arr: any[], item: any) => {
  if (!arr.length) return;
  const index = arr.indexOf(item);
  if (index > -1) return arr.splice(index, 1);
};

/**
 * Sum of array elements
 */
export const sum = (arr: number[]): number | undefined => {
  return arr.length === 0 ? undefined : arr.reduce((acc, cur) => acc + cur, 0);
};

export function avg(numbers: number[]) {
  if (numbers.length === 0) {
    return undefined;
  }
  return numbers.reduce((a, b) => a + b, 0) / numbers.length;
}

export function min(numbers: number[]) {
  if (numbers.length === 0) {
    return undefined;
  }
  return Math.min.apply(null, numbers);
}

export function max(numbers: number[]) {
  if (numbers.length === 0) {
    return undefined;
  }
  return Math.max.apply(null, numbers);
}

export function filledWithZeros(length: number) {
  return new Array(length).fill(0);
}

export function flatten(array: any[]): any[] {
  let result: any[] = [];
  array.forEach((value) => {
    if (Array.isArray(value)) {
      result = result.concat(flatten(value));
    } else {
      result.push(value);
    }
  });
  return result;
}

/**
 * returns index of collection's element with ID equal to preferredId
 * if no such element, picks element with "default: true" flag
 * if no default element, returns 0
 * @param arr
 * @param preferredId
 * @returns {number} index
 */
export const getPreferredOrDefaultIndexOfArray = (arr: Array<any>, preferredId: string) => {
  if (!Array.isArray(arr) || !arr.length) {
    throw new Error('Bad array');
  }

  let index = -1;

  if (preferredId) {
    const lowerCasedPreferredId = preferredId.toLowerCase();
    index = arr.findIndex((el) => el?.id?.toLowerCase() === lowerCasedPreferredId);
  }

  if (index === -1) {
    index = arr.findIndex((el) => !!el.default);
  }

  if (index === -1) {
    index = 0;
  }

  return index;
};
