<template>
  <div class="continue-status body1" :data-cy="`${dataCy}-title-continue-status`">
    <div v-if="title" class="name" v-html="title" />
    <div class="progress-status" :class="`color-${theme}-font-secondary`">
      <ProgressBar
        v-if="progress > 0"
        :theme-forced="theme"
        :percents="progress"
        :alpha-level="6"
      />
      <div class="duration">
        <span v-text="timeLeft" />
        <span v-if="showDuration && duration"> / <span v-text="duration" /> </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import ProgressBar from 'src/components/ui/ProgressBar.vue';

@Component({
  components: {
    ProgressBar,
  },
})
export default class ContinueStatus extends SequoiaComponent {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  timeLeft!: string;

  @Prop({ required: true })
  duration!: string;

  @Prop({ required: true })
  progress!: number;

  @Prop({ default: true })
  showDuration?: boolean;

  @Prop()
  dataCy?: string;

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.name {
  width: 256px;
  @extend %truncate-after-1-line;

  @include mobile {
    width: 100%;
  }
}

.progress-status {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column-reverse;
    align-items: start;

    .duration {
      margin-bottom: 4px;
    }
  }

  .progress-bar {
    width: 256px;
    margin-right: 8px;

    @include mobile {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
