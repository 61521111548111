<template>
  <div class="tile-description" :class="theme" v-html="description" />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component
export default class TileDescription extends SequoiaComponent {
  @Prop({ required: true })
  description!: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/truncate-lines';
@import 'src/styles/placeholders-and-mixins/typography';

.tile-description {
  @extend %caption2;
  @extend %truncate-after-2-lines;

  &.light {
    color: var(--c-light-font-secondary);
  }

  &.dark {
    color: var(--c-dark-font-secondary);
  }
}
</style>
