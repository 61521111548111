<template>
  <div :class="componentName" :data-ctype="$options.name" :data-cname="componentName">
    <div v-html="title" />
    <ul class="advantages-list">
      <li v-for="advantage in advantages" class="advantage-container">
        <div class="image-container">
          <img :src="advantage.imageUrl" alt="" />
        </div>
        <div class="description" v-html="advantage.contentBlock" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

@Component
export default class Advantages extends mixins(Global, CmsComponent) {
  // TODO rename AdvantagesSequoia

  content?: {
    contentBlock: { [language: string]: string };
    advantages: Array<{
      contentBlock: TTranslations;
      image: TTranslations<TImageCMS>;
    }>;
  } | null = null;

  get title() {
    return this.getByLanguage(this.content?.contentBlock);
  }

  get advantages() {
    return this.content?.advantages
      ?.map((advantage) => ({
        contentBlock: this.getByLanguage(advantage.contentBlock),
        imageUrl: this.getByLanguage(advantage.image)?.url,
      }))
      .filter((advantage) => !!advantage.imageUrl);
  }
}
</script>

<style lang="scss" scoped>
.advantages-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 0 10%;
  font-size: 1.125rem;

  li {
    position: relative;
    width: 18.75rem;
    margin: 1rem;
    list-style: none;

    > div {
      display: inline-block;
      vertical-align: top;
    }

    .image-container {
      width: 4.2rem;
      margin-top: 16px;
      margin-right: 8px;

      img {
        width: 100%;
      }
    }

    .description {
      width: calc(100% - 80px);
    }
  }
}
</style>
