<template>
  <div class="image-slider with-top-and-bottom-padding">
    <img v-if="slides.length === 1" :src="slides[0].imageUrl" alt="" />
    <ContentSlider
      v-else-if="slides.length > 1"
      type="legacy"
      :theme-forced="theme"
      :highlight-visible="false"
      :thumbnails="thumbnails"
    >
      <div v-for="slide in slides" class="slide">
        <img :src="slide.imageUrl" :alt="slide.title" />
        <div v-if="slide.title" class="text" v-html="slide.title" />
      </div>
    </ContentSlider>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import AddResizeListener from 'src/mixins/AddResizeListener';
import ButtonCircleConnected from 'src/components/ui/buttons/ButtonCircleConnected.vue';
import IconSVG from 'src/components/IconSVG.vue';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

@Component({
  components: { ContentSlider, IconSVG, ButtonCircleConnected },
})
export default class ImageSlider extends mixins(Global, CmsComponent, AddResizeListener) {
  content?: {
    images: Array<{
      title: TTranslations | undefined;
      image: TTranslations<TImageCMS>;
      imageId: string;
      titleId: string;
    }>;
  } | null = null;

  get slides(): { imageUrl: string; title: string }[] {
    if (!this.content?.images) {
      return [];
    }
    return this.content.images
      .filter((slide) => slide.image)
      .map((slide) => ({
        imageUrl: this.getByLanguage(slide.image)?.url || '',
        title: slide.title ? this.getByLanguage(slide.title) || '' : '',
      }));
  }

  get thumbnails() {
    return this.slides.map((slide) => slide.imageUrl);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.image-slider {
  img {
    width: 100%;
  }

  .slide {
    position: relative;
    width: 100%;
    padding-top: var(--proportion-16-9);

    img {
      position: absolute;
      top: 0;
    }

    .text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 16px;
      line-height: 20px;
      color: var(--c-dark-font-primary);
      background-color: var(--c-overlay-lvl-60);
    }
  }
}
</style>
