<template>
  <div class="container with-side-and-bottom-padding dark">
    <QuickSubscribeBackground />

    <div class="qs-content">
      <span class="icon-wrap">
        <IconSVG :svg="IconCrossCircled" class="color-error" :size="40" />
      </span>
      <h4 v-html="getTranslation('subscribed_error')" />
      <ButtonDefault @click="$store.QS.currentStepIndex = 0">
        <span v-html="getTranslation('try_again_2')" />
      </ButtonDefault>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconCrossCircled from 'src/svg/cross-circled.svg';
import IconSVG from 'src/components/IconSVG.vue';

@Component({
  components: {
    QuickSubscribeBackground,
    ButtonDefault,
    IconSVG,
  },
})
export default class PhoneVerificationError extends SequoiaComponent {
  IconCrossCircled = IconCrossCircled;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.container {
  .icon-wrap {
    margin-bottom: 16px;
  }

  h4 {
    margin-bottom: 48px;
  }
}
</style>
