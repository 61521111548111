<template>
  <div class="banner">
    <a
      v-if="isExternalLink && link"
      :class="theme"
      :data-ctype="$options.name"
      :data-cname="componentName"
      :style="style"
      :href="link"
      target="_blank"
      class="banner-inner"
    >
      <div class="icon-top" :style="'background-image: url(' + iconTop + ')'" />
      <div class="banner-text" v-html="title" />
      <div class="icon-bottom" :style="'background-image: url(' + iconBottom + ')'" />
    </a>
    <router-link
      v-if="!isExternalLink && link"
      :to="link"
      :data-ctype="$options.name"
      :data-cname="componentName"
      :style="style"
      class="banner-inner"
    >
      <div class="icon-top" :style="'background-image: url(' + iconTop + ')'" />
      <div class="banner-text" v-html="title" />
      <div class="icon-bottom" :style="'background-image: url(' + iconBottom + ')'" />
    </router-link>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CmsComponent from 'src/mixins/CmsComponent';
import Global from 'src/mixins/Global';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

@Component
export default class Banner extends mixins(Global, CmsComponent) {
  // TODO rename BannerSequoia

  content?: {
    title: TTranslations;
    link: string;
    iconTop: TTranslations<TImageCMS>;
    iconBottom: TTranslations<TImageCMS>;
    backgroundColor1?: {
      basic: string;
    };
    backgroundColor2?: {
      basic: string;
    };
  } | null = null;

  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }
  get title() {
    return this.getByLanguage(this.content?.title);
  }

  get style() {
    return {
      'background-image': `linear-gradient(124deg, ${this.content?.backgroundColor1?.basic}, ${this.content?.backgroundColor2?.basic})`,
    };
  }

  get link() {
    return this.content?.link;
  }

  get iconTop() {
    return this.getByLanguage(this.content?.iconTop)?.url || '';
  }

  get iconBottom() {
    return this.getByLanguage(this.content?.iconBottom)?.url || '';
  }

  get isExternalLink() {
    return this.link?.startsWith('http');
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.banner {
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 242px;
  height: 168px;
  margin-right: 24px;
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  @include heading5;

  .banner-inner {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow: hidden;
  }

  .banner-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: var(--c-dark-font-primary);
  }

  .icon-top,
  .icon-bottom {
    position: absolute;
    width: 72px;
    height: 72px;
    background-size: cover;
    transition: all 0.3s 0.1s;
    transform: rotate(-15deg);
  }

  .icon-top {
    top: -72px;
    left: -72px;
  }

  .icon-bottom {
    right: -72px;
    bottom: -72px;
  }

  @include devices-with-hover {
    &:hover {
      transform: scale(1.08);

      .icon-top {
        top: -24px;
        left: -16px;
      }

      .icon-bottom {
        right: -16px;
        bottom: -24px;
      }
    }
  }

  &:active {
    transform: scale(1.04);
  }

  @include devices-with-touch {
    .icon-top,
    .icon-top:hover {
      top: -24px;
      left: -16px;
    }

    .icon-bottom,
    .icon-bottom:hover {
      right: -16px;
      bottom: -24px;
    }
  }

  &:hover,
  &:active {
    @media devices-with-touch {
      transform: scale(0.96);
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    @include devices-with-hover {
      &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }

  &.dark {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

    @include devices-with-hover {
      &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>
