import {
  PROMETHEUS_ID,
  QS_STEP_NAMES,
  RENDERING_METHOD_NAMES,
  SPEEDNET_ID,
  SUBSCRIPTION_METHOD_NAMES,
  SUBSCRIPTION_STATUSES,
  TIGHT_VIDEO_ID,
} from 'src/constants';
import * as api from 'src/api';
import { sortByPriority } from 'src/utils/object';
import logger from 'src/utils/logger';
import {
  TLastPayment,
  TStep,
  TSubscriptionOption,
  TTemplate,
  TTemplates,
} from 'src/store/quick-subscribe/types';
import { TReqConfig } from 'src/api/utils';
import { RenderingMethod, SubscriptionOptionListV2 } from 'src/models/ts/offer';

const log = logger('quick-subscribe');

const trialSteps: Array<TStep> = [
  { name: 'AboutTrial' },
  { name: 'PhoneSending', prev: 'AboutTrial' },
  { name: 'PhoneVerification' },
  { name: QS_STEP_NAMES.CONFIRMED },
  { name: QS_STEP_NAMES.ERROR },
];

const trialTemplate: TTemplate = {
  component: 'TrialQS',
  steps(subscription: TSubscriptionOption) {
    return {
      authenticated: [{ name: 'AboutTrial' }, { name: 'TrialConfirmed' }],
      'sub20-phone-collect-instant': trialSteps,
    }[subscription.subscriptionMethod.name];
  },
  availableSubscriptionMethods: [
    'authenticated',
    'sub20-phone-collect-instant',
    'sub20-phone-collect-delayed',
  ],
};

const qsSteps: Array<TStep> = [
  { name: 'AboutSubscription', prev: 'PaymentRetry' },
  { name: 'SubscriptionDetails' },
  { name: 'PhoneSending', prev: 'AboutSubscription' },
  { name: 'PhoneVerification' },
  { name: QS_STEP_NAMES.ERROR },
  { name: 'TrialGift' },
  { name: 'PaymentRetry' },
  { name: 'SelectVerificationSubscription', prev: 'AboutSubscription' },
  { name: 'PasswordVerificationSubscription', prev: 'AboutSubscription' },
  { name: 'PhoneVerificationSubscription', prev: 'AboutSubscription' },
];

const mainTemplate: TTemplate = {
  component: 'AcquiringQS',
  steps(subscription: TSubscriptionOption) {
    return {
      authenticated: qsSteps,
      'sub20-yakassa-mvp': qsSteps,
      'sub20-phone-collect-instant': qsSteps,
      'sub21-yakassa': qsSteps,
      [SUBSCRIPTION_METHOD_NAMES.UPSALE]: qsSteps,
      [SUBSCRIPTION_METHOD_NAMES.UPSALE_V2]: qsSteps,
      [SUBSCRIPTION_METHOD_NAMES.UPSALE_V21]: qsSteps,
    }[subscription.subscriptionMethod.name];
  },
  availableSubscriptionMethods: [
    'authenticated',
    'sub20-phone-collect-instant',
    'sub20-yakassa-mvp',
    'sub21-yakassa',
    SUBSCRIPTION_METHOD_NAMES.UPSALE,
    SUBSCRIPTION_METHOD_NAMES.UPSALE_V2,
    SUBSCRIPTION_METHOD_NAMES.UPSALE_V21,
  ],
};

export const availableTemplates: TTemplates = {
  'qs20-yakassa-v2': mainTemplate,
  'qs21-yakassa': mainTemplate,
  'qs10-legacy-default': mainTemplate,
  'qs20-vod-trial': trialTemplate,
  'qs20-tv-trial': trialTemplate,
  'qs20-tv-default': mainTemplate,
  'qs20-vod-default': mainTemplate,
  'buy-on-website': {
    component: 'FallbackQS',
  },
};

export const getStepsForSubscription = (subscription: TSubscriptionOption) => {
  const templateName = subscription.renderingMethod.name;
  return availableTemplates[templateName].steps
    ? availableTemplates[templateName].steps(subscription)
    : [];
};

export const quickSubscribeDataPreparation = (quickSubscribeData: SubscriptionOptionListV2) => {
  const qsDataSubOptions = quickSubscribeData.subscriptionOptions;
  let subscriptionOptions = [];

  if (qsDataSubOptions?.length) {
    subscriptionOptions = sortByPriority(qsDataSubOptions)
      .map((one) => {
        const renderingMethods = sortByPriority(one.renderingMethods);
        const subscriptionMethods = sortByPriority(one.subscriptionMethods);

        for (const renderingMethod of renderingMethods) {
          const template = availableTemplates[renderingMethod.name];

          if (template) {
            for (const subscriptionMethod of subscriptionMethods) {
              const subscriptionMethodName = subscriptionMethod.name;

              if (template.availableSubscriptionMethods?.includes(subscriptionMethodName)) {
                one.renderingMethod = renderingMethod;
                one.subscriptionMethod = subscriptionMethod;
                break;
              }
            }
          }
          if (one.renderingMethod) {
            break;
          }
        }

        try {
          one.renderingData = JSON.parse(one.renderingDataJson);
          if (one.trialAvailable && one.trial) {
            one.trial.renderingData = JSON.parse(one.trial.renderingDataJson);
          }
        } catch (err) {
          log.error(err);
        }

        if (one.renderingMethod && one.subscriptionMethod) {
          if (!one.trialAvailable) {
            const differentPeriods = sortByPriority(
              qsDataSubOptions.filter((subOption) => {
                const renderingMethods = subOption.renderingMethods.filter(
                  (method) => method.name === RENDERING_METHOD_NAMES.BILLING_PERIOD
                );
                return one.title === subOption.title && renderingMethods.length;
              })
            );
            if (differentPeriods?.length) {
              one.differentPeriods = [one, ...differentPeriods];
            }
          }
          one.steps = getStepsForSubscription(one);
          return one;
        }
        return undefined;
      })
      .filter((one) => one);

    const prioritySubscriptionMethodName = subscriptionOptions?.[0]?.subscriptionMethod?.name || '';
    subscriptionOptions = subscriptionOptions.filter(
      (sub) => sub.subscriptionMethod.name === prioritySubscriptionMethodName
    );
  }

  if (!subscriptionOptions.length) {
    const fallback = quickSubscribeData.fallback;
    const fallbackEnhanced: { renderingData?: string; renderingMethod?: RenderingMethod } = {};

    if (fallback) {
      for (const renderingMethod of fallback?.renderingMethods) {
        if (availableTemplates[renderingMethod.name]) {
          try {
            fallbackEnhanced.renderingData = JSON.parse(fallback.renderingDataJson);
          } catch (err) {
            log.error(err);
          }
          fallbackEnhanced.renderingMethod = renderingMethod;
          subscriptionOptions.push({ ...fallback, ...fallbackEnhanced });
          break;
        }
      }
    }
  }

  return {
    existingSubscriptions: quickSubscribeData.existingSubscriptions,
    subscriptionOptions,
    steps: subscriptionOptions[0] ? getStepsForSubscription(subscriptionOptions[0]) : [],
    qsTransactionId: '',
  };
};

export const isAcquiring = {
  [PROMETHEUS_ID]: true,
  [SPEEDNET_ID]: true,
  [TIGHT_VIDEO_ID]: true,
};

/**
 * If status is "pending" - we have to re-check status again each N seconds, until something other than "pending" will be returned
 * see https://lifestream.atlassian.net/browse/SEQ-883
 */
export const getPaymentStatus = (
  subscriptionId: string,
  config: TReqConfig = {}
): Promise<TLastPayment> =>
  new Promise(async (resolve, reject) => {
    const checkPaymentStatusTimeout = 5000; // milliseconds
    const checkPaymentStatusAttempts = 6; // amount of attempts to check payment status

    let attempt = 0;
    let status: string | undefined = '';
    let lastPayment: TLastPayment = {
      status: '',
      cancellation_details: {
        reason: '',
      },
    };
    let error = false;
    // making first attempt immediately
    try {
      lastPayment = await api.quickSubscribe.getLastPaymentFromKassa(subscriptionId, config);
      status = lastPayment?.status;
    } catch (err) {
      error = true;
      reject(err);
    }
    if (status !== SUBSCRIPTION_STATUSES.PENDING) {
      // status is not pending, we can resolve promise
      resolve(lastPayment);
    }
    // if status === "pending", try it in a loop
    const intervalId = setInterval(async () => {
      if (error || status !== SUBSCRIPTION_STATUSES.PENDING) {
        clearInterval(intervalId);
        return;
      }

      try {
        lastPayment = await api.quickSubscribe.getLastPaymentFromKassa(subscriptionId, config);
        status = lastPayment?.status;
      } catch (err) {
        clearInterval(intervalId);
        reject(err);
      }

      if (status !== SUBSCRIPTION_STATUSES.PENDING) {
        // status is not pending, we can resolve promise
        clearInterval(intervalId);
        resolve(lastPayment);
      }
      attempt++;
      if (attempt >= checkPaymentStatusAttempts - 1) {
        // max attempts reached, reject promise
        clearInterval(intervalId);
        log.info(`Check-payment status attempts exceeded, status is "${status}"`);
        resolve(lastPayment);
      }
    }, checkPaymentStatusTimeout);
  });

/**
 * Payment status considered successful if it's waiting_for_capture or succeeded
 * See https://kassa.yandex.ru/developers/payments/payment-process#payment-statuses
 */
export const isPaymentSuccessful = (status: string) =>
  status === SUBSCRIPTION_STATUSES.WAITING_FOR_CAPTURE ||
  status === SUBSCRIPTION_STATUSES.SUCCEEDED;

export const checkPaymentStatusTrials = async (subscriptionId: string, token = '') => {
  const subscription = await api.quickSubscribe.subscription(subscriptionId, {
    params: { token },
  });
  const finalStatuses = [
    SUBSCRIPTION_STATUSES.CANCELED,
    SUBSCRIPTION_STATUSES.SUCCEEDED,
    SUBSCRIPTION_STATUSES.WAITING_FOR_CAPTURE,
  ];
  return (
    subscription.subscription_renew_status === 'active' &&
    finalStatuses.includes(subscription.last_payment.status)
  );
};
