<template>
  <ModalSequoia
    v-if="!isAnonymous && showAgeConfirmationOverlay"
    class="age-confirmation-overlay"
    size="medium"
    type="fixed"
    :with-blur="true"
    :with-button-close="false"
    :title="getTranslation('modal_age_confirmation_title')"
  >
    <div class="with-side-and-bottom-padding">
      <div class="description" v-html="getTranslation('modal_age_confirmation_description')" />

      <div class="buttons-wrapper">
        <ButtonDefault view="primary" type="button" size="small" @click="setAgeConfirmed">
          <span v-html="getTranslation('modal_age_confirmation_button_1')" />
        </ButtonDefault>
        <ButtonDefault view="secondary" type="button" size="small" @click="goToProfilesPage">
          <span v-html="getTranslation('modal_age_confirmation_button_2')" />
        </ButtonDefault>
      </div>
    </div>
  </ModalSequoia>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import logger from 'src/utils/logger';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import { localStore } from 'src/utils/storage';

const log = logger('age-confirmation-overlay');

@Component({
  components: { ModalSequoia, ButtonDefault },
})
export default class AgeConfirmationOverlay extends SequoiaComponent {
  get showAgeConfirmationOverlay() {
    return this.$store.common.showAgeConfirmationOverlay;
  }

  setAgeConfirmed() {
    localStore.set('ageConfirmed', true);
    this.$store.common.showAgeConfirmationOverlay = false;
  }

  setProfileConfirmed() {
    localStore.set('profileConfirmed', true);
    this.$store.common.showProfileConfirmationOverlay = false;
  }

  goToProfilesPage() {
    this.setAgeConfirmed();
    this.setProfileConfirmed();
    if (this.$route.path !== '/personal/profiles') {
      this.$router.push('/personal/profiles');
    }
  }
}
</script>

<style lang="scss" scoped>
.age-confirmation-overlay.modal {
  &::v-deep {
    .title.h3.with-side-and-bottom-padding {
      padding-bottom: 24px;
      margin-bottom: 0;
    }
  }
}
</style>
