import { getCurrentUrl } from 'src/utils/url';
import { getEnvVars } from 'src/config';
import convertLayoutRu from 'convert-layout/ru';
import logger from 'src/utils/logger';
import { TTranslations } from 'src/store/translations/types';

const log = logger('language');

export const calculateLanguageCode = () => {
  const url = getCurrentUrl();
  const { languages, locales } = global.cmsData;
  const searchLang = url.pathname.split('/')[1];
  //условие для промо страницы по задаче SEQ-1241
  const languageCode =
    url.pathname === '/promo' && getEnvVars().wlAppId === 'kt'
      ? 'kk'
      : locales.includes(searchLang)
      ? searchLang
      : global.cmsData.defaultLanguage || 'ru';
  if (locales.indexOf(languageCode) !== -1) {
    return languageCode;
  }

  if (locales.indexOf(window.navigator.language) !== -1) {
    return window.navigator.language;
  }

  // if not found, try to search in 'forWhichLocales' arrays in config languages
  for (const language of languages) {
    if (!Array.isArray(language.forWhichLocales) || !language.forWhichLocales.length) {
      continue;
    }
    if (language.forWhichLocales.indexOf(window.navigator.language) > -1) {
      return language.locale;
    }
  }

  // if still not found, return default value
  return locales[0] || 'ru';
};

export const getByLanguage = <T = undefined>(
  translations: TTranslations<T> | undefined | null,
  languageCode: string | undefined
) => {
  if (!translations || typeof translations !== 'object') {
    return null;
  }
  const keys = Object.keys(translations);
  if (!keys.length) {
    log.error('getByLanguage: translations are empty');
    return null;
  }

  if (!languageCode) {
    log.error('getByLanguage: languageCode was not provided. It must be provided');
    return null;
  }

  return (
    translations[languageCode] ||
    translations['ru'] ||
    translations['en'] ||
    translations[keys[0]] ||
    null
  );
};

export const enToRu = (value: string) => {
  if (!value) {
    return '';
  }
  return convertLayoutRu.fromEn(value);
};

export const transliterate = (word: string) => {
  const keys: Record<string, string> = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'j',
    з: 'z',
    и: 'i',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'shch',
    ы: 'y',
    э: 'e',
    ю: 'u',
    я: 'ya',
    h: 'h',
    d: 'd',
  };

  return word
    .toLowerCase()
    .trim()
    .split('')
    .map((char) => keys[char] || '')
    .join('');
};
