<template>
  <div class="verification-subscription">
    <QuickSubscribeBackground />

    <SubscriptionInfoLine />

    <div class="verification-subscription-content">
      <div class="form-container with-side-and-bottom-padding pt-32 light">
        <div class="description mb-32">
          <span v-html="getTranslation('sms_sent')" />&#32;<span
            class="bold"
            v-text="formatPhone($store.account.user.phone)"
          />
        </div>
        <form autocomplete="off" novalidate class="form" @submit.prevent="phoneVerification">
          <div class="input-wrapper mr-16">
            <InputText
              ref="smscode"
              v-model="code"
              theme-forced="light"
              input-category="floating"
              size="medium"
              required
              type="number"
              :maxlength="smsCodeMaxLength"
              :status="error.sendCode ? 'error' : undefined"
              :label="getTranslation('code_from_sms')"
              :message="getTranslation('confirm_verify_code_error')"
            />
          </div>
          <ButtonDefault
            size="medium"
            :is-loading="isLoading"
            :disabled="disabledButton"
            class="button"
          >
            <span v-html="getTranslation('confirm_verify_subscription')" />
          </ButtonDefault>
        </form>
        <p v-if="error.sendSms" class="body2 mt-16 color-light-error" v-text="error.sendSms" />
        <p
          v-if="timer > 0"
          class="timer-text mt-16"
          v-html="getTranslation('confirm_verify_timer').replace(/%seconds%/g, `${timer}`)"
        />
        <a
          v-if="interval && timer === 0"
          class="repeat-code mt-16 link"
          @click="sendSms"
          v-html="getTranslation('resend_code')"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import SubscriptionInfoLine from '../SubscriptionInfoLine.vue';
import { wlDetector } from 'src/utils';
import { MTS_AUTH_SMS_CODE_LENGTH, REGISTRATION_SMS_CODE_LENGTH } from 'src/constants';
import * as api from 'src/api';
import { cleanupPhone, formatPhone } from 'src/utils/phone';
import { Ref, Vue } from 'vue-property-decorator';

@Component({
  components: { QuickSubscribeBackground, ButtonDefault, InputText, SubscriptionInfoLine },
})
export default class PhoneVerificationSubscription extends SequoiaComponent {
  code = '';
  isLoading = false;
  error = {
    sendCode: '',
    sendSms: '',
  };
  timer = 0;
  interval: NodeJS.Timeout | null = null;

  @Ref('smscode')
  refSmscode?: Vue;

  get smsCodeMaxLength() {
    return wlDetector().isMts ? MTS_AUTH_SMS_CODE_LENGTH : REGISTRATION_SMS_CODE_LENGTH;
  }

  get disabledButton() {
    return this.code.length < this.smsCodeMaxLength;
  }

  mounted() {
    this.sendSms();
    (this.refSmscode?.$refs.input as HTMLInputElement | undefined)?.focus();
  }

  async phoneVerification() {
    this.isLoading = true;
    try {
      const { sessionToken } = await api.auth.contactsVerification.verify({
        data: {
          type: 'phone',
          contacts: cleanupPhone(
            this.$store.account?.user?.phone,
            this.$store.authAndReg.usePlusInPhoneMethods.registration
          ),
          code: this.code,
        },
      });
      if (sessionToken) {
        await api.profile.managingWithVerifiedContacts({
          params: {
            'contacts-session-token': sessionToken,
          },
          data: {
            login: this.$store.account?.user.login,
          },
        });
        this.$emit('createSub');
      }
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      this.error.sendCode = err instanceof Error ? err.message : '';
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timer <= 0) {
        if (this.interval) {
          clearInterval(this.interval);
        }
        return;
      }
      this.timer--;
    }, 1000);
  }

  formatPhone(phone: string) {
    return formatPhone(phone);
  }

  async sendSms() {
    this.error.sendSms = '';
    try {
      const { interval } = await api.auth.contactsVerification.startWithAccountSession({
        data: {
          type: 'phone',
          contacts: cleanupPhone(
            this.$store.account?.user?.phone,
            this.$store.authAndReg.usePlusInPhoneMethods.registration
          ),
          reason: 'default',
        },
      });
      this.timer = interval;
      this.startTimer();
    } catch (err) {
      this.error.sendSms = err instanceof Error ? err.message : '';
    }
  }

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
</script>
