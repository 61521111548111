<template>
  <div class="welcome-step">
    <div class="h3" v-html="getTranslation('welcome_screen_step_welcome_title')" />
    <div class="body1" v-html="getTranslation('welcome_screen_step_welcome_text')" />
    <div class="profile-list mt-32 mb-32">
      <div class="profile-list-item flex mb-16">
        <div class="profile-list-item-icon flex-center-vertical">
          <IconSVG size="32" :svg="IconProfiles" />
        </div>
        <div class="profile-list-item-content">
          <div class="title body1" v-html="getTranslation('welcome_screen_profile_list_title_1')" />
          <div class="body1" v-html="getTranslation('welcome_screen_profile_list_text_1')" />
        </div>
      </div>
      <div class="profile-list-item flex mb-16">
        <div class="profile-list-item-icon flex-center-vertical">
          <IconSVG size="32" :svg="IconAdult" />
        </div>
        <div class="profile-list-item-content">
          <div class="title body1" v-html="getTranslation('welcome_screen_profile_list_title_2')" />
          <div class="body1" v-html="getTranslation('welcome_screen_profile_list_text_2')" />
        </div>
      </div>
      <div class="profile-list-item flex">
        <div class="profile-list-item-icon flex-center-vertical">
          <IconSVG size="32" :svg="IconKids" />
        </div>
        <div class="profile-list-item-content">
          <div class="title body1" v-html="getTranslation('welcome_screen_profile_list_title_3')" />
          <div class="body1" v-html="getTranslation('welcome_screen_profile_list_text_3')" />
        </div>
      </div>
    </div>
    <div class="profile-list-button">
      <ButtonDefault view="primary" type="button" @click="next">
        <span v-html="getTranslation('next')" />
      </ButtonDefault>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconProfiles from 'src/svg/profiles.svg';
import IconAdult from 'src/svg/adult.svg';
import IconKids from 'src/svg/genres/kids.svg';
import { cookieStore } from 'src/utils/storage';

@Component({
  components: { IconSVG, ButtonDefault },
})
export default class WelcomeStep extends SequoiaComponent {
  IconProfiles = IconProfiles;
  IconAdult = IconAdult;
  IconKids = IconKids;

  next() {
    if (this.$store.account?.profile.pin_required) {
      this.$emit('close', 'selectProfile');
      cookieStore.set('welcomeScreenConfirmed', '1');
    } else {
      this.$emit('changeStep', 'SetPin');
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.profile-list {
  .profile-list-item {
    .title {
      font-weight: 700;
    }

    .text {
      color: var(--alpha-light-7);
    }

    .profile-list-item-icon {
      width: 48px;
      color: var(--c-light-brand);
    }

    .profile-list-item-content {
      width: calc(100% - 48px);
    }
  }

  @include mobile {
    padding-bottom: 70px;
  }
}

.profile-list-button {
  width: 100%;

  @include mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 24px;
    background: #eaedee;
  }
}

.dark {
  .profile-list {
    .text {
      color: var(--alpha-dark-7);
    }
  }
}
</style>
