<template>
  <div class="notification-wrap" :data-cy="dataCy">
    <div class="backdrop" @click="$emit('hide')" />
    <transition appear name="notification-animation" mode="out-in">
      <div class="notification dark">
        <div class="top">
          <IconSVG v-if="icon" :svg="icon" :class="iconColorClass" />
          <h6 class="title" v-html="title" />
          <button type="button" class="button button-action" data-cy="close" @click="$emit('hide')">
            <IconSVG :svg="IconCross" />
          </button>
        </div>
        <div v-if="message" class="text body2">
          <span v-html="message" />
        </div>
        <ButtonDefault theme-forced="dark" @click="$emit('buttonAction')">
          <span v-html="buttonText" />
        </ButtonDefault>
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NotificationBaseComponent } from 'src/components/ui/notifications/NotificationBaseComponent';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';

@Component({
  components: {
    ButtonDefault,
    IconSVG,
  },
})
export default class NotificationWithButton extends NotificationBaseComponent {
  IconCross = IconCross;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  message!: string;

  @Prop({ required: true })
  buttonText!: string;

  @Prop()
  icon?: Record<string, unknown>;

  @Prop({ default: 'color-dark-error' })
  iconColorClass!: string;

  @Prop()
  dataCy!: string;
}
</script>

<style lang="scss">
@import 'notification-base';
</style>
