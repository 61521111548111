<template>
  <div
    v-if="text.trim()"
    class="badge"
    :class="[theme, type, size, typography, { 'with-icon': icon }]"
    :data-cy="dataCyPrefixed"
  >
    <IconSVG v-if="icon" :svg="icon" :size="iconSize" />
    <span v-html="text" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';

@Component({
  components: {
    IconSVG,
  },
})
export default class BadgeSequoia extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: '' })
  type!: '' | 'accent' | 'air' | 'brand';

  @Prop({ required: true })
  text!: string;

  @Prop({ default: '' })
  size!: '' | 'regular' | 'compact' | 'large';

  @Prop()
  icon?: Record<string, unknown>;

  @Prop({ default: 16 })
  iconSize?: number;

  @Prop({ default: 'caption1' })
  typography!: 'caption1' | 'caption2' | 'h6';

  @Prop()
  dataCy!: string;

  get dataCyPrefixed() {
    return this.dataCy ? `badge-${this.dataCy}` : '';
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 24px;
  padding-right: 8px;
  padding-left: 8px;
  white-space: nowrap;
  vertical-align: top;
  border-radius: 4px;

  @include mobile-and-tablet {
    height: 20px;
    padding-right: 4px;
    padding-left: 4px;

    .icon {
      display: none;
    }
  }

  &.large {
    height: 40px;
    padding-right: 16px;
    padding-left: 16px;
    border-radius: 8px;
  }

  &.regular {
    height: 24px;
    padding-right: 8px;
    padding-left: 8px;
  }

  &.compact {
    height: 20px;
    padding-right: 4px;
    padding-left: 4px;

    .icon {
      display: none;
    }
  }

  &.with-icon {
    padding-left: 4px;

    &.large {
      padding-left: 16px;
    }

    .icon {
      flex-shrink: 0;
      margin-right: 4px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    color: var(--c-light-font-primary);
    background-color: var(--c-light-300);

    &.accent {
      color: var(--c-dark-font-primary);
      background-color: var(--c-light-violet);
    }

    &.air {
      color: var(--c-dark-font-primary);
      background-color: var(--c-light-status-error);
    }

    &.brand {
      color: var(--c-text-on-brand-primary);
      background-color: var(--c-light-brand);
    }
  }

  &.dark {
    color: var(--c-dark-font-primary);
    background-color: var(--c-dark-600);

    &.accent {
      color: var(--c-light-font-primary);
      background-color: var(--c-dark-violet);
    }

    &.air {
      color: var(--c-light-font-primary);
      background-color: var(--c-dark-status-error);
    }

    &.brand {
      color: var(--c-text-on-brand-primary);
      background-color: var(--c-dark-brand);
    }
  }
}
</style>
