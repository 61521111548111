<template>
  <div class="verification-subscription">
    <QuickSubscribeBackground />

    <SubscriptionInfoLine />

    <div class="verification-subscription-content with-side-and-bottom-padding">
      <div class="description mb-32" v-html="getTranslation('confirm_verify_select_text')" />

      <div class="buttons-wrapper">
        <ButtonDefault @click="goToStepByName($store, 'PasswordVerificationSubscription')">
          <span v-html="getTranslation('enter_password')" />
        </ButtonDefault>
        <ButtonDefault
          class="dark"
          view="secondary"
          @click="goToStepByName($store, 'PhoneVerificationSubscription')"
        >
          <span v-html="getTranslation('code_from_sms')" />
        </ButtonDefault>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import SubscriptionInfoLine from '../SubscriptionInfoLine.vue';
import { goToStepByName } from 'src/store/quick-subscribe/actions';

@Component({
  components: {
    QuickSubscribeBackground,
    ButtonDefault,
    SubscriptionInfoLine,
  },
})
export default class SelectVerificationSubscription extends SequoiaComponent {
  goToStepByName = goToStepByName;
}
</script>
