import dayjs from 'dayjs';
import { THRESHOLDS } from 'src/constants';
import { DateTime } from 'src/utils/time/date-time';

export const dateAfterNDays = (days: number) => {
  return dayjs().add(days, 'day').format('DD.MM.YYYY');
};

export const dateAfterNMinutes = (minutes: number) => {
  return dayjs().add(minutes, 'minutes');
};

export const expireDate = (expire?: number) => {
  if (!expire) {
    return '';
  }

  const expiredDate = new Date(expire * THRESHOLDS.subscribes.dateUTCMultiplier);

  return expire > 0
    ? `${DateTime.getDMonth(expiredDate)}.${expiredDate.getFullYear()}`
    : `${DateTime.getDMonth(new Date())}.${new Date().getFullYear()}`;
};
