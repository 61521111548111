import { render, staticRenderFns } from "./TitleHgroup.vue?vue&type=template&id=12f5637a&scoped=true&"
import script from "./TitleHgroup.vue?vue&type=script&lang=ts&"
export * from "./TitleHgroup.vue?vue&type=script&lang=ts&"
import style0 from "./TitleHgroup.vue?vue&type=style&index=0&id=12f5637a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f5637a",
  null
  
)

export default component.exports