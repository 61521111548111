import sortBy from 'lodash/sortBy';
import { PLAYBACK_METHODS } from 'src/constants';
import { TStore } from 'src/store/types';
import { convertToMilliseconds, convertToSeconds, secondsToHM } from 'src/utils/time/convert-time';
import { serverTimeMsSelector } from 'src/store/app-info/selectors';
import {
  currentSeasonNumSelector,
  playingCurrentTimeSelector,
  playingEpisodeIdSelector,
  playingEpisodeWithoutSeasonIndexSelector,
  playingMediaItemSelector,
  playingTickOffsetSelector,
  playingTitleSelector,
} from 'src/store/vod/selectors';
import { lastVodPause } from 'src/store/pauses/selectors';

export const archivePosterWidthProportionSelector = (store: TStore) =>
  store.siteConfig?.archiveCatalog?.posterProportions?.width || 16;

export const archivePosterHeightProportionSelector = (store: TStore) =>
  store.siteConfig?.archiveCatalog?.posterProportions?.height || 9;

// -------------------------------------------------------------------------------------
// Archive Player
// -------------------------------------------------------------------------------------

export const isDvrRestrictedSelector = (store: TStore) =>
  playbackInfoSelector(store)?.dvrDepth === 0;

export const playbackInfoSelector = (store: TStore) => playingTitleSelector(store)?.playbackInfo;

export const isSmotreshkaPlaybackInfoSelector = (store: TStore) => {
  const playbackMethod = titlePlaybackMethodSelector(store);
  return playbackMethod?.name === PLAYBACK_METHODS.smotreshkaPlayBackInfo;
};

export const videoDelaySelector = (store: TStore) => {
  const delay = serverTimeMsSelector(store) - timelineStartsMsSelector(store);
  return Math.max(0, Math.floor(convertToSeconds(delay, 'millisecond')));
};

export const playingCurrentTimeInSecondsSelector = (store: TStore) => {
  return convertToSeconds(playingCurrentTimeSelector(store), 'millisecond');
};

// -------------------------------------------------------------------------------------
// Playing Title / Episode / Media Item
// -------------------------------------------------------------------------------------

export const isItTheLasEpisodeSelector = (store: TStore) =>
  playingEpisodeWithoutSeasonIndexSelector(store) + 1 ===
  playingTitleSelector(store)?.episodes?.length;

export const titlePlaybackMethodSelector = (store: TStore, forPlayingTitle = true) => {
  const title = forPlayingTitle
    ? playingTitleSelector(store)
    : store.vod?.sources?.archive?.fullTitles[store.vod.modalTitleId];
  if (!title) {
    return;
  }

  const seasons = title.seasons;

  let playbackMethods: Array<any> = [];

  if (title.preview?.hasSeries) {
    const episodeId = forPlayingTitle
      ? playingEpisodeIdSelector(store) || lastVodPause(store)?.episodeId
      : lastVodPause(store)?.episodeId;

    if (!episodeId) {
      return null;
    }

    const episodes = seasons?.[currentSeasonNumSelector(store)]?.episodes || title?.episodes;
    const episode = episodes?.find((e) => e.preview?.id === episodeId);

    if (!(episode && episode.details?.mediaItems)) {
      return null;
    }

    const mediaItem = playingMediaItemSelector(store, episode.details.mediaItems);
    playbackMethods = sortBy(mediaItem?.playbackMethods, 'priority');
  } else {
    if (!(title && title.details?.mediaItems)) {
      return null;
    }

    const mediaItem = playingMediaItemSelector(store, title.details.mediaItems);
    playbackMethods = sortBy(mediaItem?.playbackMethods, 'priority');
  }
  const existingMethods = Object.values(PLAYBACK_METHODS);
  return playbackMethods.find((pm) => existingMethods.includes(pm.name));
};

export const titlePlaybackMethodParamsSelector = (store: TStore, forPlayingTitle = true) => {
  const playbackMethod = titlePlaybackMethodSelector(store, forPlayingTitle);
  if (!playbackMethod) {
    return null;
  }
  return playbackMethod.params.reduce(
    (acc: { [x: string]: any }, elem: { key: string | number; value: any }) => {
      acc[elem.key] = elem.value;
      return acc;
    },
    {}
  );
};

export const titleStartTimeSelector = (store: TStore) => {
  const playbackMethod = titlePlaybackMethodSelector(store);
  const playbackMethodParams = titlePlaybackMethodParamsSelector(store);
  if (!(playbackMethod && playbackMethodParams)) {
    return 0;
  }
  if (playbackMethod.name === PLAYBACK_METHODS.smotreshkaPlayBackInfo) {
    return playbackMethodParams.start;
  }
  return 0;
};

export const titleStartTimeMsSelector = (store: TStore) =>
  convertToMilliseconds(titleStartTimeSelector(store), 'second') || 0;

export const titleEndTimeSelector = (store: TStore) => {
  const playbackMethod = titlePlaybackMethodSelector(store);
  const playbackMethodParams = titlePlaybackMethodParamsSelector(store);
  if (!(playbackMethod && playbackMethodParams)) {
    return 0;
  }
  if (playbackMethod.name === PLAYBACK_METHODS.smotreshkaPlayBackInfo) {
    return playbackMethodParams.end;
  }
  return 0;
};

export const titleEndTimeMsSelector = (store: TStore) =>
  convertToMilliseconds(titleEndTimeSelector(store), 'second') || 0;

export const titleDurationSelector = (store: TStore, forPlayingTitle = true) => {
  const playbackMethod = titlePlaybackMethodSelector(store, forPlayingTitle);
  const playbackMethodParams = titlePlaybackMethodParamsSelector(store, forPlayingTitle);

  if (!(playbackMethod && playbackMethodParams)) {
    return 0;
  }

  switch (playbackMethod.name) {
    case 'smotreshka-playback-info':
      return playbackMethodParams.end - playbackMethodParams.start;
    default:
      return 0;
  }
};

export const titleDurationMsSelector = (store: TStore, forPlayingTitle = true) =>
  convertToMilliseconds(titleDurationSelector(store, forPlayingTitle), 'second');

export const titleDurationSelectorHM = (store: TStore, forPlayingTitle = true) =>
  secondsToHM(titleDurationSelector(store, forPlayingTitle));

// ----------------------------------------------------------------------------------------
// Timeline
// ----------------------------------------------------------------------------------------

export const timelineDurationMsSelector = (store: TStore) =>
  titleDurationMsSelector(store) +
  titleDurationMsSelector(store) * playingTickOffsetSelector(store) * 2;

export const tickDurationMsSelector = (store: TStore) =>
  playingTickOffsetSelector(store) * timelineDurationMsSelector(store);

export const timelineStartsMsSelector = (store: TStore) =>
  titleStartTimeMsSelector(store) - tickDurationMsSelector(store);
