var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.href)?_c('a',{staticClass:"button default overlay-default-before",class:[
    _vm.theme,
    _vm.view,
    _vm.size,
    { 'full-width': _vm.fullWidth },
    { 'with-icon': _vm.withIcon },
    { 'with-white-bg': _vm.withWhiteBg },
    { 'is-external': _vm.isExternal },
  ],attrs:{"href":_vm.href,"data-cy":_vm.dataCyPrefixed,"target":_vm.isExternal ? '_blank' : '_self'}},[_c('span',{staticClass:"content"},[_vm._t("default")],2)]):(_vm.to)?_c('router-link',{staticClass:"button default overlay-default-before",class:[
    _vm.theme,
    _vm.view,
    _vm.size,
    { 'full-width': _vm.fullWidth },
    { 'with-icon': _vm.withIcon },
    { 'with-white-bg': _vm.withWhiteBg },
  ],attrs:{"to":_vm.to,"data-cy":_vm.dataCyPrefixed}},[_c('span',{staticClass:"content"},[_vm._t("default")],2)]):_c('button',{ref:"button",staticClass:"button default overlay-default-before",class:[
    _vm.theme,
    _vm.view,
    _vm.size,
    { 'full-width': _vm.fullWidth },
    { 'with-icon': _vm.withIcon },
    { loading: _vm.isLoading },
    { 'with-white-bg': _vm.withWhiteBg },
    { 'text-left': _vm.textAlign === 'left' },
  ],attrs:{"type":_vm.type,"disabled":_vm.disabled || _vm.isLoading,"data-cy":_vm.dataCyPrefixed},on:{"click":function($event){return _vm.onClick($event)},"focus":function($event){return _vm.onFocus($event)}}},[(_vm.isLoading)?_c('LoaderSpinner'):_vm._e(),_c('span',{staticClass:"content",style:({ visibility: `${_vm.isLoading ? 'hidden' : 'visible'}` })},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }