import { TStore } from 'src/store/types';
import Vue from 'vue';
import Router, { NavigationGuard, RouteConfig } from 'vue-router';

import { DEBUG_ACQUIRING_AFTER_REGISTRATION, AUTH_AND_REG_URLS } from 'src/constants';
import { userIdSelector } from 'src/store/account/selectors';

import CmsPage from 'src/pages/CmsPage.vue';
import Error404Page from 'src/pages/error/Error404Page.vue';
import { sessionStore } from 'src/utils/storage';

// these pages should be in main bundle, since they share one WrapperForCatalogAndPlayer component
import ChannelsCatalogPage from 'src/pages/ChannelsCatalogPage.vue';
import ArchiveCatalogPage from 'src/pages/archive/ArchiveCatalogPage.vue';
import VodCatalogPage from 'src/pages/vod/VodCatalogPage.vue';

//===========================
// App redirect page
//===========================
const AppRedirectPage = () =>
  import(/* webpackChunkName: "app-redirect-page" */ 'src/pages/AppRedirectPage.vue');

//===========================
// search page
//===========================
const SearchPage = () =>
  import(/* webpackChunkName: "home-and-search-page" */ 'src/pages/SearchPage.vue'); // search should be in one chunk with home, otherwise it produces conflicting order warning and styles loading issue

//===========================
// channels pages group
//===========================
const ChannelPage = () => import(/* webpackChunkName: "tv" */ 'src/pages/ChannelPage.vue');
const ChannelCollectionPage = () =>
  import(/* webpackChunkName: "tv" */ 'src/pages/ChannelCollectionPage.vue');

//===========================
// auth-and-reg pages group
//===========================
const RegPage = () => import(/* webpackChunkName: "auth-and-reg" */ 'src/pages/auth/RegPage.vue');
const AuthPage = () => import(/* webpackChunkName: "auth-and-reg" */ 'src/pages/auth/AuthPage.vue');
const ResetPasswordPage = () =>
  import(/* webpackChunkName: "auth-and-reg" */ 'src/pages/auth/ResetPasswordPage.vue');
const RestorePasswordPage = () =>
  import(/* webpackChunkName: "auth-and-reg" */ 'src/pages/auth/RestorePasswordPage.vue');
const PhoneUnlinkPage = () =>
  import(/* webpackChunkName: "auth-and-reg" */ 'src/pages/auth/PhoneUnlinkPage.vue');

//===========================
// archives pages group
//===========================
const ArchiveTitlePage = () =>
  import(/* webpackChunkName: "archive" */ 'src/pages/archive/ArchiveTitlePage.vue');

//===========================
// vod pages group
//===========================
const VodMainPage = () => import(/* webpackChunkName: "vod" */ 'src/pages/vod/VodMainPage.vue');
const VodTitlePage = () => import(/* webpackChunkName: "vod" */ 'src/pages/vod/VodTitlePage.vue');
const VodCollectionPage = () =>
  import(/* webpackChunkName: "vod" */ 'src/pages/vod/VodCollectionPage.vue');

//===========================
// acquiring page
//===========================
const AcquiringStatusPage = () =>
  import(/* webpackChunkName: "acquiring" */ 'src/pages/AcquiringStatusPage.vue');

//===========================
// qr pages group
//===========================
const QRLoginPage = () => import(/* webpackChunkName: "qr" */ 'src/pages/qr/QRLoginPage.vue');
const QRSuccessPage = () => import(/* webpackChunkName: "qr" */ 'src/pages/qr/QRSuccessPage.vue');
const QRPaymentPage = () => import(/* webpackChunkName: "qr" */ 'src/pages/qr/QRPaymentPage.vue');

//===========================
// personal pages group
//===========================
const PersonalPage = () =>
  import(/* webpackChunkName: "personal" */ 'src/pages/personal/PersonalPage.vue');
const PersonalDataPage = () =>
  import(/* webpackChunkName: "personal" */ 'src/pages/personal/inner/PersonalDataPage.vue');
const ProfilesPage = () =>
  import(/* webpackChunkName: "personal" */ 'src/pages/personal/inner/ProfilesPage.vue');
const SubscriptionsPage = () =>
  import(/* webpackChunkName: "personal" */ 'src/pages/personal/inner/SubscriptionsPage.vue');

//===========================
// promocode page
//===========================
const PromocodePage = () =>
  import(/* webpackChunkName: "promocode" */ 'src/pages/promocodes/PromocodePage.vue');

//===========================
// diagnostics page
//===========================
const DiagnosticsPage = () =>
  import(/* webpackChunkName: "diagnostics" */ 'src/pages/DiagnosticsPage.vue');

const PremiumPage = () =>
  import(/* webpackChunkName: "premium" */ 'src/pages/premium/PremiumPage.vue');

const CodePage = () => import(/* webpackChunkName: "codepage" */ 'src/pages/auth/CodePage.vue');

const PackagesPage = () =>
  import(/* webpackChunkName: "packages" */ 'src/pages/packages/PackagesPage.vue');
const PackagePage = () =>
  import(/* webpackChunkName: "package" */ 'src/pages/packages/PackagePage.vue');

Vue.use(Router);

export const createRouter = (store: TStore) => {
  const notAuthorizedGuard: NavigationGuard = (to, from, next) => {
    if (!userIdSelector(store)) {
      router.push('/login').catch(() => {
        // do nothing
        // have to use router.push() instead of next(), https://stackoverflow.com/a/65326844/2393499
      });
    } else {
      next();
    }
  };
  const alreadyAuthorizedGuard: NavigationGuard = (to, from, next) => {
    if (!!userIdSelector(store)) {
      router.push('/').catch(() => {
        // do nothing
        // have to use router.push() instead of next(), see https://stackoverflow.com/a/65326844/2393499
      });
    } else {
      if (from && process.env.VUE_ENV === 'client') {
        const excludeRedirectPage = Object.keys(AUTH_AND_REG_URLS).map(
          (page) => AUTH_AND_REG_URLS[page]
        );

        if (!excludeRedirectPage.includes(from.path) && from.path !== '/') {
          const backUrl =
            store.languageCode === store.defaultLanguage
              ? window.location.pathname
              : window.location.pathname.substring(3);
          sessionStore.set('backUrlAfterLogin', backUrl);
        }
      }
      next();
    }
  };

  const routes: Array<RouteConfig> = [
    {
      name: 'app-redirect',
      path: store.siteConfig?.appStores?.path || '/x',
      component: AppRedirectPage,
    },

    {
      name: 'qr-success',
      path: '/qr-success',
      component: QRSuccessPage,
      props: { customLayout: true },
    },
    { name: 'qr-login', path: '/qr-login', component: QRLoginPage, props: { customLayout: true } },
    {
      name: 'qr-payment',
      path: '/qr-payment',
      component: QRPaymentPage,
      props: { customLayout: true },
    },
    {
      name: 'acquiring-status',
      path: '/acquiring-status',
      component: AcquiringStatusPage,
    },
    { name: 'check', path: '/check', component: DiagnosticsPage },
    { name: '404', path: '/404', component: Error404Page },

    { name: 'search', path: '/search', component: SearchPage },

    { name: 'provider', path: '/provider', component: PremiumPage },

    // TV Channels
    { name: 'channels', path: '/channels', component: ChannelsCatalogPage },
    { name: 'channels-list', path: '/channels/list', component: ChannelsCatalogPage },
    { name: 'channels-now', path: '/channels/now', component: ChannelsCatalogPage },

    {
      name: 'channels-list-with-player',
      path: '/channels/list/:channelId?/watch',
      component: ChannelsCatalogPage,
    },
    {
      name: 'channels-now-with-player',
      path: '/channels/now/:channelId?/watch',
      component: ChannelsCatalogPage,
    },

    // TV Channel – Details
    {
      name: 'channel',
      path: '/channel/:channelId',
      component: ChannelPage,
    },
    {
      name: 'channel-about',
      path: '/channel/:channelId/about',
      component: ChannelPage,
    },
    {
      name: 'channel-program',
      path: '/channel/:channelId/program',
      component: ChannelPage,
    },

    // Collections
    {
      name: 'channel-collection',
      path: '/collection/channels/:slug',
      component: ChannelCollectionPage,
    },

    { name: 'vod-collection', path: '/collection/:sourceId?/:id', component: VodCollectionPage },

    // Archive
    {
      name: 'archive',
      path: '/archive',
      alias: '/cinema',
      component: ArchiveCatalogPage,
    },

    {
      name: 'archive-with-player',
      path: '/archive/:titleId?/:episodeId?/:mediaItemId?/watch',
      component: ArchiveCatalogPage,
    },

    {
      name: 'archive-title',
      path: '/archive/:titleId',
      component: ArchiveTitlePage,
    },

    // VOD
    {
      name: 'vod',
      path: '/vod',
      component: VodMainPage,
    },

    {
      name: 'vod-with-player',
      path: '/vod/:sourceId/:titleId?/:episodeId?/watch',
      component: VodCatalogPage,
    },

    {
      name: 'vod-catalog',
      path: '/vod/:sourceId',
      component: VodCatalogPage,
    },

    {
      name: 'vod-title',
      path: '/vod/:sourceId/:titleId',
      component: VodTitlePage,
    },

    // Login
    {
      name: 'login',
      path: '/login',
      beforeEnter: alreadyAuthorizedGuard,
      component: AuthPage,
      props: { customLayout: true },
    },
    {
      name: 'restore-password',
      path: '/restore-password',
      component: RestorePasswordPage,
      props: { customLayout: true },
    },

    // Personal
    {
      name: 'personal',
      path: '/personal',
      beforeEnter: notAuthorizedGuard,
      component: PersonalPage,
      children: [
        {
          name: 'personal-data',
          path: '/personal/data',
          beforeEnter: notAuthorizedGuard,
          component: PersonalDataPage,
        },
        {
          name: 'personal-profiles',
          path: '/personal/profiles',
          beforeEnter: notAuthorizedGuard,
          component: ProfilesPage,
        },
        {
          name: 'personal-subscriptions',
          path: '/personal/subscriptions',
          beforeEnter: notAuthorizedGuard,
          component: SubscriptionsPage,
        },
      ],
    },
    ...store.cmsPages?.map((page) => {
      const paths = page.path
        .replace(/\s/g, '')
        .split(',')
        .filter((path) => path);

      page.path = paths.shift() || '';

      if (paths.length === 1) {
        page.alias = paths[0];
      } else if (paths.length > 1) {
        page.alias = paths;
      }

      return {
        ...page,
        component: CmsPage,
        props: {
          cmsPageComponent: page.component,
          dataObject: page.dataObject,
        },
      };
    }),

    // promocodes for KT
    {
      name: 'promo',
      path: '/promo',
      component: PromocodePage,
      props: { customLayout: true },
    },

    // packages for megafon
    {
      name: 'packages',
      path: '/packages',
      component: PackagesPage,
    },
    {
      name: 'package',
      path: '/packages/:slug',
      component: PackagePage,
    },
  ];

  if (store.authAndReg?.showPhoneUnlinkPage) {
    routes.push({
      name: 'phone-unlink',
      path: '/phone-unlink',
      component: PhoneUnlinkPage,
      props: { customLayout: true },
    });
  }

  if (store.authAndReg?.showRegPage) {
    routes.push({
      name: 'reg',
      path: '/reg',
      component: RegPage,
      beforeEnter: DEBUG_ACQUIRING_AFTER_REGISTRATION ? undefined : alreadyAuthorizedGuard,
      props: { customLayout: true },
    });
  }

  if (store.authAndReg?.showRestorePwdPage) {
    routes.push({
      name: 'reset',
      path: '/reset',
      component: ResetPasswordPage,
      props: { customLayout: true },
    });
  }

  if (store.authAndReg?.usePromoCodes) {
    routes.push({
      name: 'code',
      path: '/code',
      component: CodePage,
      props: { customLayout: true },
    });
  }

  routes.push({ path: '*', component: Error404Page });

  const router = new Router({
    base: store.languageCode === store.defaultLanguage ? '/' : `/${store.languageCode}`,
    mode: 'history',
    routes,
    scrollBehavior(to, from, saved) {
      if (
        to.path === from.path ||
        (to.meta && to.meta.keepScrollPosition) ||
        from.path === '/' // TODO scroll-to-top should be triggered on each page after page load
      ) {
        return saved;
      }
      // use to restore a scroll position after prefetching within component code
      if (saved && to.meta) {
        to.meta.scrollPosition = saved;
      }
      return saved || { x: 0, y: 0 };
    },
  });

  return router;
};
