<template>
  <ErrorLayout :http-status="404" :image-desktop="imageUrl">
    <div v-if="contentBlock" v-html="contentBlock" />
    <div v-else>
      <h2 v-html="getTranslation('404_page_not_found')" />
      <p>
        <span v-html="getTranslation('try_going_to')" />
        <a
          class="link action-colored"
          href="/public"
          v-html="getTranslation('main_page_declension')"
        />.
      </p>
    </div>
  </ErrorLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ErrorLayout from 'src/layouts/ErrorLayout.vue';

@Component({
  components: { ErrorLayout },
})
export default class Error404 extends SequoiaComponent {
  get contentBlock() {
    return this.getByLanguage(this.$store.page404?.contentBlock);
  }

  get imageUrl() {
    const fallbackSrc = '/public/images/backgrounds/error-pages/404.png';
    return (
      this.getByLanguage(this.$store.page404?.image)?.url ||
      `${fallbackSrc}?v${this.$store.imagesMTimes[fallbackSrc]}`
    );
  }

  created() {
    if (process.env.VUE_ENV === 'server') {
      this.$ssrContext.httpStatus = 404;
    }
  }
}
</script>
