<template>
  <div
    class="channels-page-settings with-side-padding"
    :class="{ 'empty-fav': !favoriteChannels.length }"
  >
    <ChannelsSearch class="hidden-mobile-and-tablet" />
    <ChannelsPageSelector />
    <ChannelsSorting
      :class="{ show: showMobileFilters }"
      :disable-sorting="disableSorting"
      :sortings="sortings"
      @sortChannels="$emit('sortChannels', $event)"
    />
    <div :class="{ show: showMobileFilters }" class="filters-wrap">
      <ChannelsSearch class="visible-mobile-and-tablet" />
      <GenreFilter v-if="!isLoading" />
    </div>
    <ButtonDefault
      v-if="!showMobileFilters"
      view="tertiary"
      type="button"
      :with-icon="true"
      class="visible-mobile show-tv-filter"
      @click="showMobileFilters = true"
    >
      <IconSVG :svg="IconFilter" />
      <span v-html="getTranslation('show_tv_filters')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ChannelsSearch from 'src/components/tv-channels/catalog/ChannelsSearch.vue';
import ChannelsPageSelector from 'src/components/tv-channels/catalog/ChannelsPageSelector.vue';
import ChannelsSorting from 'src/components/tv-channels/catalog/ChannelsSorting.vue';
import GenreFilter from 'src/components/tv-channels/catalog/GenreFilter.vue';
import { selectors } from 'src/store/selectors';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { SequoiaComponent } from 'src/mixins';
import IconSVG from 'src/components/IconSVG.vue';
import IconFilter from 'src/svg/filter.svg';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';

@Component({
  components: {
    ChannelsSearch,
    ChannelsPageSelector,
    ChannelsSorting,
    GenreFilter,
    ButtonDefault,
    IconSVG,
  },
})
export default class ChannelsPageSettings extends SequoiaComponent {
  IconFilter = IconFilter;
  showMobileFilters = false;

  @Prop({ default: false })
  disableSorting!: boolean;

  @Prop({ required: true })
  isLoading!: boolean;

  @Prop({ required: true })
  sortings!: TDropdownItem[];

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.channels-page-settings {
  position: relative;
  z-index: var(--z-6);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 32px;

  @include mobile-and-tablet {
    margin-bottom: 8px;
  }

  .show-tv-filter {
    width: 100%;
  }

  &.empty-fav {
    margin-bottom: 40px;

    @media (max-width: #{$desktop-s-max}) {
      margin-bottom: 16px;
    }

    @include mobile {
      margin-bottom: 8px;
    }
  }

  .filters-wrap {
    width: 100%;

    @include mobile-and-tablet {
      display: flex;

      .channels-genre-filter {
        margin-top: 0;
      }
    }

    @include mobile {
      display: none;
      flex-wrap: wrap;

      &.show {
        display: flex;
      }

      .channels-genre-filter {
        width: 100%;
      }

      &::v-deep .button-dropdown {
        margin: 0;
      }
    }
  }

  @media (min-width: #{$desktop-s-min}) {
    .input-block {
      min-width: 256px;
    }
  }

  @include mobile-and-tablet {
    flex-wrap: wrap;

    .input-block {
      flex: 1;
      width: 200px;
      min-width: 0;

      &::v-deep .container {
        width: 100%;
      }
    }

    .selector-single {
      width: calc(50% - 8px);
    }

    .channels-sorting {
      width: calc(50% - 8px);
    }

    .channels-search {
      .container {
        width: 100%;
      }
    }

    &::v-deep .channels-sorting {
      .button-dropdown-inner {
        width: 100% !important;
        max-width: none;
      }
    }

    &::v-deep .selector-single {
      .menu-dropdown {
        width: 100%;
      }

      .inner {
        width: 100% !important;
        max-width: none;
      }
    }
  }

  @include mobile {
    .input-block {
      flex: auto;
      width: 100%;
      min-width: 0;
      margin-right: 0 !important;
    }

    .selector-single {
      width: 100%;
      margin-right: 0 !important;
    }

    .channels-sorting {
      display: none;
      width: 100%;

      &.show {
        display: flex;
      }
    }

    &::v-deep .menu-dropdown {
      flex: 1;
      width: 100%;

      .inner {
        width: 100% !important;
        max-width: none;
      }
    }
  }
}
</style>
