<template>
  <div class="profile-card" type="button" :class="[theme, { selected }]" @click="$emit('click')">
    <div class="badges">
      <BadgeSequoia v-if="profile.pin_required" type="brand" text="PIN" />
      <BadgeSequoia v-if="ageRating && ageRating >= 18" type="brand" text="18+" />
    </div>
    <IconSVG :svg="IconProfile" :size="40" class="color-secondary" />
    <div class="name" v-text="profile.name" />
    <div class="type color-secondary caption2" v-text="getProfileType(profile)" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { TProfile } from 'src/api/auth/types';
import { Prop } from 'vue-property-decorator';
import IconSVG from '../IconSVG.vue';
import IconProfile from 'src/svg/profile.svg';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';

@Component({
  components: { BadgeSequoia, IconSVG },
})
export default class ProfileCard extends SequoiaComponent {
  IconProfile = IconProfile;

  @Prop({ required: true })
  profile!: TProfile;

  @Prop({ default: false })
  selected!: boolean;

  get profileRestrictionsOptions() {
    return this.$store.profileRestrictions;
  }

  get ageRating() {
    return this.profile.age_rating;
  }

  getProfileType(profile: TProfile) {
    return (
      this.profileRestrictionsOptions.find(
        (option) =>
          option.id === profile.restriction ||
          (option.id === '2' && profile.restriction === 'default')
      )?.name ||
      profile?.restriction ||
      ''
    );
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.profile-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 184px;
  min-height: 156px;
  padding: 20px 16px 16px;
  cursor: pointer;
  background-color: var(--alpha-light-3);
  border-radius: 8px;

  @include mobile {
    max-width: 100%;
  }

  @include devices-with-hover {
    &:hover {
      background-color: var(--alpha-light-4);
    }

    &:active {
      background-color: var(--alpha-light-5);
    }
  }

  &.selected {
    pointer-events: none;
    background-color: transparent;

    .badges {
      display: none;
    }
  }

  .badges {
    position: absolute;
    top: -12px;
    left: 50%;
    display: flex;
    transform: translateX(-50%);

    @include mobile-and-tablet {
      top: -8px;
    }

    .badge {
      + .badge {
        margin-left: 4px;
      }
    }
  }

  .icon {
    position: relative;
    margin: 16px 16px 24px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 72px;
      height: 72px;
      background-color: var(--alpha-light-3);
      border-radius: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .name {
    max-width: 100%;
    @extend %truncate-after-1-line;
  }

  &.dark {
    background-color: var(--alpha-dark-3);

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-4);
      }

      &:active {
        background-color: var(--alpha-dark-5);
      }
    }

    &.selected {
      background-color: transparent;
    }

    .icon {
      &::before {
        background-color: var(--alpha-dark-3);
      }
    }
  }
}
</style>
