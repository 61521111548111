<template>
  <div id="app" data-cy="app" :class="{ 'with-smart-app-banner': showBanner }">
    <ProfileConfirmationOverlay v-if="!isRouteLoginOrReg" />

    <AgeConfirmationOverlay v-if="!isRouteLoginOrReg" />

    <WelcomeScreenModal v-if="!isRouteLoginOrReg" />

    <SmartAppBanner v-if="isMounted" />

    <component v-if="!hasCustomLayout" :is="headerComponent" />

    <Error500Page v-if="isError500" />

    <transition v-else name="fade" mode="out-in">
      <router-view />
    </transition>

    <!-- Notifications -->
    <div class="notifications-container">
      <NotificationSimple
        v-if="showLoginSuccessNotificationLocal && !isRouteLoginOrReg"
        icon="check-circled"
        :text="getTranslation('you_are_logged_in')"
      />

      <NotificationSimple
        v-if="$store.vod.errors.sourceWasNotFound"
        icon="cross-circled"
        :text="getTranslation($store.vod.errors.sourceWasNotFound)"
      />

      <template v-if="$store.notifications.profile.length">
        <NotificationSimple
          v-for="(modal, i) in $store.notifications.profile"
          :key="i"
          :icon="modal.icon"
          :text="modal.text"
        />
      </template>

      <template v-if="$store.notifications.favorite.length">
        <NotificationSimple
          v-for="(modal, i) in $store.notifications.favorite"
          :key="i"
          :icon="modal.icon"
          :text="modal.text"
        />
      </template>

      <NotificationAuthAndReg v-if="showNotificationAuthAndReg" />

      <NotificationSubscriptionPaymentError
        v-if="!isAnonymous & showNotificationSubscriptionPaymentError"
        :archived-subs="archivedSubs"
      />

      <NotificationReview v-if="showNotificationReview" />

      <NotificationWithDetails
        v-if="showAdultOnboardingNotification"
        :title="adultOnboardingNotificationTitle"
        :message="adultOnboardingNotificationMessage"
        @hide="closeAdultOnboardingNotification"
      >
        <ButtonDefault
          v-if="adultOnboardingWithButton"
          theme-forced="dark"
          @click="
            $router.push('/personal/profiles');
            closeAdultOnboardingNotification();
          "
        >
          <span v-html="getTranslation('profile_management')" />
        </ButtonDefault>
      </NotificationWithDetails>

      <NotificationWithDetails
        v-if="showNotificationWithDetails && dvrRestrictionMessage"
        :title="getTranslation('live_management_is_not_available')"
        :message="dvrRestrictionMessage"
        :icon="IconInfo"
        :truncate-message="true"
        :show-button="true"
        @showDetails="openDvrFullDetailsModal"
        @hide="closeNotificationWithDetails"
      />

      <NotificationWithButton
        v-if="showMegafonWarningNotification"
        :title="getTranslation('megafon_warning_title')"
        :message="getTranslation('megafon_warning_message')"
        :button-text="getTranslation('megafon_warning_button_text')"
        @hide="showMegafonWarningNotification = false"
        @buttonAction="showMegafonWarningNotification = false"
      />

      <NotificationSimple
        v-if="showQSNotification && QSNotificationText"
        icon="cross-circled"
        :text="QSNotificationText"
      />
    </div>

    <ModalSequoia
      v-if="!hasPlayerError && !isAnonymous && isDvrWarningModalVisible"
      theme-forced="dark"
      size="small"
      type="fixed"
      :with-blur="true"
      @close="hideDvrWarningModal"
    >
      <PlayerDvrWarning />
    </ModalSequoia>

    <ModalSequoia
      v-if="showDvrFullDetailsModal"
      theme-forced="dark"
      size="small"
      type="fixed"
      :with-blur="true"
      @close="showDvrFullDetailsModal = false"
    >
      <div class="dvr-error-modal with-side-and-bottom-padding">
        <div class="h3" v-html="getTranslation('live_management_is_not_available')" />
        <div class="content" v-html="dvrRestrictionMessage" />
      </div>
    </ModalSequoia>

    <ModalSequoia
      v-if="isHelpVisible"
      type="fixed"
      size="large"
      theme-forced="dark"
      :with-blur="true"
      :title="getTranslation('player_control')"
      @close="closeHelp"
    >
      <PlayerHelp />
    </ModalSequoia>

    <ModalSequoia
      v-if="isModalWithAppsOpen"
      type="fixed"
      :show-title="false"
      :with-fixed-header="false"
      :with-button-close="false"
    >
      <AppModal />
    </ModalSequoia>

    <portal-target name="modal" />

    <client-only>
      <vue-progress-bar style="background-color: var(--c-dark-brand)" />
    </client-only>

    <!-- TODO uncomment once there will be pay-with-existing-card and attach-new-card buttons in profile -->
    <!-- <PaymentRetryNotification v-if="!isAnonymous" /> -->
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { SequoiaComponent } from './mixins';
import { actions } from 'src/store/actions';
import { COOKIE_NAMES, EVENTS, LOCAL_STORAGE_NAMES } from 'src/constants';
import { cookieStore, localStore } from 'src/utils/storage';
import Error500Page from 'src/pages/error/Error500Page.vue';
import PaymentRetryNotification from 'src/components/payment-retry/PaymentRetryNotification.vue';
import NotificationSimple from 'src/components/ui/notifications/NotificationSimple.vue';
import { selectors } from 'src/store/selectors';
import NotificationAuthAndReg from 'src/components/ui/notifications/NotificationAuthAndReg.vue';
import ClientOnly from 'vue-client-only';
import logger from 'src/utils/logger';
import NotificationSubscriptionPaymentError from 'src/components/ui/notifications/NotificationSubscriptionPaymentError.vue';
import * as api from 'src/api';
import { TYakassaSubscription } from 'src/api/quick-subscribe/types';
import NotificationWithDetails from 'src/components/ui/notifications/NotificationWithDetails.vue';
import NotificationWithButton from 'src/components/ui/notifications/NotificationWithButton.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import NotificationReview from 'src/components/ui/notifications/NotificationReview.vue';
import { convertToMilliseconds } from 'src/utils/time/convert-time';
import IconInfo from 'src/svg/info.svg';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import PlayerDvrWarning from 'src/components/player/parts/tv/PlayerDvrWarning.vue';
import PlayerHelp from 'src/components/player/parts/tv/PlayerHelp.vue';
import { wlDetector } from 'src/utils';
import SmartAppBanner from 'src/components/app-redirect/SmartAppBanner.vue';
import { getDeviceFlags } from 'src/utils/platform-detector';
import AppModal from 'src/components/app-redirect/AppModal.vue';
import HeaderSequoia from 'src/components/cms/HeaderSequoia.vue';
import dayjs from 'dayjs';
import ProfileConfirmationOverlay from 'src/components/personal/ProfileConfirmationOverlay.vue';
import AgeConfirmationOverlay from 'src/components/personal/AgeConfirmationOverlay.vue';
import WelcomeScreenModal from 'src/components/welcome-screen/WelcomeScreenModal.vue';

const log = logger('app');

@Component({
  components: {
    WelcomeScreenModal,
    AgeConfirmationOverlay,
    ProfileConfirmationOverlay,
    AppModal,
    SmartAppBanner,
    ModalSequoia,
    Error500Page,
    PaymentRetryNotification,
    NotificationSimple,
    NotificationAuthAndReg,
    NotificationSubscriptionPaymentError,
    NotificationWithDetails,
    NotificationWithButton,
    PlayerHelp,
    ButtonDefault,
    NotificationReview,
    PlayerDvrWarning,
    ClientOnly,
  },
})
export default class App extends SequoiaComponent {
  IconInfo = IconInfo;

  isMounted = false;
  serverTimeInterval?: NodeJS.Timeout;
  progressTimeout?: NodeJS.Timeout;
  archivedSubs: TYakassaSubscription[] = [];
  showDvrFullDetailsModal = false;
  showLoginSuccessNotificationLocal = false;
  showMegafonWarningNotification = false;
  profileConfirmationPeriod = this.ageAndProfileConfirmation?.profileConfirmationPeriod || 600; // in minutes
  profileConfirmationInterval?: NodeJS.Timeout;
  isTabActive = true;

  @Watch('showLoginSuccessNotification')
  onShowLoginSuccessNotificationChange(val: boolean) {
    if (val) {
      this.showLoginSuccessNotificationLocal = true;

      setTimeout(() => {
        localStore.set('showLoginSuccessNotification', false);
        this.showLoginSuccessNotificationLocal = false;
      }, 10000);
    }
  }

  @Watch('$route')
  onRouteChange() {
    actions.player.clearError(this.$store);
  }

  @Watch('isAnonymous')
  async onIsAnonymousChange(val: boolean) {
    log.warning('reset all data due to store.isAnonymous flag change');
    await this.resetAllData();
    await this.loadSubscriptions();
  }

  @Watch('$store.common.isProfileLoaded')
  async onIsProfileLoadedChange(val: boolean) {
    if (val) {
      log.info('reset all data due to store.common.isProfileLoaded flag change');
      await this.resetAllData();
      await actions.profile.getAccount(this.$store);
      actions.common.unlockBodyScroll(this.$store);
    }
  }

  @Watch('$store.common.needServerTimeSync')
  async onNeedServerTimeSyncChange(val: boolean) {
    if (val) {
      this.clearServerTimeInterval();
      await actions.appInfo.updateServerTime(this.$store);
      actions.common.setLocalTime(this.$store, Date.now());
      actions.common.setNeedServerTimeSync(this.$store, false);
      this.setServerTimeInterval();
    }
  }

  // todo change 'any' once there will be proper type for 'is' attribute in component tag
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get headerComponent(): any {
    return {
      components: {
        HeaderSequoia,
      },
      template: this.$store.header,
    };
  }

  get ageAndProfileConfirmation() {
    return this.$store.siteConfig?.ageAndProfileConfirmation;
  }

  get isMobile() {
    return getDeviceFlags().isMobile;
  }
  get isTablet() {
    return getDeviceFlags().isTablet;
  }
  get isMobileOrTablet() {
    return this.isMobile || this.isTablet;
  }
  get isSmartAppBannerVisible() {
    return this.$store.siteConfig?.smartAppBanner?.isVisible;
  }
  get showSmartAppBanner() {
    return this.$store.common.showSmartAppBanner;
  }
  get showBanner() {
    return this.isSmartAppBannerVisible && this.showSmartAppBanner && this.isMobileOrTablet;
  }

  get isModalWithAppsOpen() {
    return this.$store.common.isModalWithAppsOpen;
  }

  get isUztel() {
    return wlDetector().isUztel;
  }

  get hasCustomLayout() {
    return (
      this.$router.options.routes?.find((r) => r.name === this.$route.name)?.props as Record<
        string,
        string
      >
    )?.customLayout;
  }

  get isRouteLogin() {
    return this.$route.name === 'login';
  }

  get isRouteReg() {
    return this.$route.name === 'reg';
  }

  get isRouteLoginOrReg() {
    return this.isRouteLogin || this.isRouteReg;
  }

  get showLoginSuccessNotificationFromStore() {
    return selectors.authAndReg.showLoginSuccessNotificationSelector(this.$store);
  }

  get showLoginSuccessNotification() {
    return (
      this.showLoginSuccessNotificationFromStore || !!localStore.get('showLoginSuccessNotification')
    );
  }

  get isHelpVisible() {
    return selectors.player.isHelpVisibleSelector(this.$store);
  }

  get hasPlayerError() {
    return selectors.player.hasErrorSelector(this.$store);
  }

  get isDvrWarningModalVisible() {
    return selectors.player.dvrWarningOverlaySelector(this.$store).isVisible;
  }

  get showNotificationWithDetails() {
    return selectors.common.showNotificationWithDetailsSelector(this.$store);
  }

  get dvrRestrictionMessage() {
    return selectors.tvCurrentChannel.dvrRestrictionMessageSelector(this.$store);
  }

  get showNotificationReview() {
    return selectors.common.showNotificationReviewSelector(this.$store);
  }

  get showNotificationSubscriptionPaymentError() {
    return selectors.common.showNotificationSubscriptionPaymentErrorSelector(this.$store);
  }

  get isError500() {
    return this.$store.isError500;
  }

  get showNotificationAuthAndReg() {
    return selectors.common.showNotificationAuthAndRegSelector(this.$store);
  }

  get tvAssetToken() {
    return selectors.common.tvAssetTokenSelector(this.$store);
  }

  get audienceToken() {
    return selectors.common.audienceTokenSelector(this.$store);
  }

  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get showAdultOnboardingNotification() {
    return selectors.adultOnboarding.showNotificationSelector(this.$store);
  }

  get showQSNotification() {
    return selectors.QS.showNotificationSelector(this.$store);
  }

  get QSNotificationText() {
    return selectors.QS.notificationTextSelector(this.$store);
  }

  get adultOnboardingNotificationTitle() {
    return selectors.adultOnboarding.notificationTitleSelector(this.$store);
  }

  get adultOnboardingNotificationMessage() {
    return selectors.adultOnboarding.notificationMessageSelector(this.$store);
  }

  get adultOnboardingWithButton() {
    return selectors.adultOnboarding.withButtonSelector(this.$store);
  }

  get reviewsSettings() {
    return selectors.reviews.reviewsSettingsSelector(this.$store);
  }

  async created() {
    const cookieTvAssetToken = cookieStore.get(COOKIE_NAMES.tvAssetToken);
    const cookieAudienceToken = cookieStore.get(COOKIE_NAMES.audienceToken);

    if (process.env.VUE_ENV === 'client') {
      this.progressLoaderStart();

      this.$router.beforeEach((to, from, next) => {
        log.info('progress router starting...');
        this.progressLoaderStart();
        next();
      });

      this.$router.afterEach(() => {
        log.info('progress router finishing...');
        this.progressLoaderFinish();
      });

      // TOKENS
      // Since asset tokens could have been loaded on server and set to store
      // it is necessary to set them in browser cookies as well,
      // otherwise, try to load asset tokens on the client-side
      if (!cookieTvAssetToken && this.tvAssetToken?.expiresAt) {
        cookieStore.set(COOKIE_NAMES.tvAssetToken, this.tvAssetToken, {
          expires: new Date(this.tvAssetToken?.expiresAt),
        });
      }

      if (!cookieAudienceToken && this.audienceToken?.expiresAt) {
        cookieStore.set(COOKIE_NAMES.audienceToken, this.audienceToken, {
          expires: new Date(this.audienceToken?.expiresAt),
        });
      }

      // If user already has a token saved in a cookie,
      // then we need to set this token to our store as well
      if (cookieTvAssetToken && !this.tvAssetToken?.expiresAt) {
        Vue.set(this.$store.common.assetTokens, 'tvAssetToken', cookieTvAssetToken);
      }

      if (cookieAudienceToken && !this.audienceToken?.expiresAt) {
        Vue.set(this.$store.common.assetTokens, 'audienceToken', cookieAudienceToken);
      }

      // -----------------------------------------------------

      await actions.home.loadBanners(this.$store).catch(() => {
        // do nothing
      });
      await actions.authAndReg.recheckAuthState(this.$store);
      await actions.tvChannels.getChannelsNotForPurchase(this.$store);
      await actions.profile.getProfileRestrictions(this.$store);

      this.progressLoaderFinish();
    }
  }

  async mounted() {
    this.isMounted = true;
    this.showMegafonWarningNotification =
      this.$route.query.from === 'megafon' && !this.showWelcomeScreen;
    await actions.appInfo.updateServerTime(this.$store);
    actions.common.setLocalTime(this.$store, Date.now());
    this.setServerTimeInterval();
    this.utmParamsToLocalStorage();
    this.$store.secretSanta = !!localStore.get('secretSanta') || false;
    this.checkTelecardRedirect();

    if (!this.isAnonymous) {
      this.checkAgeAndProfileConfirmations();

      actions.common.unlockBodyScroll(this.$store);
      this.onShowLoginSuccessNotificationChange(this.showLoginSuccessNotification);
      await this.loadSubscriptions();

      if (this.reviewsSettings.enabled && !localStore.get(LOCAL_STORAGE_NAMES.reviewsDisabled)) {
        setTimeout(() => {
          actions.common.setShowNotificationReview(this.$store, true);
        }, convertToMilliseconds(this.reviewsSettings.timeout, 'minute'));
      }
    }

    document.addEventListener('visibilitychange', this.onVisibilitychange);
  }

  beforeDestroy() {
    this.clearServerTimeInterval();
    this.clearProfileConfirmationInterval();
    document.removeEventListener('visibilitychange', this.onVisibilitychange);
  }

  onVisibilitychange() {
    if (document.hidden) {
      this.isTabActive = false;
      this.clearProfileConfirmationInterval();
    } else {
      this.updateCookieForProfileConfirmationPeriod();
      this.setProfileConfirmationInterval();
      this.isTabActive = true;
    }
  }

  async loadSubscriptions() {
    if (wlDetector().isSmotreshka) {
      try {
        await actions.payment.loadPaymentSubscriptions(this.$store);
      } catch (err) {
        log.error('onLoadPaymentSubscriptions', err);
      }
    }

    try {
      await actions.payment.loadPaymentSubscriptionsV2(this.$store);
    } catch (err) {
      log.error('onLoadPaymentSubscriptionsV2', err);
    }

    try {
      if (wlDetector().isSmotreshka) {
        // Notification Payment Error
        try {
          this.archivedSubs = await api.quickSubscribe.getArchivedSubscriptions();
        } catch (err) {
          // do nothing
        }

        if (this.archivedSubs.length) {
          const localArchivedSubs: Array<string> =
            localStore.get(LOCAL_STORAGE_NAMES.archivedSubs) || [];
          const filteredArchivedSubs = this.archivedSubs?.filter(
            (sub) =>
              !localArchivedSubs?.includes(sub.id) &&
              sub.expires_at !== 0 &&
              (sub.subscription_renew_status === 'canceled_by_payment' ||
                sub.subscription_renew_status === 'canceled_by_removing_payment_method')
          );

          setTimeout(() => {
            actions.common.setShowNotificationSubscriptionPaymentError(
              this.$store,
              !!filteredArchivedSubs?.length
            );
            if (window.innerWidth < 600 && this.isModalOpen) {
              actions.common.lockBodyScroll(this.$store);
            }
          }, 2000);
        }
      }
    } catch (err) {
      log.error(err);
    }
  }

  closeHelp(controlType?: string) {
    actions.player.hideHelp(this.$store, controlType);
  }
  hideDvrWarningModal() {
    actions.player.hideDvrWarningOverlay(this.$store);
  }

  closeAdultOnboardingNotification() {
    actions.adultOnboarding.hideNotification(this.$store);
  }

  closeNotificationWithDetails() {
    actions.common.setShowNotificationWithDetails(this.$store, false);
  }

  openDvrFullDetailsModal() {
    actions.common.setShowNotificationWithDetails(this.$store, false);
    this.showDvrFullDetailsModal = true;
  }

  async resetAllData() {
    log.info('resetAllData triggered');
    this.$store.flags.tvDataState.loaded = false;
    actions.tvChannels.resetGenre(this.$store);
    Vue.set(this.$store.home, 'banners', []);
    this.$store.home.isBannersDataLoaded = false;
    this.$store.vod.states.recommendationsLoaded = false;
    actions.channelCollections.resetChannelsInCollections(this.$store);
    actions.tvChannels.resetChannels(this.$store);
    actions.tvChannels.resetRecentAndFavChannels(this.$store);
    actions.tvChannels.resetRecommendedChannels(this.$store);
    actions.vod.resetSources(this.$store);
    actions.vod.resetCurrentCategoryId(this.$store);
    actions.vod.resetCollections(this.$store);
    await actions.common.removeAssetTokens(this.$store);
    // reload ALL data after reset
    await actions.vod.loadSources(this.$store);
    await actions.tvChannels.loadTvData(this.$store);
    await actions.tvChannels.loadRecentAndFavChannels(this.$store);
    await actions.home.loadBanners(this.$store);
  }

  utmParamsToLocalStorage() {
    // SEQ-628
    if (localStorage?.getItem('utmParameters')) {
      // if there are some utm-params in local-storage - no need to overwrite them
      return;
    }
    const searchParams = new URLSearchParams(document.location.search);
    const utmParamsKeys: { [key: string]: string } = {
      utmSource: 'utm_source',
      utmMedium: 'utm_medium',
      utmCampaign: 'utm_campaign',
      utmContent: 'utm_content',
      utmTerm: 'utm_term',
    };
    const utmParams: { [key: string]: string } = {};
    for (const key in utmParamsKeys) {
      utmParams[key] = searchParams.get(utmParamsKeys[key]) || '';
    }
    if (Object.values(utmParams).find((value) => !!value)) {
      // there should be at least one utm-param
      localStorage?.setItem(LOCAL_STORAGE_NAMES.utmParameters, JSON.stringify(utmParams));
    }
  }

  checkTelecardRedirect() {
    // telecard awful redirect (SEQ-447)
    if (
      !!this.$store.siteConfig?.wlSpecials?.telecardAnonymousRedirect &&
      this.isAnonymous &&
      location.pathname === `/`
    ) {
      if (!location.hash.length) {
        location.replace(`https://on.telekarta.tv/`);
      } else if (location.hash === 'loginButton') {
        try {
          const menuItem = document.querySelector('.authStateBlock a.menuItem') as HTMLDivElement;
          if (menuItem) {
            menuItem.click();
          }
        } catch (err) {
          log.error(
            'telecardAnonymousRedirect: cannot open login form, failed click on "#loginButton"',
            err
          );
        }
      }
    }
  }

  setServerTimeInterval() {
    // every second
    // actualize server time
    this.serverTimeInterval = setInterval(
      () => actions.appInfo.incrementServerTime(this.$store),
      1000
    );
  }

  setProfileConfirmationInterval() {
    // every 60 seconds:
    // - check whether it is time to show profile confirmation overlay after {period} minutes
    // - update profileConfirmationPeriod cookie
    this.profileConfirmationInterval = setInterval(() => {
      this.updateCookieForProfileConfirmationPeriod();
    }, 60 * 1000);
  }

  clearServerTimeInterval() {
    if (this.serverTimeInterval) {
      clearInterval(this.serverTimeInterval);
    }
  }

  clearProfileConfirmationInterval() {
    if (this.profileConfirmationInterval) {
      clearInterval(this.profileConfirmationInterval);
    }
  }

  progressLoaderStart() {
    this.progressLoaderClearTimeout();
    this.progressTimeout = setTimeout(() => {
      this.$Progress.start();
    }, 200);
  }

  progressLoaderFinish() {
    this.progressLoaderClearTimeout();
    this.$Progress.finish();
  }

  progressLoaderClearTimeout() {
    if (this.progressTimeout) {
      clearTimeout(this.progressTimeout);
    }
  }

  checkCookieForProfileConfirmationPeriod() {
    if (this.$store.siteConfig?.showWelcomeScreen) {
      return false;
    }

    const cookieProfileConfirmationPeriod = cookieStore.get(COOKIE_NAMES.profileConfirmationPeriod);

    if (cookieProfileConfirmationPeriod?.expires) {
      const startsAt = dayjs().toISOString();
      const expiresAt = dayjs(cookieProfileConfirmationPeriod.expires);
      const expirationDiff = expiresAt.diff(startsAt);

      // show profile confirmation overlay if cookie expired
      if (expirationDiff <= 0) {
        this.showProfileConfirmation();
      }
    } else {
      // show profile confirmation overlay if cookie was not found
      this.showProfileConfirmation();
    }
  }

  updateCookieForProfileConfirmationPeriod() {
    if (!this.isTabActive) {
      this.checkCookieForProfileConfirmationPeriod();
    }

    this.setCookieForProfileConfirmationPeriod();
  }

  showProfileConfirmation() {
    if (this.ageAndProfileConfirmation?.showProfileConfirmation) {
      this.$emit(EVENTS.player.pause);
      localStore.set('profileConfirmed', false);
      this.$store.common.showProfileConfirmationOverlay = true;
    }
  }

  showAgeConfirmation() {
    if (!localStore.get('ageConfirmed') && this.ageAndProfileConfirmation.showAgeConfirmation) {
      this.$emit(EVENTS.player.pause);
      localStore.set('ageConfirmed', false);
      this.$store.common.showAgeConfirmationOverlay = true;
    }
  }

  setCookieForProfileConfirmationPeriod() {
    const expires = {
      expires: new Date(
        Date.now() + convertToMilliseconds(this.profileConfirmationPeriod, 'minutes')
      ),
    };
    cookieStore.set(COOKIE_NAMES.profileConfirmationPeriod, expires, expires);
  }

  checkAgeAndProfileConfirmations() {
    if (cookieStore.get('welcomeScreenConfirmed') !== '1') {
      return false;
    }

    if (this.ageAndProfileConfirmation) {
      if (this.ageAndProfileConfirmation.showAgeConfirmation) {
        this.showAgeConfirmation();
      }
      if (this.ageAndProfileConfirmation.showProfileConfirmation) {
        if (!localStore.get('profileConfirmed')) {
          this.updateCookieForProfileConfirmationPeriod();
          this.setProfileConfirmationInterval();
          this.showProfileConfirmation();
        } else {
          this.checkCookieForProfileConfirmationPeriod();
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/media-queries';

.with-smart-app-banner {
  &::v-deep {
    header {
      top: 72px;
    }

    @include mobile-and-tablet {
      .cms-layout {
        height: calc(100% - 136px);
        margin-top: 136px;
      }
    }
  }
}
</style>
