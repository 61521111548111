var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"chip-selector",class:[
    { selected: _vm.selected },
    { disabled: _vm.disabled },
    { 'with-leading': _vm.withLeading },
    { 'with-action': _vm.withAction },
    _vm.size ? _vm.sizeClass : '',
    _vm.theme,
  ],attrs:{"type":"button","data-cy":_vm.dataCyPrefixed},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.withLeading)?_c('IconSVG',{staticClass:"leading",attrs:{"svg":_vm.iconLeading,"svg-path":_vm.iconLeadingPath,"size":24}}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.withAction)?_c('IconSVG',{staticClass:"action",attrs:{"svg":_vm.iconAction,"svg-path":_vm.iconActionPath,"size":20}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }