import { COOKIE_NAMES, URLS } from 'src/constants';
import { getEnvVars } from 'src/config';
import { getAppVersionParamsFlattened } from 'src/utils/app-version';
import { cookieStore } from 'src/utils/storage';

interface TSentStatParams {
  ChannelId: string | null;
  PlaybackTime: number;
  EventDuration: number;
}

interface TSentStatCleanParams extends TSentStatParams {
  ChannelId: string;
}

/**
 * navigator.sendBeacon is the most reliable way to send data at the end of the session
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#sending_analytics_at_the_end_of_a_session
 */
export const sendStats = (rawData: TSentStatParams) => {
  if (!rawData.ChannelId) {
    return;
  }

  const data = rawData as TSentStatCleanParams;

  const params = getAppVersionParamsFlattened();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const feCookie = cookieStore.get(COOKIE_NAMES.feCookie) as null | Record<string, any>;
  params.session = feCookie?.session || undefined;
  const urlParams = new URLSearchParams(Object.entries(params));
  const url = new URL(`${URLS.statistics}?${urlParams}`, getEnvVars().feBaseUrl);

  const headers = {
    type: 'application/x-www-form-urlencoded',
  };
  const blob = new Blob([new URLSearchParams(Object.entries(data)).toString()], headers);

  navigator.sendBeacon(url, blob);
};
