<template>
  <router-link
    v-if="to"
    class="button action"
    :class="[size, theme, { 'with-text': !!text }]"
    :to="to"
    :disabled="disabled"
    :data-cy="dataCyPrefixed"
  >
    <IconSVG :svg="icon" />
    <span v-if="text" class="text" v-html="text" />
  </router-link>

  <button
    v-else
    type="button"
    class="button action"
    :class="[size, themeForced || $store.theme, { 'with-text': !!text }]"
    :disabled="disabled"
    :data-cy="dataCyPrefixed"
    @click="$emit('click', $event)"
    @focus="$emit('focus', $event)"
  >
    <IconSVG :svg="icon" />
    <span v-if="text" class="text" v-html="text" />
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import IconSVG from 'src/components/IconSVG.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    IconSVG,
  },
})
export default class ButtonAction extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  to?: string;

  @Prop({ required: true })
  icon!: Record<string, unknown>;

  @Prop()
  text?: string;

  // small - 40, medium - 48
  @Prop({ default: 'small' })
  size!: 'small' | 'medium';

  @Prop()
  disabled?: boolean;

  @Prop()
  dataCy?: string;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get dataCyPrefixed() {
    return this.dataCy ? `button-action-${this.dataCy}` : '';
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/overlays';

.button.action {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  text-transform: none;
  background-color: var(--alpha-light-1);
  transition: all var(--ease-out) 0.15s;

  @include mobile-and-tablet {
    width: 48px;
    height: 48px;
  }

  &.medium {
    width: 48px;
    height: 48px;
  }

  &.small {
    width: 40px;
    height: 40px;
  }

  &.with-text {
    justify-content: flex-start;
    width: auto;
    padding: 0 16px;
  }

  .text {
    margin-left: 8px;
  }

  &::v-deep .icon {
    width: 24px;
    height: 24px;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    &::v-deep .icon {
      color: var(--alpha-light-7);
    }

    &:disabled {
      background-color: var(--alpha-light-1);

      &::v-deep .icon {
        color: var(--alpha-light-6);
      }
    }

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-light-4);

        &::v-deep .icon {
          color: var(--alpha-light-8);
        }
      }
    }

    &.active,
    &:active {
      background-color: var(--alpha-light-5);

      &::v-deep .icon {
        color: var(--alpha-light-9);
      }
    }
  }

  &.dark {
    background-color: var(--alpha-dark-1);

    &::v-deep .icon {
      color: var(--alpha-dark-7);
    }

    &:disabled {
      background-color: var(--alpha-dark-1);

      &::v-deep .icon {
        color: var(--alpha-dark-6);
      }
    }

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-4);

        &::v-deep .icon {
          color: var(--alpha-dark-8);
        }
      }
    }

    &.active,
    &:active {
      background-color: var(--alpha-dark-5);

      &::v-deep .icon {
        color: var(--alpha-dark-9);
      }
    }
  }
}
</style>
