import { TCommon } from 'src/store/common/types';

const initialState: TCommon = {
  assetTokens: { audienceToken: undefined, tvAssetToken: undefined },
  currentChannelCollectionSlug: '',
  isOffline: false, // if user lost internet connection & went offline
  isModalOpen: false,
  isModalWithAppsOpen: false,
  howManyTimesBodyScrollWasLocked: 0,
  showFooter: false,
  showNotificationWithDetails: false,
  showNotificationAuthAndReg: false,
  showNotificationSubscriptionPaymentError: false,
  showNotificationRetry: false,
  showNotificationReview: false,
  showProfileConfirmationOverlay: false,
  showAgeConfirmationOverlay: false,
  showSmartAppBanner: false,
  showWelcomeScreen: true,
  showJwtStepOnWelcomeScreen: false,
  localTime: 0,
  needServerTimeSync: false,
  isProfileLoading: false, // TODO move to profile module
  isProfileLoaded: false, // TODO move to profile module
  isHttps: true,
};

export default initialState;
