import { CHANNEL_LIST_POSTER_HEIGHT } from 'src/constants';
import { imageServerUrl } from 'src/utils/images';
import { TChannelEnhanced } from 'src/api/channels/types';
import { parseMd } from 'src/utils/markdown';

// channel data
export const getChannelTitle = (channel?: TChannelEnhanced) => {
  if (channel?.keyNumber) {
    return channel.title;
  } else {
    const title = channel?.info?.metaInfo?.title;
    return title?.slice(title?.indexOf('_') + 1) || '';
  }
};

export const getChannelNumber = (channel?: TChannelEnhanced) =>
  (channel?.keyNumber || channel?.strNumber || '').toString();

export const getChannelDescription = (channel?: TChannelEnhanced) =>
  parseMd(channel?.description || channel?.info?.metaInfo?.description || '');

export const getChannelGenres = (channel?: TChannelEnhanced) =>
  channel?.relevantGenres || channel?.info?.metaInfo?.genres;

export const getChannelGenresMapped = (channel?: TChannelEnhanced) =>
  channel?.relevantGenres?.map((genre) => genre.genreId) || channel?.info?.metaInfo?.genres || [];

export const getChannelIsLocked = (channel?: TChannelEnhanced) => {
  if (channel?.keyNumber) {
    return channel?.medias?.find((media) => media.isLocked);
  } else {
    return !channel?.info?.purchaseInfo?.bought;
  }
};

export const getChannelLogo = (channel?: TChannelEnhanced) =>
  channel?.logoUrl || channel?.info?.mediaInfo?.thumbnails?.[0]?.url || '';

export const getChannelLogoResized = (
  channel: TChannelEnhanced,
  height = CHANNEL_LIST_POSTER_HEIGHT,
  width?: number
) => {
  const url = getChannelLogo(channel);

  if (!url) {
    return '';
  }

  if (!width) {
    width = Math.round((height * 16) / 9);
  }

  return imageServerUrl(url, width, height);
};
export const processChannel = (channel: TChannelEnhanced) => {
  return {
    genres: getChannelGenresMapped(channel),
    image: getChannelLogoResized(channel, 140, 248),
    name: getChannelTitle(channel),
  };
};

// ----------------------------------------------------------------------------
// DVR
// ----------------------------------------------------------------------------

export const isDvrDepthEqualZero = (channel: TChannelEnhanced) =>
  channel?.info?.playbackInfo?.details?.dvrDepth === 0;

export const getChannelDvrRestrictionMessage = (channel: TChannelEnhanced) =>
  channel?.info?.playbackInfo?.dvrAvailability?.msg || '';
