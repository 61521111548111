<template>
  <div class="fe-settings">
    <div class="menu">
      <div class="data">
        <div class="menu-item pointer-events-none">
          <div class="current" v-text="fe.url" />
        </div>
        <div class="menu-item pointer-events-none">
          <div
            v-if="$store.account && $store.account.user"
            class="login"
            v-text="$store.account.user.login"
          />
        </div>
      </div>

      <div class="menu-item" @click="openSettings">Настройки</div>
      <div v-if="!isAnonymous" class="menu-item" @click="$emit('logOut')">Выйти</div>
    </div>

    <ModalSequoia v-if="settingsVisible" type="fixed" size="medium" @close="closeSettings">
      <div class="settings with-side-and-bottom-padding">
        <div class="h3">Настройки</div>
        <div class="h5">Front-end</div>
        <div class="form-container">
          <div class="form-row">
            <DropdownSequoia
              v-if="feUrlsOptions.length"
              v-slot="{ value, opened, toggle }"
              :list="feUrlsOptions"
              :placeholder="getTranslation('profile_type')"
              class="select-restrictions"
              @select="onSelectFeUrl"
            >
              <ButtonDropdown
                width="auto"
                placeholder="FE Server"
                :open="opened"
                :value="value"
                @click="toggle"
              />
            </DropdownSequoia>
          </div>

          <div class="form-row">
            <InputText
              ref="url"
              v-model="fe.url"
              input-category="floating"
              label="URL"
              size="medium"
              :status="fe.error ? 'error' : undefined"
              :message="fe.error"
            />
          </div>
        </div>

        <div class="buttons-wrapper">
          <ButtonDefault
            :is-loading="fe.loading"
            :disabled="fe.url === feInitialUrl || !isFeUrlValid"
            @click="save"
          >
            Сохранить
          </ButtonDefault>
        </div>

        <div v-if="feInitialUrl !== fe.defaultUrl">
          <div class="h5 reset">Сброс</div>
          <div class="description">Возврат к {{ fe.defaultUrl }}</div>

          <div class="buttons-wrapper">
            <ButtonDefault view="danger" @click="reset"> Сбросить </ButtonDefault>
          </div>
        </div>

        <div v-if="$store.account && $store.account.user" class="user">
          <div class="h5">Пользователь</div>
          <div class="status" :class="isAnonymous ? 'bg-error' : 'bg-success'">
            <span v-if="isAnonymous">Не авторизован</span>
            <span v-else>Авторизован</span>
          </div>
        </div>

        <pre v-if="$store.account" class="pre" v-text="$store.account.user" />

        <div class="h5">Сменить пользователя</div>

        <div class="form-container">
          <div class="form-row">
            <InputText
              v-model="auth.login"
              input-category="floating"
              size="medium"
              :label="getTranslation('login')"
            />
          </div>
          <div class="form-row">
            <InputText
              v-model="auth.password"
              input-category="floating"
              size="medium"
              :label="getTranslation('password')"
              @keyup.enter="signIn"
            />
          </div>
        </div>

        <div class="buttons-wrapper">
          <ButtonDefault :disabled="!(auth.login && auth.password)" @click="signIn">
            Войти
          </ButtonDefault>
          <div class="color-error" v-text="auth.error" />
        </div>
      </div>
    </ModalSequoia>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import axios from 'axios';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { isOkUrl } from 'src/utils/url';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import { cookieStore } from 'src/utils/storage';
import { getEnvVars } from 'src/config';
import logger from 'src/utils/logger';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';

const log = logger('fe-settings');

@Component({
  components: {
    InputText,
    ModalSequoia,
    ButtonDefault,
    DropdownSequoia,
    ButtonDropdown,
  },
})
export default class FeSettings extends SequoiaComponent {
  auth = {
    login: '',
    password: '',
    error: '',
  };

  fe = {
    url: '',
    defaultUrl: '',
    error: '',
    loading: false,
  };

  settingsVisible = false;

  get feInitialUrl() {
    return getEnvVars().feBaseUrl;
  }

  get isFeUrlValid() {
    return this.fe.url && isOkUrl(this.fe.url);
  }

  get servers() {
    return this.$store.siteConfig?.santa?.servers || '';
  }

  get feUrls(): Array<string> {
    return this.servers
      .split('\n')
      .filter((url: string) => url)
      .map((url: string) => url.replace(/\s/g, ''))
      .map((url: string) => (!url.match(/^http(s)?/) ? 'https://' + url : url));
  }

  get feUrlsOptions(): TDropdownItem[] {
    return this.feUrls
      .filter((url) => url && url.length)
      .map((url, i) => ({ key: i.toString(), id: url, value: url, selected: this.fe.url === url }));
  }

  created() {
    this.fe.defaultUrl = this.feUrls[0];
    this.fe.url = getEnvVars().feBaseUrl;
  }

  openSettings() {
    this.settingsVisible = true;
  }

  closeSettings() {
    this.settingsVisible = false;
  }

  reset() {
    cookieStore.remove('feBaseUrl');
    this.reload();
  }

  reload() {
    this.closeSettings();
    location.reload();
  }

  onSelectFeUrl(item: TDropdownItem) {
    this.fe.url = item.value;
  }

  async save() {
    try {
      this.fe.error = '';
      this.fe.loading = true;
      await this.checkFeUrl();
      cookieStore.set('feBaseUrl', this.fe.url);
      this.reload();
    } catch (err) {
      this.fe.error = err.message;
      log.error(err);
    } finally {
      this.fe.loading = false;
    }
  }

  async signIn() {
    try {
      await actions.authAndReg.login(this.$store, this.auth.login, this.auth.password);
      this.reload();
    } catch (err) {
      this.auth.error = err.message;
      log.error(err);
    }
  }

  async checkFeUrl() {
    const response = await axios.get(this.fe.url);
    if (!(response && response.data && response.data.version > 0)) {
      throw new Error(`invalid response  ${response.data}`);
    }
  }
}
</script>

<style lang="scss" scoped>
.fe-settings {
  height: 56px;

  // TODO: remove after refactoring dropdowns
  &::v-deep .modal {
    height: 100vh;

    .dialog-wrapper {
      height: 1124px;
    }
  }

  .select-restrictions {
    width: 100%;
  }

  .menu {
    position: fixed;
    z-index: var(--z-dropdown);
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 56px;
    color: var(--c-text-on-brand-primary);
    background-color: var(--c-light-brand);

    .menu-item {
      display: inline-flex;
      align-items: center;
      height: 100%;
      padding: 0 16px;
      cursor: pointer;

      &:hover {
        background-color: var(--c-light-brand-lighter);
      }
    }

    .data {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .settings {
    margin: 56px 48px;

    .h5 {
      margin-top: 32px;
      margin-bottom: 16px;

      &.reset {
        margin-bottom: 4px;
      }
    }

    .description {
      margin-bottom: 16px;
      font-size: 0.875rem;
      color: var(--c-overlay-lvl-60);
    }

    .user {
      > div {
        display: inline-block;
      }

      .status {
        padding: 4px 8px;
        margin-left: 16px;
        color: var(--c-dark-font-primary);
        border-radius: 2px;
      }
    }

    .pre {
      padding: 8px 16px;
      background-color: var(--c-light-200);
    }

    .color-error {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      margin-left: 20px;
      font-size: 0.875rem;
    }

    .buttons-wrapper {
      flex-wrap: nowrap;
    }
  }
}
</style>
