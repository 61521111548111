<template>
  <div
    class="image-text-button-container"
    :class="[componentName, { ['reverse-flex']: content && content.imagePosition === 'left' }]"
    :data-ctype="$options.name"
    :data-cname="componentName"
  >
    <div v-if="contentBlock" class="itb-text-and-button">
      <div class="itb-text" v-html="contentBlock" />
      <ButtonDefault v-if="showButton" size="medium" :href="link">
        <span v-html="buttonTranslation" />
      </ButtonDefault>
    </div>

    <div class="itb-image">
      <img :src="imageSrc" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

@Component({
  components: { ButtonDefault },
})
export default class ImageTextButton extends mixins(Global, CmsComponent) {
  content?: {
    contentBlock: TTranslations;
    button?: {
      show: boolean;
      link: string;
      translation: TTranslations;
    };
    image: TTranslations<TImageCMS>;
    imagePosition?: string;
  } | null = null;

  get contentBlock() {
    return this.getByLanguage(this.content?.contentBlock);
  }

  get showButton() {
    return this.content?.button?.show;
  }

  get link() {
    return this.content?.button?.link || '';
  }

  get buttonTranslation() {
    return this.getByLanguage(this.content?.button?.translation);
  }

  get imageSrc() {
    return this.getByLanguage(this.content?.image)?.url || '';
  }
}
</script>

<style lang="scss" scoped>
.image-text-button-container {
  display: flex;
  justify-content: center;
  padding: 32px 0;

  &.reverse-flex {
    flex-direction: row-reverse;
  }

  .itb-text-and-button {
    padding: 0 32px;

    .itb-text {
      padding-bottom: 32px;
    }
  }

  .itb-image {
    padding: 0 32px;
  }
}
</style>
