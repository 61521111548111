import { getDeviceFlags } from 'src/utils/platform-detector';

const clickOutsideBind = (el: any, binding: any, vnode: any) => {
  el.eventSetDrag = function () {
    el.setAttribute('data-dragging', 'yes');
  };
  el.eventClearDrag = function () {
    el.removeAttribute('data-dragging');
  };
  el.eventOnClick = function (event: Event) {
    if (!getDeviceFlags().isMobile) {
      const dragging = el.getAttribute('data-dragging');
      // Check if the click was outside the el and its children,
      // and that it wasn't a drag
      if (!(el === event.target || el.contains(event.target)) && !dragging) {
        // call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    }
  };
  el.eventOnTouchEnd = function (event: Event) {
    el.eventOnClick(event);
    el.eventClearDrag();
  };

  document.addEventListener('click', el.eventOnClick);
  document.addEventListener('touchstart', el.eventSetDrag);
  document.addEventListener('touchmove', el.eventSetDrag);
  document.addEventListener('touchend', el.eventOnTouchEnd);
};

const clickOutsideUnbind = (el: any) => {
  document.removeEventListener('click', el.eventOnClick);
  document.removeEventListener('touchstart', el.eventSetDrag);
  document.removeEventListener('touchmove', el.eventSetDrag);
  document.removeEventListener('touchend', el.eventOnTouchEnd);
  el.removeAttribute('data-dragging');
};

// TODO remove after switching to vue3
export const clickOutside = {
  bind: clickOutsideBind,
  unbind: clickOutsideUnbind,
};

export const vClickOutSide = {
  mounted: clickOutsideBind,
  unmounted: clickOutsideUnbind,
};

const offAutocompleteBind = (el: any) => {
  el.setAttribute('readonly', 'true');
  el.onfocus = function () {
    this.removeAttribute('readonly');
  };
};

// TODO remove after switching to vue3
export const offAutocomplete = {
  bind: offAutocompleteBind,
};

export const vOffAutocomplete = {
  mounted: offAutocompleteBind,
};
