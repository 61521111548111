<template>
  <div class="fallback-container with-side-and-bottom-padding dark">
    <QuickSubscribeBackground />
    <h2 class="mb-24" v-html="title" />
    <div class="description mb-32" v-html="getTranslation('kt_description_azimut_subscription')" />
    <ButtonDefault @click="onClick">
      <span v-html="getTranslation('kt_cta_azimut_subscription')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import Component from 'vue-class-component';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { convertToMilliseconds } from 'src/utils/time/convert-time';

@Component({
  components: {
    QuickSubscribeBackground,
    ButtonDefault,
  },
})
export default class FallbackQS extends SequoiaComponent {
  timeout: NodeJS.Timeout | undefined;

  get serverTimeMs() {
    return selectors.appInfo.serverTimeMsSelector(this.$store);
  }

  get sub() {
    return selectors.QS.prioritySubscriptionSelector(this.$store);
  }

  get title() {
    const store = this.$store;

    return this.getTranslation('kt_title_azimut_subscription').replace(
      '%channelName%',
      (store.QS.sourceType === 'vod' ? store.QS.vod?.name : store.QS.channel?.name) ||
        '%channelName%'
    );
  }

  mounted() {
    this.timeout = setTimeout(() => {
      this.hideModal();
    }, convertToMilliseconds(this.sub?.renderingData['buy-on-website-expire'], 'second') - this.serverTimeMs);
  }

  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onClick() {
    window.open(this.sub?.renderingData['buy-on-website-url'], '_blank');
  }

  hideModal() {
    actions.common.hideModal(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.fallback-container {
  @include mobile-and-tablet {
    width: 100%;
  }

  h2,
  .description {
    position: relative;
  }
}
</style>
