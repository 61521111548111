<template>
  <ButtonCircle
    :theme="theme"
    :direction="direction"
    :icon-type="iconType"
    :data-cy="dataCy"
    @click="$emit('click')"
  />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ButtonCircle from 'src/components/ui/buttons/ButtonCircle.vue';

@Component({
  components: {
    ButtonCircle,
  },
})
export default class ButtonCircleConnected extends SequoiaComponent {
  @Prop()
  direction!: string;

  @Prop()
  dataCy?: string;

  @Prop({ default: 'angle' })
  iconType!: 'angle' | 'arrow';
}
</script>
