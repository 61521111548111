import { TAdultOnboarding } from 'src/store/adult-onboarding/types';

const initialState: TAdultOnboarding = {
  currentStep: '',
  nextStep: '',
  notificationMessage: '',
  notificationTitle: '',
  pinAction: '',
  showNotification: false,
  withButton: false,
};

export default initialState;
