import Vue from 'vue';
import * as api from 'src/api';
import { TStore } from 'src/store/types';
import { TReqConfig } from 'src/api/utils';
import logger from 'src/utils/logger';
import { removeAssetTokens, saveAssetTokens } from 'src/store/common/actions';
import { loadAssetTokens } from 'src/api/tokens';
import { TProfile } from 'src/api/auth/types';

const log = logger('profile');

export const getAccount = async (store: TStore, config?: TReqConfig) => {
  const account = await api.auth.getAccount(config);
  if (account) {
    Vue.set(store, 'account', account);
  }
};

export const change = async (store: TStore, config?: TReqConfig) => {
  setIsProfileLoaded(store, false);
  setIsProfileLoading(store, true);
  try {
    await removeAssetTokens(store);
    await api.profile.change(config);
    await saveAssetTokens(store, await loadAssetTokens());
    setIsProfileLoading(store, false);
    setIsProfileLoaded(store, true);
  } catch (err) {
    setIsProfileLoading(store, false);
    throw new Error(err?.message);
  }
};

export const update = async (store: TStore, id: string, config?: TReqConfig) => {
  setIsProfileLoaded(store, false);
  setIsProfileLoading(store, true);
  if (
    id === store.account?.profile?.id &&
    store.account?.profile?.restriction !== config?.data['Restriction.Id']
  ) {
    await removeAssetTokens(store);
    await api.profile.update(id, config);
    await saveAssetTokens(store, await loadAssetTokens());
  } else {
    await api.profile.update(id, config);
  }
  setIsProfileLoading(store, false);
  setIsProfileLoaded(store, true);
};

export const getProfileRestrictions = async (store: TStore) => {
  const { restrictions } = await api.profile.getProfileRestrictions();
  Vue.set(store, 'profileRestrictions', restrictions);
};

export const setIsProfileLoading = (store: TStore, isProfileLoading: boolean) => {
  log.info('isProfileLoading', isProfileLoading);
  store.common.isProfileLoading = isProfileLoading;
};

export const setIsProfileLoaded = (store: TStore, isProfileLoaded: boolean) => {
  log.info('isProfileLoaded', isProfileLoaded);
  store.common.isProfileLoaded = isProfileLoaded;
};

export const changeProfileWithoutPin = async (store: TStore, profile: TProfile) => {
  await change(store, {
    data: {
      Id: profile.id,
      Pin: '',
    },
  });
};
