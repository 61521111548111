import { TStore } from 'src/store/types';
import { getByLanguage } from 'src/utils/language';
import { languageCodeSelector } from 'src/store/common/selectors';

export const translationSelector = (store: TStore, translationId: string): string => {
  if (!translationId) {
    return '';
  }

  const translation = store.translations[translationId];
  const translationIdString = `{${translationId}}`;

  if (!translation) {
    return translationIdString;
  }

  return getByLanguage(translation, languageCodeSelector(store)) || translationIdString;
};
