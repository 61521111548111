import { TStore } from 'src/store/types';
import * as api from 'src/api';
import { TBanner, TPromoElement } from 'src/api/home/types';
import sortBy from 'lodash/sortBy';
import { listOfAllSourceIdsSelector } from 'src/store/vod/selectors';
import { addTitlesToCollectionFromSource } from 'src/store/vod/actions';
import logger from 'src/utils/logger';
import { audienceTokenValueSelector } from 'src/store/common/selectors';
import {
  isBannersDataLoadedSelector,
  isBannersDataLoadingSelector,
} from 'src/store/home/selectors';
import { saveAssetTokens } from 'src/store/common/actions';
import { loadAssetTokens } from 'src/api/tokens';
import { wlDetector } from 'src/utils';

const log = logger('home-actions');

/**
 * Deprecated
 * Filter banners which do not have proper rendering or action methods
 */
const filterBanners = (store: TStore, banners: TBanner[]) => {
  // check rendering methods
  let filteredBanners = banners.filter(
    (banner) =>
      !!banner.renderingMethods.find(
        (renderingMethod: { method: string }) => renderingMethod.method === 'plain-image', // support only plain-image for now
      ),
  );

  // check action methods
  filteredBanners = filteredBanners.map((banner) => {
    if (!banner.internalReference?.screenId?.startsWith('vod-')) {
      // skip if banner doesn't have any reference to vod page
      return banner;
    }
    // filter internal-ref actions which do not include available VOD source
    const filteredActionMethods = banner.actionMethods.filter(
      (actionMethod: { method: string }) => {
        return actionMethod.method === 'internal-ref' && banner.internalReference?.vodContent
          ? listOfAllSourceIdsSelector(store).includes(banner.internalReference.vodContent.source)
          : true;
      },
    );
    return { ...banner, actionMethods: filteredActionMethods };
  });

  return filteredBanners.filter((banner) => banner.actionMethods.length);
};

/**
 * Filter promo-elements which do not have proper rendering or action methods
 */
const filterPromoElements = (store: TStore, promoElements: TPromoElement[]) => {
  // check rendering methods
  let filtered = promoElements.filter(
    (one) =>
      !!one.renderingMethods.find(
        (renderingMethod: { method: string }) => renderingMethod.method === 'plain-image', // support only plain-image for now
      ),
  );

  // check action methods
  filtered = filtered.map((one) => {
    if (!one.internalReference?.screenId?.startsWith('vod-')) {
      // skip if banner doesn't have any reference to vod page
      return one;
    }
    // filter internal-ref actions which do not include available VOD source
    const filteredActionMethods = one.actionMethods.filter((actionMethod: { method: string }) => {
      const vodContent = one.internalReference?.vodContent;
      return actionMethod.method === 'internal-ref' && vodContent
        ? listOfAllSourceIdsSelector(store).includes(vodContent.source)
        : true;
    });
    return { ...one, actionMethods: filteredActionMethods };
  });

  // check placement blocks
  filtered = filtered.filter((one) =>
    one.placementBlocks.find(
      (placementBlock) =>
        placementBlock.blockId === 'home-carousel' || placementBlock.blockId === 'home',
    ),
  );

  return filtered.filter((one) => one.actionMethods.length);
};

const fetchBannersInternalLinks = async (store: TStore, banners: TBanner[] | TPromoElement[]) => {
  const promises: Array<any> = [];
  for (const banner of banners) {
    // const sourceId = banner.internalReference.vodContent?.source;
    let promise = new Promise((resolve) => resolve(''));

    if (!banner.actionMethods) {
      promises.push(promise);
      continue;
    }
    const action = sortBy(banner.actionMethods, 'priority')[0];

    if (action?.method === 'internal-ref') {
      switch (banner.internalReference?.screenId) {
        case 'vod-content-wall':
          const s = banner.internalReference.vodContent?.source;
          promise = new Promise((resolve) => resolve(s === 'archive' ? '/archive' : `/vod/${s}`));
          break;
        case 'vod-collection-page':
          const sourceId = banner.internalReference.vodContent?.source;
          const vodCollectionId = banner.internalReference.vodContent?.collectionId.trim();

          if (vodCollectionId && sourceId) {
            try {
              const titles = (await api.vod.getCollectionTitles(sourceId, vodCollectionId, true))
                ?.titles;

              if (titles?.length && sourceId) {
                addTitlesToCollectionFromSource(store, vodCollectionId, sourceId, titles, '');
                promise = new Promise((resolve) =>
                  resolve(`/collection/${sourceId}/${vodCollectionId}`),
                );
              }
            } catch (err) {
              log.error(err);
            }
          }
          break;
        case 'vod-title-page':
        case 'vod-start-playback':
          if (banner.internalReference.vodContent) {
            const { source, contentId } = banner.internalReference.vodContent; // get direct url to detailed title page in archive
            if (source === 'archive') {
              promise = new Promise((resolve) => resolve(`/archive/${contentId}`));
              break;
            }
            promise = new Promise((resolve) => resolve(`/vod/${source}/${contentId}`));
          }
          break;
        case 'tv-content-wall':
        case 'tv-start-playback':
          if (banner.internalReference.tvContent) {
            const { channelId } = banner.internalReference.tvContent;
            promise = new Promise((resolve) => resolve(`/channel/${channelId}/about`));
          }
          break;
      }
    }

    promises.push(promise);
  }
  const links = promises.length ? await Promise.all(promises) : [];
  return banners.map((banner: any, index: any) => ({
    ...banner,
    internalUrl: links[index],
  }));
};

const prepareImages = (elements: TPromoElement[]) => {
  return elements.map((one) => {
    const result = { ...one };
    one.images.forEach((image) => {
      switch (image.imageType) {
        case 'plain-16x9':
          result.plainImage16x9 = image.imageURL;
          break;
        case 'plain-16x4':
          result.plainImage16x4 = image.imageURL;
          break;
      }
    });
    return result;
  });
};

export const loadBanners = async (store: TStore) => {
  if (isBannersDataLoadingSelector(store)) {
    log.info('Banners are already loading...');
    return;
  }
  if (isBannersDataLoadedSelector(store)) {
    log.info('Banners have already been loaded');
    return;
  }
  store.home.isBannersDataLoading = true;
  if (wlDetector().isSmotreshka) {
    // promo-elements are available only for smotreshka for now...
    await loadPromoElements(store);
  } else {
    await loadBannersLegacy(store);
  }
  store.home.isBannersDataLoading = false;
};

/**
 * Deprecated
 * @see https://lifestream.atlassian.net/browse/SEQ-2058
 */
export const loadBannersLegacy = async (store: TStore) => {
  const { banners } = await api.home.banners();
  const bannersLength = banners?.length;

  if (!bannersLength) {
    log.info(`Banners loaded: 0`);
    store.home.isBannersDataLoaded = true;
    return;
  }

  const bannersFiltered = filterBanners(store, banners);
  store.home.banners = await fetchBannersInternalLinks(store, bannersFiltered);
  log.info(`Banners loaded:`, bannersLength, 'After filter:', bannersFiltered.length);
  store.home.isBannersDataLoaded = true;
};

/**
 * Banners with placement blocks
 * @see https://lifestream.atlassian.net/browse/SEQ-2058
 */
export const loadPromoElements = async (store: TStore) => {
  if (!audienceTokenValueSelector(store)) {
    await saveAssetTokens(store, await loadAssetTokens());
  }
  const audienceToken = audienceTokenValueSelector(store);
  const { elements } = await api.home.promoElements(audienceToken);
  const elementsLength = elements?.length;
  if (!elementsLength) {
    log.info(`Banners loaded: 0`);
    store.home.isBannersDataLoaded = true;
    return;
  }
  let elementsFiltered = filterPromoElements(store, elements);
  elementsFiltered = prepareImages(elementsFiltered);
  store.home.banners = await fetchBannersInternalLinks(store, elementsFiltered);
  store.home.isBannersDataLoaded = true;
  log.info(`Banners loaded:`, elementsLength, 'After filter:', elementsFiltered.length);
};
