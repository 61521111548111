<template>
  <div v-click-outside="clickOutsideMenu" class="menu-filters">
    <slot :value="selectedValue || ''" :opened="isMenuOpened" :disabled="disabled" :toggle="toggle">
      <ButtonDropdown
        :floating="true"
        :placeholder="placeholder"
        :value="selectedValue || ''"
        :open="isMenuOpened"
        :disabled="disabled"
        :theme-forced="themeForced"
        :icon="IconFilter"
        data-cy="categories"
        @click="toggle"
      />
    </slot>

    <transition
      v-if="isMenuOpened"
      name="dropdown-menu-filters"
      appear
      @before-enter="beforeOpenMenu"
    >
      <div class="dropdown-menu-filters flex row" :class="[theme]">
        <VodMenuFiltersColumn
          :list="categoryList"
          class="col-desktop-4 col-tablet-8 category-list"
          :title="getTranslation('filters_category')"
          :theme-forced="theme"
          dataCy="categories"
          @select="selectCategory"
        />

        <VodMenuFiltersColumn
          v-if="showGenreList"
          :list="genreList"
          class="col-desktop-8 col-tablet-8"
          :title="getTranslation('filters_genres')"
          :show-all-button-text="getTranslation('filters_show_all_genres_btn_text')"
          :theme-forced="theme"
          :count-default-items="countDefaultItems"
          dataCy="genres"
          @select="selectGenre"
        />

        <div class="dropdown-menu-footer">
          <ButtonDefault :with-icon="true" view="primary" type="button" @click="applyFilter">
            <IconSVG :svg="IconSubmit" />
            <span v-html="getTranslation('filters_apply_btn')" />
          </ButtonDefault>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconSubmit from 'src/svg/submit.svg';
import IconFilter from 'src/svg/filter.svg';
import ChipSelector from 'src/components/ui/ChipSelector.vue';
import { clickOutside } from 'src/utils/directives';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import VodMenuFiltersColumn from 'src/components/vod/vod-menu-filters/VodMenuFiltersColumn.vue';
import { VODGenre } from 'src/models/ts/vod/v2/vod';

const COUNT_ITEMS_WITHOUT_CUT_DESKTOP = 30;
const COUNT_ITEMS_WITHOUT_CUT_TABLET = 20;
const COUNT_ITEMS_WITHOUT_CUT_MOBILE = 15;

@Component({
  components: { ButtonDropdown, ButtonDefault, IconSVG, ChipSelector, VodMenuFiltersColumn },
  directives: { clickOutside },
})
export default class VodMenuFilters extends SequoiaComponent {
  IconSubmit = IconSubmit;
  IconFilter = IconFilter;
  isMenuOpened = false;
  categoryList: TDropdownItem[] = [];
  genreList: TDropdownItem[] = [];
  checkChangeFilter = false;

  previousCategory: string | undefined = undefined;
  previousGenre: string | undefined = undefined;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  categories!: TDropdownItem[];

  @Prop()
  genres!: TDropdownItem[];

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: '' })
  placeholder?: string;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get countDefaultItems() {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1280) {
      return COUNT_ITEMS_WITHOUT_CUT_DESKTOP;
    } else if (windowWidth <= 1280 && windowWidth >= 960) {
      return COUNT_ITEMS_WITHOUT_CUT_TABLET;
    }

    return COUNT_ITEMS_WITHOUT_CUT_MOBILE;
  }

  get selectedCategory() {
    return this.categoryList.find((category) => category.selected);
  }

  get selectedGenre() {
    return this.genreList.find((genre) => genre.selected);
  }

  get shouldNotTriggerUpdate() {
    return (
      !this.checkChangeFilter &&
      this.selectedCategory?.id === this.previousCategory &&
      this.selectedGenre?.id === this.previousGenre
    );
  }

  get selectedValue() {
    const val = [this.selectedCategory?.value];

    if (this.showGenreList) {
      val.push(this.selectedGenre?.value);
    }

    return val.join(', ');
  }

  get showGenreList() {
    return this.genreList.length > 1;
  }

  created() {
    this.resetFilter();
  }

  beforeOpenMenu() {
    this.resetFilter();
    const selectedIndex = this.genreList.findIndex((g) => g.selected);
    const selectedGenre = this.genreList[selectedIndex];

    if (selectedGenre?.id !== '-1' && selectedIndex > this.countDefaultItems) {
      const genres = this.sortGenreList(this.genreList, 'value');
      genres.splice(selectedIndex, 1);
      genres.splice(1, 0, selectedGenre);
      this.genreList = genres;
    }
  }

  applyFilter() {
    if (!this.shouldNotTriggerUpdate) {
      this.previousCategory = this.selectedCategory?.id;
      this.previousGenre = this.selectedGenre?.id;

      this.$emit('select', { category: this.selectedCategory, genre: this.selectedGenre });

      this.checkChangeFilter = false;
    }

    if (this.isMenuOpened) {
      this.isMenuOpened = false;
    }
  }

  resetFilter() {
    this.categoryList = this.categories;
    const categoryIndex = this.categoryList.findIndex((category) => category.selected);

    if (categoryIndex >= 0) {
      this.genreList = this.generateGenresList(this.categoryList[categoryIndex].genres || []);
      this.previousCategory = this.selectedCategory?.id;
      this.previousGenre = this.selectedGenre?.id;
    }
  }

  toggle() {
    if (this.isMenuOpened) {
      this.resetFilter();
    }
    this.isMenuOpened = !this.isMenuOpened;
  }

  clickOutsideMenu() {
    if (this.isMenuOpened) {
      this.isMenuOpened = false;
      this.resetFilter();
    }
  }

  selectCategory(categoryItem: TDropdownItem) {
    if (!categoryItem) {
      return;
    }

    const { list, selectIndex } = this.updateList(categoryItem, this.categoryList);
    this.categoryList = list;
    this.genreList = this.generateGenresList(this.categoryList[selectIndex].genres || []);
    this.checkChangeFilter = categoryItem.id !== this.previousCategory;
  }

  selectGenre(genreItem: TDropdownItem) {
    if (!genreItem) {
      return;
    }

    const { list } = this.updateList(genreItem, this.genreList);
    this.genreList = list;
    this.checkChangeFilter = genreItem.id !== this.previousGenre;
  }

  updateList(selectItem: TDropdownItem, list: TDropdownItem[]) {
    let selectIndex = 0;

    const resultList = list.map((item, i) => {
      if (String(item.key) === String(selectItem.key)) {
        selectIndex = i;
      }

      return {
        ...item,
        selected: String(item.key) === String(selectItem.key),
      };
    });

    return {
      list: resultList,
      selectIndex,
    };
  }

  generateGenresList(list: VODGenre[]): TDropdownItem[] {
    return this.sortGenreList(list).map(
      (genre: { title: string; id: string; selected?: boolean }, i: number) => {
        return {
          id: genre.id,
          key: genre.id,
          selected:
            this.selectedCategory === this.categories.find((c) => c.selected) &&
            this.genres.find((g) => g.selected)
              ? this.genres.find((g) => g.selected)?.id === genre?.id
              : i === 0,
          value: genre.title,
        };
      }
    );
  }

  sortGenreList(list: VODGenre[] | TDropdownItem[], field = 'title') {
    return Object.values(list).sort((a, b) => {
      if (a.id === '-1') {
        return -1;
      }
      if (b.id === '-1') {
        return 1;
      }
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }

      return 0;
    });
  }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/media-queries';

.menu-filters {
  display: inline-flex;
  margin: 8px 0;
}

.dropdown-menu-filters {
  position: absolute;
  top: 100%;
  z-index: var(--z-dropdown);
  width: calc(100% + 24px);
  padding: 16px 4px;
  overflow: hidden;
  background-color: var(--c-light-150);
  border-radius: 12px;
  box-shadow: 0 2px 4px var(--shadow-lvl-30);

  .category-list {
    flex: 1 1 auto;
  }

  @include tablet {
    .category-list {
      margin-bottom: 24px;
    }
  }

  .dropdown-menu-footer {
    position: relative;
    width: calc(100% - 24px);
    padding-top: 16px;
    margin: 15px auto 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: var(--alpha-light-3);
    }
  }

  &.dark {
    background-color: var(--c-dark-400);
    box-shadow: 0 2px 4px var(--shadow-lvl-50);

    .dropdown-menu-footer::before {
      background-color: var(--alpha-dark-3);
    }
  }
}

.dropdown-menu-filters-enter-active,
.dropdown-menu-filters-leave-active {
  transition: opacity 0.15s;
}

.dropdown-menu-filters-enter,
.dropdown-menu-filters-leave-to {
  opacity: 0;
  transform: translateY(-8px);
}
</style>
