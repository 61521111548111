<template>
  <div class="button-sign-out">
    <a class="link link-icon nav-icon-colored" data-cy="sign-out" @click.prevent="handleLogout()">
      <IconSVG :svg="IconSignOut" />
      <span class="text" v-html="getTranslation('account_exit')" />
    </a>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import IconSignOut from 'src/svg/sign-out.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { actions } from 'src/store/actions';
import logger from 'src/utils/logger';
import { makePath } from 'src/utils/url';

const log = logger('button-sign-out');

@Component({
  components: { IconSVG },
})
export default class ButtonSignOut extends SequoiaComponent {
  IconSignOut = IconSignOut;

  async handleLogout() {
    await actions.authAndReg.logout(this.$store);
    localStorage?.removeItem('tab');
    document.location.href = makePath('/');
  }
}
</script>

<style lang="scss" scoped>
.button-sign-out {
  display: flex;
}
</style>
