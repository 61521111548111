<template>
  <ContentSlider
    :type="type"
    :with-image-preload="true"
    :auto-rotation="autoRotation"
    :highlight-visible="highlightVisible"
    :theme-forced="theme"
    @ready="ready"
    @gaScroll="gaScroll"
  >
    <slot />
  </ContentSlider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ContentSlider from 'src/components/ui/ContentSlider.vue';

@Component({
  components: {
    ContentSlider,
  },
})
export default class HomeContentSlider extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  type!: 'fixed' | 'adaptive' | 'carousel' | 'legacy';

  @Prop({ default: 0 })
  autoRotation!: number;

  @Prop({ default: true })
  highlightVisible!: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  ready() {
    this.$emit('ready');
  }

  gaScroll(id: string | undefined) {
    this.$emit('gaScroll', id);
  }
}
</script>
