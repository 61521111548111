<template>
  <div v-if="content" class="accordion" :data-ctype="$options.name" :data-cname="componentName">
    <AccordionItem
      v-for="(item, i) in content.items"
      :key="i + uuid + JSON.stringify(item)"
      :item="item"
      :itemIndex="i"
      :title="titles[i]"
    >
      <span class="content" v-html="blocks[i]" />
    </AccordionItem>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import AccordionItem from 'src/components/ui/AccordionItem.vue';
import { v4 as uuidV4 } from 'uuid';

@Component({
  components: { AccordionItem },
})
export default class Accordion extends mixins(Global, CmsComponent) {
  uuid = uuidV4();

  // TODO rename AccordionSequoia
  content?: {
    items: Array<{
      title: { [language: string]: string };
      contentBlock: { [language: string]: string };
    }>;
  } | null = null;

  get titles() {
    return this.content?.items.map((item) => this.getByLanguage(item.title) || '') || [];
  }

  get blocks() {
    return this.content?.items.map((item) => this.getByLanguage(item.contentBlock));
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  .item + .item {
    border-top: 0;
  }

  .content {
    display: block;
    padding: 4px 0 20px 32px;
  }
}
</style>
