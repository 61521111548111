<template>
  <div class="offer-card" :class="{ 'not-priority': !priority }">
    <!-- TODO check if v-html works on <component> tag-->
    <component :is="priority ? 'h2' : 'h3'" v-html="titleTranslation" />

    <template v-if="withDifferentPeriods">
      <div class="different-periods flex">
        <div
          v-for="(s, i) in sub.differentPeriods"
          :key="s.offerId + i"
          class="period"
          :class="{ current: currentSub ? currentSub.offerId === s.offerId : false }"
          @click="changeSub(s)"
        >
          <div v-if="s.offerId !== sub.offerId" class="badge" v-text="getBadgePrice(s)" />
          <div class="duration" v-html="s.renderingData.offerDuration || '%offerDuration%'" />
          <div class="price">
            <span v-text="s.renderingData.priceValue || '%priceValue%'" />
            <span class="price-unit" v-text="s.renderingData.priceUnit || '%priceUnit%'" />
          </div>
          <template v-if="s.offerId !== sub.offerId">
            <div class="payment" v-html="getTranslation('payment')" />
            <div class="payment" v-html="s.renderingData.fullPrice || '%fullPrice%'" />
          </template>
        </div>
      </div>
    </template>

    <template v-else>
      <template v-if="sub.trialAvailable">
        <BadgeSequoia v-if="badgeTranslation" class="mb-4" :text="badgeTranslation" type="accent" />
        <h4 class="mb-4" v-html="trialPeriodTranslation" />
      </template>

      <div v-if="sub.trialAvailable" class="remark" v-html="priceAndPeriodWithTrialTranslation" />
      <div v-else>
        <span class="price bold" v-html="priceAndPeriodTranslation" />
      </div>

      <BadgeSequoia
        v-if="isWl && priority && badgeTranslation && !sub.trialAvailable"
        class="mb-24"
        :text="badgeTranslation"
      />
    </template>

    <div class="description" v-html="descriptionTranslation" />

    <template v-if="sub.renderingData.channels && sub.renderingData.channels.length">
      <div class="remark mb-8" v-html="composedOfTranslation" />
      <div class="composition flex">
        <div class="flex-center-vertical white-space-nowrap mr-24">
          <IconSVG :svg="IconTV" class="mr-8" />
          <span v-html="channelsCountTranslation" />
        </div>
        <div
          v-if="!offerIdsWithoutDvr.includes(sub.offerId)"
          class="flex-center-vertical white-space-nowrap mr-24"
        >
          <IconSVG :svg="IconCatalog" class="mr-8" />
          <span v-html="programCatalogTranslation" />
        </div>
        <div v-if="sub.vodSources && vodTranslation" class="flex-center-vertical">
          <IconSVG :svg="IconVod" class="mr-8" />
          <span v-html="vodTranslation" />
        </div>
      </div>
    </template>

    <div class="buttons-wrapper mb-16">
      <ButtonDefault
        v-if="!paymentMethodAvailable || sub.trialAvailable"
        data-cy="subscribe"
        :is-loading="isLoading"
        @click="
          createSub(currentSub);
          gaEvent({
            category: 'acquiring',
            action: `Клик по кнопке '${callToActionTranslation}'`,
            trial_available: `${sub.trialAvailable}`,
            qs_qr: $route.query.token ? 'qr' : 'qs',
            offer_id: sub.offerId,
          });
        "
      >
        <span v-html="callToActionTranslation" />
      </ButtonDefault>

      <ButtonPayment
        v-else
        :is-loading="isLoading"
        data-cy="subscribe"
        @createSub="createSub(currentSub)"
      />

      <ButtonDefault
        v-if="sub.renderingData.channels && sub.renderingData.channels.length"
        theme-forced="dark"
        view="secondary"
        @click="$emit('handleSubDetails', currentSub)"
      >
        <span v-html="moreDetailsTranslation" />
      </ButtonDefault>
    </div>

    <div v-if="sub.trialAvailable" class="body2 color-dark-font-secondary mt-16 mb-8">
      <span v-html="prolongationDateDesktopTranslation" />
      &#32;
      <span v-text="dateAfterTrialDurationDays" />
    </div>

    <div class="disclaimer" v-html="disclaimerContentBlock" />
  </div>
</template>

<script lang="ts">
import cloneDeep from 'lodash/cloneDeep';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ButtonPayment from 'src/components/ui/buttons/ButtonPayment.vue';
import UnorderedList from 'src/components/ui/UnorderedList.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCatalog from 'src/svg/catalog.svg';
import IconVod from 'src/svg/vod.svg';
import IconTV from 'src/svg/tv.svg';
import { dateAfterNDays } from 'src/utils/time/date';
import { convertToDays } from 'src/utils/time/convert-time';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { FALLBACK_RENDERING_METHOD_NAME, legacyRenderingMethods } from 'src/constants';
import { TSubscriptionOption } from 'src/store/quick-subscribe/types';
import { actions } from 'src/store/actions';
import { parseMd } from 'src/utils/markdown';

@Component({
  components: {
    BadgeSequoia,
    UnorderedList,
    ButtonPayment,
    ButtonDefault,
    IconSVG,
  },
})
export default class OfferCard extends SequoiaComponent {
  IconTV = IconTV;
  IconCatalog = IconCatalog;
  IconVod = IconVod;

  currentSub: TSubscriptionOption | null = null;

  @Prop()
  isLoading?: boolean;

  @Prop()
  sub!: TSubscriptionOption;

  @Prop({ default: false })
  priority!: boolean;

  @Prop({ default: false })
  isWl!: boolean;

  getBadgePrice(s: TSubscriptionOption) {
    return `-${Math.floor(
      100 -
        (parseInt(s.renderingData.priceValue) * 100) / parseInt(this.sub.renderingData.priceValue)
    )}%`;
  }

  get withDifferentPeriods() {
    return this.sub?.differentPeriods?.length > 1;
  }

  get trialDurationDays() {
    return convertToDays(this.sub.trialDurationSeconds, 'second');
  }

  get dateAfterTrialDurationDays() {
    return dateAfterNDays(this.trialDurationDays);
  }

  get offerIdsWithoutDvr() {
    return this.$store.siteConfig?.rates?.offerIdsWithoutDvr?.replace(/\s/g, '').split(',') || [];
  }

  get titleTranslation() {
    return this.renderingMethodConfig?.title?.translation || '{title}';
  }

  get badgeTranslation() {
    return this.renderingMethodConfig?.badge?.translation;
  }

  get trialPeriodTranslation() {
    return this.renderingMethodConfig?.trialPeriod?.translation;
  }

  get priceAndPeriodWithTrialTranslation() {
    return this.renderingMethodConfig?.priceAndPeriodWithTrial?.translation;
  }

  get priceAndPeriodTranslation() {
    return this.renderingMethodConfig?.priceAndPeriod?.translation;
  }

  get composedOfTranslation() {
    return this.renderingMethodConfig?.composedOf?.translation;
  }

  get channelsCountTranslation() {
    return this.renderingMethodConfig?.channelsCount?.translation || '{ channelsCount }';
  }

  get programCatalogTranslation() {
    return this.renderingMethodConfig?.programCatalog?.translation || '{ programCatalog }';
  }

  get vodTranslation() {
    return this.renderingMethodConfig?.vod?.translation;
  }

  get descriptionTranslation() {
    const key = this.sub.trialAvailable ? 'trialDescription' : 'description';
    if (!this.renderingMethodConfig) {
      return `{ ${key} }`;
    }

    return this.renderingMethodConfig[key]?.translation;
  }

  get callToActionTranslation() {
    const key = this.sub.trialAvailable ? 'trialCallToAction' : 'callToAction';

    if (!this.renderingMethodConfig) {
      return `{ ${key} }`;
    }

    return this.renderingMethodConfig[key]?.translation || `{ ${key} }`;
  }

  get moreDetailsTranslation() {
    return this.renderingMethodConfig?.moreDetails?.translation || '{ moreDetails }';
  }

  get disclaimerContentBlock() {
    const key = this.sub.trialAvailable ? 'trialDisclaimer' : 'disclaimer';
    if (!this.renderingMethodConfig) {
      return `{${key}}`;
    }

    return parseMd(this.renderingMethodConfig[key]?.contentBlock);
  }

  get prolongationDateDesktopTranslation() {
    const translation = this.getTranslation('prolongation_date') || '';
    return translation.replace('%offerTitle%', this.sub?.title || '');
  }

  get paymentMethodAvailable() {
    return selectors.payment.paymentMethodAvailableSelector(this.$store);
  }

  get renderingMethodName() {
    return this.sub?.renderingMethod?.name || '';
  }

  get renderingMethodConfig() {
    if (this.renderingMethodName) {
      const isLegacyMethod = legacyRenderingMethods.includes(this.renderingMethodName);

      const renderingMethodConfig = cloneDeep(
        this.$store.QSConfig[
          isLegacyMethod ? FALLBACK_RENDERING_METHOD_NAME : this.renderingMethodName
        ]
      );

      actions.QS.prepareRenderingMethodConfig(this.$store, renderingMethodConfig, this.sub);

      this.sub.renderingMethodConfig = renderingMethodConfig;
      return renderingMethodConfig;
    }
    return null;
  }

  created() {
    this.currentSub = this.sub;
  }

  createSub(sub: TSubscriptionOption | null) {
    this.$emit('createSub', sub);
  }

  changeSub(sub: TSubscriptionOption) {
    if (sub.offerId !== this.sub.offerId) {
      sub.renderingMethodConfig = this.sub.renderingMethodConfig;
      sub.renderingMethodConfig.priceAndPeriod.translation =
        sub.renderingData.priceValue + sub.renderingData.priceUnit;
      sub.renderingData.channels = this.sub.renderingData.channels;
    }
    this.currentSub = sub;
  }
}
</script>

<style lang="scss">
@import 'src/components/ui/input';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/link';
@import 'src/styles/common/paddings-variables';

.offer-card {
  position: relative;

  h2,
  h3 {
    &::first-letter {
      text-transform: capitalize;
    }
  }

  h2 {
    margin-bottom: 32px;

    @include desktop-s {
      margin-bottom: 48px;
    }
    @include tablet {
      margin-bottom: 32px;
    }
    @include mobile {
      margin-bottom: 24px;
    }
  }

  &.not-priority {
    padding: 24px 32px;
    background-color: var(--c-dark-400);
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

    + .not-priority {
      margin-top: 24px;
    }

    @include mobile {
      padding: 16px;
    }

    .price {
      font-size: 1.5rem;
    }
  }

  h3 {
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  .different-periods {
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 8px;
    }

    @include mobile {
      margin-bottom: 8px;
    }

    .period {
      position: relative;
      min-width: 162px;
      height: 120px;
      padding: 12px;
      margin-right: 16px;
      cursor: pointer;
      border: 2px solid var(--alpha-dark-5);
      border-radius: 8px;

      @include tablet {
        margin-bottom: 16px;
      }

      @include mobile {
        margin-bottom: 16px;
      }

      &.current {
        border-color: var(--alpha-dark-9);
      }

      .badge {
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 4px 5.5px;
        font-weight: bold;
        color: var(--c-light-font-primary);
        letter-spacing: 0.5px;
        background: var(--c-dark-violet);
        border-radius: 2px;
        @include caption2;
      }

      .duration {
        margin-bottom: 4px;
        line-height: 1.5rem;
        letter-spacing: 0.2px;
      }

      .price {
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        letter-spacing: 0;
      }

      .price .price-unit,
      .payment {
        color: var(--c-dark-font-secondary);
        @include caption2;
      }
    }
  }

  .price {
    font-size: 2rem;
  }

  .remark {
    color: var(--c-dark-font-secondary);
    @extend %body1;
  }

  .unordered-list {
    &::v-deep li {
      @extend %body2;

      position: relative;
      padding-left: 16px;

      &::before {
        content: '•';
        position: absolute;
        left: 0;
        color: var(--c-dark-brand);
      }

      + li {
        margin-top: 4px;
      }
    }
  }

  .description {
    margin: 16px 0 24px;
    white-space: pre-line;
    @include body2;
  }

  .buttons-wrapper {
    padding-bottom: 0;
    margin-bottom: 0;

    button {
      margin-bottom: 0;
    }
  }

  .disclaimer {
    color: var(--c-dark-font-secondary);
    @include body2;

    a {
      @extend %light-link-nav-underlined;
    }
  }

  .composition {
    margin-bottom: 24px;

    @include mobile {
      flex-direction: column;
      margin-bottom: 20px;
    }

    > div {
      margin-bottom: 8px;
    }

    .icon {
      flex-shrink: 0;
      cursor: auto;
    }
  }
}
</style>
