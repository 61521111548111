import { TStore } from 'src/store/types';
import * as api from 'src/api';
import { HTTP_CODES, PLAYBACK_METHODS, CHUNK_LENGTH, PLAYER_ERROR_CODES } from 'src/constants';
import {
  getArchiveTitleWatchUrl,
  makePath,
  matchUrlProtocolWithLocationProtocol,
} from 'src/utils/url';
import { getByLanguage, transliterate } from 'src/utils/language';
import { TPlayerErrorCodes } from 'src/store/player/types';
import {
  checkForProviderWithCountryCode,
  loadTitleVod,
  setCurrentAndNextSeasonsAndEpisodes,
  setPlayingChannelId,
  setPlayingCurrentTime,
  setPlayingEpisodeId,
  setPlayingMediaItemId,
  setPlayingTitleId,
  setPlayingUrl,
  setPlayingSeasonId,
  setCurrentEpisodeId,
} from 'src/store/vod/actions';
import {
  modalTitleIdSelector,
  playingMediaItemSelector,
  playingTickOffsetSelector,
  playingTitleSelector,
  currentSeasonIdSelector,
  videoDataSelector,
} from 'src/store/vod/selectors';
import {
  playingCurrentTimeInSecondsSelector,
  videoDelaySelector,
  titlePlaybackMethodParamsSelector,
  titlePlaybackMethodSelector,
  tickDurationMsSelector,
  timelineDurationMsSelector,
  titleDurationMsSelector,
} from 'src/store/archive/selectors';
import logger from 'src/utils/logger';
import { setMetaTitle } from 'src/store/seo/actions';
import { lastVodPause } from 'src/store/pauses/selectors';
import {
  clearError,
  lockScroll,
  setError,
  setLoaded,
  showPlayer,
  startLoading,
  stopLoading,
} from 'src/store/player/actions';
import Vue from 'vue';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { restoreChannelSettings, restoreLanguageSettings } from 'src/store/tv-channels/actions';
import { scrollEverythingToTop } from 'src/utils/scroll-everything-to-top';
import { languageCodeSelector } from 'src/store/common/selectors';
import {
  currentChannelIdSelector,
  currentRenditionSelector,
} from 'src/store/tv-current-channel/selectors';
import { getChannelById } from 'src/utils/epg';
import { allChannelsSelector } from 'src/store/tv-channels/selectors';
import { loadVodPauses } from 'src/store/pauses/actions';

const log = logger('archive-actions');

export const playVideo = async (
  store: TStore,
  data: { titleId: string; episodeId?: string; mediaItemId?: string; fromStart?: number }
) => {
  startLoading(store);
  showPlayer(store);

  if (process.env.VUE_ENV === 'client') {
    scrollEverythingToTop(true);
  }

  const title = store.vod?.sources?.['archive']?.fullTitles?.[data.titleId];

  setPlayingUrl(store, '');
  setPlayingTitleId(store, data.titleId);
  setPlayingEpisodeId(store, data.episodeId || '');
  setPlayingMediaItemId(store, data.mediaItemId || '');

  let _episodeId = data.episodeId;
  const isSavedPause = store.vod.isSavedPause;

  if (!title) {
    log.info('Title ID =', data.titleId, 'was not loaded yet, loading...');
    try {
      await loadTitleVod(store, 'archive', data.titleId);
    } catch (err) {
      log.error('Could not load title ID =', data.titleId, err);
      return;
    }
  }

  if (isSavedPause && title?.preview?.hasSeries && !_episodeId) {
    _episodeId = lastVodPause(store).episodeId || data.episodeId;
  }

  if (title?.preview?.hasSeries && _episodeId) {
    setCurrentEpisodeId(store, _episodeId);
    if (title.seasons?.length) {
      await setCurrentAndNextSeasonsAndEpisodes(store, 'archive', _episodeId, isSavedPause);
      setPlayingSeasonId(store, currentSeasonIdSelector(store));
    }
  }

  const playbackMethod = titlePlaybackMethodSelector(store);

  if (!playbackMethod) {
    stopLoading(store);
    setLoaded(store, true);

    log.error('Proper playback method for titleId =', data.titleId, 'was not found');
    setError(
      store,
      'common' as TPlayerErrorCodes,
      `Proper playback method for titleId = ${data.titleId} was not found`
    );
    throw new Error(`Proper playback method for titleId = ${data.titleId} was not found`);
  }

  const pmParams = titlePlaybackMethodParamsSelector(store);

  if (!pmParams) {
    log.error('Playback method params were not found');
    throw new Error('Playback method params were not found');
  }

  try {
    if (playbackMethod.name === PLAYBACK_METHODS.smotreshkaPlayBackInfo) {
      const currentChannelId = currentChannelIdSelector(store);
      const channelChanged = currentChannelId !== pmParams.channel_id;

      if (channelChanged) {
        Vue.set(
          store,
          'tvCurrentChannel',
          getChannelById(allChannelsSelector(store), pmParams.channel_id) || null
        );
      }

      setPlayingChannelId(store, pmParams.channel_id || '');
      const category =
        store.vod.sources['archive']?.fullTitles?.[data.titleId]?.preview?.categories?.[0];
      const typeSingular = category
        ? getByLanguage(
            store.translations[`category_${transliterate(category?.title || '')}_singular`],
            store.languageCode
          )
        : '';

      const year = store.vod.sources['archive']?.fullTitles?.[data.titleId].preview?.years?.[0];

      setMetaTitle(
        store,
        (getByLanguage(store.translations['archive_meta_title'], store.languageCode) || '')
          .replace(
            /%titleName%/g,
            store.vod.sources['archive']?.fullTitles?.[data.titleId]?.preview?.title || ''
          )
          .replace(/%year%/g, year ? `(${year})` : '')
          .replace(/%titleType%/g, typeSingular || '')
      );

      const success = await loadPlaybackInfoDetailsForArchive(store, pmParams.channel_id).catch(
        (err) => {
          log.error('loadPlaybackInfoDetailsForArchive', err.message || err);
        }
      );

      if (!success) {
        return;
      }

      setPlayingCurrentTime(store, tickDurationMsSelector(store), data?.fromStart);
      updateVideoUrlTime(store);
      stopLoading(store);
      lockScroll(store);

      const url = getArchiveTitleWatchUrl(data.titleId, _episodeId, data.mediaItemId);
      history.replaceState({}, '', makePath(url));
    } else {
      log.error(title, playbackMethod);
      throw new Error(`UNKNOWN PLAYBACK METHOD: '${playbackMethod.name}'`);
    }
  } catch (err) {
    if (err?.code === HTTP_CODES.FORBIDDEN) {
      await checkForProviderWithCountryCode(store, err);
    }
    throw err;
  }
};

export const playNextEpisode = async (
  store: TStore,
  nextEpisode: TVODTitlePreviewEnhanced,
  title = playingTitleSelector(store)
) => {
  const titleId = title?.preview?.id;
  const episodeId = nextEpisode?.preview?.id;
  const mediaItemId = nextEpisode?.details?.mediaItems
    ? nextEpisode?.details?.mediaItems[0]?.id
    : '';

  if (titleId && episodeId && mediaItemId) {
    await playVideo(store, {
      titleId,
      episodeId,
      mediaItemId,
    });
  }
};

export const savePause = async (store: TStore) => {
  const timelineDurationMs = timelineDurationMsSelector(store);
  const currentOffset = timelineDurationMs * playingTickOffsetSelector(store);
  const title = playingTitleSelector(store);
  const hasSeasons = title?.seasons?.length;
  const hasSeries = title?.preview?.hasSeries;
  const videoData = videoDataSelector(store);
  let episode;

  if (hasSeasons) {
    const season = title?.seasons?.find((s) => s.id === videoData.seasonId);
    episode = season?.episodes.find(
      (e) => e?.id === videoData.episodeId || e?.preview?.id === videoData.episodeId
    );
  } else if (hasSeries) {
    episode = title?.episodes?.find((e) => e?.preview?.id === videoData.episodeId);
  }

  const mediaItem = playingMediaItemSelector(
    store,
    hasSeries ? episode?.details?.mediaItems : title?.details?.mediaItems
  );

  if (
    videoData.currentTime > currentOffset &&
    videoData.currentTime < timelineDurationMs - currentOffset
  ) {
    if (videoData.titleId) {
      await api.pauses.saveVodPause(
        'archive',
        videoData.titleId,
        videoData.mediaItemId || mediaItem?.id,
        Math.floor(videoData.currentTime - tickDurationMsSelector(store)),
        titleDurationMsSelector(store),
        videoData.episodeId,
        hasSeasons ? videoData.seasonId : '',
        mediaItem?.expiresAt
      );

      await loadVodPauses(store, 'archive', videoData.titleId);
    } else {
      log.warning(`Could not save pause. Title's ID was not provided`);
    }
  }
};

export const updateVideoUrlTime = (store: TStore) => {
  const rendition = currentRenditionSelector(store);
  let url = rendition?.url;

  if (url) {
    url = matchUrlProtocolWithLocationProtocol(url, store.common.isHttps);
    const delay: number = videoDelaySelector(store) | 0;

    if (delay > CHUNK_LENGTH) {
      url += `&delay=${delay - playingCurrentTimeInSecondsSelector(store)}`;
    }
    log.info('updateVideoUrlTime', playingCurrentTimeInSecondsSelector(store));
    setPlayingUrl(store, url); // has to be here since it will take server time in precise moment
    log.info('Set playing video URL', url);
  } else {
    log.error('Playback video URL is absent');
  }
};

export const loadPlaybackInfoDetailsForArchive = async (store: TStore, channelId: string) => {
  const title =
    playingTitleSelector(store) ||
    store.vod?.sources?.['archive']?.fullTitles?.[modalTitleIdSelector(store)];

  try {
    if (!title) {
      log.error('Unable to load playbackInfo details: invalid title', title);
      return;
    }

    log.info('loading playbackInfo details for channel', channelId);

    const playbackInfoDetails: any = await api.channels.getPlaybackInfo(channelId).catch((err) => {
      log.error(err);
      let errorCode = PLAYER_ERROR_CODES.COMMON;
      if (err.status === HTTP_CODES.FORBIDDEN) {
        errorCode = PLAYER_ERROR_CODES.PROVIDER_ERROR;
      }
      setError(
        store,
        errorCode as TPlayerErrorCodes,
        err.data?.msg ||
          getByLanguage(store.translations['error_empty_response'], languageCodeSelector(store))
      );
    });

    if (!Array.isArray(playbackInfoDetails?.languages) || !playbackInfoDetails?.languages.length) {
      log.warning('Bad playbackInfo: no languages were found');
    }

    if (playbackInfoDetails) {
      clearError(store);
      log.info('playbackInfo details have been loaded', playbackInfoDetails);
    } else {
      log.warning('playbackInfo details have not been loaded', playbackInfoDetails);
    }

    if (store.siteConfig?.tv?.shouldUseTvApiV2) {
      if (store.tvChannels?.list?.[channelId]) {
        const info = {
          playbackInfo: {
            languageIndex: -1,
            renditionIndex: -1,
            details: playbackInfoDetails,
          },
        };

        Vue.set(store.tvChannels.list[channelId], 'info', info);
      }
    }

    const playbackInfoFromStore = store.tvChannels?.list?.[channelId]?.info?.playbackInfo;

    if (playbackInfoFromStore) {
      Vue.set(playbackInfoFromStore, 'details', playbackInfoDetails);
      Vue.set(playbackInfoFromStore, 'languageIndex', -1);
      Vue.set(playbackInfoFromStore, 'renditionIndex', -1);
    }

    title.playbackInfo = playbackInfoDetails;

    if (
      store.tvCurrentChannel?.info?.playbackInfo &&
      channelId === currentChannelIdSelector(store)
    ) {
      Vue.set(store.tvCurrentChannel.info.playbackInfo, 'details', playbackInfoDetails);
      Vue.set(store.tvCurrentChannel.info.playbackInfo, 'languageIndex', -1);
      Vue.set(store.tvCurrentChannel.info.playbackInfo, 'renditionIndex', -1);
    }

    if (title?.preview?.id) {
      Vue.set(
        store.vod.sources.archive.fullTitles[title.preview.id],
        'playbackInfo',
        playbackInfoDetails
      );
    }

    // restore language, rendition & video scaled settings
    restoreLanguageSettings(store);
    restoreChannelSettings(store);

    return true;
  } catch (err) {
    log.error(err.data.msg || err);

    let errorCode = PLAYER_ERROR_CODES.COMMON;
    if (err.status === HTTP_CODES.FORBIDDEN) {
      errorCode = PLAYER_ERROR_CODES.PROVIDER_ERROR;
    }

    // TODO refactor getTranslation in Global.ts in order to use it in actions
    setError(store, errorCode as TPlayerErrorCodes, err.data?.msg || 'Канал недоступен');
    return false;
  }
};
