import { TStore } from 'src/store/types';

export const channelCollectionsSelector = (store: TStore) =>
  store.siteConfig?.channelCollections || [];

export const channelCollectionsToShowOnMainSelector = (store: TStore) =>
  channelCollectionsSelector(store)?.filter(
    (collection) => collection.showOnMain && collection.slug && collection?.channels?.length
  ) || [];

export const currentCollectionSlugSelector = (store: TStore) =>
  store.common.currentChannelCollectionSlug;

export const currentCollectionSelector = (store: TStore) =>
  channelCollectionsSelector(store).find(
    (collection) => collection.slug === currentCollectionSlugSelector(store)
  );
