import { v4 as uuidV4 } from 'uuid';
import { cookieStore } from 'src/utils/storage';
import { COOKIE_NAMES } from 'src/constants';

/**
 * Generates user-agent UUID
 * @see https://lifestream.atlassian.net/browse/SEQ-1181
 */
export const getUserAgentUuid = () => {
  let uuid =
    (process.env.VUE_ENV === 'server' ? global.uaUuid : cookieStore.get(COOKIE_NAMES.uaUuid)) || '';
  if (uuid) {
    return uuid;
  }
  uuid = uuidV4();
  cookieStore.set(COOKIE_NAMES.uaUuid, uuid);
  return uuid;
};
