import logger from 'src/utils/logger';
import { TStore } from 'src/store/types';
import { providerIdSelector, userIdSelector } from 'src/store/account/selectors';
const log = logger('metrics');

const sentGaEvents: { [type: string]: boolean } = {};

export interface GaEventParams {
  category?: string | null;
  action?: string | null;
  page_path?: string | null;
  user_id?: string | null;
  provider_id?: string | null;
  strip_name?: string | null;
  collection_name?: string | null;
  channel_name?: string | null;
  title_id?: string | null;
  vod_name?: string | null;
  query?: string | null;
  link_text?: string | null;
  banner_id?: string | null;
  qs_qr?: string | null;
  offer_id?: string | null;
  trial_available?: string | null;
  control_type?: string | null;
  player_type?: string | null;
  error_text?: string | null;
  fullscreen?: string | null;
  sorting?: string | null;
  genre_filtering?: string | null;
  category_filtering?: string | null;
  profile?: string | null;
  season_num?: string | null;
}

interface GaEvent extends GaEventParams {
  event?: string;
}

declare global {
  interface Window {
    dataLayer: Array<GaEvent>;
    ymCounter: string;
    ga(): void;
  }
}

export const gaEvent = (params: GaEventParams, store: TStore) => {
  if (typeof window === 'undefined' || !params?.action) {
    return;
  }
  const pathname = location.pathname;

  if (userIdSelector(store)) {
    params.user_id = userIdSelector(store);
  }
  if (providerIdSelector(store)) {
    params.provider_id = providerIdSelector(store);
  }
  if (window.location.hostname === 'localhost' || !window.dataLayer) {
    return log.info('ga', 'instead of sending to GA', {
      ...params,
      event: 'event-to-ga',
      page_path: pathname,
    });
  }
  window.dataLayer.push({
    ...{
      user_id: null,
      provider_id: null,
      strip_name: null,
      collection_name: null,
      channel_name: null,
      title_id: null,
      vod_name: null,
      query: null,
      link_text: null,
      banner_id: null,
      qs_qr: null,
      offer_id: null,
      trial_available: null,
      control_type: null,
      player_type: null,
      error_text: null,
      fullscreen: null,
      sorting: null,
      genre_filtering: null,
      category_filtering: null,
      profile: null,
      season_num: null,
    },
    ...params,
    event: 'event-to-ga',
    page_path: pathname,
  });
};

export const gaEventOnce = (params: GaEventParams, store: TStore) => {
  const hash = JSON.stringify(params);
  if (sentGaEvents[hash]) {
    return;
  }
  gaEvent(params, store);
  sentGaEvents[hash] = true;
};

export const yaMetrikaGoal = (goal: string) => {
  const yaCounter = `yaCounter${window.ymCounter}`;
  try {
    (window as { [key: string]: any })[yaCounter]?.reachGoal(goal);
  } catch (err) {
    log.info('metrika', 'instead of sending to Yandex.Metrika', err);
  }
};
