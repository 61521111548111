import { TPlayer } from 'src/store/player/types';
import { DateTime } from 'src/utils/time/date-time';

const initialState: TPlayer = {
  alert: '',
  autoplay: false,

  dvrWarningOverlay: {
    channelId: null,
    isVisible: false,
    message: '',
  },

  error: null,
  fastForwarding: false,

  hasVideoEnded: false,
  isDateTimeVisible: true,
  isHelpVisible: false,
  isEpgVisible: false,
  isFullscreenMenuVisible: false,
  isLive: true,
  isScrollLocked: false,
  isSettingsMenuVisible: false,

  loading: false,
  loaded: false,
  minimized: false,

  overlay: {
    frozen: false,
    hideTimeoutId: null,
    visible: false,
  },

  pickedEpochDay: DateTime?.toEpochDay(new Date()), // day number starting from 1970
  pushHistoryStateForChannel: false,
  ready: false,
  rewinding: false,

  shouldStartFromPause: false,

  timeline: {
    isBalloonFollowingMouse: true,
    isShowBalloon: false,
  },

  touchedTimes: 0,

  type: '',

  video: {
    fps50: false,
    hasFps50: false,
    isFullscreen: false,
    isPlaying: false,
    isVolumeMounted: false,
    hasSubtitles: false,
    muted: false,
    playingTimeMs: 0,
    poster: '',
    scaled: false,
    startTimeMs: 0,
    subtitleDisplay: false,
    volume: 0.5,
    wasLanguageChanged: false,
    wasPlayingBeforeOffline: false,
  },

  visible: false,

  volumeSlider: {
    hideTimeoutId: null,
    visible: false,
  },
};

export default initialState;
