<template>
  <div
    class="tile-thumbnail"
    :class="[$store.theme, { loading: !title && image }]"
    @click="$emit('click', $event)"
  >
    <div v-if="!(title && image)" class="loading" />

    <component v-else :is="link ? 'router-link' : 'div'" :to="link" class="inner">
      <div class="thumbnail" :class="{ 'with-progress-bar': timelinePosition }">
        <button v-if="isActive" type="button" class="play">
          <IconSVG :svg="IconPlay" />
        </button>

        <div class="image-wrap">
          <LazyImage
            v-if="image"
            class="image"
            :class="image.orientation"
            :with-background-blur="image.width <= image.height"
            :with-icon="true"
            :src="image.src"
            :width="image.width"
            :height="image.height"
            :provider="image.type"
          />
        </div>
        <div class="progress-wrap">
          <ProgressBar
            v-if="timelinePosition"
            class="progress"
            theme-forced="dark"
            :percents="timelinePosition"
          />
          <div v-if="duration" class="duration" v-html="duration" />
        </div>
      </div>
      <TileTitle v-if="title" :title="title" />
      <TileDescription v-if="description" :description="description" />
    </component>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import TileTitle from 'src/components/ui/tiles/parts/TileTitle.vue';
import TileDescription from 'src/components/ui/tiles/parts/TileDescription.vue';
import LazyImage from 'src/components/LazyImage.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';

@Component({
  components: { LazyImage, ProgressBar, TileTitle, TileDescription, IconSVG },
})
export default class TileThumbnail extends Vue {
  IconPlay = IconPlay;

  @Prop({ required: true })
  title!: string;

  @Prop({ default: false })
  isActive!: boolean;

  @Prop()
  description?: string;

  @Prop({ required: true })
  image!: {
    src: string;
    width?: number;
    height?: number;
    orientation?: 'portrait' | 'landscape';
    type: string; // use type instead of provider https://dev.tightvideo.com/projects/server-side/wiki/Vod-pics
  };

  @Prop()
  link?: string;

  @Prop()
  duration?: string;

  @Prop()
  timelinePosition?: number;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/gradient-for-progress-bar';

$transition: var(--ease-in-out) 0.25s;
$border-radius: 4px;

.tile-thumbnail {
  position: relative;
  display: block;
  min-width: 114px;
  cursor: pointer;
  backface-visibility: hidden;
  transform: perspective(1000px);

  &.loading {
    flex-shrink: 0;
  }

  &.active {
    pointer-events: none;

    .thumbnail {
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px;
      }

      &::before {
        z-index: var(--z-1);
        background-color: var(--c-overlay-lvl-60);
      }

      &::after {
        z-index: var(--z-2);
        background-color: var(--c-overlay-brand-fixed-2);
      }
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: var(--z-3);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background-color: var(--alpha-dark-2);
      border: 0;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      .icon {
        color: var(--alpha-dark-6);
      }
    }

    .image-wrap {
      box-shadow: none;
    }
  }

  @include devices-with-hover {
    &:hover .thumbnail {
      transform: translateY(-8px);
    }
  }

  .inner {
    width: 100%;
  }

  button {
    width: 100%;
    text-align: left;
  }

  .loading {
    &::before {
      content: '';
      display: block;
      padding-top: var(--proportion-3-2);
      border-radius: $border-radius;
    }

    &::after {
      content: '';
      display: block;
      height: 40px;
      margin-top: 8px;
      border-radius: $border-radius;

      @include mobile {
        height: 20px;
      }
    }
  }

  .thumbnail {
    position: relative;
    background-color: var(--c-light-400);
    border-radius: 4px;
    transition: $transition;

    &.with-progress-bar::before {
      @include gradient-for-progress-bar($border-radius);
    }
  }

  .image-wrap {
    position: relative;
    width: 100%;
    padding-top: var(--proportion-3-2);
    overflow: hidden;
    border-radius: $border-radius;
    transition: $transition;
  }

  .image {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: translateX(-50%);
    object-fit: cover;

    &.portrait {
      &::v-deep .image {
        object-fit: contain;
      }
    }
  }

  .progress-wrap {
    position: absolute;
    bottom: 8px;
    left: 8px;
    z-index: var(--z-1);
    display: flex;
    flex-direction: column-reverse;
    width: calc(100% - 16px);
    height: calc(100% - 16px);

    @include mobile-and-tablet {
      bottom: 4px;
      left: 4px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
    }

    .duration {
      align-self: flex-start;
      padding: 2px 4px;
      margin-bottom: 4px;
      color: var(--c-dark-font-primary);
      background-color: var(--c-dark-300);
      border-radius: 2px;
      @include caption2;
    }
  }

  .tile-title {
    @media (max-width: #{$desktop-s-max}) {
      margin-top: 8px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    @include devices-with-hover {
      &:hover .image-wrap {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
      }
    }

    &.active {
      .tile-title {
        color: var(--c-light-font-secondary);
      }

      .tile-description {
        color: var(--c-light-font-tertiary);
      }
    }

    .loading::before,
    .loading::after {
      background-color: var(--alpha-light-3);
    }

    .image-wrap {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &.dark {
    @include devices-with-hover {
      &:hover .image-wrap {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
      }
    }

    &.active {
      .tile-title {
        color: var(--c-dark-font-secondary);
      }

      .tile-description {
        color: var(--c-dark-font-tertiary);
      }
    }

    .loading::before,
    .loading::after {
      background-color: var(--alpha-dark-3);
    }

    .image-wrap {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
