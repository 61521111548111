<template>
  <component
    v-if="currentStepComponentName"
    :is="currentStepComponentName"
    @handleClose="$emit('handleClose')"
    @createSub="createSub"
  />
</template>

<script lang="ts">
import AboutTrial from './steps/AboutTrial.vue';
import TrialConfirmed from './steps/TrialConfirmed.vue';
import PhoneSending from '../phone-verification-steps/PhoneSending.vue';
import PhoneVerification from '../phone-verification-steps/PhoneVerification.vue';
import Error from '../phone-verification-steps/PhoneVerificationError.vue';
import { actions } from 'src/store/actions';
import {
  HTTP_CODES,
  QS_STEP_NAMES,
  SUBSCRIPTION_FORBIDDEN_ERROR_CODE,
  SUBSCRIPTION_METHOD_NAMES,
} from 'src/constants';
import { goToNextStep, goToStepByName } from 'src/store/quick-subscribe/actions';
import { selectors } from 'src/store/selectors';
import logger from 'src/utils/logger';
import Component from 'vue-class-component';
import Vue from 'vue';

const log = logger('trial');

@Component({
  components: {
    AboutTrial,
    TrialConfirmed,
    PhoneSending,
    PhoneVerification,
    Error,
  },
})
export default class TrialQS extends Vue {
  token = '';

  get currentStepComponentName() {
    return selectors.QS.currentStepComponentNameSelector(this.$store);
  }

  get prioritySubscription() {
    return selectors.QS.prioritySubscriptionSelector(this.$store);
  }

  mounted() {
    this.token = this.$route.query.token as string;
  }

  async createSub() {
    const subscriptionMethodName = this.prioritySubscription?.subscriptionMethod.name;

    if (subscriptionMethodName === SUBSCRIPTION_METHOD_NAMES.AUTHENTICATED) {
      try {
        await actions.QS.createSubscription(this.$store, {
          data: {
            Id: this.prioritySubscription?.offerId,
          },
        });
        this.$store.modalCallback = true;
        goToStepByName(this.$store, QS_STEP_NAMES.CONFIRMED);
      } catch (err) {}
    } else if (
      subscriptionMethodName === SUBSCRIPTION_METHOD_NAMES.PHONE_COLLECT_INSTANT ||
      subscriptionMethodName === SUBSCRIPTION_METHOD_NAMES.PHONE_COLLECT_DELAYED
    ) {
      try {
        await actions.QS.createSubscriptionWithVerifiedPhone(this.$store, {
          data: {
            Id: this.prioritySubscription?.offerId,
          },
          params: { token: this.token },
        });
        this.$store.modalCallback = true;
        goToStepByName(this.$store, QS_STEP_NAMES.CONFIRMED);
      } catch (err) {
        log.error(err);
        if (err.status === HTTP_CODES.FORBIDDEN && err.code === SUBSCRIPTION_FORBIDDEN_ERROR_CODE) {
          goToNextStep(this.$store);
        } else {
          goToStepByName(this.$store, QS_STEP_NAMES.ERROR);
        }
      }
    }
  }
}
</script>
