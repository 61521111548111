import { TStore } from 'src/store/types';

export const hideNotification = (store: TStore) => {
  store.adultOnboarding.showNotification = false;
};

export const setShowNotification = (store: TStore, val: boolean) => {
  store.adultOnboarding.showNotification = val;
};

export const setNotificationTitle = (store: TStore, val: string) => {
  store.adultOnboarding.notificationTitle = val;
};

export const setNotificationMessage = (store: TStore, val: string) => {
  store.adultOnboarding.notificationMessage = val;
};

export const setWithButton = (store: TStore, val: boolean) => {
  store.adultOnboarding.withButton = val;
};

export const setNextStep = (store: TStore, val: string) => {
  store.adultOnboarding.nextStep = val;
};

export const setPinAction = (store: TStore, val: string) => {
  store.adultOnboarding.pinAction = val;
};

export const setCurrentStep = (store: TStore, val: string) => {
  store.adultOnboarding.currentStep = val;
};
