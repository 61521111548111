<template>
  <div
    class="device-component"
    :class="componentName"
    :data-ctype="$options.name"
    :data-cname="componentName"
  >
    <div class="device-name" v-html="contentBlock" />
    <img class="device-image" :src="imageSrc" alt="" />
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

@Component
export default class Device extends mixins(Global, CmsComponent) {
  // TODO rename DeviceSequoia

  content?: Partial<{
    contentBlock: TTranslations;
    image: TTranslations<TImageCMS>;
  }> | null = null;

  get contentBlock() {
    return this.getByLanguage(this.content?.contentBlock);
  }

  get imageSrc() {
    return this.getByLanguage(this.content?.image)?.url || '';
  }
}
</script>

<style lang="scss" scoped>
.device-component {
  display: inline-block;
  width: calc(100% * 1 / 4 - 20px);
  padding: 20px 0 0;
  text-align: center;
  background: linear-gradient(rgba(255, 255, 255, 0.9), #fff), #ececec;
  border: 1px solid #ececec;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02);

  .device-name {
    padding: 0 10px;
  }

  .device-image {
    display: inline-block;
    width: calc(100% - 80px);
    height: auto;
    padding: 10px 0;
  }
}
</style>
