<template>
  <ul class="unordered-list" :class="theme">
    <template v-for="listItem in list">
      <li v-if="typeof listItem === 'object' && listItem.available" v-text="listItem.value" />
      <li v-else-if="typeof listItem === 'string'" v-text="listItem" />
    </template>
  </ul>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';

@Component
export default class UnorderedList extends SequoiaComponent {
  @Prop({ required: true })
  list!: any;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';

.unordered-list {
  list-style-type: none;

  li {
    @extend %body2;

    position: relative;
    padding-left: 16px;
    color: var(--c-dark-font-secondary);

    &::before {
      content: '•';
      position: absolute;
      left: 0;
    }

    + li {
      margin-top: 4px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    li {
      &::before {
        color: var(--c-light-brand);
      }
    }
  }

  &.dark {
    li {
      &::before {
        color: var(--c-dark-brand);
      }
    }
  }
}
</style>
