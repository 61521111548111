<template>
  <button
    type="button"
    class="button bookmark"
    :class="[theme, size, { active }, { 'has-indicator': hasIndicator }]"
    @click.stop="$emit('click')"
  >
    <IconSVG :svg="active ? IconBookmarkOn : IconBookmarkOff" />
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconBookmarkOn from 'src/svg/bookmark-on.svg';
import IconBookmarkOff from 'src/svg/bookmark-off.svg';

@Component({
  components: { IconSVG },
})
export default class ButtonBookmark extends Vue {
  IconBookmarkOn = IconBookmarkOn;
  IconBookmarkOff = IconBookmarkOff;

  @Prop({ default: 'dark' })
  themeForced!: 'light' | 'dark';

  @Prop({ default: 'small' })
  size!: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

  @Prop({ default: false })
  active!: boolean;

  @Prop({ default: true })
  hasIndicator!: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.button.bookmark {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 4px;
  transition: background-color 0.15s var(--ease-out), opacity 0.25s var(--ease-in-out);

  @include devices-with-hover {
    &:active,
    &.active:active {
      .icon {
        transition: transform var(--ease-out) 0.15s;
        transform: scale(1.08);
      }
    }
  }

  &.small {
    width: 40px;
    height: 32px;
  }

  &.medium {
    width: 40px;
    height: 40px;
  }

  &.large {
    width: 48px;
    height: 40px;
  }

  &.xlarge {
    width: 48px;
    height: 48px;
  }

  &.xxlarge {
    width: 56px;
    height: 56px;
  }

  &.active {
    opacity: 1;

    &.has-indicator {
      background-color: var(--alpha-light-7); // the same for both themes
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    color: var(--c-light-contrast);
    background-color: var(--alpha-light-3);

    @include devices-with-hover {
      &:hover,
      &:focus {
        background-color: var(--alpha-light-4);
      }

      &:active {
        background-color: var(--alpha-light-5);
      }
    }

    &.active {
      color: var(--c-light-brand);
    }
  }

  &.dark {
    color: var(--c-dark-contrast);
    background-color: var(--alpha-dark-3);

    @include devices-with-hover {
      &:hover,
      &:focus {
        background-color: var(--alpha-dark-4);
      }

      &:active {
        background-color: var(--alpha-dark-5);
      }
    }

    &.active {
      color: var(--c-dark-brand);
    }
  }
}
</style>
