<template>
  <div class="credit-card" @click="$emit('click')">
    <div v-if="isNew" class="image-wrapper">
      <IconSVG :svg="IconPlusCircled" class="plus" :size="16" />
    </div>
    <div v-else class="image-wrapper">
      <ImageWithHash
        class="image"
        :src="`/public/images/icons/${cardType.toLowerCase()}.svg`"
        :alt="cardType"
      />
    </div>
    <span v-html="cardText" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlusCircled from 'src/svg/plus-circled.svg';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: { ImageWithHash, IconSVG },
})
export default class CreditCard extends SequoiaComponent {
  IconPlusCircled = IconPlusCircled;

  @Prop()
  cardType?: string;

  @Prop()
  cardLast4?: string;

  @Prop()
  isNew?: boolean;

  get cardText() {
    return this.isNew ? this.getTranslation('new_card') : `*${this.cardLast4}`;
  }
}
</script>

<style lang="scss" scoped>
.credit-card {
  display: flex;
  align-items: center;

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 24px;
    margin-right: 8px;
    background-color: var(--c-light-100);
    border-radius: 4px;
  }

  .image {
    width: 24px;
  }

  .plus {
    color: currentColor;
  }
}
</style>
