<template>
  <div class="quick-subscribe-container">
    <component :is="currentTemplateComponent" @handleClose="hideModal" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import TrialQS from 'src/components/quick-subscribe/trial/TrialQS.vue';
import AcquiringQS from 'src/components/quick-subscribe/acquiring/AcquiringQS.vue';
import FallbackQS from 'src/components/quick-subscribe/FallbackQS.vue';

@Component({
  components: { AcquiringQS, TrialQS, FallbackQS },
})
export default class QuickSubscribe extends Vue {
  get currentTemplateComponent() {
    return selectors.QS.currentTemplateComponentSelector(this.$store);
  }

  created() {
    if (process.env.VUE_ENV === 'server') {
      this.$store.QSConfig = this.$ssrContext.cmsData.QSConfig;
    }
  }

  mounted() {
    actions.QS.setCurrentStepByIndex(this.$store, 0);
    if (this.$store.QS.paymentRetry) {
      actions.QS.goToStepByName(this.$store, 'PaymentRetry');
    }
  }

  hideModal() {
    actions.common.hideModal(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.quick-subscribe-container {
  @include mobile-and-tablet {
    width: 100%;
  }

  &::v-deep .qs-content {
    position: relative;
    z-index: var(--z-2);
  }
}
</style>
