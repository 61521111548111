<template>
  <CmsLayout>
    <template v-slot="{ refScrollWrap }">
      <main class="cms-page flex" :class="[$store.theme, containerClass]">
        <component
          :is="cmsPageComponentEnhanced"
          class="cms-page-inner left"
          :class="{
            'with-background': background,
            'text-page-wrap': dataObject.page.shouldBeTextPage,
          }"
          :style="{ backgroundImage }"
        />

        <div v-if="dataObject.page.shouldBeTextPage" class="instructions right">
          <div class="h6" v-html="dataObject.page.groupNameKey" />
          <router-link
            v-for="page in pages"
            :key="page.pageId"
            class="device"
            :to="page.path"
            :class="{
              active: page.pageId === dataObject.page.pageId,
            }"
            v-html="getTranslation(page.meta.titleTrKey)"
          />
        </div>
      </main>

      <ButtonActionScrollTop :scroll-block="refScrollWrap" />
    </template>
  </CmsLayout>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { SequoiaPage } from 'src/mixins';
import groupBy from 'lodash/groupBy';
import { TCmsPage, TCmsPageData } from 'src/store/types';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import YoutubeVideo from 'src/components/cms/YoutubeVideo.vue';
import ButtonActionScrollTop from 'src/components/ui/buttons/ButtonActionScrollTop.vue';

// CMS components which are available in admin editor
const AboutPage = () =>
  import(/* webpackChunkName: "about-page" */ 'src/components/cms/AboutPage.vue');
import Accordion from 'src/components/cms/Accordion.vue';
import Advantages from 'src/components/cms/Advantages.vue';
import BackgroundWithText from 'src/components/cms/BackgroundWithText.vue';
import Banner from 'src/components/cms/Banner.vue';
import ButtonComponent from 'src/components/cms/ButtonComponent.vue';
import ButtonFileDownload from 'src/components/cms/ButtonFileDownload.vue';
import Device from 'src/components/cms/Device.vue';
import FooterComponent from 'src/components/cms/FooterComponent.vue';
import HeaderSequoia from 'src/components/cms/HeaderSequoia.vue';
const HomePage = () =>
  import(/* webpackChunkName: "home-and-search-page" */ 'src/pages/HomePage.vue');
import HowToWatch from 'src/components/cms/HowToWatch.vue';
import ImageSlider from 'src/components/cms/ImageSlider.vue';
import ImageTextButton from 'src/components/cms/ImageTextButton.vue';
import TabPanel from 'src/components/cms/TabPanel.vue';
import TextOnly from 'src/components/cms/TextOnly.vue';
const TvWizard = () =>
  import(/* webpackChunkName: "tv-wizard" */ 'src/components/cms/TvWizard.vue');

// registering these components globally to avoid circular dependencies
Vue.component('AboutPage', AboutPage);
Vue.component('Accordion', Accordion);
Vue.component('Advantages', Advantages);
Vue.component('BackgroundWithText', BackgroundWithText);
Vue.component('Banner', Banner);
Vue.component('ButtonComponent', ButtonComponent);
Vue.component('ButtonFileDownload', ButtonFileDownload);
Vue.component('Device', Device);
Vue.component('FooterComponent', FooterComponent);
Vue.component('HeaderSequoia', HeaderSequoia);
Vue.component('HomePage', HomePage);
Vue.component('HowToWatch', HowToWatch);
Vue.component('ImageSlider', ImageSlider);
Vue.component('ImageTextButton', ImageTextButton);
Vue.component('tab-panel', TabPanel);
Vue.component('TextOnly', TextOnly);
Vue.component('TvWizard', TvWizard);
Vue.component('YoutubeVideo', YoutubeVideo);

@Component({
  components: { CmsLayout, ButtonActionScrollTop },
})
export default class CmsPage extends SequoiaPage {
  background: null | { url: string } = null;

  @Prop()
  cmsPageComponent!: TCmsPage;

  @Prop()
  dataObject!: TCmsPageData;

  get containerClass() {
    return ['/', '/adult', '/about-us/about'].includes(this.dataObject.page.path)
      ? ''
      : 'container-1440 with-side-padding';
  }

  get backgroundImage() {
    return this.background ? `url(${this.background.url})` : 'none';
  }

  // todo change 'any' once there will be proper type for 'is' attribute in component tag
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get cmsPageComponentEnhanced(): any {
    return {
      ...this.cmsPageComponent,
      data: () => this.dataObject,
    };
  }

  get pages() {
    const groups = groupBy(
      this.$store.cmsPages?.map((page) => page.dataObject.page),
      'groupNameKey'
    );

    return groups[this.dataObject.page.groupNameKey];
  }

  serverPrefetch() {
    this.showFooter();
    actions.seo.addBreadcrumbs(this.$store, this.seoBreadcrumbs);
  }

  mounted() {
    this.showFooter();
  }

  updated() {
    this.showFooter();
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  showFooter() {
    if (
      !selectors.common.showFooterSelector(this.$store) &&
      !this.$route.path.includes('/channel/')
    ) {
      actions.common.showFooter(this.$store);
    }
  }

  get seoBreadcrumbs() {
    return [
      { name: this.getTranslation('breadcrumb_smotreshka'), link: '/' },
      {
        name: this.getTranslation(this.dataObject.page.meta.titleTrKey),
        link: this.dataObject.page.path,
      },
    ];
  }

  getMetaInfo() {
    return {
      title: this.getTranslation(this.dataObject.page.meta.titleTrKey),
      meta: this.generateMetaCmsPage(this.dataObject),
    };
  }

  generateMetaCmsPage(data: TCmsPageData) {
    const meta = [];
    const page = data.page;

    if (page.meta.descriptionTrKey) {
      meta.push({
        name: 'description',
        content: this.getTranslation(page.meta.descriptionTrKey),
      });
    }
    if (page.meta.keywordsTrKey) {
      meta.push({ name: 'keywords', content: this.getTranslation(page.meta.keywordsTrKey) });
    }
    if (page.socialMeta.titleTrKey) {
      meta.push({
        property: 'og:title',
        content: this.getTranslation(page.socialMeta.titleTrKey),
      });
    }
    if (page.socialMeta.descriptionTrKey) {
      meta.push({
        property: 'og:description',
        content: this.getTranslation(page.socialMeta.descriptionTrKey),
      });
    }
    if (data.metaImages.ogFileKey) {
      meta.push({
        property: 'og:image',
        content: data.metaImages.ogFileKey,
      });
    }
    if (data.metaImages.vkFileKey) {
      meta.push({
        property: 'vk:image',
        content: data.metaImages.vkFileKey,
      });
    }

    return meta;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.cms-page {
  flex: 1 0 auto;
  align-items: flex-start;
  margin-top: 64px;

  @include tablet {
    margin-top: 48px;
  }

  @include mobile {
    margin-top: 32px;
  }

  .cms-page-inner {
    flex-grow: 1;
    width: 100%;
  }

  .text-page-wrap {
    max-width: 68%;
    padding-right: 0;
    padding-left: 0;

    @include mobile-and-tablet {
      order: 2;
      max-width: 100%;
    }
  }

  .with-background {
    background: no-repeat center center fixed;
    background-size: cover;
  }

  .instructions {
    flex-shrink: 0;
    max-width: 30%;
    padding: 8px 0 0 16px;
    margin-top: 20px;
    margin-left: auto;
    border-left-style: solid;
    border-left-width: 4px;

    @include mobile-and-tablet {
      order: 1;
      width: 100%;
      max-width: 100%;
      padding: 0 0 0 12px;
      margin-top: 0;
      margin-bottom: 24px;
      margin-left: 0;
    }

    .h6 {
      margin-bottom: 24px;

      @include mobile-and-tablet {
        margin-bottom: 16px;
      }
    }

    .device {
      @include body2;

      display: block;
      margin-bottom: 12px;

      &.active {
        font-weight: bold;
        pointer-events: none;
      }

      @include mobile-and-tablet {
        display: inline-block;
        margin-right: 16px;
      }
    }
  }

  &::v-deep .accordion + .text-only {
    margin-top: 32px;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .instructions {
      border-left-color: var(--c-light-brand);

      .device {
        color: var(--c-light-font-primary);

        &:visited {
          color: var(--c-light-font-primary);
        }

        @include devices-with-hover {
          &:hover {
            color: var(--c-light-font-secondary);
          }
        }
      }
    }
  }

  &.dark {
    .instructions {
      border-left-color: var(--c-dark-brand);

      .device {
        color: var(--c-dark-font-primary);

        &:visited {
          color: var(--c-dark-font-primary);
        }

        @include devices-with-hover {
          &:hover {
            color: var(--c-dark-font-secondary);
          }
        }
      }
    }
  }
}
</style>
