<template>
  <div class="payment-retry-actions dark flex-space-between">
    <div class="controls">
      <div v-if="cardType && cardLast4" class="method">
        <div class="method-text"><span v-html="getTranslation('current_payment_method')" />:</div>
        <CreditCard :cardType="cardType" :cardLast4="cardLast4" />
      </div>
      <div class="buttons flex">
        <ButtonDefault
          view="primary"
          theme-forced="dark"
          data-cy="renew-with-current-card"
          :disabled="!canRenewWithCurrentCard"
          @click="renewWithCurrentCard"
        >
          <span v-html="getTranslation('renew_current_card')" />
        </ButtonDefault>
        <ButtonDefault
          theme-forced="dark"
          view="secondary"
          data-cy="add-new-card"
          @click="attachNewCard"
        >
          <span v-html="getTranslation('link_new_card')" />
        </ButtonDefault>
      </div>
    </div>
    <div class="error" data-cy="retry-payment-error" v-text="error" />
    <div
      v-if="!canRenewWithCurrentCard"
      class="try-next-day-note"
      data-cy="retry-try-again-next-day"
      v-html="getTranslation('retry_try_renew_with_current_card_next_hour')"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import NotificationRetry from 'src/components/ui/notifications/NotificationRetry.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import QSPayment from 'src/components/quick-subscribe/QSPayment.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import CreditCard from 'src/components/ui/CreditCard.vue';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import * as api from 'src/api';
import { getPaymentStatus, isPaymentSuccessful } from 'src/utils/quick-subscribe';
import { cookieStore } from 'src/utils/storage';
import { COOKIE_NAMES, SUBSCRIPTION_STATUSES } from 'src/constants';
import { convertToMilliseconds } from 'src/utils/time/convert-time';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import logger from 'src/utils/logger';
import { makePath } from 'src/utils/url';

const log = logger('payment-retry-actions');

@Component({
  components: {
    QSPayment,
    QuickSubscribeBackground,
    CreditCard,
    ButtonDefault,
    ModalSequoia,
    NotificationRetry,
  },
})
export default class PaymentRetryActions extends SequoiaComponent {
  retryWithCurrentCardAttempt = 0;
  blockForCurrentCard = false;
  error = '';

  @Prop({ required: true })
  subscriptionOptionId!: string;

  get cardType() {
    return selectors.payment.cardTypeSelector(this.$store);
  }

  get cardLast4() {
    return selectors.payment.cardLast4Selector(this.$store);
  }

  get subscription() {
    return selectors.payment
      .subscriptionsSelector(this.$store)
      .find((subscription) => subscription.id === this.subscriptionOptionId);
  }

  get lastPaymentStatus() {
    return this.subscription?.last_payment.status;
  }

  get canRenewWithCurrentCard() {
    return (
      !this.blockForCurrentCard && // card can be blocked by yoomoney on the 3rd attempt to retry payment
      this.lastPaymentStatus !== SUBSCRIPTION_STATUSES.PENDING // user can't renew with current card after click on add new card and changing payment status to pending
    );
  }

  mounted() {
    this.blockForCurrentCard = cookieStore.get(COOKIE_NAMES.retryBlockForCard) === this.cardLast4;
  }

  async renewWithCurrentCard() {
    const paymentMethodId = this.$store.payment.method?.id;
    if (!(this.subscriptionOptionId && paymentMethodId)) {
      log.error(
        'subscriptionOptionId or paymentMethodId are not defined',
        this.subscriptionOptionId,
        paymentMethodId
      );
      return;
    }
    this.$emit('loading', true);

    try {
      await api.quickSubscribe.renewSubscriptionWithExistedPm(this.subscriptionOptionId, {
        data: {
          payment_method_id: paymentMethodId,
        },
        params: {
          token: this.$route.query.token,
        },
      });
      this.gaEvent({
        category: 'acquiring',
        action: 'Ретрай - продлить с текущей',
        qs_qr: this.$route.query.token ? 'qr' : 'qs',
      });
      const paymentStatus = await getPaymentStatus(this.subscriptionOptionId, {
        params: {
          token: this.$route.query.token,
        },
      }).catch((err) => {
        log.error(err);
        return null;
      });
      if (isPaymentSuccessful(paymentStatus?.status || '')) {
        await actions.tvChannels.loadTvData(this.$store);
        this.$emit('success');
      } else {
        this.retryWithCurrentCardAttempt++;
        this.error = this.getTranslation('retry_unable_to_renew_with_current_card');
        if (this.retryWithCurrentCardAttempt >= 3) {
          // after 3rd attempt - block retry button for 1 hour (set cookie with last 4 digits of card for 1 hour)
          cookieStore.set(COOKIE_NAMES.retryBlockForCard, this.cardLast4, {
            expires: new Date(Date.now() + convertToMilliseconds(1, 'hour')),
          });
          this.retryWithCurrentCardAttempt = 0;
          this.blockForCurrentCard = true;
        }
      }
    } catch (err) {
      this.error = err.status === 404 ? this.getTranslation('something_went_wrong') : err.message;
    } finally {
      this.$emit('loading', false);
    }
  }

  async attachNewCard() {
    if (!this.subscriptionOptionId) {
      log.error('subscriptionOptionId is not defined');
      return;
    }
    const confirmationUrl = await this.getConfirmationUrl();
    if (confirmationUrl) {
      window.location.href = confirmationUrl;
    } else {
      this.error = this.getTranslation('retry_unable_to_renew_with_new_card');
    }
  }

  async getConfirmationUrl() {
    // if existing in-retry subscription is in user-subscriptions-list and this subscription has confirmation_url - return it
    const subscription = this.$store.payment.subscriptions.find(
      (subscription) => subscription.id === this.subscriptionOptionId
    );
    if (subscription?.last_payment?.confirmation?.confirmation_url) {
      return subscription.last_payment.confirmation.confirmation_url;
    }

    // otherwise, request confirmation_url with "renew-with-new-pm" method
    this.$emit('loading', true);

    const returnUrl =
      this.$route.name === 'qr-payment'
        ? `${location.origin}${makePath('/qr-payment')}?token=${
            this.$route.query.token
          }&expires_at=${this.$route.query.expires_at}&utm_source=retry_qr`
        : `${location.origin}${makePath('/acquiring-status')}?subscription_id=${
            this.subscriptionOptionId
          }&utm_source=retry`;
    const result = await api.quickSubscribe
      .renewSubscriptionWithNewPm(this.subscriptionOptionId, {
        data: {
          return_url: returnUrl,
        },
        params: {
          token: this.$route.query.token,
        },
      })
      .catch((err) => {
        this.error = err.status === 404 ? this.getTranslation('something_went_wrong') : err.message;
      })
      .finally(() => {
        this.$emit('loading', false);
        this.gaEvent({
          category: 'acquiring',
          action: 'Ретрай - продлить с новой карты',
          qs_qr: this.$route.query.token ? 'qr' : 'qs',
        });
      });
    return result?.last_payment?.confirmation?.confirmation_url || '';
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.payment-retry-actions {
  .controls {
    padding: 24px 0 0;
    border-top: 1px solid var(--alpha-dark-3);
    border-bottom: 1px solid var(--alpha-dark-3);

    .buttons,
    .method {
      margin-bottom: 24px;
    }
  }

  .buttons {
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: 0;

    .button:last-child {
      margin-right: 0;
    }

    @include mobile {
      .button {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;

        + .button {
          margin-top: 8px;
          margin-left: 0;
        }
      }

      .button span {
        width: 100%;
      }
    }

    @include tablet {
      justify-content: flex-start;
    }
  }

  .method {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  .method-text {
    margin-right: 16px;
  }

  .primary {
    margin-right: 18px;
    margin-bottom: 0;
  }

  .try-next-day-note {
    margin-top: 40px;
  }

  .error {
    width: 100%;
    margin-top: 16px;
  }
}
</style>
