import { TRegistration } from 'src/store/registration/types';

const initialState: TRegistration = {
  currentOfferId: '',
  confirmationUrl: '',
  vodCaption: 'Кинотеатры: ivi, Megogo, AMEDIATEKA, START',
  createSubscriptionError: '',
  disableTrials: false,
  autoTrialStatus: null,
  autoTrialDuration: 0,
};

export default initialState;
