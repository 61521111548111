<template>
  <div
    ref="scrollWrap"
    class="cms-layout scroll-layout-wrap"
    data-cy="scroll-wrap"
    :class="{
      loading: !showFooter,
      'player-is-visible': isPlayerVisible,
      mobile: windowWidth < 600,
      'cms-layout-santa': isSanta,
    }"
  >
    <slot v-bind:refScrollWrap="refScrollWrap" />

    <component :is="footerComponent" />
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import AddResizeListener from 'src/mixins/AddResizeListener';
import FooterComponent from 'src/components/cms/FooterComponent.vue';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { wlDetector } from 'src/utils';
import { Ref } from 'vue-property-decorator';

@Component({
  components: { FooterComponent },
})
export default class CmsLayout extends mixins(AddResizeListener) {
  isSanta = wlDetector().isSanta;

  @Ref('scrollWrap')
  readonly refScrollWrap!: HTMLDivElement;

  // todo change 'any' once there will be proper type for 'is' attribute in component tag
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get footerComponent(): any {
    return {
      components: {
        FooterComponent,
      },
      template: this.$store.footer,
    };
  }

  get showFooter() {
    return selectors.common.showFooterSelector(this.$store);
  }

  get isPlayerVisible() {
    return selectors.player.isPlayerVisibleSelector(this.$store);
  }

  updated() {
    actions.common.showFooter(this.$store);
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }
}
</script>
