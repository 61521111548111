import Vue from 'vue';
import { TStore } from 'src/store/types';
import { howManyTimesBodyScrollWasLockedSelector } from 'src/store/common/selectors';
import { cookieStore } from 'src/utils/storage';
import { COOKIE_NAMES, NOTIFICATION_SIMPLE_HIDE_TIMEOUT } from 'src/constants';
import { TAssetTokens } from 'src/api/tokens/types';

export const setLocalTime = (store: TStore, time: number) => {
  store.common.localTime = time;
};

export const setNeedServerTimeSync = (store: TStore, val: boolean) => {
  store.common.needServerTimeSync = val;
};

export const setIsOffline = (store: TStore, val: boolean) => (store.common.isOffline = val);

export const showModal = (store: TStore) => (store.common.isModalOpen = true);

export const hideModal = (store: TStore) => (store.common.isModalOpen = false);

export const showFooter = (store: TStore) => (store.common.showFooter = true);

export const hideFooter = (store: TStore) => (store.common.showFooter = false);

// -----------------------------------------------------------------------------------------------
// <body> scroll
// -----------------------------------------------------------------------------------------------

export const resetHowManyTimesBodyScrollWasLocked = (store: TStore) => {
  store.common.howManyTimesBodyScrollWasLocked = 0;
  document?.body?.classList.remove('locked-scroll');
};

export const lockBodyScroll = (store: TStore) => {
  store.common.howManyTimesBodyScrollWasLocked++;
  if (!document?.body?.classList.contains('locked-scroll')) {
    document?.body?.classList.add('locked-scroll');
  }
};

export const unlockBodyScroll = (store: TStore) => {
  if (howManyTimesBodyScrollWasLockedSelector(store) > 0) {
    store.common.howManyTimesBodyScrollWasLocked--;
  }

  if (howManyTimesBodyScrollWasLockedSelector(store) <= 0) {
    document?.body?.classList.remove('locked-scroll');
  }
};

// -----------------------------------------------------------------------------------------------
// Notifications
// -----------------------------------------------------------------------------------------------
export const showProfileNotification = (
  store: TStore,
  text: string,
  icon: 'to-profile' | 'check-circled' | 'cross-circled' = 'check-circled'
) => {
  store.notifications.profile.push({ icon, text });
  setTimeout(() => {
    store.notifications.profile.shift();
  }, NOTIFICATION_SIMPLE_HIDE_TIMEOUT);
};

export const showNotificationAuthAndReg = (store: TStore) => {
  store.common.showNotificationAuthAndReg = false;
  // in case notification was already shown,
  // you should hide it first and then show it again right away
  // in order to trigger notification's animation
  setTimeout(() => {
    store.common.showNotificationAuthAndReg = true;
  }, 0);
};

export const hideNotificationAuthAndReg = (store: TStore) => {
  store.common.showNotificationAuthAndReg = false;
};

export const setShowNotificationWithDetails = (store: TStore, val: boolean) => {
  store.common.showNotificationWithDetails = val;
};

export const setShowNotificationSubscriptionPaymentError = (store: TStore, val: boolean) => {
  store.common.showNotificationSubscriptionPaymentError = val;
};

export const setShowNotificationRetry = (store: TStore, val: boolean) => {
  store.common.showNotificationRetry = val;
};

export const setShowNotificationReview = (store: TStore, val: boolean) => {
  store.common.showNotificationReview = val;
};

// -----------------------------------------------------------------------------------------------
// Tokens
// -----------------------------------------------------------------------------------------------

export const saveAssetTokens = async (store: TStore, tokens: TAssetTokens) => {
  for (const [key, value] of Object.entries(tokens)) {
    if (!key) {
      return;
    }
    cookieStore.set(COOKIE_NAMES[key], value, {
      // since expiresAt's value is the same for all tokens,
      // we can just get it from audienceToken
      expires: new Date(value.expiresAt),
    });
    Vue.set(store.common.assetTokens, key, value);
  }
};

export const removeAssetTokens = async (store: TStore) => {
  cookieStore.remove(COOKIE_NAMES.audienceToken);
  Vue.set(store.common.assetTokens, 'audienceToken', undefined);
  cookieStore.remove(COOKIE_NAMES.tvAssetToken);
  Vue.set(store.common.assetTokens, 'tvAssetToken', undefined);
};
