<template>
  <div class="continue-watching row" :class="theme">
    <ProgramPreview
      class="col-desktop-4 col-tablet-4 col-mobile-4"
      :src="src"
      :title="programTitle"
      :timeline-position="timelinePosition"
      :width="416"
      :height="234"
      @click="goToPause"
    />
    <div class="program-info-wrap col-desktop-8 col-tablet-4 col-mobile-4">
      <BadgeSequoia :text="textForBadge" :icon="IconBackToPause" />
      <div class="title" v-html="programTitle" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ProgramPreview from 'src/components/tv-channels/ProgramPreview.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import IconBackToPause from 'src/svg/player/back-to-pause.svg';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { DateTime } from 'src/utils/time/date-time';
import { EVENTS } from 'src/constants';

@Component({
  components: { ProgramPreview, BadgeSequoia },
})
export default class ContinueWatching extends SequoiaComponent {
  IconBackToPause = IconBackToPause;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  channelId!: string;

  @Prop({ required: true })
  src!: string;

  @Prop({ required: true })
  programTitle!: string;

  @Prop({ required: true })
  pauseTime!: string;

  @Prop({ required: true })
  timelinePosition!: number;

  @Prop({ default: true })
  redirectToPlayer!: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get textForBadge() {
    return `${this.getTranslation('pause_from')} ${this.pauseTime}`;
  }

  get lastPause() {
    return selectors.pauses.lastTvPause(this.$store);
  }

  get epochDay() {
    return DateTime.toEpochDay(new Date(this.lastPause.time));
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  get isPageChannelOpen() {
    return selectors.tvChannels.isPageChannelOpenSelector(this.$store);
  }

  async goToCatalogPage() {
    await this.$router.push(`/channels/now/${this.channelId}/watch`);
  }

  async goToPause() {
    actions.tvChannels.hideChannelDetails(this.$store);

    if (this.isPageChannelOpen || this.redirectToPlayer) {
      this.$store.player.video.playingTimeMs = this.lastPause.time;
      await this.goToCatalogPage();
    } else if (this.currentChannelId === this.channelId) {
      actions.player.setIsLive(this.$store, false);
      actions.player.updatePlayingTime(this.$store, this.lastPause.time);
      this.$events.emit(EVENTS.player.reloadStream);
      actions.player.setPickedEpochDay(this.$store, this.epochDay);
      actions.player.showPlayer(this.$store);
      actions.player.expandPlayer(this.$store);
      actions.player.lockScroll(this.$store);
    } else {
      await actions.tvChannels.selectChannel(
        this.$store,
        this.channelId,
        this.$events,
        true,
        false,
        this.lastPause.time
      );
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.continue-watching {
  display: flex;
  padding: 16px 16px 16px 4px;
  cursor: pointer;
  border-radius: 8px;
  transition: background var(--ease-out) 0.25s;

  &:hover {
    .program-preview {
      transform: scale(1.04);
    }
  }

  &:active {
    .program-preview {
      transform: scale(1.02);
    }
  }

  .program-info-wrap {
    padding-top: 12px;

    @include mobile {
      padding-top: 8px;
    }

    .badge {
      margin-bottom: 8px;
    }

    .title {
      @include heading5;

      @include mobile {
        @include heading6;
      }
    }
  }

  // -----------------------------------------------
  // Theme Colors
  // -----------------------------------------------

  &.light {
    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-light-3);

        .program-preview {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  &.dark {
    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-3);

        .program-preview {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
