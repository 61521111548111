<template>
  <DropdownSequoia
    v-if="shouldShowSorting"
    v-slot="{ value, opened, disabled, toggle, icon }"
    :disabled="disableSorting"
    :list="sortings"
    :transform-native-select="true"
    data-cy="sorting"
    class="channels-sorting"
    @select="selectSorting"
  >
    <ButtonDropdown
      class="vod-sorting"
      data-cy="channels"
      size="small"
      width="content"
      :floating="true"
      :placeholder="getTranslation('sorting')"
      :disabled="disabled"
      :icon="icon ? icon : IconSort"
      :open="opened"
      :value="value"
      @click="toggle"
    />
  </DropdownSequoia>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import IconSort from 'src/svg/sort.svg';
import { Prop } from 'vue-property-decorator';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';

@Component({
  components: {
    DropdownSequoia,
    ButtonDropdown,
  },
})
export default class ChannelsSorting extends SequoiaComponent {
  IconSort = IconSort;

  @Prop()
  sortings!: TDropdownItem[];

  @Prop({ default: false })
  disableSorting!: boolean;

  get shouldShowSorting() {
    return this.sortings && this.sortings.length;
  }

  selectSorting(item: TDropdownItem) {
    this.$emit('sortChannels', item);
  }
}
</script>
