import { TTvChannels } from 'src/store/tv-channels/types';
import { TV_SPECIAL_GENRES } from 'src/constants';

const initialState: TTvChannels = {
  channelsNotForPurchase: {},
  currentGenre: TV_SPECIAL_GENRES.recommended,
  favorites: {},
  genres: [],
  isModalChannelOpen: false,
  isPageChannelOpen: false,
  isChannelTabAboutOpen: false,
  isChannelTabEpgOpen: false,
  isChannelsCatalogListOpen: false,
  isChannelsCatalogNowOpen: false,
  loaded: false,
  loading: false,
  list: {},
  listWithOnAirPrograms: [],
  openChannelId: '',
  openChannelIdsHistory: [],
  modalChannelId: '',
  recentAndFavLoaded: false,
  recentAndFavLoading: false,
  recentlyWatched: {},
  recommended: {},
  searchQuery: '',
};

export default initialState;
