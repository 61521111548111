<template>
  <button
    type="button"
    class="button-file-download"
    :class="[$store.theme, { 'pointer-events-none': isLoading }]"
    :data-ctype="$options.name"
    :data-cname="componentName"
    :title="description"
    @click="download"
  >
    <LoaderSpinner v-if="isLoading" class="loader-for-bfd" />

    <span class="icon-wrap">
      <IconSVG :svg="IconDownload" />
    </span>

    <span class="info">
      <span class="description" v-html="description" />
      <span class="meta">
        <span class="ext" v-text="extText" />
        <span v-if="size" class="separator">|</span>
        <span v-if="size" class="size" v-text="size" />
      </span>
    </span>
  </button>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import downloadFile from 'downloadjs';
import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import { bytesToKiloBytes } from 'src/utils/number';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import IconDownload from 'src/svg/download.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { TTranslations } from 'src/store/translations/types';

@Component({
  components: { LoaderSpinner, IconSVG },
})
export default class ButtonFileDownload extends mixins(Global, CmsComponent) {
  IconDownload = IconDownload;
  isLoading = false;
  content?: {
    translation?: TTranslations;
    link?: string;
    file?: TTranslations<{
      url: string;
      originalname: string;
      size: number;
    }>;
    name?: TTranslations;
  } | null = null;

  get description() {
    return this.getByLanguage(this.content?.translation);
  }

  get link() {
    return this.content?.link;
  }

  get fileSrc() {
    return this.link || this.getByLanguage(this.content?.file)?.url;
  }

  get name() {
    return (
      this.getByLanguage(this.content?.name) ||
      this.getByLanguage(this.content?.file)?.originalname.split('.').shift() ||
      'file'
    );
  }

  get ext() {
    return this.fileSrc?.split('.').pop();
  }

  get extText() {
    return `.${this.ext}`;
  }
  get size() {
    const size = this.getByLanguage(this.content?.file)?.size;
    return !this.link && size ? bytesToKiloBytes(size) : '';
  }

  download() {
    const src = this.fileSrc;
    if (!src) {
      return;
    }
    if (this.ext === 'html') {
      const popup = window.open(src, '_blank');
      if (popup) {
        popup.focus();
      }
      return false;
    }

    this.isLoading = true;
    const x = new XMLHttpRequest();
    x.open('GET', src, true);
    x.responseType = 'blob';
    x.onload = (e) => {
      const r = (e.target as any)?.response;
      this.isLoading = false;
      downloadFile(r, this.name, r.type);
    };
    x.send();
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.button-file-download {
  position: relative;
  display: inline-block;
  max-width: 250px;
  padding: 12px 20px 12px 60px;
  background: var(--c-overlay-brand-custom-1);
  border: 0;
  border-radius: 8px;
  transition: var(--ease-out) 0.15s;

  &:hover,
  &:active {
    background-color: var(--c-overlay-brand-custom-2);
  }

  .icon-wrap {
    position: absolute;
    top: 50%;
    left: 16px;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    outline: none;

    .icon {
      color: var(--c-text-on-brand-primary);
    }
  }

  .info {
    display: block;
    text-align: left;
    @include body2;

    .description {
      display: block;
      @extend %truncate-after-1-line;
    }

    .meta {
      .separator {
        padding: 0 8px;
      }
    }
  }

  &.light {
    &:hover .icon-wrap {
      background-color: var(--c-light-brand-lighter);
    }

    &:active .icon-wrap {
      background-color: var(--c-light-brand-darker);
    }

    .icon-wrap {
      background-color: var(--c-light-brand);
    }

    .meta {
      color: var(--c-light-font-tertiary);

      .separator {
        color: var(--c-light-font-secondary);
      }
    }
  }

  &.dark {
    &:hover .icon-wrap {
      background-color: var(--c-dark-brand-lighter);
    }

    &:active .icon-wrap {
      background-color: var(--c-dark-brand-darker);
    }

    .icon-wrap {
      background-color: var(--c-dark-brand);
    }

    .meta {
      color: var(--c-dark-font-tertiary);

      .separator {
        color: var(--c-dark-font-secondary);
      }
    }
  }
}
</style>
