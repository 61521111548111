import { localStore } from 'src/utils/storage';

// https://stackoverflow.com/a/26078207/2393499
const consoleLogger = (color: string, prefix: string) => {
  if (process?.env?.NODE_ENV !== 'production' || !!localStore.get('debug')) {
    return Function.prototype.bind.call(
      console.log,
      console,
      `%cseq[${prefix}]`,
      `color: ${color}; font-weight: bold;`
    );
  }
  return Function();
};

const logger = (namespace: string) => ({
  error: consoleLogger('red', namespace),
  warning: consoleLogger('yellow', namespace),
  info: consoleLogger('green', namespace),
});

export default logger;
