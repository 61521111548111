import { TStore } from 'src/store/types';
import { BRANDING_METHODS_DEFAULT } from 'src/constants';
import { TTitleBrandingMethod } from 'src/store/branding-methods/types';

export const getCurrentBrandingMethods = (store: TStore, methods: TTitleBrandingMethod[]) => {
  const currentBrandingMethods = methods.sort(
    (a: { priority: number }, b: { priority: number }) => a?.priority - b?.priority
  )[0]?.name;

  const defaultMethod = store.brandingMethods?.find(
    (method: any) => method.customId === BRANDING_METHODS_DEFAULT
  );

  if (!currentBrandingMethods) {
    return defaultMethod?.options;
  }

  if (currentBrandingMethods && store.brandingMethods && store.brandingMethods.length > 0) {
    const method = store.brandingMethods?.find(
      (method: any) => method.customId === currentBrandingMethods
    );

    if (!method) {
      return defaultMethod?.options;
    }

    return method?.options;
  }

  return [];
};
