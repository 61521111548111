import Blowfish from 'blowfish';

const BS = 8;

// implements logic of python's pycrypto::Crypto.Uril.strxor
const strxor = (a: string, b: string) => {
  if (a.length !== b.length)
    throw new Error(`strxor: string lengths are not equal. a: ${a}. b: ${b}`);

  return a
    .split('')
    .map((el, i) => String.fromCharCode(a.charCodeAt(i) ^ b.charCodeAt(i)))
    .join('');
};

// implements logic of python's string.decode("hex")
const decodeHex = (k: string) => {
  if (k.length % 2 !== 0) throw new Error('hex string length is not odd');
  let s = '';
  for (let i = 0; i < k.length - 1; i += 2) {
    const num = parseInt(k[i] + k[i + 1], 16);
    s += String.fromCharCode(num);
  }
  return s;
};

const repeatString = (s: string, n: number) => {
  let str = '';
  for (let i = 0; i < n; i++) {
    str += s;
  }
  return str;
};

// implements logic of some python script which definitely works
// didn't try to understand this logic, just translated it from python to ts
export class IVICMACBF {
  cipher: Blowfish;
  k1: string;
  k2: string;

  constructor(keys: string[]) {
    this.cipher = new Blowfish(decodeHex(keys[0]));
    this.k1 = decodeHex(keys[1]);
    this.k2 = decodeHex(keys[2]);
  }

  calculateSign(data: string) {
    let prev = repeatString('\x00', BS);

    const len = data.length;

    const lastIndex = len % BS === 0 ? len - BS : len - (len % BS);

    for (let i = 0; i < lastIndex; i += BS) {
      prev = decodeHex(this.cipher.encrypt(strxor(data.slice(i, i + BS), prev)));
    }

    let last = data.slice(lastIndex);

    let k;
    if (last.length === BS) {
      k = this.k1;
    } else {
      k = this.k2;
      last += '\x80' + repeatString('\x00', BS - last.length - 1);
    }

    return this.cipher.encrypt(strxor(strxor(last, prev), k)).toLowerCase();
  }
}
