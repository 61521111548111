import axios from 'axios';
import { be, fe, TReqConfig } from 'src/api/utils';
import { COOKIE_NAMES, URLS } from 'src/constants';
import { getAppVersionParamsFlattened } from 'src/utils/app-version';
import {
  TAuthEmptyResponse,
  TAuthProvider,
  TAuthRegion,
  TAvailableLogin,
  TContactsVerificationMethod,
  TContactsVerificationStart,
  TContactsVerificationVerify,
  TLoginContacts,
  TLoginResponse,
  TMegafonAccount,
  TPhoneInfoV1,
  TPhoneInfoV2,
  TRegisterWithToken,
} from './types';
import { Login } from 'src/models/ts/accounts';
import { PromoCodeInfoResponse } from 'src/models/ts/promocodes/v1/promocodes';
import { smotreshkaRegistrationResult } from 'src/models/ts/registration/v1/registration';

export const login = async (config: TReqConfig = {}): Promise<TLoginResponse> =>
  fe.post(URLS.login, config);

// TODO add types
export const QRLogin = async (config: TReqConfig = {}) => fe.post(URLS.QRLogin, config);

export const logout = async (config: TReqConfig = {}): Promise<TAuthEmptyResponse> =>
  fe.get(URLS.logout, config);

export const getAccount = async (config: TReqConfig = {}): Promise<Login> =>
  fe.get(URLS.account, config);

// TODO add types
export const registerByPhone = async (config: TReqConfig = {}) =>
  fe.post(URLS.registration.byPhone, config);

// TODO add types
export const restorePassword = async (config: TReqConfig = {}) => {
  config.params = getAppVersionParamsFlattened();
  return be.post(URLS.restorePassword, config);
};

// TODO add types
export const restorePasswordByLink = async (
  { id, accountId }: { id: string; accountId: string },
  config: TReqConfig
) => fe.put(URLS.restorePasswordByLink(id, accountId), config);

export const contactsVerification = {
  getMethods: async (
    config: TReqConfig = {}
  ): Promise<{ methods: TContactsVerificationMethod[] }> =>
    fe.get(URLS.contactsVerification.methods, config),
  startWithAccountSession: async (config: TReqConfig = {}): Promise<TContactsVerificationStart> =>
    fe.post(URLS.contactsVerification.startWithAccountSession, config),
  start: async (config: TReqConfig = {}): Promise<TContactsVerificationStart> => {
    return be.post(URLS.contactsVerification.start, config);
  },
  startRecaptcha: async (config: TReqConfig = {}): Promise<TContactsVerificationStart> =>
    fe.post(URLS.contactsVerification.startRecaptcha, config),
  verify: async (config: TReqConfig = {}): Promise<TContactsVerificationVerify> =>
    fe.post(URLS.contactsVerification.verify, config),
  retry: async (config: TReqConfig = {}): Promise<TContactsVerificationStart> =>
    fe.post(URLS.contactsVerification.retry, config),
  setPhone: async (config: TReqConfig = {}) => fe.post(URLS.contactsVerification.setPhone, config),
  unlinkPhone: async (config: TReqConfig = {}) =>
    fe.post(URLS.contactsVerification.unlinkPhone, config),
};

export const availableLogins = async (config: TReqConfig = {}): Promise<TAvailableLogin[]> =>
  fe.get(URLS.availableLogins, config);

export const loginWithVerifiedContacts = async (config: TReqConfig = {}): Promise<TLoginContacts> =>
  await fe.post(URLS.loginWithVerifiedContacts, config);

export const sendPassword = async (config: TReqConfig = {}): Promise<TAuthEmptyResponse> =>
  await fe.post(URLS.sendPassword, config);

export const getRegions = async (config: TReqConfig = {}): Promise<{ regions: TAuthRegion[] }> =>
  fe.get(URLS.regions, config);

export const getProviders = async (
  config: TReqConfig = {}
): Promise<{ providers: TAuthProvider[] }> => fe.get(URLS.providers, config);

export const registerWithToken = async (config: TReqConfig = {}): Promise<TRegisterWithToken> =>
  await fe.post(URLS.register, config);

// !!
export const registerMobileWithToken = async (config: TReqConfig = {}) =>
  await fe.post(URLS.registerMobile, config);

export const getPhoneInfoV1 = async (config: TReqConfig = {}): Promise<TPhoneInfoV1> =>
  await fe.post(URLS.phoneInfoV1, config);

export const getPhoneInfoV2 = async (config: TReqConfig = {}): Promise<TPhoneInfoV2> =>
  await fe.get(URLS.phoneInfoV2, config);

export const mtsSendSms = async (config: TReqConfig = {}): Promise<TAuthEmptyResponse> =>
  await be.post(URLS.mtsby.sendSms, config);

export const mtsSmsAuth = async (config: TReqConfig = {}): Promise<TLoginContacts> =>
  await fe.post(URLS.mtsby.auth, config);

export const promoCodeCheck = async (config: TReqConfig = {}): Promise<PromoCodeInfoResponse> =>
  await fe.get(URLS.promoCodeCheck, config);

export const registerWithPromoCode = async (
  config: TReqConfig = {}
): Promise<smotreshkaRegistrationResult> => await fe.post(URLS.registerWithPromoCode, config);

export const uztelAuth = async (config: TReqConfig = {}): Promise<TLoginContacts> =>
  await fe.post(URLS.uztel.auth, config);

export const registerWithPromoCodeMobile = async (
  config: TReqConfig = {}
): Promise<smotreshkaRegistrationResult> => await fe.post(URLS.registerWithPromoCodeMobile, config);

export const registerSimPassword = async (config: TReqConfig = {}): Promise<TAuthEmptyResponse> =>
  await fe.post(URLS.registerSimPassword, config);

export const getMegafonAccount = async (sessionId: string): Promise<TMegafonAccount> => {
  const response = await axios({
    url: `${URLS.megafonAuth.server}${URLS.megafonAuth.accountInfo}`,
    method: 'GET',
    headers: {
      Cookie: `${COOKIE_NAMES.megafonSessionId}=${sessionId};`,
    },
  });
  return response.data;
};

export const contactsVerificationSimPassword = async (
  config: TReqConfig = {}
): Promise<TContactsVerificationVerify> =>
  await fe.post(URLS.contactsVerificationSimPassword, config);

export const getMegafonJwtToken = async (sessionId: string): Promise<string> => {
  const account = await getMegafonAccount(sessionId);
  return account?.player_analytics?.user_info?.jwt || '';
};

export const megafonLoginByToken = async (jwt: string) =>
  await fe.post(URLS.megafonMigrationJwt(jwt));
