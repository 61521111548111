<template>
  <img
    :src="srcWithHash"
    :srcset="srcSetWithHash"
    :alt="alt"
    :title="title"
    onerror="this.style.display='none'"
  />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class ImageWithHash extends Vue {
  @Prop()
  src!: string;

  @Prop()
  srcset!: { src: string; density?: string }[];

  @Prop({ default: '' })
  alt?: string;

  @Prop({ default: '' })
  title?: string;

  hash = '';

  get mTime() {
    return this.$store.imagesMTimes[this.src] || new Date().getTime();
  }

  get srcWithHash() {
    return `${this.src}?v${this.mTime}`;
  }

  get srcSetWithHash() {
    if (!this.srcset) {
      return '';
    }
    return this.srcset
      .map(({ src, density }) => {
        const mTime = this.$store.imagesMTimes[src];
        return `${src}?v${mTime} ${density}`;
      })
      .join(', ');
  }
}
</script>
