import * as account from 'src/store/account/selectors';
import * as adultOnboarding from 'src/store/adult-onboarding/selectors';
import * as appInfo from 'src/store/app-info/selectors';
import * as archive from 'src/store/archive/selectors';
import * as authAndReg from 'src/store/auth-and-reg/selectors';
import * as brandingMethods from 'src/store/branding-methods/selectors';
import * as channelCollections from 'src/store/channel-collections/selectors';
import * as common from 'src/store/common/selectors';
import * as home from 'src/store/home/selectors';
import * as payment from 'src/store/payment/selectors';
import * as pauses from 'src/store/pauses/selectors';
import * as personal from 'src/store/personal/selectors';
import * as player from 'src/store/player/selectors';
import * as providerInfo from 'src/store/provider-info/selectors';
import * as QS from 'src/store/quick-subscribe/selectors';
import * as registration from 'src/store/registration/selectors';
import * as search from 'src/store/search/selectors';
import * as seo from 'src/store/seo/selectors';
import * as siteConfig from 'src/store/site-config/selectors';
import * as translations from 'src/store/translations/selectors';
import * as tvChannels from 'src/store/tv-channels/selectors';
import * as tvCurrentChannel from 'src/store/tv-current-channel/selectors';
import * as tvEpg from 'src/store/tv-epg/selectors';
import * as vod from 'src/store/vod/selectors';
import * as reviews from 'src/store/reviews/selectors';
import * as packages from 'src/store/packages/selectors';
import * as metrics from 'src/store/metrics/selectors';

export const selectors = {
  account,
  adultOnboarding,
  appInfo,
  archive,
  authAndReg,
  brandingMethods,
  channelCollections,
  common,
  home,
  payment,
  pauses,
  personal,
  player,
  providerInfo,
  QS,
  registration,
  search,
  seo,
  siteConfig,
  translations,
  tvChannels,
  tvCurrentChannel,
  tvEpg,
  vod,
  reviews,
  packages,
  metrics,
};
