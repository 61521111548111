import { iviLight } from 'src/api/utils';
import { IVICMACBF } from 'src/utils/ivi-crypto';
import { storage } from 'src/utils';
import { STORE_KEYS } from 'src/constants';
import { TMessageToIvi } from 'src/api/ivi/types';

const getIviUid = () => {
  const storeKey = STORE_KEYS.vod.ivi.uid;
  const uid = storage.get(storeKey);
  if (uid) {
    return uid;
  }
  const newUid = Math.random().toString(10).slice(2);
  storage.set(storeKey, newUid);
  return newUid;
};

const savedCmacs: Record<string, IVICMACBF> = {};

const getCmac = (pmParams: {
  appVersion?: string;
  cmakKey?: string;
  cmakK1?: string;
  cmakK2?: string;
}) => {
  const keys = [pmParams.cmakKey || '', pmParams.cmakK1 || '', pmParams.cmakK2 || ''];
  const k = keys.join('_');

  if (!savedCmacs[k]) {
    savedCmacs[k] = new IVICMACBF(keys);
  }

  return savedCmacs[k];
};

const sendMessageToIvi = async (
  pmParams: {
    [p: string]: string | undefined;
    id?: string;
    iviSession?: string;
    appVersion?: string;
  },
  payload: {
    params: (number | { uid: string; session: string; app_version: number })[];
    method: string;
  }
): Promise<TMessageToIvi> => {
  const timestampRequest = await iviLight.post(`/`, {
    data: `{"method":"da.timestamp.get","params":[]}`,
  });

  const timestamp = timestampRequest.result;
  const body = JSON.stringify(payload);

  const cmac = getCmac(pmParams);
  const sign = cmac.calculateSign(timestamp + body);

  const result = await iviLight.post(
    `/?app_version=${pmParams.appVersion}&ts=${timestamp}&sign=${sign}`,
    { data: body }
  );

  if (result.error) throw result.error;
  return result;
};

export const getContentInfo = async (pmParams: {
  [x: string]: string | undefined;
  id?: string;
  iviSession?: string;
  appVersion?: string;
}) => {
  const payload = {
    params: [
      parseInt(pmParams.id || '', 10),
      {
        uid: getIviUid(),
        session: pmParams.iviSession || '',
        app_version: parseInt(pmParams.appVersion || '', 10),
      },
    ],
    method: 'da.content.get',
  };

  const { result } = await sendMessageToIvi(pmParams, payload);

  return result;
};
