<template>
  <div
    class="status-message responsive-container mt-64 mt-tablet-0 mt-mobile-32"
    :class="theme"
    data-cy="status-message"
  >
    <h3 class="flex-center-vertical">
      <IconSVG :svg="icon" :class="status ? 'success' : 'error'" :size="48" />
      <span v-text="title" />
    </h3>

    <div v-if="text" class="text body1" v-text="text" />

    <ButtonDefault size="medium" @click="$emit('clickButton')">
      <span v-html="buttonText || getTranslation('back_to_main')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCheckCircled from 'src/svg/check-circled.svg';
import IconCrossCircled from 'src/svg/cross-circled.svg';

@Component({
  components: { ButtonDefault, IconSVG },
})
export default class StatusMessage extends SequoiaComponent {
  IconCheckCircled = IconCheckCircled;
  IconCrossCircled = IconCrossCircled;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  status!: boolean;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  text!: string;

  @Prop()
  buttonText?: string;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get icon() {
    return this.status ? this.IconCheckCircled : this.IconCrossCircled;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.status-message {
  padding-bottom: 96px;
  margin-top: 128px;

  @include mobile-and-tablet {
    padding-bottom: 64px;
  }

  .icon {
    margin-right: 8px;
    pointer-events: none;
  }

  h3 {
    margin-bottom: 24px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;

      .icon {
        margin-bottom: 8px;
      }
    }
  }

  .text {
    max-width: 520px;
    padding-bottom: 48px;
  }

  button {
    margin-bottom: 0;

    @include mobile {
      width: 100%;
    }
  }
}
</style>
