import Vue from 'vue';
import * as api from 'src/api';
import { TStore } from 'src/store/types';
import { resetCollections } from 'src/store/vod/actions';
import { resetGenre } from 'src/store/tv-channels/actions';
import { hideNotificationAuthAndReg, removeAssetTokens, saveAssetTokens } from '../common/actions';
import logger from 'src/utils/logger';
import { clearFeCookie, setFeCookie } from 'src/utils/cookie';
import { getByLanguage } from 'src/utils/language';
import { Login } from 'src/models/ts/accounts';
import { TLoginContacts } from 'src/api/auth/types';
import { loadAssetTokens } from 'src/api/tokens';
import { cookieStore, localStore } from 'src/utils/storage';
import { COOKIE_NAMES } from 'src/constants';
import { loadProviderInfo } from 'src/store/provider-info/actions';

const log = logger('auth');

const putAccountToStoreAndSetFeCookie = async (
  store: TStore,
  result: Login | TLoginContacts,
  ssrCookiesToSet?: Record<string, string>
) => {
  setFeCookie(result, ssrCookiesToSet);
  Vue.set(store, 'account', await api.auth.getAccount());
  resetCollections(store);
  await removeAssetTokens(store);
};

export const recheckAuthState = async (store: TStore) => {
  const actualAccount = await api.auth.getAccount();
  const actualId = actualAccount?.id;

  if (actualId && actualId !== store.account?.id) {
    log.warning(
      `accountId mismatch. FE: ${actualId}, store: ${store.account?.id}. Store account has been synced with FE`
    );
    await logout(store);
  } else if (!actualId && store.account && !!store.feSessionId) {
    log.warning(`accountId mismatch. FE: undefined, store: ${store.account?.id}. Logging out`);
    // for some reason there is a set of feCookie which doesn't correspond to actual session on FE
    await logout(store);
  }
};

export const login = async (
  store: TStore,
  email: string,
  password: string,
  ssrCookiesToSet?: Record<string, string>
) => {
  const data = { email, password };
  const result = await api.auth.login({ data });
  await putAccountToStoreAndSetFeCookie(store, result, ssrCookiesToSet);
  hideNotificationAuthAndReg(store);
  await saveAssetTokens(store, await loadAssetTokens());
};

// TODO type code properly
export const QRLogin = async (store: TStore, code: any) => {
  resetCollections(store);
  await removeAssetTokens(store);
  await api.auth.QRLogin({ data: { code } });
  await saveAssetTokens(store, await loadAssetTokens());
};

export const logout = async (store: TStore) => {
  await api.auth.logout();
  localStore.set('ageConfirmed', false);
  localStore.set('profileConfirmed', false);
  cookieStore.set('welcomeScreenConfirmed', '0');
  cookieStore.remove(COOKIE_NAMES.megafonJwt);
  cookieStore.remove(COOKIE_NAMES.megafonJwtChecked);
  cookieStore.remove(COOKIE_NAMES.profileConfirmationPeriod);
  clearFeCookie();
  resetGenre(store);
  resetCollections(store);
  await removeAssetTokens(store);
};

export const getAvailableLogins = async (store: TStore) => {
  store.availableLogins = await api.auth.availableLogins({
    params: { 'contacts-session-token': store.verificationResult.sessionToken },
  });
};

// TODO type login properly
export const loginWithVerifiedContacts = async (
  store: TStore,
  login: any,
  ssrCookiesToSet?: Record<string, string>
) => {
  store.loginResult = await api.auth.loginWithVerifiedContacts({
    params: { 'contacts-session-token': store.verificationResult.sessionToken },
    data: { login },
    timeout: 30000, // 30 seconds (could take pretty long time)
  });
  await loadProviderInfo(store);
  setFeCookie(store.loginResult, ssrCookiesToSet);
  Vue.set(store, 'account', await api.auth.getAccount());
  resetCollections(store);
  await removeAssetTokens(store);
};

// TODO type login properly
export const sendPassword = async (store: TStore, login: any) => {
  store.sendPasswordResult = await api.auth.sendPassword({
    params: { 'contacts-session-token': store.verificationResult.sessionToken },
    data: { login },
  });
};

export const getProviderName = (store: TStore) => store.account?.provider?.provider?.name || '';

export const mtsSmsAuth = async (
  store: TStore,
  phone: string,
  code: string,
  ssrCookiesToSet?: Record<string, string>
) => {
  const data = { phone, code };
  const result = await api.auth.mtsSmsAuth({ data });
  await putAccountToStoreAndSetFeCookie(store, result, ssrCookiesToSet);
};

export const uztelAuth = async (store: TStore, ssrCookiesToSet?: Record<string, string>) => {
  const result = await api.auth.uztelAuth();
  await putAccountToStoreAndSetFeCookie(store, result, ssrCookiesToSet);
};

export const megafonJwtTokenAuth = async (
  store: TStore,
  jwt: string,
  ssrCookiesToSet?: Record<string, string>
) => {
  const result = await api.auth.megafonLoginByToken(jwt);
  await putAccountToStoreAndSetFeCookie(store, result, ssrCookiesToSet);
};

export const getTranslationForAuthAndRegTrial = (store: TStore, key: string) => {
  if (!store.authAndReg?.trial) {
    return '';
  }

  const trialString = store.authAndReg.trial[key];
  if (!trialString) {
    return '';
  }
  const dict = trialString?.translationDict;
  return getByLanguage(dict, store.languageCode) || `{${key}}`;
};

export const showLoginSuccessNotification = (store: TStore) => {
  store.authAndReg.showLoginSuccessNotification = true;
};
export const hideLoginSuccessNotification = (store: TStore) => {
  store.authAndReg.showLoginSuccessNotification = false;
};
