<template>
  <div class="qr-payment-result-container with-side-and-bottom-padding dark">
    <QuickSubscribeBackground />
    <div v-show="loading" class="loading" data-cy="loader">
      <LoaderSpinner />
    </div>
    <div v-show="!loading" class="content">
      <span v-if="withIcon" class="icon-wrap">
        <IconSVG
          :svg="isError ? IconCrossCircled : IconCheckCircled"
          :class="isError ? 'color-dark-error' : 'color-dark-success'"
          :size="48"
        />
      </span>
      <div v-if="title" class="h2" v-html="title" />
      <div v-if="description" class="description" v-html="description" />
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCrossCircled from 'src/svg/cross-circled.svg';
import IconCheckCircled from 'src/svg/check-circled.svg';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';

@Component({
  components: {
    LoaderSpinner,
    QuickSubscribeBackground,
    IconSVG,
  },
})
export default class QSPayment extends SequoiaComponent {
  IconCrossCircled = IconCrossCircled;
  IconCheckCircled = IconCheckCircled;

  @Prop({ default: false })
  withIcon!: boolean;

  @Prop({ default: false })
  isError!: boolean;

  @Prop()
  title?: string;

  @Prop()
  description?: string;

  @Prop({ default: false })
  loading!: boolean;

  mounted() {
    if (this.$route?.query.token) {
      this.gaEvent({
        category: 'acquiring',
        action: this.isError ? 'Ошибка оплаты' : 'Оплата прошла успешно',
        qs_qr: 'qr',
        error_text: this.isError ? 'token_failed' : 'success_screen',
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.qr-payment-result-container {
  min-height: 510px;

  .content {
    position: relative;
    z-index: var(--z-2);
  }

  .icon-wrap {
    margin-bottom: 16px;
  }

  .h2 {
    margin-bottom: 24px;
  }

  .description {
    margin-bottom: 16px;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
