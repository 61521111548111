<template>
  <a
    v-if="href"
    class="button default overlay-default-before"
    :class="[
      theme,
      view,
      size,
      { 'full-width': fullWidth },
      { 'with-icon': withIcon },
      { 'with-white-bg': withWhiteBg },
      { 'is-external': isExternal },
    ]"
    :href="href"
    :data-cy="dataCyPrefixed"
    :target="isExternal ? '_blank' : '_self'"
  >
    <span class="content">
      <slot />
    </span>
  </a>

  <router-link
    v-else-if="to"
    class="button default overlay-default-before"
    :class="[
      theme,
      view,
      size,
      { 'full-width': fullWidth },
      { 'with-icon': withIcon },
      { 'with-white-bg': withWhiteBg },
    ]"
    :to="to"
    :data-cy="dataCyPrefixed"
  >
    <span class="content">
      <slot />
    </span>
  </router-link>

  <button
    v-else
    ref="button"
    :type="type"
    class="button default overlay-default-before"
    :class="[
      theme,
      view,
      size,
      { 'full-width': fullWidth },
      { 'with-icon': withIcon },
      { loading: isLoading },
      { 'with-white-bg': withWhiteBg },
      { 'text-left': textAlign === 'left' },
    ]"
    :disabled="disabled || isLoading"
    :data-cy="dataCyPrefixed"
    @click="onClick($event)"
    @focus="onFocus($event)"
  >
    <LoaderSpinner v-if="isLoading" />
    <span class="content" :style="{ visibility: `${isLoading ? 'hidden' : 'visible'}` }">
      <slot />
    </span>
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';

@Component({
  components: {
    LoaderSpinner,
  },
})
export default class ButtonDefault extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: 'submit' })
  type!: 'submit' | 'button';

  @Prop({ default: 'primary' })
  view!: 'primary' | 'secondary' | 'tertiary' | 'custom' | 'danger';

  @Prop()
  href?: string;

  @Prop()
  to?: string;

  // small - 40, medium - 48, large - 56
  // small is always default and included in base CSS,
  // but it is not forced via class, unless the prop is passed
  @Prop()
  size?: 'small' | 'medium' | 'large';

  @Prop({ default: false })
  withIcon!: boolean;

  @Prop()
  disabled?: boolean;

  @Prop()
  isLoading?: boolean;

  @Prop({ default: false })
  fullWidth!: boolean;

  @Prop({ default: false })
  isExternal!: boolean;

  @Prop()
  textAlign?: string;

  @Prop()
  dataCy?: string;

  // This is a temporary prop
  // TODO delete after trial design refactoring
  @Prop()
  withWhiteBg?: boolean;

  @Ref('button')
  refButton?: HTMLButtonElement;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get dataCyPrefixed() {
    return this.dataCy ? `button-default-${this.dataCy}` : '';
  }

  onClick(e: MouseEvent) {
    this.$emit('click', e, this.refButton);
  }

  onFocus(e: MouseEvent) {
    this.$emit('focus', e, this.refButton);
  }
}
</script>

<style lang="scss">
@import 'src/components/ui/buttons/button-default';
</style>
