<template>
  <div
    v-if="currentCategory && currentCategory.title"
    class="title-slider-more-titles"
    :class="theme"
  >
    <div v-for="titlesByGenre in titlesByGenres" :key="titlesByGenre.genre.id" class="block">
      <SectionHeader
        class="with-side-padding mb-24 mb-mobile-16"
        :text="getSectionHeaderText(titlesByGenre)"
      />

      <ContentSlider
        :key="refreshKey"
        :theme-forced="theme"
        type="adaptive"
        @gaScroll="gaScroll(getSectionHeaderText(titlesByGenre))"
      >
        <TilePoster
          v-for="title in titlesByGenre.titles"
          :key="title.id"
          class="col-desktop-2 col-desktop-s-3 col-tablet-2 col-mobile-2"
          :countries="title.countries"
          :duration="title.duration"
          :genres="title.genres"
          :height-proportion="posterHeightProportion(sourceId)"
          :image="title.posters[0].path"
          :link="preparePosterLink(title, sourceId)"
          :logo="getSourceLogoUrl()"
          :original-title="title.originalTitle"
          :rating-imdb="title.ratingImdb"
          :rating-kinopoisk="title.ratingKp"
          :source="sourceId"
          :title="title.title"
          :title-branding-methods="title.brandingMethods"
          :width-proportion="posterWidthProportion(sourceId)"
          :years="title.years"
          @click="
            gaEvent({
              category: 'vod',
              action: 'Клик по тайтлу в стрипе',
              strip_name: getSectionHeaderText(titlesByGenre),
              vod_name: sourceId,
            });
            switchTitleModal(title.id);
          "
        />
      </ContentSlider>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import TilePoster from 'src/components/ui/tiles/TilePoster.vue';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { Watch } from 'vue-property-decorator';
import * as api from 'src/api';
import shuffle from 'lodash/shuffle';
import Vod from 'src/mixins/Vod';
import Global from 'src/mixins/Global';
import { TitleDetails } from 'src/mixins/TitleDetails';
import { getChannelLogo } from 'src/utils/channel';
import { TChannelEnhanced } from 'src/api/channels/types';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import { getSeoBot } from 'src/utils/platform-detector';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { prepareTitleLink } from 'src/utils/vod';

@Component({
  components: {
    TilePoster,
    ContentSlider,
    SectionHeader,
  },
})
export default class TitleSliderMoreTitles extends mixins(Vod, TitleDetails, Global) {
  // titlesByGenres: Array<{
  //   genre: { id: string; title: string };
  //   titles: Array<TTitlePreview>;
  // }> = [];

  titlesByGenres: any = [];
  refreshKey = 0;

  @Watch('currentCategoryId')
  async onCurrentCategoryIdChange() {
    await this.loadTitlesByGenres();
  }

  @Watch('modalTitleId')
  async onTitleIdChange() {
    this.titlesByGenres = [];
    await this.loadTitlesByGenres();
    this.refreshKey++;
  }

  get languageCode() {
    return selectors.common.languageCodeSelector(this.$store);
  }

  get hasGenres() {
    return !!this.modalTitle?.preview?.genres;
  }

  preparePosterLink(title: TVODTitlePreviewEnhanced, sourceId: string) {
    return prepareTitleLink(title, sourceId);
  }

  getSectionHeaderText(titlesByGenre: { genre: { title: string } }) {
    return `${this.getTranslation('more')}   ${
      this.hasGenres ? this.currentCategory.title.toLowerCase() : ''
    } ${this.getTranslation('in_genre')}: ${titlesByGenre.genre.title}`;
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  async serverPrefetch() {
    if (getSeoBot()) {
      await this.loadTitlesByGenres();
    }
  }

  async mounted() {
    if (this.currentCategory?.id) {
      await this.loadTitlesByGenres();
    }
  }

  posterWidthProportion(source: string) {
    return selectors.vod.posterWidthProportionSelector(this.$store, source);
  }

  getSourceLogoUrl() {
    const channelId = this.modalTitle?.preview?.availableOnTvChannels?.[0]?.channelId;
    const channel = channelId ? this.getChannelById(channelId) : null;

    return channel
      ? this.getChannelLogo(channel)
      : actions.vod.getSourceLogoUrl(this.$store, this.sourceId);
  }

  posterHeightProportion(source: string) {
    return selectors.vod.posterHeightProportionSelector(this.$store, source);
  }

  async switchTitleModal(titleId: string) {
    await actions.vod.showTitleDetails(this.$store, this.sourceId, titleId, true);
  }

  async loadTitlesByGenres() {
    const loadTitlesBy = this.modalTitle?.preview?.genres || this.modalTitle?.preview?.categories;

    if (!loadTitlesBy) {
      return;
    }

    const promises = loadTitlesBy.map((item: { id: string }) =>
      api.vod.getCategoryTitles({
        categoryId: this.currentCategory?.id || loadTitlesBy[0].id,
        sourceId: this.sourceId,
        genreIds: this.hasGenres ? item.id : '',
        limit: 12,
      })
    );

    const titlesByGenres: Array<Array<any>> = await Promise.all(promises);
    this.titlesByGenres = loadTitlesBy.map((genre, index) => ({
      genre,
      titles: shuffle(titlesByGenres[index]?.filter((title) => title.id !== this.modalTitleId)),
    }));

    this.titlesByGenres = this.titlesByGenres?.filter((t: any) => {
      return t.titles.length > 0;
    });
  }

  gaScroll(stripName: string) {
    this.gaEvent({ category: 'archive', action: 'Скролл стрипа', strip_name: stripName });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.block {
  padding-bottom: 64px;

  @include mobile-and-tablet {
    padding-bottom: 48px;
  }

  h4 {
    margin-bottom: 16px;
  }
}

// --------------------------------------------
// Theme Colors
// --------------------------------------------
.title-slider-more-titles {
  &.light {
    background-color: var(--c-light-100);
  }

  &.dark {
    background-color: var(--c-dark-150);
  }
}
</style>
