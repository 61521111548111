<template>
  <component :is="tag" class="section-header">
    <IconSVG :class="colorThemed" :svg="icon" /><span :class="theme" v-html="text" />
  </component>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';

@Component({ components: { IconSVG } })
export default class SectionHeader extends SequoiaComponent {
  @Prop({ default: 'h3' })
  tag!: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  @Prop({ required: true })
  text!: string;

  @Prop()
  icon?: Record<string, unknown>;

  @Prop({ default: '' })
  color?: 'brand' | '';

  get colorThemed() {
    return this.color ? `color-${this.theme}-${this.color}` : this.theme;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.section-header {
  .icon {
    margin-right: 12px;
    cursor: default;

    @include mobile-and-tablet {
      margin-right: 8px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    color: var(--c-light-font-primary);
  }

  &.dark {
    color: var(--c-dark-font-primary);
  }
}
</style>
