<template>
  <ContentSlider
    v-if="calendar"
    class="epg-slider"
    type="fixed"
    :theme-forced="theme"
    :highlight-visible="false"
    :count-slides-change-step="1"
  >
    <button
      v-for="day in calendar"
      :key="day.epochDay"
      class="epg-slide"
      :class="{ active: day.epochDay === pickedEpochDay }"
      type="button"
      @click="setPickedEpochDay(day.epochDay)"
    >
      <span class="day-of-week h3" v-text="getDayOfWeek(day)" />
      <span class="date body2" v-text="getMonthAndDay(day.date)" />
    </button>
  </ContentSlider>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import * as epg from 'src/utils/epg';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import { TProgramEnhanced } from 'src/api/channels/types';
import dayjs from 'dayjs';
import { DateTime } from 'src/utils/time/date-time';

interface TEpgCalendarDay {
  date: Date; // Sat Jul 23 2022 00:00:00 GMT+0300 (Moscow Standard Time)
  dayOfWeek: string; // Сб
  dayString: string; // "23 июля"
  epochDay: number; // 19196
  programs: TProgramEnhanced[];
}

@Component({
  components: { ContentSlider },
})
export default class EpgSlider extends SequoiaComponent {
  todayEpochDay = DateTime.toEpochDay(new Date());

  @Prop({ required: true })
  channelId!: string;

  get pickedEpochDay() {
    return selectors.player.pickedEpochDaySelector(this.$store);
  }

  get calendar() {
    return epg.getCalendarByChannelId(this.$store, this.channelId);
  }

  async serverPrefetch() {
    await this.loadEpgForChannel();
  }

  async mounted() {
    actions.player.resetPickedEpochDay(this.$store);
    await this.loadEpgForChannel();
  }

  setPickedEpochDay(day: number) {
    this.$emit('setPickedEpochDay');
    actions.player.setPickedEpochDay(this.$store, day);
  }

  getDayOfWeek(slide: TEpgCalendarDay) {
    return slide.epochDay === this.todayEpochDay
      ? this.getTranslation('today')
      : slide.epochDay === this.todayEpochDay + 1
      ? this.getTranslation('tomorrow')
      : slide.dayOfWeek;
  }

  getMonthAndDay(date: Date) {
    return dayjs(date).format('DD.MM');
  }

  async loadEpgForChannel() {
    if (this.channelId) {
      await actions.tvEpg.loadEpgForChannel(this.$store, this.channelId);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.epg-slider {
  width: 100%;
  margin: 0 auto;

  &.content-slider {
    &::v-deep {
      .slider-items {
        margin: 0;
      }
    }
  }

  &::v-deep {
    .inner {
      overflow: visible;
    }
  }

  .epg-slide {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: fit-content;
    padding-right: 16px;
    padding-left: 16px;
    margin: 0 12px;
    border-bottom-style: solid;
    border-bottom-width: 4px;

    .day-of-week {
      display: block;
      width: 100%;
      margin-bottom: 0;
    }

    .date {
      display: block;
      padding-bottom: 8px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .epg-slide {
      color: var(--c-light-font-secondary);

      &.active {
        color: var(--c-light-font-primary);
        border-bottom-color: var(--c-light-brand);
      }
    }
  }

  &.dark {
    .epg-slide {
      color: var(--c-dark-font-secondary);

      &.active {
        color: var(--c-dark-font-primary);
        border-bottom-color: var(--c-dark-brand);
      }
    }
  }
}
</style>
