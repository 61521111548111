import Vue from 'vue';
import Component from 'vue-class-component';
import { gaEvent, gaEventOnce, yaMetrikaGoal, GaEventParams } from 'src/utils/metrics';

@Component
export default class Metrics extends Vue {
  gaEvent = (params: GaEventParams) => gaEvent(params, this.$store);
  gaEventOnce = (params: GaEventParams) => gaEventOnce(params, this.$store);
  yaMetrikaGoal = yaMetrikaGoal;
}
