<template>
  <div class="notification-review">
    <NotificationWithDetails
      v-if="currentNotification === START"
      :title="getTranslation('star_select_title')"
      message=""
      @hide="hideNotification()"
    >
      <div class="stars mb-6">
        <span v-for="(star, i) in stars" class="star" @click="rate(stars.length - i)">
          <IconSVG :svg="star" size="48" />
        </span>
      </div>
      <div class="additional-action">
        <a class="link nav-hidden" @click="replyLater" v-html="getTranslation('reply_later')" />
      </div>
    </NotificationWithDetails>

    <NotificationWithDetails
      v-else-if="currentNotification === ASK"
      :icon="IconHeart"
      :title="getTranslation('have_problems_title')"
      :message="getTranslation('have_problems_message')"
      @hide="hideNotification"
    >
      <ButtonDefault view="primary" theme-forced="dark" @click="goToSupport">
        <span v-html="getTranslation('go_to_support')" />
      </ButtonDefault>
      <div class="additional-action">
        <a class="link nav-hidden" @click="skip" v-html="getTranslation('skip')" />
      </div>
    </NotificationWithDetails>

    <NotificationWithDetails
      v-else-if="currentNotification === THANKS"
      :icon="IconStar"
      icon-color-class="color-brand"
      :title="getTranslation('leave_feedback_title')"
      :message="getTranslation('leave_feedback_message')"
      @hide="hideNotification"
    >
      <ButtonDefault
        v-for="item in reviewItems"
        :key="item._id"
        with-icon
        view="tertiary"
        theme-forced="dark"
        class="mb-8"
        @click="goToSite(item.link)"
      >
        <IconSVG :svg-path="getIconUrl(item)" />
        <span v-html="getByLanguage(item.title)" />
      </ButtonDefault>
    </NotificationWithDetails>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { NotificationBaseComponent } from 'src/components/ui/notifications/NotificationBaseComponent';
import { actions } from 'src/store/actions';
import IconStar from 'src/svg/star.svg';
import IconStarGrade1 from 'src/svg/star-grade-1.svg';
import IconStarGrade2 from 'src/svg/star-grade-2.svg';
import IconStarGrade3 from 'src/svg/star-grade-3.svg';
import IconStarGrade4 from 'src/svg/star-grade-4.svg';
import IconStarGrade5 from 'src/svg/star-grade-5.svg';
import IconHeart from 'src/svg/heart.svg';
import IconSVG from 'src/components/IconSVG.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import NotificationWithDetails from 'src/components/ui/notifications/NotificationWithDetails.vue';
import { selectors } from 'src/store/selectors';
import { localStore } from 'src/utils/storage';
import { LOCAL_STORAGE_NAMES } from 'src/constants';
import { TReviewsItem } from 'src/api/cms/types';

@Component({
  components: {
    ButtonDefault,
    NotificationWithDetails,
    IconSVG,
  },
})
export default class NotificationReview extends NotificationBaseComponent {
  IconStar = IconStar;
  IconHeart = IconHeart;
  stars = [IconStarGrade5, IconStarGrade4, IconStarGrade3, IconStarGrade2, IconStarGrade1];
  START = 'start';
  ASK = 'ask';
  THANKS = 'thanks';
  currentNotification = this.START;
  GAActions = {
    [this.START]: 'star_select',
    [this.THANKS]: 'leave_feedback',
    [this.ASK]: 'have_problems_alert',
  };

  get reviewSettings() {
    return selectors.reviews.reviewsSettingsSelector(this.$store);
  }

  get reviewItems() {
    return this.reviewSettings.items;
  }

  async mounted() {
    this.ga('star_select', 'alert_shown');
  }

  ga(action: string, label: string) {
    this.gaEvent({ category: 'feedback_collect', action: `${action} ${label}` });
  }

  replyLater() {
    actions.common.setShowNotificationReview(this.$store, false);

    this.ga('star_select', `answer_later`);
  }

  hideNotification() {
    localStore.set(LOCAL_STORAGE_NAMES.reviewsDisabled, true);
    actions.common.setShowNotificationReview(this.$store, false);

    this.ga(this.GAActions[this.currentNotification], `modal_close`);
  }

  rate(stars: number) {
    localStore.set(LOCAL_STORAGE_NAMES.reviewsDisabled, true);
    this.currentNotification = stars < 4 ? this.ASK : this.THANKS;

    this.ga('star_select', `choose_${stars}`);
    this.ga(this.GAActions[this.currentNotification], 'alert_shown');
  }

  goToSupport() {
    location.href = 'https://ask.smotreshka.tv';

    this.ga('have_problems_alert', 'go_to_support');
  }

  skip() {
    actions.common.setShowNotificationReview(this.$store, false);

    this.ga('have_problems_alert', 'skip');
  }

  goToSite(link: string) {
    actions.common.setShowNotificationReview(this.$store, false);
    this.ga('leave_feedback', link);
    window.open(link, '_blank');
  }

  getIconUrl(item: TReviewsItem) {
    return typeof item.icon === 'object' ? item.icon[this.languageCode]?.url : '';
  }
}
</script>

<style lang="scss">
@import 'src/styles/placeholders-and-mixins/typography';
@import 'notification-base';

.notification-review {
  .stars {
    margin-bottom: 16px;
    text-align: center;
    direction: rtl;

    .star {
      display: inline-block;
      color: var(--c-dark-brand);
      cursor: pointer;
      opacity: 0.18;

      @include devices-with-hover {
        &:hover,
        &:hover ~ .star {
          opacity: 1;

          .icon {
            svg {
              filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
            }
          }
        }
      }
    }
  }

  .additional-action {
    @include body2;

    text-align: center;
  }
}
</style>
