<template>
  <hgroup class="title-hgroup">
    <component v-if="title" :is="tag" class="h2" v-html="title" />
    <h6 v-if="originalTitle" class="color-secondary" v-text="originalTitle" />
  </hgroup>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class TitleHgroup extends Vue {
  @Prop()
  title?: string;

  @Prop()
  originalTitle?: string;

  @Prop({ default: 'h1' })
  tag!: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.title-hgroup {
  .h2 {
    margin-bottom: 8px;

    @include mobile-and-desktop-s {
      @include heading3;
    }
  }

  h6 {
    margin-bottom: 0;
  }
}
</style>
