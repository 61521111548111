import { TStore } from 'src/store/types';
import { availableTemplates } from 'src/utils/quick-subscribe';
import { PaymentSystem, SubscriptionStatus } from 'src/models/ts/offer';

export const isModalOpenSelector = (store: TStore) => store.QS.isModalOpen;

export const currentSourceSubscriptionsSelector = (store: TStore) => {
  if (store.QS.currentSourceId) {
    return store.QS?.subscriptionsBySource?.[store.QS?.currentSourceId];
  }
};

export const allSubscriptionsSelector = (store: TStore) => store.QS.allSubscriptions;

export const allAvailableSubscriptionsSelector = (store: TStore) =>
  store.QS.allAvailableSubscriptions;

export const existingSubscriptionsSelector = (store: TStore) => store.QS.existingSubscriptions;

/**
 * User can subscribe if subscription type is base, and it has sub20-yakassa-mvp subscription method
 * https://lifestream.atlassian.net/browse/SEQ-1628?focusedCommentId=22659
 */
export const allSubscriptionsOptionsTypeBaseSelector = (store: TStore) =>
  allSubscriptionsSelector(store)?.subscriptionOptions.filter(
    (subscription) =>
      subscription.type === 'base' &&
      !!subscription.subscriptionMethods.find(
        (subscriptionMethod) => subscriptionMethod.name === 'sub20-yakassa-mvp'
      )
  );

export const currentStepSelector = (store: TStore) =>
  currentSourceSubscriptionsSelector(store)?.steps[store.QS.currentStepIndex];

export const currentStepComponentNameSelector = (store: TStore) => currentStepSelector(store)?.name;

export const getStepIdxByName = (store: TStore, name: string) =>
  currentSourceSubscriptionsSelector(store)?.steps?.findIndex((v) => v.name === name);

export const currentTemplateSelector = (store: TStore) => {
  const prioritySubscription = prioritySubscriptionSelector(store);
  const renderingMethod = prioritySubscription?.renderingMethod;
  return renderingMethod && renderingMethod.name
    ? (availableTemplates as Record<string, any>)[renderingMethod.name]
    : { component: null };
};

export const currentTemplateComponentSelector = (store: TStore) =>
  currentTemplateSelector(store)?.component;

export const prioritySubscriptionSelector = (store: TStore) =>
  currentSourceSubscriptionsSelector(store)?.subscriptionOptions[0];

export const otherSubscriptionsSelector = (store: TStore) =>
  currentSourceSubscriptionsSelector(store)?.subscriptionOptions?.slice(1);

export const allSubscriptionOptionsSelector = (store: TStore) =>
  currentSourceSubscriptionsSelector(store)?.subscriptionOptions || [];

export const subscriptionOptionsWithoutExistingSelector = (store: TStore) => {
  const currentSourceSubscriptions = currentSourceSubscriptionsSelector(store);
  const existingOfferIds = currentSourceSubscriptions?.existingSubscriptions?.map(
    (subscription) => subscription.offerId
  );
  return allSubscriptionOptionsSelector(store)?.filter(
    (one) => !existingOfferIds?.includes(one.offerId)
  );
};

export const existingSubscriptionInRetrySelector = (store: TStore) => {
  const subscriptions =
    currentSourceSubscriptionsSelector(store)?.existingSubscriptions?.filter(
      (subscription) =>
        subscription.paymentSystemType === PaymentSystem.Yakassa.toLowerCase() &&
        subscription.subscriptionStatus === SubscriptionStatus.IN_RETRY.toLowerCase()
    ) || [];

  return subscriptions.length ? subscriptions[0] : null;
};

export const paymentRetrySelector = (store: TStore) => store.QS.paymentRetry;

export const showNotificationSelector = (store: TStore) => store.QS.showNotification;

export const notificationTextSelector = (store: TStore) => store.QS.notificationText;
