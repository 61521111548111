// import logger from 'src/utils/logger';

// const log = logger('platform-detector');

import Bowser from 'bowser';

export const BROWSERS = {
  safari: 'Safari',
  chrome: 'Chrome',
  firefox: 'Firefox',
  opera: 'Opera',
  ie: 'Internet Explorer',
  edge: 'Microsoft Edge',
  yandex: 'Yandex Browser',
};

const OS_LIST = {
  ios: 'ios',
  android: 'android',
  macos: 'macos',
  windows: 'windows',
};

export const PLATFORMS = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
};

export const SEO_BOT = {
  google: 'Googlebot',
  yandex: 'YandexBot',
};

const huaweiDevicesRegex =
  /HUAWEI|HMS|ALP-|AMN-|ANA-|ANE-|ANG-|AQM-|ARS-|ART-|ATU-|BAC-|BLA-|BRQ-|CAG-|CAM-|CAN-|CAZ-|CDL-|CDY-|CLT-|CRO-|CUN-|DIG-|DRA-|DUA-|DUB-|DVC-|ELE-|ELS-|EML-|EVA-|EVR-|FIG-|FLA-|FRL-|GLK-|HMA-|HW-|HWI-|INE-|JAT-|JEF-|JER-|JKM-|JNY-|JSC-|LDN-|LIO-|LON-|LUA-|LYA-|LYO-|MAR-|MED-|MHA-|MLA-|MRD-|MYA-|NCE-|NEO-|NOH-|NOP-|OCE-|PAR-|PIC-|POT-|PPA-|PRA-|RNE-|SEA-|SLA-|SNE-|SPN-|STK-|TAH-|TAS-|TET-|TRT-|VCE-|VIE-|VKY-|VNS-|VOG-|VTR-|WAS-|WLZ-|WKG-|MLD-|RTE-|NAM-|NEN-|BAL-|JAD-|JLN-|YAL|MRA/i;

export const getPlatformDetector = () => {
  const isServer = typeof window === 'undefined';
  const ua =
    (isServer
      ? global.userAgent
      : window.navigator.userAgent || navigator.userAgent || navigator.vendor) || 'unknown';
  const parser = Bowser.getParser(ua);
  return { ua, parser };
};
export const getPlatformType = () => getPlatformDetector().parser.getPlatformType(true);
export const getOsVersion = () => getPlatformDetector().parser.getOSVersion();
export const getOsName = () => getPlatformDetector().parser.getOSName(true);
export const getBrowserName = () => getPlatformDetector().parser.getBrowserName();
export const getAndroidDeviceType = () => {
  const { ua } = getPlatformDetector();
  const checks = {
    artel: /Artel/i.test(ua),
    huawei: huaweiDevicesRegex.test(ua),
    oppo: /OPPO/i.test(ua),
    vivo: /vivo/i.test(ua),
  };
  const names = Object.keys(checks) as Array<keyof typeof checks>;
  return names.find((name) => checks[name]) || '';
};
export const getPlatformName = () => {
  const { ua } = getPlatformDetector();
  const platformChecks = {
    // iOS
    ipad: /iPad/i.test(ua),
    ipod: /iPod/i.test(ua),
    iphone: /iPhone/i.test(ua),

    // Android
    android: /Android/i.test(ua),

    // NON Android
    winphone: /Windows Phone/i.test(ua),

    // TV box
    dune: /arora/i.test(ua) || /dune/i.test(ua),
    eltex: /eltex/i.test(ua),
    mag: /mag/i.test(ua),

    // Smart-TV
    netcast: /netcast/i.test(ua) || /LG SimpleSmart/i.test(ua),
    samsung: /smart-tv/i.test(ua),
    tizen: /Tizen/i.test(ua),
    webos: /Web0S/i.test(ua),

    // PC
    pc: /Opera/i.test(ua) || /Mozilla/i.test(ua),
  };
  const names = Object.keys(platformChecks) as Array<keyof typeof platformChecks>;
  return names.find((name) => platformChecks[name]) || '';
};

export const getDeviceFlags = () => {
  const platform = getPlatformName();
  const androidDeviceType = getAndroidDeviceType();
  const platformType = getPlatformType();
  const isServer = typeof window === 'undefined';
  return {
    isAndroid: platform === 'android',
    isAndroidWithoutGooglePlay:
      ['artel', 'huawei', 'oppo', 'vivo', 'winphone'].indexOf(androidDeviceType) !== -1,
    isIphone: platform === 'iphone',
    isArtel: androidDeviceType === 'artel',
    isHuawei: androidDeviceType === 'huawei',
    isOppo: androidDeviceType === 'oppo',
    isVivo: androidDeviceType === 'vivo',
    isWinphone: platform === 'winphone',

    isMobile: platformType === PLATFORMS.mobile,
    isTablet: platformType === PLATFORMS.tablet,
    isiPadPro: isServer
      ? false
      : navigator.userAgent.match(/Mac/) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2,
    isSmartTv: ['netcast', 'webos', 'samsung', 'tizen'].indexOf(platform) !== -1,
    isStb: ['dune', 'eltex', 'mag'].indexOf(platform) !== -1,
    isPc: platform === 'pc' || !platform,
  };
};

export const getOsFlags = () => {
  const OS = getOsName();
  return {
    isIos: OS === OS_LIST.ios,
    isMacos: OS === OS_LIST.macos,
  };
};

export const getBrowserFlags = () => {
  const browserName = getBrowserName();
  return {
    isMicrosoft: [BROWSERS.ie, BROWSERS.edge].includes(browserName),
    isSafari: browserName === BROWSERS.safari,
    isEdge: browserName === BROWSERS.edge,
    isYandex: browserName === BROWSERS.yandex,
  };
};

export const getDeviceType = () => {
  const deviceFlags = getDeviceFlags();
  return deviceFlags.isMobile
    ? 'phone'
    : deviceFlags.isTablet
    ? 'tablet'
    : deviceFlags.isiPadPro
    ? 'iPadPro'
    : deviceFlags.isSmartTv || deviceFlags.isStb
    ? 'smarttv'
    : 'pc';
};

export const getSeoBot = () => {
  const { ua } = getPlatformDetector();

  return (
    new RegExp(`${SEO_BOT.google}|${SEO_BOT.yandex}`, 'i').test(ua) && typeof window === 'undefined'
  );
};

export const isAndroidWebview = () => {
  // https://developer.chrome.com/docs/multidevice/user-agent/#webview-on-android
  const { ua } = getPlatformDetector();
  return ua.includes('wv') && getDeviceFlags().isAndroid;
};

// export const isIosWebview = () => {
//   // todo find reliable way to detect webview in ios, https://developer.chrome.com/docs/multidevice/user-agent/#chrome-for-ios Chrome detects as WebView on iPhone which is not what we want
// };

// export const isWebView = () => isAndroidWebview() || isIosWebview();
