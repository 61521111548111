import { convertToMilliseconds } from 'src/utils/time/convert-time';
import { TPlayerErrorCodes } from 'src/store/player/types';

export const GTM_COUNTERS: { [key: string]: string } = {
  smotreshka: 'GTM-WPZ98KR',
  sevensky: 'GTM-T46QHD6',
  akado: 'GTM-53MFTSM',
  almatel: 'GTM-WK32S8',
  galam: 'GTM-55LM4S5',
  altel: 'GTM-TFTRHLW',
  tele2: 'GTM-T2B8CLB',
  kcell: 'GTM-MDW82CK',
  otv: 'GTM-KH38BLZ',
  kt: 'GTM-KHX9PC4',
  mtsby: 'GTM-TCKXXF5',
  alma: 'GTM-MSB6XV7',
  n3: 'GTM-MKBMC24',
  megacom: 'GTM-TH245VD',
  intersv: 'GTM-5BQR23B',
  ttk: 'GTM-N5FNZNG',
  uztel: 'GTM-MJDRMF9',
  megafon: 'GTM-P2SXTPV',
  mtr: 'GTM-PDNS7PB',
  ins: 'GTM-PKRGSQB',
  ucell: 'GTM-5TS3BB6',
};

const API_VOD_PREFIX = `/vod/v2`;

export const URLS = {
  account: `/v2/account`,
  login: `/v2/login`,
  QRLogin: `/v2/login/qr`,
  logout: `/logout`,
  genres: `/genres`,
  channels: `/channels`,
  channelsV2: (tvAssetToken: string) => `/tv/v2/channels?tv-asset-token=${tvAssetToken}`,
  medias: (tvAssetToken: string) => `/tv/v2/medias?tv-asset-token=${tvAssetToken}`,
  channel: (channelId: string) => `/channels/${channelId}`,
  epg: (channelId: string, period: string) => `/channels/${channelId}/programs?period=${period}`,
  profileRestrictions: `/v2/settings/profile/restrictions`,
  recommendedChannels: `/channels-recommendations/v1`,
  recentAndFavChannels: `/walls/1`,
  channelsNotForPurchase: '/subscription-options/tv/channels-not-for-purchase/v2',
  playbackInfo: `/playback-info`,
  dvrAvailability: (channelId: string) => `/playback-info/${channelId}/dvr-availability`,
  appInfo: `/app-info`,
  speedTests: '/app-info/speed-tests/v2',
  favorites: `/favorites`,
  profile: `/account/profile`,
  managingLogin: `/v2/managing-login`,
  managingLogout: '/v2/managing-logout',
  managingLoginWithVerifiedContacts: `/v2/managing-login/with-verified-contacts`,
  managingLoginMethods: '/v2/managing-login/methods',
  megafonMigrationJwt: (jwt: string) => `/v2/login/methods/megafon-migration-jwt?jwt=${jwt}`,
  currentSubscriptions: `/account/v3/current-subscriptions`,
  changeProfile: `/account/change-profile`,
  changeProfileV2: `/v2/account/change-profile`,
  createProfile: `/v2/account/profile`,
  changePassword: `/account/change-password`,
  updateProfile: (id: string) => `/v2/account/profile/${id}`,
  deleteProfile: (id: string) => `/v2/account/profile/${id}`,
  getProfile: (id: string) => `/v2/account/profile/${id}`,
  vod: {
    sources: `${API_VOD_PREFIX}/`,
    categories: (sourceId: string) => `${API_VOD_PREFIX}/${sourceId}/categories`,
    collections: (sourceId: string) => `${API_VOD_PREFIX}/${sourceId}/collections`,
    collectionTitles: (sourceId: string, collectionId: string) =>
      `${API_VOD_PREFIX}/${sourceId}/collections/${collectionId}/titles`,
    titles: (sourceId: string) => `${API_VOD_PREFIX}/${sourceId}/titles`,
    titlesMeta: (sourceId: string) => `${API_VOD_PREFIX}/${sourceId}/titles-meta`,
    searchTitles: (sourceId: string) => `${API_VOD_PREFIX}/${sourceId}/search-titles`,
    title: (sourceId: string, titleId: string) => `${API_VOD_PREFIX}/${sourceId}/titles/${titleId}`,
    season: (sourceId: string, titleId: string, seasonId: string) =>
      `${API_VOD_PREFIX}/${sourceId}/titles/${titleId}/seasons/${seasonId}`,
    seasonEpisodes: (sourceId: string, titleId: string, seasonId: string) =>
      `${API_VOD_PREFIX}/${sourceId}/titles/${titleId}/seasons/${seasonId}/episodes`,
    allEpisodes: (sourceId: string, titleId: string) =>
      `${API_VOD_PREFIX}/${sourceId}/titles/${titleId}/episodes`,
    playback: (titleId: string) => `/playback/vod/v2/${titleId}`,
    playbackUpdate: (sessionId: string, close: boolean) =>
      `/playback-updates/vod/v1?pbs_id=${sessionId}&close_pbs=${close}`,
    ivi: {
      lightServer: `https://api.ivi.ru/light`,
      lightServerMock: 'http://localhost:8081/ivi',
      session: (session: string) => `/v2/account/services/ivi?session=${session}`,
      playback: (titleId: string) => `/playback/vod/ivi/${titleId}`,
    },
    amedia: {
      playback: (titleId: string) => `/playback/vod/amedia2/v1/${titleId}`,
    },
    megogo: {
      playback: (titleId: string) => `/playback/vod/megogo/v1/${titleId}`,
    },
  },
  providerInfo: (id: string) => `/providers/v1/internal-providers/${id}/info`,
  providerInfoV2: `/providers/v2/internal-provider-info`,
  registration: {
    byPhone: '/account/register',
  },
  restorePassword: '/v2/account/restore-password',
  restorePasswordByLink: (id: string, accountId: string) =>
    `/account/restore-password/${accountId}/${id}`,
  contactsVerification: {
    methods: `/contacts-verification/v1/methods`,
    startWithAccountSession: `/contacts-verification/v1/start-with-account-session`,
    start: `/contacts-verification/v1/start`,
    startRecaptcha: `/contacts-verification/v1/start-recaptcha`,
    retry: `/contacts-verification/v1/retry`,
    verify: `/contacts-verification/v1/verify`,
    unlinkPhone: `/user/v1/detach-phone-with-verified-cd`,
    setPhone: `/user/v1/set-phone-with-verified-cd`,
    techSim: `/contacts-verification/v1/verify-with-password/tech-sim`,
  },
  assetTokens: `/user/v1/asset-tokens`,
  availableLogins: `/auth/v1/with-verified-contacts/available-logins`,
  register: `/registration/v1/with-verified-contacts/smotreshka/v3`,
  registerMobile: `/registration/v1/with-verified-contacts/mobile`,
  loginWithVerifiedContacts: `/v2/login/methods/with-verified-contacts`,
  sendPassword: `/change-password/v1/with-verified-contacts/methods/send-password`,
  promoCodeCheck: '/promocode/check-value/v1',
  registerWithPromoCode: '/registration/v1/with-verified-contacts/smotreshka-with-promocode/v2',
  registerWithPromoCodeMobile: '/registration/v1/with-verified-contacts/mobile-with-promocode',
  registerSimPassword: '/registration/v1/login-password/mobile-with-promocode',
  contactsVerificationSimPassword: '/contacts-verification/v1/verify-with-password/tech-sim',
  regions: `/providers/v1/regions`,
  providers: `/providers/v1/providers`,
  statistics: `/send-stats/channel-playing`,
  trialRegistrationStatus: '/trial/registration/v1/status',
  quickSubscribe: {
    allAvailableSubscriptionOptions: '/subscription-options/all-available/v2/',
    vodSubscriptionOptions: `/subscription-options/vod/v2`,
    vodSubscriptionOptionsV3: `/subscription-options/vod/v3/`,
    tvSubscriptionOptions: (channelId: string) => `/subscription-options/tv/v2/${channelId}`,
    tvSubscriptionOptionsV3: (channelId: string) => `/subscription-options/tv/v3/${channelId}`,
    createSubscriptionWithVerifiedPhone: `/account/v3/create-subscription/with-verified-phone`,
    createSubscription: `/v2/account/subscriptions`,
    createSubscriptionUpsale: '/account/v3/create-subscription/upsale/v1',
    createSubscriptionUpsaleV2: '/account/v3/create-subscription/upsale/v2',
    currentSubscriptions: '/current-subscriptions/v2',
    enableAutoRenew: (subscriptionId: string) =>
      `/generic-subscriptions/enable-auto-renew/v1/${subscriptionId}`,
    disableAutoRenew: (subscriptionId: string) =>
      `/generic-subscriptions/disable-auto-renew/v1/${subscriptionId}`,
  },
  yakassa: {
    paymentMethod: `/yakassa/v1/payment-method`,
    removePaymentMethod: `/yakassa/v1/payment-method/remove`,
    archivedSubscriptions: `/yakassa/v1/archived-subscriptions`,
    subscriptions: `/yakassa/v1/subscriptions`,
    subscription: (subscriptionId: string) => `/yakassa/v1/subscriptions/${subscriptionId}`,
    subscriptionPayments: (subscriptionId: string) =>
      `/yakassa/v1/subscriptions/${subscriptionId}/payments`,
    activateSubscription: (subscriptionId: string) =>
      `/yakassa/v1/subscriptions/${subscriptionId}/activate`,
    cancelSubscription: (subscriptionId: string) =>
      `/yakassa/v1/subscriptions/${subscriptionId}/cancel`,
    renewSubscriptionWithExistedPm: (subscriptionId: string) =>
      `/yakassa/v1/subscriptions/${subscriptionId}/renew-with-existed-pm`,
    renewSubscriptionWithNewPm: (subscriptionId: string) =>
      `/yakassa/v1/subscriptions/${subscriptionId}/renew-with-new-pm`,
    offer: (offerId: string) => `/yakassa/v1/offers/${offerId}`,
    lastPaymentFromKassa: (subscriptionId: string) =>
      `/yakassa/v1/subscriptions/${subscriptionId}/last-payment-from-kassa`,
    createSubscription: `/yakassa/v1/create-subscription/v2`,
  },
  offers: {
    listOfOffers: `/offers/v3/`,
    offer: (offerId: string) => `/offers/v3/${offerId}`,
    offerChannels: (offerId: string) => `/offers/v3/${offerId}/showcase-channels`,
  },
  phoneInfoV1: `/phone-info/v1`,
  phoneInfoV2: `/phone-info/v2`,
  bow: {
    proxy: `/buy-on-website/proxy`,
    tokenData: `/buy-on-website/token-data`,
    qsStateForTransaction: (transactionId: string) =>
      `/account/v3/qs-state-for-transaction/${transactionId}`,
  },
  pauses: {
    vod: {
      titles: '/pause/vod/v1/titles',
      title: (sourceId: string, titleId: string) => `/pause/vod/v1/titles/${sourceId}/${titleId}`,
    },
    tv: '/pauses',
  },
  home: {
    banners: '/banners/v1/home-carousel',
    promoElements: (audienceToken: string) => `/promo/v2/elements?audience-token=${audienceToken}`,
    vodCollections: (audienceToken: string) =>
      `/vod/home/collections/v2?audience-token=${audienceToken}`,
  },
  allAvailableSubscriptionOptions: '/subscription-options/all-available/v2/',
  mtsby: {
    captcha: `/captcha`,
    sendSms: `/v2/login/methods/mts-bel-sms-auth/send-sms`,
    auth: `/v2/login/methods/mts-bel-sms-auth`,
  },
  kt: {
    captcha: '/contacts-verification/v1/captcha',
  },
  uztel: {
    auth: '/v2/login/methods/uztelecom-ip',
  },
  sitemap: '*.xml',
  brandingMethods: '/brandingMethodList',
  diagnostics: {
    ip: 'https://v4.ident.me/',
  },
  premiumPageProviderBySlug: (slug: string) => `/premium-page/providers-enhancement/${slug}`,
  oldSmotreshkaPremiumPage: 'https://old.smotreshka.tv/api/connection_premium_request',
  premiumtwo: '/premiumtwo',
  megafonAuth: {
    server: 'http://bmp.megafon.tv',
    accountInfo: '/api/v10/info',
  },
  seoRules: '/seo-rules',
  ssrPath: '/s',
  redirect: '/redirect',
};

export const STORE_KEYS = {
  channelId: 'channelId',
  genreId: 'genreId',
  player: {
    mute: 'player_mute',
    volume: 'player_volume',
    language: 'player_tv_language',
    channelsSettings: 'player_tv_channelSettings',
  },
  vod: {
    titlesLimit: 'vod_titlesLimit',
    sourceId: 'vod_sourceId',
    categoryId: 'vod_categoryId',
    titleId: 'vod_titleId',
    collectionId: 'vod_collectionId',
    genreIds: 'vod_genreIds',
    pauseTimes: 'vod_pauseTimes',
    ivi: {
      uid: 'vod_ivi_uid',
    },
  },
  test: {
    feUrl: 'test_feUrl',
    showSettings: 'test_showSettings',
  },
};

export const ENABLE_CYPRESS_MOCK =
  typeof window !== 'undefined'
    ? window.ENABLE_CYPRESS_MOCK === '1'
    : process.env.CYPRESS_MOCK === '1';

export const PROMETHEUS_ID = '59db5f83bd10231640a3d9d6';
export const SPEEDNET_ID = '5a787ff7c564bbb65b482292';
export const TIGHT_VIDEO_ID = '53b159aadcdb1f426ff5ddf6';
// export const STORAGE_MAX_CAPACITY = {
//   playerTvChannelSettings: 50,
// };

export const EVENTS = {
  channelSelect: 'CHANNEL_SELECT',
  player: {
    play: 'PLAYER_PLAY',
    pause: 'PLAYER_PAUSE',
    reloadStream: 'PLAYER_RELOAD_STREAM',
    changeLanguage: 'PLAYER_CHANGE_LANGUAGE',
    fastForward: {
      start: 'PLAYER_FAST_FORWARD_START',
      stop: 'PLAYER_FAST_FORWARD_STOP',
    },
    rewind: {
      start: 'PLAYER_REWIND_START',
      stop: 'PLAYER_REWIND_STOP',
    },
  },
};

export const CHANNEL_LIST_POSTER_HEIGHT = 6 * 16;
// export const CHANNEL_LIST_SMALL_POSTER_HEIGHT = 6 * 6;

// export const ACCOUNT_CACHE_PARAMS = {
//   max: 1000,
//   maxAge: 60 * 1000,
// };

// export const TVDATA_CACHE_PARAMS = {
//   max: 1000,
//   maxAge: 10 * 60 * 1000,
// };

export const COMPONENT_TYPES = {
  LSheader: 'LSheader',
  LSfooter: 'LSfooter',

  // CMS components
  AboutPage: 'AboutPage',
  Accordion: 'Accordion',
  Advantages: 'Advantages',
  BackgroundWithText: 'BackgroundWithText',
  Banner: 'Banner',
  Button: 'Button',
  ButtonFileDownload: 'ButtonFileDownload',
  Device: 'Device',
  FooterComponent: 'FooterComponent',
  Header: 'Header',
  HeaderSequoia: 'HeaderSequoia',
  HowToWatch: 'HowToWatch',
  ImageSlider: 'ImageSlider',
  ImageTextButton: 'ImageTextButton',
  PersonalArea: 'PersonalArea',
  Tab: 'Tab',
  TabPanel: 'TabPanel',
  TextOnly: 'TextOnly',
  TvWizard: 'TvWizard',
  YoutubeVideo: 'YoutubeVideo',
};

export const PLAYBACK_METHODS = {
  ivi: 'ivi_with_integration_params',
  iviTwoSteps: 'ivi_two_steps',
  smotreshkaVodV2: 'smotreshka_vod_v2',
  smotreshkaPlayBackInfo: 'smotreshka-playback-info',
  amedia2V1: 'amedia2_v1',
  megogoV1: 'megogo_v1',
};

export const THRESHOLDS = {
  vod: {
    maxStoredPauseTimes: 50,
  },
  tv: {
    dontShowPlaylistErrorAfterSeconds: 10,
    hideOverlayAfterMilliseconds: 3000,
    shortJumpLength: 15 * 1000,
    seekStep: { normal: 10000, medium: 30000, fast: 60000 },
    seekLengthToSwitchToMedium: 20,
    seekLengthToSwitchToFast: 40,
    channelSwitchDelay: 300,
  },
  subscribes: {
    dateUTCMultiplier: 1000,
  },
};

export const CHUNK_LENGTH = 6; // in seconds

export const COOKIE_NAMES = {
  audienceToken: 'audience_token',
  feCookie: 'feCookie',
  hideRetryAlert: 'hide_retry_alert',
  languageCode: 'languageCode',
  megafonSessionId: 'SessionID',
  megafonJwt: 'megafon_jwt',
  megafonJwtChecked: 'megafon_jwt_checked',
  profileConfirmationPeriod: 'profile_confirmation_period',
  retryBlockForCard: 'retry_block_for_card',
  tvAssetToken: 'tv_asset_token',
  uaUuid: 'ua_uuid',
};

export const LOCAL_STORAGE_NAMES = {
  archivedSubs: 'archivedSubs',
  feBaseUrl: 'feBaseUrl',
  utmParameters: 'utmParameters',
  userLastItemKey: 'userMenuLastItem',
  reviewsDisabled: 'reviewsDisabled',
};

export const TV_SPECIAL_GENRES = {
  all: '',
  recommended: '__RECOMMENDED__',
  favourite: '__FAVOURITE__',
  recentlyWatched: '__LAST_WATCHED__',
  available: '__AVAILABLE__',
};

export const TV_SORTING = {
  ascendingNumber: 'ascendingNumber',
  descendingNumber: 'descendingNumber',
  ascendingName: 'ascendingName',
  descendingName: 'descendingName',
};

export const PROFILE_ACTIONS = {
  ADD: 'add',
  EDIT: 'edit',
  MANAGING: 'managing',
  CHANGE: 'change',
  DELETE: 'delete',
};

export const EPG_SLIDER_LEFT_COUNT = 3;
export const EPG_SLIDER_RIGHT_COUNT = 7;

export const CHANNEL_SLIDER_TOP_ITEM_GAP = 1.2;
export const CHANNEL_SLIDER_TOP_SELECT_GAP = 2;

export const GTM_EVENT_TIMEOUT = 100;

export const REFILL_CACHE_INTERVAL = convertToMilliseconds(5, 'second');

export const SSR_DEFAULT_HOST = '0.0.0.0';
export const SSR_DEFAULT_PORT = 8080;

export const HTTP_CODES = {
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  GONE: 410,
  PRECONDITION_FAILED: 412,
  NOT_ALLOWED_FOR_LOCATION: 451,
  IVI_NOT_ALLOWED_FOR_LOCATION: 4501,
  SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  IVI_UNAVAILABLE_ON_SITE: 4520,
  IVI_NOT_PURCHASED: 4514,
  IVI_NOT_PAID: 4516,
  EXISTING_LOGIN: 4091,
};

export const HTTP_PLAYER_BAD_RESPONSE_CODES = [
  HTTP_CODES.NOT_FOUND,
  HTTP_CODES.GONE,
  HTTP_CODES.BAD_REQUEST,
  HTTP_CODES.SERVER_ERROR,
];

export const IVI_FORBIDDEN_CODES = [
  HTTP_CODES.IVI_NOT_PAID,
  HTTP_CODES.IVI_NOT_PURCHASED,
  HTTP_CODES.IVI_UNAVAILABLE_ON_SITE,
];

export const COOKIE_EXPIRES_DATE = new Date(2137, 12, 31);

// export const CACHE_AWAIT_INTERVAL = 500;

export const REGISTRATION_SMS_CODE_LENGTH = 4;
export const MTS_AUTH_SMS_CODE_LENGTH = 5;
export const RESEND_SMS_CODE_TIMEOUT = 30;

export const PROFILE_PIN_LENGTH = 4;

export const REGISTRATION_RESEND_SMS_CODE_IN_SECONDS = 10;
export const REGISTRATION_SESSION_LIFETIME = 600000;

export const PLAYER_DEFAULT_VOLUME = 0.8;
export const TV_PLAYER_HIDE_OVERLAY_TIMEOUT = 250;

export const CHANNEL_PLAYING_STATISTIC_SAFE_TIME_IN_SECONDS = 2;

export const NOTIFICATION_SIMPLE_HIDE_TIMEOUT = 5000;

// default timeout for a single notification on the page
export const NOTIFICATION_HIDE_TIMEOUT = 4750;

export const REGISTRATION_CAPTCHA_LENGTH = 6;

export const REGISTRATION_TIMEOUT_AFTER_SIGN_IN = 3000;

export const VOD_TITLES_DEFAULT_LIMIT = 42;

export const CHANNELS_TO_SHOW_LIMIT = 12;

export const CURRENCIES = {
  ru: '₽',
};

export const SCREEN_MIN_WIDTH_FOR_SHOWING_PLAYER = 600;

export const DESKTOP_WINDOW_WIDTH = 1440;

export const TABLET_WINDOW_WIDTH = 768;

export const SUBSCRIPTION_STATUSES = {
  PENDING: 'pending',
  CANCELED: 'canceled',
  WAITING_FOR_CAPTURE: 'waiting_for_capture',
  SUCCEEDED: 'succeeded',
  ACTIVE: 'active',
  IN_RETRY: 'in_retry',
};

export const SUBSCRIPTION_STATUSES_REASONS = {
  CANCELED_BY_MERCHANT: 'canceled_by_merchant',
};

export const YAKASSA_RESPONSE_CODES = {
  SUBSCRIPTION_ALREADY_EXISTS: 'yakassa.subscription_already_exists',
  NO_VERIFIED_PHONE: 'yakassa.no_verified_phone_number',
};

export const CONTACT_VERIFICATION_OUTDATED_ERROR_CODE = 4031;
export const CONTACT_VERIFICATION_ERROR_CODE = 4032;
export const SUBSCRIPTION_FORBIDDEN_ERROR_CODE = 4033;
export const GET_TRIAL_STATUS_ERROR_CODE = 9991; // generated on frontend side

export const QS_STEP_NAMES = {
  CONFIRMED: 'TrialConfirmed',
  ERROR: 'Error',
};

export const SUBSCRIPTION_STEPS = {
  SELECT_VERIFICATION: 'SelectVerificationSubscription',
  PASSWORD_VERIFICATION: 'PasswordVerificationSubscription',
  PHONE_VERIFICATION: 'PhoneVerificationSubscription',
  DETAIL: 'SubscriptionDetails',
};

export const SUBSCRIPBE_STEP_WITH_BACK_BTN = [
  SUBSCRIPTION_STEPS.SELECT_VERIFICATION,
  SUBSCRIPTION_STEPS.PASSWORD_VERIFICATION,
  SUBSCRIPTION_STEPS.PHONE_VERIFICATION,
  SUBSCRIPTION_STEPS.DETAIL,
];

export const QS_SOURCE_TYPES = {
  VOD: 'vod',
  CHANNEL: 'channel',
};

export const EPG_TOP_MARGIN = 350;

export const DEFAULT_EPG_DISPERSION_DAYS = 5;

export const PHONE_INFO_STATUSES = {
  SUPPORTED: 'SUPPORTED',
  UNSUPPORTED: 'UNSUPPORTED',
  TECHSIM: 'TECHSIM',
};

export const MEGAFON_IS_TECHNICAL_PARAM = 'techSimPassword';

export const PLAYER_ERROR_CODES = {
  COMMON: 'common',
  DVR: 'dvr',
  PROVIDER_ERROR: 'provider-error',
  INTERNAL: 'internal',
} as const;

export const VERIFICATION_METHODS = {
  PHONE: 'phone',
  PHONE_WITH_ACCOUNT_SESSION: 'phone-with-account-session',
};

export const REGISTRATION_TRIAL_STATUSES = {
  unknown: 'unknown',
  processStarted: 'process_started',
  created: 'trial_is_created',
  skipped: 'trial_is_skipped',
};

export const HIDE_PLAYER_ALERT_DELAY = 3000; // hide player alert after N milliseconds

export const HOME_SCREEN_TILES_COUNT = 15;

export const SUBSCRIPTION_METHOD_NAMES = {
  AUTHENTICATED: 'authenticated',
  PHONE_COLLECT_INSTANT: 'sub20-phone-collect-instant',
  PHONE_COLLECT_DELAYED: 'sub20-phone-collect-delayed',
  UPSALE: 'sub20-upsale-v1',
  UPSALE_VERIFIED: 'sub20-upsale-with-verified-contacts-v1',
  UPSALE_V2: 'sub20-upsale-v2',
  UPSALE_V21: 'sub20-upsale-v21',
};

export const RENDERING_METHOD_NAMES = {
  BILLING_PERIOD: 'qs21-billing-period-option',
};

export const DEBUG_ACQUIRING_AFTER_REGISTRATION = false;

export const KEY_CODE = {
  backspace: 8,
  enter: 13,
};

export const BLANK_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const legacyRenderingMethods = ['qs10-legacy-default', 'qs20-yakassa-v2'];

export const AUTH_STEPS = {
  start: 'start',
  captcha: 'captcha',
  checkCode: 'checkCode',
  provider: 'provider',
  finish: 'finish',
  multipleLogins: 'multipleLogins',
  phoneFoundBeforeReset: 'phoneFoundBeforeReset',
  phoneFoundAfterReset: 'phoneFoundAfterReset',
  retryTrialStatus: 'retryTrialStatus',
  error: 'error',
  outdated: 'outdated',
  phoneNotFound: 'phoneNotFound',
  unlinkLogin: 'unlinkLogin',
  success: 'success',
  fail: 'fail',
  unlinkOneOfMultipleLogins: 'unlinkOneOfMultipleLogins',
  emailFinish: 'emailFinish',
  auth: 'auth',
  authMts: 'authMts',
  phoneIsNotAttached: 'phoneIsNotAttached',
  phoneUnsupported: 'phoneUnsupported',
  promoCode: 'promoCode',
  promoCodeNotFound: 'promoCodeNotFound',
  promoCodeUserExist: 'promoCodeUserExist',
  promoCodeSuccess: 'promoCodeSuccess',
  simPassword: 'simPassword',
  autoLoginFailed: 'autoLoginFailed',
  legalLinks: 'legalLinks',
  recommendProvider: 'recommendProvider',
  skipProvider: 'skipProvider',
  confirmProvider: 'confirmProvider',
};

export const AUTH_AND_REG_URLS: Record<string, string> = {
  defaultReg: '/reg',
  defaultLogin: '/login',
  defaultReset: '/reset',
  defaultCode: '/code',
};

export const CORRECT_PHONE_LENGTH = 12;

export const DEFAULT_VOD_CATEGORIES: Record<string, string> = {
  ivi: '14', // Фильмы
  amedia: '4', // Сериалы
  start: 'L3R2L2ZlYXR1cmVk', // Новинки
  megogo: '16', // Фильмы
  premier: '60ca0902b8e842c8228244c2', // Сериалы
  moretv: '4', // Сериалы
};

export const BUGSNAG_API_KEY = '9d1d92f9488aab463d31520e312baeb7';

export const VOD_SESSION_LIFETIME = 10 * 60 * 1000;
export const VOD_SESSION_QUEUE_LIMIT = 20;
export const VOD_SESSION_SEEK_EVENTS_TIMEOUT = 1000;

export const VOD_SESSION_STATE: Record<string, string> = {
  playing: 'playing',
  paused: 'paused',
  stopped: 'stopped',
};

export const VOD_SESSION_USER_EVENT: Record<string, string> = {
  start: 'start',
  timeline: 'timeline',
  displayMode: 'displayMode',
  continue: 'continue',
  stop: 'stop',
};

export const VIDEO_DISPLAY_MODE: Record<string, string> = {
  fullscreen: 'fullscreen',
  windowed: 'windowed',
  audioOnly: 'audio-only',
};

export const VOD_SERIES_DATA_RESET = {
  episodeId: '',
  episodeNum: 0,
  mediaItemId: '',
  nextEpisodeNum: 1,
  nextSeasonNum: 1,
  seasonId: '',
  seasonNum: 0,
  seasonNumForNav: 0,
};

export const VOD_VIDEO_DATA_RESET = {
  channelId: '',
  currentTime: 0,
  duration: 0,
  episodeId: '',
  iviContentInfo: undefined,
  mediaItemId: '',
  playbackMethod: '',
  playbackSession: undefined,
  seasonId: '',
  sourceId: '',
  ticksOffset: 0.21,
  title: null,
  titleId: '',
  url: undefined,
};

export const TV_PAUSE_RESET = {
  channelId: '',
  contentId: '',
  createdAt: 0,
  id: '',
  time: 0,
};

export const VOD_PAUSE_RESET = {
  createdAt: 0,
  episodeId: '',
  id: '',
  mediaItemId: '',
  mediaItemExpiresAt: 0,
  msecFromEpoch: 0,
  msecFromStart: 0,
  seasonId: '',
  titleId: '',
  totalDurationMsec: 0,
  sourceId: '',
};

export const CMS_DATA_INITIAL: typeof global.cmsData = {
  allComponents: [],
  appInfo: undefined,
  archives: [],
  authAndReg: undefined,
  components: {},
  contentBlocks: {},
  defaultLanguage: '',
  filePages: {},
  files: {},
  favicons: {},
  languages: [],
  locales: [],
  pages: [],
  pageFolders: [],
  translations: [],
  vodSourcesBe: [],
  brandingMethods: [],
  footer: undefined,
  header: undefined,
  imagesMTimes: undefined,
  page404: undefined,
  personalArea: undefined,
  reviews: undefined,
  packages: undefined,
  QSConfig: undefined,
  siteConfig: undefined,
  styles: '',
  theme: 'light',
  branding2: undefined,
  seoRules: undefined,
  redirects: undefined,
};

export const BRANDING_METHODS_OPTIONS_ID: Record<string, string> = {
  showIcon: 'showIcon',
  showLegal: 'showLegal',
};

export const BRANDING_METHODS_DEFAULT = 'default';

export const ADULT_ONBOARDING_STEPS: Record<string, string> = {
  EnterPin: 'EnterPin',
  VerifySession: 'VerifySession',
  NoProfile: 'NoProfile',
  OneProfile: 'OneProfile',
  SetPin: 'SetPin',
  StartStep: 'StartStep',
  YourPin: 'YourPin',
};

export const ADULT_ONBOARDING_PIN_ACTION: Record<string, string> = {
  create: 'create',
};

export const ADULT_ONBOARDING_IMAGES: Record<string, string> = {
  EnterPin: '/public/images/backgrounds/avocado',
  VerifySession: '/public/images/backgrounds/candy',
  NoProfile: '/public/images/backgrounds/grapefruit',
  OneProfile: '/public/images/backgrounds/grapefruit',
  SetPin: '/public/images/backgrounds/avocado',
  StartStep: '/public/images/backgrounds/banana',
  YourPin: '/public/images/backgrounds/candy',
};

export const ADULT_ONBOARDING_PROFILE_CHANGE_TIMEOUT = 3500;
export const ADULT_ONBOARDING_MAX_NUMBER_OF_TRY = 3;

export const DEFAULT_NUMBER_OF_SLIDES = 18;

export const ADULT_OFFER_ID = '6481a2afc9e80accd93d5dda';
export const ADULT_CHANNELS: Record<string, { title: string; logo: string }> = {
  '5aead51021887f04724d177e': {
    title: 'Barely Legal',
    logo: 'https://megafon.server-img.lfstrm.tv/image/aHR0cDovL2ltZy5iNjEyLnRpZ2h0dmlkZW8uY29tL2NoYW5uZWxzL2JhcmVseV9sZWdhbF9uZXcucG5n',
  },
  '59ac8dcf21887f680800fc71': {
    title: 'Blue Hustler HD',
    logo: 'https://megafon.server-img.lfstrm.tv/image/aHR0cDovL2ltZy5iNjEyLnRpZ2h0dmlkZW8uY29tL2NoYW5uZWxzL2JsdWVfaHVzdGxlci5wbmc=',
  },
  '5a3a31c121887fb740dc5969': {
    title: 'Erox',
    logo: 'https://megafon.server-img.lfstrm.tv/image/aHR0cDovL2ltZy5iNjEyLnRpZ2h0dmlkZW8uY29tL2NoYW5uZWxzL2Vyb3gucG5n',
  },
  '58bdcf5021887f26e6d9b432': {
    title: 'О-ля-ля',
    logo: 'https://megafon.server-img.lfstrm.tv/image/aHR0cDovL2ltZy5iNjEyLnRpZ2h0dmlkZW8uY29tL2NoYW5uZWxzL29sYWxhLnBuZw==',
  },
  '62e406074b4b5eba1d6cf609': {
    title: 'Red Lips HD',
    logo: 'https://megafon.server-img.lfstrm.tv/image/aHR0cHM6Ly9pbWcuYjYxMi50aWdodHZpZGVvLmNvbS9jaGFubmVscy9yZWRfbGlwcy5wbmc=',
  },
  '57a5a3ca8daaa84636062cde': {
    title: 'Русская ночь HD',
    logo: 'https://megafon.server-img.lfstrm.tv/image/aHR0cDovL2ltZy5iNjEyLnRpZ2h0dmlkZW8uY29tL2NoYW5uZWxzL3J1c3NrYXlhLW5vY2gucG5n',
  },
  '5a65d08921887ff2831c1e99': {
    title: 'Шалун HD',
    logo: 'https://megafon.server-img.lfstrm.tv/image/aHR0cDovL2ltZy5iNjEyLnRpZ2h0dmlkZW8uY29tL2NoYW5uZWxzL3NoYWx1bi5wbmc=',
  },
  '6405daef652c7818e3e76fa6': {
    title: 'Brazzers TV Europe',
    logo: 'https://megafon.server-img.lfstrm.tv/image/aHR0cDovL2ltZy5iNjEyLnRpZ2h0dmlkZW8uY29tL2NoYW5uZWxzL2JyYXp6ZXJzLnBuZw==',
  },
  '641d94056bda72612718db63': {
    title: 'Playboy',
    logo: 'https://megafon.server-img.lfstrm.tv/image/aHR0cDovL2ltZy5iNjEyLnRpZ2h0dmlkZW8uY29tL2NoYW5uZWxzL3BsYXlib3kucG5n',
  },
};

export const DRM_SYSTEMS = {
  fairplay: 'fairplay',
  playready: 'playready',
  widevine: 'widevine',
};

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button#value
export const MOUSE_EVENT_BUTTONS = {
  MAIN_BUTTON: 0,
};

export const PENDING_TIMEOUT = 30000;

export const MANAGING_LOGIN_METHODS = {
  password: 'password',
  widthVerifiedContacts: 'with-verified-contacts',
};

export const PHONE_INFO_VERIFICATION_METHODS = {
  fixedProvider: 'fixedProvider',
  recommendProvider: 'recommendProvider',
  selectProvider: 'selectProvider',
};

export const BILLING_RENDERING_METHOD_NAME = 'qs21-billing-period-option';

export const FALLBACK_RENDERING_METHOD_NAME = 'qs21-yakassa';

export const SENTRY_DSN = 'https://6c3606a4ee5c4904b323679460d860d9@sentry.lfstrm.tv/38';
