<template>
  <div
    class="tile-next-program col-desktop-4 col-desktop-s-4 col-tablet-4 col-mobile-4"
    :class="theme"
  >
    <ProgramPreview
      :src="image"
      :show-progress="false"
      :show-play="false"
      :time="showStartsIn ? textForStartsIn : ''"
      :disable-hover="true"
      :width="422"
      :height="238"
    />
    <BadgeSequoia class="time" :theme-forced="theme" :text="startTimeHM" />
    <div class="h6" v-html="title" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ProgramPreview from 'src/components/tv-channels/ProgramPreview.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';

@Component({
  components: {
    BadgeSequoia,
    ProgramPreview,
  },
})
export default class TileNextProgram extends SequoiaComponent {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  image!: string;

  // e.g. '22:47'
  @Prop({ required: true })
  startTimeHM!: string;

  // e.g. '1 ч 39 мин'
  @Prop({ required: true })
  startsIn!: string;

  @Prop({ default: false })
  showStartsIn!: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get textForStartsIn() {
    return `${this.getTranslation('cherez')} ${this.startsIn}`;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.tile-next-program {
  .program-preview {
    margin-bottom: 8px;
  }

  .time {
    margin-bottom: 4px;
  }

  .h6 {
    @extend %truncate-after-2-lines;
  }
}
</style>
