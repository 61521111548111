<template>
  <ButtonDefault
    class="button-show-all-episodes"
    :class="{ 'mt-48 mt-tablet-32 mt-tablet-32': !shouldShowEpisodesAsGrid }"
    view="tertiary"
    size="medium"
    :full-width="true"
    :with-icon="true"
    @click="$emit('click')"
  >
    <IconSVG :svg="!shouldShowEpisodesAsGrid ? IconAngleDown : IconAngleUp" />
    <span v-if="!shouldShowEpisodesAsGrid" v-html="showAllEpisodesButtonText" />
    <span v-else v-html="getTranslation('collapse')" />
  </ButtonDefault>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleDown from 'src/svg/angle-down.svg';
import IconAngleUp from 'src/svg/angle-up.svg';

@Component({
  components: { IconSVG, ButtonDefault },
})
export default class ButtonShowAllEpisodes extends SequoiaComponent {
  IconAngleDown = IconAngleDown;
  IconAngleUp = IconAngleUp;

  @Prop({ required: true })
  shouldShowEpisodesAsGrid!: boolean;

  get showAllEpisodesButtonText() {
    return `${this.getTranslation('show_all')} ${this.getTranslation('episodes').toLowerCase()}`;
  }
}
</script>
