<template>
  <div class="player-dvr-error with-side-and-bottom-padding dark">
    <div class="title h4" v-html="getTranslation('live_management_is_not_available')" />
    <div class="content" v-html="overlayData.message" />

    <div class="buttons">
      <ButtonDefault theme-forced="dark" @click="close">
        <span v-html="getTranslation('continue_watching')" />
      </ButtonDefault>
      <ButtonDefault
        v-if="overlayData.channelId && overlayData.channelId !== currentChannelId"
        view="tertiary"
        theme-forced="dark"
        @click="selectChannel"
      >
        <span v-html="getTranslation('watch_live')" />&nbsp;«{{ channelName }}»
      </ButtonDefault>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { getChannelById } from 'src/utils/epg';
import { selectors } from 'src/store/selectors';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconCross from 'src/svg/cross.svg';
import IconSVG from 'src/components/IconSVG.vue';
import logger from 'src/utils/logger';
import { getChannelTitle } from 'src/utils/channel';

const log = logger('player-dvr-warning');

@Component({
  components: { PlayerButton, ButtonDefault, IconSVG },
})
export default class PlayerDvrWarning extends SequoiaComponent {
  IconCross = IconCross;

  get overlayData() {
    return selectors.player.dvrWarningOverlaySelector(this.$store);
  }

  get channels() {
    return selectors.tvChannels.allChannelsSelector(this.$store);
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  get channelName() {
    if (!this.overlayData.channelId) {
      return '';
    }
    const channel = getChannelById(this.channels, this.overlayData.channelId);
    return getChannelTitle(channel);
  }

  close() {
    actions.player.hideDvrWarningOverlay(this.$store);
  }

  async selectChannel() {
    this.close();
    if (this.overlayData.channelId) {
      await actions.tvChannels.selectChannel(this.$store, this.overlayData.channelId, this.$events);
      log.info('selectChannel', this.overlayData.channelId);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-dvr-error {
  z-index: var(--z-2);
  color: var(--c-dark-font-primary);
  text-align: center;

  .title {
    margin-bottom: 16px;
  }

  @include mobile-and-tablet {
    .content {
      max-height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .buttons {
    margin-top: 32px;

    button {
      margin: 8px;
    }
  }
}
</style>
