<template>
  <div class="footer-technical-info caption2 color-tertiary" :class="theme">
    <span>v{{ version }}</span>
    <span class="separator hidden-mobile">|</span>
    <span>UUID: {{ uaUuid }}</span>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import packageJson from '../../package.json';
import { getAppVersionParams } from 'src/utils/app-version';

@Component
export default class FooterTechnicalInfo extends SequoiaComponent {
  version = packageJson.version;
  uaUuid = getAppVersionParams().device.uuid;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.footer-technical-info {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 4px;
  text-align: right;
  background-color: var(--c-light-200);

  @include mobile {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }

  .separator {
    margin-right: 6px;
    margin-left: 6px;
  }

  &.dark {
    background-color: var(--c-dark-200);
  }
}
</style>
