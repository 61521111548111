import * as api from 'src/api';
import { selectors } from 'src/store/selectors';
import { TStore } from 'src/store/types';
import { convertToMilliseconds } from 'src/utils/time/convert-time';
import { TV_PAUSE_RESET, VOD_PAUSE_RESET } from 'src/constants';
import Vue from 'vue';
import { VODPause } from 'src/models/ts/pauses/v1/pauses';
import { Pause } from 'src/models/ts/playback';
import logger from 'src/utils/logger';

const log = logger('pauses');

const comparePauses = (a: Pause | VODPause, b: Pause | VODPause) => b.createdAt - a.createdAt;

export const loadVodPauses = async (store: TStore, sourceId: string, titleId: string) => {
  if (store.account?.anonymous || !sourceId || !titleId) {
    return;
  }

  try {
    const { pauses } = await api.pauses.getVodTitlePause(sourceId, titleId).catch();

    if (pauses) {
      Vue.set(store.pauseData.vod, 'pauses', pauses.sort(comparePauses));

      const lastPause = store.pauseData.vod.pauses[0];
      if (lastPause) {
        Vue.set(store.pauseData.vod, 'lastPause', lastPause);
      }
      log.info('Pauses were set to store, lastPause', lastPause);
    } else {
      Vue.set(store.pauseData.vod, 'pauses', []);
      Vue.set(store.pauseData.vod, 'lastPause', { ...VOD_PAUSE_RESET });
    }
  } catch (err) {
    log.error(err);
  }
};

export const loadTvPauses = async (store: TStore, channelId: string) => {
  const programs = selectors.tvEpg.programsSelector(store, channelId);

  if (store.account?.anonymous || !programs.length) {
    return;
  }

  let { programPauses: pauses } = await api.pauses.getTvPauses(channelId);

  pauses = pauses?.filter((pause: Pause) => {
    const time = convertToMilliseconds(pause.time, 'second');
    return time >= programs[0].startMs;
  });

  if (pauses?.length) {
    Vue.set(store.pauseData.tv, 'pauses', pauses.sort(comparePauses));
    const lastPause = store.pauseData.tv.pauses[0];

    if (lastPause) {
      Vue.set(store.pauseData.tv, 'lastPause', lastPause);
    }
  } else {
    Vue.set(store.pauseData.tv, 'pauses', []);
    Vue.set(store.pauseData.tv, 'lastPause', { ...TV_PAUSE_RESET });
  }

  log.info('TV pauses have been successfully loaded', store.pauseData.tv);
};
