<template>
  <div class="phone-verification">
    <QuickSubscribeBackground :reducedImage="true" />

    <div class="qs-content">
      <h2
        class="with-side-and-bottom-padding dark"
        v-html="getTranslation(token ? 'qr_phone_verification' : 'phone_verification')"
      />

      <div class="form-container with-side-and-bottom-padding pt-32 light">
        <InputText
          v-model="$store.QS.phone"
          theme-forced="light"
          input-category="floating"
          type="tel"
          size="medium"
          disabled
          required
          :label="getTranslation('phone_number_reg')"
        />
        <div>
          <span class="tip" v-text="getTranslation('wrong_number')" />
          <button
            type="button"
            class="link action-default"
            @click="
              goToPrevStep($store);
              gaEvent({
                category: 'acquiring',
                action: 'Клик по кнопке “Неверный номер телефона”',
                qs_qr: $route.query.token ? 'qr' : 'qs',
              });
            "
            v-html="getTranslation('enter_another')"
          />
        </div>

        <div class="note" v-html="getTranslation('sms_sent_to_phone')" />

        <div class="form">
          <InputText
            ref="smscode"
            v-model="checkCode"
            class="input-block"
            theme-forced="light"
            input-category="floating"
            type="number"
            size="medium"
            :maxlength="4"
            required
            :label="getTranslation('code_from_sms')"
            :disabled="verifyIsPending"
          />

          <ButtonDefault
            view="secondary"
            size="medium"
            :disabled="!!timerId && !!waitingTime"
            @click="retry"
          >
            <span v-if="!!timerId && !!waitingTime">
              <span v-text="waitingTime" /> <span v-html="getTranslation('seconds')" />
            </span>
            <span v-else v-html="getTranslation('resend_code')" />
          </ButtonDefault>
        </div>
        <ErrorSequoia :messages="errorMessages" data-cy="phone-verification" class="error" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Ref, Vue, Watch } from 'vue-property-decorator';
import { goToPrevStep, goToStepByName } from 'src/store/quick-subscribe/actions';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import { actions } from 'src/store/actions';
import * as api from 'src/api';
import Global from 'src/mixins/Global';
import Metrics from 'src/mixins/Metrics';
import Verification from 'src/mixins/Verification';
import { QS_STEP_NAMES, REGISTRATION_SMS_CODE_LENGTH } from 'src/constants';
import { HTTP_CODES } from 'src/constants';
import ErrorSequoia from 'src/components/ErrorSequoia.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { cleanupPhone } from 'src/utils/phone';
import Component, { mixins } from 'vue-class-component';

@Component({
  components: {
    QuickSubscribeBackground,
    InputText,
    ErrorSequoia,
    ButtonDefault,
  },
})
export default class PhoneVerification extends mixins(Global, Metrics, Verification) {
  goToPrevStep = goToPrevStep;
  goToStepByName = goToStepByName;

  verifyIsPending = false;
  checkCode = '';
  token = '';
  errorMessages: Array<string> = [];

  @Ref('smscode')
  refSmscode?: Vue;

  @Watch('checkCode')
  async onCheckCodeChange(val: string) {
    if (val.length === REGISTRATION_SMS_CODE_LENGTH) {
      this.$_verification_clearTimeout(this.timeoutIdUntilSessionTokenOutdated);
      try {
        this.verifyIsPending = true;
        const { sessionToken } = await actions.registration
          .verify(this.$store, {
            params: { token: this.token },
            data: {
              type: 'phone',
              contacts: cleanupPhone(this.$store.QS.phone),
              code: this.checkCode,
            },
          })
          .finally(() => {
            this.verifyIsPending = false;
          });

        await api.auth.contactsVerification.setPhone({
          params: {
            'contacts-session-token': sessionToken,
            token: this.token,
          },
        });

        await this.$emit('createSub');

        this.gaEvent({
          category: 'acquiring',
          action: 'Сабмит "Подтвердить телефон"',
          qs_qr: this.token ? 'qr' : 'qs',
        });
      } catch (err) {
        this.errorMessages = [err.data.message];
        if (err.code === HTTP_CODES.NOT_FOUND) {
          this.goToStepByName(this.$store, QS_STEP_NAMES.ERROR);
        }
        this.gaEvent({
          category: 'acquiring',
          action: 'Сабмит "Подтвердить телефон"',
          qs_qr: this.token ? 'qr' : 'qs',
          error_text: err.message,
        });
      }
    }
  }

  mounted() {
    this.token = this.$route.query.token as string;
    this.$_verification_startTimer();
    this.$_verification_setTimeout(
      this.goToStepByName.bind(this, this.$store),
      QS_STEP_NAMES.ERROR
    );
    (this.refSmscode?.$refs.input as HTMLInputElement | undefined)?.focus();
  }

  beforeDestroy() {
    this.$_verification_clearTimeout(this.timeoutIdUntilSessionTokenOutdated);
    this.$_verification_stopTimer();
  }

  async retry() {
    try {
      await actions.registration.retry(this.$store, {
        data: {
          type: 'phone',
          id: this.$store.contactsVerification.id,
        },
        params: { token: this.token },
      });
      this.$_verification_startTimer();
      this.gaEvent({
        category: 'acquiring',
        action: 'Клик по кнопке "Выслать заново"',
        qs_qr: this.token ? 'qr' : 'qs',
      });
    } catch (err) {
      this.goToStepByName(this.$store, QS_STEP_NAMES.ERROR);
      this.gaEvent({
        category: 'acquiring',
        action: 'Клик по кнопке "Выслать заново"',
        qs_qr: this.token ? 'qr' : 'qs',
        error_text: err.message,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.phone-verification {
  .qs-content {
    display: flex;
    flex-direction: column;
    min-height: 600px;
  }

  .form-container {
    flex: 1;
    margin-bottom: 0;
    background-color: var(--c-light-100);

    .input-block {
      display: inline-block;
      width: 100%;
      max-width: 300px;
      margin-bottom: 8px;
    }

    .tip {
      color: var(--c-light-font-secondary);
    }

    .note {
      margin: 32px 0 16px;
    }

    .form {
      display: flex;

      &::v-deep .button.secondary {
        min-width: 128px;
      }

      .input-block {
        width: 128px;
        margin-right: 16px;
      }
    }

    .error {
      margin-left: 16px;
    }
  }
}
</style>
