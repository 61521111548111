<template>
  <div class="item" :class="theme">
    <button type="button" class="title-wrap" @click="triggerVisibility">
      <IconSVG :svg="IconAngleRight" :direction="showContent ? 'down-from-right' : ''" />
      <span class="title" v-html="title" />
    </button>
    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div v-show="showContent" class="content-wrap">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import IconAngleRight from 'src/svg/angle-right.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { IconSVG },
})
export default class AccordionItem extends SequoiaComponent {
  IconAngleRight = IconAngleRight;
  showContent = false;

  @Prop()
  item?: Record<any, any>;

  @Prop()
  itemIndex?: number;

  @Prop({ required: true })
  title!: string;

  triggerVisibility() {
    this.showContent = !this.showContent;
  }

  beforeEnter(el: { style: { height: string } }) {
    el.style.height = '0';
  }

  enter(el: { style: { height: string }; scrollHeight: string }) {
    el.style.height = el.scrollHeight + 'px';
  }

  beforeLeave(el: { style: { height: string }; scrollHeight: string }) {
    el.style.height = el.scrollHeight + 'px';
  }

  leave(el: { style: { height: string } }) {
    el.style.height = '0';
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.item {
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 0;
  @include body1;

  &:last-child {
    border-bottom-width: 1px;
  }

  .title-wrap {
    position: relative;
    display: flex;
    width: 100%;
    padding: 20px 0;
    font-size: 1rem;
    text-align: left;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: none;

    .icon {
      flex-shrink: 0;
      margin-right: 8px;
    }

    .title {
      padding: 2px 0 3px;
      background-image: linear-gradient(transparent, transparent);
      background-repeat: no-repeat;
      background-position: 0 95%;
      background-size: 100% 1px;
      transition: all 0.15s var(--ease-out);
    }

    &:active .title {
      transition: none;
    }
  }

  .content-wrap {
    display: block;
    overflow: hidden;
    transition: height 0.25s var(--ease-in-out);

    &::v-deep p {
      &:not(:last-child) {
        padding-bottom: 16px;
      }
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    border-top-color: var(--c-light-300);
    border-bottom-color: var(--c-light-300);

    .title-wrap {
      color: var(--c-light-link-base);

      @include devices-with-hover {
        &:hover .title {
          background-image: linear-gradient(0, var(--c-light-link-base), var(--c-light-link-base));
        }
      }

      &:active {
        color: var(--c-light-link-active);

        .title {
          background-image: linear-gradient(
            0,
            var(--c-light-link-active),
            var(--c-light-link-active)
          );
        }
      }
    }
  }

  &.dark {
    border-top-color: var(--c-dark-300);
    border-bottom-color: var(--c-dark-300);

    .title-wrap {
      color: var(--c-dark-link-base);

      @include devices-with-hover {
        &:hover .title {
          background-image: linear-gradient(0, var(--c-dark-link-base), var(--c-dark-link-base));
        }
      }

      &:active {
        color: var(--c-dark-link-active);

        .title {
          background-image: linear-gradient(
            0,
            var(--c-dark-link-active),
            var(--c-dark-link-active)
          );
        }
      }
    }
  }
}
</style>
