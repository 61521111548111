<template>
  <div class="quick-sub-confirmed with-side-and-bottom-padding dark">
    <QuickSubscribeBackground />
    <div class="qs-content">
      <span class="icon-wrap">
        <IconSVG :svg="IconGift" class="color-brand" :size="40" />
      </span>
      <h2 v-html="getTranslation('subscribed')" />
      <div class="description" v-html="getTranslation('subscribed_description')" />
      <ButtonDefault :is-loading="isLoading" @click="goToView">
        <span v-html="getTranslation('go_to_view')" />
      </ButtonDefault>
    </div>
  </div>
</template>

<script lang="ts">
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconGift from 'src/svg/gift-old.svg';
import IconSVG from 'src/components/IconSVG.vue';
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';

@Component({
  components: {
    QuickSubscribeBackground,
    ButtonDefault,
    IconSVG,
  },
})
export default class TrialConfirmed extends SequoiaComponent {
  isLoading = false;
  IconGift = IconGift;

  async goToView() {
    this.isLoading = true;
    this.$emit('handleClose');
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.quick-sub-confirmed {
  .icon-wrap {
    margin-top: 16px;
  }

  h2 {
    margin: 24px 0;
  }

  .description {
    margin-bottom: 48px;
  }
}
</style>
