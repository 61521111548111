import { TStore } from 'src/store/types';
import { existingSubscriptionInRetrySelector as qsExistingSubscriptionInRetrySelector } from 'src/store/quick-subscribe/selectors';
import { SUBSCRIPTION_STATUSES } from 'src/constants';

export const paymentMethodAvailableSelector = (store: TStore) =>
  (store.payment?.method?.saved && !store.payment?.method?.is_cancelled) || false;

export const subscriptionsSelector = (store: TStore) => store.payment.subscriptions;
export const subscriptionsSelectorV2 = (store: TStore) =>
  store.payment.subscriptionsV2.filter(
    (subV2) =>
      !subV2.yakassa ||
      store.payment.subscriptions.find(
        (yakassaSub) => yakassaSub.id === subV2.yakassa?.subscriptionId
      )?.last_payment.status !== SUBSCRIPTION_STATUSES.PENDING
  );

export const hasActiveSubscriptionsSelector = (store: TStore) =>
  subscriptionsSelectorV2(store).length;

export const cardSelector = (store: TStore) => store.payment?.method?.card;

export const cardTypeSelector = (store: TStore) => cardSelector(store)?.card_type || '';

export const cardLast4Selector = (store: TStore) => cardSelector(store)?.last4 || '';

export const cardTypeImageSelector = (store: TStore) => {
  const cardType = cardTypeSelector(store);
  const src = `/public/images/icons/${cardType.toLowerCase()}.svg`;
  return cardType ? `${src}?v${store.imagesMTimes[src]}` : '';
};

export const subscriptionsInRetrySelector = (store: TStore) =>
  subscriptionsSelector(store).filter(
    (subscription) => subscription.subscription_renew_status === SUBSCRIPTION_STATUSES.IN_RETRY
  );

export const existingSubscriptionInRetrySelector = (store: TStore) => {
  const existingSubscriptionInRetry = qsExistingSubscriptionInRetrySelector(store);
  if (existingSubscriptionInRetry) {
    return subscriptionsSelector(store).find(
      (subscription) => subscription.id === existingSubscriptionInRetry.subscriptionId
    );
  }
  return null;
};
