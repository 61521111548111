<template>
  <option
    v-if="transformNativeSelect && isMobile"
    :disabled="disabled"
    :selected="selected"
    :value="keyValue"
    v-html="(iconText || '') + value"
  />
  <div
    v-else
    class="menu-item-container"
    data-cy="dropdown-menu-item-container"
    :class="[{ selected, disabled, 'with-bg': widthBg }, themeForced]"
    @click.stop="$emit('select')"
  >
    <IconSVG v-if="hasIcons && icon" :svg="icon" :size="20" class="menu-icon" />
    <div v-else-if="hasIcons && !icon" class="no-icon" />
    <p class="menu-item body1" v-html="value" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';

@Component({
  components: { IconSVG },
})
export default class DropdownItem extends SequoiaComponent {
  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  selected!: boolean;

  @Prop()
  widthBg?: boolean;

  @Prop()
  icon?: string;

  @Prop()
  iconText?: string;

  @Prop({ required: true })
  keyValue!: string;

  @Prop()
  hasIcons?: boolean;

  @Prop({ required: true })
  isMobile!: boolean;

  @Prop({ required: true })
  transformNativeSelect!: boolean;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  value!: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.menu-item-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 8px 16px;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 2px;

  @include devices-with-touch {
    min-height: 48px;
  }

  @include tablet {
    min-height: 48px;
  }

  @include devices-with-hover {
    &:hover {
      background-color: var(--alpha-light-4);
    }
  }

  &:active {
    background-color: var(--alpha-light-5);
  }

  &.selected {
    pointer-events: none;
    cursor: default;

    &.with-bg {
      color: var(--c-light-brand-darker);
      background-color: var(--c-overlay-brand-fixed-2);

      .menu-icon {
        color: var(--c-light-brand-lighter);
      }
    }
  }

  &.disabled,
  &.disabled .icon {
    color: var(--c-light-font-tertiary);
    pointer-events: none;
  }

  &.group-title {
    @include devices-with-hover {
      &:hover {
        cursor: auto;
        background-color: inherit;
      }
    }

    .menu-item {
      color: var(--c-light-font-tertiary);
    }
  }

  .icon {
    flex: none;
    margin-right: 8px;
    color: var(--c-dark-100);
  }

  .no-icon {
    flex: none;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .menu-item {
    margin: 0;
    @extend %truncate-after-1-line;
  }

  &.dark {
    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-4);
      }
    }

    &.disabled,
    &.disabled .icon {
      color: var(--c-dark-font-tertiary);
    }

    &.active.with-bg {
      color: var(--c-dark-brand-darker);
    }

    .icon {
      color: var(--c-light-100);
    }
  }
}
</style>
