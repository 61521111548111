<template>
  <div class="app-modal" :class="theme">
    <AppIconWithDescription />

    <div v-for="store in stores" class="app-block">
      <div class="body2" v-html="getTranslation(`download_for_${store}`)" />
      <a class="store" target="_blank" :href="getStoreLink(store)">
        <ImageWithHash :src="getThemedImg(store)" alt="" />
      </a>
    </div>

    <ButtonDefault view="tertiary" :full-width="true" @click="closeAppModal">
      <span v-html="getTranslation('continue_on_site')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import AppIconWithDescription from 'src/components/app-redirect/AppIconWithDescription.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import LazyImage from 'src/components/LazyImage.vue';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

type TStore = 'ios' | 'android';

@Component({
  components: {
    ImageWithHash,
    ButtonDefault,
    AppIconWithDescription,
    LazyImage,
  },
})
export default class AppModal extends SequoiaComponent {
  stores: TStore[] = ['ios', 'android'];

  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }
  getStoreLink(store: TStore) {
    return this.$store.siteConfig?.appStores.links[store];
  }
  getThemedImg(store: TStore) {
    return `/public/images/icons/store-badges/${store}-${this.theme}.svg`;
  }
  closeAppModal() {
    this.$store.common.isModalWithAppsOpen = false;
  }
}
</script>

<style lang="scss" scoped>
.app-modal {
  margin-right: 16px;
  margin-bottom: 40px;
  margin-left: 16px;

  > div {
    margin-bottom: 16px;
  }

  .app-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .store {
      width: 100%;
      max-width: 132px;
    }
  }
}
</style>
