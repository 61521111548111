<template>
  <div
    class="progress-bar"
    :class="theme"
    :style="{ backgroundColor: `var(--alpha-${theme}-${alphaLevel})` }"
  >
    <div class="progress" :style="`width: ${percentsComputed}%;`" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  percents!: number;

  // Pass alpha level number from 1 to 9 for a progress bar background variable
  @Prop({ default: 6 })
  alphaLevel!: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get percentsComputed() {
    return (this.percents > 1 ? 1 : this.percents) * 100;
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 2px;

  .progress {
    height: 100%;
    border-radius: 2px;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .progress {
      background-color: var(--c-light-brand);
    }
  }

  &.dark {
    .progress {
      background-color: var(--c-dark-brand);
    }
  }
}
</style>
