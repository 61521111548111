import { getBrowserFlags, getOsFlags } from 'src/utils/platform-detector';
import { VODAmedia2Playback } from 'src/models/ts/vod/v2/vod';
import { DRM_SYSTEMS } from 'src/constants';

export const getDrmSystem = () => {
  if (getOsFlags().isIos && !getBrowserFlags().isSafari) {
    // DRM doesn't work on iOS in Chrome/Firefox browsers
    // @see https://developer.bitmovin.com/playback/docs/does-drm-work-on-ios-in-chromefirefox-browsers
    return '';
  }
  if (getBrowserFlags().isSafari) {
    // todo enable DRM in Safari after fixing bug https://lifestream.atlassian.net/browse/SEQ-2478
    // return DRM_SYSTEMS.fairplay;
    return '';
  } else if (getBrowserFlags().isMicrosoft) {
    return DRM_SYSTEMS.playready;
  } else {
    return DRM_SYSTEMS.widevine;
  }
};

export const getDeviceDrmQueryParamValue = () => {
  const drmSystem = getDrmSystem();
  switch (drmSystem) {
    case DRM_SYSTEMS.fairplay:
      return 'fp';
    case DRM_SYSTEMS.playready:
      return 'prdsh';
    case DRM_SYSTEMS.widevine:
      return 'wvm';
  }
  return '';
};

export const getAmediaDrmStreamUrl = (amedia2Playback: VODAmedia2Playback, drmSystem: string) => {
  switch (drmSystem) {
    case DRM_SYSTEMS.fairplay:
      return amedia2Playback.streams?.hls?.fairplay || '';
    case DRM_SYSTEMS.playready:
      return amedia2Playback.streams?.dash?.playready || '';
    case DRM_SYSTEMS.widevine:
      return amedia2Playback.streams?.dash?.widevine || '';
  }
  return '';
};
