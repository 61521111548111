<template>
  <div v-if="isHelpVisible" class="player-help flex with-side-and-bottom-padding">
    <div v-for="(term, i) in termsFiltered" :key="term.text + i" class="term">
      <component :is="term.icon" class="icon" />
      <div>
        <div v-if="term.hint" class="hint" v-html="term.hint" />
        <div class="text" v-html="term.text" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { getOsFlags } from 'src/utils/platform-detector';
import IconSVG from 'src/components/IconSVG.vue';
import IconArrowLeft from 'src/svg/player/help/arrow-left.svg';
import IconArrowRight from 'src/svg/player/help/arrow-right.svg';
import IconArrowUp from 'src/svg/player/help/arrow-up.svg';
import IconArrowDown from 'src/svg/player/help/arrow-down.svg';
import IconFnUp from 'src/svg/player/help/fn-up.svg';
import IconFnDown from 'src/svg/player/help/fn-down.svg';
import IconPgUp from 'src/svg/player/help/pg-up.svg';
import IconPgDown from 'src/svg/player/help/pg-down.svg';
import IconEsc from 'src/svg/player/help/esc.svg';
import IconSpaceBar from 'src/svg/player/help/space-bar.svg';
import IconClick from 'src/svg/player/help/click.svg';
import IconClickDouble from 'src/svg/player/help/click-double.svg';

@Component({
  components: { IconSVG },
})
export default class PlayerHelp extends SequoiaComponent {
  terms = [
    {
      icon: IconArrowLeft,
      text: this.getTranslation('rewind_10_sec'),
      hint: '',
      onlyTv: false,
    },
    {
      icon: IconArrowUp,
      text: this.getTranslation('volume_up'),
      hint: '',
      onlyTv: false,
    },
    {
      icon: IconArrowRight,
      text: this.getTranslation('fast_forward_10_sec'),
      hint: '',
      onlyTv: false,
    },
    {
      icon: IconArrowDown,
      text: this.getTranslation('volume_down'),
      hint: '',
      onlyTv: false,
    },
    {
      icon: getOsFlags().isMacos ? IconFnUp : IconPgUp,
      text: this.getTranslation('previous_channel'),
      hint: '',
      onlyTv: true,
    },
    {
      icon: IconClick,
      text: this.getTranslation('play_pause'),
      hint: this.getTranslation('left_mouse_click'),
      onlyTv: false,
    },
    {
      icon: getOsFlags().isMacos ? IconFnDown : IconPgDown,
      text: this.getTranslation('next_channel'),
      hint: '',
      onlyTv: true,
    },
    {
      icon: IconClickDouble,
      text: this.getTranslation('fullscreen_mode'),
      hint: this.getTranslation('double_mouse_click'),
      onlyTv: false,
    },
    {
      icon: IconEsc,
      text: this.getTranslation('exit_fullscreen'),
      hint: '',
      onlyTv: false,
    },
    {
      icon: IconSpaceBar,
      text: this.getTranslation('play_pause'),
      hint: '',
      onlyTv: false,
    },
  ];

  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get isTv() {
    return this.playerType === 'tv';
  }

  get termsFiltered() {
    return this.terms.filter((t) => (!this.isTv && !t.onlyTv) || this.isTv);
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get isHelpVisible() {
    return selectors.player.isHelpVisibleSelector(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-help {
  flex-direction: row;
  width: 100%;

  .term {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 20px;
  }

  .icon {
    height: 54px;
    margin-right: 24px;

    @include mobile-and-tablet {
      height: 32px;
      margin-right: 12px;
    }
  }

  .text {
    @include body2;
    @include mobile-and-tablet {
      @include caption2;
    }
  }

  .hint {
    color: var(--c-dark-font-secondary);
    @include caption2;
  }
}
</style>
