import { TQuickSubscribe } from 'src/store/quick-subscribe/types';

const initialState: TQuickSubscribe = {
  notificationText: '',
  showNotification: false,
  existingSubscriptions: null,
  allAvailableSubscriptions: null,
  allSubscriptions: null,
  channel: null,
  confirmationUrl: '',
  currentSourceId: '',
  currentStepIndex: 0,
  isModalOpen: false,
  paymentRetry: false,
  phone: '',
  QRPayment: {
    isPaid: false,
    isError: false,
    title: '',
    expiresAt: '',
  },
  qsTransactionId: '',
  serverError: {
    code: '',
    message: '',
    last_payment: {
      status: '',
      cancellation_details: {
        reason: '',
      },
    },
  },
  sourceType: '',
  subChannels: [],
  subDetails: null,
  subscriptionsBySource: {},
  tempSub: null,
  toPrevStep: false,
  usePM: true,
  vod: null,
  wasChannelSelected: false, // show acquiring only when user selects some channel
};

export default initialState;
