<template>
  <div
    class="program-preview"
    :class="[theme, { 'without-progress': !showProgress }, { 'pointer-events-none': disableHover }]"
    @click="$emit('click')"
  >
    <LazyImage v-if="src" :key="src" :with-icon="true" :src="src" :width="width" :height="height" />
    <IconPlayInCircle v-if="showPlay" />
    <div class="inner-wrap">
      <BadgeSequoia
        v-if="time"
        class="time"
        theme-forced="dark"
        typography="caption2"
        size="compact"
        :text="time"
      />
      <ProgressBar
        v-if="showProgress && timelinePosition > 0"
        theme-forced="dark"
        :percents="timelinePosition || 0"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import IconPlayInCircle from 'src/components/tv-channels/IconPlayInCircle.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import LazyImage from 'src/components/LazyImage.vue';

@Component({
  components: { BadgeSequoia, ProgressBar, IconPlayInCircle, LazyImage },
})
export default class ProgramPreview extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  src!: string;

  @Prop()
  title?: string;

  @Prop()
  time?: string;

  @Prop()
  timelinePosition?: number;

  @Prop({ default: true })
  showPlay?: boolean;

  @Prop({ default: true })
  showProgress?: boolean;

  @Prop({ default: false })
  disableHover!: boolean;

  @Prop()
  width?: number;

  @Prop()
  height?: number;

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/gradient-for-progress-bar';
@import 'src/styles/placeholders-and-mixins/shadows';

$border-radius: 8px;

.program-preview {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: $border-radius;
  transition: transform var(--ease-out) 0.15s, box-shadow var(--ease-out) 0.15s;

  @include devices-with-hover {
    &:hover {
      transform: scale(1.04);
    }
  }

  &:active {
    transform: scale(1.02);
  }

  &:not(.without-progress)::after {
    @include gradient-for-progress-bar($border-radius);
  }

  .lazy-image,
  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;
  }

  .inner-wrap {
    position: absolute;
    bottom: 12px;
    left: 12px;
    z-index: var(--z-3);
    display: flex;
    flex-direction: column;
    width: calc(100% - 24px);

    @include mobile-and-tablet {
      bottom: 8px;
      left: 8px;
      width: calc(100% - 16px);
    }

    .progress-bar {
      width: 100%;
      margin-top: 4px;
    }
  }

  // -----------------------------------------------
  // Theme Colors
  // -----------------------------------------------

  &.light {
    @extend %shadow-default-light;
  }

  &.dark {
    @extend %shadow-default-dark;
  }
}
</style>
