import { TStore } from 'src/store/types';
import { convertToMilliseconds, convertToSeconds, secondsToHM } from 'src/utils/time/convert-time';

export const vodPauses = (store: TStore) => store.pauseData.vod.pauses;

export const lastVodPause = (store: TStore) => {
  const pause = store.pauseData.vod.lastPause;

  return {
    currentProgress: parseFloat((pause.msecFromStart / pause.totalDurationMsec).toFixed(4)),
    duration: secondsToHM(convertToSeconds(pause.totalDurationMsec, 'millisecond')),
    episodeId: pause.episodeId,
    fromStart: pause.msecFromStart,
    id: pause.id,
    mediaItemId: pause.mediaItemId,
    seasonId: pause.seasonId,
    timeLeft: secondsToHM(Math.ceil(convertToSeconds(pause.msecFromStart, 'millisecond'))),
    titleId: pause.titleId,
  };
};

export const lastTvPause = (store: TStore) => {
  return {
    ...store.pauseData?.tv?.lastPause,
    time: convertToMilliseconds(store.pauseData?.tv?.lastPause?.time, 'second'),
  };
};
