<template>
  <div class="vod-catalog catalog with-side-padding">
    <PageTitle
      :crumbs="crumbs"
      :text="
        source ? (source.customTitle ? getByLanguage(source.customTitle) || '' : source.title) : ''
      "
      :show-text="isSourceAvailable && !showVodSourceIsUnavailableError"
      :with-side-padding="false"
    />

    <div
      v-if="(!isSourceAvailable || showVodSourceIsUnavailableError) && isLoaded"
      class="error-block"
    >
      <h4 v-if="!isSourceAvailable" v-html="getTranslation('vod_unavailable_error_profile')" />
      <h4
        v-if="!isSourceAvailable || showVodSourceIsUnavailableError"
        v-html="getTranslation('vod_unavailable_error_default')"
      />
    </div>

    <template v-else>
      <VodFilters
        :source-id="sourceId"
        :categories="sortedCategories"
        :current-category="currentCategory"
        :current-genre="currentGenre"
        @loadTitles="loadTitles"
        @setSelectedGenre="setSelectedGenre"
      />

      <template v-if="!isLoaded && !titles.length">
        <h4 class="loading"><span v-html="getTranslation('loading')" />...</h4>
      </template>

      <div
        v-if="isLoaded && !titlesWithPosters.length"
        class="titles-not-found mt-32 mb-32 body1 color-light-font-secondary"
        v-html="getTranslation('movies_not_found')"
      />

      <div v-if="titlesWithPosters.length" class="row" data-cy="titles">
        <TilePoster
          v-for="title in titlesWithPosters"
          :key="title.id"
          class="col-desktop-2 col-desktop-s-3 col-tablet-2 col-mobile-2 mb-24"
          :href="preparePosterLink(title, sourceId)"
          :width-proportion="posterWidthProportion(sourceId)"
          :height-proportion="posterHeightProportion(sourceId)"
          :image="title.posters[0].path"
          :logo="getSourceLogoUrl()"
          :rating-imdb="title.ratingImdb"
          :rating-kinopoisk="title.ratingKp"
          :title="title.title"
          :original-title="title.originalTitle"
          :countries="title.countries"
          :years="title.years"
          :genres="title.genres"
          :duration="title.duration"
          :title-branding-methods="title.brandingMethods"
          @click="showTitleDetails(title.id)"
        />
      </div>

      <ButtonShowMore
        :is-visible="isButtonShowMoreVisible && !loadTitlesPending && titlesWithPosters.length"
        @click="loadMoreTitles"
      />

      <a v-if="showSeoLinkNextPage" :href="seoLinkNextPage" />

      <LoaderSpinner v-if="loadTitlesPending" />

      <ModalSequoia
        v-if="isModalOpen && isModalTitleOpen"
        type="responsive"
        theme-forced="dark"
        :title="modalTitle?.preview?.title"
        :show-title="false"
        :with-button-back="modalTitleIdsHistory.length > 1"
        @close="hideTitleDetails"
        @back="handleModalStepBack"
      >
        <VodTitleDetails />
      </ModalSequoia>
    </template>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import Global from 'src/mixins/Global';
import Vod from 'src/mixins/Vod';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import {
  DEFAULT_VOD_CATEGORIES,
  NOTIFICATION_HIDE_TIMEOUT,
  VOD_TITLES_DEFAULT_LIMIT,
} from 'src/constants';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import IconSVG from 'src/components/IconSVG.vue';
import InputSearch from 'src/components/ui/input/InputSearch.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import TilePoster from 'src/components/ui/tiles/TilePoster.vue';
import VodFilters from 'src/components/vod/VodFilters.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import VodTitleDetails from 'src/components/vod/VodTitleDetails.vue';
import { Prop, Watch } from 'vue-property-decorator';
import { makePath, getVodTitleWatchUrl } from 'src/utils/url';
import logger from 'src/utils/logger';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { VODGenre } from 'src/models/ts/vod/v2/vod';
import { getSeoBot } from 'src/utils/platform-detector';
import PageTitle from '../ui/PageTitle.vue';
import { wlDetector } from 'src/utils';
import ButtonShowMore from 'src/components/ui/buttons/ButtonShowMore.vue';

const log = logger('vod-catalog-page');

@Component({
  components: {
    ButtonShowMore,
    VodTitleDetails,
    VodFilters,
    ButtonDefault,
    ButtonDropdown,
    IconSVG,
    InputSearch,
    LoaderSpinner,
    ModalSequoia,
    TilePoster,
    PageTitle,
  },
})
export default class VodCatalog extends mixins(Global, Vod) {
  currentGenre: VODGenre | null = null;
  loadMoreOffsetTop?: number;
  isButtonShowMoreVisible = true;
  loadTitlesPending = false;
  showVodSourceIsUnavailableError = false;

  @Prop({ required: true })
  refScrollWrap!: Element;

  @Watch('isLoaded')
  onIsLoadedChange(val: boolean) {
    if (val) {
      actions.common.showFooter(this.$store);
    }
  }

  @Watch('$store.vod.modalTitleId')
  onModalTitleIdChange(modalTitleId: string) {
    if (!modalTitleId) {
      history.pushState(
        {},
        '',
        makePath(
          !this.playingTitleId
            ? `/vod/${this.sourceId}`
            : getVodTitleWatchUrl(this.sourceId, this.playingTitleId, this.playingEpisodeId)
        )
      );
    }
  }

  @Watch('$store.vod.states.sourcesLoaded')
  async onVodSourcesLoadedChange(val: boolean) {
    if (val) {
      await this.loadVodData(true);
    }
  }

  get crumbs() {
    return [
      { name: this.getTranslation('menu_homescreen'), link: '/' },
      { name: this.getTranslation('vod'), link: '/vod' },
    ];
  }

  get isSourceAvailable() {
    return !!this.source;
  }

  get isLoaded() {
    return this.vodSourcesLoaded && !this.vodSourcesLoading && !this.loadTitlesPending;
  }

  get vodSourceCategoriesLoaded() {
    return selectors.vod.sourceCategoriesLoadedSelector(this.$store, this.sourceId);
  }

  get vodSourceCategoriesLoading() {
    return selectors.vod.sourceCategoriesLoadingSelector(this.$store, this.sourceId);
  }

  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get isModalTitleOpen() {
    return selectors.vod.isModalTitleOpenSelector(this.$store);
  }

  get modalTitleIdsHistory() {
    return selectors.vod.modalTitleIdsHistorySelector(this.$store);
  }

  get titlesWithPosters() {
    return this.titles.filter((title: TVODTitlePreviewEnhanced) => {
      return title.posters?.length != null;
    });
  }

  get showSeoLinkNextPage() {
    return getSeoBot() && VOD_TITLES_DEFAULT_LIMIT >= this.titlesWithPosters.length;
  }

  get seoLinkNextPage() {
    return `/vod/${this.sourceId}/?offset=${
      (Number(this.$route.query.offset) || 0) + VOD_TITLES_DEFAULT_LIMIT
    }`;
  }

  get isUztel() {
    return wlDetector().isUztel;
  }

  get sorting() {
    return selectors.vod.sortingSelector(this.$store);
  }

  async serverPrefetch() {
    actions.vod.resetSorting(this.$store);

    if (!this.isSourceAvailable && this.isAnonymous) {
      return;
    }

    await actions.vod.loadSourceCategories(this.$store, this.sourceId);

    if (getSeoBot()) {
      await this.loadVodData(true);
    }
  }

  async mounted() {
    actions.vod.resetSorting(this.$store);

    if (!this.$store.vod.sources[this.sourceId]) {
      this.showVodSourceIsUnavailableError = true;
      return;
    }

    await this.loadVodData(true);

    if (this.$store.vod.errors.sourceWasNotFound) {
      setTimeout(() => {
        this.$store.vod.errors.sourceWasNotFound = null;
      }, NOTIFICATION_HIDE_TIMEOUT);
    }
  }

  beforeDestroy() {
    actions.vod.resetCurrentCategoryId(this.$store);
  }

  getSourceLogoUrl() {
    return actions.vod.getSourceLogoUrl(this.$store, this.sourceId);
  }

  posterWidthProportion(source: string) {
    return selectors.vod.posterWidthProportionSelector(this.$store, source);
  }

  posterHeightProportion(source: string) {
    return selectors.vod.posterHeightProportionSelector(this.$store, source);
  }

  flushTitles() {
    actions.vod.flushTitles(this.$store, this.sourceId, this.currentCategoryId);
  }

  setSelectedGenre(genre: VODGenre) {
    this.currentGenre = genre;
  }

  async loadVodData(resetGenre = false) {
    if (!this.isSourceAvailable || this.vodSourceCategoriesLoading) {
      return;
    }

    log.info(
      `Do you need to load VOD source categories?`,
      !this.categories || !this.vodSourceCategoriesLoaded
    );

    if (!this.categories || !this.vodSourcesLoaded) {
      await actions.vod.loadSourceCategories(this.$store, this.sourceId);
    }

    if (selectors.vod.sourceCategoriesLoadingSelector(this.$store, this.sourceId)) {
      return;
    }

    if (this.sortedCategories.length <= 0) {
      this.showVodSourceIsUnavailableError = true;
      return;
    }

    this.showVodSourceIsUnavailableError = false;

    this.flushTitles();

    this.$forceUpdate();

    if (resetGenre && this.currentCategory?.genres?.[0]) {
      this.currentGenre = this.currentCategory?.genres?.[0];
    }

    actions.vod.setCurrentCategoryId(
      this.$store,
      this.sourceId,
      this.getCategoryIdFromTitle(this.title) ||
        this.currentCategoryId ||
        DEFAULT_VOD_CATEGORIES[this.sourceId] ||
        this.sortedCategories?.[0]?.id ||
        ''
    );

    await this.loadTitles();
  }

  loadMoreTitles() {
    this.gaEvent({ category: 'vod', action: 'Клик по кнопке "Показать еще"' });
    this.loadMoreOffsetTop = this.refScrollWrap.scrollTop;
    this.loadTitles(true).finally(() => {
      if (this.loadMoreOffsetTop) {
        return (this.refScrollWrap.scrollTop = this.loadMoreOffsetTop);
      }
    });
  }

  async loadTitles(loadMore = false, catId = this.currentCategoryId, sorting = this.sorting) {
    if (this.loadTitlesPending) {
      return;
    }

    if (!catId) {
      log.error('loadTitles: titles were not loaded – currentCategoryId was not initialized');
      return;
    }

    this.isButtonShowMoreVisible = true;
    this.loadTitlesPending = true;

    actions.vod.setCurrentCategoryId(this.$store, this.sourceId, catId);

    if (!loadMore) {
      this.flushTitles();
    }

    const sourceId = this.sourceId;
    const categoryId = this.currentCategoryId;
    const genreIds =
      this.currentGenre?.id && this.currentGenre.id !== '-1' ? this.currentGenre.id : undefined;

    const titles = await actions.vod
      .loadTitles(this.$store, {
        sourceId,
        categoryId,
        genreIds,
        limit: VOD_TITLES_DEFAULT_LIMIT,
        offset: Number(this.$route.query.offset) || 0,
        sort: sorting,
      })
      .finally(() => {
        this.loadTitlesPending = false;
      });

    if (!titles?.length || titles?.length < VOD_TITLES_DEFAULT_LIMIT) {
      this.isButtonShowMoreVisible = false;
    }
  }

  async showTitleDetails(titleId: string) {
    await actions.vod.showTitleDetails(this.$store, this.sourceId, titleId, true);
  }

  hideTitleDetails() {
    actions.vod.hideTitleDetails(
      this.$store,
      this.sourceId,
      this.$store.vod.videoData.titleId,
      this.getTranslation('vod_catalog_meta_title')
    );
  }

  handleModalStepBack() {
    this.$store.vod.isTitleLoading = true;
    const arrayOfTitleIds: Array<string> = this.modalTitleIdsHistory;
    const currentTitleId: string = arrayOfTitleIds[arrayOfTitleIds.length - 2];
    actions.vod.handleModalStepBack(this.$store, this.sourceId, currentTitleId);
    history.pushState({}, '', makePath(`/vod/${this.sourceId}/${currentTitleId}`));
    document.querySelector('.modal')?.scrollTo(0, 0);
    this.$nextTick(() => {
      this.$store.vod.isTitleLoading = false;
    });
  }
}
</script>
