<template>
  <div
    v-if="isDesktop && store.languages.length > 1 && !!currentLanguage"
    v-click-outside="clickOutsideLangMenu"
    class="language-dropdrown"
    :class="{ pressed: showLanguageDropdown }"
  >
    <div class="language-button flex-center-vertical" @click="toggleLanguageDropdown()">
      <IconSVG :svg="currentLanguageIcon(currentLanguage.shortName)" />
    </div>
    <div v-if="showLanguageDropdown" class="dropdown-wrapper">
      <div class="dropdown">
        <div
          v-for="language in filteredLanguages"
          :key="language.locale"
          class="dropdown-item"
          @click="changeLanguage(language)"
        >
          <IconSVG :svg="currentLanguageIcon(language.shortName)" />
          <span v-text="language.shortName" />
        </div>
      </div>
    </div>
  </div>

  <div
    v-else-if="!isDesktop && store.languages.length > 1 && !!currentLanguage"
    class="dropdown-wrap mb-24"
  >
    <DropdownSequoia
      v-slot="{ value, toggle }"
      :list="mobileLanguageList"
      :show-selected-item="false"
      theme-forced="dark"
      width="auto"
      class="language-dropdown-mobile"
      @select="changeLanguage"
    >
      <ButtonDropdown
        class="language-dropdown-mobile"
        size="medium"
        width="auto"
        theme-forced="dark"
        :value="value"
        :icon="currentLanguageIcon(value)"
        @click="toggle"
      />
    </DropdownSequoia>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, onServerPrefetch } from 'vue';
import { store } from 'src/store';
import { TLanguage } from 'src/store/types';
import { selectors } from 'src/store/selectors';
import { cookieStore } from 'src/utils/storage';
import { COOKIE_NAMES } from 'src/constants';
import { getLanguages } from 'src/api/cms';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconLangRu from 'src/svg/lang-ru.svg';
import IconLangEn from 'src/svg/lang-en.svg';
import IconLangKz from 'src/svg/lang-kz.svg';
import IconLangKg from 'src/svg/lang-kg.svg';
import IconLangUz from 'src/svg/lang-uz.svg';

const showLanguageDropdown = ref(false);
const IconLangs: Record<string, any> = ref({
  IconLangRU: IconLangRu,
  IconLangEN: IconLangEn,
  IconLangKZ: IconLangKz,
  IconLangKG: IconLangKg,
  IconLangUZ: IconLangUz,
});

const props = defineProps({
  isDesktop: {
    type: Boolean,
    default: true,
  },
});

onServerPrefetch(() => {
  //no need to filter: languages have already been prepared at the SSR side
  store.languages = global.cmsData.languages;
});

onMounted(async () => {
  if (!store.languages.length) {
    const languages: { defaultLanguage: string; languages: Array<TLanguage> } =
      (await getLanguages()) || [];
    store.defaultLanguage = languages.defaultLanguage;
    store.languages = languages.languages.filter((language) => !!language.isOnSite);
  }
});

const languageCode = computed(() => selectors.common.languageCodeSelector(store));

const currentLanguage = computed(() =>
  store.languages.find(
    (language: TLanguage) => language.locale === (languageCode.value || global.languageCode)
  )
);

const mobileLanguageList = (): TDropdownItem[] => {
  return store.languages.map((language: TLanguage) => {
    return {
      key: language.shortName,
      value: language.shortName,
      icon: currentLanguageIcon(language.shortName),
      selected: language.locale === languageCode.value,
    };
  });
};

const filteredLanguages = computed(() => {
  return store.languages.filter((language: TLanguage) => language.locale !== languageCode.value);
});

const changeLanguage = (item: TLanguage | TDropdownItem) => {
  const langShortName = 'shortName' in item ? item.shortName : item.value;
  const chosenLanguage = filteredLanguages.value.find(
    (lang: TLanguage) => lang.shortName === langShortName
  );
  cookieStore.set(COOKIE_NAMES.languageCode, chosenLanguage?.locale);

  const path =
    store.languageCode !== store.defaultLanguage
      ? window.location.pathname.substring(3)
      : window.location.pathname;
  const url =
    chosenLanguage?.locale === store.defaultLanguage
      ? path === ''
        ? `/`
        : path + window.location.search
      : `/${chosenLanguage?.locale}${path}${window.location.search}`;

  // setTimeout needed to work properly on ios
  setTimeout(() => {
    location.href = url;
  }, 100);
};

const toggleLanguageDropdown = () => {
  showLanguageDropdown.value = !showLanguageDropdown.value;
};

const clickOutsideLangMenu = () => {
  showLanguageDropdown.value = false;
};

const currentLanguageIcon = (lang: string) => {
  const iconLangName = `IconLang${lang}`;
  return IconLangs.value[iconLangName] || '';
};
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/components/header/dropdowns';

.language-dropdrown {
  position: relative;
  height: 100%;
  cursor: pointer;

  .language-button {
    height: 100%;
    padding: 0 16px;
  }

  &.pressed .language-button,
  .language-button:active {
    background-color: var(--alpha-dark-4);
  }

  &:not(.pressed) {
    @include devices-with-hover {
      .language-button:hover {
        background-color: var(--alpha-dark-3);
      }
    }
  }
}

.language-dropdown-mobile {
  .menu-dropdown {
    position: relative;

    &::v-deep .suggestions-container {
      height: auto !important;
    }
  }
}
</style>
