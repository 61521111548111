import { URLS } from 'src/constants';
import { fe, TReqConfig } from 'src/api/utils';
import { TQsStateForTransaction, TTokenData } from './types';

export const getTokenData = async (config: TReqConfig): Promise<TTokenData> =>
  fe.get(URLS.bow.tokenData, config);

export const qsStateForTransaction = async (
  transactionId: string,
  config: TReqConfig
): Promise<TQsStateForTransaction> => fe.get(URLS.bow.qsStateForTransaction(transactionId), config);
