<template>
  <NotificationRetry
    v-if="!isAnonymous && inRetrySubscriptions && inRetrySubscriptions.length"
    :inRetrySubscriptions="inRetrySubscriptions"
  />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import NotificationRetry from 'src/components/ui/notifications/NotificationRetry.vue';
import logger from 'src/utils/logger';

const log = logger('payment-retry-notification');

@Component({
  components: {
    NotificationRetry,
  },
})
export default class PaymentRetryNotification extends SequoiaComponent {
  get inRetrySubscriptions(): any[] {
    return selectors.payment.subscriptionsInRetrySelector(this.$store);
  }

  async mounted() {
    try {
      await actions.payment.loadPaymentSubscriptions(this.$store);
      await actions.payment.loadPaymentMethod(this.$store);
    } catch (err) {
      log.error(err);
    }
  }
}
</script>
