<template>
  <div class="tile-title" :class="theme" :title="title" data-cy="tile-title" v-text="title" />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component
export default class TileTitle extends SequoiaComponent {
  @Prop({ required: true })
  title!: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.tile-title {
  @extend %body1;
  @extend %truncate-after-2-lines;

  position: relative;
  margin: 12px 0 4px;
  font-weight: bold;
  line-height: 1.25rem;
}
</style>
