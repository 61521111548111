import Vue from 'vue';
import Component from 'vue-class-component';
import plurar from 'plural-ru';
import { getByLanguage } from 'src/utils/language';
import { selectors } from 'src/store/selectors';
import { cookieStore } from 'src/utils/storage';
import { TTranslations } from 'src/store/translations/types';
import { makePath } from 'src/utils/url';

declare global {
  interface Window {
    smDev: { happyNewYear(): void };
  }
}

@Component
export default class Global extends Vue {
  uid = null;

  get languageCode() {
    return selectors.common.languageCodeSelector(this.$store);
  }

  get isAnonymous() {
    return selectors.account.isAnonymousSelector(this.$store);
  }

  get theme() {
    return this.$store.theme;
  }

  get showWelcomeScreen() {
    const welcomeScreenConfirmed =
      process.env.VUE_ENV === 'server'
        ? this.$ssrContext.cookies['welcomeScreenConfirmed']
        : cookieStore.get('welcomeScreenConfirmed');
    return (
      !this.isAnonymous &&
      welcomeScreenConfirmed !== '1' &&
      this.$store.siteConfig?.showWelcomeScreen &&
      this.$store.common.showWelcomeScreen
    );
  }

  getByLanguage<T = string>(translations?: TTranslations<T> | null) {
    return getByLanguage<T>(translations, this.languageCode);
  }

  getTranslation(translationId: string): string {
    return selectors.translations.translationSelector(this.$store, translationId);
  }

  getDaysWord(days: number) {
    return plurar(
      days,
      this.getTranslation('day'),
      this.getTranslation('day_genitive'),
      this.getTranslation('days_genitive')
    );
  }

  getChannelsWord(channels: number) {
    return plurar(
      channels,
      this.getTranslation('channel'),
      this.getTranslation('channel_genitive'),
      this.getTranslation('channels_genitive')
    );
  }

  mounted() {
    window.smDev = {
      happyNewYear: () => {
        this.$store.secretSanta = !this.$store.secretSanta;
        if (this.$store.secretSanta) {
          cookieStore.set('secretSanta', this.$store.secretSanta);
        } else {
          cookieStore.remove('secretSanta');
        }
      },
    };
  }

  goToMainPage() {
    location.replace(makePath('/'));
  }

  async goToMainPageWithRouter() {
    await this.$router.push('/');
  }

  async goToArchiveCatalogPageWithRouter() {
    await this.$router.push('/archive');
  }

  goToMainPageWithReloadAndTimeout() {
    // timeout - is a workaround when getAllFeCookies() returns undefined even though user is authorized,
    // but once the page has been reloaded - getAllFeCookies() returns data as expected.
    // so it seems we need a small delay between authorization and a call of getAllFeCookies()
    // 0.5s timeout should fix this issue for now
    // TODO investigate this problem
    setTimeout(() => (document.location.href = makePath('/')), 500);
  }

  goToPageWithReload(name: string) {
    document.location.href = makePath(`/${name}`);
  }

  prepareError(err: { code: string; msg: string; errors: Array<{ code: string; msg: string }> }) {
    let errors = [];
    if (err.code) {
      errors.push(err.msg);
    }
    if (Array.isArray(err.errors) && err.errors.length) {
      errors = errors.concat(err.errors.map((e) => e.msg));
    }
    throw errors;
  }
}
