import Vue from 'vue';
import { currentChannelIdSelector } from 'src/store/tv-current-channel/selectors';
import { TStore } from 'src/store/types';
import * as api from 'src/api/index';
import { selectors } from 'src/store/selectors';
import { epgBoundsSelector, epgDefaultBoundsSelector } from 'src/store/tv-channels/selectors';
import logger from 'src/utils/logger';
import { convertToSeconds } from 'src/utils/time/convert-time';
import { DEFAULT_EPG_DISPERSION_DAYS } from 'src/constants';
import { programIndexForChannelSelector } from 'src/store/tv-epg/selectors';
import { updateServerTime } from 'src/store/app-info/actions';

const log = logger('tv-epg');

const defaultProgramsPeriod = () => {
  const now = Math.round(convertToSeconds(Date.now(), 'millisecond'));
  const oneWeek = convertToSeconds(DEFAULT_EPG_DISPERSION_DAYS, 'day');
  return `${now - oneWeek}:${now + oneWeek}`;
};

export const loadEpgForChannel = async (
  store: TStore,
  channelId: string,
  shouldUpdateServerTime = true
) => {
  const programs = selectors.tvEpg.programsSelector(store, channelId);

  if (store.tvEpg.list[channelId]?.loading) {
    log.info('EPG has already started loading for channel', channelId);
    return;
  }

  if (store.tvEpg.list[channelId]?.loaded || (Array.isArray(programs) && programs.length > 0)) {
    log.info('EPG has already been loaded for channel', channelId);
    return;
  }

  log.info('Loading EPG for channel', channelId, '...');

  let epg;
  const epgBounds = epgBoundsSelector(store, channelId);
  const epgBoundsDefault = epgDefaultBoundsSelector(store);

  if (!epgBounds) {
    epg = await api.channels.getEpg(channelId, defaultProgramsPeriod());

    if (channelId && store.tvChannels.list[channelId] && store.tvEpg.list[channelId]) {
      Vue.set(store.tvEpg.list[channelId], 'loading', true);
      Vue.set(store.tvChannels.list[channelId], 'epgBounds', epg.info?.EPGBounds);
    }
  }

  const { leftBound, rightBound } = epgBounds || epgBoundsDefault;
  const period = `${leftBound}:${rightBound}`;

  epg = await api.channels.getEpg(channelId, period);

  Vue.set(store.tvEpg.list, channelId, epg);
  Vue.set(store.tvEpg.list[channelId], 'loading', false);
  Vue.set(store.tvEpg.list[channelId], 'loaded', true);

  log.info('EPG has just been loaded for channel', channelId, {
    period,
    programsCount: epg.programs?.length,
  });

  if (shouldUpdateServerTime) {
    await updateServerTime(store);
  }

  actualizeLiveProgramIndexForChannel(store, channelId);
  actualizeCurrentProgramIndex(store);
};

export const loadEpgForCurrentChannel = async (store: TStore) => {
  const currentChannelId = currentChannelIdSelector(store);
  if (currentChannelId) {
    try {
      await loadEpgForChannel(store, currentChannelId);
    } catch (err) {
      log.error(err);
    }
  } else {
    log.error('loadEpgForCurrentChannel', 'invalid currentChannelId', currentChannelId);
  }
};

export const actualizeCurrentProgramIndex = (store: TStore) => {
  if (store.player.video.playingTimeMs) {
    store.tvEpg.currentProgramIndex = programIndexForChannelSelector(
      store,
      store.player.video.playingTimeMs
    );
  }
};

export const actualizeLiveProgramIndexForChannel = (store: TStore, channelId: string) => {
  if (store.tvEpg.list[channelId]) {
    Vue.set(
      store.tvEpg.list[channelId],
      'liveProgramIndex',
      programIndexForChannelSelector(store, Date.now(), channelId)
    );
  }
};

export const resetSearchQuery = (store: TStore) => {
  store.tvEpg.searchQuery = '';
};
