<template>
  <div
    v-if="showOverlay"
    class="profile-confirmation-overlay with-side-padding scroll-wrap"
    :class="theme"
  >
    <div class="container container-1440">
      <LogoSequoia class="top" :disabled="true" />

      <div class="middle">
        <div v-if="!chosenProfileWithPin && profilesLength >= 1" class="step choose-profile">
          <div class="title" v-html="getTranslation('choose_profile')" />
          <div class="row">
            <ProfileCard
              v-for="profile in profiles"
              :key="profile.id"
              class="col-desktop-2 col-desktop-s-3 col-tablet-4 col-mobile-2"
              :profile="profile"
              @click="selectProfile(profile)"
            />
          </div>

          <div class="text-center">
            <span
              class="caption2 color-tertiary"
              v-text="getTranslation('adult_profile_pined')"
            />&nbsp;<a
              href=""
              class="link caption2 color-tertiary"
              @click.prevent="goToAdult"
              v-text="getTranslation('here')"
            />
          </div>
        </div>

        <div v-if="chosenProfileWithPin" class="step enter-pin">
          <div class="title" v-html="getTranslation('enter_pin')" />
          <div class="row">
            <ProfileCard
              v-if="chosenProfile"
              class="col-desktop-2 col-desktop-s-3 col-tablet-4 col-mobile-2"
              :profile="chosenProfile"
              :selected="true"
            />
          </div>
          <InputText
            v-model="pin"
            :icon-leading="IconLockOn"
            icon="visibility"
            input-category="floating"
            autocomplete="off"
            size="large"
            :status="error ? 'error' : undefined"
            :message="error"
            :maxlength="4"
            :allow-digits-only="true"
            :label="getTranslation('pin_code')"
            :description="getTranslation('enter_4_characters')"
            @keyup.enter="changeProfile"
          />
          <button
            v-if="profilesLength !== 1"
            type="button"
            class="link action-tertiary"
            @click="resetChosenProfile"
            v-html="getTranslation('choose_different_profile')"
          />
        </div>
      </div>

      <div class="bottom">
        <div>
          <span class="note" v-html="getTranslation('you_have_entered_account')" />
          <span>&nbsp;</span>
          <span class="account-name bold" v-text="accountName" />
        </div>
        <ButtonSignOut class="mt-8 mb-64" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonSignOut from 'src/components/personal/ButtonSignOut.vue';
import ProfileCard from 'src/components/personal/ProfileCard.vue';
import { TProfile } from 'src/api/auth/types';
import { actions } from 'src/store/actions';
import logger from 'src/utils/logger';
import LogoSequoia from 'src/components/auth/parts/LogoSequoia.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import IconLockOn from 'src/svg/lock-on.svg';
import { localStore } from 'src/utils/storage';
import { Watch } from 'vue-property-decorator';

const log = logger('profile-confirmation-overlay');

@Component({
  components: { InputText, LogoSequoia, ProfileCard, ButtonSignOut },
})
export default class ProfileConfirmationOverlay extends SequoiaComponent {
  IconLockOn = IconLockOn;
  chosenProfile?: TProfile | null = null;
  pin = '';
  error = '';

  @Watch('pin')
  async onPinChange(val: string) {
    if (val.length >= 4 && this.chosenProfile) {
      await this.changeProfile(this.chosenProfile);
    }
  }

  get showOverlay() {
    return (
      !this.isAnonymous &&
      this.showProfileConfirmationOverlay &&
      !this.showAgeConfirmationOverlay &&
      this.showOverlayForCurrentProfile
    );
  }

  get showOverlayForCurrentProfile() {
    return this.profilesLength > 1 || (this.profilesLength === 1 && this.profile?.pin_required);
  }

  get showProfileConfirmationOverlay() {
    return this.$store.common.showProfileConfirmationOverlay;
  }

  get showAgeConfirmationOverlay() {
    return this.$store.common.showAgeConfirmationOverlay;
  }

  get chosenProfileWithPin() {
    return this.chosenProfile?.pin_required;
  }

  get accountName() {
    return this.$store.account?.user.login;
  }

  get profile() {
    return this.$store.account?.profile;
  }

  get profiles() {
    return this.$store.account?.profiles;
  }

  get profilesLength() {
    return this.profiles?.length || 0;
  }

  mounted() {
    if (this.profilesLength === 1) {
      this.chosenProfile = this.profiles?.[0];
    }
  }

  setChosenProfile(profile: TProfile) {
    this.chosenProfile = profile;
  }

  resetChosenProfile() {
    this.chosenProfile = null;
  }

  async selectProfile(profile: TProfile) {
    if (profile.pin_required) {
      this.setChosenProfile(profile);
      return;
    }

    // if selected profle is the same as current profile on site
    if (this.profile && profile.id === this.profile.id) {
      localStore.set('profileConfirmed', true);
      this.$store.common.showProfileConfirmationOverlay = false;
      log.info('Profile was successfully confirmed');
      return;
    }

    await this.changeProfile(profile);
  }

  async changeProfile(profile: TProfile) {
    try {
      await actions.profile.change(this.$store, {
        data: {
          Id: profile.id,
          Pin: this.pin || '',
        },
      });

      this.error = '';
      this.pin = '';

      localStore.set('profileConfirmed', true);
      this.$store.common.showProfileConfirmationOverlay = false;
      log.info('Profile was successfully confirmed & changed');

      actions.common.showProfileNotification(
        this.$store,
        `<span>${this.getTranslation('profile_entered')}</span><span>«${profile.name}»</span>`,
        'to-profile'
      );
    } catch (err) {
      this.renderError(err);
    }
  }

  renderError(err: any) {
    actions.profile.setIsProfileLoading(this.$store, false);
    this.error = err?.message || err?.data?.message;
  }

  goToAdult() {
    this.$store.common.showProfileConfirmationOverlay = false;
    this.$router.replace('/adult');
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.profile-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-666);
  width: 100%;
  height: 100%;
  background-color: var(--c-light-100);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .top {
    margin-top: 48px;
    margin-bottom: 96px;

    @include tablet-and-desktop-s {
      margin-top: 32px;
      margin-bottom: 64px;
    }

    @include mobile {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  .middle {
    width: 100%;
    margin-bottom: 48px;

    @include tablet-and-desktop-s {
      margin-bottom: 32px;
    }

    @include mobile {
      margin-bottom: 16px;
    }

    .title {
      margin-bottom: 48px;
      text-align: center;
      @include heading1;

      @include mobile-and-desktop-s {
        @include heading2;
      }

      @include tablet-and-desktop-s {
        margin-bottom: 32px;
      }

      @include mobile {
        margin-bottom: 16px;
      }
    }

    .row {
      justify-content: center;
    }

    .profile-card {
      margin-bottom: 24px;

      @include mobile-and-tablet {
        margin-bottom: 16px;
      }
    }

    .step {
      &.enter-pin {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .link {
          width: fit-content;
        }

        .row {
          width: 100%;

          .profile-card {
            margin-bottom: 8px;
          }
        }
      }
    }

    .input-block {
      margin-bottom: 24px;

      @include mobile-and-desktop-s {
        margin-bottom: 8px;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.dark {
    background-color: var(--c-dark-100);
  }
}
</style>
