import { TStore } from 'src/store/types';

export const urlSelector = (store: TStore) => store.providerInfo?.url || '';

export const salesPhoneSelector = (store: TStore) => store.providerInfo?.sales_phone || null;
export const supportEmailSelector = (store: TStore) => store.providerInfo?.support_email;
export const supportEmailV2Selector = (store: TStore) =>
  store.providerInfoV2?.supportInfo.supportEmail;
export const supportURLSelector = (store: TStore) => store.providerInfo?.support_url_short;
export const supportURLV2Selector = (store: TStore) =>
  store.providerInfoV2?.supportInfo.supportUrl.originalValue;

export const salesPhoneFormattedSelector = (store: TStore) => {
  const phone = salesPhoneSelector(store);
  if (phone && (phone.country_code || phone.region_code || phone.number)) {
    return `${phone.country_code} (${phone.region_code}) ${phone.number}`;
  }
  return '';
};
