<template>
  <div class="selector-single" :class="theme">
    <SelectorSingleDesktop
      v-show="!getShowDropdown()"
      :theme-forced="theme"
      :items="items"
      :selected="selected"
      @select="select"
    />

    <DropdownSequoia
      v-show="getShowDropdown()"
      v-slot="{ value, opened, disabled, toggle, icon }"
      :list="itemsForDropdown"
      data-cy="selector-single"
      width="auto"
      :transform-native-select="true"
      :placeholder="placeholder"
      @select="select"
    >
      <ButtonDropdown
        size="small"
        width="content"
        :placeholder="placeholder"
        :icon="icon ? icon : ''"
        :open="opened"
        :value="value"
        @click="toggle"
      />
    </DropdownSequoia>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import SelectorSingleDesktop from 'src/components/ui/selector-single/SelectorSingleDesktop.vue';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import { SequoiaComponent } from 'src/mixins';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import { TSelectorItem } from 'src/components/ui/selector-single/SelectorSingle.types';
import { getDeviceFlags } from 'src/utils/platform-detector';
import AddResizeListener from 'src/mixins/AddResizeListener';

@Component({
  components: { SelectorSingleDesktop, ButtonDropdown, DropdownSequoia },
})
export default class SelectorSingle extends mixins(SequoiaComponent, AddResizeListener) {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  items!: TSelectorItem[];

  @Prop({ default: 0 })
  selected!: number;

  @Prop({ default: 2 })
  limit!: number;

  @Prop()
  icon?: Record<string, unknown>;

  @Prop()
  iconForButtonDropdown?: Record<string, unknown>;

  @Prop({ default: false })
  convertToDropdownForTablesAndMobile?: boolean;

  @Prop({ default: false })
  showIconForButtonDropdown?: boolean;

  @Prop()
  placeholder?: string;

  @Watch('windowWidth', { immediate: true })
  onWindowWidthChange() {
    this.getShowDropdown();
  }
  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get isMobileOrTablet() {
    return getDeviceFlags().isMobile || getDeviceFlags().isTablet;
  }

  get itemsForDropdown(): TDropdownItem[] {
    return this.items.map((item, i) => ({
      icon: item.icon,
      id: i.toString(),
      key: item.number.toString(),
      index: item.index,
      selected: this.selected === item.index,
      value: item.text,
    }));
  }

  getShowDropdown() {
    return (
      ((this.isMobileOrTablet || (this.windowWidth < 960 && this.isLoaded)) &&
        this.convertToDropdownForTablesAndMobile &&
        this.items.length > 1) ||
      this.limit < this.items.length
    );
  }

  select(item: TDropdownItem | number) {
    if (
      typeof item === 'undefined' ||
      (typeof item === 'object' && typeof item.index !== 'number')
    ) {
      return;
    }

    this.$emit('select', typeof item === 'object' ? item.index : item);
  }
}
</script>

<style lang="scss" scoped>
.selector-single {
  display: flex;
  flex-wrap: wrap;
}
</style>
