<template>
  <button
    class="button circle"
    type="button"
    :class="theme"
    :data-cy="dataCyPrefixed"
    @click="$emit('click')"
  >
    <IconSVG
      v-if="direction === 'right'"
      :svg="iconType === 'arrow' ? IconArrowRight : IconAngleRight"
    />
    <IconSVG
      v-else-if="direction === 'left'"
      :svg="iconType === 'arrow' ? IconArrowLeft : IconAngleLeft"
    />
    <IconSVG
      v-else-if="direction === 'down'"
      :svg="iconType === 'arrow' ? IconArrowDown : IconAngleDown"
    />
    <IconSVG
      v-else-if="direction === 'up'"
      :svg="iconType === 'arrow' ? IconArrowUp : IconAngleUp"
    />
  </button>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import IconAngleRight from 'src/svg/angle-right.svg';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconAngleDown from 'src/svg/angle-down.svg';
import IconAngleUp from 'src/svg/angle-up.svg';
import IconArrowRight from 'src/svg/arrow-right.svg';
import IconArrowLeft from 'src/svg/arrow-left.svg';
import IconArrowDown from 'src/svg/arrow-down.svg';
import IconArrowUp from 'src/svg/arrow-up.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { IconSVG },
})
export default class ButtonCircle extends Vue {
  IconAngleRight = IconAngleRight;
  IconAngleLeft = IconAngleLeft;
  IconAngleDown = IconAngleDown;
  IconAngleUp = IconAngleUp;
  IconArrowRight = IconArrowRight;
  IconArrowLeft = IconArrowLeft;
  IconArrowDown = IconArrowDown;
  IconArrowUp = IconArrowUp;

  @Prop({ default: 'light' })
  theme!: 'light' | 'dark';

  @Prop()
  direction!: string;

  @Prop({ default: 'angle' })
  iconType!: 'angle' | 'arrow';

  @Prop()
  dataCy?: string;

  get dataCyPrefixed() {
    return this.dataCy ? `button-circle-${this.dataCy}` : '';
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.button.circle {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 4px;
  cursor: pointer;
  opacity: 1;

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: var(--z-1);
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: all 0.15s var(--ease-out);
  }

  @include devices-with-hover {
    &:hover {
      &::before {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        transform: scale(1.2);
      }
    }
  }

  &:active {
    &::before {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      transition: none;
      transform: scale(1.15);
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.3;
  }

  .icon {
    z-index: var(--z-2);
    color: var(--c-light-100);
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    &::before {
      background-color: var(--c-light-brand);
    }

    &:active {
      &::before {
        background-color: var(--c-light-brand-darker);
      }
    }
  }

  &.dark {
    &::before {
      background-color: var(--c-dark-brand);
    }

    &:active {
      &::before {
        background-color: var(--c-dark-brand-darker);
      }
    }
  }
}
</style>
