import Component, { mixins } from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import {
  HTMLDivWithFullscreenElement,
  HTMLVideoWithFullscreenElement,
} from 'src/components/player/types';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import GetAndSetWindowWidth from 'src/mixins/GetAndSetWindowWidth';
import Vod from 'src/mixins/Vod';
import THls from 'hls.js';
import IconInfo from 'src/svg/info.svg';
import IconCross from 'src/svg/cross.svg';
import { BROWSERS, getBrowserName, getDeviceFlags, getOsFlags } from 'src/utils/platform-detector';
import logger from 'src/utils/logger';
import {
  CHANNEL_PLAYING_STATISTIC_SAFE_TIME_IN_SECONDS,
  EVENTS,
  PLAYBACK_METHODS,
  PLAYER_ERROR_CODES,
  SCREEN_MIN_WIDTH_FOR_SHOWING_PLAYER,
  THRESHOLDS,
  VIDEO_DISPLAY_MODE,
  VOD_SESSION_USER_EVENT,
} from 'src/constants';
import { getIsFullscreen } from 'src/utils';
import { VodPlayback } from 'src/utils/vod/playback';
import { convertToSeconds } from 'src/utils/time/convert-time';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { videoDataSelector } from 'src/store/vod/selectors';
import { makePath } from 'src/utils/url';

const log = logger('player-vod-archive-mixin');

@Component
export default class PlayerVodArchive extends mixins(Vod, GetAndSetWindowWidth) {
  IconInfo = IconInfo;
  IconCross = IconCross;

  isShortJumpDisabled = false;
  isShortJumping = false;

  videoWidth = 0;
  scrollPosition = 0;

  hls?: THls | null;

  lastPauseTime = 0;
  changeEpisode = false; // used for playback sessions

  everySecondIntervalId?: NodeJS.Timeout;
  waitingLoaderTimeoutId?: NodeJS.Timeout;

  playbackSession: VodPlayback | null = null;

  showSmokingWarning = false;
  iosFullscreenAvailable = true;
  canplay = false;

  networkChange = {
    reloadAttempt: 1,
    maxAttempts: 3,
  };

  @Ref('player')
  readonly refPlayer?: HTMLDivWithFullscreenElement;

  @Ref('video')
  readonly refVideo?: HTMLVideoWithFullscreenElement;

  @Ref('overlay')
  readonly refOverlay?: HTMLDivElement;

  @Watch('showSmokingWarning')
  onShowedSmokingWarning(show: boolean) {
    if (show) {
      setTimeout(() => {
        this.showSmokingWarning = false;
      }, 5000);
    }
  }

  @Watch('$store.vod.states.sourcesLoaded')
  async onVodSourcesLoadedChange(val: boolean) {
    if (!val) {
      await this.closePlayer();
      let url = '/';
      if (this.playerType === 'vod') {
        url = `/vod/${this.sourceId}`;
      } else if (this.playerType === 'archive') {
        url = '/archive';
      }
      history.pushState({}, '', makePath(url));
    }
  }

  @Watch('subtitleDisplay')
  onSubtitleDisplayChange(val: boolean) {
    if (this.hls) {
      this.hls.subtitleDisplay = val;
      this.hls.subtitleTrack = val ? 0 : -1;
      log.info('subtitleDisplay has been set to ', val);
    }
  }

  get isShowSmokingWarning() {
    return (
      this.$store.siteConfig?.showSmokingWarning &&
      parseInt(this.title?.preview?.ageRating || '') >= 18 &&
      this.showSmokingWarning
    );
  }

  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get sourceUrl() {
    return this.isArchive ? `/archive` : `/vod/${this.sourceId}`;
  }

  get metaTitle() {
    return this.sourceId === 'archive'
      ? this.getTranslation('archive_catalog_meta_title')
      : this.getTranslation('vod_title') + ' ' + this.sourceId;
  }

  get howManyTimesBodyScrollWasLocked() {
    return selectors.common.howManyTimesBodyScrollWasLockedSelector(this.$store);
  }

  get isPlayerLoading() {
    return selectors.player.isLoadingSelector(this.$store);
  }

  get isOffline() {
    return selectors.common.isOfflineSelector(this.$store);
  }

  get wasPlayingBeforeOffline() {
    return selectors.player.wasPlayingBeforeOfflineSelector(this.$store);
  }

  get isPlayerPlaying() {
    return selectors.player.isPlayingSelector(this.$store);
  }

  get isOverlayVisible() {
    return selectors.player.isOverlayVisibleSelector(this.$store);
  }

  get shouldHideOverlay() {
    return !this.isOverlayVisible && this.isPlayerPlaying && !this.hasPlayerError;
  }

  get isVideoScaled() {
    return selectors.player.isVideoScaledSelector(this.$store);
  }

  get hasPlayerError() {
    return selectors.player.hasErrorSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get hasVideoEnded() {
    return selectors.player.hasVideoEndedSelector(this.$store);
  }

  get isVideoMuted() {
    return selectors.player.isVideoMutedSelector(this.$store);
  }

  get playingVideoUrl() {
    return selectors.vod.playingUrlSelector(this.$store);
  }

  get videoUrl() {
    if (this.isIvi) {
      return this.videoData.iviContentInfo?.files[0].url;
    } else {
      return this.playingVideoUrl;
    }
  }

  get isMobileDevice() {
    return getDeviceFlags().isMobile;
  }

  get isMobile() {
    return this.windowWidth < SCREEN_MIN_WIDTH_FOR_SHOWING_PLAYER || this.isMobileDevice;
  }

  get timelineDurationMs() {
    return selectors.archive.timelineDurationMsSelector(this.$store);
  }

  get isIos() {
    return getOsFlags().isIos;
  }

  get isIvi() {
    return [PLAYBACK_METHODS.ivi, PLAYBACK_METHODS.iviTwoSteps].includes(this.playbackMethod);
  }

  get useHls() {
    return !this.isIos && !this.isIvi;
  }

  get useShaka() {
    return [PLAYBACK_METHODS.amedia2V1, PLAYBACK_METHODS.megogoV1].includes(this.playbackMethod);
  }

  get lastPause() {
    return selectors.pauses.lastVodPause(this.$store);
  }

  get isSavedPause() {
    return selectors.vod.isSavedPause(this.$store);
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get showNotificationWithDetails() {
    return selectors.common.showNotificationWithDetailsSelector(this.$store);
  }

  get videoData() {
    return selectors.vod.videoDataSelector(this.$store);
  }

  get playbackMethod() {
    return selectors.vod.playbackMethodSelector(this.$store);
  }

  get playingCurrentTime() {
    return selectors.vod.playingCurrentTimeSelector(this.$store);
  }

  get currentTimeForVod() {
    return selectors.vod.playingCurrentTimeSelector(this.$store);
  }

  get currentTimeForArchive() {
    return convertToSeconds(selectors.vod.playingCurrentTimeSelector(this.$store), 'millisecond');
  }

  get currentTime() {
    return this.type === 'vod' ? this.currentTimeForVod : this.currentTimeForArchive;
  }

  get durationForVod() {
    return this.videoData.duration;
  }

  get durationForArchive() {
    return convertToSeconds(
      selectors.archive.timelineDurationMsSelector(this.$store),
      'millisecond'
    );
  }

  get duration() {
    return this.isArchive ? this.durationForArchive : this.durationForVod;
  }

  get playerError() {
    return selectors.player.errorSelector(this.$store);
  }

  get playerErrorMessage() {
    return this.playerError?.message || '';
  }

  get hasSubtitles() {
    return selectors.player.hasSubtitlesSelector(this.$store);
  }

  get subtitleDisplay() {
    return selectors.player.subtitleDisplaySelector(this.$store);
  }

  // ------------------------------------------------------
  // VOD only
  // ------------------------------------------------------
  get displayMode() {
    if (this.isFullscreen) {
      return VIDEO_DISPLAY_MODE.fullscreen;
    }
    if (this.isPlayerMinimized && !this.isFullscreen) {
      return VIDEO_DISPLAY_MODE.audioOnly;
    }

    return VIDEO_DISPLAY_MODE.windowed;
  }

  get playbackSessionEventData() {
    return {
      displayMode: this.displayMode,
      destroy: false,
      loadingNewState: false,
    };
  }

  // -----------------------------------------

  /**
   * On window offline event
   *
   * Video should be paused and HLS instance should be destroyed to free up resources
   * Note: wasPlayingBeforeOffline flag should be set to the value of isPlaying falg
   * in order to detect whether video was playing before disconnection
   * and resume the same state in onGoOnline event handler
   */
  onGoOffline() {
    log.warning('connection is offline');
    actions.common.setIsOffline(this.$store, true);
    actions.player.setWasPlayingBeforeOffline(this.$store, this.isPlayerPlaying);
    this.showPlayerNetworkError();
  }

  async showTitleDetails() {
    const titleId = this.playingTitle?.preview?.id;

    if (titleId) {
      await actions.vod.showTitleDetails(this.$store, this.sourceId, titleId, true);
    } else {
      log.warning("titleId was not found; cannot show title's details");
    }

    if (getIsFullscreen()) {
      actions.player.exitFullscreen(this.$store);
    }

    this.gaEvent({
      category: 'player_controls',
      action: 'Клик по кнопке "Info"',
      title_id: titleId,
      vod_name: this.sourceId,
    });
  }

  showPlayerNetworkError() {
    if (this.isFullscreen) {
      actions.player.exitFullscreen(this.$store);
    }
    actions.player.setError(
      this.$store,
      PLAYER_ERROR_CODES.INTERNAL,
      this.getTranslation('vod_network_error')
    );
    this.refVideo?.pause();
    this.destroyHls();
  }

  clearAllErrors() {
    actions.player.clearError(this.$store);
  }

  destroyHls() {
    if (this.hls) {
      log.info('destroyHls');
      this.hls.destroy();
    }
    this.hls = null;
  }

  async destroyShaka() {
    // to be overloaded
  }

  setShowNotificationAuthAndReg() {
    actions.common.showNotificationAuthAndReg(this.$store);
  }

  closeNotificationWithDetails() {
    actions.common.setShowNotificationWithDetails(this.$store, false);
  }

  async updatePlaybackSession() {
    try {
      if (
        !this.changeEpisode &&
        this.playingTitleId === this.playbackSession?.titleId &&
        !this.playbackSession?.checkSession()
      ) {
        await actions.vod.playVideo(
          this.$store,
          this.sourceId,
          this.titleFromParams || this.title,
          this.episodeIdFromParams || this.playingEpisodeId
        );
        actions.player.setLoaded(this.$store, true);
      }
      if (this.videoData.playbackSession && !this.playbackSession?.checkSession()) {
        this.playbackSession = new VodPlayback(
          this.videoData.playbackSession,
          this.refVideo,
          this.playingTitleId,
          this.episodeIdFromParams || this.playingEpisodeId
        );
      }
    } catch {
      // do nothing, errors should already be logged and printed
    }
  }

  showMaxQualityError() {
    if (getBrowserName() === BROWSERS.firefox) {
      // firefox seems to be working fine
      return;
    }
    // select first (auto) rendition
    this.showOverlay();
    actions.player.setAlert(this.$store, this.getTranslation('player_tv_max_quality_error'));
    actions.tvCurrentChannel.setRenditionIndex(this.$store, 0);
    this.$events.emit(EVENTS.player.reloadStream);
    log.error('max quality error');
  }

  async attachUrl() {
    if (!this.refVideo) {
      log.info('refVideo is not ready yet');
      return;
    }

    if (this.isArchive) {
      actions.archive.updateVideoUrlTime(this.$store);
      log.info('attachUrl: update video URL time for Archive');
    }

    if (!this.videoUrl) {
      log.error('attachUrl: invalid videoUrl', `'${this.videoUrl}'`);
      return;
    }

    if (this.hls && (this.isArchive || (!this.isArchive && this.useHls))) {
      log.info('attaching URL via HLS loadSource:', `'${this.videoUrl}'`);
      this.hls.loadSource(this.videoUrl);
    } else {
      log.info('attaching URL directly:', this.videoUrl);
      this.refVideo.src = this.videoUrl;
    }
  }

  sendStatistics() {
    const secondsPlayed = this.duration;
    if (secondsPlayed < CHANNEL_PLAYING_STATISTIC_SAFE_TIME_IN_SECONDS) {
      return;
    }

    actions.player.sendStats(this.$store);
  }

  async sendStatOnSessionEnd() {
    if (this.$store.player.video.isPlaying) {
      this.sendStatistics();
    }
  }

  // -----------------------------------------
  // Player events
  // -----------------------------------------

  togglePlayPause() {
    // togglePlayPause is reinitialized in player components
    // it is needed here to avoid errors in mixin
  }

  async savePause() {
    await actions[this.type].savePause(this.$store);
  }

  async pause() {
    if (!this.isPlayerPlaying || !this.refVideo) {
      return false;
    }

    log.info('pause');
    this.showOverlay();

    if (this.isArchive && this.hls) {
      this.hls.stopLoad();
    }

    this.refVideo.pause();

    if (this.playbackSession) {
      await this.playbackSession.userEvent(VOD_SESSION_USER_EVENT.stop, {
        ...this.playbackSessionEventData,
      });
    }
  }

  async playNextEpisode() {
    this.releaseOverlay(); // force unfreeze overlay for mobile/tablet devices
    actions.player.setIsPlaying(this.$store, false);
    this.lastPauseTime = 0;
    actions.vod.setIsSavedPause(this.$store, false);

    if (this.playbackSession) {
      this.changeEpisode = true;
      await this.playbackSession.userEvent(VOD_SESSION_USER_EVENT.stop, {
        ...this.playbackSessionEventData,
        destroy: true,
        loadingNewState: true,
      });
    }

    let nextEpisode: TVODTitlePreviewEnhanced | undefined;
    let nextEpisodeId = '';

    if (this.seasonsLength) {
      // for titles with seasons
      // play next episode in current season or 1st episode in next season
      if (this.nextSeasonNum !== undefined && this.currentSeasonNum !== this.nextSeasonNum) {
        actions.vod.setCurrentSeasonNum(this.$store, this.nextSeasonNum);
        actions.vod.setCurrentSeasonNumForNav(this.$store, this.nextSeasonNum);
      }

      if (this.currentSeasonNum !== this.currentSeasonNumForNav) {
        actions.vod.setCurrentSeasonNumForNav(this.$store, this.currentSeasonNum);
      }

      if (this.nextEpisodeNum === undefined) {
        return;
      }

      nextEpisode = this.getEpisodes(this.nextSeasonNum)?.[this.nextEpisodeNum];

      // for titles without seasons
      // play next episode in line
    } else if (this.getEpisodes()?.length) {
      nextEpisode = this.getEpisodes()?.[this.playingEpisodeWithoutSeasonIndex + 1];
    }

    nextEpisodeId = nextEpisode?.id || nextEpisode?.preview?.id || '';

    if (this.isArchive) {
      if (nextEpisode) {
        await actions.archive.playNextEpisode(this.$store, nextEpisode);
      }
    } else {
      try {
        await actions.vod.playVideo(this.$store, this.sourceId, this.playingTitle, nextEpisodeId);
        await this.updatePlaybackSession();
      } catch {
        // do nothing, errors should already be logged and printed
      }
    }
    this.gaEvent({
      category: 'player_controls',
      action: 'Клик по кнопке "Далее"',
      vod_name: this.sourceId,
    });
  }

  async onPlay() {
    log.info('Player event -> PLAY');
    actions.player.setIsPlaying(this.$store, true);
    actions.player.stopLoading(this.$store);

    if (this.waitingLoaderTimeoutId) {
      clearTimeout(this.waitingLoaderTimeoutId);
    }
  }

  async onPause() {
    log.info('Player event -> PAUSE');
    actions.player.setIsPlaying(this.$store, false);
    actions.player.stopLoading(this.$store);

    if (this.playingCurrentTime > 0) {
      this.lastPauseTime = this.playingCurrentTime;
      await this.savePause();
    }

    if (this.waitingLoaderTimeoutId) {
      clearTimeout(this.waitingLoaderTimeoutId);
    }
  }

  onCanplay() {
    log.info('Player event -> CANPLAY');
    this.canplay = true;
    actions.player.stopLoading(this.$store);
  }

  // when video is buffering
  onWaiting() {
    log.info('Player event -> WAITING');
    this.canplay = false;
    if (this.playbackSession && !this.playbackSession.bufferTimerStarted) {
      this.playbackSession.bufferStart();
    }

    if (!getOsFlags().isIos) {
      if (this.waitingLoaderTimeoutId) {
        clearTimeout(this.waitingLoaderTimeoutId);
      }

      this.waitingLoaderTimeoutId = setTimeout(() => {
        if (!this.canplay) {
          actions.player.startLoading(this.$store);
        }
      }, 500);
    }
  }

  onPlaying() {
    log.info('Player event -> PLAYING');
    actions.player.setIsPlaying(this.$store, true);
    actions.player.stopLoading(this.$store);

    if (this.playbackSession && this.playbackSession.bufferTimerStarted) {
      this.playbackSession.bufferStop();
    }

    if (this.waitingLoaderTimeoutId) {
      clearTimeout(this.waitingLoaderTimeoutId);
    }
  }

  actualizeVolume() {
    actions.player.actualizeVolume(this.$store, this.refVideo);
  }

  // -----------------------------------------
  // Player controls
  // -----------------------------------------
  async closePlayer() {
    await this.destroyShaka();
    if (this.refVideo) {
      this.refVideo.src = '';
    }

    await this.pause();

    if (!this.hasPlayerError) {
      await this.savePause();
    }

    this.destroyHls();

    this.resetPlayer();

    if (this.$route.name?.includes('archive') || this.$route.path?.includes('vod/')) {
      history.pushState({}, '', makePath(this.sourceUrl));
      actions.seo.setMetaTitle(this.$store, this.metaTitle);
    }
  }

  resetPlayer() {
    this.clearAllErrors();
    actions.tvCurrentChannel.reset(this.$store);
    actions.player.stopLoading(this.$store);
    actions.player.setLoaded(this.$store, false);
    actions.player.setIsPlaying(this.$store, false);
    actions.player.setHasSubtitles(this.$store, false);
    actions.player.hidePlayer(this.$store);
    actions.player.setOverlayVisibility(this.$store, false);
    actions.player.releaseOverlay(this.$store);
    actions.vod.resetVideoData(this.$store);
    actions.common.unlockBodyScroll(this.$store);
  }

  setCurrentTime(value: number) {
    if (this.refVideo) {
      const time = this.isArchive
        ? value
        : this.duration
        ? value <= this.duration
          ? value
          : this.duration
        : value;
      actions.vod.setPlayingCurrentTime(this.$store, time);

      if (this.isArchive) {
        actions.archive.updateVideoUrlTime(this.$store);
      } else {
        this.refVideo.currentTime = time;
      }
    }
  }

  async shortJump(direction: number) {
    if (this.isShortJumpDisabled || this.hasPlayerError || !this.refVideo) {
      return;
    }

    this.isShortJumping = true;

    if (!this.isOverlayVisible) {
      this.showOverlay();
    }

    if (this.isArchive) {
      const timeToSet = this.playingCurrentTime + direction * THRESHOLDS.tv.shortJumpLength;
      this.setCurrentTime(
        timeToSet <= 0
          ? 0
          : timeToSet >= this.timelineDurationMs
          ? this.timelineDurationMs
          : timeToSet
      );
    } else {
      this.setCurrentTime(
        this.refVideo.currentTime + direction * (THRESHOLDS.tv.shortJumpLength / 1000)
      );

      if (this.playbackSession && this.isPlayerPlaying && this.playbackSession.checkSession()) {
        await this.playbackSession.userEvent(VOD_SESSION_USER_EVENT.timeline, {
          ...this.playbackSessionEventData,
        });
      }
    }

    const { titleId, sourceId } = videoDataSelector(this.$store);
    this.gaEvent({
      category: 'player_controls',
      action: direction < 0 ? 'Rewind' : 'Forward',
      title_id: titleId,
      vod_name: sourceId || 'archive',
    });
  }

  onKeydown(event: KeyboardEvent) {
    if ((event.target as HTMLInputElement)?.tagName?.toLowerCase() === 'input') {
      return;
    }

    const code = (event.code === 'KeyF' ? event.code : event.key || event.code)?.toLowerCase();

    // when cmnd/ctrl is pressed in conjunction with other keys -> do nothing
    if (!code || event.metaKey || event.ctrlKey) {
      return;
    }

    switch (code) {
      case 'space':
      case ' ':
        event.preventDefault();
        this.togglePlayPause();
        break;

      case 'arrowleft':
        event.preventDefault();
        this.shortJump(-1);
        break;

      case 'arrowright':
        event.preventDefault();
        this.shortJump(1);
        break;

      case 'keyf':
        event.preventDefault();
        if (this.howManyTimesBodyScrollWasLocked > 1) {
          return;
        }
        this.toggleFullscreen();
        break;
    }
  }

  onKeyup(event: KeyboardEvent) {
    const code = (event.key || event.code)?.toLowerCase();
    if (!code) {
    }

    switch (code) {
      case 'arrowleft':
        this.isShortJumpDisabled = false;
        this.isShortJumping = false;
        break;
      case 'arrowright':
        this.isShortJumpDisabled = false;
        this.isShortJumping = false;
        break;
    }
  }

  // -----------------------------------------
  // Overlay
  // -----------------------------------------

  freezeOverlay() {
    actions.player.freezeOverlay(this.$store);
  }

  releaseOverlay() {
    actions.player.releaseOverlay(this.$store);
  }

  showOverlay() {
    actions.player.showOverlay(this.$store);
  }

  tryFocusOnPlayerContainer() {
    if (typeof this.refOverlay?.focus === 'function') {
      this.refOverlay.focus();
    }
  }

  // -----------------------------------------
  // Fullscreen
  // -----------------------------------------
  toggleFullscreen() {
    if (!this.refPlayer) {
      return;
    }

    if (!getIsFullscreen()) {
      if (this.isMobile && this.refVideo?.webkitEnterFullScreen) {
        this.refVideo?.webkitEnterFullScreen();
        actions.player.toggleFullscreen(this.$store);
      } else {
        actions.player.goFullscreen(this.$store, this.refPlayer);
      }

      this.gaEvent({ category: 'player_controls', action: 'Включить fullscreen' });
      this.scrollPosition = window.pageYOffset;
    } else {
      actions.player.exitFullscreen(this.$store);
      this.gaEvent({ category: 'player_controls', action: 'Выйти из fullscreen' });
    }

    if (!this.isArchive) {
      this.playbackSession?.userEvent(VOD_SESSION_USER_EVENT.displayMode, {
        ...this.playbackSessionEventData,
      });
    }
  }

  actualizeFullscreen() {
    const isFullscreen = getIsFullscreen();
    this.$store.player.video.isFullscreen = isFullscreen;
    this.videoWidth = this.refVideo?.offsetWidth || 0;

    if (!isFullscreen) {
      window.scrollTo(0, this.scrollPosition);
    }
  }

  exitFullScreenOnAndroid() {
    actions.player.exitFullscreenOnAndroid(this.$store, this.isFullscreen);
  }

  // -----------------------------------------
  // Clear timeouts
  // -----------------------------------------

  clearEverySecondinterval() {
    if (this.everySecondIntervalId) {
      clearInterval(this.everySecondIntervalId);
    }
  }
}
