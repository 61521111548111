<template>
  <div class="trial-container with-side-and-bottom-padding dark" :class="{ 'qr-acquiring': token }">
    <QuickSubscribeBackground />
    <div class="qs-content">
      <h2 v-html="prioritySubscription.renderingData.summary || '${summary}'" />
      <div v-if="token && $store.account.user" class="about-subscription-note">
        <span v-html="getTranslation('qr_payment_for_account')" />
        <span v-text="$store.account.user.login" />
      </div>
      <div
        class="description"
        v-html="prioritySubscription.renderingData.description || '${description}'"
      />
      <ButtonDefault
        :is-loading="isLoading"
        @click="
          $emit('createSub');
          gaEvent({
            category: 'acquiring',
            action: `Клик по кнопке 'Подключить'`,
            qs_qr: $route.query.token ? 'qr' : 'qs',
            offer_id: prioritySubscription.offerId,
          });
        "
      >
        <span v-html="prioritySubscription.renderingData.call_to_action || '${call_to_action}'" />
      </ButtonDefault>
      <div
        v-if="prioritySubscription.renderingData.disclaimer"
        class="disclaimer mt-24"
        v-html="prioritySubscription.renderingData.disclaimer"
      />
    </div>
  </div>
</template>

<script lang="ts">
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { selectors } from 'src/store/selectors';
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    QuickSubscribeBackground,
    ButtonDefault,
  },
})
export default class AboutTrial extends SequoiaComponent {
  @Prop()
  isLoading?: boolean;

  token = '';

  get prioritySubscription() {
    return selectors.QS.prioritySubscriptionSelector(this.$store);
  }

  mounted() {
    this.token = this.$route.query.token as string;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/link';

.trial-container {
  h2 {
    margin-bottom: 24px;
  }

  .about-subscription-note {
    margin-bottom: 16px;
  }

  .description {
    margin-bottom: 200px;
    white-space: pre-line;
  }

  .qs-content .disclaimer {
    color: var(--c-dark-font-secondary);
    @include body2;

    a {
      @extend %light-link-nav-underlined;
    }
  }
}
</style>
