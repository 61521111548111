<template>
  <div class="content-separator" :class="theme" />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ContentSeparator extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
.content-separator {
  width: 100%;
  height: 1px;

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    background-color: var(--alpha-light-4);
  }

  &.dark {
    background-color: var(--alpha-dark-4);
  }
}
</style>
