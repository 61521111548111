import Component, { mixins } from 'vue-class-component';
import GetAndSetWindowWidth from 'src/mixins/GetAndSetWindowWidth';

@Component
export default class AddResizeListener extends mixins(GetAndSetWindowWidth) {
  async mounted() {
    this.$_getAndSetWindowWidth();
    window.addEventListener('resize', this.$_getAndSetWindowWidth);
    this.$on('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.$_getAndSetWindowWidth);
    });
  }
}
