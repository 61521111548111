import { TStore } from 'src/store/types';
import { localStore } from 'src/utils/storage';

import adultOnboarding from 'src/store/adult-onboarding/store';
import authAndReg from 'src/store/auth-and-reg/store';
import common from 'src/store/common/store';
import cypress from 'src/store/cypress/store';
import home from 'src/store/home/store';
import pauseData from 'src/store/pauses/store';
import payment from 'src/store/payment/store';
import player from 'src/store/player/store';
import providerInfo from 'src/store/provider-info/store';
import QS from 'src/store/quick-subscribe/store';
import registration from 'src/store/registration/store';
import search from 'src/store/search/store';
import seo from 'src/store/seo/store';
import tvEpg from 'src/store/tv-epg/store';
import tvChannels from 'src/store/tv-channels/store';
import vod from 'src/store/vod/store';

export let store: TStore;
store = {
  premiumPage: {
    loaded: false,
    provider: undefined,
  },
  personalArea: undefined,
  adultOnboarding,
  common,
  cypress,
  home,
  payment,
  player,
  QS,
  search,
  seo,
  registration,
  registrationResult: null,
  tvEpg,
  tvChannels,
  authAndReg,

  pauseData,
  vod,

  theme: 'light',
  styles: '',
  header: null,
  footer: null,
  cmsPages: [],
  feSessionId: '',
  imagesMTimes: {},
  profileRestrictions: [],
  profileSubscriptions: [],
  account: null,
  appInfo: null,
  page: null,
  pages: [],
  pageFolders: [],
  languageCode: '',
  defaultLanguage: '',
  languages: [],
  translations: {},
  components: {},

  flags: {
    tvDataState: {
      loading: false,
      loaded: false,
    },
    channelCollectionsDataState: {
      loading: false,
      loaded: false,
    },
  },

  siteConfig: null,
  regions: [],
  providers: [],
  providerInfo,
  contactsVerification: null,
  verificationResult: null,
  availableLogins: null,
  loginResult: null,
  sendPasswordResult: null,
  isPhoneMode: true, // otherwise, it's email mode
  secretSanta: !!localStore.get('secretSanta') || false,
  modalCallback: false,
  tvCurrentChannel: null,
  incompletePhone: null,
  incompletePhoneInitialFlag: true,
  isError500: false,

  notifications: {
    favorite: [],
    other: [],
    profile: [],
  },
  page404: null,
  QSConfig: {},
  brandingMethods: [],

  gaParams: {},
};

export const setStore = (newStore: TStore): void => {
  store = newStore;
};
