import { AxiosRequestConfig } from 'axios';
import { TStore } from 'src/store/types';
import * as api from 'src/api';
import { TReqConfig } from 'src/api/utils';
import { wlDetector } from 'src/utils';

export const loadPaymentMethod = async (store: TStore, force = false, config?: TReqConfig) => {
  if (wlDetector().isSmotreshka && (!store.payment.method || force)) {
    store.payment.method = await api.quickSubscribe.paymentMethod(config);
  }
};

export const loadPaymentSubscriptions = async (store: TStore, config?: AxiosRequestConfig) => {
  if (wlDetector().isSmotreshka) {
    store.payment.subscriptions = await api.quickSubscribe.subscriptions(config);
  }
};

export const loadPaymentSubscriptionsV2 = async (store: TStore, config?: AxiosRequestConfig) => {
  const { subscriptions } = await api.quickSubscribe.subscriptionsV2(config);
  store.payment.subscriptionsV2 =
    subscriptions?.filter((subscription) => subscription?.mainOffer?.renderingMethods?.length) ||
    [];
};

export const removePaymentMethod = async (store: TStore) => {
  await api.quickSubscribe.removePaymentMethod();
  store.payment.method = {};
};
