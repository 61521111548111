import Vue from 'vue';
import VueStash from 'vue-stash';
import VueEvents from 'vue-events';
import ScrollTo from 'vue-scrollto';
import vOutsideEvents from 'vue-outside-events';
import VueProgressBar from 'vue-progressbar';
import PortalVue from 'portal-vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import * as Sentry from '@sentry/vue';
import { BUGSNAG_API_KEY, ENABLE_CYPRESS_MOCK, SENTRY_DSN } from 'src/constants';
import { TStore } from 'src/store/types';
import { getEnvironment } from 'src/utils/env';
import { createRouter } from './router';
import App from './App.vue';
import packageJson from '../package.json';
import 'src/styles/index.scss';

Vue.use(VueStash);
Vue.use(ScrollTo);
Vue.use(vOutsideEvents);
Vue.use(PortalVue);

if (process.env.VUE_ENV === 'client') {
  Vue.use(VueProgressBar, {
    transition: {
      speed: '1s',
      opacity: '0.6s',
      termination: 1000,
    },
    autoFinish: false,
  });
  Vue.use(VueEvents);
}

if (!ENABLE_CYPRESS_MOCK && process.env.NODE_ENV === 'production') {
  // error-reporting tool, see https://lifestream.atlassian.net/browse/SEQ-1493
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    appVersion: packageJson.version,
    plugins: [new BugsnagPluginVue() as any],
  });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue?.installVueErrorHandler(Vue);
}

let app: any = null;
let router: any = null;
let wasSentryInitialized = false;

export const getApp = (store?: TStore, ssrHost?: string) => {
  if (store) {
    router = createRouter(store);
    if (process.env.NODE_ENV === 'production' && !wasSentryInitialized) {
      Sentry.init({
        Vue,
        dsn: SENTRY_DSN,
        environment: getEnvironment(ssrHost),
        release: packageJson.version,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new Sentry.Replay(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
      wasSentryInitialized = true;
    }
    app = new Vue({
      router,
      data: { store },
      render: (h) => h(App),
    });
    if (process.env.NODE_ENV === 'production') {
      Vue.config.errorHandler = (err) => {
        Sentry.captureException(err);
      };
    }
  }
  return { app, router };
};
