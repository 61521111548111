import { selectors } from 'src/store/selectors';
import { TStore } from 'src/store/types';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'src/utils/time/date-time';
import { enToRu } from 'src/utils/language';
import { TProgramEnhanced } from 'src/api/channels/types';
import { serverTimeMsSelector } from 'src/store/app-info/selectors';
import { TChannelId, TTvChannelsList } from 'src/store/tv-channels/types';

export const getDayProgramsByChannelId = (store: TStore, channelId: string, epochDay: number) => {
  const allPrograms = selectors.tvEpg.programsSelector(store, channelId) || [];
  if (!allPrograms.length) {
    return allPrograms;
  }

  const pickedDate = DateTime.fromEpochDay(epochDay).getDate();
  const searchQuery = store.tvEpg?.searchQuery?.toLowerCase().replace(/ё/gi, 'е');

  const dayPrograms = allPrograms.filter((program: TProgramEnhanced) => {
    const dateIsOk = new Date(program.startMs).getDate() === pickedDate;
    if (!dateIsOk) {
      return false;
    }
    if (!searchQuery) {
      return true;
    }

    const title = program.metaInfo?.title?.toLowerCase().replace(/ё/gi, 'е') || '';
    return title.indexOf(searchQuery) >= 0 || title.indexOf(enToRu(searchQuery)) >= 0;
  });

  return sortBy(dayPrograms, (el) => el.startMs);
};

export const getCalendarByChannelId = (store: TStore, channelId: string) => {
  const programs = selectors.tvEpg.programsSelector(store, channelId);

  if (!programs.length) {
    return [];
  }

  const firstStartTime = new Date(programs[0].startMs);
  const lastStartTime = new Date(programs[programs.length - 1].startMs);

  const epochFirstDay = DateTime.toEpochDay(firstStartTime);
  const epochLastDay = DateTime.toEpochDay(lastStartTime);
  const durationInDays = epochLastDay - epochFirstDay;

  return new Array(durationInDays)
    .fill(null)
    .map((el, index) => {
      const epochDay = epochFirstDay + index;
      const date = DateTime.fromEpochDay(epochDay);
      const dayOfWeek = new Date(date).toLocaleString('ru', {
        weekday: 'short',
      });
      return {
        date,
        epochDay,
        dayString: DateTime.getDFullMonth(date),
        dayOfWeek: `${dayOfWeek[0].toUpperCase()}${dayOfWeek[1]}`,
        programs: getDayProgramsByChannelId(store, channelId, epochDay),
      };
    })
    .filter((day) => day.programs.length > 0);
};

export const getChannelById = (channels: TTvChannelsList, channelId: TChannelId) =>
  channels[channelId];

export const isProgramLive = (store: TStore, program: TProgramEnhanced) => {
  const serverTimeMs = serverTimeMsSelector(store);
  return serverTimeMs > program.startMs && serverTimeMs < program.endMs;
};

export const isProgramInFuture = (program: TProgramEnhanced) =>
  program.startMs > new Date().getTime();
