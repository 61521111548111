<template>
  <div class="verification-subscription-info with-side-and-bottom-padding">
    <h2 class="dark" v-html="getTranslation('confirm_verify_title')" />
    <div v-if="prioritySubscription" class="description mb-32" v-html="subscriptionInfo" />
  </div>
</template>
<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import Component from 'vue-class-component';

@Component
export default class SubscriptionInfoLine extends SequoiaComponent {
  get prioritySubscription() {
    if (this.paymentRetry) {
      return this.subscriptionOptionsWithoutExisting[0];
    }
    return selectors.QS.prioritySubscriptionSelector(this.$store);
  }

  get subscriptionOptionsWithoutExisting() {
    return selectors.QS.subscriptionOptionsWithoutExistingSelector(this.$store);
  }

  get subscriptionInfo() {
    return [
      this.prioritySubscription?.title,
      this.prioritySubscription?.renderingMethodConfig?.trialPeriod?.translation,
      this.prioritySubscription?.renderingMethodConfig?.priceAndPeriodWithTrial?.translation,
    ].join(', ');
  }

  get paymentRetry() {
    return selectors.QS.paymentRetrySelector(this.$store);
  }
}
</script>
