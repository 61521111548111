import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins/index';
import { selectors } from 'src/store/selectors';
import { TChannelEnhanced } from 'src/api/channels/types';
import { convertToSeconds, secondsToHM } from 'src/utils/time/convert-time';
import { getChannelLogo } from 'src/utils/channel';
import { TV_SPECIAL_GENRES } from 'src/constants';

@Component
export default class Channel extends SequoiaComponent {
  // CHANNELS
  get channels() {
    return selectors.tvChannels.filteredChannelsSelector(this.$store);
  }

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  // GENRE
  get currentGenre() {
    return selectors.tvChannels.currentGenreSelector(this.$store);
  }

  get isCurrentGenreFavorites() {
    return this.currentGenre === TV_SPECIAL_GENRES.favourite;
  }

  // MODAL & CHANNEL PAGE – Open channel
  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get isModalChannelOpen() {
    return selectors.tvChannels.isModalChannelOpenSelector(this.$store);
  }

  get isPageChannelOpen() {
    return selectors.tvChannels.isPageChannelOpenSelector(this.$store);
  }

  get openChannelIdsHistory() {
    return selectors.tvChannels.openChannelIdsHistorySelector(this.$store);
  }

  get openChannelId() {
    return selectors.tvChannels.openChannelIdSelector(this.$store);
  }

  get openChannel() {
    return this.getChannelById(this.openChannelId);
  }

  // CHANNEL

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  getLiveProgramForChannel(channelId: string) {
    return selectors.tvEpg.programForChannelSelector({
      store: this.$store,
      channelId,
      isLive: true,
    });
  }

  getNextProgramForChannel(channelId: string) {
    return selectors.tvEpg.programForChannelSelector({
      store: this.$store,
      channelId,
      programIndex: selectors.tvEpg.nextProgramIndexForChannelSelector(this.$store, channelId),
    });
  }

  getLiveProgramSrc(channelId: string) {
    const fallbackSrc = `/public/images/placeholders/placeholder-poster-horizontal-${this.$store.theme}.png`;
    return (
      this.getLiveProgramForChannel(channelId)?.image ||
      `${fallbackSrc}?v${this.$store.imagesMTimes[fallbackSrc]}`
    );
  }

  getLiveProgramTitle(channelId: string) {
    return this.getLiveProgramForChannel(channelId)?.title || '';
  }

  getLiveProgramTimelinePosition(channelId: string) {
    return selectors.tvEpg.programTimelinePositionForChannelSelector(this.$store, channelId);
  }

  getLiveProgramStartTimeHM(channelId: string) {
    return this.getLiveProgramForChannel(channelId)?.startTimeHM;
  }

  getLiveProgramEndsMs(channelId: string) {
    return this.getLiveProgramForChannel(channelId)?.endMs || 0;
  }

  getNextProgramTitle(channelId: string) {
    return this.getNextProgramForChannel(channelId)?.title || '';
  }

  getNextProgramStartTimeHM(channelId: string) {
    return this.getNextProgramForChannel(channelId)?.startTimeHM;
  }

  getNextProgramStartsMs(channelId: string) {
    return this.getNextProgramForChannel(channelId)?.startMs || 0;
  }

  getNextProgramEndsMs(channelId: string) {
    return this.getNextProgramForChannel(channelId)?.endMs || 0;
  }

  getNextProgramStartsIn(channelId: string) {
    if (!this.$store.appInfo) {
      return;
    }

    const currentTime = this.$store.appInfo.currentTime;
    const getNextProgramStartsMs = convertToSeconds(
      this.getNextProgramStartsMs(channelId),
      'millisecond'
    );

    if (getNextProgramStartsMs) {
      return secondsToHM(getNextProgramStartsMs - currentTime);
    } else {
      return undefined;
    }
  }
}
