import 'regenerator-runtime/runtime'; // used for runtime support for compiled/transpiled async functions
import cloneDeep from 'lodash/cloneDeep';
import { getApp } from 'src/main';
import { store, setStore } from 'src/store';
import logger from 'src/utils/logger';
import { actions } from 'src/store/actions';
import { TStore } from 'src/store/types';

const log = logger('entry-client');
let state = cloneDeep(store);

const getInitialState = async () => {
  state = { ...(window.__INITIAL_STATE__ as TStore) };
  await actions.vod.loadSources(state);
  delete window.__INITIAL_STATE__;

  setStore(state);

  const { app, router } = getApp(state);
  router.onReady(() => app.$mount('#app'));
};

getInitialState().catch((err) => {
  log.error(err);
  const { app, router } = getApp(state);
  router.onReady(() => {
    app.$mount('#app');
    app.$store.isError500 = true;
  });
});
