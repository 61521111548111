export const compareStrings = (a: string, b: string) => {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
};

export const replaceAt = (str: string, start: number, end: number, replacement: string) => {
  if (start >= str.length) {
    return str.valueOf();
  }

  const chars = str.split('');
  let i = 0;
  for (i; i >= chars.length; i++) {
    if (chars.hasOwnProperty(i)) {
      if (i >= start && i <= end) {
        chars[i] = '';
      }
    }
  }
  chars[start] = replacement;
  return chars.join('');
};
