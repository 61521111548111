<template>
  <transition name="fade-out-fast">
    <div v-if="showBanner" class="smart-app-banner flex">
      <IconCross
        class="item left color-dark-font-primary"
        data-cy="close-smart-app-banner"
        @click="hideBanner('close')"
      />

      <AppIconWithDescription theme-forced="dark" class="item middle" />

      <ButtonDefault
        :href="isUrlExternal(link) ? link : ''"
        :to="isUrlExternal(link) ? '' : link"
        theme-forced="dark"
        type="button"
        class="item right gift"
        size="small"
        view="secondary"
        :target="isUrlExternal(link) ? '_blank' : ''"
        @click.native="hideBanner('open')"
      >
        <span v-html="getTranslation('smart_app_banner_button')" />
      </ButtonDefault>
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { localStore } from 'src/utils/storage';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';
import { getDeviceFlags } from 'src/utils/platform-detector';
import AppIconWithDescription from 'src/components/app-redirect/AppIconWithDescription.vue';
import { isUrlExternal } from 'src/utils/url';

@Component({
  components: { AppIconWithDescription, ButtonDefault, ButtonAction, IconSVG, IconCross },
})
export default class SmartAppBanner extends SequoiaComponent {
  isUrlExternal = isUrlExternal;

  get hasCustomLayout() {
    return (
      this.$router?.options?.routes?.find((r) => r.name === this.$route.name)?.props as Record<
        string,
        string
      >
    )?.customLayout;
  }
  get isVisible() {
    return this.$store.siteConfig?.smartAppBanner?.isVisible;
  }
  get link() {
    return this.$store.siteConfig?.smartAppBanner?.link;
  }
  get showSmartAppBanner() {
    return this.$store.common.showSmartAppBanner;
  }
  get isMobile() {
    return getDeviceFlags().isMobile;
  }
  get isTablet() {
    return getDeviceFlags().isTablet;
  }
  get isMobileOrTablet() {
    return this.isMobile || this.isTablet;
  }
  get showBanner() {
    return (
      !this.hasCustomLayout && this.isVisible && this.showSmartAppBanner && this.isMobileOrTablet
    );
  }

  mounted() {
    if (
      !this.$store.common.showSmartAppBanner &&
      (localStore.get('showSmartAppBanner') === null ||
        localStore.get('showSmartAppBanner') === true)
    ) {
      localStore.set('showSmartAppBanner', true);
      this.$store.common.showSmartAppBanner = true;
    }
  }

  hideBanner(gaString: 'open' | 'close') {
    localStore.set('showSmartAppBanner', false);
    this.$store.common.showSmartAppBanner = false;
    this.gaEvent({ category: 'smart_app_banner', action: `${gaString} banner` });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.smart-app-banner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-smart-banner);
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 16px 12px;
  background-color: var(--c-dark-700);

  .item {
    &.left {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        opacity: 0.7;
      }
    }

    &.left,
    &.middle {
      margin-right: 12px;
    }

    &.right {
      margin-left: auto;

      &.small {
        height: 40px;
      }
    }
  }
}
</style>
