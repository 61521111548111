import axios from 'axios';
import { errorHandler } from 'src/api/utils';

export const fetchLoaderData = (url: string) =>
  axios
    .get(url)
    .then((response) => response.data)
    .catch((err) => {
      errorHandler(err, {}, url);
    });
