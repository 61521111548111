<template>
  <form class="profile-forms" @submit.prevent="handleChange">
    <h3 v-html="getTranslation('change_profile')" />
    <div class="profile-forms-description" v-html="profilePinRequest" />

    <InputText
      ref="input"
      v-model="changeForm.pin"
      input-category="floating"
      size="medium"
      placeholder=" "
      icon="visibility"
      :tab-index-for-button="-1"
      :maxlength="4"
      :allow-digits-only="true"
      :status="errorPin ? 'error' : undefined"
      :label="getTranslation('enter_pin')"
      :message="errorPin"
      :description="getTranslation('enter_4_characters')"
    />

    <div class="buttons-wrapper">
      <ButtonDefault :disabled="!changeForm.pin || changeForm.pin.length < PROFILE_PIN_LENGTH">
        <span v-html="getTranslation('signin_reg')" />
      </ButtonDefault>
      <ButtonDefault view="secondary" type="button" @click="closeModal">
        <span v-html="getTranslation('cancel')" />
      </ButtonDefault>
    </div>
  </form>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref } from 'vue-property-decorator';
import { TProfile } from 'src/api/auth/types';
import { actions } from 'src/store/actions';
import { PROFILE_PIN_LENGTH } from 'src/constants';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';

@Component({ components: { InputText, ButtonDefault } })
export default class ProfileChange extends SequoiaComponent {
  PROFILE_PIN_LENGTH = PROFILE_PIN_LENGTH;

  showPin = false;
  errorVisible = false;
  errorPin = '';
  pin = '';

  changeForm = {
    selected: '',
    pin: '',
    pinRequired: false,
  };

  @Prop()
  profile!: Partial<TProfile>;

  @Prop()
  actionName!: string;

  @Ref('input')
  readonly refInput?: Vue;

  get profilePinRequest() {
    const profileInsert = '<span class="bold name-overflow">' + this.profile?.name + '</span>';
    const pinRequest = this.getTranslation('profile_pin_request');
    return pinRequest.replace('%profile%', profileInsert);
  }

  mounted() {
    (this.refInput?.$refs?.input as HTMLElement)?.focus();
  }

  async handleChange() {
    try {
      await actions.profile.change(this.$store, {
        data: {
          Id: this.profile.id,
          Pin: this.changeForm.pin,
        },
      });
      this.errorVisible = false;
      this.errorPin = '';

      actions.common.showProfileNotification(
        this.$store,
        `<span>${this.getTranslation('profile_entered')}</span><span>«${
          this.profile?.name
        }»</span>`,
        'to-profile'
      );

      await this.closeModal();
    } catch (err) {
      this.renderError(err);
    }
  }

  renderError(err: any) {
    actions.profile.setIsProfileLoading(this.$store, false);
    this.errorPin = err?.message || err?.data?.message;
    this.errorVisible = true;
  }

  async closeModal() {
    actions.common.hideModal(this.$store);
  }
}
</script>
