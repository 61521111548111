import { URLS } from 'src/constants';
import { fe } from 'src/api/utils';
import { TTrialStatusAfterRegistrationResponse } from 'src/api/trials/types';

/**
 * Trials could be applied automatically after registration
 * This method tells whether it was applied or not
 * Expected to be called after registration
 *
 * @return Promise<TTrialStatusAfterRegistrationResponse>
 * @see https://lifestream.atlassian.net/browse/SEQ-1189
 * @see https://docs.api.lfstrm.tv/?urls.primaryName=Docs%20for%20discussion#/default/get_trial_registration_v1_status
 */
export const getTrialStatusAfterRegistration = (): Promise<TTrialStatusAfterRegistrationResponse> =>
  fe.get(URLS.trialRegistrationStatus);
