import { channelCollectionsSelector } from 'src/store/channel-collections/selectors';
import { TStore } from 'src/store/types';
import { TChannelEnhanced } from 'src/api/channels/types';
import { filteredChannelsSelector } from 'src/store/tv-channels/selectors';
import { getChannelGenresMapped, getChannelNumber } from 'src/utils/channel';
import { getByLanguage } from 'src/utils/language';
import logger from 'src/utils/logger';
import Vue from 'vue';
import { makePath } from 'src/utils/url';

const log = logger('channel-collections');

export const resetChannelsInCollections = (store: TStore) => {
  channelCollectionsSelector(store).forEach((collection) => {
    Vue.set(collection, 'channels', []);
  });

  store.flags.channelCollectionsDataState.loaded = false;
};

export const addChannelsToChannelCollections = async (store: TStore) => {
  if (store.flags.channelCollectionsDataState.loaded) {
    log.warning('Channels have already been added to channel collections');
    return;
  }

  if (store.flags.channelCollectionsDataState.loading) {
    log.warning('Channels are already loading to channel collections right now...');
    return;
  }

  const channelCollections = channelCollectionsSelector(store);

  if (!store.flags.channelCollectionsDataState.loading) {
    store.flags.channelCollectionsDataState.loading = true;

    // all channels across all collections should be cleared
    // before replenishing collections with new channels again
    channelCollections.forEach((col) => {
      if (col.channels?.length) {
        col.channels.length = 0;
      }
    });

    const filteredChannels = Object.values(filteredChannelsSelector(store));

    filteredChannels.forEach((channel: TChannelEnhanced) => {
      getChannelGenresMapped(channel)?.forEach((genre) => {
        const i = channelCollections?.findIndex(
          (collection) =>
            collection.genreId === genre ||
            getByLanguage(collection.genre, store.languageCode) === genre
        );
        const channels = store.siteConfig?.channelCollections?.[i]?.channels;
        const hasChannelInCollection = !!channels?.find((ch) => ch.id === channel.id);

        if (i >= 0 && !hasChannelInCollection) {
          channels?.push(channel);
        }
      });
    });

    store.siteConfig?.channelCollections?.forEach((collection) => {
      collection.channels?.sort(
        (a, b) => parseInt(getChannelNumber(a)) - parseInt(getChannelNumber(b))
      );
    });

    store.flags.channelCollectionsDataState.loaded = true;
    store.flags.channelCollectionsDataState.loading = false;
    log.info('Channels have been added to channel collections');
  }
};

export const showCollection = async (
  store: TStore,
  slug: string,
  shouldPushStateToHistory = true
) => {
  setCurrentCollectionSlug(store, slug);

  if (shouldPushStateToHistory) {
    history.pushState({}, '', makePath(`/collection/channels/${slug}`));
  }
};

export const setCurrentCollectionSlug = (store: TStore, slug: string) => {
  store.common.currentChannelCollectionSlug = slug;
};
