<template>
  <div>
    <QuickSubscribeBackground :reducedImage="true" />

    <div class="sub-details with-side-and-bottom-padding dark">
      <h2 v-html="titleTranslation" />

      <template v-if="subDetails.trialAvailable">
        <BadgeSequoia class="mb-4" :text="badgeTranslation" type="accent" />
        <h4 class="mb-4" v-html="trialPeriodTranslation" />
      </template>

      <div
        class="sub-details-price"
        :class="{ remark: subDetails.trialAvailable }"
        v-html="priceAndPeriodTranslation"
      />

      <div class="buttons-wrapper">
        <ButtonDefault
          v-if="!paymentMethodAvailable || subDetails.trialAvailable"
          :is-loading="isLoading"
          @click="$emit('createSub', subDetails)"
        >
          <span v-html="callToActionTranslation" />
        </ButtonDefault>
        <ButtonPayment v-else :is-loading="isLoading" @createSub="$emit('createSub', subDetails)" />
      </div>
    </div>

    <div class="sub-details-structure">
      <div class="sub-details-structure-head-title" v-html="getTranslation('in_subscription')" />
      <div v-if="isSmotreshka" class="sub-details-structure-head">
        <div class="sub-details-structure-head-container">
          <div v-if="QS.sourceType === QS_SOURCE_TYPES.CHANNEL">
            <div class="sub-details-structure-head-image-container">
              <img
                v-if="channel"
                :src="getChannelLogoResized(channel, 36)"
                :alt="getChannelTitle(channel)"
              />
            </div>
            <div v-if="channel" v-html="getChannelTitle(channel)" />
          </div>
          <div>
            <IconSVG :svg="IconTv" class="color-brand" :size="48" />
            <div v-html="channelsCountTranslation" />
          </div>
          <div>
            <IconSVG :svg="IconCatalog" class="color-brand" :size="48" />
            <div v-html="programCatalogTranslation" />
          </div>
        </div>
      </div>

      <div class="sub-details-structure-channels flex-space-between row">
        <div
          v-for="channel in subDetails.renderingData.channels"
          :key="channel.id"
          class="channel col-desktop-3 col-tablet-2 col-mobile-1"
        >
          <div class="image-container">
            <img
              class="image"
              :src="getChannelLogoResized(channel)"
              :alt="getChannelTitle(channel)"
            />
          </div>
          <div class="name" v-text="getChannelTitle(channel)" />
        </div>
        <!-- workaround to left align last row's items  -->
        <i v-for="n in 5" :key="n" aria-hidden="true" />
      </div>

      <div class="sub-details-structure-notes" v-html="getTranslation('offer_notes')" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { QS_SOURCE_TYPES } from 'src/constants';
import ButtonPayment from 'src/components/ui/buttons/ButtonPayment.vue';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import IconTv from 'src/svg/tv.svg';
import IconCatalog from 'src/svg/catalog.svg';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { getChannelLogo, getChannelLogoResized, getChannelTitle } from 'src/utils/channel';
import { wlDetector } from 'src/utils';
import { TChannelEnhanced } from 'src/api/channels/types';

@Component({
  components: {
    ButtonDefault,
    ButtonPayment,
    QuickSubscribeBackground,
    IconSVG,
    BadgeSequoia,
  },
})
export default class SubscriptionDetails extends SequoiaComponent {
  @Prop()
  isLoading?: boolean;

  IconTv = IconTv;
  IconCatalog = IconCatalog;
  IconAngleLeft = IconAngleLeft;
  QS_SOURCE_TYPES = QS_SOURCE_TYPES;

  get QS() {
    return this.$store.QS;
  }

  get channel() {
    return this.QS.channel;
  }

  get subDetails() {
    return this.QS?.subDetails;
  }

  get renderingMethodConfig() {
    return this.subDetails?.renderingMethodConfig;
  }

  get programCatalogTranslation() {
    return this.renderingMethodConfig?.programCatalog?.translation;
  }

  get channelsCountTranslation() {
    return this.renderingMethodConfig?.channelsCount?.translation;
  }

  get callToActionTranslation() {
    if (!this.subDetails) {
      return '';
    }
    const translationKey = this.subDetails?.trialAvailable ? 'trialCallToAction' : 'callToAction';
    return this.subDetails.renderingMethodConfig[translationKey]?.translation;
  }

  get titleTranslation() {
    return this.renderingMethodConfig?.title?.translation;
  }

  get badgeTranslation() {
    return this.renderingMethodConfig?.badge?.translation || '';
  }

  get trialPeriodTranslation() {
    return this.renderingMethodConfig?.trialPeriod?.translation;
  }

  get priceAndPeriodTranslation() {
    if (this.subDetails?.trialAvailable) {
      return this.renderingMethodConfig?.priceAndPeriodWithTrial?.translation;
    } else {
      return this.renderingMethodConfig?.priceAndPeriod?.translation;
    }
  }

  get paymentMethodAvailable() {
    return selectors.payment.paymentMethodAvailableSelector(this.$store);
  }

  get isSmotreshka() {
    return wlDetector().isSmotreshka;
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  getChannelLogoResized(channel: TChannelEnhanced, height?: number) {
    return getChannelLogoResized(channel, height);
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }
}
</script>

<!-- TODO do we still need this here? There are 0 inputs in this component. -->
<style lang="scss">
@import 'src/components/ui/input/index';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.sub-details {
  position: relative;
  z-index: var(--z-2);

  .buttons-wrapper .button {
    width: auto;
    @include mobile {
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 8px;
  }

  .sub-details-price {
    margin-bottom: 24px;
  }

  .remark {
    color: var(--c-dark-font-secondary);
    @extend %body1;

    span {
      font-size: 1.25rem;
    }
  }
}

.sub-details-structure {
  position: relative;
  min-height: 430px;
  color: var(--c-light-font-primary);
  background-color: var(--c-light-100);

  .sub-details-structure-head-title {
    padding: 48px 48px 8px;
    color: var(--c-light-font-tertiary);
    @include body1;

    @include mobile {
      padding-top: 32px;
      padding-left: 24px;
    }
  }

  .sub-details-structure-head {
    height: 64px;
    overflow: hidden;

    .icon {
      margin-right: 16px;
    }

    .sub-details-structure-head-container {
      padding-bottom: 32px;
      overflow-x: scroll;
      white-space: nowrap;

      > div {
        display: inline-flex;
        align-items: center;
        height: 64px;
        padding: 8px 24px 8px 16px;
        vertical-align: middle;
        background-color: var(--c-light-150);
        border-radius: 4px;

        &:first-child {
          margin-left: 48px;
          @include mobile {
            margin-left: 24px;
          }
        }

        &:last-child {
          margin-right: 48px;
          @include mobile {
            margin-right: 24px;
          }
        }

        + div {
          margin-left: 24px;
        }

        span {
          white-space: nowrap;
        }
      }
    }

    .sub-details-structure-head-image-container {
      min-width: 64px;
      aspect-ratio: 16/9;
      margin-right: 16px;
    }
  }

  .sub-details-structure-channels {
    padding: 48px 78px 32px 48px;

    i {
      width: 124px;
      margin-right: 12px;
      margin-left: 12px;

      @include mobile-and-tablet {
        width: 110px;
        margin-right: 8px;
        margin-left: 8px;
      }

      @include mobile-s {
        width: 155px;
      }
    }

    @include devices-with-hover {
      padding-right: 48px;
    }

    @include mobile {
      padding-right: 54px;
      padding-left: 24px;

      @include devices-with-hover {
        padding-right: 24px;
      }
    }

    .channel {
      margin-bottom: 24px;
      overflow: hidden;
      @include body2;

      @include mobile-and-tablet {
        margin-bottom: 20px;
      }

      .name {
        min-height: 40px;
        @include mobile-and-tablet {
          display: none;
        }
      }

      .image-container {
        width: 100%;
        aspect-ratio: 57 / 32;
        margin-bottom: 8px;
        background-color: var(--c-light-150);
      }

      .image {
        width: 100%;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .sub-details-structure-notes {
    padding: 0 48px 48px;
    color: var(--c-light-font-tertiary);
    @include body2;

    @include mobile-s {
      padding-right: 32px;
      padding-left: 24px;
    }
  }
}
</style>
