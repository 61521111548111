export interface TEnvVars {
  feBaseUrl: string;
  beBaseUrl: string;
  wlAppId: string;
}

export const getEnvVars = () => {
  let feBaseUrl;
  let beBaseUrl;
  let wlAppId;
  if (process?.env?.VUE_ENV === 'client' || !!process?.env?.STORYBOOK) {
    feBaseUrl = window.FE_BASE_URL || '';
    beBaseUrl = window.BE_BASE_URL || '';
    wlAppId = window.WL_APP_ID || '';
  } else {
    global.env = global.env || {};
    feBaseUrl = global.env.feBaseUrl || '';
    beBaseUrl = global.env.beBaseUrl || '';
    wlAppId = global.env.wlAppId || '';
  }

  if (!feBaseUrl) throw new Error('feBaseUrl is not defined!');
  if (!beBaseUrl) throw new Error('beBaseUrl is not defined!');
  if (!wlAppId) throw new Error('wlAppId is not defined!');

  return { beBaseUrl, feBaseUrl, wlAppId };
};
