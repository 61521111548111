<template>
  <div
    v-if="sourceId && sourceId !== 'archive'"
    class="source title-details-info vod"
    :class="theme"
  >
    <span v-if="showTranslation" class="left" :class="`color-${theme}-font-tertiary`"
      ><span v-html="getTranslation('cinema')" />
    </span>
    <BadgeSource
      v-if="!!sourceLogoUrl"
      class="logo"
      :src="sourceLogoUrl"
      :width="36"
      :height="24"
    />
    <span v-text="sourceTitle" />
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import Global from 'src/mixins/Global';
import Vod from 'src/mixins/Vod';
import BadgeSource from 'src/components/ui/BadgeSource.vue';

@Component({
  components: {
    BadgeSource,
  },
})
export default class TitleSource extends mixins(Vod, Global) {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: false })
  showTranslation!: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get customTitle() {
    return this.sources[this.sourceId]?.customTitle || '';
  }

  get sourceTitle() {
    return this.customTitle ? this.getByLanguage(this.customTitle) : this.sourceId;
  }

  get sourceLogoUrl() {
    return actions.vod.getSourceLogoUrl(this.$store, this.sourceId);
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/title-details-info';
</style>

<style lang="scss" scoped>
.source {
  .logo {
    width: 36px;
    height: 24px;
    margin-right: 12px;
    border-radius: 4px;
  }
}
</style>
