<template>
  <ul
    class="steps-container"
    :class="componentName"
    :data-ctype="$options.name"
    :data-cname="componentName"
  >
    <li v-for="(step, stepNumber) in steps" :key="step.imageId" class="step-container">
      <span class="step-number" v-text="stepNumber + 1" />
      <img :src="step.image.url" :alt="step.image.alt" />
      <div class="description" v-html="step.contentBlock" />
      <p :alt="step.id" />
    </li>
  </ul>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';

@Component
export default class HowToWatch extends mixins(Global, CmsComponent) {
  content?: {
    steps: Array<{
      contentBlock?: { [language: string]: string };
      image?: { [language: string]: string };
    }>;
  } | null = null;

  get steps() {
    if (!this.content) return [];
    return this.content.steps.map((step) => ({
      contentBlock: this.getByLanguage(step.contentBlock),
      image: this.getByLanguage(step.image),
    }));
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';

.steps-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  li {
    position: relative;
    width: 18.75rem;
    margin: 1rem;
    box-shadow: 0 4px 0.625rem rgba(0, 0, 0, 0.02);

    .step-number {
      position: absolute;
      top: -0.625rem;
      left: -0.625rem;
      width: 1.25rem;
      height: 1.25rem;
      padding: 0;
      color: var(--c-text-on-brand-primary);
      text-align: center;
      background-color: var(--c-light-brand);
      border-radius: 100%;
      @include body2;
    }

    img {
      width: 100%;
      border-bottom: 0.0625rem solid #ececec;
    }

    .description {
      display: block;
      padding: 1.25rem;
      text-align: left;
      @include body2;
    }
  }
}
</style>
