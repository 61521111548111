<template>
  <span class="circle">
    <IconSVG :svg="IconPlay" />
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';

@Component({
  components: { IconSVG },
})
export default class IconPlayInCircle extends Vue {
  IconPlay = IconPlay;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
$transition: var(--ease-out) 0.25s;

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: var(--z-3);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: var(--c-dark-100);
  border-radius: 100%;
  transition: opacity $transition;
  transform: translate(-50%, -50%);

  @include mobile-and-tablet {
    width: 32px;
    height: 32px;
  }

  .icon {
    color: var(--c-light-100);

    @include mobile-and-tablet {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
