// TODO type config properly
import { cookieStore } from 'src/utils/storage';
import { COOKIE_NAMES } from 'src/constants';

export const stringifyCookies = (cookies: any) => {
  return Object.entries(cookies)
    .map(([k, v]: any) => k + '=' + encodeURIComponent(v))
    .join(';');
};

export const setFeCookie = (account: any, ssrCookiesToSet?: Record<string, string>) => {
  const payload = {
    id: account.id,
    email: account.email,
    username: account.username,
    session: account.session,
    age_rating: account.age_rating,
  };
  if (process.env.VUE_ENV === 'server') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ssrCookiesToSet![COOKIE_NAMES.feCookie] = JSON.stringify(payload);
    global.feCookie = payload;
  } else {
    cookieStore.set(COOKIE_NAMES.feCookie, JSON.stringify(payload));
  }
};

export const clearFeCookie = () => cookieStore.remove(COOKIE_NAMES.feCookie);
