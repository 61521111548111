<template>
  <button
    ref="button"
    type="button"
    class="player-button"
    :class="{ flip }"
    :disabled="disabled"
    @click="onClick($event)"
    @mousedown="onMousedown($event)"
    @mouseup="onMouseup($event)"
    @mouseover="onMouseover($event)"
    @mouseout="onMouseout($event)"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';

@Component
export default class PlayerButton extends Vue {
  @Prop()
  icon!: Record<string, unknown>;

  @Prop()
  flip?: boolean;

  @Prop()
  disabled?: boolean;

  @Ref('button')
  readonly refButton?: HTMLButtonElement;

  onClick(e: MouseEvent) {
    if (this.refButton) {
      // remove focus from pressed button to avoid side effects by player keyboard controls
      // see: https://lifestream.atlassian.net/browse/SEQ-853
      this.refButton.blur();
    }
    this.$emit('click', e);
  }

  onMousedown(e: MouseEvent) {
    this.$emit('mousedown', e);
  }

  onMouseup(e: MouseEvent) {
    this.$emit('mouseup', e);
  }

  onMouseover(e: MouseEvent) {
    this.$emit('mouseover', e);
  }

  onMouseout(e: MouseEvent) {
    this.$emit('mouseout', e);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: var(--alpha-dark-7);
  user-select: none;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  transition: color 0.15s var(--ease-out), background-color 0.15s var(--ease-out);

  @include devices-with-hover {
    &:hover {
      color: var(--alpha-dark-8);
      background-color: var(--alpha-dark-3);
    }
  }

  &:active,
  &.active {
    color: var(--alpha-dark-9);
    background-color: var(--alpha-dark-5);
  }

  &:disabled,
  &.disabled {
    color: var(--alpha-dark-6) !important;
    cursor: default;
  }

  &.flip {
    transform: rotate(180deg);
  }
}
</style>
