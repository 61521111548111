<template>
  <div
    class="button button-payment padding-0 text-on-brand-primary"
    :class="{
      loading: isLoading,
      disabled: isLoading,
    }"
    :data-cy="dataCy ? `button-payment-${dataCy}` : ''"
  >
    <div class="side left" :class="{ active: dropdownVisible }">
      <div ref="click" class="click" @click="clickOnDropdown" />
      <CreditCard
        v-if="$store.QS.usePM"
        :cardType="paymentCardType"
        :cardLast4="paymentCardLast4"
      />
      <CreditCard v-else :isNew="true" />
      <IconSVG :svg="IconTriangleDown" :direction="dropdownVisible ? 'up' : ''" />
    </div>

    <div class="side">
      <span class="separator" />
    </div>

    <div class="side right" @click="$emit('createSub')">
      <LoaderSpinner v-show="isLoading" />
      <span
        :style="{ visibility: `${isLoading ? 'hidden' : 'visible'}` }"
        v-html="getTranslation('connect')"
      />
    </div>

    <transition name="fade-ease-out-slow">
      <div v-if="dropdownVisible" ref="dropdown" class="dropdown">
        <CreditCard
          :cardType="paymentCardType"
          :cardLast4="paymentCardLast4"
          @click="changePaymentMethod(true)"
        />
        <CreditCard :isNew="true" @click="changePaymentMethod(false)" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref } from 'vue-property-decorator';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import IconTriangleDown from 'src/svg/triangle-down.svg';
import IconSVG from 'src/components/IconSVG.vue';
import CreditCard from 'src/components/ui/CreditCard.vue';
import { selectors } from 'src/store/selectors';

@Component({
  components: { CreditCard, LoaderSpinner, IconSVG },
})
export default class ButtonPayment extends SequoiaComponent {
  @Prop()
  dataCy?: string;

  @Prop()
  isLoading?: boolean;

  @Ref('click')
  readonly refClick?: HTMLDivElement;

  @Ref('dropdown')
  readonly refDropdown?: HTMLDivElement;

  IconTriangleDown = IconTriangleDown;
  dropdownVisible = false;

  get paymentCardType() {
    return selectors.payment.cardTypeSelector(this.$store).toLowerCase();
  }

  get paymentCardLast4() {
    return selectors.payment.cardLast4Selector(this.$store);
  }

  mounted() {
    document.addEventListener('click', this.documentClick);
  }

  destroyed() {
    document.removeEventListener('click', this.documentClick);
  }

  documentClick(e: any) {
    if (e.target !== this.refClick && e.target !== this.refDropdown) {
      this.dropdownVisible = false;
    }
  }

  changePaymentMethod(usePM: boolean) {
    this.$store.QS.usePM = usePM;
    this.dropdownVisible = false;
  }

  clickOnDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
    this.gaEvent({
      category: 'acquiring',
      action: 'Клик на дропдаун с картой',
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.button.button-payment {
  display: inline-flex;
  padding: 0;
  cursor: unset;

  &.padding-0 {
    padding: 0;
  }

  &.disabled {
    color: var(--c-light-800);
    pointer-events: none;
    cursor: none;
    background-color: var(--c-light-300);
    border: 2px solid var(--c-light-300);
    box-shadow: none;

    .separator {
      background-color: var(--c-light-800);
    }
  }

  img {
    max-width: none;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 24px;
    margin-right: 8px;
    background-color: var(--c-light-100);
    border-radius: 4px;

    img {
      width: 24px;
    }

    .plus {
      width: 16px;
    }
  }

  .separator {
    width: 1px;
    height: 24px;
  }

  &.light-text .side {
    color: var(--c-dark-font-primary);
  }

  .side {
    display: flex;
    align-items: center;
    height: 100%;
    color: var(--c-light-font-primary);
    border-radius: 4px;

    &.left,
    &.right {
      cursor: pointer;

      &:active,
      &.active {
        color: unset;
      }
    }

    &.left {
      position: relative;
      padding: 0 4px 0 16px;
      white-space: nowrap;

      .click {
        position: absolute;
        top: 0;
        left: 0;
        z-index: var(--z-1);
        width: 100%;
        height: 100%;
      }

      span {
        display: inline-flex;
        align-items: center;
      }
    }

    &.right {
      position: relative;
      width: 100%;
      padding: 0 16px;
      text-align: center;

      &::v-deep .loader-spinner {
        position: absolute;
        height: 100%;
      }

      @include mobile-and-tablet {
        display: flex;
        justify-content: center;
      }
    }

    .card {
      &.new-card {
        display: none;
      }

      @include mobile-s {
        display: none;

        &.new-card {
          display: flex;

          + span {
            display: none;
          }
        }
      }
    }
  }

  .dropdown {
    position: absolute;
    top: 48px;
    left: 0;
    z-index: var(--z-3);
    padding: 8px;
    background-color: var(--c-light-150);
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    > div {
      display: flex;
      align-items: center;
      padding: 8px;
      color: var(--c-dark-800);
      cursor: pointer;

      @include devices-with-hover {
        &:hover {
          background-color: var(--c-light-300);
          border-radius: 2px;
        }
      }

      + div {
        margin-top: 4px;
      }
    }
  }
}

// --------------------------------------------
// Theme Colors
// --------------------------------------------
.light {
  .button-payment {
    background-color: var(--c-light-brand);

    .separator {
      background-color: var(--c-light-brand-lighter);
    }

    &.left,
    &.right {
      @include devices-with-hover {
        &:hover {
          background-color: var(--c-light-brand-lighter);
        }
      }

      &:active,
      &.active {
        background-color: var(--c-light-brand-darker);
      }
    }
  }
}

.dark {
  .button-payment {
    background-color: var(--c-dark-brand);

    .separator {
      background-color: var(--c-dark-brand-lighter);
    }

    &.left,
    &.right {
      @include devices-with-hover {
        &:hover {
          background-color: var(--c-dark-brand-lighter);
        }
      }

      &:active,
      &.active {
        background-color: var(--c-dark-brand-darker);
      }
    }
  }
}
</style>
