<template>
  <InputText
    v-model="$store.tvChannels.searchQuery"
    class="channels-search mr-16 mr-tablet-8 mr-mobile-8 mt-tablet-8 mt-mobile-8"
    size="small"
    input-category="default"
    :placeholder="getTranslation('channel_name')"
    icon="search"
  />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import InputText from 'src/components/ui/input/InputText.vue';

@Component({
  components: {
    InputText,
  },
})
export default class ChannelsSearch extends SequoiaComponent {}
</script>
