<template>
  <div class="notification-wrap">
    <div class="backdrop" @click="hideNotification" />
    <transition appear name="notification-animation" mode="out-in">
      <div v-if="loaded" class="notification dark">
        <div class="top">
          <IconSVG :svg="IconCard" class="color-dark-error" />
          <h6 class="title" v-html="getTranslation('payment_error')" />
          <button type="button" class="button button-action" @click="hideNotification">
            <IconSVG :svg="IconCross" />
          </button>
        </div>
        <div class="text body2" v-html="getTranslation('payment_error_try_again')" />
        <button
          type="button"
          class="link action-secondary body2"
          @click="hideNotification"
          v-html="getTranslation('do_not_show_anymore')"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { NotificationBaseComponent } from 'src/components/ui/notifications/NotificationBaseComponent';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { localStore } from 'src/utils/storage';
import { LOCAL_STORAGE_NAMES } from 'src/constants';
import IconCard from 'src/svg/card.svg';

@Component
export default class NotificationSubscriptionPaymentError extends NotificationBaseComponent {
  IconCard = IconCard;

  @Prop()
  archivedSubs!: Array<any>;

  async mounted() {
    this.gaEvent({ category: 'acquiring', action: 'Появление ошибки оплаты' });
  }

  hideNotification() {
    this.loaded = false;
    setTimeout(() => {
      actions.common.setShowNotificationSubscriptionPaymentError(this.$store, false);
      actions.common.unlockBodyScroll(this.$store);

      localStore.set(
        LOCAL_STORAGE_NAMES.archivedSubs,
        this.archivedSubs.map((sub) => sub.id)
      );
    }, 250);
    this.gaEvent({
      category: 'acquiring',
      action: 'Клик по кнопке "Больше не показывать" в окне ошибки оплаты',
    });
  }
}
</script>

<style lang="scss">
@import 'notification-base';
</style>

<style lang="scss" scoped>
.link {
  align-self: center;
}
</style>
