<template>
  <div class="selector-single-desktop body1" :class="theme">
    <template v-for="(item, i) in itemsComputed">
      <button
        :key="item.number + item.text + i"
        type="button"
        class="item"
        :class="[{ selected: selected === item.index }, `color-${theme}-font-primary`, size]"
        @click="select(item.index)"
        v-text="item.text"
      />
    </template>
    <button
      v-if="lastItem"
      type="button"
      class="item last"
      :class="[{ selected: selected === lastItem.index }, `color-${theme}-font-primary`, size]"
      @click="select(lastItem.index)"
      v-text="lastItem.text"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TSelectorItem } from 'src/components/ui/selector-single/SelectorSingle.types';

@Component
export default class SelectorSingleDesktop extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  items!: TSelectorItem[];

  @Prop({ default: 0 })
  selected!: string | number;

  @Prop({ default: 'small' })
  size?: 'small' | 'medium';

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get itemsLength() {
    return this.items?.length || 0;
  }

  get itemsComputed() {
    return this.items?.slice(0, this.itemsLength - 1) || [];
  }

  get lastItem() {
    return this.items[this.itemsLength - 1];
  }

  select(index: string | number) {
    this.$emit('select', index);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/media-queries';

.selector-single-desktop {
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  margin: 8px 0;
  border-radius: 8px;

  .item {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: 1px;
    border-radius: 8px;
    transition: background-color 0.15s var(--ease-in-out);

    &.small {
      min-height: 40px;
    }

    &.medium {
      min-height: 48px;
    }

    &.last {
      margin-right: 0;
    }

    &:not(.last) {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -1px;
        display: block;
        width: 1px;
        height: 16px;
        transform: translateY(-50%);
      }
    }

    &.selected {
      pointer-events: none;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    background-color: var(--alpha-light-2);

    .item {
      background-color: var(--alpha-light-1);

      &::after {
        background-color: var(--alpha-light-3);
      }

      &.selected {
        background-color: var(--c-light-surface-300);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      }

      @include devices-with-hover {
        &:not(.selected):hover {
          background-color: var(--alpha-light-3);
        }
      }
    }
  }

  &.dark {
    background-color: var(--alpha-dark-2);

    .item {
      background-color: var(--alpha-dark-1);

      &::after {
        background-color: var(--alpha-dark-3);
      }

      &.selected {
        color: var(--c-text-on-brand-primary);
        background-color: var(--c-dark-surface-700);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
      }

      @include devices-with-hover {
        &:not(.selected):hover {
          background-color: var(--alpha-dark-3);
        }
      }
    }
  }
}
</style>
