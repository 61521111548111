var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"input-block",class:[
    _vm.theme,
    _vm.size,
    _vm.inputCategory,
    { disabled: _vm.disabled },
    {
      'label-focus': _vm.labelFocus,
      'with-captcha': _vm.withCaptcha,
      'with-icon-action': _vm.icon,
      'with-icon-leading': _vm.iconLeading,
      'without-submit': _vm.isIconSearch && !_vm.submitOnEnter,
    },
  ]},[_c('div',{staticClass:"container"},[_c('IconSVG',{staticClass:"icon-leading",attrs:{"svg":_vm.iconLeading,"size":20}}),_c('input',{ref:"input",staticClass:"input",class:[_vm.size, _vm.status, { password: _vm.isPassword }],attrs:{"id":_vm.id,"placeholder":_vm.inputCategory === 'floating' ? ' ' : _vm.placeholderLocal,"type":_vm.type
          ? _vm.type
          : _vm.icon === 'visibility' && !_vm.showPassword
          ? 'password'
          : _vm.withCaptcha || _vm.allowDigitsOnly
          ? 'number'
          : 'text',"disabled":_vm.disabled,"readonly":_vm.readonly,"name":_vm.name,"minlength":_vm.minlength,"maxlength":_vm.maxlength,"required":_vm.required,"data-cy":_vm.dataCy ? `input-${_vm.dataCy}` : '',"inputmode":_vm.allowDigitsOnly ? 'numeric' : '',"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateSelf($event.target.value)},"change":function($event){return _vm.onChange($event)},"keypress":function($event){return _vm.onKeypress($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.submitOnEnter
          ? _vm.$emit('clickEnter')
          : $event.target.nextElementSibling
          ? $event.target.nextElementSibling.focus()
          : ''},"focus":function($event){return _vm.onFocus($event)},"paste":function($event){return _vm.onPaste($event)}}}),(_vm.inputCategory === 'floating')?_c('label',{staticClass:"label floating",attrs:{"for":_vm.id},domProps:{"textContent":_vm._s(_vm.label)}}):_vm._e(),(_vm.isIconSearch || _vm.isIconVisibility)?_c('button',{staticClass:"input-icon",class:{ search: _vm.isIconSearch, visibility: _vm.isIconVisibility },attrs:{"tabindex":_vm.tabIndexForButton,"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.isIconSearch ? _vm.$emit('submitSearch') : (_vm.showPassword = !_vm.showPassword)}}},[_c('IconSVG',{attrs:{"svg":_vm.isIconSearch ? _vm.IconSearch : _vm.showPassword ? _vm.IconVisibilityOn : _vm.IconVisibilityOff}})],1):(_vm.icon === 'clear' && _vm.value)?_c('button',{staticClass:"input-icon",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.clearInput.apply(null, arguments)}}},[_c('IconSVG',{attrs:{"svg":_vm.IconClear}})],1):_vm._e(),(_vm.message && _vm.status)?_c('div',{staticClass:"message",class:[{ status: _vm.status }, _vm.status ? `bg-${_vm.status}` : ''],attrs:{"data-cy":_vm.dataCy ? `input-error-${_vm.dataCy}` : ''},domProps:{"textContent":_vm._s(_vm.message)}}):_vm._e()],1),(_vm.withCaptcha)?[_c('CaptchaSequoia',{attrs:{"use-vercont-server":_vm.useVercontServer}}),_c('div',{staticClass:"flex-break"})]:_vm._e(),(_vm.description)?_c('div',{staticClass:"description",domProps:{"textContent":_vm._s(_vm.descriptionFormatted)}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }