import { getEnvVars } from 'src/config';
import { getDeviceType, getPlatformName } from 'src/utils/platform-detector';
import { getUserAgentUuid } from 'src/utils/uuid';
import { flattenObject } from 'src/utils/object';
import { getDeviceDrmQueryParamValue } from 'src/utils/drm';
// import { APP_VERSION, BUILD_COMMIT_HASH, BUILD_DATE } from 'src/global';

// export interface TAppVersionParams {
//   'app.buildDate': number;
//   'app.buildType': string;
//   'app.hash': string;
//   'app.id': string;
//   'app.version': string;
//   'app.whitelabel': string;
//   'device.brand': string;
//   'device.model': string;
//   'device.type': string;
//   'device.uuid': string;
// }

export const getAppVersionParams = () => {
  const { wlAppId } = getEnvVars();

  let whitelabel = String(wlAppId);
  if (whitelabel === 'sm') whitelabel = 'smotreshka';
  if (whitelabel === 'galam' || whitelabel === 'glm') whitelabel = 'galamtv';
  if (whitelabel === 'o') whitelabel = 'otv';
  if (whitelabel === 'mts') whitelabel = 'mtstv';
  if (!whitelabel) whitelabel = 'none';

  const appVersionParams: { app: Record<string, string>; device: Record<string, string> } = {
    app: {
      /* параметры, жестко связанные с конкретным бинарным образом приложения, известны на момент сборки*/
      version: APP_VERSION, // версия клиента как её видит пользователь
      hash: BUILD_COMMIT_HASH, // строка, позволяющая однозначно идентифицировать конкретный коммит в системе контроля версий
      buildDate: BUILD_DATE, // строка, позволяющая однозначно идентифицировать конкретный коммит в системе контроля версий
      id: 'sequoia', // идентификатор типа приложения
      buildType: 'portal', // тип сборки приложения + операционная система
      whitelabel, // идентификатор WL в соответствии со списком (см. ниже)
      hostname: process.env.VUE_ENV === 'server' ? global.hostname : '',
      env: process.env.VUE_ENV === 'server' ? global.sequoiaEnv : '',
    },
    device: {
      /* параметры устройства, как правило, детектируемые в runtime */
      type: getDeviceType() === 'iPadPro' ? 'tablet' : getDeviceType(), // тип устройства
      brand: getPlatformName() || 'none', // бренд производителя устройства (если нет возможности определить, то none)
      model: 'none', // модель устройства (если нет возможности определить, то none)
      uuid: getUserAgentUuid(), // user agent uuid https://lifestream.atlassian.net/browse/SEQ-1181
    },
  };

  const drm = getDeviceDrmQueryParamValue();
  if (drm) {
    appVersionParams.device.drm = drm;
  }

  return appVersionParams;
};

export const getAppVersionParamsFlattened = () => flattenObject(getAppVersionParams());
