<template>
  <div
    v-if="text"
    class="text-with-truncation"
    :class="[theme, typographyClass, { safari: isSafari }]"
  >
    <div ref="text" :class="{ truncate: shouldTruncate }" v-html="text" />
    <button
      v-if="showButton && isButtonVisible"
      type="button"
      class="link mt-4"
      :class="theme"
      @click.stop="toggleTruncation"
      v-html="textForButton"
    />
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import Global from 'src/mixins/Global';
import AddResizeListener from 'src/mixins/AddResizeListener';
import { BROWSERS, getBrowserName } from 'src/utils/platform-detector';

@Component
export default class TextWithTruncation extends mixins(Global, AddResizeListener) {
  shouldTruncate = true;
  isTruncated = false;
  isButtonVisible = false;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: 5 })
  numberOfLines!: number;

  @Prop({ required: true })
  sourceText!: string;

  @Prop({ default: false })
  smallText!: boolean;

  @Prop({ default: false })
  hideButtonAfterExpansion!: boolean;

  @Prop({ default: true })
  showButton!: boolean;

  @Prop({ default: '***' })
  divider!: string;

  @Ref('text')
  readonly refText?: HTMLDivElement;

  @Watch('numberOfLines')
  onNumberOfLinesChange() {
    this.refText?.style.setProperty('--number-of-lines', this.numberOfLines.toString());
  }

  @Watch('hideButtonAfterExpansion')
  onHideButtonAfterExpansionChange() {
    this.resetIsTruncatedAndIsButtonVisible();
  }

  @Watch('windowWidth')
  onWindowWidthChange() {
    if (!this.checkDivider) {
      this.resetIsTruncatedAndIsButtonVisible();
    }
  }

  get checkDivider() {
    return this.sourceText.split(this.divider).length > 1;
  }

  get text() {
    if (!this.checkDivider) {
      return this.sourceText;
    }

    return this.shouldTruncate
      ? this.sourceText.split(this.divider)[0]
      : this.sourceText.replace(this.divider, '');
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get isSafari() {
    return getBrowserName() === BROWSERS.safari;
  }

  get typographyClass() {
    return this.smallText ? 'body2' : 'body1';
  }

  get isTextExpanded() {
    return this.isTruncated && !this.shouldTruncate;
  }

  get textForButton() {
    if (this.isTextExpanded) {
      return this.getTranslation('collapse');
    }
    return this.getTranslation('read_more');
  }

  async mounted() {
    if (!this.checkDivider) {
      this.refText?.style.setProperty('--number-of-lines', this.numberOfLines.toString());
      this.resetIsTruncatedAndIsButtonVisible();
    } else {
      this.isButtonVisible = true;
      this.isTruncated = true;
    }
  }

  resetIsTruncatedAndIsButtonVisible() {
    if (!this.refText || !this.shouldTruncate) {
      this.isButtonVisible = !this.hideButtonAfterExpansion && this.isTextExpanded;
      return false;
    }
    // +1 is needed to account for Safari bug,
    // where offsetHeight's value is off by 1 pixel
    this.isTruncated = this.refText.offsetHeight + 1 < this.refText.scrollHeight;
    this.isButtonVisible = this.isTruncated;
  }

  toggleTruncation() {
    this.shouldTruncate = !this.shouldTruncate;
    this.isButtonVisible = !(!this.shouldTruncate && this.hideButtonAfterExpansion);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/truncate-lines';
@import 'src/styles/placeholders-and-mixins/link';

.text-with-truncation {
  // since Safari cannot clamp lines with <ul> lists in it,
  // we need to limit height manually
  &.safari {
    .truncate {
      height: 4.5rem;
      line-height: 1.5rem;
    }
  }

  .truncate {
    --number-of-lines: 5;
    @include truncate-after-several-lines(var(--number-of-lines));
  }

  // for long text generated from markdown (e.g. SEO meta info, etc.)
  &::v-deep {
    p + h1,
    p + h2,
    p + h3,
    p + h4,
    p + h5,
    p + h6 {
      margin-top: 16px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    .link {
      @extend %light-link-action-colored;
    }
  }

  &.dark {
    .link {
      @extend %dark-link-action-colored;
    }
  }
}
</style>
