import { TSeo } from 'src/store/seo/types';

const initialState: TSeo = {
  title: '',
  meta: [],
  breadcrumbs: [],
  breadcrumbTelevisionChannel: null,
  breadcrumbProduct: null,
};

export default initialState;
