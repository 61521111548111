<template>
  <nav class="breadcrumbs body1 flex" :class="theme" role="navigation">
    <template v-for="(crumb, i) in crumbsWithLinks">
      <span class="overflow-wrap">
        <router-link
          :key="i + crumb"
          class="crumb link nav-colored"
          :to="crumb.link"
          v-text="crumb.name"
        />
        <span class="divider color-secondary" aria-hidden="true">/</span>
      </span>
    </template>
    <span v-if="currentCrumb" class="crumb current color-primary" v-text="currentCrumb.name" />
  </nav>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TCrumb } from 'src/store/common/types';

@Component
export default class BreadCrumbs extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  crumbs!: Array<TCrumb>;

  @Prop({ default: true })
  withCurrentLevel!: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get crumbsLength() {
    return this.crumbs?.length || 0;
  }

  get crumbsWithLinks() {
    return (
      (this.withCurrentLevel ? this.crumbs?.slice(0, this.crumbsLength - 1) : this.crumbs) || []
    );
  }

  get currentCrumb() {
    return this.withCurrentLevel ? this.crumbs[this.crumbsLength - 1] : null;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/media-queries';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.breadcrumbs {
  .divider {
    margin-right: 8px;
    margin-left: 8px;
  }

  .current {
    position: relative;
    max-width: 280px;
    @extend %truncate-after-1-line;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    .crumb:not(.current) {
      color: var(--c-light-font-secondary);

      @include devices-with-hover {
        &:hover {
          color: var(--c-light-font-primary);
          border-bottom-color: var(--c-light-font-primary);
        }
      }

      &:active {
        color: var(--c-light-link-active);
        border-bottom-color: var(--c-light-link-active);
      }
    }
  }

  &.dark {
    .crumb:not(.current) {
      color: var(--c-dark-font-secondary);

      @include devices-with-hover {
        &:hover {
          color: var(--c-dark-font-primary);
          border-bottom-color: var(--c-dark-font-primary);
        }
      }

      &:active {
        color: var(--c-dark-link-active);
        border-bottom-color: var(--c-dark-link-active);
      }
    }
  }
}
</style>
