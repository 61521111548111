import { TPauseData } from 'src/store/pauses/types';

const initialState: TPauseData = {
  vod: {
    pauses: [],
    lastPause: {
      createdAt: 0,
      episodeId: '',
      id: '',
      mediaItemId: '',
      msecFromEpoch: 0,
      msecFromStart: 0,
      seasonId: '',
      titleId: '',
      totalDurationMsec: 0,
      sourceId: '',
      mediaItemExpiresAt: 0,
    },
    mediaItem: {
      id: '',
      channelId: '',
      title: '',
      source: '',
      broadcastStartsAt: '',
      expiresAt: 0,
      playbackMethods: [],
    },
  },
  tv: {
    pauses: [],
    lastPause: {
      channelId: '',
      contentId: '',
      createdAt: 0,
      id: '',
      time: 0,
    },
  },
};

export default initialState;
