import Component from 'vue-class-component';
import { selectors } from 'src/store/selectors';
import { SequoiaComponent } from 'src/mixins/index';
import { TCategory, TVODTitleEnhanced, TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { prepareTitleLink } from 'src/utils/vod';

@Component
export default class Vod extends SequoiaComponent {
  // SOURCES
  get sources() {
    return selectors.vod.sourcesSelector(this.$store);
  }

  get sourceId() {
    return this.$route.params.sourceId || 'archive';
  }

  get source() {
    return this.sources[this.sourceId];
  }

  get vodSourcesLoaded() {
    return selectors.vod.sourcesLoadedSelector(this.$store);
  }

  get vodSourcesLoading() {
    return selectors.vod.sourcesLoadingSelector(this.$store);
  }

  get type(): 'vod' | 'archive' {
    return this.sourceId === 'archive' ? 'archive' : 'vod';
  }

  get isArchive() {
    return this.type === 'archive';
  }

  // CATEGORIES
  getCategoryIdFromTitle(title: TVODTitleEnhanced) {
    const categories = title?.preview?.categories;

    if (!categories || !this.categories) {
      return '';
    }

    // TODO: ask BACK-END why category IDs from title & source are different
    return (
      Object.values(this.categories || {}).find((cat) => cat.title === categories[0]?.title)?.id ||
      ''
    );
  }

  get currentCategoryId() {
    return selectors.vod.currentCategoryIdSelector(this.$store, this.sourceId);
  }

  get currentCategory() {
    return selectors.vod.currentCategorySelector(this.$store, this.sourceId);
  }

  get categories() {
    return this.source?.categories;
  }

  get sortedCategories() {
    return this.categories
      ? Object.values(this.categories).sort((a: TCategory, b: TCategory) => a.priority - b.priority)
      : [];
  }

  // TITLE
  get playingTitleId() {
    return selectors.vod.playingTitleIdSelector(this.$store, this.sourceId);
  }

  get playingTitle() {
    return selectors.vod.playingTitleSelector(this.$store, this.sourceId);
  }

  get modalTitleId() {
    return selectors.vod.modalTitleIdSelector(this.$store) || this.$route.params.titleId || '';
  }

  get modalTitle() {
    return selectors.vod.modalTitleSelector(this.$store, this.sourceId);
  }

  get titleIdFromParams() {
    return this.$route.params.titleId;
  }

  get titleFromParams() {
    return this.sources[this.sourceId]?.fullTitles?.[this.titleIdFromParams] || null;
  }

  // if there is no playing title, and title ID from route params is not available,
  // then use title id from the open modal
  get title() {
    return this.playingTitle || this.titleFromParams || this.modalTitle;
  }

  get titles() {
    return this.currentCategory?.titles || [];
  }

  // SEASONS
  get playingSeason() {
    return this.playingTitle?.seasons?.find((s) =>
      s.episodes.find(
        (e) => e?.id === this.playingEpisodeId || e?.preview?.id === this.playingEpisodeId
      )
    );
  }

  get playingSeasonId() {
    return selectors.vod.playingSeasonIdSelector(this.$store);
  }

  get seasons() {
    const title = !!this.modalTitle ? this.modalTitle : this.title;
    return title?.seasons || [];
  }

  get seasonsLength() {
    return this.seasons?.length || 0;
  }

  get itemsForSeason() {
    return this.seasons?.map((s, i) => {
      return {
        index: i,
        number: s.number,
        text: s.title ? s.title : `${this.getTranslation('season')} ${s.number}`,
      };
    });
  }

  get currentSeasonId() {
    return selectors.vod.currentSeasonIdSelector(this.$store);
  }

  get currentSeasonNum() {
    return selectors.vod.currentSeasonNumSelector(this.$store);
  }

  get currentSeasonNumForNav() {
    return selectors.vod.currentSeasonNumForNavSelector(this.$store);
  }

  get nextSeasonNum() {
    return selectors.vod.nextSeasonNumSelector(this.$store);
  }

  // EPISODES
  // returns all episodes for playing or open in modal/page title
  getEpisodes(seasonNum = this.currentSeasonNum) {
    const title = !!this.modalTitle ? this.modalTitle : this.title;
    const seasons = title?.seasons;

    if (!title) {
      return [];
    }

    // for episodes with seasons
    if (seasons?.length) {
      return seasons[seasonNum]?.episodes || [];
    }

    // for episodes without seasons
    if (title.episodes?.length) {
      return title.episodes || [];
    }

    return [];
  }

  get episodesForNav() {
    return this.getEpisodes(this.currentSeasonNumForNav);
  }

  get episodesForNavLength(): number {
    return this.episodesForNav?.length || 0;
  }

  get playingEpisodeId() {
    return selectors.vod.playingEpisodeIdSelector(this.$store);
  }

  get episodeIdFromParams() {
    return this.$route.params.episodeId;
  }

  get nextEpisode() {
    const title = !!this.modalTitle ? this.modalTitle : this.title;

    if (this.seasonsLength) {
      if (this.nextSeasonNum !== undefined && this.nextEpisodeNum !== undefined) {
        const episode = this.seasons?.[this.nextSeasonNum]?.episodes?.[this.nextEpisodeNum];
        return episode?.preview || episode;
      }
    } else if (title?.episodes?.length) {
      return title?.episodes?.[this.playingEpisodeWithoutSeasonIndex + 1]?.preview;
    }
  }

  get nextEpisodeId() {
    return this.nextEpisode?.id;
  }

  get nextEpisodeNum() {
    return selectors.vod.nextEpisodeNumSelector(this.$store);
  }

  get playingMediaItemId() {
    return selectors.vod.playingMediaItemIdSelector(this.$store);
  }

  get playingEpisodeTitle() {
    // for episodes with seasons
    if (this.seasonsLength) {
      const season = this.playingTitle?.seasons?.find((s) =>
        s.episodes.find(
          (e) => e?.id === this.playingEpisodeId || e?.preview?.id === this.playingEpisodeId
        )
      );

      const seasonTitle = season?.number
        ? `${this.getTranslation('season')} ${season?.number}. `
        : '';

      const episode = season?.episodes.find(
        (e) => e?.id === this.playingEpisodeId || e?.preview?.id === this.playingEpisodeId
      );

      const episodeTitle = episode?.title || episode?.preview?.title || '';

      return `${seasonTitle}${episodeTitle}`;

      // for episodes without seasons
    } else if (this.playingTitle?.episodes?.length) {
      return (
        this.playingTitle?.episodes?.find((e) => e?.preview?.id === this.playingEpisodeId)?.preview
          ?.title || ''
      );
    }
  }

  get nextEpisodeTitle() {
    // for episodes with seasons
    if (
      this.seasonsLength &&
      this.playingEpisodeId &&
      this.nextEpisodeNum !== undefined &&
      this.nextEpisodeNum >= 0 &&
      this.nextSeasonNum !== undefined &&
      this.nextSeasonNum >= 0
    ) {
      const season = this.playingTitle?.seasons?.[this.nextSeasonNum];
      const seasonTitle = season?.number
        ? `${this.getTranslation('season')} ${season?.number}. `
        : '';
      const episode = season?.episodes[this.nextEpisodeNum];
      const episodeTitle = episode?.title || episode?.preview?.title || '';
      return `${seasonTitle}${
        episodeTitle || this.getTranslation('episode') + ' ' + this.nextEpisodeNum
      }`;
      // for episodes without seasons
    } else if (this.playingTitle?.episodes?.length) {
      return this.playingTitle?.episodes?.[this.playingEpisodeWithoutSeasonIndex + 1]?.preview
        ?.title;
    }
  }

  get playingEpisodeWithoutSeasonIndex() {
    const title = !!this.modalTitle ? this.modalTitle : this.title;

    return selectors.vod.playingEpisodeWithoutSeasonIndexSelector(
      this.$store,
      this.sourceId,
      title
    );
  }

  get mediaItemIdFromParams() {
    return this.$route.params.mediaItemId;
  }

  preparePosterLink(title: TVODTitlePreviewEnhanced, sourceId: string) {
    return prepareTitleLink(title, sourceId);
  }
}
