<template>
  <SelectorSingle
    class="mr-16"
    :limit="2"
    :items="itemsForPageSelector"
    :selected="currentPageIndex"
    :show-icon-for-button-dropdown="true"
    :convert-to-dropdown-for-tables-and-mobile="true"
    @select="selectPage"
  />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import SelectorSingle from 'src/components/ui/selector-single/SelectorSingle.vue';
import IconViewDetail from 'src/svg/view-detail.svg';
import IconViewGrid from 'src/svg/view-grid.svg';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { makePath } from 'src/utils/url';

@Component({
  components: { SelectorSingle },
})
export default class ChannelsPageSelector extends SequoiaComponent {
  itemsForPageSelector = [
    {
      index: 0,
      number: 1,
      text: this.getTranslation('now_on_air'),
      icon: IconViewDetail,
    },
    {
      index: 1,
      number: 2,
      text: this.getTranslation('list_of_channels'),
      icon: IconViewGrid,
    },
  ];

  get currentPageIndex() {
    return this.isChannelsCatalogNowOpen ? 0 : this.isChannelsCatalogListOpen ? 1 : -1;
  }

  get isChannelsCatalogListOpen() {
    return selectors.tvChannels.isChannelsCatalogListOpenSelector(this.$store);
  }

  get isChannelsCatalogNowOpen() {
    return selectors.tvChannels.isChannelsCatalogNowOpenSelector(this.$store);
  }

  get isChannelsCatalogNowRoute() {
    const route = this.$route.name === 'channels-now';
    if (process.env.VUE_ENV === 'client') {
      return route || (window && window.location.href.includes('now'));
    } else {
      return route;
    }
  }

  get isChannelsCatalogListRoute() {
    const route = this.$route.name === 'channels-list';
    if (process.env.VUE_ENV === 'client') {
      return route || (window && window.location.href.includes('list'));
    } else {
      return route;
    }
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  async mounted() {
    // if /channels is the final route than always redirect to /channels/now
    if (!this.isChannelsCatalogListRoute && !this.isChannelsCatalogNowRoute) {
      this.selectPage(0);
    }
  }

  selectPage(index: number) {
    actions.tvChannels.setIsChannelsCatalogNowOpen(this.$store, index === 0);
    actions.tvChannels.setIsChannelsCatalogListOpen(this.$store, index === 1);
    let url = `/channels/${index === 0 ? 'now' : 'list'}`;

    if (this.currentChannelId) {
      url += `/${this.currentChannelId}/watch`;
    }

    history.pushState({}, '', makePath(url));
    actions.tvChannels.setChannelsCatalogMetaTitle(this.$store);
  }
}
</script>
