<template>
  <div
    class="background-container"
    :style="containerStyle"
    :data-ctype="$options.name"
    :data-cname="componentName"
  >
    <div class="text-container" :style="textStyle" v-html="contentBlock" />
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

@Component
export default class BackgroundWithText extends mixins(Global, CmsComponent) {
  content?: {
    contentBlock: { [language: string]: string };
    image: TTranslations<TImageCMS>;
    margins?: {
      top: string;
      right: string;
      left: string;
      bottom: string;
    };
    textAlign: string;
  } | null = null;

  get containerStyle() {
    const backgroundUrl = this.getByLanguage(this.content?.image)?.url || '';
    const margins = this.content?.margins;
    return {
      background: `url(${backgroundUrl}) left top no-repeat`,
      margin: `${margins?.top} ${margins?.right} ${margins?.bottom} ${margins?.left}`,
    };
  }

  get textStyle() {
    const textAlign = this.content?.textAlign;
    return {
      textAlign,
      background: `linear-gradient(to ${textAlign || 'right'}, rgba(0, 0, 0, 0), #000000)`,
    };
  }

  get contentBlock() {
    return this.getByLanguage(this.content?.contentBlock);
  }
}
</script>

<style lang="scss" scoped>
.background-container {
  background-size: cover;

  .text-container {
    padding: 100px 60px;
  }
}
</style>
