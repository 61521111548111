<template>
  <div class="phone-verification">
    <QuickSubscribeBackground :reducedImage="true" />

    <div class="qs-content">
      <h2
        class="with-side-and-bottom-padding dark"
        v-html="getTranslation(token ? 'qr_phone_verification' : 'phone_verification')"
      />

      <div class="form-container with-side-and-bottom-padding pt-32 light">
        <div
          class="title"
          v-html="getTranslation(token ? 'qr_enter_phone_number' : 'enter_phone_number')"
        />

        <form autocomplete="off" novalidate class="form" @submit.prevent="start">
          <InputText
            v-model="$store.QS.phone"
            theme-forced="light"
            input-category="floating"
            name="phone"
            type="tel"
            size="medium"
            data-cy="phone"
            :label="getTranslation('phone_number_reg')"
            :status="$store.incompletePhone ? 'error' : undefined"
            :message="getTranslation('incomplete_phone_reg')"
            @focus="
              gaEventOnce({
                category: 'acquiring',
                action: 'Фокус в поле телефон',
                qs_qr: $route.query.token ? 'qr' : 'qs',
              })
            "
          />

          <InputText
            v-if="withCaptcha"
            v-model="captchaValue"
            theme-forced="light"
            input-category="floating"
            size="medium"
            required
            maxlength="6"
            :with-captcha="true"
            :label="getTranslation('code_reg')"
            :description="getTranslation('enter_digits_left')"
          />

          <p v-if="error" class="error" v-html="error" />

          <ButtonDefault :disabled="disabledButton" class="button">
            <span v-html="getTranslation('next_reg')" />
          </ButtonDefault>

          <LoaderSpinner v-if="isLoading" />
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import capitalize from 'lodash/capitalize';
import { goToNextStep } from 'src/store/quick-subscribe/actions';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import Verification from 'src/mixins/Verification';
import { HTTP_CODES, REGISTRATION_CAPTCHA_LENGTH, VERIFICATION_METHODS } from 'src/constants';
import ErrorSequoia from 'src/components/ErrorSequoia.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import Global from 'src/mixins/Global';
import Metrics from 'src/mixins/Metrics';
import logger from 'src/utils/logger';
import * as api from 'src/api';
import { actions } from 'src/store/actions';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';

const log = logger('quick-subscribe');

@Component({
  components: {
    InputText,
    QuickSubscribeBackground,
    ErrorSequoia,
    ButtonDefault,
    LoaderSpinner,
  },
})
export default class PhoneSending extends mixins(Global, Metrics, Verification) {
  captchaValue = '';
  withCaptcha = false;
  error = '';
  isLoading = false;

  get disabledButton() {
    return (
      this.isLoading ||
      !this.$store.QS.phone ||
      this.$store.incompletePhone ||
      (this.captchaValue.length < REGISTRATION_CAPTCHA_LENGTH && this.withCaptcha)
    );
  }

  get token() {
    return this.$route.query.token;
  }

  async mounted() {
    const { methods } = await api.auth.contactsVerification.getMethods();

    this.withCaptcha = !methods?.find(
      (method) => method.type === VERIFICATION_METHODS.PHONE_WITH_ACCOUNT_SESSION
    );

    this.$store.isPhoneMode = true;
  }
  goToNextStep = goToNextStep;
  capitalize = capitalize;

  async start() {
    this.isLoading = true;

    try {
      await actions.registration.verifyContacts(
        this.$store,
        this.$store.QS.phone,
        this.withCaptcha ? this.captchaValue : null,
        'set_account_contact_phone',
        { token: this.token }
      );

      this.gaEvent({
        category: 'acquiring',
        action: 'Сабмит "Ввести номер телефона"',
        qs_qr: this.token ? 'qr' : 'qs',
      });

      this.goToNextStep(this.$store);
    } catch (err) {
      this.gaEvent({
        category: 'acquiring',
        action: 'Сабмит "Ввести номер телефона"',
        qs_qr: this.token ? 'qr' : 'qs',
        error_text: err.message,
      });
      this.isLoading = false;
      this.error = err;
      log.error(err);
      if (err?.code === HTTP_CODES.BAD_REQUEST || err.name === 'Error') {
        this.captchaValue = '';
        this.$store.authAndReg.captchaRefreshValue++;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.phone-verification {
  .qs-content {
    display: flex;
    flex-direction: column;
    min-height: 600px;
  }

  .title {
    max-width: 480px;
    margin-bottom: 24px;
  }

  .form-container {
    flex: 1;
    background-color: var(--c-light-100);

    .form {
      width: 100%;
      max-width: 300px;

      .input-block {
        margin-bottom: 16px;
      }

      .error {
        margin-bottom: 16px;
        margin-left: 16px;
      }
    }

    @include mobile {
      .form {
        max-width: 100%;
      }

      .button {
        width: 100%;
      }
    }
  }
}
</style>
