<template>
  <div class="enter-pin-step">
    <div class="h3" v-html="getTranslation('megafon_warning_title')" />
    <div class="body1 mb-32" v-html="getTranslation('megafon_warning_message')" />
    <ButtonDefault
      view="primary"
      type="button"
      class="mr-16"
      @click="$emit('changeStep', 'Welcome')"
    >
      <span v-html="getTranslation('next')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';

@Component({
  components: { ButtonDefault },
})
export default class WithJwtStep extends SequoiaComponent {}
</script>
