<template>
  <div v-if="channelsByGenre.length" class="channel-slider-more-channels" :class="theme">
    <SectionHeader class="with-side-padding" :text="sectionHeaderText" />
    <ChannelsStrip
      :key="refreshKey"
      :channels="channelsByGenre"
      :show-slider-only="true"
      :highlight-visible="true"
      data-cy="more-channels"
      @gaFavorite="gaFavorite"
      @gaClick="gaClick"
      @gaScroll="gaScroll"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Watch } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { TChannelEnhanced } from 'src/api/channels/types';
import { getChannelGenres } from 'src/utils/channel';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import ChannelsStrip from 'src/components/home/ChannelsStrip.vue';

@Component({
  components: {
    ContentSlider,
    SectionHeader,
    ChannelsStrip,
  },
})
export default class ChannelSliderMoreChannels extends SequoiaComponent {
  refreshKey = 0;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  channel!: TChannelEnhanced;

  @Watch('channel')
  onChannelChange() {
    this.refreshKey++;
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get channelId() {
    return this.channel.id;
  }

  get channelsByGenre() {
    return Object.values(
      selectors.tvChannels.filteredChannelsSelector(this.$store, this.genre, true)
    ).slice(0, 20);
  }

  get genre() {
    const genre = getChannelGenres(this.channel)?.[0];

    if (typeof genre === 'string') {
      return genre;
    } else {
      return this.$store.tvChannels.genres.find((g) => g?.id === genre?.genreId)?.title || '';
    }
  }

  get sectionHeaderText() {
    return `${this.getTranslation('more')} ${this.getTranslation(
      'channels'
    ).toLowerCase()} ${this.getTranslation('in_genre')}: ${this.genre}`;
  }

  gaFavorite(id: string | undefined) {
    this.gaEvent({
      category: 'channels',
      action: 'more_channels, more_by_genre',
      channel_name: id,
    });
  }

  gaClick(id: string | undefined) {
    this.gaEvent({ category: 'channels', action: 'more_channels, click', channel_name: id });
  }

  gaScroll() {
    this.gaEvent({ category: 'channels', action: 'more_channels, scroll' });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.channel-slider-more-channels {
  padding-top: 48px;
  margin-bottom: 16px;

  @include mobile-and-tablet {
    padding-top: 32px;
  }

  .section-header {
    margin-bottom: 24px;

    @include desktop-s {
      margin-bottom: 16px;
    }

    @include mobile-and-tablet {
      margin-bottom: 16px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    background-color: var(--c-light-100);
  }

  &.dark {
    background-color: var(--c-dark-150);
  }
}
</style>
