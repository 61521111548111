import sortBy from 'lodash/sortBy';

export const isOkObject = (obj: any) => typeof obj === 'object' && !!obj;

export const sortByPriority = (obj: Record<any, any>) => sortBy(obj, 'priority');

const flattenObjectRecursive = (obj: Record<any, any>, delimiter: string, prefix = '') => {
  let flattened: Record<any, any> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] !== 'object') {
        flattened[`${prefix}${key}`] = obj[key];
      } else {
        flattened = {
          ...flattened,
          ...flattenObjectRecursive(obj[key], delimiter, `${key}${delimiter}`),
        };
      }
    }
  }
  return flattened;
};

/**
 * Flatten complex object to one-dimensional object, e.g.
 * {
 *   app: {
 *     a: 1,
 *     b: 2,
 *     c: 3
 *   }
 * }
 * will be transformed into
 *
 * { "app.a": 1, "app.b": 2, "app.c": 3 }
 */
export const flattenObject = (obj: Record<any, any>, delimiter = '.') =>
  flattenObjectRecursive(obj, delimiter);

export const deleteFromObject = (keyPart: any, obj: Record<any, any>) => {
  for (const k in obj) {
    // Loop through the object
    if (~k.indexOf(keyPart)) {
      // If the current key contains the string we're looking for
      delete obj[k]; // Delete obj[key];
    }
  }
};
