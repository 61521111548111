import { TTvEpg } from 'src/store/tv-epg/types';

const initialState: TTvEpg = {
  loading: false,
  loaded: false,
  list: {},
  searchQuery: '',
  currentProgramIndex: -1,
};

export default initialState;
