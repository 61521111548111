<template>
  <NotificationWithDetails
    v-if="showNotificationRetry"
    :title="getTranslation('payment_error')"
    :message="paymentInRetryTranslation"
    :icon="IconCard"
    data-cy="notification-auth-reg"
    @hide="hideNotification"
  >
    <ButtonDefault view="primary" @click="onCtaClick">
      <span v-html="getTranslation('to_personal_area')" />
    </ButtonDefault>
  </NotificationWithDetails>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { NotificationBaseComponent } from 'src/components/ui/notifications/NotificationBaseComponent';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import NotificationWithDetails from 'src/components/ui/notifications/NotificationWithDetails.vue';
import IconCard from 'src/svg/card.svg';
import { cookieStore } from 'src/utils/storage';
import { convertToMilliseconds } from 'src/utils/time/convert-time';
import { COOKIE_NAMES } from 'src/constants';
import { TPaymentSubscription } from 'src/store/payment/types';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    ButtonDefault,
    NotificationWithDetails,
  },
})
export default class NotificationRetry extends NotificationBaseComponent {
  IconCard = IconCard;

  @Prop({ required: true, default: [] })
  inRetrySubscriptions!: Array<TPaymentSubscription>;

  get showNotificationRetry() {
    return selectors.common.showNotificationRetrySelector(this.$store);
  }

  get paymentInRetryTranslation() {
    return this.inRetrySubscriptions.length === 1
      ? this.getTranslation('payment_in_retry')
      : this.getTranslation('payment_in_retry_multiple');
  }

  async mounted() {
    if (this.inRetrySubscriptions.length && cookieStore.get(COOKIE_NAMES.hideRetryAlert) !== '1') {
      actions.common.setShowNotificationRetry(this.$store, true);
    }
  }

  hideNotification() {
    this.loaded = false;
    setTimeout(() => {
      actions.common.setShowNotificationRetry(this.$store, false);
      cookieStore.set(COOKIE_NAMES.hideRetryAlert, '1', {
        expires: new Date(Date.now() + convertToMilliseconds(1, 'days')),
      });
    }, 250);
  }

  onCtaClick() {
    actions.common.setShowNotificationRetry(this.$store, false);
    this.$emit('ctaClick');
  }
}
</script>

<style lang="scss">
@import 'notification-base';
</style>
