<template>
  <ButtonDefault
    data-cy="play-next-episode"
    size="large"
    view="tertiary"
    :full-width="fullWidth"
    :theme-forced="theme"
    :size="size"
    @click="$emit('click')"
  >
    <IconSVG :svg="IconMediaNext" />
    <span v-html="getTranslation('next_episode')" />
  </ButtonDefault>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconMediaNext from 'src/svg/player/media-next.svg';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { ButtonDefault, IconSVG },
})
export default class TitleButtonStartNextEpisode extends SequoiaComponent {
  IconMediaNext = IconMediaNext;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: 'large' })
  size!: 'small' | 'medium' | 'large';

  @Prop({ default: false })
  fullWidth?: boolean;
  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>
