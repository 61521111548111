import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { TSeoBreadcrumb, TSeoMeta } from 'src/store/seo/types';
import Vue from 'vue';
import { getByLanguage } from 'src/utils/language';

const LOCALE: Record<string, string> = {
  ru: 'ru_Ru',
};

@Component
export default class Seo extends Vue {
  @Watch('$route')
  onRouteChange() {
    this.initMeta();
  }

  get seoBreadcrumbs(): TSeoBreadcrumb[] {
    return [];
  }

  get breadcrumbTelevisionChannel(): TSeoBreadcrumb | null {
    return null;
  }

  get breadcrumbProduct(): TSeoBreadcrumb | null {
    return null;
  }

  created() {
    this.initMeta();

    if (this.seoBreadcrumbs.length) {
      actions.seo.addBreadcrumbs(this.$store, this.seoBreadcrumbs);
    }

    if (this.breadcrumbTelevisionChannel) {
      actions.seo.addBreadcrumbTelevisionChannel(this.$store, this.breadcrumbTelevisionChannel);
    }

    if (this.breadcrumbProduct) {
      actions.seo.addBreadcrumbProduct(this.$store, this.breadcrumbProduct);
    }
  }

  getMetaInfo(): { title: string; meta: TSeoMeta[] } {
    return {
      title: '',
      meta: [],
    };
  }

  initMeta() {
    const { title, meta } = this.getMetaInfo();
    const { languageCode } = this.$store;

    actions.seo.setMetaTitle(this.$store, title);

    const ogMeta = [
      {
        name: 'og:type',
        content: 'website',
      },
      {
        name: 'og:site_name',
        content: getByLanguage(this.$store.translations['smotreshka'] || '', languageCode) || '',
      },
      {
        name: 'og:locale',
        content: LOCALE[languageCode],
      },
      {
        name: 'og:url',
        content: `https://smotreshka.tv${this.$route.fullPath}`,
      },
    ];

    actions.seo.setMeta(this.$store, [...meta, ...ogMeta]);
  }

  getDefaultMetaOgImage() {
    const page = this.$store.cmsPages?.find((page) => page.path === '/');

    if (page) {
      return page.dataObject.metaImages.ogFileKey;
    }
  }
}
