import { TStore } from 'src/store/types';
import { TSeoBreadcrumb, TSeoMeta } from 'src/store/seo/types';
import initialState from 'src/store/seo/store';
import { getByLanguage } from 'src/utils/language';
import Vue from 'vue';

export const reset = (store: TStore) => Vue.set(store, 'seo', initialState);

export const setMetaTitle = (store: TStore, title = '') => {
  store.seo.title = title || getByLanguage(store.translations['title'], store.languageCode) || '';

  if (typeof document !== 'undefined') {
    document.title = title;
  }
};

export const setMeta = (store: TStore, meta: TSeoMeta[]) => Vue.set(store.seo, 'meta', meta);

export const addMeta = (store: TStore, meta: TSeoMeta) => store.seo.meta.push(meta);

export const addBreadcrumbs = (store: TStore, breadcrumbs: TSeoBreadcrumb[]) =>
  Vue.set(store.seo, 'breadcrumbs', breadcrumbs);

export const addBreadcrumbTelevisionChannel = (store: TStore, breadcrumb: TSeoBreadcrumb | null) =>
  Vue.set(store.seo, 'breadcrumbTelevisionChannel', breadcrumb);

export const addBreadcrumbProduct = (store: TStore, breadcrumb: TSeoBreadcrumb | null) =>
  Vue.set(store.seo, 'breadcrumbProduct', breadcrumb);
