import Component from 'vue-class-component';
import Vue from 'vue';
import { SCREEN_MIN_WIDTH_FOR_SHOWING_PLAYER } from 'src/constants';
import debounce from 'lodash/debounce';

@Component
export default class GetAndSetWindowWidth extends Vue {
  windowWidth = SCREEN_MIN_WIDTH_FOR_SHOWING_PLAYER; // TODO refactor this legacy
  isLoaded = false;

  $_getAndSetWindowWidth() {
    debounce(() => {
      this.windowWidth = document.documentElement.clientWidth;
      this.isLoaded = true;
    }, 200)();
  }
}
