<template>
  <div class="set-pin-step">
    <div class="h3" v-html="getTranslation('welcome_screen_step_setpin_title')" />
    <div class="body1 mb-8" v-html="getTranslation('welcome_screen_step_setpin_text')" />
    <div class="body1 mb-32" v-html="getTranslation('welcome_screen_step_setpin_text_2')" />
    <div class="body1 mb-16 text" v-html="getTranslation('welcome_screen_step_setpin_text_3')" />

    <ButtonDefault view="primary" type="button" class="mr-16" @click="goToView">
      <span v-html="getTranslation('go_to_view')" />
    </ButtonDefault>
    <ButtonDefault view="secondary" type="button" :isLoading="isLoading" @click="setPinCode">
      <span v-html="getTranslation('set_pin_code')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { cookieStore, localStore, sessionStore } from 'src/utils/storage';
import * as api from 'src/api';
import { TContactsVerificationVerify } from 'src/api/auth/types';

import logger from 'src/utils/logger';
const log = logger('set-pin-step');

@Component({
  components: { ButtonDefault },
})
export default class SetPinStep extends SequoiaComponent {
  isLoading = false;

  async goToView() {
    this.$emit('close');
    cookieStore.set('welcomeScreenConfirmed', '1');
    localStore.set('profileConfirmed', true);
    await api.profile.managingLogout().catch((err) => {
      log.error(err);
    });
  }

  async setPinCode() {
    this.isLoading = true;

    const verificationResult = sessionStore.get(
      'verificationResult'
    ) as null | TContactsVerificationVerify;
    const session = verificationResult?.sessionToken || '';

    if (!this.$store.authAndReg.passwordAuth && !session) {
      this.$emit('changeStep', 'Verify');
      return;
    }

    try {
      if (this.$store.authAndReg.passwordAuth) {
        await api.profile.managing({
          data: {
            email: this.$store.account?.user.login,
            password: this.$store.authAndReg.passwordAuth,
          },
        });
      } else if (session) {
        await api.profile.managingWithVerifiedContacts({
          params: {
            'contacts-session-token': session,
          },
          data: {
            login: this.$store.account?.user.login,
          },
        });
      }

      this.$emit('changeStep', 'EnterPin');
    } catch (err) {
      this.$emit('changeStep', 'Verify');
    } finally {
      sessionStore.remove('verificationResult');
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  color: var(--alpha-light-7);
}

.dark {
  .text {
    color: var(--alpha-dark-7);
  }
}
</style>
