<template>
  <div v-if="country" class="title-details-info-with-icon country" :class="theme">
    <IconSVG v-if="showIcon" :svg="IconCountry" />
    <span v-text="country" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconCountry from 'src/svg/country.svg';

@Component({
  components: {
    IconSVG,
  },
})
export default class TitleCountry extends Vue {
  IconCountry = IconCountry;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  country?: string;

  @Prop({ default: false })
  showIcon?: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/title-details-info-with-icon';
</style>
