import { TStore } from 'src/store/types';

export const globalSearchUsefulLinksSelector = (store: TStore) =>
  store.siteConfig?.globalSearch?.usefulLinks || [];

export const globalSearchPopularQueriesSelector = (store: TStore) =>
  store.siteConfig?.globalSearch?.popularQueries || [];

export const promocodesKtResultsSelector = (store: TStore) =>
  store.siteConfig?.promocodesKT?.results || [];

export const promocodesKtUsedCodesSelector = (store: TStore) =>
  promocodesKtResultsSelector(store).map((i) => i.code);

export const promocodesKtCodesSelector = (store: TStore) => {
  const codes = store.siteConfig?.promocodesKT?.codes.split('\n');
  return codes || [];
};
