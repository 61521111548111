<template>
  <div class="lazy-image" :class="theme" @click="$emit('click')">
    <ImageBackground v-if="withBg" :with-icon="withIcon" :theme-forced="theme" />

    <div v-if="withBackgroundBlur" class="blur-wrap">
      <div class="blur" :style="{ backgroundImage: `url('${srcFinal}')` }" />
    </div>

    <component
      v-if="tag !== 'img'"
      :is="tag"
      ref="refBlock"
      class="image"
      :class="{ hidden: !wasRendered }"
      :style="{ backgroundImage: `url('${srcFinal}')` }"
    >
      <slot />
    </component>
    <img
      v-else
      ref="refImage"
      class="image"
      :srcset="srcset"
      :class="{ hidden: !wasRendered }"
      :src="srcFinal"
      :width="width"
      :height="height"
      alt=""
    />
  </div>
</template>

<script lang="ts">
import Vue, { nextTick } from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import lazy, { cached } from 'src/utils/lazy-images/lazy';
import LazyImageInstance from 'src/utils/lazy-images/lazy-image';
import { findScrollableParent } from 'src/utils/lazy-images/utils';
import { BLANK_IMAGE } from 'src/constants';
import { getResizedImageUrl } from 'src/utils/images';
import logger from 'src/utils/logger';
import { matchUrlProtocolWithLocationProtocol, removePortFromServerUrl } from 'src/utils/url';
import IconSVG from 'src/components/IconSVG.vue';
import ImageBackground from 'src/components/ui/ImageBackground.vue';

const log = logger('lazy-image');

@Component({
  components: { ImageBackground, IconSVG },
})
export default class LazyImage extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: true })
  withBg!: boolean;

  // shows blurred background made from the original image
  // usually used for vertical posters
  @Prop({ default: false })
  withBackgroundBlur!: boolean;

  // shows default popcorn icon placeholder while actual image is loading
  @Prop({ default: false })
  withIcon!: boolean;

  @Prop({ required: true })
  src!: string;

  @Prop({ default: 'img' })
  tag!: 'img' | 'div';

  @Prop()
  width?: number;

  @Prop()
  height?: number;

  @Prop()
  provider?: string;

  @Ref('block')
  refBlock?: HTMLElement;

  @Ref('image')
  refImage?: HTMLImageElement;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get isSrcRelative() {
    return this.src?.indexOf('http://') !== 0 && this.src?.indexOf('https://') !== 0;
  }

  get width2x() {
    return this.width ? this.width * 2 : 0;
  }

  get height2x() {
    return this.height ? this.height * 2 : 0;
  }

  get srcPrepared() {
    if (this.isSrcRelative) {
      return this.src;
    }
    const src = removePortFromServerUrl(this.src);
    return matchUrlProtocolWithLocationProtocol(src, this.$store.common.isHttps);
  }

  get srcResized() {
    return this.canResize
      ? getResizedImageUrl(this.srcPrepared, this.width, this.height, this.provider)
      : this.srcPrepared;
  }

  get srcResized2x() {
    return this.canResize
      ? getResizedImageUrl(this.srcPrepared, this.width2x, this.height2x, this.provider)
      : this.srcResized;
  }

  get srcFinal() {
    if (!this.wasRendered) {
      return BLANK_IMAGE;
    }
    return this.srcResized;
  }

  get srcFinal2x() {
    if (!this.wasRendered) {
      return BLANK_IMAGE;
    }
    return this.srcResized2x;
  }

  get srcset() {
    return `${this.srcFinal} 1x, ${this.srcFinal2x} 2x`;
  }

  get canResize() {
    return !!(this.width && this.height && !this.isSrcRelative);
  }

  get wasRendered() {
    if (process.env.VUE_ENV === 'server') {
      return false;
    } else if (!!process.env.STORYBOOK) {
      return true;
    }
    return !!cached.src[this.srcResized];
  }

  mounted() {
    this.init();
  }

  beforeDestroy() {
    this.removeFromQueue();
  }

  init() {
    if (!this.src) {
      log.warning('SRC is empty', this.refImage?.parentNode);
      return;
    }

    if (!this.srcFinal) {
      log.warning('srcHighRes is empty', this.refImage?.parentNode);
      return;
    }

    if (this.isSrcRelative) {
      // if it's a local image -> do nothing
      return;
    } else if (this.wasRendered) {
      // if image has already been rendered before -> do nothing
      return;
    }

    const lazyImage = lazy.findImageByEl(this.$el as HTMLElement);
    if (lazyImage) {
      // if lazy image element is in queue, but the image hasn't been rendered yet
      lazyImage.update(this.srcResized);
      nextTick(lazy.findVisibleImagesToLoad);
    } else {
      // if image hasn't been rendered and not in queue -> add image to the queue
      this.addToQueue();
    }
  }

  /**
   * add lazy image instance to the queue
   */
  addToQueue() {
    const el = this.$el as HTMLElement;
    const src = this.srcResized;
    const lazyImage = new LazyImageInstance({ el, src });

    nextTick(() => {
      if (lazyImage) {
        const parent = findScrollableParent(el);
        lazyImage.setParent(parent);
        lazy.addToQueue(lazyImage);
      }
      nextTick(lazy.findVisibleImagesToLoad);
    });
  }

  /**
   * remove lazy image instance form the queue
   */
  removeFromQueue() {
    const el = this.$el as HTMLElement;
    lazy.removeFromQueue(el);
  }
}
</script>

<style lang="scss" scoped>
.lazy-image {
  position: relative;
  width: 100%;
  height: 100%;

  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: cover;

    &.hidden {
      display: none;
    }
  }

  .background {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .placeholder {
      width: 100%;
      height: 100%;
    }
  }

  .blur-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;

    .blur {
      height: 100%;
      filter: blur(50px);
      background-size: cover;
    }
  }

  // -----------------------------------------------
  // Theme Colors
  // -----------------------------------------------
  &.light {
    .background {
      background-color: var(--c-light-200);
    }

    .placeholder {
      color: var(--c-light-300);
    }
  }

  &.dark {
    .background {
      background-color: var(--c-dark-400);
    }

    .placeholder {
      color: var(--c-dark-500);
    }
  }
}
</style>
