import * as Cookies from 'js-cookie';
import { convertToMilliseconds } from 'src/utils/time/convert-time';

class AbstractStore {
  store: any;

  constructor() {
    this.store = null;
  }

  set(key: string, val: any, config?: Record<any, any>) {
    if (!config) {
      config = {};
    }
    this.store.setItem(key, JSON.stringify(val));
    return val;
  }

  get(key: string) {
    const value = this.store.getItem(key);
    if (typeof value != 'string') {
      return null;
    }
    try {
      return JSON.parse(value);
    } catch (err) {
      return value;
    }
  }

  getWithDefault(key: string, defaultValue: any) {
    const value = this.get(key);

    if (value == null) return defaultValue;
    else if (typeof defaultValue === 'string') return value;
    else if (typeof value === 'string') return defaultValue;
    else return value;
  }

  // 2nd parameter path is needed for proper class extension by CookieStore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  remove(key: string, path?: string) {
    this.store.removeItem(key);
  }

  removeAll() {
    this.store.clear();
  }

  getAll() {
    const res: Record<any, any> = {};

    for (let i = 0; i < this.store.length; i++) {
      const _key = this.store.key(i);
      if (!_key) continue;
      const key = _key.toString();
      res[key] = this.get(key);
    }

    return res;
  }
}

class Localstore extends AbstractStore {
  constructor() {
    super();
    this.store = window.localStorage;
  }
}

class Sessionstore extends AbstractStore {
  constructor() {
    super();
    this.store = window.sessionStorage;
  }
}

const processValue = (value: any) => {
  if (value.substring(0, 1) === '{') {
    try {
      return JSON.parse(value);
    } catch (err) {
      return value;
    }
  }
  return value !== 'undefined' ? decodeURIComponent(value) : null;
};

class Cookiestore extends AbstractStore {
  set(name: string, value: any, config: Record<any, any> = {}) {
    const expiresInMonths = 120;

    const {
      expires = new Date(Date.now() + convertToMilliseconds(expiresInMonths, 'months')),
      path = '/',
      secure = false,
    } = config;

    const _config: Cookies.CookieAttributes = { expires, path, secure, sameSite: 'lax' };

    const valueToUse =
      value !== undefined && typeof value === 'object' ? JSON.stringify(value) : value;
    Cookies.set(name, valueToUse, _config);

    return value;
  }

  get(name: string) {
    const result = Cookies.get(name);
    if (result) {
      return processValue(result);
    } else {
      return null;
    }
  }

  getAll() {
    const result: Record<any, any> = {};
    const c = Cookies.get();
    for (const name in c) {
      if (c.hasOwnProperty(name)) {
        result[name] = this.get(name);
      }
    }
    return result;
  }

  remove(name: string, path?: string) {
    const config: Record<any, any> = {};

    if (path) {
      config.path = path;
    }
    Cookies.remove(name, config);
    return null;
  }

  removeAll() {
    const c = Cookies.get();
    for (const name in c) {
      if (c.hasOwnProperty(name)) {
        Cookies.remove(name);
      }
    }
  }
}

class DummyStore extends AbstractStore {
  set(_key: string, val: any, config?: Record<any, any>) {
    if (!config) {
      config = {};
    }
    return val;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  get(key: string) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars, no-unused-vars
  remove(key: string) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeAll() {}

  getAll() {
    return {};
  }
}

const storageAvailable = (type: any) => {
  const storage = window[type];

  try {
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (err) {
    const errCodes = { nonFf: 22, ff: 1024 };
    const errNames = {
      nonFf: 'QuotaExceededError',
      ff: 'NS_ERROR_DOM_QUOTA_REACHED',
    };

    // test name field too, because code might not be present
    const isProperException =
      err.code === errCodes.nonFf ||
      err.code === errCodes.ff ||
      err.name === errNames.nonFf ||
      err.name === errNames.ff;

    // acknowledge QuotaExceededError only if there's something already stored
    return err instanceof DOMException && isProperException && storage?.length !== 0;
  }
};

const cookieAvailable = () => {
  // Quick test if browser has cookieEnabled host property
  if (!navigator.cookieEnabled) {
    return false;
  }
  // Create cookie
  document.cookie = 'cookietest=1';
  const res = document.cookie.indexOf('cookietest=') !== -1;
  // Delete cookie
  document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
  return res;
};

const dummyStore = new DummyStore();

let localStore = dummyStore;
let sessionStore = dummyStore;
let cookieStore: Cookiestore | DummyStore = dummyStore;
let storage: Cookiestore | DummyStore = dummyStore;

const available = {
  local: false,
  session: false,
  cookie: false,
};

if (typeof window !== 'undefined') {
  if ((available.cookie = cookieAvailable())) {
    cookieStore = new Cookiestore();
    storage = cookieStore;
  }

  if ((available.local = storageAvailable('localStorage'))) {
    localStore = new Localstore();
    storage = localStore;
  }

  if ((available.session = storageAvailable('sessionStorage'))) {
    sessionStore = new Sessionstore();
  }
}

export { localStore, sessionStore, cookieStore, storage, available };
