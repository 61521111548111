import * as appInfo from './app-info';
import * as auth from './auth/';
import * as bow from './bow/';
import * as channels from './channels/';
import * as cms from './cms';
import * as home from './home/';
import * as ivi from './ivi/';
import * as loader from './loader';
import * as offers from './offers/';
import * as pauses from './pauses/';
import * as profile from './profile/';
import * as quickSubscribe from './quick-subscribe/';
import * as statistics from './statistics/';
import * as tokens from './tokens/';
import * as vod from './vod';

export {
  appInfo,
  auth,
  bow,
  channels,
  cms,
  home,
  ivi,
  loader,
  offers,
  pauses,
  profile,
  quickSubscribe,
  statistics,
  tokens,
  vod,
};
