<template>
  <transition name="fade-ease-out-fast" mode="out-in">
    <div
      ref="modal"
      class="modal"
      :class="[theme, type, type !== 'responsive' ? size : '']"
      @scroll="onScroll"
    >
      <div
        ref="backdrop"
        class="backdrop"
        data-cy="modal-backdrop"
        :class="[{ 'pointer-events-none': !withButtonClose }, { blur: withBlur }]"
        @mousedown="closeModal"
      >
        <img v-if="withLogo" class="logo" alt="logo" :src="logo" />

        <div
          ref="dialogWrapper"
          class="dialog-wrapper"
          :class="{ 'without-adult-onboarding-bg': !adultOnboardingClass }"
          @mousedown="closeModal"
        >
          <div
            v-if="adultOnboardingClass && ADULT_ONBOARDING_IMAGES[adultOnboardingClass]"
            class="adult-onboarding-bg"
            :class="adultOnboardingClass.toLowerCase()"
          >
            <ImageWithHash :src="adultOnboardingSrc" :srcset="adultOnboardingSrcset" alt="" />
          </div>

          <div
            ref="dialog"
            class="dialog"
            :class="{ 'with-header': withFixedHeader }"
            :style="[modalStyle, { minHeight: minHeight + 'px' }]"
          >
            <template v-if="withFixedHeader">
              <div
                class="header fixed"
                :class="{ visible: isHeaderLocked }"
                :style="[
                  { width: headerWidth > 0 ? headerWidth + 'px' : '' },
                  {
                    marginLeft: headerMarginLeft > 0 ? '-' + headerMarginLeft + 'px' : '',
                  },
                ]"
              >
                <button
                  v-if="withButtonBack"
                  type="button"
                  class="button button-action button-back modal-control"
                  @click="handleBack"
                >
                  <IconSVG :svg="IconAngleLeft" />
                  <span v-html="getTranslation('back')" />
                </button>
                <button
                  v-if="withButtonClose"
                  type="button"
                  class="button button-action button-close modal-control"
                  data-cy="modal-button-close-fixed"
                  @click="handleClose"
                >
                  <IconSVG :svg="IconCross" />
                </button>
                <div v-if="title" class="title h6 dark" v-html="title" />
              </div>

              <div class="header" :class="{ visible: !isHeaderLocked }">
                <button
                  v-if="withButtonBack"
                  type="button"
                  class="button button-action button-back modal-control"
                  @click="handleBack"
                >
                  <IconSVG :svg="IconAngleLeft" />
                  <span v-html="getTranslation('back')" />
                </button>
                <button
                  v-if="withButtonClose"
                  type="button"
                  class="button button-action button-close modal-control"
                  data-cy="modal-button-close"
                  @click="handleClose"
                >
                  <IconSVG :svg="IconCross" />
                </button>
              </div>
            </template>

            <div
              v-if="title && showTitle"
              class="title h3 with-side-and-bottom-padding"
              v-html="title"
            />
            <slot :scroll-block="refModal" />
          </div>
        </div>
      </div>

      <ButtonActionScrollTop :scroll-block="refModal" theme-forced="dark" />
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import debounce from 'lodash/debounce';
import IconCross from 'src/svg/cross.svg';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { selectors } from 'src/store/selectors';
import { ADULT_ONBOARDING_IMAGES } from 'src/constants';
import ButtonActionScrollTop from 'src/components/ui/buttons/ButtonActionScrollTop.vue';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: { ImageWithHash, ButtonActionScrollTop, IconSVG },
})
export default class ModalSequoia extends SequoiaComponent {
  @Prop()
  themeForced?: 'light' | 'dark';

  // responsive or fixed
  @Prop({ default: 'responsive' })
  type!: 'responsive' | 'fixed';

  // small, medium, large, xlarge
  @Prop()
  size?: 'small' | 'medium' | 'large' | 'xlarge';

  @Prop({ default: 0 })
  minHeight!: number;

  @Prop()
  withButtonBack?: boolean;

  @Prop({ default: true })
  withButtonClose!: boolean;

  // shows regualar title in default static position
  @Prop({ default: true })
  showTitle!: boolean;

  @Prop()
  title?: string;

  // some edge cases do not need a header, especially fixed one
  @Prop({ default: true })
  withFixedHeader!: boolean;

  @Prop({ default: false })
  withLogo!: boolean;

  @Prop({ default: false })
  withBlur!: boolean;

  // TODO: it is a temporary prop until we have new branding for modals
  @Prop({ default: () => ({}) })
  modalStyle?: Record<string, unknown>;

  @Watch('type')
  onTypeChange() {
    this.onResizeWithDebounce();
  }

  @Watch('size')
  onSizeChange() {
    this.onResizeWithDebounce();
  }

  @Ref('modal')
  readonly refModal!: HTMLDivElement;

  @Ref('backdrop')
  readonly refBackdrop!: HTMLDivElement;

  @Ref('dialogWrapper')
  readonly refDialogWrapper!: HTMLDivElement;

  @Ref('dialog')
  readonly refDialog!: HTMLDivElement;

  IconCross = IconCross;
  IconAngleLeft = IconAngleLeft;

  windowWidth?: number;
  windowHeight?: number;
  modalOffsetWidth?: number;
  modalClientWidth?: number;
  dialogWidth?: number;
  dialogHeight?: number;

  headerWidth = 0;
  headerMarginLeft = 0;
  isHeaderLocked = false;
  isMobile = false;

  onResizeWithDebounce = debounce(this.onResize, 200);
  currentTopPosition: number = this.isMobile || this.type === 'fixed' ? 32 : 0;

  ADULT_ONBOARDING_IMAGES = ADULT_ONBOARDING_IMAGES;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get logo() {
    const logoImage = this.$store.siteConfig?.logo?.[this.theme as 'light' | 'dark'];
    return this.getByLanguage(logoImage)?.url;
  }

  get adultOnboardingClass() {
    return selectors.adultOnboarding.currentStepSelector(this.$store);
  }

  get adultOnboardingSrc() {
    return ADULT_ONBOARDING_IMAGES[this.adultOnboardingClass] + '.png';
  }

  get adultOnboardingSrcset() {
    return [
      {
        src: `${ADULT_ONBOARDING_IMAGES[this.adultOnboardingClass]}.png`,
        density: '1x',
      },
      {
        src: `${ADULT_ONBOARDING_IMAGES[this.adultOnboardingClass]}2x.png`,
        density: '2x',
      },
    ];
  }

  mounted() {
    actions.common.showModal(this.$store);
    this.onResizeWithDebounce();
    window.addEventListener('resize', this.onResizeWithDebounce);
    window.addEventListener('keydown', this.onKeydown);
    actions.common.lockBodyScroll(this.$store);
  }

  beforeDestroy() {
    actions.common.unlockBodyScroll(this.$store);
    actions.common.hideModal(this.$store);
    window.removeEventListener('resize', this.onResizeWithDebounce);
    window.removeEventListener('keydown', this.onKeydown);
  }

  onResize() {
    this.windowWidth = document.documentElement.clientWidth;
    this.windowHeight = document.documentElement.clientHeight;
    this.dialogHeight = this.refDialog?.clientHeight;
    this.dialogWidth = this.refDialog?.clientWidth;
    this.modalOffsetWidth = this.refModal?.offsetWidth;
    this.modalClientWidth = this.refModal?.clientWidth;

    this.currentTopPosition = this.windowWidth && this.windowWidth < 959 ? 80 : 96;

    if (this.windowWidth < 600) {
      this.isMobile = true;
      this.headerWidth = 0;
      this.headerMarginLeft = 0;
    } else {
      this.isMobile = false;
      // calculate the width of fixed header
      this.headerWidth = this.dialogWidth;
      this.headerMarginLeft = this.dialogWidth / 2;
    }
  }

  onScroll(e: Event) {
    if (!e.target) {
      return;
    }
    this.windowWidth = document.documentElement.clientWidth;
    const currentScrollPosition = (e.target as HTMLElement).scrollTop;
    this.isHeaderLocked = currentScrollPosition > this.currentTopPosition;
  }

  onKeydown(event: KeyboardEvent) {
    const code = (event.key || event.code)?.toLowerCase();

    if (!code) {
      return;
    }

    if (code === 'escape') {
      event.preventDefault();
      this.handleClose('keyboard');
    }
  }

  closeModal(event: MouseEvent) {
    if (event.target === this.refBackdrop || event.target === this.refDialogWrapper) {
      this.handleClose();
    }
  }

  handleClose(controlType?: string) {
    actions.common.hideModal(this.$store);
    this.$emit('close', controlType);
  }

  handleBack() {
    this.$emit('back');
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/side-and-bottom-padding';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

@mixin tablet-large {
  @include tablet {
    width: 536px;

    &.with-header {
      padding-top: 64px;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-modal);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  backface-visibility: hidden;
  overscroll-behavior-y: contain;

  .backdrop {
    position: relative;
    z-index: var(--z-1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    min-height: 100%;
    cursor: pointer;
    background-color: var(--c-overlay-lvl-80);

    &::before {
      content: '';
    }

    @include mobile {
      top: -64px;

      &::before {
        flex: 1 0 32px;
        min-height: 32px;
      }
    }

    &.blur::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      backdrop-filter: blur(10px);
    }

    .logo {
      z-index: var(--z-7);
      width: 165px;
      height: 40px;
    }
  }

  .dialog-wrapper {
    position: relative;

    &.without-adult-onboarding-bg {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .adult-onboarding-bg {
      position: relative;
      width: 100%;
      height: 100px;
      margin-bottom: -8px;
      cursor: auto;
      border-radius: 8px 8px 0 0;

      @include desktop {
        display: none;
      }

      &.enterpin {
        background: linear-gradient(-135deg, rgb(255, 176, 104) 0%, rgb(252, 241, 40) 100%);

        img {
          top: -91px;
        }
      }

      &.verifysession {
        background: linear-gradient(-135deg, rgb(56, 207, 226) 0%, rgb(155, 152, 243) 100%);

        img {
          top: -27px;
        }
      }

      &.noprofile {
        background: linear-gradient(-135deg, rgb(255, 35, 154) 0%, rgb(252, 71, 38) 100%);

        img {
          top: -32px;
        }
      }

      &.setpin {
        background: linear-gradient(-135deg, rgb(255, 176, 104) 0%, rgb(252, 241, 40) 100%);

        img {
          top: -91px;
        }
      }

      &.startstep {
        background: linear-gradient(-135deg, rgb(245, 120, 98) 0%, rgb(202, 0, 192) 100%);

        img {
          top: -67px;
        }
      }

      &.yourpin {
        background: linear-gradient(-135deg, rgb(56, 207, 226) 0%, rgb(155, 152, 243) 100%);

        img {
          top: -27px;
        }
      }

      img {
        position: absolute;
        left: 35px;
      }
    }

    .dialog {
      position: relative;
      z-index: var(--z-2);
      min-width: 320px;
      padding-top: 32px;
      overflow: hidden;
      pointer-events: auto;
      cursor: auto;
      border-radius: 8px;

      @media (min-width: #{$desktop-m-min}) {
        padding-top: 48px;
      }

      @include mobile {
        padding-top: 24px;
      }

      .header {
        position: absolute;
        top: 0;
        left: 0;
        z-index: var(--z-7);
        width: 100%;
        height: 56px;
        opacity: 0;

        @include mobile-and-tablet {
          height: 64px;
        }

        &.visible {
          opacity: 1;

          &:not(.fixed) {
            @include mobile {
              margin-top: 0;
            }
          }
        }

        &.fixed {
          position: fixed;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          text-align: center;
          background-color: var(--c-overlay-lvl-80);
          backdrop-filter: blur(10px);
          // to avoid bugs on scroll in iOS
          transform: translatez(0);
          -webkit-font-smoothing: antialiased;

          @include mobile {
            left: 0;
            transform: none;
          }

          .button {
            &.modal-control {
              top: 8px;
            }
          }

          .title {
            display: inline-block;
            margin-bottom: 0;

            @include mobile {
              display: none;
            }
          }

          .button,
          .button svg {
            color: var(--c-dark-contrast);
          }
        }
      }

      .title {
        @extend %truncate-after-1-line;
      }

      .button {
        &.modal-control {
          position: absolute;
          top: 8px;
          z-index: var(--z-8);
          transition: none;

          &.button-back {
            left: 8px;
          }

          &.button-close {
            right: 8px;
          }
        }
      }
    }
  }

  &.fixed {
    .logo {
      margin-bottom: 32px;
    }

    &.xlarge {
      .dialog {
        width: 1184px;

        @include desktop-s {
          width: 864px;
        }

        @include tablet-large;
      }
    }

    &.large {
      .dialog {
        width: 864px;
        @include tablet-large;
      }
    }

    &.medium {
      .dialog {
        width: 644px;
        @include tablet-large;
      }
    }

    &.small {
      .dialog {
        width: 500px;

        @include tablet {
          width: 438px;

          &.with-header {
            padding-top: 64px;
          }
        }
      }
    }

    .backdrop {
      justify-content: center;
      padding-top: 32px;
      padding-bottom: 32px;
    }

    .dialog {
      &.with-header {
        padding-top: 56px;
      }

      &::v-deep .with-side-and-bottom-padding {
        padding-right: 48px;
        padding-bottom: 32px;
        padding-left: 48px;
      }
    }
  }

  &.responsive {
    .logo {
      position: absolute;
      top: 32px;

      @include mobile-and-tablet {
        top: 30px;
      }

      @include mobile {
        position: relative;
        top: 0;
        margin-bottom: 32px;
      }
    }

    .backdrop {
      padding-right: 48px;
      padding-left: 48px;

      @include tablet {
        padding-right: 32px;
        padding-left: 32px;
      }

      @include mobile {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .dialog-wrapper {
      .dialog {
        width: 100%;
        max-width: 1344px;
        margin: 96px auto;

        &.with-header {
          padding-top: 64px;
        }

        &::v-deep {
          .with-side-and-bottom-padding {
            @include side-and-bottom-padding;
          }
        }

        @include desktop-s {
          &.with-header {
            padding-top: 56px;
          }
        }

        @include tablet {
          margin: 80px auto;

          &.with-header {
            padding-top: 64px;
          }
        }
      }

      .button.modal-control {
        @media (min-width: #{$desktop-m-min}) {
          top: 16px;

          &.button-close {
            right: 16px;
          }

          &.button-back {
            left: 16px;
          }
        }
      }
    }
  }

  // mobile modal is UNIVERSAL
  // need to override all settings
  &.responsive,
  &.fixed,
  &.fixed.small,
  &.fixed.medium,
  &.fixed.large,
  &.fixed.xlarge {
    @include mobile {
      .backdrop {
        padding-top: 0;
        padding-bottom: 0;
      }

      .dialog-wrapper,
      .dialog {
        width: 100%;
        min-width: 0;
        margin: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        transform: none;

        &.with-header {
          padding-top: 64px;
        }

        &::v-deep .with-side-and-bottom-padding {
          padding-right: 24px;
          padding-bottom: 32px;
          padding-left: 24px;
        }
      }
    }
  }
}

// --------------------------------------------
// Theme Colors
// --------------------------------------------
.light {
  .dialog {
    background-color: var(--c-light-150);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  }
}

.dark {
  .dialog {
    background-color: var(--c-dark-150);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  }
}
</style>
