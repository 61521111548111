<template>
  <footer
    v-show="showFooter"
    :class="[{ 'single-menu': menu.length === 1 }, componentName, $store.theme]"
    :data-ctype="$options.name"
    :data-cname="componentName"
  >
    <div class="responsive-container">
      <div class="row">
        <div
          class="logo-and-feedback col col-desktop-3 col-tablet-8 col-mobile-4"
          :class="menu.length > 1 ? 'col-desktop-s-4' : 'col-desktop-s-4'"
        >
          <router-link to="/" class="flex logo-container mb-32">
            <img :src="logoUrl" alt="logo" />
          </router-link>

          <div v-if="supportURL || feedbackLink" class="feedback-link mb-32">
            <div class="body2 title mb-8" v-html="getTranslation('have_questions')" />
            <ButtonDefault
              class="headline2"
              view="tertiary"
              :href="supportURL || feedbackLink"
              :target="linkTarget(supportURL || feedbackLink)"
            >
              <IconSVG :svg="IconAsk" />
              <span v-html="getTranslation('feedback')" />
            </ButtonDefault>
          </div>
        </div>

        <div
          v-for="(column, i) in menu"
          :key="'menu' + i"
          class="menu desktop-menu col col-desktop-2 col-desktop-s-4 col-tablet-4 mb-32"
        >
          <div class="uppercase-text title mb-24" v-html="column.title" />
          <ul v-if="menu && menu.length" class="links">
            <li v-for="item in column.items" :key="'menu' + item.link">
              <LinkInternalOrExternal class="link nav-colored" :link="item.link" @moveUp="moveUp">
                <span v-html="item.translation" />
              </LinkInternalOrExternal>
            </li>
          </ul>
        </div>

        <div class="menu mobile-menu">
          <AccordionItem
            v-for="(column, i) in menu"
            :key="`mobileMenu${i}`"
            class="col-mobile-4"
            :item="column"
            :itemIndex="i"
            :title="column.title"
          >
            <ul v-if="menu && menu.length" class="links">
              <li v-for="item in column.items" :key="`mobileMenu${item.translationId}`">
                <LinkInternalOrExternal class="link nav-colored" :link="item.link" @moveUp="moveUp">
                  <span v-html="item.translation" />
                </LinkInternalOrExternal>
              </li>
            </ul>
          </AccordionItem>
        </div>

        <div
          class="col col-mobile-4 apps-and-socials"
          :class="
            menu.length > 1
              ? 'col-desktop-5 col-desktop-s-12 col-tablet-8'
              : 'col-desktop-7 col-desktop-s-4 col-tablet-4'
          "
        >
          <div
            v-if="storeButtons.length"
            class="apps mb-32"
            :class="menu.length > 1 ? 'col-desktop-s-8' : 'col-desktop-s-12'"
          >
            <div class="uppercase-text title mb-24" v-html="getTranslation('apps')" />
            <a
              v-for="(button, i) in storeButtons"
              :key="'button' + i"
              target="_blank"
              :href="button.link"
            >
              <LazyImage :src="getThemedImg(button)" :width="132" :height="40" />
            </a>
          </div>

          <div
            v-if="socials.length"
            class="socials mb-32"
            :class="menu.length > 1 ? 'col-desktop-s-4' : ''"
          >
            <div class="uppercase-text title mb-24" v-html="getTranslation('we_in_socials')" />
            <a
              v-for="(item, i) in socials"
              :key="'social' + i"
              class="button button-action"
              target="_blank"
              :href="item.link"
            >
              <IconSVG :svg-path="darkIconsFallback(item)" />
            </a>
          </div>
        </div>
      </div>

      <div class="bottom row">
        <div v-if="copyright" class="col col-desktop-7 col-tablet-4 col-mobile-4">
          <div class="title body2" v-html="copyright" />
        </div>
        <div v-if="supportEmail" class="col col-desktop-5 col-tablet-4 col-mobile-4">
          <div class="title body2 feedback">
            <p>&nbsp;</p>
            <p>{{ getTranslation('support_email') }}: {{ supportEmail }}</p>
          </div>
        </div>
        <div v-else-if="feedback" class="col col-desktop-5 col-tablet-4 col-mobile-4">
          <div class="title body2 feedback" v-html="feedback" />
        </div>
      </div>
      <FooterTechnicalInfo />
    </div>
  </footer>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import AccordionItem from 'src/components/ui/AccordionItem.vue';
import IconSVG from 'src/components/IconSVG.vue';
import { selectors } from 'src/store/selectors';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';
import LinkInternalOrExternal from 'src/components/ui/LinkInternalOrExternal.vue';
import LazyImage from 'src/components/LazyImage.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconAsk from 'src/svg/ask.svg';
import { actions } from 'src/store/actions';
import FooterTechnicalInfo from 'src/components/FooterTechnicalInfo.vue';

interface TItem {
  link: Array<string>;
  imageLight: TTranslations<TImageCMS>;
  imageDark: TTranslations<TImageCMS>;
  imageId: TTranslations<TImageCMS>;
}

@Component({
  components: {
    FooterTechnicalInfo,
    ButtonDefault,
    LazyImage,
    AccordionItem,
    IconSVG,
    LinkInternalOrExternal,
  },
})
export default class FooterComponent extends mixins(Global, CmsComponent) {
  IconAsk = IconAsk;
  content?: Partial<{
    logoLight: TTranslations<TImageCMS>;
    logoDark: TTranslations<TImageCMS>;
    imageLight: TTranslations<TImageCMS>;
    imageDark: TTranslations<TImageCMS>;
    feedbackLink: string;
    menu: Array<{
      title: TTranslations;
      items: Array<{ link: string; translation: TTranslations }>;
    }>;
    storeButtons: Array<TItem>;
    social: Array<TItem>;
    copyright: TTranslations;
    feedback: TTranslations;
  }> | null = null;

  get logoUrl() {
    if (!this.content) {
      return '';
    }
    const image = this.content[this.$store.theme === 'light' ? 'logoLight' : 'logoDark'];
    return this.getByLanguage(image)?.url || '';
  }

  get socials() {
    return this.content?.social?.filter((it) => this.darkIconsFallback(it)) || [];
  }

  get menu() {
    if (!this.content?.menu) {
      return [];
    }
    return this.content.menu
      .filter((column) => column?.items?.length)
      .map((column) => ({
        ...column,
        title: column.title ? this.getByLanguage(column.title) : '',
        items: column.items.map((item) => ({
          ...item,
          translation: item.translation ? this.getByLanguage(item.translation) : '',
        })),
      }));
  }

  get feedbackLink() {
    return this.content?.feedbackLink || '';
  }

  get showFooter() {
    return selectors.common.showFooterSelector(this.$store);
  }

  get copyright() {
    if (this.content?.copyright) {
      return this.getByLanguage(this.content.copyright);
    }
    return '';
  }

  get feedback() {
    if (this.content?.feedback) {
      return this.getByLanguage(this.content.feedback);
    }
    return '';
  }

  get storeButtons() {
    return this.content?.storeButtons || [];
  }

  get supportEmail() {
    return (
      selectors.providerInfo.supportEmailV2Selector(this.$store) ||
      selectors.providerInfo.supportEmailSelector(this.$store)
    );
  }

  get supportURL() {
    return (
      selectors.providerInfo.supportURLV2Selector(this.$store) ||
      selectors.providerInfo.supportURLSelector(this.$store)
    );
  }

  async serverPrefetch() {
    await actions.providerInfo.loadProviderInfo(this.$store);
  }

  async mounted() {
    await actions.providerInfo.loadProviderInfo(this.$store);
  }

  getThemedImg(content?: TItem) {
    if (!content) {
      return '';
    }
    const image = content[this.$store.theme === 'light' ? 'imageLight' : 'imageDark'];
    return this.getByLanguage(image)?.url || '';
  }

  linkTarget(link: string) {
    return link[0] === '/' ? '_self' : '_blank';
  }

  moveUp() {
    const scrollWrap = document.querySelector('.scroll-layout-wrap');

    if (scrollWrap) {
      scrollWrap.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }

  darkIconsFallback(item: TItem) {
    let image = this.$store.theme === 'light' ? item.imageLight : item.imageDark;
    if (item.imageId) {
      image = item.imageId;
    }
    return this.getByLanguage(image)?.url || '';
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

// stylelint-disable selector-no-qualifying-type
footer {
  position: sticky;
  top: 100vh;
  margin-top: auto;
  background-color: var(--c-light-200);

  &.single-menu {
    @include desktop-s {
      .responsive-container {
        .apps-and-socials {
          margin-top: 0;
        }
      }
    }
  }

  .responsive-container {
    padding: 48px 64px 32px;
    color: var(--c-light-font-primary);

    @include tablet {
      padding: 32px 48px;
    }

    @include mobile {
      padding: 32px 24px;
    }

    .bottom {
      justify-content: space-between;
      margin: 0;

      @include mobile-and-tablet {
        .col-desktop-7,
        .col-desktop-5 {
          margin-left: 0;
        }
      }
    }

    .logo-container {
      display: inline-block;
      width: 100%;
      max-width: 200px;

      &::v-deep img {
        max-height: 48px;
      }
    }

    .title {
      color: var(--c-light-font-tertiary);
    }

    .desktop-menu {
      display: block;

      @include mobile {
        display: none;
      }
    }

    .mobile-menu {
      display: none;
      width: 100%;

      @include mobile {
        display: block;
      }

      .links {
        padding: 4px 0 20px 32px;
      }
    }

    .menu {
      .links {
        li {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .link {
          color: var(--c-light-font-primary);
          @include body2;

          @include devices-with-hover {
            &:hover {
              border-bottom-color: var(--c-light-font-primary);
            }
          }
        }
      }
    }

    .feedback {
      text-align: right;

      @include mobile {
        text-align: left;
      }
    }

    .bottom {
      border-top: 1px solid var(--alpha-light-3);
    }

    .apps-and-socials {
      @media (min-width: #{$desktop-m-min}) {
        margin-bottom: 32px;
      }

      @include desktop-s {
        display: flex;
        justify-content: space-between;
      }

      @include mobile {
        margin-top: 32px;
      }

      .apps {
        margin-left: 0;

        a {
          width: 100%;
          max-width: 132px;

          @include devices-with-hover {
            &:hover {
              opacity: 0.8;
            }
          }

          &:active {
            opacity: 0.7;
          }
        }

        &::v-deep img {
          min-height: 40px;
        }
      }

      .socials {
        @include mobile-and-tablet {
          margin-left: 0;
        }
      }

      a {
        display: inline-block;
        min-width: 40px;
        min-height: 40px;
        margin-right: 8px;
        margin-bottom: 8px;

        @include mobile-and-tablet {
          margin-bottom: 0;
        }
      }
    }
  }

  // DARK THEME
  &.dark {
    color: var(--c-dark-font-primary);
    background-color: var(--c-dark-200);

    .responsive-container {
      .title {
        color: var(--c-dark-font-tertiary);
      }

      .menu .links .link {
        color: var(--c-dark-font-primary);

        @include devices-with-hover {
          &:hover {
            border-bottom-color: var(--c-dark-font-primary);
          }
        }
      }

      .bottom {
        border-top-color: var(--alpha-dark-3);
      }
    }
  }
}
</style>
