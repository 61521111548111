<template>
  <div
    class="media-list"
    data-cy="media-list"
    :class="[{ open: open }, { playing: isPlaying }, theme]"
  >
    <div class="media-container">
      <div class="media-block">
        <div class="click-episode-area" @click="$emit('clickEpisode', episodeId)" />
        <IconSVG :svg="IconPlay" class="icon-play" />
        <div class="media-info">
          <div class="title body1" v-text="title" />
          <ProgressBar
            v-if="progress > 0 && !isPlaying && !open"
            :percents="progress"
            :alpha-level="5"
          />
        </div>

        <ButtonDropdown
          size="small"
          :width="windowWidth < 600 ? '48px' : '150px'"
          :value="getTranslation('all_airs')"
          :theme-forced="theme"
          :open="open"
          :collapse="true"
          @toggle="toggle"
        />
      </div>
    </div>

    <div v-if="open" class="list-wrap">
      <MediaItem
        v-for="item in list"
        :id="item.id"
        :key="item.title"
        :theme-forced="theme"
        :title="item.title"
        :channel-title="item.channelName"
        :img="item.img"
        :is-playing="item.isPlaying"
        :progress="item.progress"
        @click="$emit('clickMediaItem', episodeId, item.id)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import BadgeSource from 'src/components/ui/BadgeSource.vue';
import MediaItem from 'src/components/ui/media/MediaItem.vue';
import Global from 'src/mixins/Global';
import AddResizeListener from 'src/mixins/AddResizeListener';

@Component({
  components: {
    MediaItem,
    IconSVG,
    ButtonDropdown,
    ProgressBar,
    BadgeSource,
  },
})
export default class MediaList extends mixins(Global, AddResizeListener) {
  IconPlay = IconPlay;

  open = false;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  episodeId!: string;

  @Prop({ required: true })
  title!: string;

  @Prop()
  isPlaying!: boolean;

  @Prop()
  progress!: number;

  @Prop()
  list!: Array<{
    id: string;
    title: string;
    channelName: string;
    img: string;
    isPlaying: boolean;
    progress: number;
  }>;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  toggle(val: boolean) {
    this.open = val;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.media-list {
  position: relative;

  &.open {
    .media-container {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      .icon-play {
        color: var(--alpha-light-6);
      }
    }
  }

  &.open,
  &.playing {
    .click-episode-area {
      pointer-events: none;
    }

    .media-container .icon-play {
      cursor: auto;
    }
  }

  &.playing {
    background-color: var(--c-overlay-brand-fixed-1);

    .media-container {
      cursor: auto;

      .icon-play {
        cursor: auto;
      }

      .title,
      .icon {
        color: var(--c-light-brand-darker);
      }
    }
  }

  &:not(.playing):not(.open) {
    .media-container {
      background-color: var(--alpha-light-3);

      @include devices-with-hover {
        .media-block:hover {
          cursor: pointer;
          background-color: var(--alpha-light-4);
        }
      }

      .media-block:active {
        background-color: var(--alpha-light-5);
      }
    }
  }

  .list-wrap {
    display: grid;
    padding: 0 12px 0 44px;
    background-color: var(--alpha-light-2);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    @include mobile-and-tablet {
      grid-template-columns: 1fr;
    }

    @include mobile {
      padding-right: 8px;
      grid-column-gap: 0;
      grid-row-gap: 0;
    }

    .media-item {
      margin-bottom: 12px;

      @include mobile {
        margin-bottom: 8px;
      }
    }
  }

  .media-container {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--alpha-light-2);
    border-radius: 12px;

    .media-block {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      width: 100%;
      height: 64px;
      padding: 12px;
      border-radius: 12px;

      @include mobile {
        padding: 12px 8px;
      }

      .click-episode-area {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: var(--z-1);
      }

      .icon {
        flex-shrink: 0;
        margin-right: 12px;
      }

      .media-info {
        position: relative;
        z-index: var(--z-0);
        flex: 1 1 250px;
        margin-right: 16px;
        overflow: hidden;

        @include mobile {
          flex: 1;
        }

        .title {
          @extend %truncate-after-1-line;
        }
      }
    }

    .progress-bar {
      max-width: 156px;
    }

    .icon-play {
      position: relative;
      z-index: var(--z-0);
    }

    .expand {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 8px 8px 8px 16px;
      background-color: var(--alpha-light-3);
      border-radius: 8px;
    }

    .button-dropdown {
      position: relative;
      z-index: var(--z-2);
    }
  }

  &.dark {
    &.playing,
    &:not(.playing):not(.open) .media-container .media-block {
      background-color: var(--alpha-dark-3);

      @include devices-with-hover {
        &:hover {
          background-color: var(--alpha-dark-4);
        }
      }

      &:active {
        background-color: var(--alpha-dark-5);
      }
    }

    .expand {
      color: var(--c-dark-font-primary);
      background-color: var(--alpha-dark-3);
    }
  }
}
</style>
