<template>
  <div
    class="button-dropdown"
    :class="[theme, size, { collapse, open }]"
    :data-cy="dataCy ? `button-dropdown-${dataCy}` : ''"
    :style="{ width: width === 'auto' || width === 'content' ? '100%' : 'fit-content' }"
  >
    <div
      class="button-dropdown-inner"
      :class="[{ open, disabled, 'with-icon': icon }, width]"
      :style="buttonDropdownWidth"
    >
      <div class="clickable-overlay" @click="clickButton" />

      <IconSVG v-if="icon" :svg="icon" :size="20" class="input-icon" />

      <InputText
        ref="input"
        v-model="inputValue"
        required
        autocomplete="off"
        :input-category="floating ? 'floating' : 'default'"
        :size="size"
        :placeholder="placeholder"
        :label="placeholder"
        :readonly="true"
        :disabled="disabled"
        :maxlength="placeholder ? placeholder.length : undefined"
        :label-focus="false"
        :theme-forced="themeForced"
      />
      <button type="button" class="toggle" :disabled="disabled">
        <IconSVG :svg="IconTriangleDown" :direction="open ? 'up' : 'down'" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch, Ref } from 'vue-property-decorator';
import IconCross from 'src/svg/cross.svg';
import IconTriangleDown from 'src/svg/triangle-down.svg';
import IconSVG from 'src/components/IconSVG.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import debounce from 'lodash/debounce';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';

@Component({
  components: {
    ButtonAction,
    InputText,
    IconSVG,
  },
})
export default class ButtonDropdown extends Vue {
  IconTriangleDown = IconTriangleDown;
  IconCross = IconCross;

  windowWidth?: number;
  onResizeWithDebounce = debounce(this.onResize, 200);

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  placeholder!: string;

  @Prop({ default: false })
  open!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  floating!: boolean;

  @Prop({ default: 'medium' })
  size!: 'small' | 'medium' | 'large';

  @Prop({ default: 'content' })
  width!: string; // content | width | auto | Npx

  @Prop()
  value!: Array<TDropdownItem> | string;

  @Prop()
  icon!: Record<string, unknown>;

  @Prop({ default: false })
  collapse!: boolean;

  @Prop()
  dataCy?: string;

  @Ref('input')
  readonly refInput!: Vue;

  @Watch('open')
  onOpenChange(open: boolean) {
    this.$emit(open ? 'open' : 'close');
  }

  @Watch('floating')
  onFloatingChange() {
    this.initButtonDropdownWidth();
  }

  @Watch('inputValue')
  onInputValueChange() {
    this.initButtonDropdownWidth();
  }

  @Watch('width')
  onButtonDropdownWidthChange() {
    this.initButtonDropdownWidth();
  }

  @Watch('windowWidth')
  onWindowWidthChange() {
    this.initButtonDropdownWidth();
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get widthParsed() {
    return parseInt(this.width, 10);
  }

  get inputValue() {
    if (Array.isArray(this.value)) {
      return (
        this.value
          .filter((item) => item.selected)
          .map((item) => item.value)
          .join(', ') || ''
      );
    } else return this.value;
  }

  buttonDropdownWidth = { width: this.width };

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResizeWithDebounce);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeWithDebounce);
  }

  onResize() {
    this.windowWidth = document.documentElement.clientWidth;
    this.initButtonDropdownWidth();
  }

  initButtonDropdownWidth() {
    const inputValueLength = this.inputValue?.length;
    const placeholderLength = this.placeholder?.length;
    const multiplier = inputValueLength >= placeholderLength - 8 || !this.floating ? 9 : 5;
    const windowWidthWithoutPaddings = this.windowWidth
      ? this.windowWidth < 600
        ? this.windowWidth - 48
        : this.windowWidth - 96
      : 0;

    switch (this.width) {
      case 'content':
        const val =
          inputValueLength > placeholderLength || (!this.floating && inputValueLength)
            ? inputValueLength
            : placeholderLength - 3;

        let inputWidth = val * multiplier + 70;

        if (!inputValueLength) {
          inputWidth += 32;
        }

        if (windowWidthWithoutPaddings > inputWidth) {
          this.buttonDropdownWidth.width = inputWidth + (this.icon ? 48 : 0) + 'px';
        } else {
          this.buttonDropdownWidth.width = '100%';
        }
        break;

      case 'auto':
        this.buttonDropdownWidth.width = '100%';
        break;

      default:
        this.buttonDropdownWidth.width =
          windowWidthWithoutPaddings <= this.widthParsed ? '100%' : this.width;
        break;
    }
  }

  clickButton() {
    this.$emit('click');
    if (this.disabled) {
      this.$emit('toggle', false);
      return;
    }
    const input = this.refInput.$el.querySelector('.input') as HTMLInputElement;
    input.blur();
    this.$emit('toggle', !this.open);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.button-dropdown {
  &.open {
    @include mobile {
      .toggle {
        background-color: var(--alpha-light-5);
      }
    }
  }

  &.collapse {
    @include mobile {
      .input-block {
        display: none;
      }

      .button-dropdown-inner {
        width: fit-content;
        min-width: 48px;

        .toggle {
          position: relative;
        }
      }
    }
  }

  &.small .button-dropdown-inner .toggle {
    width: 40px;
    height: 40px;
  }

  &.medium .button-dropdown-inner .toggle {
    width: 48px;
    height: 48px;
  }

  &.large .button-dropdown-inner .toggle {
    width: 56px;
    height: 56px;
  }

  @include mobile-and-tablet {
    &.small .button-dropdown-inner .toggle {
      width: 48px;
      height: 48px;
    }
  }

  .clickable-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-2);
    cursor: pointer;
  }

  .button-dropdown-inner {
    position: relative;
    min-width: 128px;

    &.content {
      max-width: 240px;

      @include mobile {
        // TODO remove !important after refactoring JS logic for this case
        width: 100% !important;
        max-width: 100%;
      }
    }

    &::v-deep .input-block {
      cursor: pointer;

      @include mobile-and-tablet {
        &.small .input.small {
          height: 48px;
        }
      }

      .input {
        padding: 8px 40px 8px 16px;
        pointer-events: none;
        cursor: pointer;
        background-color: var(--alpha-light-3);
        border-width: 0;
        transition: all var(--ease-out) 0.15s;
        @extend %truncate-after-1-line;

        &:focus {
          background-color: var(--alpha-light-5);
        }

        &:disabled {
          background-color: var(--alpha-light-2);

          @include devices-with-hover {
            &:hover {
              background-color: var(--alpha-light-2);
            }
          }
        }

        &::placeholder {
          text-transform: capitalize;
        }
      }

      &.floating {
        .input {
          padding-top: 20px;
          padding-bottom: 4px;
        }
      }
    }

    &.open {
      z-index: var(--z-dropdown);
      border-radius: 2px;

      &::v-deep .input-block .input {
        z-index: var(--z-dropdown);
        border: 0;

        &::placeholder {
          text-overflow: ellipsis;
        }
      }
    }

    .on-mobile {
      display: none;
    }

    .toggle {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }

    &.with-icon {
      background-color: var(--alpha-dark-3);
      border-radius: 8px;

      .input-icon {
        position: absolute;
        top: 50%;
        left: 16px;
        overflow: hidden;
        transform: translateY(-50%);
      }

      &::v-deep {
        .input-block {
          &.floating {
            .label {
              margin-left: 48px;
            }
          }

          .input {
            padding-left: 48px;
          }
        }
      }
    }
  }

  // -----------------------------------------------
  // Theme Colors
  // -----------------------------------------------
  &.light {
    .toggle {
      .icon {
        color: var(--c-light-contrast);
      }

      &:disabled .icon {
        color: var(--alpha-light-6);
        cursor: auto;
      }
    }

    .button-dropdown-inner {
      &.disabled .icon {
        color: var(--alpha-light-6);
      }

      @include devices-with-hover {
        &:hover {
          &::v-deep .input-block .input {
            background-color: var(--alpha-light-4);
          }
        }
      }

      &:focus,
      &:active {
        &::v-deep .input-block .input {
          background-color: var(--alpha-light-5);
        }
      }

      &.open {
        &::v-deep .input-block .input {
          background-color: var(--alpha-light-5);
        }
      }
    }
  }

  &.dark {
    .toggle {
      .icon {
        color: var(--c-dark-contrast);
      }

      &:disabled .icon {
        color: var(--alpha-dark-6);
        cursor: auto;
      }
    }

    .button-dropdown-inner {
      &.disabled .icon {
        color: var(--alpha-dark-6);
      }

      @include devices-with-hover {
        &:hover {
          &::v-deep .input-block .input {
            background-color: var(--alpha-dark-4);
          }
        }
      }

      &:focus,
      &:active {
        &::v-deep .input-block .input {
          background-color: var(--alpha-dark-5);
        }
      }

      &.open {
        &::v-deep .input-block .input {
          background-color: var(--alpha-dark-5);
        }
      }
    }

    &::v-deep .input-block {
      .input {
        background-color: var(--alpha-dark-3);

        &:focus {
          background-color: var(--alpha-dark-5);
        }

        &:disabled {
          background-color: var(--alpha-dark-2);
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .dropdown-container {
    .button-dropdown-inner {
      &.open {
        .toggle {
          right: 32px;
        }
      }

      .toggle {
        display: none;

        &.on-mobile {
          display: block;
        }
      }
    }
  }
}
</style>
