import { URLS } from 'src/constants';
import { fe, TReqConfig } from '../utils';
import { convertToMilliseconds } from 'src/utils/time/convert-time';
import { DateTime } from 'src/utils/time/date-time';
import { getLogger } from 'lightlog';
import logger from 'src/utils/logger';
import { TGenre, TMedia, TTvChannelsList } from 'src/store/tv-channels/types';
import {
  TChannelEnhanced,
  TChannelsEmptyResponse,
  TProgramEnhanced,
  TReqConfigForChannels,
} from './types';
import { DVRAvailability, LivePlaybackInfo } from 'src/models/ts/playback';
import { Channel, Program } from 'src/models/ts/epg';
import { ChannelRecommendation } from 'src/models/ts/walls/v1/weightprovider';
import { ProfileRestriction } from 'src/models/ts/accounts';
import { ChannelsNotForPurchaseIdList } from 'src/models/ts/offer';
import sortBy from 'lodash/sortBy';
import { getChannelNumber } from 'src/utils/channel';
import { TFeCookie } from 'src/types';

const log =
  process.env.VUE_ENV === 'server'
    ? getLogger('api-channels')
    : {
        error: logger('api-channels').error,
        debug: logger('api-channels').info,
      };

export const addToFavorites = async (
  channelId: string,
  config?: TReqConfig
): Promise<TChannelsEmptyResponse> => fe.post(`${URLS.favorites}/${channelId}`, config);

export const removeFromFavorites = async (
  channelId: string,
  config: TReqConfig = {}
): Promise<TChannelsEmptyResponse> => fe.delete(`${URLS.favorites}/${channelId}`, config);

export const getChannels = async (useTvApiV2: boolean, config: TReqConfigForChannels = {}) => {
  const { tvAssetToken, ...reqConfig } = config || {};
  const shouldUseTvApiV2 = !!tvAssetToken && useTvApiV2;

  // v2
  if (shouldUseTvApiV2) {
    log.debug('Loading TV API 2.0');
    const channelsV2Response = await fe
      .get(URLS.channelsV2(tvAssetToken), reqConfig)
      .catch((err) => {
        log.error(err);
        log.debug('TV API 2.0 was not loaded. Switching to TV API 1.0...');
      });

    if (channelsV2Response) {
      const { genres, channels } = channelsV2Response;
      const medias: TMedia[] =
        (
          await fe.get(URLS.medias(tvAssetToken), reqConfig).catch((err) => {
            log.error(err);
            return { medias: [] };
          })
        )?.medias || [];

      const channelsEnhanced: Record<string, TChannelEnhanced> = {};
      medias.forEach((media: TMedia) => {
        const channel = channels.find((one: TChannelEnhanced) => one.id === media.channelId);
        if (channel) {
          channelsEnhanced[channel.id] = channelsEnhanced[channel.id] || channel;
          channelsEnhanced[channel.id].medias = channelsEnhanced[channel.id].medias || [];
          channelsEnhanced[channel.id].medias.push(media);
        }
      });

      log.debug('TV API 2.0 was successfully loaded!');

      return {
        channels: prepareChannels(Object.values(channelsEnhanced)),
        genres,
      };
    }
  }

  // v1
  log.debug('Loading TV API 1.0');
  const { channels } = await fe.get(URLS.channels, reqConfig).catch((err) => {
    log.error(err);
    return { channels: [] };
  });

  const sessionSuffix = config.params?.session ? `?session=${config.params.session}` : '';
  const { genres } = await fe.get(`${URLS.genres}${sessionSuffix}`).catch((e) => {
    log.error(e);
    return { genres: [] };
  });

  log.debug('TV API 1.0 was successfully loaded!');

  return {
    channels: prepareChannels(channels || []),
    genres: genres.map((genre: string) => ({ id: '', title: genre })),
  };
};

export const getPlaybackInfo = async (
  channelId: string | null,
  enable50fps = false,
  config: TReqConfig = {}
): Promise<LivePlaybackInfo> =>
  fe.get(`${URLS.playbackInfo}/${channelId}?enable50fps=${enable50fps}`, config);

export const getDvrAvailability = async (
  channelId: string,
  config: TReqConfig = {}
): Promise<DVRAvailability> => fe.get(URLS.dvrAvailability(channelId), config);

export const getEpg = async (
  channelId: string,
  period: string,
  config: TReqConfig = {}
): Promise<Channel> => {
  const result = await fe.get(URLS.epg(channelId, period), config);
  if (result?.programs) {
    result.programs = result.programs.map((program: Program) => prepareProgram(program));
  }
  return result;
};

const prepareChannels = (channels: TChannelEnhanced[]) =>
  channels
    .map((channel) => {
      const isV2 = !!channel?.keyNumber;
      const title = channel?.info?.metaInfo?.title || channel?.title || '';
      const numberLength = 3;
      let channelPrepared = isV2
        ? { ...channel }
        : {
            // TODO delete after all WL are migrated to channels v2
            ...channel,
            title,
            name: isV2 ? title : title.slice(numberLength + 1),
            strNumber: channel?.keyNumber || title.slice(0, numberLength),
          };

      if (channel.info) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { socialInfo, updateInfo, ...channelInfo } = channel.info;
        channelPrepared.info = channelInfo;
      }

      if (channelPrepared?.programs) {
        channelPrepared = {
          ...channelPrepared,
          programs: (channelPrepared.programs as Program[]).map((program) =>
            prepareProgram(program)
          ),
        };
      }

      return channelPrepared;
    })
    .sort((a, b) => parseInt(getChannelNumber(a)) - parseInt(getChannelNumber(b)))
    .reduce<TTvChannelsList>((acc, channel) => {
      acc[channel.id] = channel;
      return acc;
    }, {});

const prepareProgram = (program: Program): TProgramEnhanced => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { metaInfo, ...programRest } = program;
  const startMs = convertToMilliseconds(program.scheduleInfo?.start, 'second');
  const endMs = convertToMilliseconds(program.scheduleInfo?.end, 'second');
  return {
    ...programRest,
    title: program.metaInfo?.title || '',
    age: program?.metaInfo?.age_rating || '',
    description: program.metaInfo?.description || '',
    image: program?.mediaInfo?.thumbnails?.[0]?.url,
    startMs,
    endMs,
    startTimeHM: startMs ? DateTime.getHM(new Date(startMs)) : '     ',
    endTimeHM: endMs ? DateTime.getHM(new Date(endMs)) : '     ',
  };
};

export const getRecentAndFavChannels = async (sessionSuffix = '', config: TReqConfig = {}) => {
  const { favouriteChannels, recentlyWatchedChannels } = await fe
    .get(`${URLS.recentAndFavChannels}${sessionSuffix}`, config)
    .catch((err) => {
      log.error('getRecentAndFavChannels', err);
      return { favouriteChannels: [], recentlyWatchedChannels: [] };
    });

  return {
    favouriteChannels:
      (favouriteChannels as TChannelEnhanced[])
        ?.reverse()
        ?.reduce<TTvChannelsList>((acc, channel) => {
          acc[channel.id] = channel;
          return acc;
        }, {}) || {},
    recentlyWatchedChannels:
      (recentlyWatchedChannels as TChannelEnhanced[])?.reduce<TTvChannelsList>((acc, channel) => {
        acc[channel.id] = channel;
        return acc;
      }, {}) || {},
  };
};

export const getRecommendedChannels = (sessionSuffix = '', config: TReqConfig = {}) =>
  fe
    .get(`${URLS.recommendedChannels}${sessionSuffix}`, config)
    .then((response) => {
      if (!response.channelRecommendations) {
        return [];
      }

      const channelsSorted = sortBy(
        (response?.channelRecommendations || []).map((channel: ChannelRecommendation) => {
          return { id: channel.channelId, priority: channel.priority };
        }),
        'priority'
      );

      return channelsSorted.reduce<TTvChannelsList>((acc, channel) => {
        acc[channel.id] = channel;
        return acc;
      }, {});
    })
    .catch((e) => {
      log.error(`Recommended channels were not loaded`, e);
      return {};
    });

export const getProfileRestrictions = (sessionSuffix = ''): Promise<ProfileRestriction[]> =>
  fe
    .get(`${URLS.profileRestrictions}${sessionSuffix}`)
    .then((response) => response?.restrictions || [])
    .catch((e) => {
      log.error(e);
      return [];
    });

export const getChannelsNotForPurchase = (
  feCookie: TFeCookie = null
): Promise<ChannelsNotForPurchaseIdList> =>
  fe
    .get(`${URLS.channelsNotForPurchase}`, {
      params: {
        session: feCookie?.session,
        timeout: 5000,
      },
    })
    .catch(() => {
      // do nothing
    });

export const getTvData = async (useTvApiV2: boolean, session = '', tvAssetToken?: string) => {
  const part1StartTime = Date.now();
  const sessionSuffix = session ? `?session=${session}` : '';

  const getChannelsResponse = await getChannels(useTvApiV2, { params: { session }, tvAssetToken });
  const channelsResponse = getChannelsResponse?.channels || {};
  const genresResponse: Array<TGenre> = getChannelsResponse?.genres;
  const recommendedChannelsResponse = await getRecommendedChannels(sessionSuffix);

  log.debug(`tvData [1]: ${Date.now() - part1StartTime}ms`);

  return {
    genresResponse,
    channelsResponse,
    recommendedChannelsResponse,
  };
};
