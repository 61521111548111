<template>
  <div class="program-info-and-buttons" :class="theme">
    <div v-if="liveProgramStartTimeHM && liveProgramEndTimeHM" class="program-air-time">
      <BadgeSequoia :theme-forced="theme" type="air" :text="getTranslation('live_air')">
        <IconSVG :svg="IconAir" />
      </BadgeSequoia>
      <div class="time" v-text="liveProgramTime" />
    </div>

    <h5 v-html="liveProgramTitle" />

    <ProgressBar
      v-if="liveProgramTimelinePosition > 0"
      :theme-forced="theme"
      :percents="liveProgramTimelinePosition"
      :alpha-level="6"
    />

    <div
      class="buttons"
      :class="[
        { appear: showButtons },
        { disappear: !showButtons },
        { 'enable-animation': animateButtons },
      ]"
    >
      <ButtonDefault
        type="button"
        :theme-forced="theme"
        data-cy="channel-live"
        @click="selectChannel"
      >
        <IconSVG :svg="IconAir" />
        <span v-html="getTranslation('live_air')" />
      </ButtonDefault>

      <ButtonDefault
        v-if="!isDvrDisabled"
        view="tertiary"
        type="button"
        :theme-forced="theme"
        data-cy="channel-start-over"
        @click="startOver"
      >
        <IconSVG :svg="IconMediaResume" />
        <span v-html="getTranslation('watch_from_the_beginning')" />
      </ButtonDefault>
      <ButtonBookmark
        v-if="!isAnonymous"
        :theme-forced="theme"
        :active="isChannelInFavorites"
        :has-indicator="false"
        @click.native="toggleChannelFavorite"
        @mouseleave.native="$event.target.blur()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import * as api from 'src/api';
import { TV_SPECIAL_GENRES } from 'src/constants';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { TChannelEnhanced } from 'src/api/channels/types';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconAir from 'src/svg/genres/air.svg';
import IconMediaResume from 'src/svg/player/media-resume.svg';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import ProgressBar from 'src/components/ui/ProgressBar.vue';
import ButtonBookmark from 'src/components/ui/buttons/ButtonBookmark.vue';

@Component({
  components: {
    ProgressBar,
    BadgeSequoia,
    ButtonDefault,
    IconSVG,
    ButtonBookmark,
  },
})
export default class ChannelDetailsProgramInfoAndButtons extends SequoiaComponent {
  IconAir = IconAir;
  IconMediaResume = IconMediaResume;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  channel!: TChannelEnhanced;

  @Prop()
  showButtons!: boolean;

  @Prop()
  animateButtons!: boolean;

  @Prop({ default: true })
  redirectToPlayer!: boolean;

  @Prop({ required: true })
  isDvrDisabled!: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get isPageChannelOpen() {
    return selectors.tvChannels.isPageChannelOpenSelector(this.$store);
  }

  get programs() {
    return selectors.tvEpg.programsSelector(this.$store, this.channel.id);
  }

  get liveProgram() {
    return selectors.tvEpg.programForChannelSelector({
      store: this.$store,
      channelId: this.channel.id,
      isLive: true,
    });
  }

  get liveProgramTitle() {
    return this.liveProgram?.title || '';
  }

  get liveProgramStartTimeHM() {
    return this.liveProgram?.startTimeHM;
  }

  get liveProgramEndTimeHM() {
    return this.liveProgram?.endTimeHM;
  }

  get liveProgramTime() {
    return `${this.liveProgramStartTimeHM} – ${this.liveProgramEndTimeHM}`;
  }

  get liveProgramTimelinePosition() {
    return selectors.tvEpg.programTimelinePositionForChannelSelector(this.$store, this.channel.id);
  }

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  get isChannelInFavorites() {
    return !!this.favoriteChannels[this.channel?.id];
  }

  get currentGenre() {
    return selectors.tvChannels.currentGenreSelector(this.$store);
  }

  get shouldAddToRecentlyWatched() {
    return this.currentGenre !== TV_SPECIAL_GENRES.recentlyWatched;
  }

  async toggleChannelFavorite() {
    if (this.channel) {
      actions.metrics.setGaParams(this.$store, {
        category: 'player_controls',
        control_type: 'mouse',
      });
      await actions.tvChannels.toggleChannelFavorite(this.$store, this.channel.id);
    }

    const { favouriteChannels } = await api.channels.getRecentAndFavChannels();
    Vue.set(this.$store.tvChannels, 'favorites', favouriteChannels || []);
  }

  async goToCatalogPage() {
    await this.$router.push(`/channels/now/${this.channel.id}/watch`);
  }

  async selectChannel() {
    if (this.isAnonymous) {
      actions.common.showNotificationAuthAndReg(this.$store);
      return;
    }

    actions.tvChannels.hideChannelDetails(this.$store);

    if (this.isPageChannelOpen || this.redirectToPlayer) {
      await this.goToCatalogPage();
    } else {
      await actions.tvChannels.selectChannel(
        this.$store,
        this.channel.id,
        this.$events,
        this.shouldAddToRecentlyWatched
      );
    }

    this.gaEvent({
      category: 'channels_now',
      action: 'Клик по кнопке "Прямой эфир"',
      channel_name: this.channel.id,
    });
  }

  async startOver() {
    if (this.isAnonymous) {
      actions.common.showNotificationAuthAndReg(this.$store);
      return;
    }

    actions.tvChannels.hideChannelDetails(this.$store);

    if (this.liveProgram?.startMs) {
      if (this.isPageChannelOpen || this.redirectToPlayer) {
        this.$store.player.video.playingTimeMs = this.liveProgram.startMs;
        await this.goToCatalogPage();
      } else {
        await actions.tvChannels.selectChannel(
          this.$store,
          this.channel.id,
          this.$events,
          this.shouldAddToRecentlyWatched,
          false,
          this.liveProgram.startMs
        );
      }
    }

    this.gaEvent({
      category: 'channels_now',
      action: 'Клик по кнопке "Смотреть с начала"',
      channel_name: this.channel.id,
    });
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/title-details';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.program-info-and-buttons {
  .program-air-time {
    display: flex;
    margin-bottom: 12px;

    @include mobile {
      margin-bottom: 8px;
    }

    .badge {
      margin-right: 12px;
    }
  }

  h5 {
    margin-bottom: 16px;

    @include mobile {
      margin-bottom: 12px;
    }
  }

  .buttons {
    display: flex;
    opacity: 0;
    transform: translatey(16px);

    &.enable-animation {
      transition: opacity var(--ease-out) 0.25s, var(--ease-out) transform 0.25s;
    }

    &.appear {
      opacity: 1;
      transform: translatey(0);
    }

    &.disappear {
      opacity: 0;
      transform: translatey(16px);
    }

    .button {
      height: 56px;

      &.bookmark {
        width: 56px;
        height: 56px;
      }

      &:not(.bookmark) {
        margin-right: 16px;

        @include mobile-and-tablet {
          margin-right: 8px;

          &.tertiary {
            display: none;
          }
        }
      }

      @include mobile {
        display: flex;
        height: 48px;

        &:not(.bookmark) {
          flex-grow: 1;
        }

        &.bookmark {
          width: 48px;
          height: 48px;
        }
      }
    }
  }

  .progress-bar {
    @include mobile {
      margin-bottom: 24px;
    }
  }

  .bottom {
    padding-bottom: 48px;

    @include tablet {
      padding-bottom: 24px;
    }
  }
}
</style>
