import * as strg from './storage';
import { getEnvVars } from 'src/config';

export const storage = strg.storage;

/**
 * Since wlAppId could change dynamically - call this method in functions which will be invoked on each request
 *
 * For example:
 * - do not call this function when defining Vue component class attributes - since after component instantiation they will NOT be changed
 * - call it in created or mounted methods instead
 */
export const wlDetector = () => {
  const { wlAppId } = getEnvVars();
  return {
    isSmotreshka: wlAppId.startsWith('sm'),
    isSanta: wlAppId === 'santa',
    isKt: wlAppId === 'kt',
    isKcell: wlAppId === 'kcell',
    isMts: wlAppId === 'mtsby',
    isMegacom: wlAppId === 'megacom',
    isTele2: wlAppId === 'tele2',
    isN3: wlAppId === 'n3',
    isAltel: wlAppId === 'altel',
    isUztel: wlAppId === 'uztel',
    isMegafon: wlAppId === 'megafon',
    isIntersv: wlAppId === 'intersv',
    isMftv: wlAppId === 'mftv',
  };
};

/**
 * Sleep for n milliseconds
 * @see https://stackoverflow.com/a/39914235/2393499
 */
export const sleep = (milliseconds: number) => new Promise((res) => setTimeout(res, milliseconds));

export const getIsFullscreen = () => {
  if (process.env.VUE_ENV === 'server') {
    return false;
  }
  return (
    !!document.fullscreenElement ||
    !!document.webkitFullscreenElement ||
    !!document.mozFullScreenElement ||
    !!document.msFullscreenElement
  );
};

export const hasAdBlock = () => window.hasAdBlock;

export const errorFactories = {
  error404: (...params: any) => {
    const err: any = new Error(...params);
    err.code = 404;
    return err;
  },
};
