<template>
  <p v-if="showError" class="error" :data-cy="dataCyPrefixed" v-text="errMessage" />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component
export default class ErrorSequoia extends SequoiaComponent {
  @Prop({ default: [] })
  messages?: Array<string>;

  @Prop()
  dataCy?: string;

  get dataCyPrefixed() {
    return this.dataCy ? `error-${this.dataCy}` : '';
  }

  get showError() {
    return this.messages?.length;
  }

  get errMessage() {
    if (!this.messages?.length) {
      return this.getTranslation('error');
    }

    return this.messages.map((err: string) => err).join('\n');
  }
}
</script>
