<template>
  <div v-if="isCurrentGenreFavorites" class="channels-page-empty-favorites" :class="theme">
    <div v-if="!channels.length" class="center-wrap">
      <IconSVG class="bookmark" :svg="IconBookmark" />
      <div class="h4" v-html="getTranslation('list_of_fav_is_empty')" />
      <div class="message color-secondary" v-html="getTranslation('add_channels_to_the_list')" />
    </div>
    <ChannelsSlider v-if="!channels.length || showRecommendedSlider" type="recommended" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { TV_SPECIAL_GENRES } from 'src/constants';
import IconSVG from 'src/components/IconSVG.vue';
import IconBookmark from 'src/svg/bookmark.svg';
import ChannelsSlider from 'src/components/tv-channels/ChannelsSlider.vue';

@Component({
  components: {
    IconSVG,
    ChannelsSlider,
  },
})
export default class EmptyFavorites extends SequoiaComponent {
  IconBookmark = IconBookmark;

  @Prop({ required: true })
  showRecommendedSlider!: boolean;

  get channels() {
    return Object.keys(selectors.tvChannels.filteredChannelsSelector(this.$store));
  }

  get currentGenre() {
    return selectors.tvChannels.currentGenreSelector(this.$store);
  }

  get isCurrentGenreFavorites() {
    return this.currentGenre === TV_SPECIAL_GENRES.favourite;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.channels-page-empty-favorites {
  .center-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 480px;
    margin: 0 auto 96px;
    text-align: center;

    @media (min-width: $desktop-m-min) {
      width: 480px;
      height: 418px;
    }

    @include desktop-m {
      margin-bottom: 60px;
    }

    @media (max-width: #{$desktop-s-max}) {
      margin-bottom: 0;
    }
  }

  .bookmark {
    width: 160px;
    height: 160px;
    margin-bottom: 16px;
  }

  .message {
    min-height: 48px;
  }

  .channels-slider {
    padding-top: 48px;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .bookmark {
      color: var(--c-light-brand);
    }
  }

  &.dark {
    .bookmark {
      color: var(--c-dark-brand);
    }
  }
}
</style>
