<template>
  <div class="with-side-and-bottom-padding">
    <QuickSubscribeBackground />
    <div class="trial-gift dark">
      <div class="image-wrapper mb-16">
        <ImageWithHash src="/public/images/trials/gift.png" alt="gift" class="image" />
      </div>
      <h3 class="mb-24" v-html="getTranslationForAuthAndRegTrial('giftTitle')" />
      <div class="description mb-32" v-html="giftDescription" />
      <div class="buttons-wrapper">
        <ButtonDefault data-cy="continue" @click="handleClick">
          <span v-html="getTranslationForAuthAndRegTrial('giftButton')" />
        </ButtonDefault>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import { actions } from 'src/store/actions';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: { ImageWithHash, QuickSubscribeBackground, ButtonDefault },
})
export default class TrialGift extends SequoiaComponent {
  get giftDescription(): string {
    const channelsLength = this.$store.QS.tempSub?.renderingData.channels.length;
    const trialChannelsLength = this.$store.QS.tempSub?.trial?.renderingData.channels.length;

    let giftDescription = this.getTranslationForAuthAndRegTrial('giftSubtitle');

    if (trialChannelsLength) {
      giftDescription = giftDescription.replace(
        '%channelCountTrial%',
        trialChannelsLength.toString()
      );
    }

    if (channelsLength) {
      giftDescription = giftDescription.replace('%channelCount%', channelsLength.toString());
    }

    return giftDescription;
  }

  mounted() {
    this.gaEvent({
      category: 'acquiring',
      action: 'Показ окна "Подарок от Смотрёшки"',
      qs_qr: this.$route.query.token ? 'qr' : 'qs',
      offer_id: this.$store.QS.tempSub?.trial?.offerId,
    });
  }

  handleClick() {
    location.href = this.$store.QS.confirmationUrl;
    this.gaEvent({
      category: 'acquiring',
      action: 'Клик по кнопке "Продолжить" в окне "Подарок от Смотрёшки"',
    });
  }

  getTranslationForAuthAndRegTrial(key: string) {
    return actions.authAndReg.getTranslationForAuthAndRegTrial(this.$store, key);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';

.trial-gift {
  position: relative;

  .image-wrapper {
    display: inline-block;
    padding: 12px;
    background-color: var(--c-light-font-tertiary);
    border-radius: 100%;

    .image {
      width: 72px;
    }
  }

  .description {
    color: var(--c-dark-font-primary);
    letter-spacing: 0.2px;
    @include body1;
  }
}
</style>
