/**
 * Returns current environment
 * unknown = access from server, like from GitLab pipelines
 * development = localhost | 127.0.0.1 | 192.168.*.* | 172.16.*.* - 172.31.*.* | 10.*.*.*
 * staging = *test.seq.lfstrm.tv
 * production = everything else
 */
export const getEnvironment = (
  ssrHost = ''
): 'development' | 'staging' | 'production' | 'unknown' => {
  const hostWithPort = process.env.VUE_ENV === 'server' ? ssrHost : window.location.hostname;
  const [host] = hostWithPort.split(':');

  if (!host) {
    return 'unknown';
  } else if (host.includes('localhost') || host.includes('127.0.0.1') || isPrivateIp(host)) {
    return 'development';
  } else if (host.includes('test.seq.lfstrm.tv')) {
    return 'staging';
  }
  return 'production';
};

/**
 * Checks whether IP is private
 * @see https://stackoverflow.com/a/13969691/2393499
 */
const isPrivateIp = (ip: string) => {
  const parts = ip.split('.');
  return (
    parts[0] === '10' ||
    (parts[0] === '172' && parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31) ||
    (parts[0] === '192' && parts[1] === '168')
  );
};
