<template>
  <div
    class="input-block"
    :class="[
      theme,
      size,
      inputCategory,
      { disabled },
      {
        'label-focus': labelFocus,
        'with-captcha': withCaptcha,
        'with-icon-action': icon,
        'with-icon-leading': iconLeading,
        'without-submit': isIconSearch && !submitOnEnter,
      },
    ]"
  >
    <div class="container">
      <IconSVG :svg="iconLeading" class="icon-leading" :size="20" />

      <input
        :id="id"
        ref="input"
        class="input"
        :value="value"
        :placeholder="inputCategory === 'floating' ? ' ' : placeholderLocal"
        :class="[size, status, { password: isPassword }]"
        :type="
          type
            ? type
            : icon === 'visibility' && !showPassword
            ? 'password'
            : withCaptcha || allowDigitsOnly
            ? 'number'
            : 'text'
        "
        :disabled="disabled"
        :readonly="readonly"
        :name="name"
        :minlength="minlength"
        :maxlength="maxlength"
        :required="required"
        :data-cy="dataCy ? `input-${dataCy}` : ''"
        :inputmode="allowDigitsOnly ? 'numeric' : ''"
        :autocomplete="autocomplete"
        @input="updateSelf($event.target.value)"
        @change="onChange($event)"
        @keypress="onKeypress($event)"
        @keyup.enter="
          submitOnEnter
            ? $emit('clickEnter')
            : $event.target.nextElementSibling
            ? $event.target.nextElementSibling.focus()
            : ''
        "
        @focus="onFocus($event)"
        @paste="onPaste($event)"
      />

      <label v-if="inputCategory === 'floating'" class="label floating" :for="id" v-text="label" />

      <button
        v-if="isIconSearch || isIconVisibility"
        :tabindex="tabIndexForButton"
        type="button"
        class="input-icon"
        :class="{ search: isIconSearch, visibility: isIconVisibility }"
        @click.prevent="isIconSearch ? $emit('submitSearch') : (showPassword = !showPassword)"
      >
        <IconSVG
          :svg="isIconSearch ? IconSearch : showPassword ? IconVisibilityOn : IconVisibilityOff"
        />
      </button>

      <button
        v-else-if="icon === 'clear' && value"
        type="button"
        class="input-icon"
        @click.prevent="clearInput"
      >
        <IconSVG :svg="IconClear" />
      </button>

      <div
        v-if="message && status"
        class="message"
        :class="[{ status: status }, status ? `bg-${status}` : '']"
        :data-cy="dataCy ? `input-error-${dataCy}` : ''"
        v-text="message"
      />
    </div>

    <template v-if="withCaptcha">
      <CaptchaSequoia :use-vercont-server="useVercontServer" />
      <div class="flex-break" />
    </template>

    <div v-if="description" class="description" v-text="descriptionFormatted" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { selectors } from 'src/store/selectors';
import IconSVG from 'src/components/IconSVG.vue';
import IconVisibilityOn from 'src/svg/visibility-on.svg';
import IconVisibilityOff from 'src/svg/visibility-off.svg';
import IconSearch from 'src/svg/search.svg';
import IconClear from 'src/svg/clear.svg';
import CaptchaSequoia from 'src/components/CaptchaSequoia.vue';
import { getBrowserName, BROWSERS, getPlatformType, PLATFORMS } from 'src/utils/platform-detector';
import { replaceAt } from 'src/utils/string';
import { KEY_CODE } from 'src/constants';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { v4 as uuidV4 } from 'uuid';

// used for phones
let Inputmask: Inputmask.Static;

@Component({
  components: {
    IconSVG,
    CaptchaSequoia,
  },
})
export default class InputText extends Vue {
  IconVisibilityOn = IconVisibilityOn;
  IconVisibilityOff = IconVisibilityOff;
  IconClear = IconClear;
  IconSearch = IconSearch;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  value?: string;

  @Prop({ default: 0 })
  tabIndexForButton!: number;
  // pass type only for anything else other than 'text' & 'password'
  // e.g phone, captcha, etc.
  @Prop()
  type?: string;

  @Prop({ default: 'floating' })
  inputCategory!: 'default' | 'floating';

  // it is necessary for floating label to keep placeholder as ' '
  @Prop({ default: ' ' })
  placeholder!: string;

  // it is used only for inputCategory = 'floating'
  @Prop()
  label?: string;

  // has to be false for ButtonDropdown
  @Prop({ default: true })
  labelFocus!: boolean;

  @Prop()
  iconLeading?: Record<string, unknown>;

  @Prop({ default: '' })
  icon!: '' | 'visibility' | 'clear' | 'search';

  @Prop()
  description?: string;

  // visible only when there is any status
  @Prop()
  message?: string;

  @Prop()
  status?: 'success' | 'warning' | 'error';

  @Prop({ default: 'medium' })
  size!: 'small' | 'medium' | 'large';

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop()
  minlength?: number;

  @Prop()
  maxlength?: number | string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: 'on' })
  autocomplete!: string;

  @Prop()
  name?: string;

  @Prop({ default: false })
  withCaptcha!: boolean;

  @Prop({ default: false })
  allowDigitsOnly!: boolean;

  @Prop({ default: false })
  useVercontServer!: boolean;

  @Prop({ default: false })
  submitOnEnter!: boolean;

  @Prop()
  dataCy?: string;

  id = '';
  showPassword = false;
  placeholderChanged = ''; // tracks changes for a placeholder when InputMask is applied & use
  maxlengthParsed = 0;
  regexForPasswordExclusion = /[^a-zA-Z0-9!@#$%^&*)(+=._-]/g;
  regexForDigitsExclusion = /\D/g;
  isPassword = this.icon === 'visibility';
  isMobileChrome = getPlatformType() === PLATFORMS.mobile && getBrowserName() === BROWSERS.chrome;

  @Watch('type')
  onTypeChange(val: string) {
    if (val !== 'tel') {
      if (Inputmask && this.refInput) {
        Inputmask.remove(this.refInput);
      }
      this.placeholderLocal = ' ';
    }
  }

  @Watch('$store.authAndReg.phone')
  onAuthAndRegPhoneChange() {
    if (
      this.$store.isPhoneMode &&
      this.refInput?.name &&
      this.refInput?.name === 'phone' &&
      this.refInput?.type === 'tel' &&
      this.$store.authAndReg.phone === ''
    ) {
      this.initPhoneMask();
    } else {
      this.placeholderLocal = ' ';
    }
  }

  @Ref('input')
  refInput?: HTMLInputElement;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get isIconSearch() {
    return this.icon === 'search';
  }

  get isIconVisibility() {
    return this.icon === 'visibility';
  }
  get isDigit() {
    return this.type === 'number' || this.allowDigitsOnly || this.withCaptcha;
  }

  get passwordMinLength() {
    return selectors.common.authAndRegPasswordMinSelector(this.$store);
  }

  get descriptionFormatted() {
    if (this.description?.search('%number%')) {
      // (!) for password descriptions only
      // replace %number% with an actual number from store
      return this.description.replace(`%number%`, this.passwordMinLength.toString());
    } else {
      return this.description;
    }
  }

  get inputValue() {
    return this.value || '';
  }

  set inputValue(value: string) {
    this.$emit('input', value);
  }

  // it is needed to work properly with InputMask & change between input types tel/text
  get placeholderLocal() {
    if (this.placeholderChanged !== this.placeholder) {
      return this.placeholder;
    } else {
      return this.placeholderChanged;
    }
  }

  set placeholderLocal(newVal) {
    this.placeholderChanged = newVal;
  }

  mounted() {
    this.id = uuidV4();
    this.maxlengthParsed = parseInt((this.maxlength || '').toString());

    if (this.type === 'tel') {
      this.initPhoneMask();
    }
  }

  updateSelf(val: string) {
    if (this.isMobileChrome) {
      // Allow only English alphanumeric symbols + special characters for passwords
      if (this.isPassword) {
        val = val.replace(this.regexForPasswordExclusion, '');
      }
      this.inputValue = val;
      return;
    }
    this.$emit('input', val);
  }

  onChange(e: Event) {
    this.$emit('change', e);
  }

  onPaste(e: ClipboardEvent) {
    // use default paste event for all non-digit & non-password inputs
    if (!this.isDigit && !this.isPassword) {
      return;
    }

    const regex = this.isDigit ? this.regexForDigitsExclusion : this.regexForPasswordExclusion;

    // Get pasted data via clipboard API
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData?.getData('Text').replace(regex, '') || ''; // strip the string from ALL non-numeric characters
    const input = <HTMLInputElement>e.target;

    // const pastedDataLength = pastedData.length;
    const currentInputValue = input.value;

    // Prevent any data from actually being pasted into the input in all cases
    e.stopPropagation();
    e.preventDefault();

    const start = input.selectionStart || -1;
    const end = input.selectionEnd || -1;

    let value: string;

    if (start > -1 && end > -1 && currentInputValue !== '') {
      value =
        start !== end
          ? replaceAt(currentInputValue, start, end, pastedData) // when selecting some/all text in the input
          : currentInputValue.slice(0, end) + pastedData + currentInputValue.slice(end); // when simply placing a caret somewhere inside the input
    } else {
      value = '' + currentInputValue + pastedData;
    }

    // now we need to manually update input value
    // and trim everything that exceeds allowed maxlength if it is present
    this.updateSelf(value.slice(0, this.maxlengthParsed || value?.length));
  }

  onKeypress(e: KeyboardEvent) {
    if (this.isMobileChrome) {
      return;
    }

    // Allow only English alphanumeric symbols + special characters for passwords
    const regexForPassword = /^[a-zA-Z0-9!@#$%^&*)(+=._-]*$/g;
    const key = String.fromCharCode(e.which);

    if (this.isPassword && e.which !== KEY_CODE.enter && !regexForPassword.test(key)) {
      e.preventDefault();
      return;
    }

    const input = <HTMLInputElement>e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    if (
      (start === end &&
        this.maxlengthParsed &&
        this.inputValue?.length >= this.maxlengthParsed &&
        e.which !== KEY_CODE.backspace) ||
      ((this.allowDigitsOnly || this.withCaptcha || this.type === 'number') &&
        !this.isNumberKeyCodeValid(e))
    ) {
      if (e.which !== KEY_CODE.enter) {
        e.preventDefault();
      }
    } else {
      this.$emit('keypress', e);
    }
  }

  onFocus(e: FocusEvent) {
    this.$emit('focus', e);
    if (this.$store.isPhoneMode && this.type === 'tel' && this.$store.authAndReg.phone === '') {
      this.initPhoneMask();
    } else if (this.refInput) {
      this.refInput.placeholder = this.inputCategory === 'floating' ? ' ' : this.placeholderLocal;
    }
  }

  clearInput() {
    this.inputValue = '';
    this.refInput?.focus();
  }

  isNumberKeyCodeValid(e: KeyboardEvent) {
    const keyCode = e.keyCode || e.which;
    const key = String.fromCharCode(keyCode);

    if (key?.length === 0) {
      return;
    }

    const regex = /^[0-9\b]+$/;

    return regex.test(key);
  }

  async initPhoneMask() {
    if (
      !this.$store.isPhoneMode ||
      this.refInput?.type !== 'tel' ||
      !this.$store.authAndReg.phoneMask?.phoneCode
    ) {
      return false;
    }

    Inputmask = (await import(/* webpackChunkName: "inputmask" */ 'inputmask')).default;

    if (this.refInput && this.$store.authAndReg?.phoneMask?.phoneCode) {
      Inputmask(this.$store.authAndReg.phoneMask.phoneCode, {
        oncomplete: () => {
          if (this.$store.incompletePhoneInitialFlag) {
            this.$store.incompletePhoneInitialFlag = false;
          }
          this.$emit('onCompletePhoneCallback', this.inputValue);
        },
        onincomplete: () => {
          this.$emit('onIncompletePhoneCallback');
        },
      }).mask(this.refInput);
    }
  }
}
</script>

<style lang="scss">
@import 'src/components/ui/input/index';
</style>

<style lang="scss" scoped>
/* stylelint-disable selector-no-vendor-prefix, property-no-vendor-prefix, media-feature-name-no-vendor-prefix */
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

@mixin label-when-input-focused {
  top: 3px;
  max-width: 77.77%;
  pointer-events: none;
  transform: translate(0, 0) scale(1);
  @include caption2;
}

.input-block input::placeholder {
  @extend %truncate-after-1-line;
}

.input-block {
  .input {
    @include body1;
  }

  &.floating {
    &.label-focus .input:focus + .label {
      @include label-when-input-focused;
      @include caption2;
    }

    .input {
      padding: 20px 16px 4px;

      &.password {
        padding-right: 46px;
      }
    }

    .label {
      position: absolute;
      top: 6px;
      left: 0;
      padding: 2px 0;
      margin-bottom: 0;
      margin-left: 16px;
      cursor: pointer;
      @extend %truncate-after-1-line;
      @include body1;

      &.floating {
        cursor: text;
      }
    }

    /*
    Translate down and scale the label up to cover the placeholder,
    when following an input (with :placeholder-shown support).
    Also make sure the label is only on one row, at max 2/3rds of the field
    — to make sure it scales properly and doesn't wrap.
    */
    .input:placeholder-shown + .label {
      max-width: 70%;
      //cursor: text;
      transform-origin: left bottom;
    }

    /* By default, the placeholder should be transparent.
       Also, it should inherit the transition. */
    /* stylelint-disable-next-line */
    ::placeholder,
    ::-webkit-input-placeholder {
      opacity: 0;
      transition: inherit;
    }

    /* Show the placeholder when the input is focused. */
    /* stylelint-disable-next-line */
    .input:focus::-webkit-input-placeholder {
      opacity: 1;
    }

    /* When the element is focused, remove the label transform.
      Also, do this when the placeholder is _not_ shown,
      i.e. when there's something in the input at all. */
    .small {
      &:-webkit-autofill + .label,
      &:focus + .label {
        top: 6px;
      }

      &:placeholder-shown + .label {
        transform: translate(0, 1px) scale(1);
      }
    }

    .medium {
      &:-webkit-autofill + .label,
      &:focus + .label {
        top: 6px;
      }

      &:placeholder-shown + .label {
        transform: translate(0, 5px) scale(1);
      }
    }

    .large {
      &:-webkit-autofill + .label,
      &:focus + .label {
        top: 6px;
      }

      &:placeholder-shown + .label {
        transform: translate(0, 8px) scale(1);
      }
    }

    .input:not(:placeholder-shown) + .label {
      @include label-when-input-focused;
      @include caption2;
    }

    // this property has to be written separately from .input:not(:placeholder-shown) + .label
    // otherwise, Firefox stops working properly with  .input:not(:placeholder-shown)
    .input:-webkit-autofill + .label {
      @include label-when-input-focused;
      @include caption2;
    }
  }

  &.with-captcha {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .container {
      flex-basis: calc(100% - 136px);
      margin-right: 16px;
      overflow: hidden;
    }

    .flex-break {
      flex-basis: 100%;
      height: 0;
    }

    .input {
      padding-right: 16px;
      margin-right: 16px;
    }
  }

  &.with-icon-action {
    .input {
      padding-right: 56px;
    }
  }

  &.with-icon-leading {
    .input {
      padding-left: 44px;
    }

    .icon-leading {
      position: absolute;
      top: 18px;
      left: 12px;
      height: 20px;
    }

    .label.floating {
      margin-left: 44px;
    }
  }

  &.without-submit {
    .input-icon {
      &.search {
        pointer-events: none;
      }
    }
  }

  + .input-block {
    margin-top: 16px;
  }
}
</style>
