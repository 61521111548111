<template>
  <router-link v-if="!isUrlExternal(link)" :to="link" @click.native="$emit('moveUp')">
    <slot />
  </router-link>

  <a v-else :href="link" target="_blank">
    <slot />
  </a>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { isUrlExternal } from 'src/utils/url';
import { Prop } from 'vue-property-decorator';

@Component
export default class LinkInternalOrExternal extends SequoiaComponent {
  isUrlExternal = isUrlExternal;

  @Prop()
  link?: string;
}
</script>
