<template>
  <div
    v-if="isDesktop"
    v-click-outside="clickOutsideProfileMenu"
    class="profile-selector desktop-only"
    :class="{ pressed: showProfileDropdown }"
    data-cy="header-profile-dropdown"
  >
    <div class="profile-button flex-center-vertical" @click="toggleProfileDropdown()">
      <IconSVG :svg="IconProfile" />
    </div>

    <transition name="fade-ease-out-slow">
      <div v-if="showProfileDropdown" class="dropdown-wrapper">
        <div v-if="$store.account" class="dropdown">
          <div
            v-for="profile in $store.account.profiles"
            :key="profile.id"
            class="dropdown-item"
            @click="selectProfile(profile)"
          >
            <IconSVG :svg="IconProfile" />
            <div class="profile-name-wrap">
              <span class="profile-title" v-text="profile.name" />
              <div
                v-if="$store.account.profile.id === profile.id"
                class="current-profile"
                v-html="getTranslation('current_profile')"
              />
            </div>
            <div class="restrictions">
              <div v-if="profile.age_rating >= 18">18+</div>
              <div v-if="profile.pin_required">PIN</div>
            </div>
          </div>

          <router-link
            v-if="!personalSettings.hideProfileManagement"
            to="/personal/profiles"
            class="profiles-manage dropdown-item"
            @click.native="
              gaEvent({
                category: 'header_personal_area',
                action: 'Клик “Меню/ управление профилями”',
              })
            "
            v-html="getTranslation('profile_management')"
          />
          <router-link
            v-if="hasActiveSubscriptions"
            to="/personal/subscriptions"
            class="dropdown-item"
            @click.native="
              gaEvent({
                category: 'header_personal_area',
                action: 'Клик “Меню/ подписки”',
              })
            "
            v-html="getTranslation('subscriptions')"
          />
          <router-link
            v-if="!personalSettings.hidePersonalData"
            to="/personal/data"
            class="dropdown-item"
            @click.native="
              gaEvent({
                category: 'header_personal_area',
                action: 'Клик “Меню/ личные данные”',
              })
            "
            v-html="getTranslation('personal_data')"
          />
          <a
            href="#"
            class="dropdown-item logout"
            data-cy="header-button-logout"
            @click.prevent="logOut"
            v-html="getTranslation('logout')"
          />
        </div>
      </div>
    </transition>
  </div>

  <div v-else class="profile-selector">
    <div class="dropdown-list">
      <div
        class="list-header"
        :class="{ opened: profilesListOpened }"
        @click="profilesListOpened = !profilesListOpened"
      >
        <div v-if="profilesListOpened" class="h6 mb-0" v-html="getTranslation('change_profile')" />

        <div v-else class="list-item">
          <IconSVG :svg="IconProfile" :size="40" />
          <div class="list-item-info">
            <div class="flex-center-vertical">
              <div class="profile-name" v-text="$store.account.profile.name" />
              <div class="restrictions ml-12">
                <div v-if="$store.account.profile.age_rating >= 18">18+</div>
                <div v-if="$store.account.profile.pin_required">PIN</div>
              </div>
            </div>
            <div class="current-profile" v-html="getTranslation('current_profile')" />
          </div>
        </div>
        <IconSVG :svg="profilesListOpened ? IconAngleDown : IconAngleRight" class="arrow" />
      </div>

      <div v-if="profilesListOpened" class="list">
        <div
          v-for="profile in $store.account.profiles"
          :key="profile.id"
          class="list-item mb-8"
          @click="selectProfile(profile)"
        >
          <IconSVG :svg="IconProfile" :size="40" />
          <div class="list-item-info">
            <div class="flex-center-vertical">
              <div class="profile-name" v-text="profile.name" />
              <div class="restrictions ml-12">
                <div v-if="profile.age_rating >= 18">18+</div>
                <div v-if="profile.pin_required">PIN</div>
              </div>
            </div>
            <div
              v-if="$store.account.profile.id === profile.id"
              class="current-profile"
              v-html="getTranslation('current_profile')"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- TODO uncomment when favorites are integrated -->
    <!-- <router-link
        v-if="!isAnonymous"
        :to="content.favoritesUrl || ''"
        :exact-active-class="content.favoritesUrl ? 'active' : ''"
        class="favorites flex-center-vertical menu-item mb-16"
      >
        <IconSVG :svg="IconBookmarkOn" /> <span v-html="getTranslation('favorites')"/>
      </router-link> -->
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Watch } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconProfile from 'src/svg/profile.svg';
import IconAngleRight from 'src/svg/angle-right.svg';
import IconAngleDown from 'src/svg/angle-down.svg';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import { TProfile } from 'src/api/auth/types';
import { actions } from 'src/store/actions';
import { PROFILE_ACTIONS } from 'src/constants';
import { selectors } from 'src/store/selectors';
import { clickOutside } from 'src/utils/directives';
import logger from 'src/utils/logger';
import { makePath } from 'src/utils/url';
import {
  hasActiveSubscriptionsSelector,
  subscriptionsSelectorV2,
} from 'src/store/payment/selectors';

const log = logger('profile-selector');

@Component({
  components: {
    IconSVG,
    ButtonDropdown,
  },
  directives: { clickOutside },
})
export default class ProfileDropdown extends SequoiaComponent {
  IconProfile = IconProfile;
  IconAngleRight = IconAngleRight;
  IconAngleDown = IconAngleDown;

  showProfileDropdown = false;
  profilesListOpened = false;

  @Prop({ default: true })
  isDesktop!: boolean;

  @Watch('$route')
  onRouteChange() {
    this.showProfileDropdown = false;
  }

  get profile() {
    return this.$store.account?.profile;
  }

  get personalSettings() {
    return selectors.personal.personalSettingsSelector(this.$store);
  }

  get hasActiveSubscriptions() {
    return selectors.payment.hasActiveSubscriptionsSelector(this.$store);
  }

  toggleProfileDropdown() {
    this.showProfileDropdown = !this.showProfileDropdown;
  }

  clickOutsideProfileMenu() {
    this.showProfileDropdown = false;
  }

  async selectProfile(profile: TProfile) {
    if (this.profile && profile.id === this.profile.id) {
      return;
    }

    this.toggleProfileDropdown();
    if (profile.pin_required) {
      this.$emit('setProfileForChange', profile);
      this.$emit('setDropdownActionName', PROFILE_ACTIONS.CHANGE);
      actions.common.showModal(this.$store);
    } else {
      try {
        await actions.profile.change(this.$store, {
          data: {
            Id: profile.id,
            Pin: '',
          },
        });

        actions.common.showProfileNotification(
          this.$store,
          `<span>${this.getTranslation('profile_entered')}</span><span>«${profile.name}»</span>`,
          'to-profile'
        );
      } catch (err) {
        log.error(err);
      }
    }

    this.gaEvent({
      category: 'header_personal_area',
      action: 'Клик "Меню/ переключение профиля"',
      profile: profile?.name,
    });
  }

  async logOut() {
    this.toggleProfileDropdown();
    await actions.authAndReg.logout(this.$store);
    localStorage?.removeItem('tab');
    document.location.href = makePath('/');

    this.gaEvent({
      category: 'header_personal_area',
      action: 'Клик “Меню/ выход”',
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/components/header/dropdowns';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.profile-selector {
  &.desktop-only {
    position: relative;
    height: 100%;
    cursor: pointer;

    .icon {
      width: 33px;
      height: 33px;
      color: var(--c-light-100);
    }

    .dropdown {
      width: 230px;
    }

    .profile-button {
      height: 100%;
      padding: 12px 16px;
    }

    &.pressed .profile-button,
    .profile-button:active {
      background-color: var(--alpha-dark-4);
    }

    &:not(.pressed) {
      @include devices-with-hover {
        .profile-button:hover {
          background-color: var(--alpha-dark-3);
        }
      }
    }

    .profile-name-wrap {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .profile-title {
      max-width: 100%;
      @extend %truncate-after-1-line;
    }

    .profiles-manage {
      position: relative;
      padding: 8px 16px;
      margin: 16px 0;

      &::before {
        content: '';
        position: absolute;
        top: -8px;
        left: 5%;
        width: 90%;
        height: 1px;
        background-color: var(--alpha-dark-4);
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 5%;
        width: 90%;
        height: 1px;
        background-color: var(--alpha-dark-4);
      }
    }
  }

  a {
    color: var(--c-dark-font-primary);
  }

  .current-profile {
    color: var(--c-dark-font-tertiary);
    @include body2;
  }

  .restrictions {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    div {
      display: flex;
      align-items: center;
      height: 16px;
      padding-right: 4px;
      padding-left: 4px;
      margin-right: 4px;
      font-size: 8px;
      color: var(--c-dark-font-secondary);
      border: 1px solid var(--c-dark-font-secondary);
      border-radius: 2px;
    }
  }
}

.dropdown-list {
  padding: 8px 16px;
  margin-bottom: 24px;
  background-color: var(--alpha-dark-3);
  border-radius: 4px;

  @include mobile {
    margin-bottom: 16px;
  }

  .icon {
    color: var(--c-light-100);
  }

  .list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &.opened {
      padding: 12px 0 16px;
    }

    .arrow {
      color: var(--alpha-dark-6);
    }
  }

  .list-item {
    display: flex;
    align-items: center;
    height: 48px;
    overflow: hidden;
    cursor: pointer;

    .icon {
      flex: none;
      margin-right: 20px;
    }

    .list-item-info {
      overflow: hidden;
    }
  }
}

.list-header {
  .list-item {
    .profile-name {
      max-width: 100%;
      @extend %truncate-after-1-line;
    }
  }
}

.dropdown-wrapper {
  .current-profile {
    color: var(--c-dark-font-tertiary);
    @include caption2;
  }
}
</style>
