<template>
  <div class="epg-element" :class="[cssClasses]">
    <div v-if="isLive" class="status caption1 now">
      <span class="line" />
      <span class="text" v-html="getTranslation('now')" />
      <span class="line" />
    </div>
    <div class="inner row">
      <div class="time-block col-desktop-2 col-tablet-2 col-mobile-4">
        <div class="inner-wrap">
          <div class="time" v-text="time" />
          <BadgeSequoia
            v-if="(isLive || isDvrAvailable) && !isFuture"
            :text="badgeText"
            :icon="isLive ? IconAir : ''"
            :type="isLive ? 'air' : ''"
          />
        </div>
        <ButtonDefault
          v-if="(isLive || isDvrAvailable) && !isFuture && !isCurrent"
          view="tertiary"
          class="visible-mobile"
          size="medium"
          :theme-forced="theme"
          @click="startPlayingProgram"
        >
          <IconSVG :svg="IconPlay" />
          <span v-html="getTranslation('tv_watch')" />
        </ButtonDefault>
      </div>

      <ProgramPreview
        class="col-desktop-3 col-tablet-2 col-mobile-4 hidden-mobile"
        :class="{ 'with-hover': !isFuture && !isCurrent && (isLive || isDvrAvailable) }"
        :src="src"
        :title="title"
        :timeline-position="timelinePosition"
        :show-play="!isFuture && !isCurrent && (isLive || isDvrAvailable)"
        :show-progress="!isFuture && isLive"
        :width="310"
        :height="175"
        @click="startPlayingProgram"
      />

      <div class="epg-details col-desktop-7 col-tablet-4 col-mobile-4">
        <div class="title" v-html="title" />
        <TextWithTruncation v-if="description" :source-text="description" :number-of-lines="2" />
      </div>
    </div>
    <div v-if="isLive" class="status caption1 next">
      <span class="line" />
      <span class="text" v-html="getTranslation('next')" />
      <span class="line" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ProgramPreview from 'src/components/tv-channels/ProgramPreview.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconAir from 'src/svg/genres/air.svg';
import IconPlay from 'src/svg/player/play.svg';
import TextWithTruncation from 'src/components/ui/TextWithTruncation.vue';
import { TChannelEnhanced, TProgramEnhanced } from 'src/api/channels/types';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { DateTime } from 'src/utils/time/date-time';
import logger from 'src/utils/logger';
import { EVENTS } from 'src/constants';

const log = logger('epg-element');

@Component({
  components: { ProgramPreview, BadgeSequoia, TextWithTruncation, ButtonDefault, IconSVG },
})
export default class EpgElement extends SequoiaComponent {
  IconAir = IconAir;
  IconPlay = IconPlay;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true })
  channel!: TChannelEnhanced;

  @Prop({ required: true })
  program!: TProgramEnhanced;

  @Prop({ required: true })
  src!: string;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  description!: string;

  @Prop({ required: true })
  time!: string;

  @Prop({ required: true })
  timelinePosition!: number;

  @Prop({ required: true })
  isLive!: boolean;

  @Prop({ required: true })
  isFuture!: boolean;

  @Prop({ required: true })
  isCurrent!: boolean;

  @Prop({ required: true })
  isDvrDisabled!: boolean;

  @Prop({ default: true })
  redirectToPlayer!: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get cssClasses() {
    return [
      this.theme,
      { future: this.isFuture || this.isCurrent },
      { live: this.isLive },
      { current: this.isCurrent },
    ];
  }

  get isDvrAvailable() {
    return !this.isDvrDisabled && !this.isDvrRestricted && !this.isChannelLocked;
  }

  get isChannelLocked() {
    return selectors.tvChannels.isChannelLockedSelector(this.$store, this.channel);
  }

  get isDvrRestricted() {
    return selectors.tvEpg.isDvrRestrictedSelector(this.$store, this.channel);
  }

  get currentProgram() {
    return selectors.tvEpg.currentProgramSelector(this.$store);
  }

  get badgeText() {
    return this.isLive ? this.getTranslation('on_the_air') : this.getTranslation('in_recording');
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  get isPageChannelOpen() {
    return selectors.tvChannels.isPageChannelOpenSelector(this.$store);
  }

  get hasDvr() {
    return selectors.tvEpg.hasDvrSelector(this.$store, this.channel);
  }

  setPickedEpochDay(day: number) {
    actions.player.setPickedEpochDay(this.$store, day);
  }

  async goToCatalogPage() {
    await this.$router.push(`/channels/now/${this.channel.id}/watch`);
  }

  async startPlayingProgram() {
    if (this.isFuture) {
      return;
    }

    actions.tvChannels.hideChannelDetails(this.$store);
    actions.common.setShowNotificationWithDetails(this.$store, this.isDvrAvailable);

    if (!this.isPageChannelOpen && !this.redirectToPlayer) {
      if (this.currentChannelId === this.channel.id) {
        actions.tvCurrentChannel.selectProgram(this.$store, this.program);
        this.$events.emit(EVENTS.player.play);
        this.$events.emit(EVENTS.player.reloadStream);
      } else {
        await actions.tvChannels.selectChannel(
          this.$store,
          this.channel.id,
          this.$events,
          true,
          false,
          this.program.startMs
        );
        this.setPickedEpochDay(DateTime.toEpochDay(new Date(this.program.startMs || Date.now())));
      }
    } else {
      this.$store.player.video.playingTimeMs = this.program.startMs;
      await this.goToCatalogPage();
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/media-queries';

$transition: var(--ease-out) 0.25s;

.epg-element {
  &.live,
  &.current {
    .inner {
      padding: 16px 4px;
      margin-right: -16px;
      margin-left: -16px;
      border-radius: 4px;

      @include mobile {
        padding: 16px 8px;
      }
    }
  }

  &.live:last-child {
    .status.next {
      display: none;
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;

    &.now {
      padding-bottom: 8px;
      @include mobile-and-tablet {
        padding-bottom: 4px;
      }
    }

    &.next {
      padding-top: 16px;
      @include mobile-and-tablet {
        padding-top: 12px;
      }
    }

    .line {
      width: 100%;
      height: 1px;
    }

    .text {
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  .time-block {
    margin-bottom: 4px;

    @include mobile {
      display: flex;
      align-items: center;
    }

    .time {
      margin-right: 8px;
      @include heading3;
    }

    .badge {
      margin-right: 8px;
    }

    .button {
      margin-left: auto;
    }
  }

  .program-preview {
    height: fit-content;
    pointer-events: none;

    &.with-hover {
      pointer-events: initial;
    }
  }

  .title {
    margin-bottom: 12px;
    @include heading5;

    @include mobile {
      @include heading6;
    }
  }

  &::v-deep .circle {
    opacity: 1;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    &.future {
      color: var(--c-light-font-secondary);
    }

    &.current {
      .inner {
        background-color: var(--alpha-light-3);
      }
    }

    .status {
      .line {
        background-color: var(--alpha-light-3);
      }

      .text {
        color: var(--c-light-font-tertiary);
      }
    }

    .epg-details {
      .text-with-truncation {
        color: var(--c-light-font-secondary);
      }
    }
  }

  &.dark {
    &.future {
      color: var(--c-dark-font-secondary);
    }

    &.current {
      .inner {
        background-color: var(--alpha-dark-3);
      }
    }

    .status {
      .line {
        background-color: var(--alpha-dark-3);
      }

      .text {
        color: var(--c-dark-font-tertiary);
      }
    }

    .epg-details {
      .text-with-truncation {
        color: var(--c-dark-font-secondary);
      }
    }
  }
}
</style>
