<template>
  <div
    ref="menu-dropdown"
    v-click-outside="clickOutsideMenu"
    class="menu-dropdown"
    :class="[
      { 'is-mobile': isMobile, disabled, 'button-active': buttonActive, 'width-auto': widthMenu },
      theme,
    ]"
    :data-cy="dataCy ? `menu-dropdown-${dataCy}` : ''"
  >
    <slot
      :value="selectedItem || ''"
      :opened="openedMenu"
      :disabled="disabled"
      :toggle="toggle"
      :icon="selectedIcon"
    >
      <ButtonDropdown
        :theme-forced="themeForced"
        :placeholder="placeholder"
        :value="selectedItem || ''"
        :open="openedMenu"
        :disabled="disabled"
        :data-cy="dataCy"
        @click="toggle"
      />
    </slot>

    <DropdownContent
      v-if="openedMenu || (this.transformNativeSelect && this.isMobile)"
      ref="dropdownSequoiaContent"
      :style="{ width: widthMenu, transform: `translateX(${offsetX}px)` }"
      :action-menu-mobile-custom-view="actionMenuMobileCustomView"
      :is-mobile="isMobile"
      :transform-native-select="transformNativeSelect"
      :multiple="multiple"
      :max-height="maxHeight"
      :theme-forced="theme"
      @opened="showMenu"
      @closed="hideMenu"
      @select="select"
      @touchStart="buttonActive = true"
      @touchEnd="buttonActive = false"
    >
      <DropdownGroup
        v-for="(_, group) in groupedMenu"
        :key="group"
        :show-title="showGroupTitle && !!group"
        :title="showGroupTitle ? group.toString() : ''"
        :is-mobile="isMobile"
        :transform-native-select="transformNativeSelect"
        :grouped="grouped"
      >
        <div
          v-if="!groupedMenu[group].length && !transformNativeSelect"
          class="menu-item-container"
          data-cy="dropdown-menu-item-container"
          v-html="getTranslation('search_not_found')"
        />

        <DropdownItem
          v-for="item in groupedMenu[group]"
          :key="item.key"
          :selected="item.selected && !multiple"
          :disabled="item.disabled"
          :width-bg="!multiple"
          :icon="getItemIcon(item)"
          :icon-text="item.iconText"
          :has-icons="hasIcons || multiple"
          :is-mobile="isMobile"
          :transform-native-select="transformNativeSelect"
          :key-value="item.key"
          :theme-forced="theme"
          :value="item.value.toString()"
          @select="select(item)"
        />
      </DropdownGroup>
    </DropdownContent>

    <transition name="fade" mode="out-in">
      <div
        v-if="showOverlay"
        class="menu-dropdown-container-mobile-overlay"
        @click="clickOutsideMenu"
      />
    </transition>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import DropdownContent from 'src/components/ui/dropdown/DropdownContent.vue';
import DropdownItem from 'src/components/ui/dropdown/DropdownItem.vue';
import DropdownGroup from 'src/components/ui/dropdown/DropdownGroup.vue';
import { clickOutside } from 'src/utils/directives';
import IconSubmit from 'src/svg/submit.svg';
import groupBy from 'lodash/groupBy';
import { getDeviceFlags } from 'src/utils/platform-detector';
import { enToRu } from 'src/utils/language';
import { actions } from 'src/store/actions';

@Component({
  components: { DropdownGroup, DropdownContent, DropdownItem, ButtonDropdown },
  directives: { clickOutside },
})
export default class DropdownSequoia extends SequoiaComponent {
  IconSubmit = IconSubmit;

  menu: Array<TDropdownItem> = [];
  openedMenu = false;
  showOverlay = false;
  offsetX = 0;
  buttonWidth = 0;
  buttonActive = false;

  @Prop()
  list!: Array<TDropdownItem>;

  @Prop({ default: '' })
  placeholder?: string;

  @Prop({ default: false })
  showGroupTitle!: boolean;

  @Prop({ default: true })
  showSelectedItem?: boolean;

  @Prop({ default: false })
  transformNativeSelect!: boolean;

  @Prop({ default: false })
  actionMenuMobileCustomView!: boolean;

  @Prop({ default: false })
  actionMenu?: boolean;

  @Prop({ default: false })
  multiple!: boolean;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: 'auto' })
  width?: 'content' | 'width' | 'auto';

  @Prop()
  maxHeight?: number;

  @Prop({ default: 'left' })
  position?: 'left' | 'center' | 'right';

  @Prop({ default: false })
  grouped?: boolean;

  @Prop()
  dataCy?: string;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: '' })
  filter?: string;

  @Prop({ default: true })
  closeToggle?: boolean;

  @Ref('menu-dropdown')
  readonly refMenuDropdown?: HTMLDivElement;

  @Ref('dropdownSequoiaContent')
  readonly refDropdownSequoiaContent?: DropdownContent;

  @Watch('list')
  onListUpdate(list: TDropdownItem[]) {
    this.menu = list;
  }

  @Watch('openedMenu')
  onOpenedMenu(val: boolean) {
    if (val) {
      this.calcOffsetMenu();
      this.$emit('gaOpen');
    }
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get isMobile() {
    return getDeviceFlags().isMobile;
  }

  get widthMenu() {
    if (this.actionMenuMobileCustomView && this.isMobile && !this.transformNativeSelect) {
      return '100%';
    }

    if (this.width === 'auto') {
      return `${this.buttonWidth}px`;
    }

    return `${this.width}px`;
  }

  get selectedItem() {
    return this.menu
      .filter((item) => item.selected)
      .map((item) => item.value)
      .join(',');
  }

  get selectedIcon() {
    if (this.multiple) {
      return '';
    }

    return this.menu.filter((item) => item.selected)[0]?.icon;
  }

  get hasIcons() {
    return !!this.list.find((item) => item.icon);
  }

  get groupedMenu() {
    let menu = this.showSelectedItem ? this.menu : this.menu.filter((item) => !item.selected);

    if (this.filter) {
      menu = this.menu.map((item) => ({ ...item, selected: false }));
      menu = this.filterList(menu);
    }

    return this.grouped ? groupBy(menu, 'group') : { '': menu };
  }

  calcOffsetMenu() {
    if (!this.refMenuDropdown || (this.actionMenuMobileCustomView && this.isMobile)) {
      return;
    }

    const { width: buttonWidth, x: buttonX } = this.refMenuDropdown?.getBoundingClientRect();

    this.$nextTick(() => {
      this.buttonWidth = buttonWidth;
      const menuWidth = Number(this.refDropdownSequoiaContent?.$el.getBoundingClientRect().width);
      const diff = menuWidth - Number(buttonWidth);

      if (this.position === 'right') {
        this.offsetX = buttonX - diff < 0 ? 0 : -diff;
      }
      if (this.position === 'center') {
        const center = diff / 2;
        if (buttonX - center < 0) {
          this.offsetX = 0;
        } else if (buttonX + center > window.innerWidth) {
          this.offsetX = -diff;
        } else {
          this.offsetX = -(diff / 2);
        }
      }
      if (this.position === 'right' && buttonX + buttonWidth + diff > window.innerWidth) {
        this.offsetX = -diff;
      }
    });
  }

  filterList(list: TDropdownItem[]) {
    if (!this.filter) {
      return [];
    }

    return list
      .filter((item) => {
        return this.filter
          ?.toLowerCase()
          .split(' ')
          .every((v) => item.value.toLowerCase().includes(v));
      })
      .sort((a, b) => {
        const l = a?.value?.toLowerCase();
        const r = b?.value?.toLowerCase();

        if (this.filter?.toLowerCase()) {
          const locPredicate = enToRu(this.filter.toLowerCase());
          if (l.indexOf(this.filter.toLowerCase()) === 0 || l.indexOf(locPredicate) === 0) {
            return -1;
          }
          if (r.indexOf(this.filter.toLowerCase()) === 0 || r.indexOf(locPredicate) === 0) {
            return 1;
          }
        }

        if (l < r) {
          return -1;
        } else if (l > r) {
          return 1;
        } else {
          return 0;
        }
      });
  }

  getItemIcon(item: TDropdownItem) {
    if (this.multiple && item.selected) {
      return IconSubmit;
    }
    if (!this.multiple && this.hasIcons) {
      return item.icon;
    }

    return '';
  }

  toggle(resetCloseToggle = false) {
    if (this.disabled) {
      return;
    }

    if (this.openedMenu && !this.closeToggle && !resetCloseToggle) {
      this.openedMenu = true;
    } else {
      this.openedMenu = !this.openedMenu;
    }
  }

  clickOutsideMenu() {
    if (this.transformNativeSelect && this.isMobile) {
      this.openedMenu = false;
      return;
    }

    if (this.openedMenu) {
      this.openedMenu = false;
    }
  }

  select(menuItem: TDropdownItem | string) {
    if (!menuItem) {
      return;
    }

    const key = typeof menuItem === 'object' ? menuItem?.key : menuItem;

    this.menu = this.menu.map((item) => {
      let selected = false;

      if (Array.isArray(menuItem)) {
        selected = menuItem.includes(String(item.key));
      } else if (!this.actionMenu) {
        selected = String(key) === String(item.key);

        if (this.multiple) {
          selected = String(key) === String(item.key) ? !item.selected : item.selected;
        }
      }

      return {
        ...item,
        selected,
      };
    });

    if (!this.multiple) {
      this.openedMenu = false;
    }

    const returnItem =
      typeof menuItem === 'object'
        ? menuItem
        : this.menu.find((item) => String(item.key) === String(key));
    this.$emit('select', returnItem);
  }

  showMenu() {
    this.showOverlay = true;
    if (this.isMobile) {
      actions.common.lockBodyScroll(this.$store);
    }
    this.$emit('open');
  }

  hideMenu() {
    this.showOverlay = false;
    if (this.isMobile) {
      actions.common.unlockBodyScroll(this.$store);
    }
    this.$emit('close');
  }

  onResize() {
    this.clickOutsideMenu();
  }

  mounted() {
    this.menu = this.list;
    window.addEventListener('resize', this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    actions.common.unlockBodyScroll(this.$store);
  }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.menu-dropdown {
  position: relative;
  display: inline-flex;
  margin: 8px 0;

  &.width-auto {
    display: block;
  }

  &::v-deep .scroll-wrap::-webkit-scrollbar {
    width: 5px;
  }

  &.icons,
  &.multiple {
    .menu-item-container {
      padding: 8px 16px 8px 12px;
    }
  }

  &.is-mobile {
    position: relative;
  }

  .not-found-in-list {
    margin: 0.5rem 0;
  }
}

.menu-dropdown-container-mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-dropdown);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s var(--ease-out);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.menu-dropdown-container-mobile-enter,
.menu-dropdown-container-mobile-leave-to {
  transform: translateY(292px) !important;
}

.button-active {
  &::v-deep .button-dropdown .button-dropdown-inner .input-block .input {
    background-color: var(--alpha-light-5);
  }
}
</style>
