import { TStore } from 'src/store/types';
import { serverTimeMsSelector } from 'src/store/app-info/selectors';
import { translationSelector } from 'src/store/translations/selectors';
import { PLAYER_ERROR_CODES } from 'src/constants';

export const typeSelector = (store: TStore) => store.player.type;

export const shouldStartFromPauseSelector = (store: TStore) => store.player.shouldStartFromPause;

export const isDateTimeVisibleSelector = (store: TStore) => store.player.isDateTimeVisible;

export const wasPlayingBeforeOfflineSelector = (store: TStore) =>
  store.player.video.wasPlayingBeforeOffline;

export const isPlayingSelector = (store: TStore) => store.player.video.isPlaying;

export const isLiveSelector = (store: TStore) => store.player.isLive;

export const isStopSelector = (store: TStore) => isLiveSelector(store) && !isPlayingSelector(store);

export const isLoadingSelector = (store: TStore) => store.player.loading;

export const isLoadedSelector = (store: TStore) => store.player.loaded;

export const alertSelector = (store: TStore) => store.player.alert;

export const dvrWarningOverlaySelector = (store: TStore) => store.player.dvrWarningOverlay;

export const dvrWarningOverlayVisibleSelector = (store: TStore) =>
  store.player.dvrWarningOverlay?.isVisible;

export const errorSelector = (store: TStore) => {
  const err = store.player.error;
  // TODO: after refactoring getTranslation -> do not replace error message
  if (err && !err.message) {
    err.code = PLAYER_ERROR_CODES.COMMON;
    err.message = translationSelector(store, 'error_empty_response');
  }
  return err;
};

export const errorCodeSelector = (store: TStore) => errorSelector(store)?.code;

export const hasErrorSelector = (store: TStore) => !!errorSelector(store);

export const isFullscreenSelector = (store: TStore) => store.player.video.isFullscreen;

export const isHelpVisibleSelector = (store: TStore) => store.player.isHelpVisible;

export const hasVideoEndedSelector = (store: TStore) => store.player.hasVideoEnded;

export const isEpgInfoVisibleSelector = (store: TStore) => store.player.isEpgVisible;

export const isFullscreenMenuVisibleSelector = (store: TStore) =>
  isFullscreenSelector(store) && store.player.isFullscreenMenuVisible;

export const isSettingsMenuVisibleSelector = (store: TStore) => store.player.isSettingsMenuVisible;

export const isVolumeSliderVisibleSelector = (store: TStore) => store.player.volumeSlider.visible;

export const videoPosterSelector = (store: TStore) => store.player.video.poster;

export const subtitleDisplaySelector = (store: TStore) => store.player.video.subtitleDisplay;

export const hasFps50Selector = (store: TStore) => store.player.video.hasFps50;

export const fps50Selector = (store: TStore) => store.player.video.fps50;

export const hasSubtitlesSelector = (store: TStore) => store.player.video.hasSubtitles;

export const isVideoMutedSelector = (store: TStore) => store.player.video.muted;

export const isVideoScaledSelector = (store: TStore) => store.player.video.scaled;

export const videoVolumeSelector = (store: TStore) => store.player.video.volume;

export const startTimeMsSelector = (store: TStore) => store.player.video.startTimeMs;

export const playingTimeMsSelector = (store: TStore) => store.player.video.playingTimeMs;

export const playDurationMsSelector = (store: TStore) => {
  const now = serverTimeMsSelector(store);
  const startTime = startTimeMsSelector(store);
  return startTime ? now - startTime : 0;
};

export const isRewindingSelector = (store: TStore) => store.player.rewinding;

export const isFastForwardingSelector = (store: TStore) => store.player.fastForwarding;

export const isShowBalloonSelector = (store: TStore) => store.player.timeline.isShowBalloon;

export const overlayVisibleSelector = (store: TStore) => store.player.overlay.visible;

export const overlayFrozenSelector = (store: TStore) => store.player.overlay.frozen;

export const isOverlayVisibleSelector = (store: TStore) => {
  return (
    (overlayVisibleSelector(store) ||
      isOverlayFrozenSelector(store) ||
      isPlayerMinimizedSelector(store) ||
      isLoadingSelector(store)) &&
    isLoadedSelector(store)
  );
};

export const isOverlayFrozenSelector = (store: TStore) =>
  // TODO refactor these flags
  hasErrorSelector(store) ||
  !store.player.video.isPlaying ||
  store.player.overlay.frozen ||
  store.player.isFullscreenMenuVisible ||
  store.player.isEpgVisible ||
  store.player.isSettingsMenuVisible ||
  store.player.volumeSlider.visible ||
  store.player.isHelpVisible ||
  store.player.dvrWarningOverlay.isVisible ||
  store.player.rewinding ||
  store.player.fastForwarding;

// const canHideOverlay =
//   this.showSettings ||
//   this.inRewindMode ||
//   this.dvrErrorExpanded;

export const pickedEpochDaySelector = (store: TStore) => store.player.pickedEpochDay;

export const autoplaySelector = (store: TStore) => store.player.autoplay;

export const isTimelineBalloonFollowingMouseSelector = (store: TStore) =>
  store.player.timeline.isBalloonFollowingMouse;

export const isPlayerMinimizedSelector = (store: TStore) => store.player.minimized;

export const isPlayerVisibleSelector = (store: TStore) => store.player.visible;

export const isScrollLockedSelector = (store: TStore) => store.player.isScrollLocked;
