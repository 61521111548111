import { TStore } from 'src/store/types';
import { convertToMilliseconds, convertToSeconds } from 'src/utils/time/convert-time';
import * as api from 'src/api';

export const incrementServerTime = (store: TStore) => {
  if (store.appInfo?.currentTime) {
    const delta = Date.now() - store.common.localTime;

    if (delta < 0 || delta > convertToMilliseconds(10, 'minute')) {
      store.common.needServerTimeSync = true;
    }

    store.common.localTime += delta;
    store.appInfo.currentTime += Math.round(convertToSeconds(delta, 'millisecond'));
  }
};

export const updateServerTime = async (store: TStore) => {
  const appInfo = await api.appInfo.getAppInfo();
  if (appInfo.currentTime && appInfo.currentTime > 0) {
    store.appInfo = appInfo;
  }
};
