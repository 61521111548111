<template>
  <div class="mobile-menu-wrapper">
    <div class="mobile-menu">
      <div class="mobile-menu-header mb-16">
        <ButtonAction
          size="medium"
          theme-forced="dark"
          data-cy="close-menu"
          class="close-icon"
          :icon="IconCross"
          @click="$emit('closeMobileMenu')"
        />

        <component :is="$route.path === '/' ? 'span' : 'router-link'" to="/" class="logo">
          <img :src="logoUrl" alt="logo" />
        </component>

        <ButtonAction
          v-if="$route.name !== 'search'"
          to="/search"
          theme-forced="dark"
          data-cy="search"
          size="medium"
          class="mobile-search"
          :icon="IconSearch"
        />
      </div>

      <div v-if="isAnonymous" class="menu-buttons">
        <ButtonDefault
          view="tertiary"
          data-cy="header-auth"
          theme-forced="dark"
          @click.prevent="$emit('signIn')"
        >
          <IconSVG :svg="IconProfile" />
          <span v-html="getTranslation('enter')" />
        </ButtonDefault>

        <ButtonDefault
          v-if="$store.authAndReg.showRegistrationButton && !isWl"
          view="secondary"
          class="gift"
          data-cy="header-register"
          theme-forced="dark"
          :with-icon="true"
          @click.prevent="$emit('signUp')"
        >
          <IconSVG :svg="IconGift" />
          <span v-html="getTranslation('reg_button_text')" />
        </ButtonDefault>

        <ButtonDefault
          v-if="$store.authAndReg.showRegistrationButton && isWl"
          view="tertiary"
          theme-forced="dark"
          data-cy="header-register"
          @click.prevent="$emit('signUp')"
        >
          <span v-html="getTranslation('registration')" />
        </ButtonDefault>
      </div>

      <ProfileDropdown
        v-else
        ref="profileSelector"
        :is-desktop="false"
        @setProfileForChange="setProfileForChange"
        @setDropdownActionName="setDropdownActionName"
      />
      <router-link
        v-if="!personalSettings.hideProfileManagement && !isAnonymous"
        to="/personal/profiles"
        class="menu-item"
        v-html="getTranslation('profile_management')"
      />

      <div class="mt-16 mb-16 flex-wrap">
        <component
          v-for="item in menuItems"
          :is="isUrlExternal(item.link) ? 'a' : 'router-link'"
          :key="item.link"
          class="menu-item"
          :class="{ active: isMenuItemActive(item.link) }"
          :to="item.link"
          :href="item.link"
          :target="isUrlExternal(item.link) ? '_blank' : ''"
        >
          <span v-html="item.title" />
        </component>
      </div>

      <template v-if="!isAnonymous">
        <router-link
          v-if="hasActiveSubscriptions"
          to="/personal/subscriptions"
          class="menu-item"
          v-html="getTranslation('subscriptions')"
        />
        <router-link
          v-if="!personalSettings.hidePersonalData"
          to="/personal/data"
          class="menu-item"
          v-html="getTranslation('personal_data')"
        />
        <router-link
          v-if="!personalSettings.hideProfileManagement"
          to="/personal/profiles"
          class="menu-item"
          v-html="getTranslation('profile_management')"
        />
        <a
          href="#"
          class="logout menu-item"
          data-cy="header-button-logout"
          @click.prevent="$emit('logOut')"
          v-html="getTranslation('logout')"
        />
      </template>

      <LanguageDropdown :is-desktop="false" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import IconSVG from 'src/components/IconSVG.vue';
import IconProfile from 'src/svg/profile.svg';
import IconCross from 'src/svg/cross.svg';
import IconGift from 'src/svg/gift.svg';
import IconSearch from 'src/svg/search.svg';
import { SequoiaComponent } from 'src/mixins';
import { wlDetector } from 'src/utils';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import LanguageDropdown from 'src/components/header/LanguageDropdown.vue';
import ProfileDropdown from 'src/components/header/ProfileDropdown.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { isUrlExternal } from 'src/utils/url';
import { TProfile } from 'src/api/auth/types';
import {
  hasActiveSubscriptionsSelector,
  subscriptionsSelectorV2,
} from 'src/store/payment/selectors';

@Component({
  components: {
    IconSVG,
    ButtonAction,
    ButtonDefault,
    ProfileDropdown,
    LanguageDropdown,
  },
})
export default class MobileMenu extends SequoiaComponent {
  isUrlExternal = isUrlExternal;

  IconCross = IconCross;
  IconProfile = IconProfile;
  IconGift = IconGift;
  IconSearch = IconSearch;

  @Prop()
  menuItems!: Array<{ link: string; title: string }>;

  @Prop()
  logoUrl!: string;

  get isWl() {
    return !wlDetector().isSmotreshka;
  }

  get hasActiveSubscriptions() {
    return selectors.payment.hasActiveSubscriptionsSelector(this.$store);
  }

  get personalSettings() {
    return selectors.personal.personalSettingsSelector(this.$store);
  }

  setProfileForChange(profile: TProfile) {
    this.$emit('setProfileForChange', profile);
  }

  setDropdownActionName(name: string) {
    this.$emit('setDropdownActionName', name);
  }

  isMenuItemActive(link: string) {
    const exact = link === '/';
    return exact ? this.$route.path === link : this.$route.path.includes(link);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/components/header/dropdowns';

.mobile-menu-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  padding: 0 48px;
  overflow-y: scroll;
  background-color: var(--c-dark-300);

  a {
    color: var(--c-dark-font-primary);
  }

  .mobile-menu {
    width: 100%;
    height: 100%;
  }

  .flex-wrap {
    display: flex;
    flex-direction: column;
  }

  .mobile-menu-header {
    position: relative;
    display: flex;
    align-items: center;
    height: var(--header-height);
    padding: 16px 0;

    .close-icon {
      position: absolute;
      left: -16px;

      .icon {
        color: var(--alpha-dark-9);
      }
    }

    .mobile-search {
      position: absolute;
      right: -16px;

      .icon {
        color: var(--alpha-dark-9);
      }
    }

    .logo {
      flex: 1;

      img {
        display: block;
        max-width: 164px;
        height: 100%;
        max-height: 28px;
        margin: 0 auto;
      }
    }
  }

  @include mobile-and-tablet {
    display: block;
  }

  @include mobile {
    padding: 0 24px;
  }

  @include body1;

  .menu-buttons {
    display: flex;
    margin-bottom: 24px;

    @include mobile {
      flex-wrap: wrap;
      margin-bottom: 16px;
    }

    .button {
      width: 50%;
      margin-bottom: 0;

      &:last-child {
        margin-left: 16px;
      }

      @include mobile-and-tablet {
        height: 56px;
      }

      @include mobile {
        width: 100%;
        margin-left: 0;

        &:first-child {
          margin-bottom: 16px;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

  .menu-item {
    display: block;
    padding: 12px 16px;
    border-radius: 4px;
    transition: 0.15s var(--ease-out);

    .icon {
      margin-right: 12px;
    }

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-3);
      }
    }

    &:active {
      background-color: var(--alpha-dark-4);
    }

    &.active {
      color: var(--c-light-font-primary);
      background-color: var(--c-dark-brand);
    }
  }

  .profile-selector ::v-deep {
    .restrictions {
      justify-content: flex-start;
    }
  }

  .logout {
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: 16px;
    }
  }
}
</style>
