import { TChannelEnhanced } from 'src/api/channels/types';
import { cookieStore } from 'src/utils/storage';
import { TChannelsNotForPurchase, TTvChannelsList } from 'src/store/tv-channels/types';
import * as api from 'src/api/index';
import logger from 'src/utils/logger';

const log = logger('tv-channels');

export const filterChannels = (
  channels: TTvChannelsList,
  filterFunction: (acc: TTvChannelsList, key: string, channel: TChannelEnhanced) => TTvChannelsList
) => {
  return Object.entries(channels).reduce<TTvChannelsList>(
    (acc, [channelId, channel]) => filterFunction(acc, channelId, channel),
    {}
  );
};

export const filterChannelsByChannelsArray = (
  channels: TTvChannelsList,
  channelsToFilter: Array<TChannelEnhanced>
) => {
  return channelsToFilter.reduce<TTvChannelsList>((acc, recommendedChannel) => {
    const channel = channels[recommendedChannel.id];
    if (channel) {
      acc[channel.id] = channel;
    }
    return acc;
  }, {});
};

export const loadChannelsNotForPurchase = async (feCookie = cookieStore.get('feCookie')) => {
  if (!feCookie) {
    log.warning(`feCookie does not exist`);
  }

  if (feCookie) {
    const channelsNotForPurchaseStartTime = Date.now();
    // get channels not for purchase for authorized users
    const channelsNotForPurchaseResponse = await api.channels.getChannelsNotForPurchase(feCookie);
    const channelsNotForPurchaseArray =
      channelsNotForPurchaseResponse?.channelsNotForPurchase || [];
    const channelsNotForPurchase = channelsNotForPurchaseArray.reduce<TChannelsNotForPurchase>(
      (acc, channelId) => {
        acc[channelId] = channelId;
        return acc;
      },
      {}
    );
    log.info(`tvData [channelsNotForPurchase]: ${Date.now() - channelsNotForPurchaseStartTime}ms`);
    return channelsNotForPurchase;
  }
};
