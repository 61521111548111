<template>
  <div
    class="tabs-component"
    :class="componentName"
    :data-ctype="$options.name"
    :data-cname="componentName"
  >
    <div class="tabs" :class="tabsStyle">
      <ul>
        <li
          v-for="tab in tabs"
          :key="tab.tabName"
          class="tab"
          :class="activeTabName === tab.tabName ? 'active-tab' : ''"
        >
          <a @click.prevent="selectTab(tab.tabName)">
            <img v-if="tabsStyle === 'wide'" :src="tabImageUrl(tab)" alt="" />
            <span v-else v-html="tabTitle(tab)" />
          </a>
        </li>
      </ul>
    </div>

    <Tab
      v-show="activeTabName === tab.tabName && tab.content"
      v-for="tab in tabs"
      :key="tab.tabName"
      data-ctype="Tab"
      :data-cname="tab.tabName"
      :tabContent="tab.content"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

interface TTab {
  tabName: string;
  content?: {
    title?: TTranslations;
    image?: TTranslations<TImageCMS>;
  };
}

@Component
class Tab extends Vue {
  @Prop({ required: true })
  tabContent!: any;

  render(h: any): any {
    return h({
      template: this.tabContent ? this.tabContent?.tabContent : '<div />',
    });
  }
}

@Component({
  components: { Tab },
})
export default class TabPanel extends mixins(Global, CmsComponent) {
  activeTabName = '';

  get tabsStyle() {
    return this.content?.tabsStyle;
  }

  get tabs(): Array<TTab> {
    return this.content?.tabs || [];
  }

  async mounted() {
    if (this.$children.length) this.reloadTabs();
  }

  selectTab(tabName: string) {
    this.activeTabName = tabName;
  }

  reloadTabs() {
    const hash = this.$route.hash;
    this.activeTabName = hash.length ? hash.replace(/#/g, '') : this.tabs[0].tabName;
  }

  tabImageUrl(tab: TTab) {
    return this.getByLanguage(tab.content?.image)?.url || '';
  }

  tabTitle(tab: TTab) {
    return this.getByLanguage(tab.content?.title);
  }
}
</script>

<style lang="scss" scoped>
.tabs-component {
  .tabs {
    &.narrow {
      ul {
        li {
          padding: 0.625rem 0.875rem;

          a {
            padding: 8px;
          }
        }
      }
    }

    &.wide {
      .tab {
        &.active-tab {
          a {
            opacity: 1;
          }

          &::after {
            content: ' ';
            position: absolute;
            bottom: -9px;
            left: 50%;
            display: block;
            width: 14px;
            height: 14px;
            margin-left: -7px;
            transform: rotate(45deg);
          }
        }

        a {
          opacity: 0.2;
          transition: all 0.2s;
        }

        a:hover {
          opacity: 1;
        }
      }
    }

    ul {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      overflow: hidden;
      list-style: none;

      li {
        position: relative;

        a {
          display: inline-block;
          cursor: pointer;

          img {
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
