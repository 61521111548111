// pass duration for transitions in milliseconds
export const scrollToTop = (scrollElement: HTMLDivElement | Element, duration: number) => {
  // do not do anything in case scrollElement is absent, or we are already at the top
  if (!scrollElement || scrollElement?.scrollTop === 0) {
    return;
  }

  const cosParameter = scrollElement.scrollTop / 2;
  let scrollCount = 0;
  let oldTimestamp: number | null = null;

  function step(newTimestamp: number) {
    if (!scrollElement?.scrollTop) {
      return;
    }

    if (oldTimestamp !== null) {
      // if duration is 0 scrollCount will be Infinity
      scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;
      if (scrollCount >= Math.PI) {
        return (scrollElement.scrollTop = 0);
      }
      scrollElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
};
