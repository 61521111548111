import { TStore } from 'src/store/types';
import { convertToMilliseconds } from 'src/utils/time/convert-time';
import { boundMinMax, isOkNumber } from 'src/utils/number';
import {
  currentChannelIdSelector,
  currentChannelSelector,
} from 'src/store/tv-current-channel/selectors';
import { isDvrDepthEqualZero } from 'src/utils/channel';

export const isLoadingSelector = (store: TStore) => store.tvEpg.loading;

export const isLoadedSelector = (store: TStore) => store.tvEpg.loaded;

// ----------------------------------------------------------------------------
// DVR
// ----------------------------------------------------------------------------

export const isDvrRestrictedSelector = (store: TStore, channel = currentChannelSelector(store)) =>
  !!(channel && isDvrDepthEqualZero(channel));

/**
 * When channel's DVR is disabled entirely (though could be restricted)
 */
export const isDvrDisabledSelector = (store: TStore, channel = currentChannelSelector(store)) => {
  const channelId = channel?.id;

  if (!channelId) {
    // consider DVR is disabled when there is no channel ID
    return true;
  }

  const programs = store.tvEpg.list[channelId]?.programs || [];

  if (!programs.length) {
    // consider DVR is disabled as well when there are no programs at all
    return true;
  }

  return channel?.info?.playbackInfo?.dvrRestriction || false;
};

/**
 * When channel's DVR is NOT disabled and NOT restricted
 */
export const hasDvrSelector = (store: TStore, channel = currentChannelSelector(store)) =>
  !isDvrDisabledSelector(store, channel) && !isDvrRestrictedSelector(store, channel);
// ----------------------------------------------------------------------------

export const programsSelector = (
  store: TStore,
  selectedChannelId = currentChannelIdSelector(store)
) => {
  if (!selectedChannelId) {
    return [];
  }
  return store.tvEpg?.list?.[selectedChannelId]?.programs || [];
};

export const currentProgramIndexSelector = (store: TStore) => store.tvEpg.currentProgramIndex;

export const currentProgramSelector = (store: TStore, channelId?: string) => {
  const programs = programsSelector(store, channelId);
  const programIndex = channelId
    ? programIndexForChannelSelector(store, Date.now(), channelId)
    : currentProgramIndexSelector(store);

  if (!(programs && programIndex > -1 && programs[programIndex])) {
    return null;
  }

  return programs[programIndex];
};

export const nextProgramSelector = (store: TStore) => {
  const programs = programsSelector(store);
  const programIndex = currentProgramIndexSelector(store);
  if (!(programs && programIndex > -1 && programs[programIndex + 1])) {
    return null;
  }
  return programs[programIndex + 1];
};

export const dvrLeftBoundSelector = (store: TStore) => {
  const now = Date.now();
  const daysInWeek = 7;
  const weekAgo = now - convertToMilliseconds(daysInWeek, 'day');
  const programs = programsSelector(store);

  const firstProgramInEpgStartTime = convertToMilliseconds(
    programs[0]?.scheduleInfo?.start,
    'second'
  );

  return isOkNumber(firstProgramInEpgStartTime)
    ? firstProgramInEpgStartTime
    : isOkNumber(store.appInfo?.dvrAvailable)
    ? convertToMilliseconds(store.appInfo?.dvrAvailable, 'second')
    : weekAgo;
};

// all other programs for any given channel
export const programIndexForChannelSelector = (
  store: TStore,
  timeMs: number,
  channelId?: string
) => {
  const programs = programsSelector(store, channelId);

  if (!Array.isArray(programs) || !programs.length) {
    return -1;
  }
  return programs.findIndex((program) => timeMs >= program.startMs && timeMs < program.endMs);
};

export const programForChannelSelector = (data: {
  store: TStore;
  channelId?: string;
  isLive?: boolean;
  programIndex?: number;
  timeMs?: number;
}) => {
  // eslint-disable-next-line prefer-const
  let { store, channelId, isLive, programIndex, timeMs } = data;
  const programs = programsSelector(store, channelId);

  if (isLive && channelId) {
    programIndex = liveProgramIndexForChannelSelector(store, channelId);
  } else if (!isLive && channelId) {
    programIndex =
      typeof programIndex !== 'undefined'
        ? programIndex
        : typeof timeMs !== 'undefined'
        ? programIndexForChannelSelector(store, timeMs, channelId)
        : -1;
  }

  if (!(programs && programIndex && programIndex > -1 && programs[programIndex])) {
    return null;
  }

  return programs[programIndex];
};

export const programTimelinePositionForChannelSelector = (
  store: TStore,
  channelId: string,
  program = programForChannelSelector({ store, channelId, isLive: true }),
  timelinePositionMs?: number
) => {
  if (program) {
    const timeMs = (timelinePositionMs || Date.now()) - program.startMs;
    return boundMinMax(0, timeMs / (program.endMs - program.startMs), 1);
  }

  return 0;
};

export const liveProgramIndexForChannelSelector = (store: TStore, channelId: string) =>
  store.tvEpg.list[channelId]?.liveProgramIndex || -1;

export const nextProgramIndexForChannelSelector = (store: TStore, channelId: string) => {
  const index = store.tvEpg.list[channelId]?.liveProgramIndex;
  return index && index >= 0 ? index + 1 : -1;
};
