<template>
  <div v-if="genresString" class="genres title-details-info" :class="theme">
    <span v-if="showTranslation" class="left" :class="`color-${theme}-font-tertiary`">
      <span v-html="getTranslation('genre')" />
    </span>

    <span v-text="genresText" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component
export default class TitleGenres extends SequoiaComponent {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: false })
  showTranslation?: boolean;

  @Prop()
  genres?: Array<{ id: string; title: string }>;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get genresString() {
    return this.genres?.map((genre) => genre.title).join(', ');
  }

  get genresText() {
    return this.showTranslation ? this.genresString?.toLowerCase() : this.genresString;
  }
}
</script>
