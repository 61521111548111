var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.showAgeConfirmationOverlay)?_c('header',{class:[_vm.componentName, { dark: !_vm.isSanta }],attrs:{"data-ctype":_vm.$options.name,"data-cname":_vm.componentName}},[(_vm.isSanta || _vm.$store.secretSanta)?_c('FeSettings',{on:{"logOut":_vm.logOut}}):_vm._e(),_c('div',{staticClass:"wrapper container-1440",class:{ 'hide-menu': _vm.menuItems.length > 5 }},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutsideMenu),expression:"clickOutsideMenu"}],staticClass:"menu-dropdown-container",class:{ opened: _vm.burgerMenuVisible }},[_c('ButtonAction',{staticClass:"burger",class:{ pressed: _vm.burgerMenuVisible },attrs:{"theme-forced":"dark","data-cy":"burger","size":"medium","icon":_vm.IconBurger},on:{"click":function($event){return _vm.openMenu()}}}),(_vm.burgerMenuVisible)?_c('div',{staticClass:"menu-wrapper"},[_c('div',{staticClass:"menu-dropdown"},_vm._l((_vm.menuItems),function(item){return _c('LinkInternalOrExternal',{key:item.link,staticClass:"menu-item",class:[{ active: _vm.isMenuItemActive(item.link) }],attrs:{"link":item.link}},[_c('span',{domProps:{"innerHTML":_vm._s(item.title)}})])}),1)]):_vm._e()],1),(_vm.actionName !== 'MOBILE_MENU')?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.logoHasLink),expression:"!logoHasLink"}],staticClass:"logo"},[_c('img',{attrs:{"src":_vm.logoUrl,"alt":"logo"}})]),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.logoHasLink),expression:"logoHasLink"}],staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":_vm.logoUrl,"alt":"logo"}})])]:_vm._e(),_c('div',{staticClass:"menu body2"},_vm._l((_vm.menuItems),function(item){return _c('LinkInternalOrExternal',{key:item.link,staticClass:"menu-item-wrap flex-center-vertical",class:[
          { active: _vm.isMenuItemActive(item.link) },
          { 'pointer-events-none': _vm.isMenuItemActive(item.link) && _vm.$store.player.visible },
        ],attrs:{"link":item.link}},[_c('div',{staticClass:"menu-item",domProps:{"innerHTML":_vm._s(item.title)}})])}),1),_c('div',{staticClass:"right"},[(_vm.$route.name !== 'search')?[_c('div',{staticClass:"search body1"},[(!_vm.isAnonymous)?_c('InputText',{staticClass:"search-input",attrs:{"placeholder":_vm.getTranslation('what_to_watch'),"input-category":"default","theme-forced":"dark","size":"small","icon":"search","submit-on-enter":true},on:{"clickEnter":_vm.submitSearch,"submitSearch":_vm.submitSearch},model:{value:(_vm.$store.search.query),callback:function ($$v) {_vm.$set(_vm.$store.search, "query", $$v)},expression:"$store.search.query"}}):_vm._e(),_c('ButtonAction',{staticClass:"search-button",class:{ anonymous: _vm.isAnonymous },attrs:{"icon":_vm.IconSearch,"to":_vm.searchUrl,"theme-forced":"dark","size":"small","data-cy":"search"}})],1)]:_vm._e(),_c('div',{staticClass:"desktop-only"},[_c('LanguageDropdown'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAnonymous),expression:"isAnonymous"}],staticClass:"flex-center-vertical enter-buttons body2"},[_c('button',{staticClass:"action-button ml-16",attrs:{"type":"button","data-cy":"header-button-auth"},domProps:{"innerHTML":_vm._s(_vm.getTranslation('enter'))},on:{"click":_vm.signIn}}),(_vm.$store.authAndReg.showRegistrationButton && !_vm.isWl)?_c('ButtonDefault',{staticClass:"gift ml-16",attrs:{"view":"secondary","data-cy":"header-register","with-icon":true,"theme-forced":"dark"},on:{"click":function($event){_vm.signUp();
              _vm.gaEvent({
                category: 'header_personal_area',
                action: 'Клик  “30 дней бесплатно”',
              });}}},[_c('IconSVG',{attrs:{"svg":_vm.IconGift}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTranslation('reg_button_text'))}})],1):_vm._e(),(_vm.$store.authAndReg.showRegistrationButton && _vm.isWl)?_c('button',{staticClass:"action-button ml-16",attrs:{"type":"button","data-cy":"header-register"},domProps:{"innerHTML":_vm._s(_vm.getTranslation('registration'))},on:{"click":function($event){_vm.signUp();
              _vm.gaEvent({
                category: 'header_personal_area',
                action: 'Клик  “Регистрация”',
              });}}}):_vm._e()],1),_c('ProfileDropdown',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAnonymous),expression:"!isAnonymous"}],ref:"profileSelector",on:{"setProfileForChange":_vm.setProfileForChange,"setDropdownActionName":_vm.setDropdownActionName}})],1)],2)],2),(_vm.actionName === 'MOBILE_MENU')?_c('MobileMenu',{attrs:{"logo-url":_vm.logoUrl,"menu-items":_vm.menuItems},on:{"closeMobileMenu":_vm.closeMobileMenu,"signIn":_vm.signIn,"signUp":_vm.signUp,"logOut":_vm.logOut,"setProfileForChange":_vm.setProfileForChange,"setDropdownActionName":_vm.setDropdownActionName,"isMenuItemActive":_vm.isMenuItemActive}}):_vm._e(),_c('portal',{attrs:{"to":"modal"}},[(_vm.isModalOpen && _vm.actionName === _vm.PROFILE_ACTIONS.CHANGE)?_c('ModalSequoia',{attrs:{"type":"fixed","size":"small"},on:{"close":function($event){_vm.actionName = ''}}},[_c('ProfileChange',{staticClass:"with-side-and-bottom-padding",attrs:{"action-name":_vm.PROFILE_ACTIONS.CHANGE,"profile":_vm.profileForChange}})],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }