<template>
  <CmsLayout>
    <template v-slot="{ refScrollWrap }">
      <WrapperForCatalogAndPlayer
        player-type="Archive"
        :ref-scroll-wrap="refScrollWrap"
        @closeModalTitle="initMeta"
      />
    </template>
  </CmsLayout>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import WrapperForCatalogAndPlayer from 'src/components/player/WrapperForCatalogAndPlayer.vue';
import Global from 'src/mixins/Global';
import Seo from 'src/mixins/Seo';
import Vod from 'src/mixins/Vod';
import { TSeoMeta } from 'src/store/seo/types';
import { transliterate } from 'src/utils/language';
import logger from 'src/utils/logger';
import CmsLayout from 'src/layouts/CmsLayout.vue';

const log = logger('archive-page');

@Component({
  components: {
    CmsLayout,
    WrapperForCatalogAndPlayer,
  },
})
export default class ArchiveCatalogPage extends mixins(Global, Vod, Seo) {
  @Watch('$store.vod.videoData.channelId')
  async onPlayingChannelIdChange(id: string) {
    log.info(`videoData.channelId has changed to '${id}'`);
    await actions.QS.handleForTv(this.$store, this.currentChannel, false);
  }

  get currentChannel() {
    return this.getChannelById(this.currentChannelId);
  }

  get currentChannelId() {
    return selectors.vod.currentChannelIdSelector(this.$store);
  }

  getMetaInfo() {
    const vodTitle = this.$store.vod.sources['archive']?.fullTitles[this.titleIdFromParams];
    const title = vodTitle?.preview?.title || '';

    const typeSingular = vodTitle?.preview?.categories?.[0]
      ? `(${this.getTranslation(
          `category_${transliterate(vodTitle?.preview?.categories?.[0]?.title || '')}_singular`
        )})`
      : '';
    const typeAccusative = vodTitle?.preview?.categories?.[0]
      ? this.getTranslation(
          `category_${transliterate(vodTitle?.preview?.categories?.[0]?.title || '')}_accusative`
        )
      : '';

    const prefix = this.$route.path === '/cinema' ? 'cinema' : 'archive';

    const description = this.getTranslation(`${prefix}_meta_description`)
      .replace(/%titleType%/g, typeAccusative)
      .replace(/%titleName%/g, title)
      .replace(
        /%titleGenres%/g,
        vodTitle?.preview?.genres?.map((item) => item.title).join(', ') || ''
      );

    const metaTitle = vodTitle?.preview
      ? this.getTranslation(`${prefix}_meta_title`)
          .replace(/%titleName%/g, title)
          .replace(/%titleType%/g, typeSingular)
      : this.getTranslation(`${prefix}_catalog_meta_title`);

    const meta: TSeoMeta[] = [
      {
        name: 'description',
        content: vodTitle?.preview
          ? description
          : this.getTranslation(`${prefix}_catalog_meta_description`),
      },
      {
        name: 'keywords',
        content: vodTitle?.preview
          ? this.getTranslation(`${prefix}_meta_keywords`)
              .replace(/%titleName%/g, title)
              .replace(/%titleType%/g, typeSingular)
          : this.getTranslation(`${prefix}_catalog_meta_keywords`),
      },
      {
        name: 'og:description',
        content: vodTitle?.preview
          ? description
          : this.getTranslation(`${prefix}_catalog_meta_description`),
      },
      {
        name: 'og:title',
        content: metaTitle,
      },
    ];

    const ogImage = vodTitle ? vodTitle?.preview?.posters?.[0]?.path : this.getDefaultMetaOgImage();

    if (ogImage) {
      meta.push({
        name: 'og:image',
        content: ogImage,
      });
    }

    return {
      title: metaTitle,
      meta,
    };
  }

  get seoBreadcrumbs() {
    return [
      { name: this.getTranslation('breadcrumb_smotreshka'), link: '/' },
      { name: this.getTranslation('breadcrumb_archive'), link: '/archive' },
    ];
  }

  async serverPrefetch() {
    const { titleId } = this.$route.params;
    if (this.$store.vod.sources.archive && titleId) {
      await actions.vod.loadTitleVod(this.$store, 'archive', titleId);
      this.initMeta();
    }
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }
}
</script>
