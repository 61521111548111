<template>
  <ModalSequoia
    v-if="showWelcomeScreen && $route.name !== 'qr-payment'"
    class="welcome-screen-modal"
    :class="theme"
    size="medium"
    type="fixed"
    :with-blur="true"
    :with-button-close="false"
    :show-title="false"
    :with-fixed-header="false"
  >
    <div class="welcome-background">
      <div class="welcome-screen-content pb-32 pt-32">
        <transition name="fade-ease-out-default" mode="out-in">
          <component :is="currentStep" @changeStep="changeStep" @close="close" />
        </transition>
      </div>
    </div>
  </ModalSequoia>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import WelcomeStep from 'src/components/welcome-screen/steps/WelcomeStep.vue';
import SetPinStep from 'src/components/welcome-screen/steps/SetPinStep.vue';
import EnterPinStep from 'src/components/welcome-screen/steps/EnterPinStep.vue';
import VerifyStep from 'src/components/welcome-screen/steps/VerificationStep.vue';
import WithJwtStep from 'src/components/welcome-screen/steps/WithJwtStep.vue';

@Component({
  components: { ModalSequoia, WelcomeStep, SetPinStep, EnterPinStep, VerifyStep, WithJwtStep },
})
export default class WelcomeScreenModal extends SequoiaComponent {
  step = 'Welcome';

  get currentStep() {
    return `${this.step}Step`;
  }

  created() {
    this.step = this.$store.common.showJwtStepOnWelcomeScreen ? 'WithJwt' : this.step;
  }

  changeStep(step: string) {
    this.step = step;
  }

  close(nextStep = '') {
    this.$store.common.showWelcomeScreen = false;
    setTimeout(() => {
      if (nextStep === 'selectProfile') {
        if (this.$store.siteConfig?.ageAndProfileConfirmation.showProfileConfirmation) {
          this.$store.common.showProfileConfirmationOverlay = true;
        }
      }
    }, 200);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.welcome-screen-modal {
  .welcome-screen-content {
    position: relative;
    z-index: 2;
    padding: 56px 48px 32px;
    background-color: var(--c-light-150);
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);

    @include mobile {
      padding: 24px;
    }
  }

  &::v-deep {
    @include mobile {
      .backdrop {
        top: 0;
      }
    }

    &.modal {
      .welcome-background {
        padding-top: 88px;
        background: linear-gradient(206.92deg, #3d7ddc 3.61%, #f09 83.16%);
        border-radius: 16px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);

        @include mobile {
          padding-top: 48px;
        }

        &::before {
          content: '';
          position: absolute;
          top: -90px;
          left: 0;
          z-index: 2;
          display: block;
          width: 100%;
          height: 200px;
          background: url('/public/images/icons/welcome-screen-lock.svg') no-repeat center center;
          background-size: 30%;

          @include mobile {
            height: 170px;
            background-size: 130px;
          }
        }
      }

      .dialog-wrapper {
        .dialog {
          padding-top: 0;
          overflow: visible;
          border-radius: 16px;

          @include mobile {
            margin-top: 50px;
          }

          .title {
            margin-bottom: 0;
            border-radius: 16px 16px 0 0;
          }
        }
      }
    }

    .button.default {
      @include mobile-and-tablet {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }

  &.dark {
    .welcome-screen-content {
      background-color: var(--c-dark-150);
    }
  }
}
</style>
