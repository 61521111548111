<template>
  <CmsLayout v-if="isSourceAvailable || !isAnonymous">
    <template v-slot="{ refScrollWrap }">
      <WrapperForCatalogAndPlayer :key="key" player-type="Vod" :ref-scroll-wrap="refScrollWrap" />
    </template>
  </CmsLayout>

  <Error404Page v-else-if="!isSourceAvailable && isAnonymous" />
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import WrapperForCatalogAndPlayer from 'src/components/player/WrapperForCatalogAndPlayer.vue';
import Global from 'src/mixins/Global';
import Vod from 'src/mixins/Vod';
import Seo from 'src/mixins/Seo';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { TSeoMeta } from 'src/store/seo/types';
import { Watch } from 'vue-property-decorator';
import { transliterate } from 'src/utils/language';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import Error404Page from 'src/pages/error/Error404Page.vue';

@Component({
  components: {
    Error404Page,
    CmsLayout,
    WrapperForCatalogAndPlayer,
  },
})
export default class VodCatalogPage extends mixins(Global, Vod, Seo) {
  key = 0;

  @Watch('$route')
  onRouteChange() {
    this.key++;
  }

  get seoBreadcrumbs() {
    return [
      { name: this.getTranslation('breadcrumb_smotreshka'), link: '/' },
      { name: this.getTranslation('breadcrumb_cinemas'), link: '/vod' },
      { name: this.getTranslation(this.sourceId), link: `/vod/${this.sourceId}` },
    ];
  }

  get isSourceAvailable() {
    return !!this.$store.vod.sources[this.sourceId];
  }

  async serverPrefetch() {
    const { sourceId, titleId } = this.$route.params;

    if (!selectors.vod.listOfSourceIdsWithoutArchiveSelector(this.$store).includes(sourceId)) {
      return;
    }

    actions.common.showFooter(this.$store);

    if (this.$store.vod.sources[sourceId] && sourceId && titleId) {
      await actions.vod.loadTitleVod(this.$store, sourceId, titleId);
      this.initMeta();
    }
  }

  getMetaInfo() {
    let meta: TSeoMeta[];
    let title: string = this.getTranslation('vod_catalog_meta_title').replace(
      /%vodName%/g,
      this.source?.customTitle[this.languageCode]
    );
    const vodTitle = this.source?.fullTitles?.[this.titleIdFromParams];
    const type = vodTitle?.preview?.categories?.[0]
      ? `(${this.getTranslation(
          `category_${transliterate(vodTitle?.preview?.categories?.[0]?.title || '')}_singular`
        )})`
      : '';

    if (vodTitle) {
      const image =
        vodTitle?.preview?.additionalImages?.find(
          (img: { originalType: string }) => img.originalType === 'fullscreen'
        )?.path || vodTitle?.preview?.posters?.[0]?.path;

      const year = this.title?.preview?.years?.[0];
      const titleDescriptionFirstSentence = this.title?.details?.description.split('. ')[0];

      title = this.getTranslation('vod_meta_title')
        .replace(/%titleName%/g, title)
        .replace(/%titleType%/g, type)
        .replace(/%year%/g, year ? `(${year})` : '')
        .replace(/%vodName%/g, this.source?.customTitle[this.languageCode]);

      const metaDescription = this.getTranslation('vod_meta_description')
        .replace(/%titleType%/g, type)
        .replace(/%titleName%/g, title)
        .replace(/%first_sentence%/g, titleDescriptionFirstSentence || '')
        .replace(/%vodName%/g, this.source?.customTitle[this.languageCode]);

      meta = [
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: this.getTranslation('vod_meta_keywords')
            .replace(/%titleName%/g, title)
            .replace(/%titleType%/g, type),
        },
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:description',
          content: metaDescription,
        },
        {
          name: 'og:image',
          content: image,
        },
      ];
    } else {
      const metaDescription = this.getTranslation('vod_catalog_meta_description').replace(
        /%vodName%/g,
        this.source?.customTitle[this.languageCode]
      );

      meta = [
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: this.getTranslation('vod_catalog_meta_keywords').replace(
            /%vodName%/g,
            this.source?.customTitle[this.languageCode]
          ),
        },
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:description',
          content: metaDescription,
        },
        {
          name: 'og:image',
          content: this.getDefaultMetaOgImage(),
        },
      ];
    }

    return { title, meta };
  }
}
</script>
