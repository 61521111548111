<template>
  <div v-if="channels.length" class="channels-slider overflow-hidden">
    <SectionHeader
      class="with-side-padding"
      :text="getTranslation(`${type}_channels`)"
      :icon="icon"
    />
    <ChannelsStrip
      class="pb-24"
      :channels="channels"
      :show-slider-only="true"
      :highlight-visible="true"
      :data-cy="dataCyPrefixed"
      @gaFavorite="gaFavorite"
      @gaClick="gaClick"
      @gaScroll="gaScroll"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import ChannelsStrip from 'src/components/home/ChannelsStrip.vue';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import IconBookmarkOn from 'src/svg/bookmark-on.svg';
import IconStar from 'src/svg/star.svg';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { ChannelsStrip, SectionHeader },
})
export default class ChannelsSlider extends SequoiaComponent {
  @Prop({ default: 'favorite' })
  type!: 'favorite' | 'recommended';

  get icon() {
    return this.type === 'favorite' ? IconBookmarkOn : IconStar;
  }

  get channels() {
    const channels = this.type === 'favorite' ? this.favorites : this.recommnended;
    return Object.values(channels);
  }

  get dataCyPrefixed() {
    return `${this.type}-channels`;
  }

  get favorites() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  get recommnended() {
    return selectors.tvChannels.recommendedSelector(this.$store);
  }

  gaFavorite(id: string | undefined) {
    this.gaEvent({
      category: 'channels',
      action: `${this.type}_channels, ${this.type}`,
      channel_name: id,
    });
  }

  gaClick(id: string | undefined) {
    this.gaEvent({
      category: 'channels',
      action: `${this.type}_channels, click`,
      channel_name: id,
    });
  }

  gaScroll() {
    this.gaEvent({ category: 'channels', action: `${this.type}_channels, scroll` });
  }
}
</script>
