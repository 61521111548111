<template>
  <div v-if="ratingKinopoiskFormatted || ratingImdbFormatted" class="rating">
    <div v-if="ratingKinopoiskFormatted" class="title-details-info-with-icon">
      <ImageWithHash
        class="icon"
        src="/public/images/icons/kinopoisk.svg"
        alt="Kinopoisk"
        :title="getTranslation('kinopoisk')"
      />
      <span v-text="ratingKinopoiskFormatted" />
    </div>
    <div v-if="ratingImdbFormatted" class="title-details-info-with-icon">
      <ImageWithHash class="icon" src="/public/images/icons/imdb.svg" title="IMDb" alt="IMDb" />
      <span v-text="ratingImdbFormatted" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';
@Component({
  components: { ImageWithHash },
})
export default class TitleRating extends SequoiaComponent {
  @Prop()
  ratingKinopoiskFormatted?: string;

  @Prop()
  ratingImdbFormatted?: string;
}
</script>

<style lang="scss">
@import 'src/styles/common/title-details-info-with-icon';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.rating {
  flex-shrink: 0;
  height: 24px;

  > .title-details-info-with-icon {
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 32px;
    }

    img {
      margin-right: 0;
    }

    @include mobile {
      flex-direction: row;

      &:not(:last-child) {
        margin-right: 20px;
      }

      img {
        margin-right: 8px;
      }
    }
  }
}
</style>
