import { TStore } from 'src/store/types';
import { VODHomeCollectionEnhanced } from 'src/store/vod/types';
import { TMediaItem } from 'src/store/archive/types';
import { TVODTitleEnhanced } from 'src/api/vod/types';

export const sourcesSelector = (store: TStore) => store.vod.sources;

export const sourcesWithoutArchiveSelector = (store: TStore): Record<any, any> => {
  if (store.vod.sources) {
    return Object.values(store.vod.sources)
      .filter((source) => source.id !== 'archive')
      .reduce((a, source) => ({ ...a, [source.id]: source }), {});
  } else {
    return {};
  }
};

// [amedia, ivi, start, moretv, premier, megogo, viju] etc.
export const listOfSourceIdsWithoutArchiveSelector = (store: TStore) => {
  if (store.vod.sources) {
    const sourcesUsed = Object.values(store.vod.sources).filter((source) => source.isUsed);
    return sourcesUsed.map((source) => source.id);
  }
  return [];
};

export const listOfAllSourceIdsSelector = (store: TStore) => {
  if (store.vod.sources) {
    return Object.values(store.vod.sources).map((source) => source.id);
  }
  return [];
};

export const sourcesLoadedSelector = (store: TStore) => store.vod.states.sourcesLoaded;

export const sourcesLoadingSelector = (store: TStore) => store.vod.states.sourcesLoading;

export const sourceCategoriesLoadedSelector = (store: TStore, sourceId: string) =>
  store.vod.sources?.[sourceId]?.categoriesLoaded;

export const sourceCategoriesLoadingSelector = (store: TStore, sourceId: string) =>
  store.vod.sources?.[sourceId]?.categoriesLoading;

export const isSavedPause = (store: TStore) => store.vod?.isSavedPause;

// ---------------------------------------------------------------------------------------
// Collections
// ---------------------------------------------------------------------------------------

export const collectionTitlesLoadingSelector = (store: TStore) =>
  store.vod.states.collectionTitlesLoading;

export const shouldLoadMoreTitlesInCollectionSelector = (store: TStore) =>
  store.vod.states.shouldLoadMoreTitlesInCollection;

export const showVodCollectionsSelector = (store: TStore) =>
  store.siteConfig?.homescreen?.showVodCollections;

export const collectionsSelector = (store: TStore) => store.vod.collections || [];

export const collectionsFromSourceSelector = (store: TStore) =>
  store.vod.collectionsFromSource || [];

export const currentCollectionIdSelector = (store: TStore) => store.vod.currentCollectionId;

export const currentCollectionSelector = (store: TStore) =>
  collectionsSelector(store)?.find(
    (collection: VODHomeCollectionEnhanced) => collection.id === currentCollectionIdSelector(store)
  ) ||
  collectionsFromSourceSelector(store)?.find(
    (collection: VODHomeCollectionEnhanced) => collection.id === currentCollectionIdSelector(store)
  );

// ---------------------------------------------------------------------------------------

export const sortingSelector = (store: TStore) => store.vod.sorting;

export const posterWidthProportionSelector = (store: TStore, source: string) =>
  source === 'archive'
    ? store.siteConfig?.archiveCatalog?.posterProportions?.width || 16 // do not use archivePosterHeightProportionSelector to avoid curcular dependency
    : store.vod.sources[source]?.posterProportions?.width || 2;

export const posterHeightProportionSelector = (store: TStore, source: string) =>
  source === 'archive'
    ? store.siteConfig?.archiveCatalog?.posterProportions?.height || 9 // do not use archivePosterHeightProportionSelector to avoid curcular dependency
    : store.vod.sources[source]?.posterProportions?.height || 3;

export const currentCategoryIdSelector = (store: TStore, sourceId: string) =>
  store.vod?.sources?.[sourceId]?.currentCategoryId || '';

export const currentCategorySelector = (store: TStore, sourceId: string) => {
  const currentCategoryId = currentCategoryIdSelector(store, sourceId);
  return currentCategoryId ? store.vod?.sources?.[sourceId]?.categories?.[currentCategoryId] : {};
};

export const isPageTitleOpenSelector = (store: TStore) => store.vod.isPageTitleOpen;

export const isTitleLoadingSelector = (store: TStore) => store.vod.isTitleLoading;

// -------------------------------------------------------------------------------------
// Modal
// -------------------------------------------------------------------------------------
export const modalTitleIdsHistorySelector = (store: TStore) => store.vod.modalTitleIdsHistory;

export const isModalTitleOpenSelector = (store: TStore) => store.vod.isModalTitleOpen;

export const modalTitleSelector = (store: TStore, sourceId: string) =>
  store.vod.sources[sourceId]?.fullTitles?.[store.vod.modalTitleId];

export const modalTitleIdSelector = (store: TStore) => store.vod.modalTitleId;

// -------------------------------------------------------------------------------------
// Playing + Next Title / Episode
// -------------------------------------------------------------------------------------

export const playingTitleIdSelector = (store: TStore, sourceId = 'archive') =>
  playingTitleSelector(store, sourceId)?.preview?.id || store.vod?.videoData?.titleId || '';

export const playingEpisodeWithoutSeasonIndexSelector = (
  store: TStore,
  sourceId = 'archive',
  title?: TVODTitleEnhanced
) => {
  const _title = title || playingTitleSelector(store, sourceId);

  return _title.episodes?.findIndex((e) => store.vod.seriesData.episodeId === e?.preview?.id) || 0;
};

export const nextEpisodeSelector = (store: TStore, sourceId: string, title?: TVODTitleEnhanced) => {
  const _title = title || playingTitleSelector(store, sourceId);

  const nextEpisodeNum = nextEpisodeNumSelector(store);
  const nextSeasonNum = nextSeasonNumSelector(store);

  // for episodes with seasons
  if (_title?.seasons && _title?.seasons.length > 0) {
    if (
      nextEpisodeNum !== undefined &&
      nextEpisodeNum >= 0 &&
      nextSeasonNum !== undefined &&
      nextSeasonNum >= 0
    ) {
      const nextSeason = _title?.seasons?.[nextSeasonNum];
      return nextSeason?.episodes[nextEpisodeNum];
    }
  } else if (_title?.episodes?.length) {
    // for episodes without seasons
    return _title?.episodes?.[
      playingEpisodeWithoutSeasonIndexSelector(store, sourceId, _title) + 1
    ];
  }
};

// -------------------------------------------------------------------------------------
// Video Data
// -------------------------------------------------------------------------------------
export const videoDataSelector = (store: TStore) => store.vod.videoData;

export const currentChannelIdSelector = (store: TStore) => {
  return store.vod.videoData?.channelId;
};

export const playingCurrentTimeSelector = (store: TStore) => store.vod.videoData?.currentTime || 0;

export const durationSelector = (store: TStore) => store.vod.videoData?.duration || 1;

export const playingEpisodeIdSelector = (store: TStore) => store.vod.videoData?.episodeId || '';

export const playingMediaItemIdSelector = (store: TStore) => store.vod.videoData?.mediaItemId || '';

export const playingMediaItemSelector = (store: TStore, mediaItems?: Array<TMediaItem>) => {
  if (!mediaItems) {
    return;
  }
  if (store.vod.videoData?.mediaItemId) {
    return (
      mediaItems.find((mediaItem) => mediaItem.id === playingMediaItemIdSelector(store)) ||
      mediaItems[0]
    );
  }
  return mediaItems[0];
};

export const playingSeasonIdSelector = (store: TStore) => store.vod.videoData?.seasonId || '';

export const playingTitleSelector = (store: TStore, sourceId = 'archive') =>
  store.vod.sources?.[sourceId]?.fullTitles?.[store.vod.videoData?.titleId];

export const playingTickOffsetSelector = (store: TStore) => store.vod.videoData?.ticksOffset;

export const playingUrlSelector = (store: TStore) => store.vod.videoData?.url || '';

export const playbackMethodSelector = (store: TStore) => store.vod.videoData?.playbackMethod;

// ----------------------------------------------------------------------------------------------
// Series Data
// ----------------------------------------------------------------------------------------------
export const currentEpisodeNumSelector = (store: TStore) => store.vod.seriesData?.episodeNum;

export const nextEpisodeNumSelector = (store: TStore) => store.vod.seriesData?.nextEpisodeNum;

export const currentSeasonNumSelector = (store: TStore) => store.vod.seriesData?.seasonNum;

export const nextSeasonNumSelector = (store: TStore) => store.vod.seriesData?.nextSeasonNum;

export const currentSeasonNumForNavSelector = (store: TStore) =>
  store.vod.seriesData?.seasonNumForNav;

export const currentEpisodeIdSelector = (store: TStore) => store.vod.seriesData?.episodeId || '';

export const currentMediaItemIdSelector = (store: TStore) =>
  store.vod.seriesData?.mediaItemId || '';

export const currentSeasonIdSelector = (store: TStore) => store.vod.seriesData?.seasonId || '';
