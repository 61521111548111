import { TVod } from './types';

const initialState: TVod = {
  collections: [],
  collectionsFromSource: [],
  currentCollectionId: '',
  errors: {
    sourceWasNotFound: null,
  },
  isTitleLoading: false,
  mediaItem: {
    title: '',
    description: '',
    isHD: false,
    source: '',
    playbackMethods: [],
    id: '',
    purchaseCheckMethods: [],
    expiresAt: 0,
    channelId: '',
    broadcastStartsAt: '',
  },

  searchQuery: '',
  sources: {},
  sourcesFe: [],
  sourcesBe: [],

  seriesData: {
    episodeId: '',
    episodeNum: 0,
    mediaItemId: '',
    nextEpisodeNum: 1,
    nextSeasonNum: 1,
    seasonId: '',
    seasonNum: 0,
    seasonNumForNav: 0,
  },

  sorting: '',

  videoData: {
    channelId: '',
    currentTime: 0,
    duration: 0,
    episodeId: '',
    iviContentInfo: undefined,
    mediaItemId: '',
    playbackMethod: '',
    playbackSession: undefined,
    seasonId: '',
    sourceId: '',
    ticksOffset: 0.21,
    title: null,
    titleId: '',
    url: undefined,
  },

  // shared data with Archive
  isModalTitleOpen: false,
  isPageTitleOpen: false,
  modalTitleId: '',
  modalTitleIdsHistory: [],
  states: {
    shouldLoadMoreTitlesInCollection: false,
    collectionTitlesLoading: false,
    sourcesLoading: false,
    sourcesLoaded: false,
    recommendationsLoaded: false,
    collectionsLoaded: false,
  },
  isSavedPause: false,
};

export default initialState;
