import * as api from 'src/api';
import { TPlaybackSessionContext } from 'src/store/vod/types';
import * as arrayUtils from 'src/utils/array';

import {
  VOD_SESSION_LIFETIME,
  VOD_SESSION_STATE,
  VIDEO_DISPLAY_MODE,
  VOD_SESSION_USER_EVENT,
  HTTP_CODES,
  VOD_SESSION_QUEUE_LIMIT,
  VOD_SESSION_SEEK_EVENTS_TIMEOUT,
} from 'src/constants';
import { TPlaybackSessionContextRequest } from 'src/api/vod/types';

interface TFieldsRules {
  updateInSec: number;
  expireInSec: number;
}

interface EventData {
  destroy: boolean;
  loadingNewState: boolean;
  displayMode: string;
}

/*interface TPlaybackSessionContextRequest {
  pbsContextToken: string;
  currentState: string;
  previousState?: string;
  loadingNewState?: boolean;
  currentPositionSec: number;
  forwardSeekSec?: number;
  backwardSeekSec?: number;
  playedTimeSec?: number;
  totalTimeSec: number;
  videoDisplayMode: string;
  playErrorsCnt?: number;
  bufferingTimeSec?: number;
}*/

interface TPlaybackPrepare {
  requestData: TPlaybackSessionContextRequest;
  eventType: string;
  close_pbs: boolean;
}

const defaultPlaybackSessionContext = {
  pbsId: '',
  createdAt: '',
  nowTimestamp: '',
  updateInSec: 0,
  expireInSec: 0,
  pbsContextToken: '',
};

const VALIDATE_RULES = {
  updateInSec: {
    min: 0.1,
    max: 600,
    check(value: number) {
      return value >= this.min && value <= this.max;
    },
  },
  expireInSec: {
    min: 0.1,
    max: 86400,
    check(value: number) {
      return value >= this.min && value <= this.max;
    },
  },
};

export class VodPlayback {
  private queue: TPlaybackPrepare[] = [];
  private continueTimeoutId: NodeJS.Timeout | undefined;
  private pending = false;
  private playbackSessionContext: TPlaybackSessionContext = defaultPlaybackSessionContext;

  private readonly video: HTMLVideoElement | undefined;
  public titleId: string | undefined;
  public episodeId: string | undefined;

  public playbackSessionTimeStart = Date.now();
  public playErrorsCnt = 0;
  private destroy = false;
  private previousState = VOD_SESSION_STATE.stopped;
  private totalTimeSec = 0;
  private previousCurrentPosition = 0;

  public bufferTimerStarted = false;
  private bufferTimeStart = 0;
  private bufferIntervals: number[] = [];
  private playIntervals: number[] = [];

  private positionTimeStart = 0;
  private isPaused = true;
  private displayMode = VIDEO_DISPLAY_MODE.windowed;

  private seekEventsTimeoutId: NodeJS.Timeout | undefined;
  private seekEvents: TPlaybackPrepare[] = [];
  private seekEventsLastTime = 0;
  public sessionError = false;

  public constructor(
    playbackSessionContext: TPlaybackSessionContext,
    video: HTMLVideoElement | undefined,
    titleId: string,
    episodeId: string | undefined
  ) {
    this.video = video;
    this.startPlaybackSession(playbackSessionContext, titleId, episodeId);
  }

  public startPlaybackSession(
    playbackSessionContext: TPlaybackSessionContext,
    titleId: string,
    episodeId: string | undefined
  ) {
    const playbackSessionContextResponse =
      this.validatePlaybackSessionContext(playbackSessionContext);

    if (playbackSessionContextResponse) {
      this.playbackSessionContext = playbackSessionContextResponse;
      this.destroy = false;
      this.totalTimeSec = Date.now();
      this.sessionError = false;
      this.titleId = titleId;
      this.episodeId = episodeId;
    }
  }

  private validatePlaybackSessionContext(context: TPlaybackSessionContext) {
    const playbackSessionContext = context;

    if (!playbackSessionContext) {
      return false;
    }

    Object.keys(playbackSessionContext).forEach((property) => {
      if (VALIDATE_RULES.hasOwnProperty(property)) {
        const prop = playbackSessionContext[property as keyof TPlaybackSessionContext];
        const res = VALIDATE_RULES[property as keyof TFieldsRules].check(prop as number);

        if (!res) {
          const { min, max } = VALIDATE_RULES[property as keyof TFieldsRules];
          (playbackSessionContext[property as keyof TPlaybackSessionContext] as number) =
            prop < min ? min : max;
        }
      }
    });

    return playbackSessionContext;
  }

  private startAutoUpdate() {
    this.continueTimeoutId = setTimeout(async () => {
      if (this.checkSession()) {
        await this.send(this.prepareData(VOD_SESSION_USER_EVENT.continue));
      }
    }, this.playbackSessionContext.updateInSec * 1000);
  }

  private stopAutoUpdate() {
    if (this.continueTimeoutId) {
      clearTimeout(this.continueTimeoutId);
    }
  }

  public bufferStart() {
    this.bufferTimerStarted = true;
    const now = Date.now();
    this.bufferTimeStart = now;
    this.playIntervals.push((now - this.positionTimeStart) / 1000);
  }

  public bufferStop() {
    this.bufferTimerStarted = false;
    const now = Date.now();
    this.bufferIntervals.push((now - this.bufferTimeStart) / 1000);
    this.positionTimeStart = now;
  }

  public checkSession() {
    return !!this.playbackSessionContext.pbsId && !this.destroy && !this.sessionError;
  }

  public async userEvent(eventType: string, eventData: EventData) {
    if (!this.checkSession() || this.queue.length > VOD_SESSION_QUEUE_LIMIT) {
      return;
    }

    this.destroy = eventData.destroy;
    this.displayMode = eventData.displayMode;

    if (eventType === VOD_SESSION_USER_EVENT.timeline) {
      if (!this.seekEventsLastTime) {
        this.seekEventsLastTime = Date.now();
      }

      if (Date.now() - this.seekEventsLastTime < VOD_SESSION_SEEK_EVENTS_TIMEOUT) {
        this.seekEventsLastTime = Date.now();
        this.seekEvents.push(
          this.prepareData(VOD_SESSION_USER_EVENT.timeline, eventData.loadingNewState)
        );

        if (this.seekEventsTimeoutId) {
          clearTimeout(this.seekEventsTimeoutId);
        }

        this.seekEventsTimeoutId = setTimeout(async () => {
          if (this.seekEvents.length) {
            this.calcSeekSec();
          }
          this.stopAutoUpdate();
          await this.processQueue();
        }, VOD_SESSION_SEEK_EVENTS_TIMEOUT);
        return;
      }
    }

    this.stopAutoUpdate();
    this.queue.push(this.prepareData(eventType, eventData.loadingNewState));
    await this.processQueue();
  }

  public resetPlaybackSession() {
    this.playbackSessionContext = defaultPlaybackSessionContext;
    this.stopAutoUpdate();
  }

  private calcSeekSec() {
    const seekTime = this.seekEvents.reduce((sum, event): number => {
      let time = 0;
      if (event.requestData.backwardSeekSec) {
        time = event.requestData.backwardSeekSec;
      } else if (event.requestData.forwardSeekSec) {
        time = event.requestData.forwardSeekSec;
      }
      return sum + time;
    }, 0);

    this.queue.push({
      ...this.seekEvents[this.seekEvents.length - 1],
      requestData: {
        ...this.seekEvents[this.seekEvents.length - 1].requestData,
        backwardSeekSec: seekTime < 0 ? Math.abs(seekTime) : 0,
        forwardSeekSec: seekTime > 0 ? seekTime : 0,
      },
    });

    this.seekEvents = [];
  }

  private prepareData(eventType: string, _loadingNewState = false): TPlaybackPrepare {
    const now = Date.now();

    if (eventType === VOD_SESSION_USER_EVENT.stop) {
      this.isPaused = true;
    }

    if (eventType === VOD_SESSION_USER_EVENT.start) {
      this.isPaused = false;
    }

    if (this.bufferTimerStarted) {
      this.bufferIntervals.push((now - this.bufferTimeStart) / 1000);
    } else {
      this.bufferTimeStart = 0;
    }

    if (!this.bufferTimerStarted && this.previousState !== VOD_SESSION_STATE.paused) {
      this.playIntervals.push((now - this.positionTimeStart) / 1000);
    }

    let currentState = VOD_SESSION_STATE.playing;
    if (this.isPaused) {
      currentState = VOD_SESSION_STATE.paused;
    }
    if (
      (this.isPaused && this.destroy) ||
      (this.isPaused && this.previousState === VOD_SESSION_STATE.stopped)
    ) {
      currentState = VOD_SESSION_STATE.stopped;
    }

    const bufferingTimeSec =
      eventType === VOD_SESSION_USER_EVENT.start ? 0 : arrayUtils.sum(this.bufferIntervals);
    this.bufferIntervals = [];

    const playedTimeSec =
      eventType === VOD_SESSION_USER_EVENT.start ? 0 : arrayUtils.sum(this.playIntervals);
    this.playIntervals = [];

    const lastRequestCurrentSession =
      this.isPaused &&
      this.previousState === VOD_SESSION_STATE.paused &&
      Date.now() - this.playbackSessionTimeStart > VOD_SESSION_LIFETIME;

    let loadingNewState = false;
    if (this.bufferTimerStarted || lastRequestCurrentSession || _loadingNewState) {
      loadingNewState = true;
    }

    let forwardSeekSec = 0;
    let backwardSeekSec = 0;

    const currentPositionSec = this.video?.currentTime || 0;

    if (eventType === VOD_SESSION_USER_EVENT.timeline) {
      if (this.previousCurrentPosition < currentPositionSec) {
        forwardSeekSec = currentPositionSec - this.previousCurrentPosition;
      }
      if (this.previousCurrentPosition > currentPositionSec) {
        backwardSeekSec = currentPositionSec - this.previousCurrentPosition;
      }
    }

    this.previousCurrentPosition = currentPositionSec;

    return {
      requestData: {
        pbsContextToken: this.playbackSessionContext?.pbsContextToken || '',
        currentState,
        previousState: this.previousState,
        loadingNewState,
        currentPositionSec,
        forwardSeekSec,
        backwardSeekSec,
        totalTimeSec: (Date.now() - this.totalTimeSec) / 1000,
        videoDisplayMode: this.displayMode,
        playedTimeSec,
        playErrorsCnt: this.playErrorsCnt,
        bufferingTimeSec,
      },
      close_pbs: this.destroy || lastRequestCurrentSession,
      eventType,
    };
  }

  private async processQueue() {
    if (this.pending || this.sessionError) {
      return;
    }

    const data = this.queue.pop();
    if (!data) {
      return;
    }

    this.pending = true;
    await this.send(data);
  }

  private async send(eventData: TPlaybackPrepare) {
    try {
      const context = await api.vod.postVodPlaybackUpdate(
        this.playbackSessionContext?.pbsId,
        eventData?.close_pbs,
        {
          data: eventData?.requestData,
        }
      );
      this.pending = false;
      if (context) {
        await this.onAfterSend(context, eventData?.requestData, eventData?.eventType);
      }
    } catch (err) {
      this.pending = false;
      if (err.code === HTTP_CODES.BAD_REQUEST) {
        this.resetPlaybackSession();
        this.sessionError = true;
      }
    }
  }

  private async onAfterSend(
    context: TPlaybackSessionContext,
    data: TPlaybackSessionContextRequest | undefined,
    eventType = ''
  ) {
    this.playErrorsCnt = 0;
    this.previousState = data?.currentState || '';

    const now = Date.now();
    this.totalTimeSec = now;

    if (this.bufferTimerStarted) {
      this.bufferTimeStart = now;
    } else {
      this.bufferTimeStart = 0;
      this.positionTimeStart = now;
    }

    if (eventType === VOD_SESSION_USER_EVENT.stop) {
      this.playbackSessionTimeStart = now;
    }

    if (eventType === VOD_SESSION_USER_EVENT.start) {
      this.playbackSessionTimeStart = now;
      this.destroy = false;
    }

    this.seekEventsLastTime = 0;

    const playbackSessionContextResponse = this.validatePlaybackSessionContext(context);
    if (playbackSessionContextResponse && this.video && !this.destroy) {
      this.playbackSessionContext = playbackSessionContextResponse;
      if (this.queue.length === 0) {
        if (this.isPaused && Date.now() - this.playbackSessionTimeStart > VOD_SESSION_LIFETIME) {
          this.resetPlaybackSession();
          return;
        }

        this.startAutoUpdate();
      } else {
        await this.processQueue();
      }
    } else {
      this.resetPlaybackSession();
    }
  }
}
