<template>
  <div class="text-with-hint" :class="[theme, { 'show-hint': showHint }]" :data-text="hint || text">
    <div ref="wrap" class="overflow-wrap" :class="{ bold }">
      <span :style="{ borderBottomStyle: borderStyle }" v-text="text" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';

@Component
export default class TextWithHint extends Vue {
  showHint = false;

  @Prop()
  themeForced?: 'light' | 'dark';

  // visible text for hovering
  @Prop({ required: true })
  text!: string;

  // full text that shows in a bubble on hover
  @Prop({ default: '' })
  hint!: string;

  @Prop({ default: true })
  bold!: boolean;

  @Prop({ default: 'none' })
  borderStyle!: 'none' | 'dashed' | 'solid' | 'dotted';

  @Prop()
  dataCy!: string;

  @Ref('wrap')
  refWrap!: HTMLDivElement;

  get dataCyPrefixed() {
    return this.dataCy ? `hint-${this.dataCy}` : '';
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  mounted() {
    if (this.refWrap?.offsetWidth < this.refWrap?.scrollWidth - 1 || !!this.hint) {
      this.showHint = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/truncate-lines';
@import 'src/styles/placeholders-and-mixins/typography';

.text-with-hint {
  position: relative;
  cursor: text;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 24px;
    left: 0;
    z-index: var(--z-1);
    display: none;
    padding: 4px 8px;
    color: var(--c-dark-font-primary);
    white-space: pre-wrap;
    pointer-events: none;
    visibility: hidden;
    background-color: var(--c-dark-500);
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.15s var(--ease-out);
    @include caption2;
  }

  &:hover {
    &::after {
      visibility: visible;
      opacity: 1;
    }
  }

  &.show-hint {
    cursor: help;

    &::after {
      display: block;
    }
  }

  .overflow-wrap {
    @extend %truncate-after-1-line;
  }

  &.dark {
    &::after {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
