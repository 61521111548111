<template>
  <div class="program-info" :class="theme">
    <div class="live-program-title">
      <TextWithHint v-if="liveProgramTitle" :text="liveProgramTitle" />
      <span v-else v-html="getTranslation('live_air')" />
    </div>
    <div class="next-program-starts-in" v-text="nextProgramStartTimeText" />
    <div class="next-program-title">
      <TextWithHint v-if="nextProgramTitle" :text="nextProgramTitle" />
    </div>
    <ContentSeparator :theme-forced="theme" />
    <button class="button button-program" type="button" @click="showEpg">
      <span v-html="getTranslation('tv_program')" />
      <IconSVG :svg="IconAngleRight" />
    </button>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ButtonBookmark from 'src/components/ui/buttons/ButtonBookmark.vue';
import ProgramPreview from 'src/components/tv-channels/ProgramPreview.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleRight from 'src/svg/angle-right.svg';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ContentSeparator from 'src/components/ui/ContentSeparator.vue';
import TextWithHint from 'src/components/ui/TextWithHint.vue';

@Component({
  components: {
    TextWithHint,
    ContentSeparator,
    ButtonDefault,
    IconSVG,
    ButtonBookmark,
    ProgramPreview,
  },
})
export default class TileChannelNowProgramInfo extends SequoiaComponent {
  IconAngleRight = IconAngleRight;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  timelinePosition!: number;

  @Prop()
  liveProgramTitle!: string;

  // e.g. '22:47'
  @Prop()
  liveProgramStartTime!: string;

  @Prop()
  nextProgramTitle!: string;

  // e.g. '22:47'
  @Prop()
  nextProgramStartTime!: string;

  // e.g. '1 ч 39 мин'
  @Prop()
  nextProgramStartsIn!: string;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get hasNoNextProgramStartsIn() {
    return !this.nextProgramStartsIn || this.nextProgramStartsIn.trim() === '';
  }

  get nextProgramStartTimeText() {
    if (!this.nextProgramStartTime && this.hasNoNextProgramStartsIn) {
      return '';
    }

    if (this.nextProgramStartTime && this.hasNoNextProgramStartsIn) {
      return this.nextProgramStartTime;
    }

    if (this.nextProgramStartsIn && this.nextProgramStartTime) {
      return `${this.nextProgramStartTime} • ${this.getTranslation('cherez')} ${
        this.nextProgramStartsIn
      }`;
    }
  }

  showEpg() {
    this.$emit('showEpg');
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.program-info {
  .live-program-title,
  .next-program-starts-in,
  .next-program-title {
    min-height: 24px;
  }

  .live-program-title {
    margin-bottom: 12px;

    @media (max-width: #{$desktop-s-max}) {
      margin-bottom: 8px;
    }
  }

  .next-program-starts-in {
    margin-bottom: 4px;
  }

  .content-separator {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .button-program {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 0;
    text-transform: none;
    @include body1;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .next-program-starts-in {
      color: var(--c-light-font-secondary);
    }

    .button-program {
      color: var(--c-light-font-secondary);

      @include devices-with-hover {
        &:hover {
          color: var(--c-light-font-primary);
        }
      }
    }
  }

  &.dark {
    .next-program-starts-in {
      color: var(--c-dark-font-secondary);
    }

    .button-program {
      color: var(--c-dark-font-secondary);

      @include devices-with-hover {
        &:hover {
          color: var(--c-dark-font-primary);
        }
      }
    }
  }
}
</style>
