export const protobufPackage = 'messages';
export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED_BY_USER = 'CANCELED_BY_USER',
  CANCELED_BY_PAYMENT = 'CANCELED_BY_PAYMENT',
  CANCELED_BY_REMOVING_PAYMENT_METHOD = 'CANCELED_BY_REMOVING_PAYMENT_METHOD',
  IN_RETRY = 'IN_RETRY',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum PaymentSystem {
  Yakassa = 'Yakassa',
  Apple = 'Apple',
  Android = 'Android',
  Smartpay = 'Smartpay',
  Uztelecom = 'Uztelecom',
  Megafon = 'Megafon',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export interface Offer {
  id: string;
  name: string;
  provider: string[];
  price: number;
  fromDate: number;
  toDate: number;
  duration: number;
  content: string[];
  users: string[];
  mediaTypes: string[];
  translitName: string;
  forPurchase: boolean;
  description: string;
  sessionCount: number;
  purchaseState: number;
  priceFloat: number;
  type: number;
  created: number;
  updated: number;
}

export interface Purchase {
  id: string;
  content: string[];
  offer: Offer | undefined;
  offer_id: string;
  user: string;
  expire_time: number;
  created: number;
  updated: number;
  receipt: string;
  state: number;
  sessionCount: number;
}

export interface OffersList {
  offers: Offer[];
}

export interface PurchasesList {
  purchases: Purchase[];
}

export interface OfferPreview {
  id: string;
  sort: number;
  title: string;
  channelsCount: number;
  type: string;
  currency: string;
  price: string;
  paymentFrequency: string;
  priceLabel: string;
}

export interface OfferPreviewList {
  offers: OfferPreview[];
}

export interface Subscription {
  id: string;
  offer: OfferPreview | undefined;
  state: string;
  expiresAt: number;
  createdAt: number;
}

export interface SubscriptionList {
  subscriptions: Subscription[];
}

export interface SubscriptionOption {
  offerId: string;
  title: string;
  currency: string;
  price: string;
  paymentFrequency: string;
  priority: number;
  subscriptionMethods: SubscriptionMethod[];
  renderingMethods: RenderingMethod[];
}

export interface SubscriptionOptionListV2 {
  subscriptionOptions: SubscriptionOptionV2[];
  fallback: SubscriptionOptionListV2Fallback | undefined;
  qsTransactionId: string;
  existingSubscriptions: ExistingSubscription[];
}

export interface BuyOnWebsiteSubscriptionOptions {
  subscriptionOptionListV2: SubscriptionOptionListV2 | undefined;
  contentType: string;
  vodSourceID: string;
  vodTitleID: string;
  tvChannelID: string;
}

export interface SubscriptionOptionListV2Fallback {
  renderingDataJson: string;
  renderingMethods: RenderingMethod[];
}

export interface AppleInApp {
  productId: string;
  trialConsumed: boolean;
}

export interface SmartPayInApp {
  productId: string;
  trialConsumed: boolean;
}

export interface AndroidInApp {
  productId: string;
  changeInfo: AndroidChangeInfo | undefined;
  trialConsumed: boolean;
}

export interface AdapterInApp {
  productId: string;
  systemType: string;
}

export interface AndroidChangeInfo {
  oldSubscriptionId: string;
  oldProductId: string;
  oldPurchaseToken: string;
  prorationMode: string;
}

export interface SubscriptionOptionV2 {
  offerId: string;
  title: string;
  priority: number;
  renderingDataJson: string;
  subscriptionMethods: SubscriptionMethod[];
  renderingMethods: RenderingMethod[];
  type: string;
  trial: SubscriptionOptionV2 | undefined;
  createSubscriptionToken: string;
  trialAvailable: boolean;
  vodSources: string[];
  trialDurationSeconds: number;
  showcaseChannelsCount: number;
  appleInApp: AppleInApp | undefined;
  androidInApp: AndroidInApp | undefined;
}

export interface ExistingSubscription {
  subscriptionId: string;
  offerId: string;
  paymentSystemType: PaymentSystem;
  subscriptionStatus: SubscriptionStatus;
}

export interface SubscriptionMethod {
  name: string;
  priority: number;
}

export interface SubscriptionOptionList {
  subscriptionOptions: SubscriptionOption[];
}

export interface RenderingMethod {
  name: string;
  priority: number;
}

export interface ChannelsNotForPurchaseIdList {
  channelsNotForPurchase: string[];
}

export interface ShowcaseChannels {
  channels: ShowcaseChannel[];
}

export interface ShowcaseChannel {
  channelId: string;
}

export interface RenderingMethodV2 {
  name: string;
  priority: number;
}

export interface ManagementMethod {
  name: string;
  priority: number;
}

export interface SubscriptionTrialV2 {
  offerId: string;
  title: string;
  vodSources: string[];
  showcaseChannelsCount: number;
  renderingMethods: RenderingMethodV2[];
  RenderingDataJson: string;
}

export interface SubscriptionInfoV2 {
  adapterInApp: AdapterInApp | undefined;
  androidInApp: AndroidInApp | undefined;
  appleInApp: AppleInApp | undefined;
  autoRenewInfo: AutoRenewInfo | undefined;
  createdAt: string;
  id: string;
  mainOffer: OfferInfoV2 | undefined;
  managementMethods: ManagementMethod[];
  piority: number;
  smartPayInApp: SmartPayInApp | undefined;
  suspensionInfo: SuspensionInfo | undefined;
  temporaryOffer: TemporaryOffer | undefined;
  yakassa: YakassaSubscriptionInfo | undefined;
}

export interface AutoRenewInfo {
  isAutoRenewEnabled: boolean;
  canResumeAutoRenew: boolean;
  currentPeriodExpiresAt: string;
}

export interface SuspensionInfo {
  isSuspended: boolean;
  suspendedAt: string;
  message: string;
}

export interface YakassaSubscriptionInfo {
  subscriptionId: string;
}

export interface OfferInfo {
  preview: OfferPreview | undefined;
  channels: string[];
}

export interface OfferInfoV2 {
  offerId: string;
  renderingMethods: RenderingMethodV2[];
  renderingDataJson: string;
  vodSources: string[];
  showcaseChannelsCount: number;
}

export interface TemporaryOffer {
  offer: OfferInfoV2 | undefined;
  expiresAt: string;
}

export interface SubscriptionInfoListV2 {
  currentTimestamp: string;
  subscriptions: SubscriptionInfoV2[];
}
