<template>
  <optgroup v-if="transformNativeSelect && isMobile" :label="title">
    <slot />
  </optgroup>
  <div v-else class="group-container">
    <div
      v-if="title && showTitle"
      class="menu-item-container group-title"
      data-cy="dropdown-menu-item-container"
    >
      <div class="caption1 menu-item" v-text="title" />
    </div>
    <slot />
    <div v-if="!showTitle && grouped" class="group-endline" />
  </div>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component
export default class DropdownGroup extends SequoiaComponent {
  @Prop()
  title?: string;

  @Prop({ default: false })
  showTitle!: boolean;

  @Prop({ required: true })
  isMobile!: boolean;

  @Prop({ required: true })
  transformNativeSelect!: boolean;

  @Prop()
  grouped?: boolean;
}
</script>
<style lang="scss" scoped>
.group-container {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  .group-title {
    align-items: center;
    min-height: 0;
    padding: 0 16px;
    margin: 12px 0 8px;
    color: var(--c-light-font-tertiary);
  }

  .group-endline {
    width: calc(100% - 32px);
    height: 1px;
    min-height: 1px;
    margin: 8px 36px 8px 16px;
    background-color: var(--alpha-light-3);
  }

  &:last-child {
    .group-endline {
      display: none;
    }
  }

  &.dark {
    .group-title {
      color: var(--alpha-dark-3);
    }

    .group-endline {
      background-color: var(--alpha-dark-3);
    }
  }
}
</style>
