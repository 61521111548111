<template>
  <div class="app-icon-with-description" :class="theme">
    <img :src="appIconSrc" class="app-icon" alt="" />
    <div class="caption2 mr-8">
      <div class="color-primary title" v-html="getTranslation('smart_app_banner_title')" />
      <div
        class="color-secondary description"
        v-html="getTranslation('smart_app_banner_description')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component
export default class AppIconWithDescription extends SequoiaComponent {
  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }
  get appIconSrc() {
    return this.getByLanguage(this.$store.siteConfig?.smartAppBanner?.icon)?.url;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.app-icon-with-description {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: auto;

  img {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    object-fit: cover;
    border-radius: 12px;
  }

  > div {
    overflow: hidden;

    div {
      @extend %truncate-after-1-line;
    }

    .title {
      @extend %truncate-after-1-line;
    }

    .description {
      @extend %truncate-after-2-lines;
    }
  }

  &.light {
    img {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    }
  }

  &.dark {
    img {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
