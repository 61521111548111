import { TStore } from 'src/store/types';
import { matchUrlProtocolWithLocationProtocol } from 'src/utils/url';
import { favoritesSelector, showVitrinaSelector } from 'src/store/tv-channels/selectors';
import { isAnonymousSelector } from 'src/store/account/selectors';

export const currentChannelSelector = (store: TStore) => store.tvCurrentChannel;

export const currentChannelIdSelector = (store: TStore) => {
  const currentChannel = currentChannelSelector(store);
  return currentChannel ? currentChannel.id : null;
};

export const isInFavoritesSelector = (store: TStore) => {
  const currentChannelId = currentChannelIdSelector(store);
  if (!currentChannelId) {
    return false;
  }
  return !!favoritesSelector(store)[currentChannelId];
};

export const playbackInfoSelector = (store: TStore) => store.tvCurrentChannel?.info?.playbackInfo;

export const playbackInfoDetailsSelector = (store: TStore) => playbackInfoSelector(store)?.details;

export const thumbnailsServerSelector = (store: TStore) => {
  const url = playbackInfoDetailsSelector(store)?.thumbnailServerUrl || '';
  return url ? matchUrlProtocolWithLocationProtocol(url, store.common.isHttps) : url;
};

export const languagesSelector = (store: TStore) =>
  playbackInfoDetailsSelector(store)?.languages || [];

export const currentLanguageIndexSelector = (store: TStore) => {
  const playbackInfo = playbackInfoSelector(store);
  const defaultLanguageIndex = defaultLanguageIndexSelector(store);
  if (!playbackInfo) {
    return defaultLanguageIndex;
  }
  return Number.isInteger(playbackInfo.languageIndex)
    ? playbackInfo.languageIndex
    : defaultLanguageIndex;
};

export const currentLanguageSelector = (store: TStore) => {
  const languages = languagesSelector(store);
  const languageIndex = currentLanguageIndexSelector(store);
  return languages[languageIndex];
};

export const defaultLanguageIndexSelector = (store: TStore) => {
  const languages = languagesSelector(store);
  const index = languages.findIndex((language: { default: any }) => language.default);
  return Math.max(index, 0);
};

export const renditionsSelector = (store: TStore) =>
  currentLanguageSelector(store)?.renditions || [];

export const currentRenditionIndexSelector = (store: TStore) => {
  const playbackInfo = playbackInfoSelector(store);
  const defaultRenditionIndex = defaultRenditionIndexSelector(store);

  if (!playbackInfo) {
    return defaultRenditionIndex;
  }

  return Number.isInteger(playbackInfo.renditionIndex)
    ? playbackInfo.renditionIndex
    : defaultRenditionIndex;
};

export const currentRenditionSelector = (store: TStore) => {
  const renditions = renditionsSelector(store);
  const renditionIndex = currentRenditionIndexSelector(store);
  return renditions[renditionIndex];
};

export const defaultRenditionIndexSelector = (store: TStore) => {
  const renditions = renditionsSelector(store);
  const index = renditions.findIndex((rendition: { default: any }) => rendition.default);
  return Math.max(index, 0);
};

// ----------------------------------------------------------------------------
// DVR
// ----------------------------------------------------------------------------
/**
 * Select message why channel's DVR has been restricted
 */
export const dvrRestrictionMessageSelector = (store: TStore) =>
  playbackInfoSelector(store)?.dvrAvailability?.msg || '';

// TODO do we need it?
// export const isDvrAllowedSelector = (store: TStore) => {
//   const dvrAvailability = store.tvCurrentChannel?.dvrAvailability;
//   return (
//     dvrAvailability &&
//     (typeof dvrAvailability.restrictionReason !== 'string' ||
//       dvrAvailability.restrictionReason === 'Allowed')
//   );
// };

// -------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------

export const anyAvailableLanguageIndexSelector = (store: TStore): number =>
  languagesSelector(store).findIndex(({ unavailable }: any) => unavailable !== true);

export const isLanguageAvailableSelector = (store: TStore, languageIndex: number): boolean =>
  languagesSelector(store)[languageIndex]?.unavailable !== true;

// -------------------------------------------------------------------------------------
// Vitrina
// -------------------------------------------------------------------------------------

/**
 * if user is not anonymous and vitrina should always be shown for current provider
 * https://lifestream.atlassian.net/browse/SEQ-1580
 */
export const shouldShowVitrinaForCurrentChannelSelector = (store: TStore) => {
  const channel = currentChannelSelector(store);
  return channel ? showVitrinaSelector(store, channel) : false;
};

export const isCurrentChannelWithVitrinaSelector = (store: TStore) =>
  (isAnonymousSelector(store) || shouldShowVitrinaForCurrentChannelSelector(store)) &&
  !!currentChannelVitrinaSelector(store);

export const currentChannelVitrinaSelector = (store: TStore) => {
  const currentChannelId = currentChannelIdSelector(store);
  return store.siteConfig?.vitrinaTv?.find((one) => one.channelId === currentChannelId);
};

export const currentChannelVitrinaUrlSelector = (store: TStore) =>
  currentChannelVitrinaSelector(store)?.url || '';
