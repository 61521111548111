import { TStore } from 'src/store/types';
import { processChannel } from 'src/utils/channel';
import { convertToDays } from 'src/utils/time/convert-time';
import { REGISTRATION_TRIAL_STATUSES } from 'src/constants';
import {
  allSubscriptionsSelector,
  allSubscriptionsOptionsTypeBaseSelector,
} from 'src/store/quick-subscribe/selectors';
import { getByLanguage } from 'src/utils/language';
import { TRenderingData } from 'src/store/quick-subscribe/types';
import { languageCodeSelector } from 'src/store/common/selectors';
import { translationSelector } from 'src/store/translations/selectors';
import { listOfAllSourceIdsSelector } from 'src/store/vod/selectors';

export const withAcquiringSelector = (store: TStore) => {
  const subscriptionOptions = allSubscriptionsOptionsTypeBaseSelector(store);
  return (
    subscriptionOptions &&
    subscriptionOptions.length > 0 &&
    !isAutoTrialStatusUnknownSelector(store) &&
    !isAutoTrialStatusProcessStartedSelector(store) &&
    !wasAutoTrialCreatedSelector(store)
  );
};

export const offersSelector = (store: TStore) => {
  const supportedRenderingMethods = ['qs21-yakassa', 'qs20-tv-default', 'qs10-legacy-default']; // these rendering methods are explicitly supported only in acquiring after registration
  const subscriptions = allSubscriptionsSelector(store);

  if (!subscriptions?.subscriptionOptions) {
    return [];
  }
  return subscriptions.subscriptionOptions
    .filter(
      ({ renderingMethods }) =>
        !!renderingMethods.find((renderingMethod) =>
          supportedRenderingMethods.includes(renderingMethod.name)
        )
    )
    .map((one) => {
      const channels = one.renderingData.channels || [];
      return {
        trialAvailable: store.registration.disableTrials ? false : one.trialAvailable,
        channels: channels.map(processChannel),
        channelsIds: channels.map((channel) => channel.id),
        trialDays: convertToDays(one.trialDurationSeconds, 'second'),
        trialChannels: one.trial?.renderingData.channels || [],
        renderingData: one.renderingData,
        id: one.offerId,
        createSubscriptionToken: one.createSubscriptionToken,
        vodSources: one.vodSources,
        title: one.title,
      };
    });
};

/**
 * User can apply trials if:
 * - there are some offers with trials
 * - trial was not applied automatically after registration
 */
export const canApplyTrialsSelector = (store: TStore) => {
  const offers = offersSelector(store);
  const hasTrialsOffers = offers?.find((offer) => offer.trialAvailable);
  return (
    hasTrialsOffers &&
    !wasAutoTrialCreatedSelector(store) &&
    !isAutoTrialStatusProcessStartedSelector(store)
  );
};

export const currentOfferIdSelector = (store: TStore) => store.registration.currentOfferId;

export const currentOfferSelector = (store: TStore) => {
  const offers = offersSelector(store);
  const currentOfferId = currentOfferIdSelector(store);
  return offers?.find((offer) => offer.id === currentOfferId);
};

export const confirmationUrlSelector = (store: TStore) => store.registration.confirmationUrl;

export const autoTrialStatusSelector = (store: TStore) => store.registration.autoTrialStatus;

export const autoTrialDurationSelector = (store: TStore) =>
  convertToDays(store.registration.autoTrialDuration, 'second');

export const wasAutoTrialCreatedSelector = (store: TStore) =>
  autoTrialStatusSelector(store) === REGISTRATION_TRIAL_STATUSES.created;

export const isAutoTrialStatusUnknownSelector = (store: TStore) =>
  autoTrialStatusSelector(store) === REGISTRATION_TRIAL_STATUSES.unknown ||
  autoTrialStatusSelector(store) === null;

export const isAutoTrialStatusProcessStartedSelector = (store: TStore) =>
  autoTrialStatusSelector(store) === REGISTRATION_TRIAL_STATUSES.processStarted;

const prepareOfferField = (
  store: TStore,
  fieldName: string,
  renderingData?: TRenderingData,
  offerId?: string
) => {
  const field = store.authAndReg?.trial && store.authAndReg?.trial[fieldName];

  if (!field) {
    return '';
  }

  let translation = getByLanguage(field.translationDict, languageCodeSelector(store)) || '';

  if (renderingData) {
    field.variables?.forEach((variable: string) => {
      if (renderingData[variable]) {
        if (variable === 'vodAvailable') {
          const sources = listOfAllSourceIdsSelector(store);
          const offers = offersSelector(store);
          const offer = offers?.find((offer) => offer.id === offerId);
          const variableFromRenderingData = offer?.renderingData[variable] || '';
          const movieTheatres = offer?.vodSources
            ?.filter((s) => sources.indexOf(s) >= 0)
            ?.map((s) => translationSelector(store, s))
            .join(`, `);

          translation = `${variableFromRenderingData.split(':')[0]}`;

          if (movieTheatres) {
            translation = `${translation}: ${movieTheatres}`;
          }
        } else {
          translation = translation.replace(`@${variable}@`, renderingData[variable]);
        }
      } else {
        translation = '';
      }
    });
  }

  return translation;
};

export const trialOfferBadgeTextSelector = (store: TStore, renderingData?: TRenderingData) =>
  prepareOfferField(store, 'badge', renderingData);

export const trialOfferTitleSelector = (store: TStore, renderingData?: TRenderingData) =>
  prepareOfferField(store, 'offerTitle', renderingData);

export const trialOfferPriceSelector = (store: TStore, renderingData?: TRenderingData) =>
  prepareOfferField(store, 'offerPrice', renderingData);

export const trialOfferChannelCountsSelector = (store: TStore, renderingData?: TRenderingData) =>
  prepareOfferField(store, 'offerChannelCount', renderingData);

export const trialOfferVodSourcesSelector = (
  store: TStore,
  offerId: string,
  renderingData?: TRenderingData
) => prepareOfferField(store, 'offerVodSources', renderingData, offerId);
