<template>
  <div class="verification-subscription">
    <QuickSubscribeBackground />

    <SubscriptionInfoLine />

    <div class="verification-subscription-content">
      <div class="form-container with-side-and-bottom-padding pt-32 light">
        <div
          class="description mb-32"
          v-html="
            getTranslation('confirm_verify_password_text').replace(
              '%account_name%',
              `&#32;<b>${accountName}</b>`
            )
          "
        />

        <form autocomplete="off" novalidate class="form" @submit.prevent="passwordVerification">
          <div class="input-wrapper mr-16">
            <InputText
              v-model="password"
              input-category="floating"
              icon="visibility"
              required
              data-cy="password"
              theme-forced="light"
              :tab-index-for-button="-1"
              :status="error ? 'error' : undefined"
              :minlength="passwordMinLength"
              :maxlength="passwordMaxLength"
              :label="getTranslation('password_reg')"
              :message="getTranslation('old_password')"
            />
          </div>
          <ButtonDefault
            size="medium"
            :is-loading="isLoading"
            :disabled="disabledButton"
            class="button"
          >
            <span v-html="getTranslation('confirm_verify_subscription')" />
          </ButtonDefault>
        </form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import SubscriptionInfoLine from '../SubscriptionInfoLine.vue';
import { selectors } from 'src/store/selectors';
import * as api from 'src/api';

@Component({
  components: { QuickSubscribeBackground, ButtonDefault, InputText, SubscriptionInfoLine },
})
export default class PasswordVerificationSubscription extends SequoiaComponent {
  password = '';
  isLoading = false;
  error = false;

  get passwordMinLength() {
    return selectors.common.authAndRegPasswordMinSelector(this.$store);
  }

  get passwordMaxLength() {
    return selectors.common.authAndRegPasswordMaxSelector(this.$store);
  }

  get disabledButton() {
    return this.password.length < this.passwordMinLength || this.isLoading;
  }

  get accountName() {
    return this.$store.account?.user.login;
  }

  async passwordVerification() {
    this.isLoading = true;
    this.error = false;
    try {
      await api.profile.managing({
        data: {
          email: this.accountName,
          password: this.password,
        },
      });
      this.$emit('createSub');
    } catch (err) {
      this.isLoading = false;
      this.error = true;
    }
  }
}
</script>
