import { be } from '../utils';
import { URLS } from 'src/constants';
// import { getLogger } from 'lightlog';
import {
  TComponent,
  TPackages,
  TPage,
  TPageFolder,
  TPersonalArea,
  TPremiumPage,
  TRedirect,
  TReviews,
  TSeoRule,
} from 'src/api/cms/types';

// const log = getLogger('api.cms');

// ----------------------------------------------------------
// GET
// ----------------------------------------------------------

export const getAllComponents = async (): Promise<TComponent[]> => {
  const result = await be.get('/component');
  return result?.components;
};

export const getAllContentBlocks = async () => {
  const result = await be.get('/cleanContentBlock');
  return result?.contentBlocks;
};

export const getAllFiles = async () => {
  const result = await be.get('/cleanFileRecord');
  return result?.files || {};
};

export const getAllPages = async (): Promise<{ pages: TPage[]; pageFolders?: TPageFolder[] }> => {
  return await be.get('/page');
};

export const getAllArchives = async () => {
  const result = await be.get('/archive');
  return result?.archives || [];
};

export const getAllTranslations = async () => {
  const result = await be.get('/cleanTranslation');
  return result?.translations || {};
};

export const getAuthAndReg = async () => {
  const result = await be.get('/authAndReg');
  return result?.authAndReg;
};

export const getSiteConfig = async () => {
  const result = await be.get('/siteConfig');
  return result?.siteConfig;
};

export const getBranding2 = async () => {
  const result = await be.get('/branding2');
  return result?.branding2;
};

export const getHeader = async () => {
  return await be.get('/header');
};

export const getFooter = async () => {
  return await be.get('/footer');
};

export const getLanguages = async () => {
  const result = await be.get('/language');
  return result || { languages: [], defaultLanguage: null };
};

export const getVodSourcesBe = async () => {
  const result = await be.get('/vod');
  return result?.vod;
};

export const getQSConfig = async () => {
  const response = await be.get('/quickSubscribe');
  return response?.quickSubscribe?.methods;
};

export const getBrandingMethods = async () => {
  try {
    const { brandingMethods } = await be.get(URLS.brandingMethods);
    return brandingMethods || [];
  } catch (err) {
    return [];
  }
};

export const getPersonalArea = async (): Promise<TPersonalArea> => {
  const response = await be.get('/personal-area');
  return response?.personalArea;
};

export const getReviews = async (): Promise<TReviews> => {
  const response = await be.get('/reviews');
  return response?.reviews;
};

export const getPremiumPageProvider = async (
  slug = ''
): Promise<TPremiumPage | { providers: [] }> => {
  const emptyResponse = { providers: [] };
  const response = await be.get(URLS.premiumPageProviderBySlug(slug), { disabledEtag: true });
  return response || emptyResponse;
};

export const getPackages = async (): Promise<TPackages> => {
  const emptyResponse = { items: [] };
  try {
    const response = await be.get('/packages');
    return response?.packages;
  } catch (e) {
    return emptyResponse;
  }
};

export const getSeoRules = async (): Promise<TSeoRule[]> => {
  const response = await be.get(URLS.seoRules).catch(() => {
    return [];
  });

  return response?.rules;
};

export const getRedirects = async (): Promise<TRedirect[]> => {
  const response = await be.get(URLS.redirect).catch(() => {
    return [];
  });

  return response?.redirects;
};

// ----------------------------------------------------------
// POST
// ----------------------------------------------------------

export const savePromocodeKT = async (data: { code: string; phone: string }) => {
  await be.post('/promocodes-kt', { data });
};
