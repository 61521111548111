<template>
  <div class="enter-pin-step">
    <div class="h3" v-html="getTranslation('welcome_screen_step_enterpin_title')" />
    <div class="body1 mb-32" v-html="getTranslation('welcome_screen_step_enterpin_text')" />

    <InputText
      id="enter-pin-step-pin"
      v-model="pin"
      icon="visibility"
      input-category="floating"
      autocomplete="off"
      :tab-index-for-button="-1"
      :maxlength="4"
      :allow-digits-only="true"
      :label="getTranslation('enter_pin')"
      class="mb-64"
    />

    <ButtonDefault
      view="primary"
      type="button"
      class="mr-16"
      :is-loading="isLoading"
      :disabled="pin.length < PROFILE_PIN_LENGTH"
      @click="enterPin"
    >
      <span v-html="getTranslation('save')" />
    </ButtonDefault>
    <ButtonDefault view="secondary" type="button" @click="cancel">
      <span v-html="getTranslation('cancel')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import { actions } from 'src/store/actions';
import { cookieStore } from 'src/utils/storage';
import * as api from 'src/api';
import { PROFILE_PIN_LENGTH } from 'src/constants';

import logger from 'src/utils/logger';
const log = logger('Enter pin step');

@Component({
  components: { ButtonDefault, InputText },
})
export default class EnterPinStep extends SequoiaComponent {
  PROFILE_PIN_LENGTH = PROFILE_PIN_LENGTH;
  pin = '';
  isLoading = false;

  async enterPin() {
    this.isLoading = true;

    try {
      if (this.$store.account?.profile?.id) {
        await actions.profile.update(this.$store, this.$store.account?.profile?.id, {
          data: {
            Name: this.$store.account?.profile?.name,
            Pin: this.pin,
          },
        });
        actions.common.showProfileNotification(
          this.$store,
          this.getTranslation('welcome_screen_success_set_pin_code')
        );
        await actions.profile.getAccount(this.$store);
        cookieStore.set('welcomeScreenConfirmed', '1');
        this.$emit('close');
        await api.profile.managingLogout().catch((err) => {
          log.error(err);
        });
      }
    } catch (err) {
      log.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  cancel() {
    this.$emit('changeStep', 'SetPin');
  }
}
</script>
