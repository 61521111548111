<template>
  <div
    v-if="((videoDurationHM && !hasSeries) || seasonsQuantity) && !currentProgress"
    class="duration title-details-info-with-icon"
    :class="{ 'without-icon': !showIcon }"
  >
    <IconSVG v-if="showIcon" :svg="IconTimeInverted" />
    <span
      v-if="showTranslation"
      :class="`color-${theme}-font-secondary`"
      v-html="getTranslation('time')"
    />
    <span v-html="videoDurationHM || seasonsString" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconTimeInverted from 'src/svg/time-inverted.svg';
import plurar from 'plural-ru';

@Component({
  components: {
    IconSVG,
  },
})
export default class TitleDuration extends SequoiaComponent {
  IconTimeInverted = IconTimeInverted;
  plurar = plurar;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: false })
  showIcon?: boolean;

  @Prop({ default: false })
  showTranslation?: boolean;

  @Prop()
  videoDurationHM?: string;

  @Prop()
  currentProgress?: number;

  @Prop()
  hasSeries?: boolean;

  @Prop()
  seasonsQuantity?: number;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get seasonsString() {
    return this.seasonsQuantity
      ? `${this.seasonsQuantity} ${plurar(
          this.seasonsQuantity,
          this.getTranslation('season'),
          this.getTranslation('season_genitive'),
          this.getTranslation('seasons_genitive')
        )}`
      : '';
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/title-details-info-with-icon';
</style>

<style lang="scss" scoped>
.duration {
  &.without-icon {
    display: block;
  }
}
</style>
