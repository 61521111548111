<template>
  <div class="image-background" :class="theme">
    <IconSVG v-if="withIcon" class="placeholder" :svg="icon" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import IconPlaceholder from 'src/svg/placeholder.svg';
import IconPlaceholderSm from 'src/svg/placeholder-sm.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { wlDetector } from 'src/utils';

@Component({
  components: { IconSVG },
})
export default class ImageBackground extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: false })
  withIcon!: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get isSmotreshka() {
    return wlDetector().isSmotreshka;
  }

  get icon() {
    return this.isSmotreshka ? IconPlaceholderSm : IconPlaceholder;
  }
}
</script>

<style lang="scss" scoped>
.image-background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .placeholder {
    width: 100%;
    height: 100%;
  }

  // -----------------------------------------------
  // Theme Colors
  // -----------------------------------------------
  &.light {
    background-color: var(--c-light-200);

    .placeholder {
      color: var(--c-light-300);
    }
  }

  &.dark {
    background-color: var(--c-dark-400);

    .placeholder {
      color: var(--c-dark-500);
    }
  }
}
</style>
