import { fe, TReqConfig } from 'src/api/utils';
import { URLS } from 'src/constants';
import dayjs from 'dayjs';
import { TAssetTokens, TAssetTokensResponse } from 'src/api/tokens/types';

export const loadAssetTokens = async (config: TReqConfig = {}) => {
  const res: TAssetTokensResponse = await fe.get(URLS.assetTokens, config).catch(() => {
    return {};
  });

  // calculate & format expiresAt attribute for a cookie
  const startsAt = dayjs().toISOString();
  const expiresAt = dayjs(res.expiresAt); // expiresAt is the same for all tokens
  const expirationDiff = expiresAt.diff(startsAt) * 0.1;
  const expiresAtRecalculated = new Date(expiresAt.valueOf() - expirationDiff);

  const tokens: TAssetTokens = {};

  for (const [key, value] of Object.entries(res)) {
    if (key.includes('Token')) {
      tokens[key] = { token: value, expiresAt: expiresAtRecalculated };
    }
  }

  return tokens;
};
