import { wlDetector } from 'src/utils/index';

export const cleanupPhone = (phone = '', withPlus = true) => {
  const cleanPhone = String(phone).trim().replace(/\D/g, '');

  if (wlDetector().isUztel) {
    return cleanPhone.slice(3);
  }

  return (withPlus ? '+' : '') + cleanPhone;
};

export const formatPhone = (_phone = '') => {
  const phone = String(_phone).trim().replace(/\D/g, '');
  if (phone.length !== 11) {
    return phone;
  } else {
    const sub = (a: number, b: number) => phone.substring(a, b);
    return `+${phone[0]} (${sub(1, 4)}) ${sub(4, 7)}-${sub(7, 9)}-${sub(9, 11)}`;
  }
};
