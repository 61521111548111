<template>
  <transition appear name="notification-animation" mode="out-in">
    <div class="notification dark" data-cy="notification-simple">
      <template v-if="icon">
        <IconSVG v-if="icon === 'to-profile'" :svg="IconToProfile" class="color-success" />
        <IconSVG
          v-else-if="icon === 'check-circled'"
          :svg="IconCheckCircled"
          class="color-success"
        />
        <IconSVG v-else-if="icon === 'cross-circled'" :svg="IconCrossCircled" class="color-error" />
        <IconSVG v-else-if="icon === 'bookmark-on'" :svg="IconBookmarkOn" class="color-brand" />
        <IconSVG v-else-if="icon === 'bookmark-off'" :svg="IconBookmarkOff" />
      </template>
      <div v-if="text" class="text body2 flex-center-vertical" v-html="text" />
      <div v-if="textWithLink" class="text-with-link body2" v-html="textWithLink" />
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconToProfile from 'src/svg/to-profile.svg';
import IconCheckCircled from 'src/svg/check-circled.svg';
import IconCrossCircled from 'src/svg/cross-circled.svg';
import IconBookmarkOn from 'src/svg/bookmark-on.svg';
import IconBookmarkOff from 'src/svg/bookmark-off.svg';

@Component({
  components: {
    IconSVG,
  },
})
export default class NotificationSimple extends SequoiaComponent {
  IconToProfile = IconToProfile;
  IconCheckCircled = IconCheckCircled;
  IconCrossCircled = IconCrossCircled;
  IconBookmarkOn = IconBookmarkOn;
  IconBookmarkOff = IconBookmarkOff;

  @Prop({ default: '' })
  icon!: 'to-profile' | 'check-circled' | 'cross-circled' | 'bookmark-on' | 'bookmark-off' | '';

  @Prop()
  text!: string;

  @Prop()
  textWithLink?: string;
}
</script>

<style lang="scss">
@import 'notification-base';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.notification {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-width: 320px;

  @include mobile {
    width: 100%;
    max-width: 100%;
    padding-bottom: 16px;
  }

  .icon {
    margin-right: 12px;
  }

  .text {
    margin-bottom: 0;

    &::v-deep {
      span {
        word-break: break-word;

        &:first-child {
          display: inline-block;
          margin-right: 4px;
        }
      }
    }

    @include mobile {
      &::v-deep {
        span {
          &:last-child {
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
