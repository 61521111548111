import { URLS } from 'src/constants';
import { fe, TReqConfig } from 'src/api/utils';
import { SubscriptionInfoListV2, SubscriptionOptionListV2 } from 'src/models/ts/offer';
import {
  TLastPayment,
  TPaymentMethod,
  TSubscriptionEmptyResponse,
  TYakassaSubscription,
} from 'src/api/quick-subscribe/types';

export const all = (config?: TReqConfig): Promise<SubscriptionOptionListV2> =>
  fe.get(URLS.quickSubscribe.allAvailableSubscriptionOptions, config);

export const vod = (config?: TReqConfig): Promise<SubscriptionOptionListV2> =>
  fe.get(URLS.quickSubscribe.vodSubscriptionOptions, config);

export const tv = (channelId: string, config?: TReqConfig): Promise<SubscriptionOptionListV2> =>
  fe.get(URLS.quickSubscribe.tvSubscriptionOptions(channelId), config);

/**
 * Предоставление данных о существующих покупках.
 * В процессе использования сервиса у пользователя может возникнуть ситуация когда фактически доступа к контенту нет, но подписка не перенесена в архив (подробнее смотри тут и тут) и попытки списания денежных средств продолжаются.
 * В этом случае пользователь может попытаться получить список предложений для восстановления доступа к контенту (а в дальнейшем и оплатить контент), а мы в свю очередь должны информировать его о retry-состоянии подписки в которую входит запрошенный контент.
 * Для этих целей в ответ на запрос subscription-options-v3 вернется поле (existingSubscriptions) с массивом объектов описывающих имеющиеся подписки в которые входит запрошенный контент.
 * Если при запросе subscription-options-v2 у пользователя обнаружатся подписки в retry статусе контент которой был запрошен, то на клиент вернется ошибка HTTP 409 Conflict.
 * @see https://dev.tightvideo.com/projects/server-side/wiki/Quick-subscribe#%D0%9F%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85-%D0%BE-%D1%81%D1%83%D1%89%D0%B5%D1%81%D1%82%D0%B2%D1%83%D1%8E%D1%89%D0%B8%D1%85-%D0%BF%D0%BE%D0%BA%D1%83%D0%BF%D0%BA%D0%B0%D1%85
 */
export const tvV3 = (channelId: string, config?: TReqConfig): Promise<SubscriptionOptionListV2> =>
  fe.get(URLS.quickSubscribe.tvSubscriptionOptionsV3(channelId), config);

export const vodV3 = (config?: TReqConfig): Promise<SubscriptionOptionListV2> =>
  fe.get(URLS.quickSubscribe.vodSubscriptionOptionsV3, config);

export const paymentMethod = (config?: TReqConfig): Promise<TPaymentMethod> =>
  fe.get(URLS.yakassa.paymentMethod, config);

export const subscriptions = (config?: TReqConfig): Promise<TYakassaSubscription[]> =>
  fe.get(URLS.yakassa.subscriptions, config);

export const subscriptionsV2 = (config?: TReqConfig): Promise<SubscriptionInfoListV2> =>
  fe.get(URLS.quickSubscribe.currentSubscriptions, config);

export const enableAutoRenew = (subscriptionId: string, config?: TReqConfig) =>
  fe.get(URLS.quickSubscribe.enableAutoRenew(subscriptionId), config);

export const disableAutoRenew = (subscriptionId: string, config?: TReqConfig) =>
  fe.get(URLS.quickSubscribe.disableAutoRenew(subscriptionId), config);

export const subscription = (
  subscriptionId: string,
  config: TReqConfig
): Promise<TYakassaSubscription> => fe.get(URLS.yakassa.subscription(subscriptionId), config);

export const renewSubscriptionWithExistedPm = (
  subscriptionId: string,
  config: TReqConfig
): Promise<TYakassaSubscription> =>
  fe.post(URLS.yakassa.renewSubscriptionWithExistedPm(subscriptionId), config);

export const renewSubscriptionWithNewPm = (
  subscriptionId: string,
  config: TReqConfig
): Promise<TYakassaSubscription> =>
  fe.post(URLS.yakassa.renewSubscriptionWithNewPm(subscriptionId), config);

export const removePaymentMethod = (config?: TReqConfig): Promise<TYakassaSubscription> =>
  fe.post(URLS.yakassa.removePaymentMethod, config);

export const createYakassaSubscription = (config: TReqConfig): Promise<TYakassaSubscription> =>
  fe.post(URLS.yakassa.subscriptions, config);

export const createYakassaSubscriptionV2 = (config: TReqConfig): Promise<TYakassaSubscription> =>
  fe.post(URLS.yakassa.createSubscription, config);

export const createSubscription = (config: TReqConfig): Promise<TSubscriptionEmptyResponse> =>
  fe.post(URLS.quickSubscribe.createSubscription, config);

export const createSubscriptionWithVerifiedPhone = (
  config: TReqConfig
): Promise<TSubscriptionEmptyResponse> =>
  fe.post(URLS.quickSubscribe.createSubscriptionWithVerifiedPhone, config);

export const createSubscriptionUpsale = (config: TReqConfig): Promise<TSubscriptionEmptyResponse> =>
  fe.post(URLS.quickSubscribe.createSubscriptionUpsale, config);

export const createSubscriptionUpsaleV2 = (
  config: TReqConfig
): Promise<TSubscriptionEmptyResponse> =>
  fe.post(URLS.quickSubscribe.createSubscriptionUpsaleV2, config);

export const getArchivedSubscriptions = (config?: TReqConfig): Promise<TYakassaSubscription[]> =>
  fe.get(URLS.yakassa.archivedSubscriptions, config);

// ??? дока https://yookassa.ru/developers/api#payment_object
export const getLastPaymentFromKassa = (
  subscriptionId: string,
  config?: TReqConfig
): Promise<TLastPayment> => fe.get(URLS.yakassa.lastPaymentFromKassa(subscriptionId), config);

export const loadAllAvailableSubscriptionOptions = (
  config?: TReqConfig
): Promise<SubscriptionOptionListV2> => fe.get(URLS.allAvailableSubscriptionOptions, config);
