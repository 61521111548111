<template>
  <CmsLayout>
    <Error404 />
  </CmsLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import ErrorLayout from 'src/layouts/ErrorLayout.vue';
import { actions } from 'src/store/actions';
import Error404 from 'src/components/Error404.vue';

@Component({
  components: { Error404, ErrorLayout, CmsLayout },
})
export default class Error404Page extends SequoiaComponent {
  mounted() {
    actions.common.showFooter(this.$store);
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }
}
</script>
