<template>
  <div v-if="currentCategory && categories && filteredCategories" class="filters flex">
    <VodMenuFilters
      v-if="!isMobile && filteredCategories && shouldShowCategories"
      :categories="filteredCategories"
      :genres="filteredGenres"
      :placeholder="getTranslation('filters')"
      @select="selectFilters"
    />

    <DropdownSequoia
      v-if="filteredCategories && shouldShowCategories && isMobile"
      :list="filteredCategories"
      :transform-native-select="true"
      class="genre-list"
      :class="{ 'one-list': !shouldShowGenres }"
      data-cy="categories"
      width="content"
      @select="selectCategory"
    />

    <DropdownSequoia
      v-if="filteredGenres && shouldShowGenres && isMobile"
      :list="filteredGenres"
      :transform-native-select="true"
      width="content"
      class="genre-list"
      data-cy="genres"
      @select="selectGenre"
    />

    <VodSorting :sourceId="sourceId" @loadTitlesWithSorting="loadTitlesWithSorting" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Watch } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { storage } from 'src/utils';
import { DEFAULT_VOD_CATEGORIES, STORE_KEYS } from 'src/constants';
import VodSorting from 'src/components/vod/VodSorting.vue';
import { TCategory } from 'src/api/vod/types';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import VodMenuFilters from 'src/components/vod/VodMenuFilters.vue';
import { getDeviceFlags } from 'src/utils/platform-detector';
import { TGenre } from 'src/store/tv-channels/types';

@Component({
  components: {
    DropdownSequoia,
    VodMenuFilters,
    VodSorting,
  },
})
export default class VodFilters extends SequoiaComponent {
  categoryValue = this.currentCategoryValueFromStore || '';
  sorting = '';
  useDefaultCategory = true;
  useCategoryFromStore = true;

  @Prop({ required: true })
  sourceId!: string;

  @Prop({ default: 0 })
  defaultActiveCategoryKey!: number;

  // should come presorted by priority
  @Prop({ required: true })
  categories!: Array<TCategory>;

  @Prop({ required: true })
  currentCategory!: TCategory;

  @Prop()
  currentGenre?: TGenre | null;

  @Watch('isProfileLoaded')
  onIsProfileLoadingChange(val: boolean) {
    if (val) {
      this.categoryValue = '';
    }
  }

  get isProfileLoaded() {
    return selectors.common.isProfileLoadedSelector(this.$store);
  }

  get isProfileLoading() {
    return selectors.common.isProfileLoadingSelector(this.$store);
  }

  get filteredCategories() {
    this.useCategoryFromStore = !!this.currentCategoryIdFromStore;

    if (
      !this.useCategoryFromStore &&
      !this.categories?.find((cat) => cat.id === DEFAULT_VOD_CATEGORIES[this.sourceId])
    ) {
      this.useDefaultCategory = false;
    }

    return this.categories?.map((one, i) => ({
      id: one.id,
      key: (i + 1).toString(),
      selected: this.useCategoryFromStore
        ? one.id === this.currentCategoryIdFromStore
        : this.useDefaultCategory
        ? one.id === DEFAULT_VOD_CATEGORIES[this.sourceId]
        : i === this.defaultActiveCategoryKey,
      value: one.title,
      genres: one.genres,
    }));
  }

  get sortedGenres() {
    return this.currentCategory.genres
      ? Object.values(this.currentCategory.genres).sort((a, b) => {
          if (a.id === '-1') {
            return -1;
          }
          if (b.id === '-1') {
            return 1;
          }
          return a.priority - b.priority;
        })
      : [];
  }

  get filteredGenres() {
    return this.sortedGenres?.map((genre, i) => {
      return {
        id: genre.id,
        key: (i + 1).toString(),
        selected: this.currentGenre ? genre.id === this.currentGenre?.id : i === 0,
        value: genre.title,
      };
    });
  }

  get currentCategoryFromStore() {
    return selectors.vod.currentCategorySelector(this.$store, this.sourceId);
  }

  get currentCategoryValueFromStore() {
    return this.currentCategoryFromStore?.title;
  }

  get currentCategoryIdFromStore() {
    return this.currentCategoryFromStore?.id;
  }

  get shouldShowCategories() {
    if (!this.categories) {
      return false;
    }
    return Object.keys(this.categories).length > 1;
  }

  get shouldShowGenres() {
    return this.currentCategory?.genres && this.currentCategory?.genres.length > 1;
  }

  get isMobile() {
    return getDeviceFlags().isMobile;
  }

  selectFilters({ category, genre }: Record<string, TDropdownItem>) {
    if (genre) {
      this.$emit('setSelectedGenre', genre);
      storage.set(STORE_KEYS.vod.genreIds, genre.id);
    }

    this.$emit('loadTitles', false, category.id, this.sorting);
    this.gaEvent({
      category: 'vod',
      action: 'Просмотр выборки тайтлов по жанру и категории',
      genre_filtering: genre.value,
      category_filtering: category.value,
    });
  }

  selectCategory(categoryItem: TDropdownItem) {
    this.selectGenre(this.filteredGenres[0], false);
    this.$emit('loadTitles', false, categoryItem.id);
  }

  selectGenre(item: TDropdownItem, loadTitles = true) {
    if (!item) {
      return;
    }

    this.$emit('setSelectedGenre', item || this.currentCategory?.genres?.[0]);

    if (loadTitles) {
      this.$emit('loadTitles');
    }

    storage.set(STORE_KEYS.vod.genreIds, item.id);
  }

  loadTitlesWithSorting(sorting: string) {
    this.sorting = sorting;
    this.$emit('loadTitles', false, this.currentCategory.id, this.sorting);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/media-queries';

.filters {
  position: relative;
  margin-bottom: 32px;

  @include mobile-and-tablet {
    margin-bottom: 24px;
  }

  .menu-filters {
    margin-right: 16px;

    @include mobile {
      width: 100%;
      margin-right: 0;
    }
  }

  .menu-dropdown {
    &:first-child {
      flex: 1 1 auto;
      margin-right: 16px;

      &.one-list {
        margin-right: 0;
      }
    }

    @include mobile {
      width: calc(50% - 8px);

      &::v-deep {
        .button-dropdown-inner {
          width: 100% !important;
          max-width: 100%;
        }
      }
    }

    @include tablet {
      width: calc(50% - 8px);

      &.mobile.vod-sorting {
        width: 100%;

        &::v-deep {
          .button-dropdown-inner {
            width: 100% !important;
            max-width: 100%;
          }
        }
      }

      &:first-child {
        margin-right: 16px;
      }

      &::v-deep:not(.vod-sorting) {
        .button-dropdown-inner {
          width: 100% !important;
          max-width: 100%;
        }
      }
    }
  }

  .button-dropdown {
    @media (min-width: #{$desktop-s-min}) {
      width: auto !important;
    }

    @include mobile-and-tablet {
      flex-basis: calc(50% - 8px);
      width: 50% !important;
    }
  }

  .vod-sorting {
    @include mobile {
      flex-basis: 100%;
      margin-top: 8px;
    }
  }
}
</style>
