import { TStore } from 'src/store/types';

export const showNotificationSelector = (store: TStore) => store.adultOnboarding.showNotification;

export const withButtonSelector = (store: TStore) => store.adultOnboarding.withButton;

export const notificationTitleSelector = (store: TStore) => store.adultOnboarding.notificationTitle;

export const notificationMessageSelector = (store: TStore) =>
  store.adultOnboarding.notificationMessage;

export const nextStepSelector = (store: TStore) => store.adultOnboarding.nextStep;

export const adultProfileSelector = (store: TStore) =>
  (store.account?.profiles?.filter((profile) => {
    return profile.restriction === 'adult';
  }) || [])[0];

export const pinActionSelector = (store: TStore) => store.adultOnboarding.pinAction;

export const currentStepSelector = (store: TStore) => {
  return store.adultOnboarding.currentStep;
};
