<template>
  <div class="tabs" :class="theme">
    <button
      v-for="(tab, i) in tabs"
      :key="tab.text"
      type="button"
      class="tab"
      :class="{ active: tab.active }"
      @click="selectTab(i)"
    >
      <IconSVG :svg="tab.icon" />
      <span class="text" v-html="tab.text" />
    </button>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';

interface TTab {
  active: boolean;
  icon: Record<string, unknown>;
  text: string;
}

@Component({
  components: { IconSVG },
})
export default class NavigationTabs extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  tabs!: TTab[];

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  selectTab(index: number) {
    this.$emit('selectTab', index);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;

  .tab {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 24px;
    overflow: hidden;
    border-bottom: 4px solid transparent;

    &:last-child {
      margin-right: 0;
    }

    .icon {
      margin-right: 8px;
    }

    .text {
      @extend %truncate-after-1-line;
    }
  }

  // -----------------------------------------------
  // Theme Colors
  // -----------------------------------------------
  &.light {
    background-color: var(--c-light-200);

    .tab {
      color: var(--c-light-font-secondary);

      @include devices-with-hover {
        &:hover,
        &:active {
          color: var(--c-light-font-primary);
        }
      }

      &.active {
        color: var(--c-light-font-primary);
        border-bottom-color: var(--c-light-brand);
      }
    }
  }

  &.dark {
    background-color: var(--c-dark-200);

    .tab {
      color: var(--c-dark-font-secondary);

      @include devices-with-hover {
        &:hover,
        &:active {
          color: var(--c-dark-font-primary);
        }
      }

      &.active {
        color: var(--c-dark-font-primary);
        border-bottom-color: var(--c-dark-brand);
      }
    }
  }
}
</style>
