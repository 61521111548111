import { fe } from 'src/api/utils';
import { URLS } from 'src/constants';
import { TStore } from 'src/store/types';

export const loadProviderInfo = async (store: TStore) => {
  store.providerInfoV2 = await fe
    .get(URLS.providerInfoV2, {
      params: {
        providerId: store.account?.provider?.provider.id,
      },
    })
    .catch(() => {
      // do nothing
      return null;
    });

  if (!store.providerInfo) {
    store.providerInfo = await fe
      .get(URLS.providerInfo(store.account?.provider?.provider.id || 'default'))
      .catch(() => {
        // do nothing
        return null;
      });
  }
};
