<template>
  <div
    ref="slider"
    class="content-slider"
    :class="[`type-${type}`, theme, { 'highlight-visible': highlightVisible }]"
  >
    <div class="inner" :class="{ 'first-step': !canGoPrev, 'last-step': !canGoNext }">
      <!-- prev button -->
      <div class="nav-button left" :class="{ active: canGoPrev }" @click="goToPrev()">
        <ButtonCircle
          v-if="buttonsType === 'circled'"
          class="nav-button-icon circled"
          direction="left"
        />
        <IconSVG
          v-else-if="buttonsType === 'arrows'"
          class="nav-button-icon arrow"
          :svg="IconArrowLeft"
          :size="32"
        />
      </div>

      <!-- next button -->
      <div class="nav-button right" :class="{ active: canGoNext }" @click="goToNext()">
        <ButtonCircle
          v-if="buttonsType === 'circled'"
          class="nav-button-icon circled"
          direction="right"
        />
        <IconSVG
          v-else-if="buttonsType === 'arrows'"
          class="nav-button-icon arrow"
          :svg="IconArrowRight"
          :size="32"
        />
      </div>

      <!-- slider -->
      <div
        ref="slider-items"
        class="slider-items"
        :class="{
          row: type !== 'legacy',
          'with-side-padding': type !== 'legacy',
          'no-transition': disableAnimation,
          'animation-shake': hasShakeAnimation,
        }"
        :style="{
          transform: `translate3d(${translateX}px, 0, 0)`,
        }"
      >
        <slot />
      </div>
    </div>
    <!-- thumbnails -->
    <div v-if="thumbnails" class="thumbnails">
      <button
        v-for="(thumbnail, i) in thumbnails"
        type="button"
        :style="{ backgroundImage: `url('${thumbnail}')` }"
        class="thumbnail"
        :class="{ active: firstVisibleSlideIndex >= i && lastVisibleSlideIndex <= i }"
        @click="goTo(i)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import throttle from 'lodash/throttle';
import { sleep } from 'src/utils';
import * as arrayUtils from 'src/utils/array';
import ButtonCircle from 'src/components/ui/buttons/ButtonCircle.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconArrowLeft from 'src/svg/angle-left.svg';
import IconArrowRight from 'src/svg/angle-right.svg';

@Component({
  components: { IconSVG, ButtonCircle },
})
export default class ContentSlider extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  /**
   * Type of the slider
   * could be:
   * - fixed - used for fixed layout (e.g. each slide width = 300px)
   * - adaptive - used for adaptive layout (e.g. each slide width = 3 columns)
   * - carousel - used in banners carousel on home page (special layout)
   * - legacy - used in old content-pages (special legacy layout)
   * see https://zeroheight.com/4bd47b4a7/p/661f3d-contentslider/t/118eef
   */
  @Prop({ default: 'fixed' })
  type!: 'fixed' | 'adaptive' | 'carousel' | 'legacy';

  /**
   * Highlight visible slides (or fade invisible slides)
   */
  @Prop({ default: true })
  highlightVisible!: boolean;

  /**
   * Enable shake to right on mobile
   */
  @Prop({ default: false })
  shakeAnimation!: boolean;

  /**
   * Image preload (using for banners carousel)
   */
  @Prop({ default: false })
  withImagePreload!: boolean;

  /**
   * Auto rotation
   */
  @Prop({ default: 0 })
  autoRotation!: number;

  /**
   * Show thumbnails
   */
  @Prop()
  thumbnails?: string[];

  /**
   * Number of shifted slides
   */
  @Prop({ default: 0 })
  countSlidesChangeStep!: number;

  /**
   * Number all slides, need for recalculate width after change count slides
   */
  @Prop()
  countSlide?: number;

  @Watch('countSlide')
  async onChange() {
    this.$nextTick(() => {
      this.actualizeSlideWidth();
      this.initScroll(true);
    });
  }

  slidesWidth: number[] = [];
  slidesWidthSum = 0;
  isMounted = false;
  centerMode = false; // whether first slide is in the center and slides are looped, see Center mode https://kenwheeler.github.io/slick/
  buttonsType = 'circled'; // circled or arrows
  direction: null | 'left' | 'right' = null; // left or right
  translateX = 0; // in pixels, position of "scroll" emulated by translateX for non-touch devices
  prevTranslateX = 0; // in pixels
  firstVisibleSlideIndex = 0; // first visible slide index of set of slides, including clone ones
  lastVisibleSlideIndex = 0; // first visible slide index of set of slides, including clone ones
  firstOriginalVisibleSlideIndex = 0; // first visible slide index of the original set of slides
  lastOriginalVisibleSlideIndex = 0; // last visible slide index of the original set of slides
  slidesPerPage: number | null = null; // number of slides visible on the page
  disableAnimation = false;
  prevWindowWidth = process.env.VUE_ENV === 'client' ? window.innerWidth : 0; // to fire resize event when window width changed
  IconArrowLeft = IconArrowLeft;
  IconArrowRight = IconArrowRight;
  onResizeThrottle = throttle(this.onResize, 200);
  mutationObserver!: MutationObserver;
  wasInteracted = false;
  slidesLength = 0; // we need this to make canGoNext getter work
  prevSlidesLength = 0; // and this is to check whether slides length was changed and if so - force scroll init
  autoRotationIntervalId?: NodeJS.Timeout;

  @Ref('slider')
  refSlider?: HTMLDivElement;

  @Ref('slider-items')
  refSliderItems?: HTMLDivElement;

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get canGoPrev() {
    return this.centerMode || this.firstVisibleSlideIndex > 0;
  }

  get canGoNext() {
    if (!this.isMounted) {
      return false;
    }

    return this.centerMode || this.firstVisibleSlideIndex < this.calcMaxVisibleSlideIndex();
  }

  get hasShakeAnimation() {
    return !this.centerMode && this.shakeAnimation && !this.wasInteracted;
  }

  created() {
    this.initDataVars();
    this.initScroll(true);
  }

  async mounted() {
    this.actualizeSlideWidth();

    if (this.centerMode) {
      this.prepareCenterMode(); // clone slides
    }

    // Create the observer (and what to do on changes...)
    this.mutationObserver = new MutationObserver(async () => {
      await this.initScroll();
    });

    // events
    window?.addEventListener('resize', this.onResizeThrottle);
    if (this.refSliderItems) {
      // Set up the observer
      this.mutationObserver.observe(this.refSliderItems, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
      });

      this.refSliderItems.addEventListener('click', this.actualizeVisibleClass);

      if (this.shakeAnimation) {
        this.refSliderItems.addEventListener('scroll', this.onScroll);
      }

      if (['carousel', 'legacy'].includes(this.type)) {
        await this.bindHammerEvents();
      }
    }

    // auto-rotation
    if (this.autoRotation && window) {
      this.stopAutoRotation();
      window.onload = () => {
        this.startAutoRotation();
      };
      document.addEventListener('visibilitychange', this.actualizeAutoRotation);
      this.refSlider?.addEventListener('mouseenter', this.stopAutoRotation);
      this.refSlider?.addEventListener('mouseleave', this.startAutoRotation);
    }

    this.isMounted = true;
  }

  beforeDestroy() {
    if (this.refSliderItems) {
      this.refSliderItems.removeEventListener('click', this.actualizeVisibleClass);
      if (this.shakeAnimation) {
        this.refSliderItems.removeEventListener('scroll', this.onScroll);
      }
    }
    if (this.autoRotation && window) {
      this.stopAutoRotation();
      document.removeEventListener('visibilitychange', this.actualizeAutoRotation);
      this.refSlider?.removeEventListener('mouseenter', this.stopAutoRotation);
      this.refSlider?.removeEventListener('mouseleave', this.startAutoRotation);
    }
    window?.removeEventListener('resize', this.onResizeThrottle);
    this.mutationObserver.disconnect();
  }

  startAutoRotation() {
    if (this.autoRotationIntervalId) {
      return;
    }
    this.autoRotationIntervalId = setInterval(() => {
      this.goToNext(false);
    }, this.autoRotation);
  }

  stopAutoRotation() {
    if (this.autoRotationIntervalId) {
      clearInterval(this.autoRotationIntervalId);
    }
  }

  restartAutoRotation() {
    this.stopAutoRotation();
    this.startAutoRotation();
  }

  actualizeAutoRotation() {
    if (document.hidden) {
      this.stopAutoRotation();
    } else {
      this.startAutoRotation();
    }
  }

  onScroll() {
    this.wasInteracted = true;
  }

  async bindHammerEvents() {
    if (!this.refSliderItems) {
      return;
    }
    let needChangeSlide = false;
    const Hammer = (await import(/* webpackChunkName: "hammerjs" */ 'hammerjs')).default;
    const hammerSliderItems = new Hammer(this.refSliderItems);

    hammerSliderItems?.on('panstart', () => {
      needChangeSlide = true;
    });

    hammerSliderItems?.on('panmove', (e) => {
      if (!this.refSliderItems) {
        return;
      }
      this.refSliderItems.classList.remove('animation-shake');
      const minDeltaX = 20;
      if (e.deltaX > minDeltaX) {
        this.direction = 'left';
      } else if (e.deltaX < -minDeltaX) {
        this.direction = 'right';
      } else {
        this.direction = null;
      }

      if (needChangeSlide) {
        if (this.direction === 'left') {
          this.goToPrev();
          needChangeSlide = false;
        } else if (this.direction === 'right') {
          this.goToNext();
          needChangeSlide = false;
        }
      }
    });
  }

  async onResize() {
    if (this.prevWindowWidth === window.innerWidth) {
      this.prevWindowWidth = window.innerWidth;
      return;
    }
    this.actualizeSlideWidth();
    this.prevWindowWidth = window.innerWidth;
    this.preloadVisibleSlides();
    await this.initScroll(true);
  }

  /**
   * Set proper data-vars values according to the type of the slider
   */
  initDataVars() {
    this.centerMode = this.type === 'carousel';
    this.buttonsType = ['carousel', 'adaptive'].includes(this.type) ? 'arrows' : 'circled';
  }

  async initScroll(force = false) {
    let activeIndexSlide = 0;
    const slides = this.getSlides();
    const slidesLength = this.getSlidesLength();
    const needInit =
      force ||
      !slides[0]?.classList.contains('content-slider-initialized') ||
      slidesLength !== this.prevSlidesLength;

    if (!needInit) {
      return;
    }

    this.slidesLength = slidesLength;
    this.prevSlidesLength = slidesLength;
    this.firstVisibleSlideIndex = 0;
    this.firstOriginalVisibleSlideIndex = 0;
    this.lastVisibleSlideIndex = this.getLastVisibleSlideIndex();
    this.lastOriginalVisibleSlideIndex = this.getLastVisibleSlideIndex();
    this.slidesPerPage = this.lastOriginalVisibleSlideIndex + 1; // actualize number of slides visible on the page
    this.preloadVisibleSlides();
    await this.actualizeTranslateX(true); // for non-touch devices (calculates and sets translateX)
    this.$emit('ready');

    for (const i in slides) {
      if (!slides.hasOwnProperty(i)) {
        continue;
      }
      if (slides[i].classList.contains('active')) {
        activeIndexSlide = Number(i);
      }
      (slides[i] as HTMLElement)?.classList.add('content-slider-initialized');
    }

    if (
      this.countSlidesChangeStep &&
      activeIndexSlide > 0 &&
      activeIndexSlide > this.lastVisibleSlideIndex - 1
    ) {
      const slideToIndex = activeIndexSlide - this.lastVisibleSlideIndex + 2;
      await this.goTo(slideToIndex);
      this.scrollTo(this.getWidthBeforeActiveIndex(slideToIndex), true);
    }
  }

  /**
   * Return HTML collection of slides (children of sliderItems container)
   */
  getSlides() {
    return this.refSliderItems?.children || [];
  }

  /**
   * Width of the slider itself
   */
  getSliderWidth() {
    return this.refSlider?.offsetWidth || 0;
  }

  /**
   * Returns width of all slides with margins
   */
  getSlidesWidth() {
    return [...this.getSlides()].map((slide) => this.calculateSlideWidth(slide));
  }

  /**
   * Calculates slide's width
   * @param slide
   */
  calculateSlideWidth(slide: Element) {
    let width = slide.getBoundingClientRect().width;

    for (const property of ['margin-left', 'margin-right']) {
      width += this.getComputedStyle(slide, property);
    }
    return width;
  }

  /**
   * Actualizes slide width
   */
  actualizeSlideWidth() {
    this.slidesWidth = this.getSlidesWidth();
    this.slidesWidthSum = arrayUtils.sum(this.slidesWidth) || 0;
  }

  /**
   * Amount of all slides (in-viewport and behind the scroll)
   */
  getSlidesLength() {
    const slidesLength = this.getSlides().length;
    return this.centerMode ? slidesLength / 3 : slidesLength;
  }

  /**
   * Amount visible (in-viewport) slides
   */
  getVisibleSlidesLength() {
    const slides = this.getSlides();
    const slide = slides ? slides[0] : null;
    let sliderWidth = this.getSliderWidth();
    if (slide) {
      for (const property of ['margin-left', 'margin-right']) {
        // we have to compensate margin-left of the first slider and margin-right of the last slider
        sliderWidth += this.getComputedStyle(slide, property);
      }
    }
    const firstSlideWidth = this.slidesWidth[0]; // TODO: add support for slides with different width
    return sliderWidth > 0 ? Math.floor(sliderWidth / firstSlideWidth) : 0;
  }

  /**
   * Returns last visible slide index
   */
  calcMaxVisibleSlideIndex() {
    const sliderWidth = this.getWidthSliderWithoutIndent();
    let sum = 0;
    let findIndex = 0;

    for (const [index, el] of this.slidesWidth.entries()) {
      sum += el;

      if (sum >= this.slidesWidthSum - sliderWidth) {
        findIndex = index + 1;
        break;
      }
    }

    return sliderWidth >= this.slidesWidthSum ? 0 : findIndex;
  }

  /**
   * Calculates maximum position of the first visible slide (e.g. to calculate amount of slides to scroll)
   */
  getMaxTranslateX() {
    if (!this.refSliderItems) {
      return 0;
    }

    // max position (position of left border of the first visible slide) equals all-slides-width minus container-width
    return Math.max(0, this.slidesWidthSum - this.getWidthSliderWithoutIndent());
  }

  /**
   * Returns width slider without padding and margin
   */
  getWidthSliderWithoutIndent() {
    if (!this.refSliderItems) {
      return 0;
    }

    let sliderWidth = this.getSliderWidth();
    for (const property of ['margin-left', 'margin-right', 'padding-left', 'padding-right']) {
      sliderWidth -= this.getComputedStyle(this.refSliderItems, property);
    }

    return sliderWidth;
  }

  getWidthBeforeActiveIndex(index: number) {
    return this.slidesWidth.slice(0, index).reduce((sum, item) => {
      sum += item;
      return sum;
    }, 0);
  }

  /**
   * Returns first visible slide index by direction (left or right)
   */
  getFirstVisibleSlideIndexByDirection(direction: 'left' | 'right') {
    const sliderWidth = this.getSliderWidth();

    let findIndex = 0;
    let sum = 0;
    const widthBeforeActiveIndex = this.getWidthBeforeActiveIndex(this.firstVisibleSlideIndex);

    if (this.countSlidesChangeStep === 0) {
      if (this.centerMode) {
        if (direction === 'left') {
          findIndex = this.firstVisibleSlideIndex - this.getVisibleSlidesLength();
        } else {
          findIndex = this.firstVisibleSlideIndex + this.getVisibleSlidesLength();
        }
      } else {
        const slides =
          direction === 'right'
            ? this.slidesWidth
            : [...this.slidesWidth].slice(0, this.firstVisibleSlideIndex).reverse();

        for (const [index, el] of slides.entries()) {
          sum += el;
          if (direction === 'right') {
            if (sum >= widthBeforeActiveIndex + sliderWidth) {
              findIndex = index;
              break;
            }
          } else if (direction === 'left') {
            if (sum >= widthBeforeActiveIndex - sliderWidth) {
              findIndex = index > 0 ? slides.length - index - 1 : 0;
              break;
            }
          }
        }
      }
    } else {
      findIndex =
        direction === 'right'
          ? this.firstVisibleSlideIndex + this.countSlidesChangeStep
          : this.firstVisibleSlideIndex - this.countSlidesChangeStep;
    }

    return findIndex;
  }

  /**
   * Returns last visible slide index
   */
  getLastVisibleSlideIndex() {
    const slidesLength = this.getSlidesLength();
    const visibleSlidesLength = this.getVisibleSlidesLength();
    const lastSlideIndex = this.firstVisibleSlideIndex + visibleSlidesLength - 1;
    const maxLastSlideIndex = slidesLength - 1;
    return this.centerMode ? lastSlideIndex : Math.min(lastSlideIndex, maxLastSlideIndex);
  }

  /**
   *  Clone all items to prepend and append cloned items, e.g. [1,2,3] -> [1,2,3, 1,2,3, 1,2,3]
   *  This is used to make smooth transition from the first element of the middle group to the last element of the same group and vice-versa
   *  In other words this is used to make an infinite loop
   */
  prepareCenterMode() {
    const slides = this.getSlides();

    const leftSideClones = []; // clones on the left side from origin set of banners
    for (const slide of slides) {
      leftSideClones.push(slide.cloneNode(true));
    }
    const rightSideClones = []; // clones on the right side from origin set of banners
    for (const slide of slides) {
      rightSideClones.push(slide.cloneNode(true));
    }

    if (this.refSliderItems) {
      // prepend cloned banners
      leftSideClones.reverse(); // start prepending from the last element to the first one
      for (const clone of leftSideClones) {
        this.refSliderItems.prepend(clone);
      }
      // append cloned banners
      for (const clone of rightSideClones) {
        this.refSliderItems.append(clone);
      }
    }
  }

  /**
   * Actualizes slider translateX according to the first visible slide
   */
  async actualizeTranslateX(disableAnimation = false) {
    const translateX = this.centerMode
      ? this.getTranslateXInCenterMode()
      : this.getTranslateXInNormalMode();
    await this.setTranslateX(translateX, disableAnimation);
    this.actualizeVisibleClass();
    this.prevTranslateX = translateX;
  }

  /**
   * Calculates the position of the first slide not in center mode
   */
  getTranslateXInNormalMode() {
    const translateX = this.calcTranslateX();
    const maxTranslateX = this.getMaxTranslateX();
    return -Math.min(translateX, maxTranslateX);
  }

  calcTranslateX() {
    let nextTranslateX = 0;

    if (this.firstVisibleSlideIndex > 0) {
      for (const [index, el] of this.slidesWidth.entries()) {
        if (index >= this.firstVisibleSlideIndex) {
          break;
        }
        nextTranslateX += el;
      }
    }

    if (nextTranslateX < 0) {
      nextTranslateX = 0;
    }

    if (nextTranslateX > this.getMaxTranslateX()) {
      nextTranslateX = this.getMaxTranslateX();
    }

    return nextTranslateX;
  }

  /**
   * Calculates the position of the first slide in center mode
   */
  getTranslateXInCenterMode() {
    if (!this.refSliderItems) {
      return this.prevTranslateX;
    }
    let sliderWidth = this.getSliderWidth();
    for (const property of ['margin-left', 'margin-right', 'padding-left', 'padding-right']) {
      sliderWidth -= this.getComputedStyle(this.refSliderItems, property);
    }
    const slidesLength = this.getSlidesLength();

    const firstSlideWidth = this.slidesWidth[0]; // assume that in center mode all slides have equal width | TODO: add support for slides with different width in center mode
    const scrolledDistance = -firstSlideWidth * (this.firstVisibleSlideIndex + slidesLength);
    const slidePosition = sliderWidth / 2 - firstSlideWidth / 2;
    return scrolledDistance + slidePosition;
  }

  /**
   * Sets the position of the first slide
   * disableAnimation flag is used in loop mode
   */
  async setTranslateX(translateX: number, disableAnimation: boolean) {
    if (disableAnimation) {
      this.disableAnimation = true;
      this.translateX = translateX;
      await sleep(10);
      this.disableAnimation = false;
    } else {
      this.translateX = translateX;
    }
  }

  /**
   * Adds .visible class to the visible (in-viewport) slides and removes .visible class from the slides behind the scroll
   */
  actualizeVisibleClass() {
    this.resetVisibleClass();
    const slides = this.getSlides();
    const slidesLength = this.getSlidesLength();

    for (const i in slides) {
      if (!slides.hasOwnProperty(i)) {
        continue;
      }
      const index = parseInt(i);

      let isVisible;

      if (this.centerMode) {
        const visibleSlideIndex = this.firstVisibleSlideIndex + slidesLength; // only one slide in center mode
        isVisible = index === visibleSlideIndex;
      } else {
        isVisible = index >= this.firstVisibleSlideIndex && index <= this.lastVisibleSlideIndex;
      }

      const className = isVisible ? 'visible' : '';
      if (className) {
        slides[i]?.classList.add(className);
      }
    }
  }

  /**
   * Removes .visible class from slides
   */
  resetVisibleClass() {
    if (!this.refSliderItems) {
      return;
    }
    const visibleItems = this.refSliderItems.querySelectorAll('.visible');
    if (visibleItems && visibleItems.length) {
      visibleItems.forEach((visibleItem) => visibleItem.classList.remove('visible'));
    }
  }

  /**
   * Go to previous slide
   */
  async goToPrev() {
    const slidesLength = this.getSlidesLength();
    this.direction = 'left';
    this.wasInteracted = true;

    if (this.autoRotation) {
      this.restartAutoRotation();
    }

    if (!this.canGoPrev) {
      // if it's not centerMode and the first visible slide is the first one - do nothing
      return;
    }

    this.firstVisibleSlideIndex = this.getFirstVisibleSlideIndexByDirection(this.direction);
    this.firstOriginalVisibleSlideIndex =
      this.firstVisibleSlideIndex < 0 // if current slide index less than zero (in center mode)
        ? slidesLength + this.firstVisibleSlideIndex // count slide index from the end
        : this.firstVisibleSlideIndex; // otherwise, it's just equal to the current slide index

    this.lastVisibleSlideIndex = this.getLastVisibleSlideIndex();
    this.lastOriginalVisibleSlideIndex =
      this.lastVisibleSlideIndex > slidesLength - 1 // if current slide index more than slides length (in center mode)
        ? this.lastVisibleSlideIndex - slidesLength // count slide index from the beginning
        : this.lastVisibleSlideIndex; // otherwise, it's just equal to the current slide index

    this.preloadVisibleSlides();
    await this.actualizeTranslateX();
    if (this.$listeners['gaScroll']) {
      this.$emit('gaScroll', this.firstOriginalVisibleSlideIndex);
    }

    if (this.firstVisibleSlideIndex < -1) {
      setTimeout(async () => {
        this.firstVisibleSlideIndex = this.firstOriginalVisibleSlideIndex;
        this.lastVisibleSlideIndex = this.getLastVisibleSlideIndex();
        this.preloadVisibleSlides();
        await this.actualizeTranslateX(true);
      }, 400);
    }
  }

  /**
   * Go to next slide
   */
  async goToNext(clearAutoRotationInterval = true) {
    const slidesLength = this.getSlidesLength();
    this.direction = 'right';
    this.wasInteracted = true;

    if (this.autoRotation && clearAutoRotationInterval) {
      this.restartAutoRotation();
    }

    if (!this.canGoNext) {
      // if it's not centerMode and the last visible slide is the last one - do nothing
      return;
    }

    this.firstVisibleSlideIndex = this.getFirstVisibleSlideIndexByDirection(this.direction);
    this.lastVisibleSlideIndex = this.getLastVisibleSlideIndex();

    // when number of leftover slides to show is less than number of slides visible on the page
    if (this.slidesPerPage && this.type !== 'fixed' && this.type !== 'legacy') {
      if (this.lastVisibleSlideIndex - this.firstVisibleSlideIndex < this.slidesPerPage - 1) {
        this.firstVisibleSlideIndex = this.lastVisibleSlideIndex - this.slidesPerPage + 1;
      }
    }

    this.firstOriginalVisibleSlideIndex =
      this.firstVisibleSlideIndex > slidesLength - 1 // if current slide index less than zero (in center mode)
        ? this.firstVisibleSlideIndex - slidesLength // count slide index from the beginning
        : this.firstVisibleSlideIndex; // otherwise, it's just equal to the current slide index

    this.lastOriginalVisibleSlideIndex =
      this.lastVisibleSlideIndex > slidesLength - 1 // if current slide index more than slides length (in center mode)
        ? this.lastVisibleSlideIndex - slidesLength // count slide index from the beginning
        : this.lastVisibleSlideIndex; // otherwise, it's just equal to the current slide index

    this.preloadVisibleSlides();
    await this.actualizeTranslateX();

    if (this.$listeners['gaScroll']) {
      this.$emit('gaScroll', this.firstOriginalVisibleSlideIndex);
    }

    if (this.lastVisibleSlideIndex > slidesLength) {
      setTimeout(async () => {
        this.firstVisibleSlideIndex = this.firstOriginalVisibleSlideIndex;
        this.lastVisibleSlideIndex = this.getLastVisibleSlideIndex();
        this.preloadVisibleSlides();
        await this.actualizeTranslateX(true);
      }, 400);
    }
  }

  /**
   * Go N slide (for thumbnails)
   */
  async goTo(index: number) {
    this.firstVisibleSlideIndex = index;
    this.firstOriginalVisibleSlideIndex = index;
    this.lastVisibleSlideIndex = index;
    this.lastOriginalVisibleSlideIndex = index;

    if (this.autoRotation) {
      this.restartAutoRotation();
    }

    this.preloadVisibleSlides();
    await this.actualizeTranslateX();

    if (this.$listeners['gaScroll']) {
      this.$emit('gaScroll', this.firstOriginalVisibleSlideIndex);
    }
  }

  scrollTo(scrollPosition: number, disableAnimation: boolean) {
    if (this.autoRotation) {
      this.restartAutoRotation();
    }
    if (!this.refSliderItems) {
      return;
    }
    this.refSliderItems.scrollTo({
      left: scrollPosition,
      behavior: disableAnimation ? 'auto' : 'smooth',
    });
  }

  getComputedStyle(element: Element, property: string) {
    return parseInt(getComputedStyle(element).getPropertyValue(property).replace('px', '')) || 0;
  }

  preloadVisibleSlides() {
    if (!this.withImagePreload) {
      return;
    }
    const slides = [...this.getSlides()];
    const slidesLength = this.getSlidesLength();
    const firstRealVisibleSlideIndex = slidesLength + this.firstVisibleSlideIndex;
    const lastRealVisibleSlideIndex = slidesLength + this.lastVisibleSlideIndex;
    let preloadImageIndexFrom = Math.max(0, firstRealVisibleSlideIndex - 1);
    preloadImageIndexFrom = Math.min(slides.length - 1, preloadImageIndexFrom);
    let preloadImageIndexTo = Math.max(0, firstRealVisibleSlideIndex + 2);
    preloadImageIndexTo = Math.min(slides.length - 1, preloadImageIndexTo);
    let visibleSlides: Element[] = [];
    if (firstRealVisibleSlideIndex === lastRealVisibleSlideIndex) {
      if (slides[firstRealVisibleSlideIndex]) {
        visibleSlides = [...slides.slice(preloadImageIndexFrom, preloadImageIndexTo)];
      }
    } else if (firstRealVisibleSlideIndex !== lastRealVisibleSlideIndex) {
      if (slides[firstRealVisibleSlideIndex]) {
        visibleSlides = [...slides.slice(preloadImageIndexFrom, preloadImageIndexTo)];
      }
    }
    visibleSlides.forEach((slide) => {
      slide.querySelectorAll<HTMLImageElement>('img[data-src]').forEach((img) => {
        if (window.getComputedStyle(img).display === 'none') {
          return;
        }
        if (img.src !== img.dataset.src) {
          img.src = img.dataset.src || '';
        }
        if (img.dataset.srcset && img.srcset !== img.dataset.srcset) {
          img.srcset = img.dataset.srcset;
        }
      });
    });
  }
}
</script>

<style lang="scss">
@import 'src/styles/placeholders-and-mixins/media-queries';

.content-slider {
  .slider-items > * {
    flex-shrink: 0;
  }

  &.type-fixed .slider-items > * {
    transition: transition-slider-fixed(opacity), transition-slider-fixed(transform),
      transition-slider-fixed(box-shadow);
  }

  &.type-adaptive .slider-items > *,
  &.type-carousel .slider-items > * {
    transition: transition-slider-adaptive(opacity), transition-slider-adaptive(transform),
      transition-slider-adaptive(box-shadow);
  }

  @include devices-with-hover {
    &.highlight-visible:hover .slider-items > * {
      opacity: 0.3;

      &.visible {
        opacity: 1 !important;
      }
    }

    &.highlight-visible:hover .slider-items > * {
      @include devices-with-touch {
        opacity: 1 !important;
      }
    }

    &.type-carousel.highlight-visible:hover .slider-items > * {
      opacity: 0.5;
    }
  }

  &.type-adaptive,
  &.type-fixed {
    .slider-items > * {
      @include devices-with-touch {
        display: inline-flex; // to make space after the last element in the slider according to side-padding
        white-space: normal;
        vertical-align: top;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/common/grid-variables';
@import 'src/styles/common/paddings-variables';
@import 'src/styles/common/dimensions-variables';

@function transition-slider-fixed($property) {
  @return #{$property} var(--ease-out) 0.15s;
}

@function transition-slider-adaptive($property) {
  @return #{$property} var(--ease-out) 0.25s;
}

$vertical-offset-regular: 16px;
$vertical-offset-carousel: 32px;

.content-slider {
  margin: -$vertical-offset-regular 0; // to compensate extra padding for hover translateY

  .inner {
    position: relative;
    overflow: hidden;
  }

  &.type-carousel {
    margin: -$vertical-offset-carousel 0; // to compensate extra padding for hover translateY
  }

  .slider-items {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    padding-top: $vertical-offset-regular; // to compensate hover translateY
    transition: transform var(--ease-out) 0.5s;
    scrollbar-width: none;

    @include devices-with-touch {
      &.animation-shake {
        left: 0;
        animation: 5s ease-in-out infinite shake;
      }

      @keyframes shake {
        0% {
          left: 0;
        }

        5% {
          left: 0;
        }

        10% {
          left: 0;
        }

        15% {
          left: 0;
        }

        20% {
          left: -16px;
        }

        25% {
          left: 0;
        }

        100% {
          left: 0;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &.no-transition {
      transition: none;
    }
  }

  &.type-adaptive,
  &.type-fixed {
    .slider-items {
      @include devices-with-touch {
        display: block;
        padding-top: $vertical-offset-regular; // to compensate margin in .content-slider
        padding-bottom: $vertical-offset-regular * 2 + $vertical-offset-regular; // to hide native scrollbar and compensate margin in .content-slider
        margin-bottom: -$vertical-offset-regular * 2; // to hide native scrollbar
        overflow: auto;
        white-space: nowrap;
        transform: none !important;
      }
    }
  }

  &.type-carousel .slider-items {
    padding-top: $vertical-offset-carousel; // to compensate hover translateY
    padding-bottom: $vertical-offset-carousel; // to compensate hover translateY
  }

  .nav-button {
    position: absolute;
    top: 0;
    z-index: var(--z-1);
    width: 64px;
    height: 100%;
    pointer-events: none;
    user-select: none;
    opacity: 0;
    transition: var(--ease-in-out) 0.15s opacity;

    @include tablet {
      width: 48px;
    }

    @include mobile {
      width: 24px;
    }

    @include devices-with-touch {
      display: none;
    }

    &.active {
      pointer-events: auto;
      cursor: pointer;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  &.type-carousel .nav-button {
    @media (min-width: #{$content-max-width}) {
      width: 35%;

      &.left {
        right: 50%;
        left: auto;
        transform: translateX(#{- (calc($content-max-width / 2)) - 15});
      }

      &.right {
        right: auto;
        left: 50%;
        transform: translateX(#{calc($content-max-width / 2) + 15});
      }
    }
  }

  .nav-button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: var(--z-2);
    opacity: 0;
    transform: translate(-50%, -50%);

    &.arrow {
      @include mobile {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.type-carousel .nav-button.left .nav-button-icon {
    right: 16px;
    left: auto;
    transform: translateY(-50%);
  }

  &.type-carousel .nav-button.right .nav-button-icon {
    right: auto;
    left: 16px;
    transform: translateY(-50%);
  }

  .nav-button.left .nav-button-icon.circled,
  .nav-button.right .nav-button-icon.circled {
    left: 50%;
  }

  @include devices-with-hover {
    .nav-button.left:hover ~ .slider-items::v-deep .before-visible,
    .nav-button.right:hover ~ .slider-items::v-deep .after-visible {
      opacity: 0.1;
    }
  }

  &.type-adaptive .nav-button-icon {
    transition: transition-slider-adaptive(all);
  }

  &.type-fixed .nav-button-icon {
    transition: transition-slider-fixed(all);
  }

  @include devices-with-hover {
    &:hover {
      .nav-button {
        opacity: 1;
      }
    }
  }

  .nav-button::after {
    content: '';
    position: absolute;
    top: 0;
    width: 24px;
    height: 100%;
    opacity: 0;
  }

  .nav-button.active::after,
  .nav-button.active .nav-button-icon {
    opacity: 1;
  }

  .nav-button.left::after {
    left: 0;
    transform: translateX(-100%);
  }

  .nav-button.right::after {
    right: 0;
    transform: translateX(100%);
  }

  .thumbnails {
    display: flex;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .thumbnail {
    width: 114px;
    height: 70px;
    margin: 20px 10px;
    background-size: cover;
    border: 2px solid transparent;

    &.active {
      border-color: var(--c-light-brand);
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    @include devices-with-hover {
      &:hover .nav-button {
        color: var(--alpha-light-7);

        &:hover {
          color: var(--alpha-light-8);
        }

        &:active {
          color: var(--alpha-light-9);
        }
      }
    }

    &.type-fixed .nav-button.left::after,
    &.type-adaptive .nav-button.left::after {
      box-shadow: 12px 0 12px -8px rgba(0, 0, 0, 0.15);
    }

    &.type-fixed .nav-button.right::after,
    &.type-adaptive .nav-button.right::after {
      box-shadow: -12px 0 12px -8px rgba(0, 0, 0, 0.15);
    }

    .thumbnails {
      background-color: var(--c-light-150);
    }
  }

  &.dark {
    @include devices-with-hover {
      &:hover .nav-button {
        color: var(--alpha-dark-7);

        &:hover {
          color: var(--alpha-dark-8);
        }

        &:active {
          color: var(--alpha-dark-9);
        }
      }
    }

    &.type-fixed .nav-button.left::after,
    &.type-adaptive .nav-button.left::after {
      box-shadow: -12px 0 12px -8px rgba(0, 0, 0, 0.7);
    }

    &.type-fixed .nav-button.right::after,
    &.type-adaptive .nav-button.right::after {
      box-shadow: -12px 0 12px -8px rgba(0, 0, 0, 0.7);
    }

    .thumbnails {
      background-color: var(--c-dark-150);
    }
  }
}
</style>
