import { render, staticRenderFns } from "./ButtonFileDownload.vue?vue&type=template&id=322cb6ee&scoped=true&"
import script from "./ButtonFileDownload.vue?vue&type=script&lang=ts&"
export * from "./ButtonFileDownload.vue?vue&type=script&lang=ts&"
import style0 from "./ButtonFileDownload.vue?vue&type=style&index=0&id=322cb6ee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "322cb6ee",
  null
  
)

export default component.exports