<template>
  <div v-show="isMounted" ref="genreFilter" class="channels-genre-filter" :class="theme">
    <template v-if="favoriteGenre">
      <ChipSelector
        ref="genreFav"
        class="genre fav"
        :class="{ selected: favoriteGenre && favoriteGenre.title === currentGenre }"
        :theme-forced="theme"
        :text="favoriteGenre ? getGenreTitleWithTranslation(favoriteGenre.title) : ''"
        :icon-leading="IconAir"
        :icon-leading-path="getGenreIconPath(favoriteGenre)"
        @click="selectGenre(favoriteGenre)"
      />

      <span class="separator mb-8 hidden-mobile-and-tablet" />
    </template>

    <div v-if="!shouldShowAllGenres" ref="genres" class="genres" :style="{ width: genresDivWidth }">
      <ChipSelector
        v-for="(genre, i) in genresWithoutFavorites"
        :key="genre.title"
        class="genre"
        :class="[{ selected: genre.title === currentGenre }, `genre-${i}`]"
        :theme-forced="theme"
        :text="getGenreTitleWithTranslation(genre.title)"
        :icon-leading="IconAir"
        :icon-leading-path="getGenreIconPath(genre)"
        @click="selectGenre(genre)"
      />
    </div>

    <ChipSelector
      v-else
      v-for="(genre, i) in genresWithoutFavorites"
      :key="genre.title"
      class="genre"
      :class="[{ selected: genre.title === currentGenre }, `genre-${i}`]"
      :theme-forced="theme"
      :text="getGenreTitleWithTranslation(genre.title)"
      :icon-leading="IconAir"
      :icon-leading-path="getGenreIconPath(genre)"
      @click="selectGenre(genre)"
    />

    <ButtonAction
      v-if="genresForDropdown.length && !shouldShowAllGenres"
      class="mb-8 hidden-mobile-and-tablet"
      data-cy="genre-filter"
      :icon="IconMoreHorizontal"
      @click="showAllGenres"
    />

    <DropdownSequoia
      v-if="genresForDropdown.length"
      v-slot="{ value, opened, disabled, toggle }"
      :list="genresForDropdown"
      :transform-native-select="true"
      width="auto"
      class="visible-mobile-and-tablet hidden-desktop"
      data-cy="genres"
      @select="selectGenreFromDropdown"
    >
      <ButtonDropdown
        data-cy="genre-filter"
        size="small"
        :floating="true"
        :placeholder="getTranslation('filters_genres')"
        :disabled="disabled"
        :open="opened"
        :width="isMobileOrTablet ? '200px' : 'content'"
        :value="value"
        @click="toggle"
      />
    </DropdownSequoia>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import AddResizeListener from 'src/mixins/AddResizeListener';
import Global from 'src/mixins/Global';
import Metrics from 'src/mixins/Metrics';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { TV_SPECIAL_GENRES } from 'src/constants';
import { TGenre } from 'src/store/tv-channels/types';
import IconMoreHorizontal from 'src/svg/more-horizontal.svg';
import ChipSelector from 'src/components/ui/ChipSelector.vue';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import { getDeviceFlags } from 'src/utils/platform-detector';
import IconAir from 'src/svg/genres/air.svg';

@Component({
  components: { ChipSelector, ButtonAction, ButtonDropdown, DropdownSequoia },
})
export default class GenreFilter extends mixins(Global, Metrics, AddResizeListener) {
  IconMoreHorizontal = IconMoreHorizontal;
  IconAir = IconAir;

  hiddenGenres: TGenre[] = [];
  shouldShowAllGenres = false;
  genresDivWidth = 'calc(100% - 200px)';
  isMounted = false;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Ref('genreFilter')
  refGenreFilter?: HTMLDivElement;

  @Ref('genreFav')
  refGenreFav?: Vue;

  @Ref('genres')
  refGenres?: HTMLDivElement;

  @Watch('windowWidth')
  async onWindowWidthChange() {
    await this.getHiddenGenres();
  }

  get isMobileOrTablet() {
    return getDeviceFlags().isMobile || getDeviceFlags().isTablet || this.windowWidth < 960;
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get currentGenre() {
    return selectors.tvChannels.currentGenreSelector(this.$store);
  }

  get favoriteGenre() {
    return this.genres.find((genre) => genre.title === TV_SPECIAL_GENRES.favourite);
  }

  get genres() {
    return selectors.tvChannels.genresSelector(this.$store);
  }

  get genresWithoutFavorites() {
    return this.genres.filter(
      (genre) =>
        this.getFilteredChannels(genre.title).length &&
        genre.title !== TV_SPECIAL_GENRES.favourite &&
        (!this.isAnonymous ||
          (this.isAnonymous && genre.title !== TV_SPECIAL_GENRES.recentlyWatched))
    );
  }

  get genresForDropdown() {
    const genres = this.windowWidth < 960 ? this.genresWithoutFavorites : this.hiddenGenres;

    return genres.map((genre, i) => ({
      key: (i + 1).toString(),
      name: genre.title,
      value: this.getGenreTitleWithTranslation(genre.title),
      selected: genre.title === this.currentGenre,
    }));
  }

  async mounted() {
    this.isMounted = true;
  }

  async getHiddenGenres() {
    if (this.refGenres && this.refGenreFav?.$el) {
      this.genresDivWidth = `calc(100% - ${
        this.refGenreFav.$el.getBoundingClientRect().width + 24 + 48
      }px)`;
      this.hiddenGenres.length = 0;
      this.genresWithoutFavorites.forEach((genre, i) => {
        if (!this.isGenreVisible(i) || this.windowWidth < 960) {
          this.hiddenGenres.push(genre);
        }
      });
    }
  }

  isGenreVisible(i: number) {
    if (typeof document !== 'undefined') {
      const el = document?.querySelector(`.genre-${i}`);
      const elRect = el?.getBoundingClientRect();
      const wrapRect = this.refGenres?.getBoundingClientRect();

      if (elRect && this.refGenres && wrapRect) {
        return elRect.right <= this.refGenres.clientWidth + wrapRect.left;
      }
    }
  }

  getGenreTitleWithTranslation(title: string) {
    return selectors.tvChannels.genreTitleSelector(this.$store, title);
  }

  getGenreIconPath(genre: TGenre) {
    return selectors.tvChannels.genreIconSelector(this.$store, genre);
  }

  getFilteredChannels(genre: string) {
    return Object.keys(selectors.tvChannels.filteredChannelsSelector(this.$store, genre, true));
  }

  selectGenre(genre: TGenre) {
    if (genre.title === TV_SPECIAL_GENRES.favourite && this.isAnonymous) {
      actions.common.showNotificationAuthAndReg(this.$store);
      return;
    }

    actions.tvChannels.selectGenre(this.$store, genre.title);
  }

  showAllGenres() {
    this.shouldShowAllGenres = true;
  }

  selectGenreFromDropdown(item: TDropdownItem) {
    if (!item) {
      return;
    }

    this.selectGenre({ id: item.id || '', title: item.name || '' });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/fade';

.channels-genre-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;

  .genres {
    display: flex;
    margin-right: auto;
    overflow: hidden;
    @include fade-right;

    @include mobile-and-tablet {
      display: none;
    }
  }

  .genre {
    flex-shrink: 0;
    height: 40px;
    margin-right: 8px;
    margin-bottom: 8px;

    &::v-deep {
      overflow: hidden;
      white-space: nowrap;
    }

    @include mobile-and-tablet {
      display: none;
      height: 48px;

      &.fav {
        display: flex !important;
        margin-bottom: 0;
      }
    }

    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  .separator {
    display: inline-block;
    width: 1px;
    height: 16px;
    padding-right: 1px;
    margin-right: 8px;
    border-radius: 1px;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    .separator {
      background-color: var(--c-light-200);
    }
  }

  &.dark {
    .separator {
      background-color: var(--c-dark-200);
    }
  }
}
</style>
