<template>
  <select
    v-if="transformNativeSelect && isMobile"
    class="menu-dropdown-content-native"
    :multiple="multiple"
    @change="selectNative($event.target)"
    @touchend="$emit('touchEnd')"
    @touchstart="$emit('touchStart')"
  >
    <slot />
  </select>

  <transition v-else :name="menuContainerType" appear @after-enter="afterEnter" @leave="afterLeave">
    <div
      :class="[menuContainerType, themeForced, { 'hide-scroll': hideScroll }]"
      :style="{ height }"
    >
      <div ref="scroll-wrap" class="scroll-wrap">
        <div ref="menu-dropdown-content" class="menu-dropdown-content">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref } from 'vue-property-decorator';

const MAX_HEIGHT_MENU = 292;

@Component
export default class DropdownContent extends SequoiaComponent {
  hideScroll = true;
  heightMenu = 0;

  @Prop({ required: true })
  actionMenuMobileCustomView!: boolean;

  @Prop({ required: true })
  transformNativeSelect!: boolean;

  @Prop({ required: true })
  isMobile!: boolean;

  @Prop({ required: true })
  multiple!: boolean;

  @Prop()
  maxHeight?: number;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Ref('menu-dropdown-content')
  readonly refMenuDropdownContent?: HTMLDivElement;

  @Ref('scroll-wrap')
  readonly refScrollWrap?: HTMLDivElement;

  get menuContainerType() {
    return this.actionMenuMobileCustomView && this.isMobile
      ? 'menu-dropdown-container-mobile'
      : 'menu-dropdown-container';
  }

  get height() {
    return this.heightMenu > 0 ? `${this.heightMenu}px` : 'auto';
  }

  get maxHeightMenu() {
    return this.maxHeight || MAX_HEIGHT_MENU;
  }

  afterEnter() {
    if (this.actionMenuMobileCustomView && this.isMobile) {
      this.$emit('opened');
    }
  }

  afterLeave() {
    if (this.actionMenuMobileCustomView && this.isMobile) {
      this.$emit('closed');
    }
  }

  selectNative(target: HTMLSelectElement) {
    this.$emit(
      'select',
      this.multiple ? [...target.selectedOptions].map((option) => option.value) : target.value
    );
  }

  calcHeightAndScroll() {
    if (this.transformNativeSelect && this.isMobile) {
      return;
    }

    const heightContent = this.refMenuDropdownContent?.getBoundingClientRect().height;
    if (heightContent && heightContent > this.maxHeightMenu) {
      this.hideScroll = false;
    }
    this.heightMenu =
      heightContent && heightContent <= this.maxHeightMenu ? heightContent : this.maxHeightMenu;

    this.$nextTick(() => {
      const selectedItem = this.refMenuDropdownContent?.querySelector(
        '.menu-item-container.selected'
      ) as HTMLElement | null;

      if (selectedItem && this.refScrollWrap) {
        this.refScrollWrap.scrollTop =
          selectedItem?.offsetTop -
          this.heightMenu / 2 +
          selectedItem.getBoundingClientRect().height / 2;
      }
    });
  }

  mounted() {
    this.calcHeightAndScroll();
  }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.menu-dropdown-container {
  position: absolute;
  top: 100%;
  z-index: var(--z-dropdown);
  width: fit-content;
  max-height: 292px;
  padding-right: 5px;
  margin-top: 8px;
  overflow: hidden;
  background-color: var(--c-light-150);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &::v-deep .menu-item-container:last-child {
    margin-bottom: 8px;
  }

  &.hide-scroll {
    padding-right: 0;

    &::v-deep .menu-item-container:last-child {
      margin-bottom: 0;
    }
  }
}

.menu-dropdown-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
}

.scroll-wrap {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  transition: transform 0.2s;
  -webkit-overflow-scrolling: touch;
}

.hide-scroll {
  .scroll-wrap {
    overflow-y: hidden;
  }
}

.menu-dropdown-container-enter-active,
.menu-dropdown-container-leave-active {
  transition: opacity 0.15s;
}

.menu-dropdown-container-enter,
.menu-dropdown-container-leave-to {
  opacity: 0;

  .scroll-wrap {
    transform: translateY(-8px);
  }
}

.menu-dropdown-container-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: calc(var(--z-dropdown) + 1);
  width: 100%;
  max-height: 292px;
  overflow: hidden;
  background-color: var(--c-light-150);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.menu-dropdown-container-mobile-enter-active,
.menu-dropdown-container-mobile-leave-active {
  transition: transform 0.2s ease-in-out;
}

.menu-dropdown-container-mobile-enter,
.menu-dropdown-container-mobile-leave-to {
  transform: translateY(292px) !important;
}

.menu-dropdown-content-native {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-dropdown);
  display: block;
  width: 100% !important;
  height: 100%;
  opacity: 0;
}

.menu-dropdown-container,
.menu-dropdown-container-mobile {
  &.dark {
    background-color: var(--c-dark-400);
  }
}
</style>
