import Vue from 'vue';
import Component from 'vue-class-component';
import {
  REGISTRATION_RESEND_SMS_CODE_IN_SECONDS,
  REGISTRATION_SESSION_LIFETIME,
} from 'src/constants';
import { convertToMilliseconds } from 'src/utils/time/convert-time';

@Component
export default class Verification extends Vue {
  waitingTime = 0;
  timerId: NodeJS.Timeout | null = null;
  timeoutIdUntilSessionTokenOutdated!: NodeJS.Timeout;

  $_verification_startTimer() {
    this.waitingTime =
      this.$store.contactsVerification?.interval || REGISTRATION_RESEND_SMS_CODE_IN_SECONDS;
    this.timerId = setInterval(this.tik, convertToMilliseconds(1, 'second'));
  }

  $_verification_stopTimer() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  $_verification_setTimeout(nextStep: (step: string) => void, step: string) {
    const expiresAt = this.$store.contactsVerification?.expiresAt;
    let ms = expiresAt
      ? convertToMilliseconds(expiresAt, 'second') - Date.now()
      : REGISTRATION_SESSION_LIFETIME;
    ms = Math.max(ms, 0);
    this.timeoutIdUntilSessionTokenOutdated = setTimeout(() => {
      nextStep(step);
    }, ms);
  }

  $_verification_clearTimeout(id: NodeJS.Timeout) {
    clearTimeout(id);
  }

  tik() {
    this.waitingTime--;
    if (this.waitingTime === 0 && this.timerId) {
      clearInterval(this.timerId);
    }
  }
}
