import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import AddResizeListener from 'src/mixins/AddResizeListener';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconCross from 'src/svg/cross.svg';
import IconSVG from 'src/components/IconSVG.vue';
import Global from 'src/mixins/Global';
import Metrics from 'src/mixins/Metrics';

@Component({
  components: {
    ButtonDefault,
    IconSVG,
  },
})
export class NotificationBaseComponent extends mixins(Global, Metrics, AddResizeListener) {
  loaded = false;
  isScrollLockedFlag: boolean | null = null;
  IconCross = IconCross;

  @Watch('windowWidth')
  onWindowWidthChange(value: number) {
    if (value < 600 && !this.isScrollLockedFlag) {
      this.isScrollLockedFlag = true;
      actions.common.lockBodyScroll(this.$store);
    } else if (value > 600 && this.isScrollLockedFlag) {
      this.isScrollLockedFlag = false;
      actions.common.unlockBodyScroll(this.$store);
    }
  }

  async mounted() {
    this.loaded = true;
    if (this.windowWidth < 600) {
      this.isScrollLockedFlag = true;
      actions.common.lockBodyScroll(this.$store);
    }
  }

  beforeDestroy() {
    this.loaded = false;
    actions.common.unlockBodyScroll(this.$store);
  }
}
