<template>
  <div v-if="categoriesString" class="categories title-details-info" :class="theme">
    <span v-if="showTranslation" class="left" :class="`color-${theme}-font-tertiary`">
      <span v-html="getTranslation('category')" />
    </span>
    <span v-text="categoriesText" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { VODCategoryPreview } from 'src/models/ts/vod/v2/vod';

@Component
export default class TitleCategories extends SequoiaComponent {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ default: false })
  showTranslation?: boolean;

  @Prop()
  categories?: VODCategoryPreview[];

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get categoriesString() {
    return this.categories?.map((category) => category.title).join(', ');
  }

  get categoriesText() {
    return this.showTranslation ? this.categoriesString?.toLowerCase() : this.categoriesString;
  }
}
</script>
