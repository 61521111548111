<template>
  <span
    v-if="svg || svgPath"
    ref="icon"
    class="icon"
    :class="[iconClass, direction, size ? `size-${size}` : '']"
    @click="$emit('click')"
  >
    <!-- When there is an internal svg (like a vue-component) -->
    <component v-if="!svgPath && svg" :is="svg" />
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import axios from 'axios';

@Component
export default class IconSVG extends Vue {
  @Prop()
  // todo change 'any' once there will be proper type for 'is' attribute in component tag
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  svg!: any;

  @Prop()
  iconClass?: string; // some icons may have a unique class for styling purposes

  @Prop({ default: 24 })
  size!: number;

  @Prop()
  direction?: string; // direction classes: up, down, right, left

  @Prop()
  svgPath?: string; // for icons from admin

  @Ref('icon')
  readonly refIcon!: HTMLSpanElement;

  // get theme() {
  //   return this.themeForced || this.$store.theme;
  // }

  async mounted() {
    // When there is an external svg path
    if (this.svgPath) {
      try {
        const { data } = await axios.get(this.svgPath);
        const svgSourceRaw = data.replace(/\n\s/g, '').trim();
        const svgSource = this.decodeSvgEntities(svgSourceRaw);
        if (svgSource) {
          this.refIcon?.appendChild(svgSource);
        }
      } catch (err) {
        throw new Error(`invalid response  ${err}`);
      }
    }
  }

  decodeSvgEntities(svgCode: string) {
    if (!svgCode) {
      return;
    }
    svgCode = svgCode.toString();
    const parsed = new DOMParser().parseFromString(svgCode, 'image/svg+xml');
    return parsed.documentElement;
  }
}
</script>

<style lang="scss" scoped>
// main svg icon class
.icon {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  transition: transform 0.15s var(--ease-out);
  transform: scale(1); // fixes safari jitter bug on hover

  // ::v-deep is needed for dynamically loaded svg files from admin
  ::v-deep svg {
    width: 100%;
    height: 100%;
    color: inherit;
  }

  &.up {
    transform: rotate(180deg);
  }

  &.down-from-right {
    transform: rotate(90deg);
  }

  &.size-16 {
    width: 16px;
    height: 16px;
  }

  &.size-20 {
    width: 20px;
    height: 20px;
  }

  &.size-32 {
    width: 32px;
    height: 32px;
  }

  &.size-36 {
    width: 36px;
    height: 36px;
  }

  &.size-40 {
    width: 40px;
    height: 40px;
  }

  &.size-48 {
    width: 48px;
    height: 48px;
  }

  &.size-64 {
    width: 64px;
    height: 64px;
  }

  &.size-96 {
    width: 96px;
    height: 96px;
  }
}
</style>
