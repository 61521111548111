<template>
  <main
    class="wrapper-for-catalog-and-player"
    :class="[
      { mobile: isMobileDevice || windowWidth <= 599 },
      { 'vitrina-is-open': isCurrentChannelWithVitrina },
    ]"
  >
    <component
      v-if="(isTv && $store.flags.tvDataState.loaded) || !isTv"
      :is="'Player' + playerType"
      :class="{ 'mobile-android': isMobileAndroid }"
      :style="{ display: isPlayerVisible ? 'flex' : 'none' }"
      @onScroll="onScroll"
    />

    <div class="container-1440 position-relative">
      <component :is="catalogComponentName" :ref-scroll-wrap="refScrollWrap" />
    </div>

    <ButtonActionScrollTop :scroll-block="refScrollWrap" />

    <ModalSequoia
      v-if="isModalOpen && isQuickSubscribeModalOpen"
      class="modal-quick-subscribe"
      theme-forced="dark"
      type="fixed"
      size="large"
      min-height="600"
      :with-button-back="withButtonBack"
      @close="handleCloseQuickSubscribeModal"
      @back="handleQuickSubscribeModalStepBack"
    >
      <QuickSubscribe />
    </ModalSequoia>
  </main>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import AddResizeListener from 'src/mixins/AddResizeListener';
import Vod from 'src/mixins/Vod';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import lethargy from 'src/utils/lethargy';
import ButtonActionScrollTop from 'src/components/ui/buttons/ButtonActionScrollTop.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import QuickSubscribe from 'src/components/quick-subscribe/QuickSubscribe.vue';
const PlayerArchive = () =>
  import(/* webpackChunkName: "player" */ 'src/components/player/PlayerArchive.vue');
const PlayerVod = () =>
  import(/* webpackChunkName: "player" */ 'src/components/player/PlayerVod.vue');
const PlayerTv = () =>
  import(/* webpackChunkName: "player" */ 'src/components/player/PlayerTv.vue');
import VodCatalog from 'src/components/vod/VodCatalog.vue';
import ArchiveCatalog from 'src/components/archive/ArchiveCatalog.vue';
import ChannelsCatalog from 'src/components/tv-channels/catalog/ChannelsCatalog.vue';
import { getDeviceFlags } from 'src/utils/platform-detector';
import { SUBSCRIPBE_STEP_WITH_BACK_BTN } from 'src/constants';
import logger from 'src/utils/logger';

const log = logger('wrapper-for-catalog-and-player');

@Component({
  components: {
    ModalSequoia,
    QuickSubscribe,
    ButtonActionScrollTop,
    PlayerVod,
    PlayerArchive,
    PlayerTv,
    ArchiveCatalog,
    VodCatalog,
    ChannelsCatalog,
  },
})
export default class WrapperForCatalogAndPlayer extends mixins(
  SequoiaComponent,
  Vod,
  AddResizeListener
) {
  lockScrollTimeoutId?: NodeJS.Timeout;
  lethargy = new lethargy();
  body: HTMLBodyElement | null = null;
  scrollTop: number | null = null;
  isScrollingDown = false;
  isMobile: boolean | null = null;
  isScrollLockedFlag: boolean | null = null; // this flag is set only after isScrollLocked is set
  start = { x: 0, y: 0 };

  @Prop({ required: true })
  refScrollWrap!: HTMLDivElement;

  @Prop({ required: true })
  playerType!: 'Vod' | 'Archive' | 'Tv';

  @Ref('catalog')
  readonly refCatalog!: HTMLDivElement;

  @Watch('$store.player.loading')
  async onPlayerLoadingChange(val: boolean) {
    if (!val && !this.isArchive) {
      // show full-size player after it has finished loading
      this.expandView();
    }
  }

  @Watch('$store.player.isScrollLocked')
  onIsScrollLockedChange(val: boolean | null) {
    if (val && this.windowWidth >= 959 && !this.isMobileDevice) {
      actions.common.lockBodyScroll(this.$store);
    } else {
      actions.common.unlockBodyScroll(this.$store);
    }
    this.isScrollLockedFlag = val;
    this.isScrollingDown = false;
  }

  @Watch('windowWidth')
  onWindowWidthChange() {
    if (!this.isPlayerVisible) {
      return;
    }

    // there is no minimized version of the player on mobile
    if (this.isPlayerMinimized && (this.windowWidth < 600 || this.isMobileDevice)) {
      actions.player.expandPlayer(this.$store);
    }

    // hide notification with player error
    // when going from mobile view to tablet/desktop
    if (!this.isPlayerMinimized && this.windowWidth >= 600 && !this.isMobileDevice) {
      actions.common.setShowNotificationWithDetails(this.$store, false);
    }

    if (this.isScrollLocked && (this.windowWidth < 600 || this.isMobileDevice)) {
      // always unlock scroll on mobile
      actions.player.unlockScroll(this.$store);
    } else if (
      (this.isScrollLocked || this.isMobile || this.isMobileDevice) &&
      this.windowWidth >= 600
    ) {
      // when going back to tablet/desktop view from mobile
      // bring player to initial position:
      // lock scroll only if isScrollLockedFlag is set to false
      if (!this.isScrollLockedFlag) {
        actions.player.lockScroll(this.$store);
      }

      // always restart onScroll calculations for proper handling of future scroll events
      const evt: WheelEvent = document?.createEvent('WheelEvent');
      evt.initEvent('wheel', true, true);
      Object.defineProperty(evt, 'deltaY', {
        value: 0,
        writable: true,
      });
      this.onScroll(evt);
    }

    this.isMobile = this.windowWidth < 600 || this.isMobileDevice;
  }

  get catalogComponentName() {
    return (this.isTv ? 'Channels' : this.playerType) + 'Catalog';
  }

  get isTv() {
    return this.playerType === 'Tv';
  }

  get isCurrentChannelWithVitrina() {
    return selectors.tvCurrentChannel.isCurrentChannelWithVitrinaSelector(this.$store);
  }

  get isMobileDevice() {
    return getDeviceFlags().isMobile;
  }

  get isMobileAndroid() {
    return getDeviceFlags().isAndroid && getDeviceFlags().isMobile;
  }

  get showFooter() {
    return selectors.common.showFooterSelector(this.$store);
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get videoUrl() {
    return selectors.vod.playingUrlSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get isPlayerVisible() {
    return selectors.player.isPlayerVisibleSelector(this.$store);
  }

  get isScrollLocked() {
    return selectors.player.isScrollLockedSelector(this.$store);
  }

  get isQuickSubscribeModalOpen() {
    return selectors.QS.isModalOpenSelector(this.$store);
  }

  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get isModalTitleOpen() {
    return selectors.vod.isModalTitleOpenSelector(this.$store);
  }

  get isModalChannelOpen() {
    return selectors.tvChannels.isModalChannelOpenSelector(this.$store);
  }

  get currentStepComponentName() {
    return selectors.QS.currentStepComponentNameSelector(this.$store);
  }

  get withButtonBack() {
    return (
      this.currentStepComponentName === 'SubscriptionDetails' ||
      SUBSCRIPBE_STEP_WITH_BACK_BTN.includes(this.currentStepComponentName || '') ||
      (this.currentStepComponentName === 'AboutSubscription' && this.$store.QS.paymentRetry)
    );
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  async mounted() {
    this.onWindowWidthChange();
    window.addEventListener('wheel', this.onScroll);
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchend', this.touchEnd);
    this.body = document?.querySelector('body');
  }

  beforeDestroy() {
    window.removeEventListener('wheel', this.onScroll);
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchend', this.touchEnd);
    actions.player.unlockScroll(this.$store);
    actions.common.resetHowManyTimesBodyScrollWasLocked(this.$store);
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  touchStart(e: TouchEvent) {
    this.start.x = e.touches[0].pageX;
    this.start.y = e.touches[0].pageY;
  }

  touchEnd(e: TouchEvent) {
    if (this.start.y === e.changedTouches[0].pageY) {
      return;
    }
    const offsetY = this.start.y - e.changedTouches[0].pageY;
    this.onScroll(e, offsetY);
  }

  onScroll(e: any, offsetY: null | number = null) {
    if (this.isFullscreen || this.isCurrentChannelWithVitrina || this.isModalOpen) {
      return;
    }

    const isInertia = this.lethargy.handleEvent(e as WheelEvent) === false;

    // do not trigger player scroll when scrolling inside dropdown menus
    const wrongScrollElement = e.path?.find(
      (el: { classList: { contains: (arg0: string) => any } }) => {
        return el.classList?.contains('menu-dropdown');
      }
    );

    const deltaY = offsetY || (e as WheelEvent).deltaY;

    if (
      wrongScrollElement ||
      !this.isPlayerVisible ||
      this.isQuickSubscribeModalOpen ||
      this.isModalTitleOpen ||
      this.isModalChannelOpen ||
      (this.isScrollLocked && this.scrollTop === 0 && deltaY <= 0) ||
      this.isMobileDevice ||
      this.windowWidth < 600
    ) {
      this.isScrollingDown = false;
      return;
    }

    this.scrollTop = this.refScrollWrap?.scrollTop || 0;

    if (deltaY >= 1 && this.isScrollLocked && !this.isScrollingDown) {
      this.isScrollingDown = true;
      actions.player.minimizePlayer(this.$store);
      this.gaEvent({
        category: 'player_controls',
        action: 'Свернуть плеер',
        player_type: this.playerType.toLowerCase(),
      });

      if (this.lockScrollTimeoutId) {
        clearTimeout(this.lockScrollTimeoutId);
      }

      this.lockScrollTimeoutId = setTimeout(() => {
        this.lockScrollTimeoutId = undefined;
        actions.player.unlockScroll(this.$store);
      }, 35);

      if (isInertia && this.lockScrollTimeoutId) {
        return;
      }
    }

    if (isInertia && this.lockScrollTimeoutId) {
      return;
    }

    if (deltaY <= 0 && this.scrollTop === 0) {
      this.gaEvent({
        category: 'player_controls',
        action: 'Развернуть плеер',
        player_type: this.playerType.toLowerCase(),
      });
      this.expandView();
    }
  }

  expandView() {
    actions.player.expandPlayer(this.$store);
    actions.player.lockScroll(this.$store);
    actions.common.setShowNotificationWithDetails(this.$store, false);
  }

  handleQuickSubscribeModalStepBack() {
    actions.QS.handleModalStepBack(this.$store);
  }

  async chooseChannel(channelId: string, autoplay = false, forced = false) {
    log.info('chooseChannel', channelId);

    if (!selectors.player.autoplaySelector(this.$store)) {
      actions.player.setAutoplay(this.$store, autoplay);
    }
    await actions.tvChannels.selectChannel(this.$store, channelId, this.$events, true, forced);
  }

  closeQuickSubscribeModal() {
    actions.common.hideModal(this.$store);
    actions.QS.hideModal(this.$store);
  }

  handleCloseQuickSubscribeModal() {
    if (this.isTv) {
      if (this.$store.modalCallback) {
        setTimeout(async () => {
          this.$store.modalCallback = false;
          try {
            await actions.tvChannels.loadTvData(this.$store);
            if (this.currentChannelId) {
              await this.chooseChannel(this.currentChannelId, true, true);
            }
          } catch (err) {}
          this.closeQuickSubscribeModal();
        }, 3000);
      } else {
        this.closeQuickSubscribeModal();
      }
    } else if (this.isArchive) {
      actions.common.hideModal(this.$store);
      actions.QS.hideModal(this.$store);
      actions.vod.setPlayingChannelId(this.$store, ''); // TODO MOVE
    } else if (this.$store.modalCallback) {
      setTimeout(async () => {
        this.$store.modalCallback = false;
        try {
          if (this.$store.QS.vod?.episodeId) {
            await actions.vod.playVideo(
              this.$store,
              this.sourceId,
              selectors.vod.modalTitleSelector(this.$store, this.sourceId),
              this.$store.QS.vod?.episodeId
            );
          }
        } catch {
          // do nothing, errors should already be logged and printed
        }
        actions.common.hideModal(this.$store);
        actions.QS.hideModal(this.$store);
      }, 3000);
    } else {
      actions.common.hideModal(this.$store);
      actions.QS.hideModal(this.$store);
    }

    this.gaEvent({ category: 'acquiring', action: 'Закрытие модально окна' });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.cms-layout {
  &.mobile {
    height: auto;

    &.scroll-wrap {
      overflow-y: unset;
    }
  }

  .container-1440 {
    margin-top: 64px;

    @include tablet {
      margin-top: 48px;
    }

    @include mobile {
      margin-top: 32px;
    }
  }
}

.scroll-wrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.player-is-visible {
  .wrapper-for-catalog-and-player {
    margin-top: 64px;

    &.mobile {
      margin-top: 0;
    }

    @include mobile {
      margin-top: 0;
    }

    &.vitrina-is-open {
      .container-1440 {
        margin-top: 0;
      }
    }
  }
}

.modal-quick-subscribe::v-deep .dialog {
  background-color: var(--c-dark-150);
}
</style>
